import LocationOnIcon from "@mui/icons-material/LocationOn";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import { isNil } from "lodash";
import * as React from "react";
import { calculateAdjustedBalance } from "~/core/components/domain/accounts/helpers";
import type { IDebtLogEntryModel, IDebtOutBalanceModel } from "~/gql/types";
import { classifyDebtLogEntry, isTotalAdjustmentDLE, isUnknownDLE } from "~/models/models/dle";
import { ArrowedPopper } from "../../widgets/ArrowedPopper";
import { BalanceAdjustmentsPopover } from "./BalanceAdjustmentsPopover";
import { TimelineHistory } from "./TimelineHistory";

export interface IHistoryHighlights {
  handleLoadMoreTimeline: () => void;
  entries: { log_entries: IDebtLogEntryModel[] } | undefined;
  debtOutBalanceAll: IDebtOutBalanceModel;
  showOnLoad?: number;
  showNext?: number;
}

const HistoryHighlights = ({
  handleLoadMoreTimeline,
  entries,
  debtOutBalanceAll,
  showOnLoad = 2,
  showNext = 5
}: IHistoryHighlights): React.ReactElement => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isPopOverOpen, setRawPopOverOpen] = React.useState(false);
  const [selectedDLE, setSelectedDLE] = React.useState<string | null>(null);

  const setPopOverOpen = (isOpen: boolean, dleId: string | null, elem: HTMLElement | null) => {
    setAnchorEl(elem);
    setRawPopOverOpen(isOpen);
    setSelectedDLE(dleId);
  };
  const sortedDLEs = React.useMemo(
    () => (entries?.log_entries ?? []).map(classifyDebtLogEntry).filter(dle => !isUnknownDLE(dle)),
    [entries?.log_entries]
  );
  const adjustedBalances = React.useMemo(
    () => calculateAdjustedBalance(sortedDLEs.filter(isTotalAdjustmentDLE)),
    [sortedDLEs]
  );

  const [timeline, setTimelineItems] = React.useState(sortedDLEs);

  React.useEffect(() => {
    const onLoadItems = sortedDLEs.slice(0, showOnLoad);
    setTimelineItems(onLoadItems);
  }, [sortedDLEs]);

  const showNextItems = (): void => {
    const nextItems = sortedDLEs.slice(0, showOnLoad + showNext);
    setTimelineItems(nextItems);
    handleLoadMoreTimeline();
  };

  const loadRemaining = (): void => {
    const allItems = sortedDLEs.slice();
    setTimelineItems(allItems);
  };

  const isValid = !isNil(timeline) && timeline.length > 0;
  return (
    <Stack spacing={2}>
      <CustomTimeline>
        <TimelineItem>
          <TimelineSeparator>
            {timeline.length > 0 && <LocationOnIcon color="primary" />}
            {isValid && (
              <TimelineConnector sx={{ marginY: 1.25, backgroundColor: theme.palette.common.black }} />
            )}
          </TimelineSeparator>
          {isValid ? (
            <TimelineContent>Today</TimelineContent>
          ) : (
            <TimelineContent>
              <Box textAlign={"center"} position={"relative"} top={"10px"}>
                No entries
              </Box>
            </TimelineContent>
          )}
        </TimelineItem>
        {isValid && <TimelineHistory timeline={timeline} setPopOverOpen={setPopOverOpen} />}
      </CustomTimeline>

      {isValid && timeline.length !== sortedDLEs.length && (
        <Stack direction="row" mt={"0px !important"}>
          <Button
            sx={{
              margin: "0 auto",
              textTransform: "none",
              color: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark,
              position: "relative",
              top: "-25px"
            }}
            onClick={() => (timeline.length >= showOnLoad + showNext ? loadRemaining() : showNextItems())}
            variant="outlined">
            {timeline.length <= showOnLoad ? `Load Next ${showNext}` : "Load Remaining"}
          </Button>
        </Stack>
      )}
      <ArrowedPopper
        open={isPopOverOpen}
        onClose={() => setPopOverOpen(false, null, null)}
        anchorEl={anchorEl}>
        <BalanceAdjustmentsPopover
          debtOutBalance={debtOutBalanceAll}
          adjustedBalance={adjustedBalances.find(b => b.id === selectedDLE)}
        />
      </ArrowedPopper>
    </Stack>
  );
};

const CustomTimeline = styled(Timeline)`
  &.MuiTimeline-root {
    margin-bottom: 0;
    padding-right: 0;
    width: 103.5%;
  }
  &.MuiTimeline-root .MuiTimelineItem-root:before {
    display: none;
  }
`;

export default React.memo(HistoryHighlights);

import type { ReactElement } from "react";
import ReactMarkdown from "react-markdown";
import { SmallAccordion } from "./SmallAccordion";

export interface MarkdownAccordionProps {
  title: string;
  markdown: string;
  isExpanded?: boolean;
}

export function MarkdownAccordion({ title, markdown, isExpanded }: MarkdownAccordionProps): ReactElement {
  return (
    <SmallAccordion title={title} isExpanded={isExpanded}>
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </SmallAccordion>
  );
}

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import type { ReactElement } from "react";
import * as React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Switch, useHistory } from "react-router-dom";
import { useDeepCompareMemo } from "use-deep-compare";
import { CenteredLayout } from "~/core/components/layouts/CenteredLayout";
import { ProgressIndicator } from "~/core/components/widgets/ProgressIndicator";
import { CoreProviders } from "~/core/contexts/CoreProviders";
import type { IConfig } from "../config/context";
import { Landing } from "../pages/Landing";
import OidcLogin from "../pages/OidcLogin";
import { ProtectedLanding } from "./ProtectedLanding";
import { EnhancedRoute, EnhancedSecureRoute } from "./sentry";

interface Props {
  config: IConfig;
}


function ErrorPage({ error }: { error: Error }) {
  let friendlyErrorMessage = "Unable to Login";
  if (error.name === "OAuthError" && error.message === "User is not assigned to the client application.") {
    friendlyErrorMessage = "This feature is not currently available";
  }
  return (
    <CenteredLayout>
      <h1>{friendlyErrorMessage}</h1>
      <div>
        <pre>{error.name}</pre>
        <pre>{error.message}</pre>
      </div>
    </CenteredLayout>
  )
}

function AppWithRouterAccess({ config }: Props): ReactElement {
  const { oktaSoloOptions } = config;
  const oktaAuth = useDeepCompareMemo(() => new OktaAuth(oktaSoloOptions), [oktaSoloOptions]);
  const history = useHistory();

  void oktaAuth.session.exists().then(exists => {
    if (exists) {
      oktaAuth.token
        .getWithoutPrompt({ responseType: ["id_token", "token"] })
        .then(res => {
          oktaAuth.tokenManager.setTokens(res.tokens);
        })
        .catch(console.error);
    }
  });

  return (
    <Security
      oktaAuth={oktaAuth}
      onError={useErrorHandler}
      restoreOriginalUri={(_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
      }}>
      <CoreProviders>
        <Switch>
          <EnhancedRoute path="/" exact component={Landing} />
          <EnhancedSecureRoute path="/app" component={ProtectedLanding} />
          <EnhancedRoute
            path="/login/callback"
            render={() => (
              <LoginCallback
                loadingElement={
                  <CenteredLayout>
                    <ProgressIndicator message={"Authenticating session..."} />
                  </CenteredLayout>
                }
                errorComponent={ErrorPage}
              />
            )}
          />
          <EnhancedRoute path="/okta/initiated" component={OidcLogin} />
        </Switch>
      </CoreProviders>
    </Security>
  );
}

export default AppWithRouterAccess;

import { Button, IconButton, Tooltip } from "@mui/material";
import type { GridActionsCellItemProps } from "@mui/x-data-grid";
import type { ReactElement } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";

export function ViewTaskButton({
  taskId,
  icon,
  label,
  showInMenu,
  tooltip
}: GridActionsCellItemProps & { taskId: string; tooltip: string }): ReactElement | null {
  const history = useHistory();
  const viewTask = (): void => history.push(`/app/tasks/${taskId}`);

  return (
    <Tooltip title={tooltip}>
      {icon && !showInMenu ? (
        <IconButton aria-label={label} onClick={viewTask}>
          {icon}
        </IconButton>
      ) : (
        <Button onClick={viewTask} size="small">
          {label}
        </Button>
      )}
    </Tooltip>
  );
}

import type { IApi_CreditorOut } from "~/gql/graphql";
import type { StrictTypedTypePolicies } from "~/gql/apollo-helpers";
import { CAMUNDA_USER_POLICY } from "~/models/client/camunda_User";
import { CAMUNDA_PROCESS_INSTANCE_POLICY } from "./camunda_ProcessInstance";
import { CAMUNDA_TASK_POLICY } from "./camunda_Task";
import { CREDITOR_TASK_POLICY } from "./CreditorTask";
import { FILE_REQUEST_POLICY } from "./FileRequest";
import { PERSON_POLICY } from "./Person";
import { defineMetaIdReader } from "./utils";

export const TYPE_POLICIES: StrictTypedTypePolicies = {
  Person: PERSON_POLICY,
  camunda_Task: CAMUNDA_TASK_POLICY,
  camunda_User: CAMUNDA_USER_POLICY,
  camunda_ProcessInstance: CAMUNDA_PROCESS_INSTANCE_POLICY,
  CreditorTask: CREDITOR_TASK_POLICY,
  creditors_FileRequest: FILE_REQUEST_POLICY,
  api_CreditorOut: {
    merge: true,
    fields: {
      id: defineMetaIdReader<IApi_CreditorOut>()
    }
  },
  api_DebtOut_BalanceDetails: {
    keyFields: false
  }
};

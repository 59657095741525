import { Link } from "@mui/material";
import { Stack } from "@mui/material";
import type { ReactElement } from "react";

interface Props {
  href: string;
  title: string;
  icon: ReactElement;
}

export function IconLink({ href, title, icon }: Props): ReactElement {
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      {icon}
      <Link href={href} fontSize={"0.8rem"}>
        {title}
      </Link>
    </Stack>
  );
}

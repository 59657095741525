import { useOktaAuth } from "@okta/okta-react";
import type { ReactElement } from "react";
import { Redirect } from "react-router-dom";
import { Login } from "./Login";

export function Landing(): ReactElement {
  const { authState } = useOktaAuth();

  if (authState?.isAuthenticated) {
    return <Redirect to={"/app/tasks"} />;
  } else {
    return <Login />;
  }
}

import type { ReactElement } from "react";
import * as React from "react";
import { DisputeTaskView } from "~/core/components/domain/tasks/DisputeTaskView";
import { WideLayout } from "~/core/components/layouts/WideLayout";
import { ErrorAlert } from "~/core/components/widgets/ErrorAlert";
import { ProgressIndicator } from "~/core/components/widgets/ProgressIndicator";
import type { IDisputeTaskView } from "~/gql/types";

export interface ShowTaskParams {
  isLoading: boolean;
  data: IDisputeTaskView | undefined;
}

export function ShowTask({ isLoading, data }: ShowTaskParams): ReactElement {
  const task = data?.task;

  if (isLoading) {
    if (!task) {
      return (
        <WideLayout widePadding>
          <ProgressIndicator message={`Loading task...`} />
        </WideLayout>
      );
    }
  } else {
    if (!task) {
      return (
        <WideLayout widePadding>
          <ErrorAlert message={`This Camunda Task is not available.`} />
        </WideLayout>
      );
    }
  }

  return (
    <WideLayout>
      <DisputeTaskView task={task} isLoading={isLoading} />
    </WideLayout>
  );
}

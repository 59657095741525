import { RemoveCircle as RemoveCircleIcon } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import { useAlerts } from "~/core/components/widgets/AlertContext";
import { ConfirmationDialog, useConfirmationDialog } from "~/core/components/widgets/ConfirmationDialog";
import { useCrmApi } from "~/core/contexts/ApiContext";
import type { IRemoteFileModel } from "~/gql/types";
import type { DisputeFileRow } from "./DisputeFileRequestsView";

interface Props {
  disputeFile: DisputeFileRow;
  onDelete: (remoteFile: IRemoteFileModel) => void;
}

export function DeleteFileAction({
  disputeFile: { remoteFile, fileName, creditorTaskId, fileRequestId, creditorId },
  onDelete
}: Props): ReactElement {
  const alerts = useAlerts();
  const crmApi = useCrmApi();

  const { confirmState, openConfirm, closeConfirm } = useConfirmationDialog();
  const deleteFile = async (): Promise<void> => {
    try {
      await crmApi.remoteFilesControllerDelete({
        creditorId,
        creditorTaskId,
        fileRequestId,
        remoteFileId: remoteFile.id
      });
      alerts.addAlert({
        message: `Successfully deleted file "${fileName}".`,
        severity: "success"
      });
      onDelete(remoteFile);
    } catch (error) {
      console.error({ error });
      alerts.addAlert({
        message: `Failed to delete file "${fileName}" due to an internal error.`,
        severity: "error"
      });
    } finally {
      closeConfirm();
    }
  };

  return (
    <Grid item>
      <ConfirmationDialog state={confirmState} onClose={closeConfirm} />

      <IconButton
        size={"small"}
        disableFocusRipple
        color="error"
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          openConfirm({
            title: "Are you sure you want to delete this file?",
            body: (
              <>
                File <strong>{fileName}</strong> will be deleted and will no longer associated with this
                dispute. Do you still want to continue?
              </>
            ),
            confirmLabel: "Yes, Delete",
            onConfirm: deleteFile
          });
        }}>
        <RemoveCircleIcon fontSize={"small"} />
      </IconButton>
    </Grid>
  );
}

/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformationConsent.EmailChannel
   */
  ContactInformationConsent_EmailChannel: unknown;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformationConsent.GrandFatheredChannel
   */
  ContactInformationConsent_GrandFatheredChannel: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CreditBalanceAuditRequestEntry.AMEX
   */
  CreditBalanceAuditRequestEntry_AMEX: unknown;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.OtherDebtCategory
   */
  Creditor_CreditorSettings_DebtCategory_OtherDebtCategory: unknown;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.ProductDebtCategory
   */
  Creditor_CreditorSettings_DebtCategory_ProductDebtCategory: unknown;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.ServiceDebtCategory
   */
  Creditor_CreditorSettings_DebtCategory_ServiceDebtCategory: unknown;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PartPayment.Settlement
   */
  DebtState_PartPayment_Settlement: unknown;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.WasOnPaymentPlanWithAnotherAgency
   */
  DebtState_WasOnPaymentPlanWithAnotherAgency: unknown;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.AdditionalPerson.Business
   */
  Debt_AdditionalPerson_Business: unknown;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.AdditionalPerson.SecondaryPerson
   */
  Debt_AdditionalPerson_SecondaryPerson: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.CallRecording
   */
  DisclosureProvisionEntry_CallRecording: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.CardApplicationDisclosure
   */
  DisclosureProvisionEntry_CardApplicationDisclosure: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.CreditBureauReporting
   */
  DisclosureProvisionEntry_CreditBureauReporting: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.Form1099Disclosure
   */
  DisclosureProvisionEntry_Form1099Disclosure: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.MiniMiranda
   */
  DisclosureProvisionEntry_MiniMiranda: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.NonLiability
   */
  DisclosureProvisionEntry_NonLiability: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.OkToSpeak
   */
  DisclosureProvisionEntry_OkToSpeak: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.OutOfStatute
   */
  DisclosureProvisionEntry_OutOfStatute: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.PreLegal
   */
  DisclosureProvisionEntry_PreLegal: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.UnreportedDebt
   */
  DisclosureProvisionEntry_UnreportedDebt: unknown;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ARCDisputeInformation.InDisputeWithCreditor
   */
  DisputeClaim_ARCDisputeInformation_InDisputeWithCreditor: unknown;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.CeaseReceived
   */
  DisputeClaim_ResurgentDisputeInformation_CeaseReceived: unknown;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.ClaimsFraud
   */
  DisputeClaim_ResurgentDisputeInformation_ClaimsFraud: unknown;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.DebtSubstantiation
   */
  DisputeClaim_ResurgentDisputeInformation_DebtSubstantiation: unknown;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.DebtVerification
   */
  DisputeClaim_ResurgentDisputeInformation_DebtVerification: unknown;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.SoldiersAndSailors
   */
  DisputeClaim_ResurgentDisputeInformation_SoldiersAndSailors: unknown;
  JsDate: Date;
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.FridaySchedule
   */
  PaymentPlanApplication_PaymentSchedule_FridaySchedule: unknown;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.AudioInfo
   */
  RemoteFile_CmsAsset_AudioInfo: unknown;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.CsvInfo
   */
  RemoteFile_CmsAsset_CsvInfo: unknown;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.PdfInfo
   */
  RemoteFile_CmsAsset_PdfInfo: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramOptInOptOut.ETAgent
   */
  SpecialProgramOptInOptOut_ETAgent: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.AchTransactionRequest
   */
  ValidatePaymentOptionsAttempt_AchTransactionRequest: unknown;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.CheckPaymentTransactionRequest
   */
  ValidatePaymentOptionsAttempt_CheckPaymentTransactionRequest: unknown;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.NotApplicableResult
   */
  ValidationResult_NotApplicableResult: unknown;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.SuccessfulResult
   */
  ValidationResult_SuccessfulResult: unknown;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.UnpauseCommand
   */
  bulk_update_BulkCommand_UnpauseCommand: unknown;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferResponse
   */
  executor_SendWarmTransferResponse: unknown;
  join__FieldSet: unknown;
};

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.AIMInformation
 */
export interface IAimInformation {
  readonly __typename?: "AIMInformation";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AIMInformation.cpay
   */
  readonly cpay?: Maybe<IAim_Cpay>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AIMInformation.cpay_file_log_entry_id
   */
  readonly cpay_file_log_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/aim_log_entries.proto
 * com.trueaccord.proto.AIMLogEntry
 */
export interface IAimLogEntry {
  readonly __typename?: "AIMLogEntry";
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.aack_file
   */
  readonly aack_file?: Maybe<IAim_Aack_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.aast_file
   */
  readonly aast_file?: Maybe<IAim_Aast_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.abkp_file
   */
  readonly abkp_file?: Maybe<IAim_Abkp_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.acls_file
   */
  readonly acls_file?: Maybe<IAim_Acls_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.adec_file
   */
  readonly adec_file?: Maybe<IAim_Adec_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.adem_file
   */
  readonly adem_file?: Maybe<IAim_Adem_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.ajdg_file
   */
  readonly ajdg_file?: Maybe<IAim_Ajdg_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.anot_file
   */
  readonly anot_file?: Maybe<IAim_Anot_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.aobj_file
   */
  readonly aobj_file?: Maybe<IAim_Aobj_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.apay_file
   */
  readonly apay_file?: Maybe<IAim_Apay_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.apdt_file
   */
  readonly apdt_file?: Maybe<IAim_Apdt_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.arar_file
   */
  readonly arar_file?: Maybe<IAim_Arar_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.arec_file
   */
  readonly arec_file?: Maybe<IAim_Arec_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.associated_log_entry
   */
  readonly associated_log_entry?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.asts_file
   */
  readonly asts_file?: Maybe<IAim_Asts_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cast_file
   */
  readonly cast_file?: Maybe<IAim_Cast_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cbkp_file
   */
  readonly cbkp_file?: Maybe<IAim_Cbkp_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cdec_file
   */
  readonly cdec_file?: Maybe<IAim_Cdec_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cdem_file
   */
  readonly cdem_file?: Maybe<IAim_Cdem_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.ceqp_file
   */
  readonly ceqp_file?: Maybe<IAim_Ceqp_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cjdg_file
   */
  readonly cjdg_file?: Maybe<IAim_Cjdg_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cmis_file
   */
  readonly cmis_file?: Maybe<IAim_Cmis_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cnot_file
   */
  readonly cnot_file?: Maybe<IAim_Cnot_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cpay_file
   */
  readonly cpay_file?: Maybe<IAim_Cpay_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.cplc_file
   */
  readonly cplc_file?: Maybe<IAim_Cplc_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.crar_file
   */
  readonly crar_file?: Maybe<IAim_Crar_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.crcl_file
   */
  readonly crcl_file?: Maybe<IAim_Crcl_File>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.filename
   */
  readonly filename?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.post_processing_necessary
   */
  readonly post_processing_necessary?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/aim_log_entries.proto
   * com.trueaccord.proto.AIMLogEntry.processed
   */
  readonly processed?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/payment_method_info.proto
 * com.trueaccord.proto.AchPaymentMethodInfo
 */
export interface IAchPaymentMethodInfo {
  readonly __typename?: "AchPaymentMethodInfo";
  /**
   * File: collectr/proto/payment_method_info.proto
   * com.trueaccord.proto.AchPaymentMethodInfo.last_four_of_account_number
   */
  readonly last_four_of_account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_method_info.proto
   * com.trueaccord.proto.AchPaymentMethodInfo.last_three_of_account_number
   */
  readonly last_three_of_account_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.AchTransactionToken
 */
export interface IAchTransactionToken {
  readonly __typename?: "AchTransactionToken";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.AchTransactionToken.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.AchTransactionToken.payment_method_info
   */
  readonly payment_method_info?: Maybe<IAchPaymentMethodInfo>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.AchTransactionToken.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/terminal_debt_information.proto
 * com.trueaccord.proto.ActiveMilitaryInformation
 */
export interface IActiveMilitaryInformation {
  readonly __typename?: "ActiveMilitaryInformation";
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.ActiveMilitaryInformation.active_duty_date
   */
  readonly active_duty_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.ActiveMilitaryInformation.active_duty_military_flag
   */
  readonly active_duty_military_flag?: Maybe<IScraDebtScrub_ActiveDutyMilitaryFlag | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.ActiveMilitaryInformation.authority
   */
  readonly authority?: Maybe<ITerminalDebt_Authority | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.ActiveMilitaryInformation.date_of_birth
   */
  readonly date_of_birth?: Maybe<IDate>;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.ActiveMilitaryInformation.full_name
   */
  readonly full_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.ActiveMilitaryInformation.time_created
   */
  readonly time_created?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Address
 */
export interface IAddress {
  readonly __typename?: "Address";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Address.city
   */
  readonly city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Address.country
   */
  readonly country?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Address.country_code
   */
  readonly country_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Address.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Address.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Address.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Address.street
   */
  readonly street?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Address.zip
   */
  readonly zip?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/capital_one/adqw.proto
 * com.trueaccord.proto.AdqwMonthlyOutbound
 */
export interface IAdqwMonthlyOutbound {
  readonly __typename?: "AdqwMonthlyOutbound";
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutbound.all_accounts_monthly_outbound
   */
  readonly all_accounts_monthly_outbound: ReadonlyArray<Maybe<IAdqwMonthlyOutboundAccountData>>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutbound.file_name
   */
  readonly file_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/capital_one/adqw.proto
 * com.trueaccord.proto.AdqwMonthlyOutboundAccountData
 */
export interface IAdqwMonthlyOutboundAccountData {
  readonly __typename?: "AdqwMonthlyOutboundAccountData";
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutboundAccountData.partner_id
   */
  readonly partner_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutboundAccountData.placement_start_date
   */
  readonly placement_start_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutboundAccountData.post_dated_check_amounts
   */
  readonly post_dated_check_amounts: ReadonlyArray<Maybe<IMonetaryAmount>>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutboundAccountData.post_dated_check_dates
   */
  readonly post_dated_check_dates: ReadonlyArray<Maybe<Scalars["Long"]>>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutboundAccountData.router_account_number
   */
  readonly router_account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutboundAccountData.times_auto_contact_attempted
   */
  readonly times_auto_contact_attempted?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutboundAccountData.times_manual_contact_attempted
   */
  readonly times_manual_contact_attempted?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwMonthlyOutboundAccountData.times_right_party_contacted
   */
  readonly times_right_party_contacted?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/capital_one/adqw.proto
 * com.trueaccord.proto.AdqwOutboundEntry
 */
export interface IAdqwOutboundEntry {
  readonly __typename?: "AdqwOutboundEntry";
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwOutboundEntry.date
   */
  readonly date?: Maybe<IDate>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwOutboundEntry.frequency
   */
  readonly frequency?: Maybe<IAdqwOutboundEntry_AdqwOutboundFrequency | "%future added value">;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwOutboundEntry.is_sent
   */
  readonly is_sent?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwOutboundEntry.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwOutboundEntry.monthly_outbound
   */
  readonly monthly_outbound?: Maybe<IAdqwMonthlyOutbound>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwOutboundEntry.weekly_outbound
   */
  readonly weekly_outbound?: Maybe<IAdqwWeeklyOutbound>;
}

/**
 * File: collectr/proto/capital_one/adqw.proto
 * com.trueaccord.proto.AdqwOutboundEntry.AdqwOutboundFrequency
 */
export enum IAdqwOutboundEntry_AdqwOutboundFrequency {
  Monthly = "MONTHLY",
  Weekly = "WEEKLY"
}

/**
 * File: collectr/proto/capital_one/adqw.proto
 * com.trueaccord.proto.AdqwWeeklyOutbound
 */
export interface IAdqwWeeklyOutbound {
  readonly __typename?: "AdqwWeeklyOutbound";
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutbound.all_accounts_weekly_outbound
   */
  readonly all_accounts_weekly_outbound: ReadonlyArray<Maybe<IAdqwWeeklyOutboundAccountData>>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutbound.file_name
   */
  readonly file_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/capital_one/adqw.proto
 * com.trueaccord.proto.AdqwWeeklyOutboundAccountData
 */
export interface IAdqwWeeklyOutboundAccountData {
  readonly __typename?: "AdqwWeeklyOutboundAccountData";
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutboundAccountData.debt_settlement_company_name
   */
  readonly debt_settlement_company_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutboundAccountData.debt_settlement_company_phone_number
   */
  readonly debt_settlement_company_phone_number?: Maybe<IPhoneNumber>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutboundAccountData.email_address
   */
  readonly email_address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutboundAccountData.partner_id
   */
  readonly partner_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutboundAccountData.placement_start_date
   */
  readonly placement_start_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutboundAccountData.right_party_contact_phone_numbers
   */
  readonly right_party_contact_phone_numbers: ReadonlyArray<Maybe<IPhoneNumber>>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutboundAccountData.router_account_number
   */
  readonly router_account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/capital_one/adqw.proto
   * com.trueaccord.proto.AdqwWeeklyOutboundAccountData.wrong_party_contact_phone_numbers
   */
  readonly wrong_party_contact_phone_numbers: ReadonlyArray<Maybe<IPhoneNumber>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Alert
 */
export interface IAlert {
  readonly __typename?: "Alert";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.action
   */
  readonly action?: Maybe<IAlert_Action>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.label
   */
  readonly label?: Maybe<IAlert_Label>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.owner_id
   */
  readonly owner_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.text
   */
  readonly text?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.topic
   */
  readonly topic?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Alert.Action
 */
export interface IAlert_Action {
  readonly __typename?: "Alert_Action";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.Action.href
   */
  readonly href?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.Action.level
   */
  readonly level?: Maybe<IAlert_Level | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.Action.target
   */
  readonly target?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.Action.text
   */
  readonly text?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Alert.Label
 */
export interface IAlert_Label {
  readonly __typename?: "Alert_Label";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.Label.level
   */
  readonly level?: Maybe<IAlert_Level | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Alert.Label.text
   */
  readonly text?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Alert.Level
 */
export enum IAlert_Level {
  Danger = "danger",
  Info = "info",
  Primary = "primary",
  Success = "success",
  Warning = "warning"
}

/**
 * File: collectr/proto/analyst_server.proto
 * com.trueaccord.proto.AnalystState
 */
export interface IAnalystState {
  readonly __typename?: "AnalystState";
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.active_heartbeat_version
   */
  readonly active_heartbeat_version?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.associated_accounts
   */
  readonly associated_accounts: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.experiments
   */
  readonly experiments: ReadonlyArray<Maybe<IAnalystState_Experiment>>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.group_change_history
   */
  readonly group_change_history: ReadonlyArray<Maybe<IAnalystState_GroupChangeEntry>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.payment_offer_strategies
   */
  readonly payment_offer_strategies: ReadonlyArray<Maybe<IAnalystState_PaymentOfferStrategy>>;
}

/**
 * File: collectr/proto/analyst_server.proto
 * com.trueaccord.proto.AnalystState.Experiment
 */
export interface IAnalystState_Experiment {
  readonly __typename?: "AnalystState_Experiment";
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.Experiment.experiment
   */
  readonly experiment?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.Experiment.experiment_extras
   */
  readonly experiment_extras?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.Experiment.experiment_group
   */
  readonly experiment_group?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.Experiment.is_binned_experiment
   */
  readonly is_binned_experiment?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.Experiment.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/analyst_server.proto
 * com.trueaccord.proto.AnalystState.GroupChangeEntry
 */
export interface IAnalystState_GroupChangeEntry {
  readonly __typename?: "AnalystState_GroupChangeEntry";
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.GroupChangeEntry.edge
   */
  readonly edge?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.GroupChangeEntry.new_group
   */
  readonly new_group?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.GroupChangeEntry.old_group
   */
  readonly old_group?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.GroupChangeEntry.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/analyst_server.proto
 * com.trueaccord.proto.AnalystState.PaymentOfferStrategy
 */
export interface IAnalystState_PaymentOfferStrategy {
  readonly __typename?: "AnalystState_PaymentOfferStrategy";
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.PaymentOfferStrategy.completed_timestamp
   */
  readonly completed_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.PaymentOfferStrategy.expiration_timestamp
   */
  readonly expiration_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.PaymentOfferStrategy.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.PaymentOfferStrategy.payment_offer_types_affected
   */
  readonly payment_offer_types_affected: ReadonlyArray<
    Maybe<IAnalystState_PaymentOfferStrategy_PaymentOfferType | "%future added value">
  >;
  /**
   * File: collectr/proto/analyst_server.proto
   * com.trueaccord.proto.AnalystState.PaymentOfferStrategy.strategy_name
   */
  readonly strategy_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/analyst_server.proto
 * com.trueaccord.proto.AnalystState.PaymentOfferStrategy.PaymentOfferType
 */
export enum IAnalystState_PaymentOfferStrategy_PaymentOfferType {
  PaymentPlan = "PAYMENT_PLAN",
  Settlement = "SETTLEMENT",
  TermSettlement = "TERM_SETTLEMENT"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.ApiKey
 */
export interface IApiKey {
  readonly __typename?: "ApiKey";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.ApiKey.environment
   */
  readonly environment?: Maybe<IEnvironment | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.ApiKey.hashed_key
   */
  readonly hashed_key?: Maybe<IAuth_HashedKey>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.ApiKey.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /** ForeignKey(foreignKey: user_account_id) */
  readonly user_account?: Maybe<IAuth_UserAccount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.ApiKey.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.AttorneyRepresentationEntry
 */
export interface IAttorneyRepresentationEntry {
  readonly __typename?: "AttorneyRepresentationEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.communication_type
   */
  readonly communication_type?: Maybe<ICommunicationType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.contact_info
   */
  readonly contact_info?: Maybe<IContactInformation>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.fico_info
   */
  readonly fico_info?: Maybe<IAttorneyRepresentationEntry_FicoAttorneyRepresentationInfo>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.is_debt_consolidator
   */
  readonly is_debt_consolidator?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.no_attorney_information_provided
   */
  readonly no_attorney_information_provided?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.received_power_of_attorney_documentation
   */
  readonly received_power_of_attorney_documentation?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.recorded_by
   */
  readonly recorded_by?: Maybe<IAttorneyRepresentationEntry_RecordedBy | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.representation_type
   */
  readonly representation_type?: Maybe<
    IAttorneyRepresentationEntry_RepresentationType | "%future added value"
  >;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.requested_by
   */
  readonly requested_by?: Maybe<IRequestedBy | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.AttorneyRepresentationEntry.FicoAttorneyRepresentationInfo
 */
export interface IAttorneyRepresentationEntry_FicoAttorneyRepresentationInfo {
  readonly __typename?: "AttorneyRepresentationEntry_FicoAttorneyRepresentationInfo";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.AttorneyRepresentationEntry.FicoAttorneyRepresentationInfo.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.AttorneyRepresentationEntry.RecordedBy
 */
export enum IAttorneyRepresentationEntry_RecordedBy {
  Agency = "AGENCY",
  Creditor = "CREDITOR",
  Fico = "FICO",
  RecordedByUnknown = "RECORDED_BY_UNKNOWN"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.AttorneyRepresentationEntry.RepresentationType
 */
export enum IAttorneyRepresentationEntry_RepresentationType {
  DemandLetter = "DEMAND_LETTER",
  Negotiation = "NEGOTIATION",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/terminal_debt_information.proto
 * com.trueaccord.proto.BankruptcyInformation
 */
export interface IBankruptcyInformation {
  readonly __typename?: "BankruptcyInformation";
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.BankruptcyInformation.authority
   */
  readonly authority?: Maybe<ITerminalDebt_Authority | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.BankruptcyInformation.bankruptcy
   */
  readonly bankruptcy?: Maybe<ITerminalDebt_Bankruptcy>;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.BankruptcyInformation.time_created
   */
  readonly time_created?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Blacklist
 */
export interface IBlacklist {
  readonly __typename?: "Blacklist";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.emails
   */
  readonly emails: ReadonlyArray<Maybe<IBlacklist_Email>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.object_id
   */
  readonly object_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.phones
   */
  readonly phones: ReadonlyArray<Maybe<IBlacklist_Phone>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Blacklist.BlacklistReason
 */
export interface IBlacklist_BlacklistReason {
  readonly __typename?: "Blacklist_BlacklistReason";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.BlacklistReason.cause
   */
  readonly cause?: Maybe<IBlacklist_Restriction | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.BlacklistReason.mode
   */
  readonly mode?: Maybe<IPhoneNumber_Mode | "%future added value">;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Blacklist.Email
 */
export interface IBlacklist_Email {
  readonly __typename?: "Blacklist_Email";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.Email.email
   */
  readonly email?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Blacklist.Phone
 */
export interface IBlacklist_Phone {
  readonly __typename?: "Blacklist_Phone";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.Phone.modes
   */
  readonly modes: ReadonlyArray<Maybe<IPhoneNumber_Mode | "%future added value">>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.Phone.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Blacklist.Phone.reasons
   */
  readonly reasons: ReadonlyArray<Maybe<IBlacklist_BlacklistReason>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Blacklist.Restriction
 */
export enum IBlacklist_Restriction {
  Scrubs = "SCRUBS"
}

/**
 * File: collectr/proto/sendgrid_event.proto
 * com.trueaccord.proto.BounceTypeMessage.BounceTypeEnum
 */
export enum IBounceTypeMessage_BounceTypeEnum {
  Blocked = "blocked",
  Bounce = "bounce",
  Expired = "expired"
}

/**
 * File: collectr/proto/management_systems/brand_mapping.proto
 * com.trueaccord.proto.management_systems.BrandMapping
 */
export interface IBrandMapping {
  readonly __typename?: "BrandMapping";
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.creditor_groups
   */
  readonly creditor_groups: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.creditors
   */
  readonly creditors: ReadonlyArray<Maybe<Scalars["String"]>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.mappings
   */
  readonly mappings: ReadonlyArray<Maybe<IManagement_Systems_BrandMapping_Mapping>>;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.name
   */
  readonly name?: Maybe<Scalars["String"]>;
}

export interface ICamundaTaskStats {
  readonly __typename?: "CamundaTaskStats";
  readonly count: Scalars["Int"];
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CancelRepresentationEntry
 */
export interface ICancelRepresentationEntry {
  readonly __typename?: "CancelRepresentationEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CancelRepresentationEntry.attorney_representation_id
   */
  readonly attorney_representation_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CancelRepresentationEntry.communication_type
   */
  readonly communication_type?: Maybe<ICommunicationType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CancelRepresentationEntry.debt_consolidator_id
   */
  readonly debt_consolidator_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CancelRepresentationEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CancelRepresentationEntry.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CancelRepresentationEntry.requested_by
   */
  readonly requested_by?: Maybe<IRequestedBy | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CapitalOneMediaRequest
 */
export interface ICapitalOneMediaRequest {
  readonly __typename?: "CapitalOneMediaRequest";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequest.credit_card_application
   */
  readonly credit_card_application?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequest.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequest.is_vod_request
   */
  readonly is_vod_request?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequest.statements
   */
  readonly statements?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CapitalOneMediaRequestQuery
 */
export interface ICapitalOneMediaRequestQuery {
  readonly __typename?: "CapitalOneMediaRequestQuery";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestQuery.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestQuery.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestQuery.is_vod_request
   */
  readonly is_vod_request?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestQuery.request_id
   */
  readonly request_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CapitalOneMediaRequestReply
 */
export interface ICapitalOneMediaRequestReply {
  readonly __typename?: "CapitalOneMediaRequestReply";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestReply.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestReply.applications
   */
  readonly applications: ReadonlyArray<Maybe<ICapitalOneMediaRequestReply_Media>>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestReply.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestReply.statements
   */
  readonly statements: ReadonlyArray<Maybe<ICapitalOneMediaRequestReply_Media>>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CapitalOneMediaRequestReply.Media
 */
export interface ICapitalOneMediaRequestReply_Media {
  readonly __typename?: "CapitalOneMediaRequestReply_Media";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestReply.Media.filename
   */
  readonly filename?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestReply.Media.remote_file_id
   */
  readonly remote_file_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CapitalOneMediaRequestReply.Media.s3_path
   */
  readonly s3_path?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/payment_method_info.proto
 * com.trueaccord.proto.CardPaymentMethodInfo
 */
export interface ICardPaymentMethodInfo {
  readonly __typename?: "CardPaymentMethodInfo";
  /**
   * File: collectr/proto/payment_method_info.proto
   * com.trueaccord.proto.CardPaymentMethodInfo.billing_zip_code
   */
  readonly billing_zip_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_method_info.proto
   * com.trueaccord.proto.CardPaymentMethodInfo.bin_number
   */
  readonly bin_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_method_info.proto
   * com.trueaccord.proto.CardPaymentMethodInfo.card_type
   */
  readonly card_type?: Maybe<ICardPaymentMethodInfo_CardType | "%future added value">;
  /**
   * File: collectr/proto/payment_method_info.proto
   * com.trueaccord.proto.CardPaymentMethodInfo.expiration_month
   */
  readonly expiration_month?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_method_info.proto
   * com.trueaccord.proto.CardPaymentMethodInfo.expiration_year
   */
  readonly expiration_year?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_method_info.proto
   * com.trueaccord.proto.CardPaymentMethodInfo.last_four_digits
   */
  readonly last_four_digits?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/payment_method_info.proto
 * com.trueaccord.proto.CardPaymentMethodInfo.CardType
 */
export enum ICardPaymentMethodInfo_CardType {
  Discover = "DISCOVER",
  Mastercard = "MASTERCARD",
  Unknown = "UNKNOWN",
  Visa = "VISA"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CfpbItemizationEntry
 */
export interface ICfpbItemizationEntry {
  readonly __typename?: "CfpbItemizationEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.itemization_breakdown
   */
  readonly itemization_breakdown?: Maybe<ICfpbItemizationEntry_ItemizationBreakdown>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.itemization_date
   */
  readonly itemization_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.itemization_type
   */
  readonly itemization_type?: Maybe<ICfpbItemizationEntry_ItemizationDateType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.name_of_creditor_at_itemization_date
   */
  readonly name_of_creditor_at_itemization_date?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CfpbItemizationEntry.BucketType
 */
export enum ICfpbItemizationEntry_BucketType {
  Credit = "CREDIT",
  Debit = "DEBIT"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CfpbItemizationEntry.ItemizationBreakdown
 */
export interface ICfpbItemizationEntry_ItemizationBreakdown {
  readonly __typename?: "CfpbItemizationEntry_ItemizationBreakdown";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.ItemizationBreakdown.itemization_balance
   */
  readonly itemization_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.ItemizationBreakdown.itemization_buckets
   */
  readonly itemization_buckets: ReadonlyArray<Maybe<ICfpbItemizationEntry_ItemizationBucket>>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.ItemizationBreakdown.itemization_principal
   */
  readonly itemization_principal?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CfpbItemizationEntry.ItemizationBucket
 */
export interface ICfpbItemizationEntry_ItemizationBucket {
  readonly __typename?: "CfpbItemizationEntry_ItemizationBucket";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.ItemizationBucket.bucket_type
   */
  readonly bucket_type?: Maybe<ICfpbItemizationEntry_BucketType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.ItemizationBucket.content_key
   */
  readonly content_key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CfpbItemizationEntry.ItemizationBucket.total
   */
  readonly total?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CfpbItemizationEntry.ItemizationDateType
 */
export enum ICfpbItemizationEntry_ItemizationDateType {
  ChargeOff = "CHARGE_OFF",
  Judgement = "JUDGEMENT",
  LastPayment = "LAST_PAYMENT",
  LastStatement = "LAST_STATEMENT",
  Transaction = "TRANSACTION",
  Unspecified = "UNSPECIFIED"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.ChargeOffStatus
 */
export enum IChargeOffStatus {
  None = "NONE",
  PostChargeOff = "POST_CHARGE_OFF",
  PreChargeOff = "PRE_CHARGE_OFF"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.CollectionPaused
 */
export interface ICollectionPaused {
  readonly __typename?: "CollectionPaused";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CollectionPaused.expiration_timestamp
   */
  readonly expiration_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CollectionPaused.notes
   */
  readonly notes?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CollectionPaused.pause_id
   */
  readonly pause_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CollectionPaused.pause_reason
   */
  readonly pause_reason?: Maybe<ICollectionPaused_PauseReason | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CollectionPaused.pause_timestamp
   */
  readonly pause_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CollectionPaused.removes_pause
   */
  readonly removes_pause?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CollectionPausedEntry
 */
export interface ICollectionPausedEntry {
  readonly __typename?: "CollectionPausedEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CollectionPausedEntry.collection_paused
   */
  readonly collection_paused?: Maybe<ICollectionPaused>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CollectionPausedEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.CollectionPaused.PauseReason
 */
export enum ICollectionPaused_PauseReason {
  DvDocsMailed = "DV_DOCS_MAILED",
  GeographicSuppression = "GEOGRAPHIC_SUPPRESSION",
  GoodFaithPayment = "GOOD_FAITH_PAYMENT",
  InvalidDisputePausePeriod = "INVALID_DISPUTE_PAUSE_PERIOD",
  MailedInPayment = "MAILED_IN_PAYMENT",
  Other = "OTHER",
  PendingBkVerification = "PENDING_BK_VERIFICATION",
  PendingFraudInvestigation = "PENDING_FRAUD_INVESTIGATION",
  PendingPrevPaidDocs = "PENDING_PREV_PAID_DOCS",
  PendingVodMailing = "PENDING_VOD_MAILING",
  PermanentHardshipLongTermIncarceration = "PERMANENT_HARDSHIP_LONG_TERM_INCARCERATION",
  PermanentHardshipPermanentDisability = "PERMANENT_HARDSHIP_PERMANENT_DISABILITY",
  PermanentHardshipTerminalIllness = "PERMANENT_HARDSHIP_TERMINAL_ILLNESS",
  Profanity = "PROFANITY",
  Scra = "SCRA",
  TemporaryHardship = "TEMPORARY_HARDSHIP",
  TemporaryHardshipConfirmed = "TEMPORARY_HARDSHIP_CONFIRMED"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Comment
 */
export interface IComment {
  readonly __typename?: "Comment";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.comment_type
   */
  readonly comment_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.data_source
   */
  readonly data_source?: Maybe<IDataSource>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.internal_information
   */
  readonly internal_information?: Maybe<IComment_InternalInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.object_id
   */
  readonly object_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.user_name
   */
  readonly user_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Comment.InternalInformation
 */
export interface IComment_InternalInformation {
  readonly __typename?: "Comment_InternalInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.InternalInformation.aim_info
   */
  readonly aim_info?: Maybe<IComment_InternalInformation_AimInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.InternalInformation.amex_information
   */
  readonly amex_information?: Maybe<IComment_InternalInformation_AmexInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.InternalInformation.fico_info
   */
  readonly fico_info?: Maybe<IComment_InternalInformation_FicoInformation>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Comment.InternalInformation.AIMInformation
 */
export interface IComment_InternalInformation_AimInformation {
  readonly __typename?: "Comment_InternalInformation_AIMInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.InternalInformation.AIMInformation.cnot_entry
   */
  readonly cnot_entry?: Maybe<IAim_Cnot>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.InternalInformation.AIMInformation.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.InternalInformation.AIMInformation.log_entry_id
   */
  readonly log_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Comment.InternalInformation.AmexInformation
 */
export interface IComment_InternalInformation_AmexInformation {
  readonly __typename?: "Comment_InternalInformation_AmexInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.InternalInformation.AmexInformation.flag
   */
  readonly flag?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Comment.InternalInformation.FicoInformation
 */
export interface IComment_InternalInformation_FicoInformation {
  readonly __typename?: "Comment_InternalInformation_FicoInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Comment.InternalInformation.FicoInformation.raw_line_ids
   */
  readonly raw_line_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.CommerceType
 */
export enum ICommerceType {
  Online = "ONLINE",
  Traditional = "TRADITIONAL"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CommonInboundTransmissionFields
 */
export interface ICommonInboundTransmissionFields {
  readonly __typename?: "CommonInboundTransmissionFields";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CommonInboundTransmissionFields.account_sid
   */
  readonly account_sid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CommonInboundTransmissionFields.api_version
   */
  readonly api_version?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CommonInboundTransmissionFields.from_address
   */
  readonly from_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CommonInboundTransmissionFields.from_number
   */
  readonly from_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CommonInboundTransmissionFields.message_sid
   */
  readonly message_sid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CommonInboundTransmissionFields.to_address
   */
  readonly to_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CommonInboundTransmissionFields.to_number
   */
  readonly to_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CommunicationType
 */
export enum ICommunicationType {
  CommunicationTypeUnknown = "COMMUNICATION_TYPE_UNKNOWN",
  Verbal = "VERBAL",
  Written = "WRITTEN"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ComplianceCheckResultEntry
 */
export interface IComplianceCheckResultEntry {
  readonly __typename?: "ComplianceCheckResultEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ComplianceCheckResultEntry.capabilities
   */
  readonly capabilities: ReadonlyArray<Maybe<ICompliance_ComplianceCapability_ChannelCapability>>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ComplianceCheckResultEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ComplianceCheckResultEntry.resultType
   */
  readonly resultType?: Maybe<IComplianceCheckResultEntry_ResultType | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ComplianceCheckResultEntry.ResultType
 */
export enum IComplianceCheckResultEntry_ResultType {
  UnableToStaggerDebt = "UNABLE_TO_STAGGER_DEBT"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ContactInformation
 */
export interface IContactInformation {
  readonly __typename?: "ContactInformation";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformation.address
   */
  readonly address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformation.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformation.phone_number
   */
  readonly phone_number?: Maybe<IPhoneNumber>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ContactInformationConsent
 */
export interface IContactInformationConsent {
  readonly __typename?: "ContactInformationConsent";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformationConsent.email_channel
   */
  readonly email_channel?: Maybe<Scalars["ContactInformationConsent_EmailChannel"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformationConsent.form_channel
   */
  readonly form_channel?: Maybe<IContactInformationConsent_FormChannel>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformationConsent.grand_fathered_channel
   */
  readonly grand_fathered_channel?: Maybe<Scalars["ContactInformationConsent_GrandFatheredChannel"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformationConsent.time
   */
  readonly time?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ContactInformationConsent.FormChannel
 */
export interface IContactInformationConsent_FormChannel {
  readonly __typename?: "ContactInformationConsent_FormChannel";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ContactInformationConsent.FormChannel.page_view_id
   */
  readonly page_view_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.Contribution
 */
export interface IContribution {
  readonly __typename?: "Contribution";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.Contribution.greeting
   */
  readonly greeting?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CreditBalanceAuditRequestEntry
 */
export interface ICreditBalanceAuditRequestEntry {
  readonly __typename?: "CreditBalanceAuditRequestEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CreditBalanceAuditRequestEntry.amex
   */
  readonly amex?: Maybe<Scalars["CreditBalanceAuditRequestEntry_AMEX"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CreditBalanceAuditRequestEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor
 */
export interface ICreditor {
  readonly __typename?: "Creditor";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor._OBSOLETE_disbursement_settings
   */
  readonly _OBSOLETE_disbursement_settings?: Maybe<ICreditors_SensitiveCreditorSettings_DisbursementSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.activation_status
   */
  readonly activation_status?: Maybe<ICreditor_ActivationStatus | "%future added value">;
  /** OneToOne */
  readonly brand_mapping?: Maybe<IBrandMapping>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.brands
   */
  readonly brands: ReadonlyArray<Maybe<ICreditor_Brand>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.collection_report_email
   */
  readonly collection_report_email?: Maybe<ICreditor_CollectionReportEmail>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.commission_percent_basis_point
   */
  readonly commission_percent_basis_point?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.company_name
   */
  readonly company_name?: Maybe<Scalars["String"]>;
  /** OneToMany */
  readonly creditor_customers?: Maybe<ReadonlyArray<Maybe<ICreditorCustomer>>>;
  /** OneToOne */
  readonly creditor_group?: Maybe<ICreditorGroup>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.creditor_settings
   */
  readonly creditor_settings?: Maybe<ICreditor_CreditorSettings>;
  /** OneToMany */
  readonly debts?: Maybe<ReadonlyArray<Maybe<IDebt>>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.documentation_availability
   */
  readonly documentation_availability?: Maybe<ICreditor_DocumentationAvailability>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.environment
   */
  readonly environment?: Maybe<IEnvironment | "%future added value">;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.industry
   */
  readonly industry?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.integration_info
   */
  readonly integration_info?: Maybe<ICreditor_IntegrationInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.policy
   */
  readonly policy?: Maybe<ICreditor_Policy>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.quickbooks_credentials
   */
  readonly quickbooks_credentials?: Maybe<ICreditor_QuickBooksCredentials>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.referral_token_id
   */
  readonly referral_token_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.tag
   */
  readonly tag?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.users
   */
  readonly users: ReadonlyArray<Maybe<Scalars["Int"]>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.volume_placed_commission_rate
   */
  readonly volume_placed_commission_rate?: Maybe<ICreditor_VolumePlacedCommissionRate>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CreditorCommentEntry
 */
export interface ICreditorCommentEntry {
  readonly __typename?: "CreditorCommentEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CreditorCommentEntry.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CreditorCommentEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CreditorCommentEntry.requires_attention
   */
  readonly requires_attention?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.CreditorCustomer
 */
export interface ICreditorCustomer {
  readonly __typename?: "CreditorCustomer";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CreditorCustomer._internal_sequence_id
   */
  readonly _internal_sequence_id?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CreditorCustomer.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CreditorCustomer.internal_person_id
   */
  readonly internal_person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CreditorCustomer.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CreditorCustomer.person
   */
  readonly person?: Maybe<IPerson>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.CreditorGroup
 */
export interface ICreditorGroup {
  readonly __typename?: "CreditorGroup";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CreditorGroup.creditor_ids
   */
  readonly creditor_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /** OneToMany */
  readonly creditors?: Maybe<ReadonlyArray<Maybe<ICreditor>>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CreditorGroup.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.CreditorGroup.name
   */
  readonly name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.CreditorTask
 */
export interface ICreditorTask {
  readonly __typename?: "CreditorTask";
  /** OneToOne */
  readonly creditor?: Maybe<ICreditor>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.debt_verification_request
   */
  readonly debt_verification_request?: Maybe<ICreditors_CreditorTask_DebtVerificationRequest>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.header
   */
  readonly header?: Maybe<ICreditors_CreditorTask_Header>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  readonly lastMediaReceivedDate?: Maybe<Scalars["JsDate"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.ActivationStatus
 */
export enum ICreditor_ActivationStatus {
  Active = "ACTIVE",
  ApplicationUnderReview = "APPLICATION_UNDER_REVIEW",
  NotApplied = "NOT_APPLIED",
  Offboarded = "OFFBOARDED",
  Rejected = "REJECTED"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Brand
 */
export interface ICreditor_Brand {
  readonly __typename?: "Creditor_Brand";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.address
   */
  readonly address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.brand_details
   */
  readonly brand_details?: Maybe<ICreditor_Brand_BrandDetails>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.brand_specific_cbr_negative_notice
   */
  readonly brand_specific_cbr_negative_notice?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.brand_specific_glb_notice_url
   */
  readonly brand_specific_glb_notice_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.brand_specific_has_sent_cbr_negative_notice
   */
  readonly brand_specific_has_sent_cbr_negative_notice?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.charge_off_status
   */
  readonly charge_off_status?: Maybe<IChargeOffStatus | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.disclaimer
   */
  readonly disclaimer?: Maybe<ICreditor_Brand_Disclaimer>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.logo_url
   */
  readonly logo_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.support_email_address
   */
  readonly support_email_address?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.type
   */
  readonly type?: Maybe<ICreditor_Brand_Type | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.website_url
   */
  readonly website_url?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Brand.BrandDetails
 */
export interface ICreditor_Brand_BrandDetails {
  readonly __typename?: "Creditor_Brand_BrandDetails";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.BrandDetails.merchant
   */
  readonly merchant?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.BrandDetails.original_creditor
   */
  readonly original_creditor?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.BrandDetails.product_type
   */
  readonly product_type?: Maybe<ICreditor_Brand_BrandDetails_ProductType | "%future added value">;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Brand.BrandDetails.ProductType
 */
export enum ICreditor_Brand_BrandDetails_ProductType {
  Card = "CARD",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Brand.Disclaimer
 */
export interface ICreditor_Brand_Disclaimer {
  readonly __typename?: "Creditor_Brand_Disclaimer";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.Disclaimer.initial
   */
  readonly initial?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Brand.Disclaimer.subsequent
   */
  readonly subsequent?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Brand.Type
 */
export enum ICreditor_Brand_Type {
  DoingBusinessAs = "DOING_BUSINESS_AS",
  Original = "ORIGINAL"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CollectionReportEmail
 */
export interface ICreditor_CollectionReportEmail {
  readonly __typename?: "Creditor_CollectionReportEmail";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CollectionReportEmail.email_address
   */
  readonly email_address?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings
 */
export interface ICreditor_CreditorSettings {
  readonly __typename?: "Creditor_CreditorSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.account_number_display_digits
   */
  readonly account_number_display_digits?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.allow_direct_to_creditor_payments_on_terminal_debts
   */
  readonly allow_direct_to_creditor_payments_on_terminal_debts?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.allow_total_to_collect_adjustments
   */
  readonly allow_total_to_collect_adjustments?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.attorney_representation_behavior
   */
  readonly attorney_representation_behavior?: Maybe<
    ICreditor_CreditorSettings_AttorneyRepresentationBehavior | "%future added value"
  >;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.attorney_representation_settings
   */
  readonly attorney_representation_settings?: Maybe<ICreditor_CreditorSettings_AttorneyRepresentationSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.automatic_notification_settings
   */
  readonly automatic_notification_settings?: Maybe<ICreditor_CreditorSettings_AutomaticNotificationSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.blacklist_settings
   */
  readonly blacklist_settings?: Maybe<ICreditor_CreditorSettings_BlacklistSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.capabilities
   */
  readonly capabilities: ReadonlyArray<Maybe<IAuth_CreditorCapability | "%future added value">>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.cfpb_itemization_settings
   */
  readonly cfpb_itemization_settings?: Maybe<ICreditor_CreditorSettings_CfpbItemizationSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.channels_requiring_opt_in
   */
  readonly channels_requiring_opt_in: ReadonlyArray<
    Maybe<ICommunications_Channel_Channel | "%future added value">
  >;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.client_type
   */
  readonly client_type?: Maybe<ICreditor_CreditorSettings_ClientType | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.comment_types
   */
  readonly comment_types: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.communication_settings
   */
  readonly communication_settings?: Maybe<ICreditor_CreditorSettings_CommunicationSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.consumer_call_limit
   */
  readonly consumer_call_limit?: Maybe<ICreditor_CreditorSettings_CallLimit>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.contract_type
   */
  readonly contract_type?: Maybe<ICreditor_CreditorSettings_ContractType | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.creditor_time_zone
   */
  readonly creditor_time_zone?: Maybe<ICreditor_CreditorSettings_CreditorTimeZone | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.creditor_value
   */
  readonly creditor_value?: Maybe<ICreditor_CreditorSettings_CreditorValue | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.custom_compliance_rules
   */
  readonly custom_compliance_rules: ReadonlyArray<
    Maybe<ICreditor_CreditorSettings_CustomComplianceRule | "%future added value">
  >;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.customer_call_authentication_settings
   */
  readonly customer_call_authentication_settings?: Maybe<ICustomerCallAuthenticationSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.debt_category
   */
  readonly debt_category?: Maybe<ICreditor_CreditorSettings_DebtCategory>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.debt_consolidator_settings
   */
  readonly debt_consolidator_settings?: Maybe<ICreditor_CreditorSettings_DebtConsolidatorSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.debt_replacement_settings
   */
  readonly debt_replacement_settings?: Maybe<ICreditor_CreditorSettings_DebtReplacementSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.default_charge_off_status
   */
  readonly default_charge_off_status?: Maybe<IChargeOffStatus | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.default_commerce_type
   */
  readonly default_commerce_type?: Maybe<ICommerceType | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.default_debt_type
   */
  readonly default_debt_type?: Maybe<IDebtType | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.default_product
   */
  readonly default_product?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.disable_phone_ownership_check
   */
  readonly disable_phone_ownership_check?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.dispute_settings
   */
  readonly dispute_settings?: Maybe<ICreditor_CreditorSettings_DisputeSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.email_ip_pool
   */
  readonly email_ip_pool?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.expected_return_days
   */
  readonly expected_return_days?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.glb_notice_url
   */
  readonly glb_notice_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.has_loans_less_than_one_month
   */
  readonly has_loans_less_than_one_month?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.initial_debt_status
   */
  readonly initial_debt_status?: Maybe<ICreditor_CreditorSettings_InitialDebtStatus | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.initial_letter_wait_period_days
   */
  readonly initial_letter_wait_period_days?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.is_debt_buyer
   */
  readonly is_debt_buyer?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.is_first_party_relationship
   */
  readonly is_first_party_relationship?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.is_tribal
   */
  readonly is_tribal?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.leave_terminal_when_un_pif_sif
   */
  readonly leave_terminal_when_un_pif_sif?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.lowest_previous_settlement_offered
   */
  readonly lowest_previous_settlement_offered?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.max_discount_percent
   */
  readonly max_discount_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.max_discount_policy
   */
  readonly max_discount_policy?: Maybe<ICreditor_MaxDiscountPolicy | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.max_payment_plan_length_in_months
   */
  readonly max_payment_plan_length_in_months?: Maybe<IOptionalInt32>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.number_of_previous_agencies
   */
  readonly number_of_previous_agencies?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.one_time_settlement_settings
   */
  readonly one_time_settlement_settings?: Maybe<ICreditor_CreditorSettings_OneTimeSettlementSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.out_of_statute_methodology
   */
  readonly out_of_statute_methodology?: Maybe<
    ICreditor_CreditorSettings_OutOfStatuteMethodology | "%future added value"
  >;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.payment_notification_setting
   */
  readonly payment_notification_setting?: Maybe<ICreditor_CreditorSettings_PaymentNotificationSetting>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.payment_plan_settings
   */
  readonly payment_plan_settings?: Maybe<ICreditor_CreditorSettings_PaymentPlanSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.placement_identifiers
   */
  readonly placement_identifiers: ReadonlyArray<Maybe<IKeyValue>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.pre_legal_settings
   */
  readonly pre_legal_settings?: Maybe<ICreditor_CreditorSettings_PreLegalSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.reporting_settings
   */
  readonly reporting_settings?: Maybe<ICreditor_CreditorSettings_ReportingSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.required_agent_disclosures
   */
  readonly required_agent_disclosures: ReadonlyArray<
    Maybe<IRequiredAgentDisclosures | "%future added value">
  >;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.required_initial_communication_channel
   */
  readonly required_initial_communication_channel?: Maybe<
    ICommunications_Channel_Channel | "%future added value"
  >;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.scra_scrub_setting
   */
  readonly scra_scrub_setting?: Maybe<ICreditor_CreditorSettings_ScrubSetting>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.servicing_type
   */
  readonly servicing_type?: Maybe<ICreditor_CreditorSettings_ServicingType | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.show_collection_reports_link
   */
  readonly show_collection_reports_link?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.soft_recall_settings
   */
  readonly soft_recall_settings?: Maybe<ICreditor_CreditorSettings_SoftRecallSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.special_call_restrictions
   */
  readonly special_call_restrictions?: Maybe<ICreditor_CreditorSettings_SpecialCallRestrictions>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.special_program_setting
   */
  readonly special_program_setting: ReadonlyArray<Maybe<ICreditor_CreditorSettings_SpecialProgramSetting>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.terminal_debt_scrub_setting
   */
  readonly terminal_debt_scrub_setting?: Maybe<ICreditor_CreditorSettings_ScrubSetting>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.validation_exceptions
   */
  readonly validation_exceptions?: Maybe<ICreditor_CreditorSettings_ValidationExceptions>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.validation_settings
   */
  readonly validation_settings?: Maybe<ICreditor_CreditorSettings_ValidationSettings>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.wire_transfer_setting
   */
  readonly wire_transfer_setting?: Maybe<ICreditor_CreditorSettings_WireTransferSetting>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.AttorneyRepresentationBehavior
 */
export enum ICreditor_CreditorSettings_AttorneyRepresentationBehavior {
  AttorneyOnly = "ATTORNEY_ONLY",
  HardStop = "HARD_STOP",
  HardStopCofUnrestricted = "HARD_STOP_COF_UNRESTRICTED",
  NoAction = "NO_ACTION",
  SoftStop = "SOFT_STOP"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.AttorneyRepresentationSettings
 */
export interface ICreditor_CreditorSettings_AttorneyRepresentationSettings {
  readonly __typename?: "Creditor_CreditorSettings_AttorneyRepresentationSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.AttorneyRepresentationSettings.attorney_debt_consolidator_behavior
   */
  readonly attorney_debt_consolidator_behavior?: Maybe<
    | ICreditor_CreditorSettings_AttorneyRepresentationSettings_AttorneyDebtConsolidatorBehavior
    | "%future added value"
  >;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.AttorneyRepresentationSettings.AttorneyDebtConsolidatorBehavior
 */
export enum ICreditor_CreditorSettings_AttorneyRepresentationSettings_AttorneyDebtConsolidatorBehavior {
  TreatAsAttorney = "TREAT_AS_ATTORNEY",
  TreatAsDebtConsolidator = "TREAT_AS_DEBT_CONSOLIDATOR"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.AutomaticNotificationSettings
 */
export interface ICreditor_CreditorSettings_AutomaticNotificationSettings {
  readonly __typename?: "Creditor_CreditorSettings_AutomaticNotificationSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.AutomaticNotificationSettings.cc_on_debt_verification_request_emails
   */
  readonly cc_on_debt_verification_request_emails: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.AutomaticNotificationSettings.default_notification_user_id
   */
  readonly default_notification_user_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.AutomaticNotificationSettings.email_on_debt_verification_request
   */
  readonly email_on_debt_verification_request?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.AutomaticNotificationSettings.id_theft_report_recipient_emails
   */
  readonly id_theft_report_recipient_emails: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.BlacklistSettings
 */
export interface ICreditor_CreditorSettings_BlacklistSettings {
  readonly __typename?: "Creditor_CreditorSettings_BlacklistSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.BlacklistSettings.do_not_blacklist_emails
   */
  readonly do_not_blacklist_emails?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.BlacklistSettings.do_not_blacklist_phone_numbers
   */
  readonly do_not_blacklist_phone_numbers?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.CallLimit
 */
export interface ICreditor_CreditorSettings_CallLimit {
  readonly __typename?: "Creditor_CreditorSettings_CallLimit";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.CallLimit.action_limit
   */
  readonly action_limit?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.CallLimit.day_interval
   */
  readonly day_interval?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.CfpbItemizationSettings
 */
export interface ICreditor_CreditorSettings_CfpbItemizationSettings {
  readonly __typename?: "Creditor_CreditorSettings_CfpbItemizationSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.CfpbItemizationSettings.bucket_mappings
   */
  readonly bucket_mappings: ReadonlyArray<
    Maybe<ICreditor_CreditorSettings_CfpbItemizationSettings_ItemizationBucketMapping>
  >;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.CfpbItemizationSettings.ItemizationBucketMapping
 */
export interface ICreditor_CreditorSettings_CfpbItemizationSettings_ItemizationBucketMapping {
  readonly __typename?: "Creditor_CreditorSettings_CfpbItemizationSettings_ItemizationBucketMapping";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.CfpbItemizationSettings.ItemizationBucketMapping.content_key
   */
  readonly content_key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.CfpbItemizationSettings.ItemizationBucketMapping.content_value
   */
  readonly content_value?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.ClientType
 */
export enum ICreditor_CreditorSettings_ClientType {
  BankLender = "BANK_LENDER",
  Commercial = "COMMERCIAL",
  DebtBuyerType = "DEBT_BUYER_TYPE",
  MedicalProvider = "MEDICAL_PROVIDER",
  NonBankLender = "NON_BANK_LENDER",
  PointOfSaleFinancing = "POINT_OF_SALE_FINANCING",
  RentToOwnAndLease = "RENT_TO_OWN_AND_LEASE",
  ServiceProvider = "SERVICE_PROVIDER",
  SubscriptionProvider = "SUBSCRIPTION_PROVIDER",
  TribalLender = "TRIBAL_LENDER",
  Utility = "UTILITY"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.CommunicationSettings
 */
export interface ICreditor_CreditorSettings_CommunicationSettings {
  readonly __typename?: "Creditor_CreditorSettings_CommunicationSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.CommunicationSettings.required_communication_clauses
   */
  readonly required_communication_clauses: ReadonlyArray<
    Maybe<IRequiredCommunicationClause | "%future added value">
  >;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.ContractType
 */
export enum ICreditor_CreditorSettings_ContractType {
  CreditCard = "CREDIT_CARD",
  Notarized = "NOTARIZED",
  SalesContract = "SALES_CONTRACT",
  Written = "WRITTEN"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.CreditorTimeZone
 */
export enum ICreditor_CreditorSettings_CreditorTimeZone {
  Cst = "CST",
  Est = "EST",
  Mst = "MST",
  Pst = "PST",
  Utc = "UTC"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.CreditorValue
 */
export enum ICreditor_CreditorSettings_CreditorValue {
  Enterprise = "ENTERPRISE",
  Essentials = "ESSENTIALS",
  Standard = "STANDARD"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.CustomComplianceRule
 */
export enum ICreditor_CreditorSettings_CustomComplianceRule {
  AmexOos = "AMEX_OOS"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory
 */
export interface ICreditor_CreditorSettings_DebtCategory {
  readonly __typename?: "Creditor_CreditorSettings_DebtCategory";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.credit
   */
  readonly credit?: Maybe<ICreditor_CreditorSettings_DebtCategory_CreditDebtCategory>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.other
   */
  readonly other?: Maybe<Scalars["Creditor_CreditorSettings_DebtCategory_OtherDebtCategory"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.product
   */
  readonly product?: Maybe<Scalars["Creditor_CreditorSettings_DebtCategory_ProductDebtCategory"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.service
   */
  readonly service?: Maybe<Scalars["Creditor_CreditorSettings_DebtCategory_ServiceDebtCategory"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.CreditDebtCategory
 */
export interface ICreditor_CreditorSettings_DebtCategory_CreditDebtCategory {
  readonly __typename?: "Creditor_CreditorSettings_DebtCategory_CreditDebtCategory";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.CreditDebtCategory.credit_type
   */
  readonly credit_type?: Maybe<
    ICreditor_CreditorSettings_DebtCategory_CreditDebtCategory_CreditType | "%future added value"
  >;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.DebtCategory.CreditDebtCategory.CreditType
 */
export enum ICreditor_CreditorSettings_DebtCategory_CreditDebtCategory_CreditType {
  Card = "CARD",
  Loan = "LOAN",
  Other = "OTHER"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.DebtConsolidatorSettings
 */
export interface ICreditor_CreditorSettings_DebtConsolidatorSettings {
  readonly __typename?: "Creditor_CreditorSettings_DebtConsolidatorSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtConsolidatorSettings.debt_consolidator_behavior
   */
  readonly debt_consolidator_behavior?: Maybe<
    ICreditor_CreditorSettings_DebtConsolidatorSettings_DebtConsolidatorBehavior | "%future added value"
  >;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.DebtConsolidatorSettings.DebtConsolidatorBehavior
 */
export enum ICreditor_CreditorSettings_DebtConsolidatorSettings_DebtConsolidatorBehavior {
  ContinueCollection = "CONTINUE_COLLECTION",
  HardStop = "HARD_STOP",
  SoftStop = "SOFT_STOP"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.DebtReplacementSettings
 */
export interface ICreditor_CreditorSettings_DebtReplacementSettings {
  readonly __typename?: "Creditor_CreditorSettings_DebtReplacementSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DebtReplacementSettings.allow_post_charge_off_balance_addition
   */
  readonly allow_post_charge_off_balance_addition?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings
 */
export interface ICreditor_CreditorSettings_DisputeSettings {
  readonly __typename?: "Creditor_CreditorSettings_DisputeSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.close_on_bankruptcy_notification
   */
  readonly close_on_bankruptcy_notification?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.close_on_fraud_notification
   */
  readonly close_on_fraud_notification?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.close_on_permanent_hardship_notification
   */
  readonly close_on_permanent_hardship_notification?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.close_on_scra_notification
   */
  readonly close_on_scra_notification?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.creditor_handles_military_disputes
   */
  readonly creditor_handles_military_disputes?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.days_to_pause_after_debt_verification
   */
  readonly days_to_pause_after_debt_verification?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.days_to_pause_after_invalid_dispute
   */
  readonly days_to_pause_after_invalid_dispute?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.dispute_creation_progression_id
   */
  readonly dispute_creation_progression_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.forward_written_disputes
   */
  readonly forward_written_disputes?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.return_debt_on_dispute
   */
  readonly return_debt_on_dispute?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.DisputeSettings.send_email_to_debtor_on_resolution
   */
  readonly send_email_to_debtor_on_resolution?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.InitialDebtStatus
 */
export enum ICreditor_CreditorSettings_InitialDebtStatus {
  PendingApproval = "PENDING_APPROVAL",
  PendingValidation = "PENDING_VALIDATION"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.OneTimeSettlementSettings
 */
export interface ICreditor_CreditorSettings_OneTimeSettlementSettings {
  readonly __typename?: "Creditor_CreditorSettings_OneTimeSettlementSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.OneTimeSettlementSettings.days_to_wait_until_settlement_offer
   */
  readonly days_to_wait_until_settlement_offer?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.OneTimeSettlementSettings.max_days_late_after_due_date
   */
  readonly max_days_late_after_due_date?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.OutOfStatuteMethodology
 */
export enum ICreditor_CreditorSettings_OutOfStatuteMethodology {
  Calculated = "CALCULATED",
  CreditorExplicit = "CREDITOR_EXPLICIT"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.PaymentNotificationSetting
 */
export interface ICreditor_CreditorSettings_PaymentNotificationSetting {
  readonly __typename?: "Creditor_CreditorSettings_PaymentNotificationSetting";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PaymentNotificationSetting.days_to_delay_pifsif_notifications
   */
  readonly days_to_delay_pifsif_notifications?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PaymentNotificationSetting.disallow_direct_to_cred_on_unstaggered_debt
   */
  readonly disallow_direct_to_cred_on_unstaggered_debt?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.PaymentPlanSettings
 */
export interface ICreditor_CreditorSettings_PaymentPlanSettings {
  readonly __typename?: "Creditor_CreditorSettings_PaymentPlanSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PaymentPlanSettings.end_date_padding_days
   */
  readonly end_date_padding_days?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PaymentPlanSettings.revocation_policy
   */
  readonly revocation_policy?: Maybe<ICreditor_CreditorSettings_PaymentPlanSettings_RevocationPolicy>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.PaymentPlanSettings.RevocationPolicy
 */
export interface ICreditor_CreditorSettings_PaymentPlanSettings_RevocationPolicy {
  readonly __typename?: "Creditor_CreditorSettings_PaymentPlanSettings_RevocationPolicy";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PaymentPlanSettings.RevocationPolicy.max_days_late_after_plan_ends
   */
  readonly max_days_late_after_plan_ends?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PaymentPlanSettings.RevocationPolicy.max_days_since_scheduled_payment
   */
  readonly max_days_since_scheduled_payment?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PaymentPlanSettings.RevocationPolicy.max_days_since_successful_payment
   */
  readonly max_days_since_successful_payment?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.PreLegalSettings
 */
export interface ICreditor_CreditorSettings_PreLegalSettings {
  readonly __typename?: "Creditor_CreditorSettings_PreLegalSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PreLegalSettings.all_debts_start_pre_legal
   */
  readonly all_debts_start_pre_legal?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.PreLegalSettings.payment_removes_pre_legal_flag
   */
  readonly payment_removes_pre_legal_flag?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.ReportingSettings
 */
export interface ICreditor_CreditorSettings_ReportingSettings {
  readonly __typename?: "Creditor_CreditorSettings_ReportingSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ReportingSettings.days_to_wait_for_sif_pif
   */
  readonly days_to_wait_for_sif_pif?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ReportingSettings.include_pending_payments_as_settled
   */
  readonly include_pending_payments_as_settled?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ReportingSettings.with_zero_change_failures
   */
  readonly with_zero_change_failures?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.ScrubSetting
 */
export interface ICreditor_CreditorSettings_ScrubSetting {
  readonly __typename?: "Creditor_CreditorSettings_ScrubSetting";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ScrubSetting.is_enabled
   */
  readonly is_enabled?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ScrubSetting.is_required
   */
  readonly is_required?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ScrubSetting.recheck_interval_hours
   */
  readonly recheck_interval_hours?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.ServicingType
 */
export enum ICreditor_CreditorSettings_ServicingType {
  Collections = "COLLECTIONS",
  Hardship = "HARDSHIP",
  Recovery = "RECOVERY"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings
 */
export interface ICreditor_CreditorSettings_SoftRecallSettings {
  readonly __typename?: "Creditor_CreditorSettings_SoftRecallSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings.days_between_soft_and_hard_recall
   */
  readonly days_between_soft_and_hard_recall?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings.days_qualifying_recent_engagement
   */
  readonly days_qualifying_recent_engagement?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings.days_qualifying_recent_payment
   */
  readonly days_qualifying_recent_payment?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings.is_enabled
   */
  readonly is_enabled?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings.keep_debt_if_in_dispute
   */
  readonly keep_debt_if_in_dispute?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings.keep_debt_if_in_payment_plan
   */
  readonly keep_debt_if_in_payment_plan?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings.keep_debt_if_recent_engagement
   */
  readonly keep_debt_if_recent_engagement?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SoftRecallSettings.keep_debt_if_recent_payment
   */
  readonly keep_debt_if_recent_payment?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.SpecialCallRestrictions
 */
export interface ICreditor_CreditorSettings_SpecialCallRestrictions {
  readonly __typename?: "Creditor_CreditorSettings_SpecialCallRestrictions";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SpecialCallRestrictions.block_calls_to_west_virginia
   */
  readonly block_calls_to_west_virginia?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.SpecialProgramSetting
 */
export interface ICreditor_CreditorSettings_SpecialProgramSetting {
  readonly __typename?: "Creditor_CreditorSettings_SpecialProgramSetting";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.SpecialProgramSetting.program_type
   */
  readonly program_type?: Maybe<ISpecialProgram_SpecialProgramType | "%future added value">;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.ValidationExceptions
 */
export interface ICreditor_CreditorSettings_ValidationExceptions {
  readonly __typename?: "Creditor_CreditorSettings_ValidationExceptions";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ValidationExceptions.allow_missing_email
   */
  readonly allow_missing_email?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.ValidationSettings
 */
export interface ICreditor_CreditorSettings_ValidationSettings {
  readonly __typename?: "Creditor_CreditorSettings_ValidationSettings";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ValidationSettings.run_always_valid_validation
   */
  readonly run_always_valid_validation?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ValidationSettings.run_deliverable_email_validation
   */
  readonly run_deliverable_email_validation?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ValidationSettings.run_state_and_zipcode_suppression_validation
   */
  readonly run_state_and_zipcode_suppression_validation?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ValidationSettings.suppressed_states
   */
  readonly suppressed_states: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.ValidationSettings.suppressed_zipcodes
   */
  readonly suppressed_zipcodes: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.CreditorSettings.WireTransferSetting
 */
export interface ICreditor_CreditorSettings_WireTransferSetting {
  readonly __typename?: "Creditor_CreditorSettings_WireTransferSetting";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.CreditorSettings.WireTransferSetting.daily_wire_transfer_limit
   */
  readonly daily_wire_transfer_limit?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.DocumentationAvailability
 */
export interface ICreditor_DocumentationAvailability {
  readonly __typename?: "Creditor_DocumentationAvailability";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.DocumentationAvailability.debt_substantiation
   */
  readonly debt_substantiation?: Maybe<ICreditor_DocumentationAvailability_DebtSubstantiation>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.DocumentationAvailability.DebtSubstantiation
 */
export interface ICreditor_DocumentationAvailability_DebtSubstantiation {
  readonly __typename?: "Creditor_DocumentationAvailability_DebtSubstantiation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.DocumentationAvailability.DebtSubstantiation.has_new_york
   */
  readonly has_new_york?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.IntegrationInformation
 */
export interface ICreditor_IntegrationInformation {
  readonly __typename?: "Creditor_IntegrationInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.IntegrationInformation.fico_info
   */
  readonly fico_info?: Maybe<ICreditor_IntegrationInformation_FicoInformation>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.IntegrationInformation.FicoInformation
 */
export interface ICreditor_IntegrationInformation_FicoInformation {
  readonly __typename?: "Creditor_IntegrationInformation_FicoInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.IntegrationInformation.FicoInformation.pnet_id
   */
  readonly pnet_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.MaxDiscountPolicy
 */
export enum ICreditor_MaxDiscountPolicy {
  CofDigitalCash = "COF_DIGITAL_CASH",
  CofDigitalHighValue = "COF_DIGITAL_HIGH_VALUE",
  CofDigitalLegal = "COF_DIGITAL_LEGAL",
  CofPrimaryDigitalLowAndUltraLow = "COF_PRIMARY_DIGITAL_LOW_AND_ULTRA_LOW",
  PncDaysSinceChargeOff = "PNC_DAYS_SINCE_CHARGE_OFF",
  PolicyDefault = "POLICY_DEFAULT"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.OBSOLETE_Portfolio.OBSOLETE_ClientServiceTier
 */
export enum ICreditor_Obsolete_Portfolio_Obsolete_ClientServiceTier {
  Core = "CORE",
  Enterprise = "ENTERPRISE",
  Essentials = "ESSENTIALS",
  UnknownTier = "UNKNOWN_TIER"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Policy
 */
export interface ICreditor_Policy {
  readonly __typename?: "Creditor_Policy";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.disable_automated_voicemail_drops
   */
  readonly disable_automated_voicemail_drops?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.disable_credit_agency_reporting
   */
  readonly disable_credit_agency_reporting?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.disable_letter
   */
  readonly disable_letter?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.disable_push_notification
   */
  readonly disable_push_notification?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.disable_sms
   */
  readonly disable_sms?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.disallowed_states
   */
  readonly disallowed_states: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.payment_options
   */
  readonly payment_options?: Maybe<ICreditor_Policy_PaymentOptions>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.template_policy_emails
   */
  readonly template_policy_emails?: Maybe<ICreditor_Policy_TemplatePolicy | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.template_policy_others
   */
  readonly template_policy_others?: Maybe<ICreditor_Policy_TemplatePolicy | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.template_policy_pages
   */
  readonly template_policy_pages?: Maybe<ICreditor_Policy_TemplatePolicy | "%future added value">;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Policy.PaymentOptions
 */
export interface ICreditor_Policy_PaymentOptions {
  readonly __typename?: "Creditor_Policy_PaymentOptions";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.PaymentOptions.ach_mode
   */
  readonly ach_mode?: Maybe<ICreditor_Policy_PaymentOptions_AchMode | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.PaymentOptions.disable_cards_in_payment_plans
   */
  readonly disable_cards_in_payment_plans?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.PaymentOptions.disable_credit_card
   */
  readonly disable_credit_card?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.PaymentOptions.disable_debit_card
   */
  readonly disable_debit_card?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.Policy.PaymentOptions.prefer_ach
   */
  readonly prefer_ach?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Policy.PaymentOptions.AchMode
 */
export enum ICreditor_Policy_PaymentOptions_AchMode {
  Disable = "DISABLE",
  Enable = "ENABLE",
  RequireRiskAssessment = "REQUIRE_RISK_ASSESSMENT"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.Policy.TemplatePolicy
 */
export enum ICreditor_Policy_TemplatePolicy {
  DefaultAllow = "DEFAULT_ALLOW",
  DefaultDeny = "DEFAULT_DENY"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.QuickBooksCredentials
 */
export interface ICreditor_QuickBooksCredentials {
  readonly __typename?: "Creditor_QuickBooksCredentials";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.QuickBooksCredentials.access_token
   */
  readonly access_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.QuickBooksCredentials.access_token_secret
   */
  readonly access_token_secret?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.QuickBooksCredentials.company_id
   */
  readonly company_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.QuickBooksCredentials.last_modified
   */
  readonly last_modified?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.QuickBooksCredentials.user_open_id
   */
  readonly user_open_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.VolumePlacedCommissionRate
 */
export interface ICreditor_VolumePlacedCommissionRate {
  readonly __typename?: "Creditor_VolumePlacedCommissionRate";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.VolumePlacedCommissionRate.accumulation_type
   */
  readonly accumulation_type?: Maybe<
    ICreditor_VolumePlacedCommissionRate_AccumulationType | "%future added value"
  >;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.VolumePlacedCommissionRate.tiers
   */
  readonly tiers: ReadonlyArray<Maybe<ICreditor_VolumePlacedCommissionRate_Tier>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.VolumePlacedCommissionRate.AccumulationType
 */
export enum ICreditor_VolumePlacedCommissionRate_AccumulationType {
  CalendarYear = "CALENDAR_YEAR",
  PrecedingCalendarYear = "PRECEDING_CALENDAR_YEAR",
  PrecedingYear = "PRECEDING_YEAR"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Creditor.VolumePlacedCommissionRate.Tier
 */
export interface ICreditor_VolumePlacedCommissionRate_Tier {
  readonly __typename?: "Creditor_VolumePlacedCommissionRate_Tier";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.VolumePlacedCommissionRate.Tier.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Creditor.VolumePlacedCommissionRate.Tier.commission_rate_basis_point
   */
  readonly commission_rate_basis_point?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.CustomerCallAuthentication
 */
export interface ICustomerCallAuthentication {
  readonly __typename?: "CustomerCallAuthentication";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.creditor_reference
   */
  readonly creditor_reference?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.current_address
   */
  readonly current_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.date_of_birth
   */
  readonly date_of_birth?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.direction
   */
  readonly direction?: Maybe<ICustomerCallAuthentication_Direction | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.email_address
   */
  readonly email_address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.full_name
   */
  readonly full_name?: Maybe<IPersonName>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.line_type
   */
  readonly line_type?: Maybe<ICustomerCallAuthentication_LineType | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.previous_address
   */
  readonly previous_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.product
   */
  readonly product?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.ssn_last4
   */
  readonly ssn_last4?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.ta_account_number
   */
  readonly ta_account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.transaction_date
   */
  readonly transaction_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthentication.year_of_birth
   */
  readonly year_of_birth?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.CustomerCallAuthenticationSettings
 */
export interface ICustomerCallAuthenticationSettings {
  readonly __typename?: "CustomerCallAuthenticationSettings";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthenticationSettings.criteria
   */
  readonly criteria: ReadonlyArray<Maybe<ICustomerCallAuthenticationSettings_CriterionOptions>>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.CustomerCallAuthenticationSettings.Criterion
 */
export enum ICustomerCallAuthenticationSettings_Criterion {
  CreditorReference = "CREDITOR_REFERENCE",
  CurrentAddress = "CURRENT_ADDRESS",
  DateOfBirth = "DATE_OF_BIRTH",
  EmailAddress = "EMAIL_ADDRESS",
  FullName = "FULL_NAME",
  PhoneNumber = "PHONE_NUMBER",
  PreviousAddress = "PREVIOUS_ADDRESS",
  Product = "PRODUCT",
  SsnLast4 = "SSN_LAST4",
  TaAccountNumber = "TA_ACCOUNT_NUMBER",
  TransactionDate = "TRANSACTION_DATE",
  TransactionId = "TRANSACTION_ID",
  YearOfBirth = "YEAR_OF_BIRTH"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.CustomerCallAuthenticationSettings.CriterionOptions
 */
export interface ICustomerCallAuthenticationSettings_CriterionOptions {
  readonly __typename?: "CustomerCallAuthenticationSettings_CriterionOptions";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.CustomerCallAuthenticationSettings.CriterionOptions.options
   */
  readonly options: ReadonlyArray<
    Maybe<ICustomerCallAuthenticationSettings_Criterion | "%future added value">
  >;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.CustomerCallAuthentication.Direction
 */
export enum ICustomerCallAuthentication_Direction {
  Inbound = "INBOUND",
  Outbound = "OUTBOUND"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.CustomerCallAuthentication.LineType
 */
export enum ICustomerCallAuthentication_LineType {
  Recorded = "RECORDED",
  Unrecorded = "UNRECORDED"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CustomerComplaintEntry
 */
export interface ICustomerComplaintEntry {
  readonly __typename?: "CustomerComplaintEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CustomerComplaintEntry.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CustomerComplaintEntry.desk_case_id
   */
  readonly desk_case_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CustomerComplaintEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.CustomerComplaintEntry.reason
   */
  readonly reason?: Maybe<ICustomerComplaintEntry_Reason | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.CustomerComplaintEntry.Reason
 */
export enum ICustomerComplaintEntry_Reason {
  ClientComplaint = "CLIENT_COMPLAINT",
  FraudOrIdTheft = "FRAUD_OR_ID_THEFT",
  GeneralOrOther = "GENERAL_OR_OTHER",
  Harassment = "HARASSMENT",
  HbContent = "HB_CONTENT",
  Policy = "POLICY",
  RepaymentOptions = "REPAYMENT_OPTIONS"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource
 */
export interface IDataSource {
  readonly __typename?: "DataSource";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DataSource.data_mining
   */
  readonly data_mining?: Maybe<IDataSource_DataMining>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DataSource.type
   */
  readonly type?: Maybe<IDataSource_Type | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DataSource.verification_status
   */
  readonly verification_status?: Maybe<IDataSource_VerificationStatus | "%future added value">;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource.DataMining
 */
export interface IDataSource_DataMining {
  readonly __typename?: "DataSource_DataMining";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DataSource.DataMining.email_append
   */
  readonly email_append?: Maybe<IDataSource_DataMining_EmailAppend>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DataSource.DataMining.phone_append
   */
  readonly phone_append?: Maybe<IDataSource_DataMining_PhoneAppend>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DataSource.DataMining.sub_type
   */
  readonly sub_type?: Maybe<IDataSource_DataMining_SubType | "%future added value">;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource.DataMining.EmailAppend
 */
export interface IDataSource_DataMining_EmailAppend {
  readonly __typename?: "DataSource_DataMining_EmailAppend";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DataSource.DataMining.EmailAppend.vendor
   */
  readonly vendor?: Maybe<IDataSource_DataMining_EmailAppend_Vendor | "%future added value">;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource.DataMining.EmailAppend.Vendor
 */
export enum IDataSource_DataMining_EmailAppend_Vendor {
  Anchor = "ANCHOR",
  DataServicesInc = "DATA_SERVICES_INC",
  Experian = "EXPERIAN",
  Neustar = "NEUSTAR",
  TowerData = "TOWER_DATA"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource.DataMining.PhoneAppend
 */
export interface IDataSource_DataMining_PhoneAppend {
  readonly __typename?: "DataSource_DataMining_PhoneAppend";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DataSource.DataMining.PhoneAppend.vendor
   */
  readonly vendor?: Maybe<IDataSource_DataMining_PhoneAppend_Vendor | "%future added value">;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource.DataMining.PhoneAppend.Vendor
 */
export enum IDataSource_DataMining_PhoneAppend_Vendor {
  Neustar = "NEUSTAR"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource.DataMining.SubType
 */
export enum IDataSource_DataMining_SubType {
  EmailAppend = "EMAIL_APPEND",
  EmailInference = "EMAIL_INFERENCE",
  Manual = "MANUAL",
  PhoneAppend = "PHONE_APPEND",
  Whois = "WHOIS"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource.Type
 */
export enum IDataSource_Type {
  Creditor = "CREDITOR",
  Customer = "CUSTOMER",
  DataMining = "DATA_MINING"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DataSource.VerificationStatus
 */
export enum IDataSource_VerificationStatus {
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  New = "NEW",
  Verified = "VERIFIED"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.Date
 */
export interface IDate {
  readonly __typename?: "Date";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.Date.day
   */
  readonly day?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.Date.month
   */
  readonly month?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.Date.year
   */
  readonly year?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DateAndTime
 */
export interface IDateAndTime {
  readonly __typename?: "DateAndTime";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DateAndTime.date
   */
  readonly date?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DateAndTime.time
   */
  readonly time?: Maybe<ITime>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.DateAndTime.time_zone_offset
   */
  readonly time_zone_offset?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt
 */
export interface IDebt {
  readonly __typename?: "Debt";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt._internal_information
   */
  readonly _internal_information?: Maybe<IDebt_InternalInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.account_open_timestamp
   */
  readonly account_open_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.additional_people
   */
  readonly additional_people: ReadonlyArray<Maybe<IDebt_AdditionalPerson>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.asset_description
   */
  readonly asset_description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.biller
   */
  readonly biller?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.calculated_out_of_statute_date
   */
  readonly calculated_out_of_statute_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.calculated_out_of_statute_flag
   */
  readonly calculated_out_of_statute_flag?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.charge_off_status
   */
  readonly charge_off_status?: Maybe<IChargeOffStatus | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.client_fields
   */
  readonly client_fields: ReadonlyArray<Maybe<IKeyValue>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.collections_stage_information
   */
  readonly collections_stage_information?: Maybe<IDebt_CollectionsStageInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.commerce_type
   */
  readonly commerce_type?: Maybe<ICommerceType | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.credit_bureau_reportable
   */
  readonly credit_bureau_reportable?: Maybe<Scalars["Boolean"]>;
  /** ForeignKey(foreignKey: creditor_id) */
  readonly creditor?: Maybe<ICreditor>;
  /** OneToMany */
  readonly creditorTasks?: Maybe<ReadonlyArray<Maybe<ICreditorTask>>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.creditor_brand_id
   */
  readonly creditor_brand_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.creditor_original_brand_id
   */
  readonly creditor_original_brand_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.date_of_purchase
   */
  readonly date_of_purchase?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.default_date
   */
  readonly default_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.default_timestamp
   */
  readonly default_timestamp?: Maybe<Scalars["Long"]>;
  /** OneToMany */
  readonly disputeClaims?: Maybe<ReadonlyArray<Maybe<IDisputeClaimEntry>>>;
  /** OneToMany */
  readonly disputeResponses?: Maybe<ReadonlyArray<Maybe<IDisputeResponseEntry>>>;
  /** OneToMany */
  readonly disputes?: Maybe<ReadonlyArray<Maybe<IDispute>>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.explicit_out_of_statute_date
   */
  readonly explicit_out_of_statute_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.explicit_out_of_statute_flag
   */
  readonly explicit_out_of_statute_flag?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.extended_information
   */
  readonly extended_information?: Maybe<IDebt_ExtendedInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.fin_serv_information
   */
  readonly fin_serv_information?: Maybe<IDebt_FinServInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.has_sent_cbr_negative_notice
   */
  readonly has_sent_cbr_negative_notice?: Maybe<Scalars["Boolean"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.initial_costs
   */
  readonly initial_costs?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.initial_fees
   */
  readonly initial_fees?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.initial_interest
   */
  readonly initial_interest?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.initial_payments_and_credits
   */
  readonly initial_payments_and_credits?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.initial_principal
   */
  readonly initial_principal?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.interest_rate
   */
  readonly interest_rate?: Maybe<IDebt_InterestRate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.is_initial_principal_before_payments_and_credits
   */
  readonly is_initial_principal_before_payments_and_credits?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.itemization_timestamp
   */
  readonly itemization_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.last_payment_amount
   */
  readonly last_payment_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.last_payment_timestamp
   */
  readonly last_payment_timestamp?: Maybe<Scalars["Long"]>;
  /** OneToMany */
  readonly log_entries?: Maybe<ReadonlyArray<Maybe<IDebtLogEntry>>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.max_discount_percent
   */
  readonly max_discount_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.max_payment_plan_length_in_months
   */
  readonly max_payment_plan_length_in_months?: Maybe<IOptionalInt32>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.original_creditor_account_number
   */
  readonly original_creditor_account_number?: Maybe<Scalars["String"]>;
  /** ForeignKey(foreignKey: person_id) */
  readonly person?: Maybe<IPerson>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.placement
   */
  readonly placement?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.placement_last_updated
   */
  readonly placement_last_updated?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.post_charge_off_information
   */
  readonly post_charge_off_information?: Maybe<IDebt_PostChargeOffInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.pre_charge_off_information
   */
  readonly pre_charge_off_information?: Maybe<IDebt_PreChargeOffInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.product
   */
  readonly product?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.send_cbr_negative_notice
   */
  readonly send_cbr_negative_notice?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.termination_date
   */
  readonly termination_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.transaction_id
   */
  readonly transaction_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.transaction_ip
   */
  readonly transaction_ip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.transaction_timestamp
   */
  readonly transaction_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.type
   */
  readonly type?: Maybe<IDebtType | "%future added value">;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.DebtAction
 */
export enum IDebtAction {
  MakeCommunication = "MAKE_COMMUNICATION",
  NewPartPayment = "NEW_PART_PAYMENT",
  NewPayment = "NEW_PAYMENT",
  NewPaymentPlan = "NEW_PAYMENT_PLAN"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.DebtCache
 */
export interface IDebtCache {
  readonly __typename?: "DebtCache";
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.DebtCache.initial_communication_debt_log_entry_id
   */
  readonly initial_communication_debt_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.DebtCache.initial_communication_timestamp
   */
  readonly initial_communication_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.DebtCache.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DebtConsolidatorRepresentationEntry
 */
export interface IDebtConsolidatorRepresentationEntry {
  readonly __typename?: "DebtConsolidatorRepresentationEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.communication_type
   */
  readonly communication_type?: Maybe<ICommunicationType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.contact_info
   */
  readonly contact_info?: Maybe<IContactInformation>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.debt_consolidator_type
   */
  readonly debt_consolidator_type?: Maybe<
    IDebtConsolidatorRepresentationEntry_DebtConsolidatorType | "%future added value"
  >;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.isAttorney
   */
  readonly isAttorney?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.offer_duration_in_months
   */
  readonly offer_duration_in_months?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.offer_percent_of_balance
   */
  readonly offer_percent_of_balance?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.received_power_of_attorney_documentation
   */
  readonly received_power_of_attorney_documentation?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.requested_by
   */
  readonly requested_by?: Maybe<IRequestedBy | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DebtConsolidatorRepresentationEntry.DebtConsolidatorType
 */
export enum IDebtConsolidatorRepresentationEntry_DebtConsolidatorType {
  AccelerateDebtManagementGroup = "ACCELERATE_DEBT_MANAGEMENT_GROUP",
  AmericanFreedomFinancial = "AMERICAN_FREEDOM_FINANCIAL",
  ChristianDebtRemovers = "CHRISTIAN_DEBT_REMOVERS",
  CorporateTurnaround = "CORPORATE_TURNAROUND",
  CreditSolutions = "CREDIT_SOLUTIONS",
  DebtSettlementUsa = "DEBT_SETTLEMENT_USA",
  FreedomFinancial = "FREEDOM_FINANCIAL",
  JasonHassGroup = "JASON_HASS_GROUP",
  LawOfficesOfMatthewGuthrie = "LAW_OFFICES_OF_MATTHEW_GUTHRIE",
  LibertySettlementGroup = "LIBERTY_SETTLEMENT_GROUP",
  NationalDebtRelief = "NATIONAL_DEBT_RELIEF",
  Other = "OTHER",
  PacificDebt = "PACIFIC_DEBT",
  RollLawOffice = "ROLL_LAW_OFFICE",
  SeidelmanPalmer = "SEIDELMAN_PALMER",
  UsDebtRelief = "US_DEBT_RELIEF"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DebtCreditorUpdateEntry
 */
export interface IDebtCreditorUpdateEntry {
  readonly __typename?: "DebtCreditorUpdateEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtCreditorUpdateEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtCreditorUpdateEntry.new_creditor_brand_id
   */
  readonly new_creditor_brand_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtCreditorUpdateEntry.new_creditor_id
   */
  readonly new_creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtCreditorUpdateEntry.new_creditor_original_brand_id
   */
  readonly new_creditor_original_brand_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtCreditorUpdateEntry.old_creditor_brand_id
   */
  readonly old_creditor_brand_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtCreditorUpdateEntry.old_creditor_id
   */
  readonly old_creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtCreditorUpdateEntry.old_creditor_original_brand_id
   */
  readonly old_creditor_original_brand_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.DebtFileS3Path
 */
export interface IDebtFileS3Path {
  readonly __typename?: "DebtFileS3Path";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.DebtFileS3Path.actual_file_key_name
   */
  readonly actual_file_key_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.DebtFileS3Path.file_line_number
   */
  readonly file_line_number?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.DebtFileS3Path.original_file_key_name
   */
  readonly original_file_key_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.DebtFileS3Path.sha1_fingerprint
   */
  readonly sha1_fingerprint?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DebtLogEntry
 */
export interface IDebtLogEntry {
  readonly __typename?: "DebtLogEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.attorney_representation
   */
  readonly attorney_representation?: Maybe<IAttorneyRepresentationEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.cancel_representation_entry
   */
  readonly cancel_representation_entry?: Maybe<ICancelRepresentationEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.capital_one_media_request
   */
  readonly capital_one_media_request?: Maybe<ICapitalOneMediaRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.capital_one_media_request_query
   */
  readonly capital_one_media_request_query?: Maybe<ICapitalOneMediaRequestQuery>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.capital_one_media_request_reply
   */
  readonly capital_one_media_request_reply?: Maybe<ICapitalOneMediaRequestReply>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.cfpb_itemization_entry
   */
  readonly cfpb_itemization_entry?: Maybe<ICfpbItemizationEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.collection_paused_entry
   */
  readonly collection_paused_entry?: Maybe<ICollectionPausedEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.compliance_check_entry
   */
  readonly compliance_check_entry?: Maybe<IComplianceCheckResultEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.create_call_request
   */
  readonly create_call_request?: Maybe<IExecutor_CreateCallRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.credit_balance_audit_request_entry
   */
  readonly credit_balance_audit_request_entry?: Maybe<ICreditBalanceAuditRequestEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.creditor_comment_entry
   */
  readonly creditor_comment_entry?: Maybe<ICreditorCommentEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.customer_call_authentication
   */
  readonly customer_call_authentication?: Maybe<ICustomerCallAuthentication>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.customer_complaint_entry
   */
  readonly customer_complaint_entry?: Maybe<ICustomerComplaintEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.debt_consolidator_representation
   */
  readonly debt_consolidator_representation?: Maybe<IDebtConsolidatorRepresentationEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.debt_creditor_update_entry
   */
  readonly debt_creditor_update_entry?: Maybe<IDebtCreditorUpdateEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.disclosure_provision_entry
   */
  readonly disclosure_provision_entry?: Maybe<IDisclosureProvisionEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.dispute_claim
   */
  readonly dispute_claim?: Maybe<IDisputeClaimEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.dispute_consent_entry
   */
  readonly dispute_consent_entry?: Maybe<IDisputeConsentEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.dispute_response
   */
  readonly dispute_response?: Maybe<IDisputeResponseEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.email_verification_attempt
   */
  readonly email_verification_attempt?: Maybe<IEmailVerificationAttempt>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.form_log_entry
   */
  readonly form_log_entry?: Maybe<IFormLogEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.historical_collection_restriction
   */
  readonly historical_collection_restriction?: Maybe<IHistoricalCollectionRestriction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.historical_dispute
   */
  readonly historical_dispute?: Maybe<IHistoricalDispute>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.historical_transaction
   */
  readonly historical_transaction?: Maybe<IHistoricalTransaction>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.inbound_sms_entry
   */
  readonly inbound_sms_entry?: Maybe<IInboundSmsEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.inbound_voice_entry
   */
  readonly inbound_voice_entry?: Maybe<IInboundVoiceEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.manual_sms_entry
   */
  readonly manual_sms_entry?: Maybe<IManualSmsEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.media_request_after_action
   */
  readonly media_request_after_action?: Maybe<IMediaRequestAfterAction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.override_max_discount_percent
   */
  readonly override_max_discount_percent?: Maybe<IOverrideMaxDiscountPercent>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.payment_log_entry
   */
  readonly payment_log_entry?: Maybe<IPaymentLogEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.pending_payment_entry
   */
  readonly pending_payment_entry?: Maybe<IPendingPaymentEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.placement_scrub_entry
   */
  readonly placement_scrub_entry?: Maybe<IPlacementScrubEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.pre_recall_notification_entry
   */
  readonly pre_recall_notification_entry?: Maybe<IPreRecallNotificationEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.pre_recall_response_entry
   */
  readonly pre_recall_response_entry?: Maybe<IPreRecallResponseEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.re_enable_payment_option_entry
   */
  readonly re_enable_payment_option_entry?: Maybe<IReEnablePaymentOptionEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.repeated_placement_entry
   */
  readonly repeated_placement_entry?: Maybe<IRepeatedPlacementEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.report_correction_entry
   */
  readonly report_correction_entry?: Maybe<IReportCorrectionEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.scrub_entry
   */
  readonly scrub_entry?: Maybe<IScrubEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_email_request
   */
  readonly send_email_request?: Maybe<IExecutor_SendEmailRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_email_to_debtor_request
   */
  readonly send_email_to_debtor_request?: Maybe<IExecutor_SendEmailToDebtorRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_email_to_support_request
   */
  readonly send_email_to_support_request?: Maybe<IExecutor_SendEmailToSupportRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_lead_sign_up_email_request
   */
  readonly send_lead_sign_up_email_request?: Maybe<IExecutor_SendLeadSignUpEmailRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_letter_to_debtor_request
   */
  readonly send_letter_to_debtor_request?: Maybe<IExecutor_SendLetterToDebtorRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_payment_thank_you_email_request
   */
  readonly send_payment_thank_you_email_request?: Maybe<IExecutor_SendPaymentThankYouEmailRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_push_notification_to_debtor_request
   */
  readonly send_push_notification_to_debtor_request?: Maybe<IExecutor_SendPushNotificationToDebtorRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_sms_to_debtor_request
   */
  readonly send_sms_to_debtor_request?: Maybe<IExecutor_SendSmsToDebtorRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_voicemail_request
   */
  readonly send_voicemail_request?: Maybe<IExecutor_SendVoicemailRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.send_warm_transfer_to_debtor_request
   */
  readonly send_warm_transfer_to_debtor_request?: Maybe<IExecutor_SendWarmTransferToDebtorRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.special_program_discussed_entry
   */
  readonly special_program_discussed_entry?: Maybe<ISpecialProgramDiscussed>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.special_program_optin_optout_entry
   */
  readonly special_program_optin_optout_entry?: Maybe<ISpecialProgramOptInOptOut>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.status_change_entry
   */
  readonly status_change_entry?: Maybe<IDebtStatusChangeEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.statute_of_limitations_update_entry
   */
  readonly statute_of_limitations_update_entry?: Maybe<IStatuteOfLimitationsUpdateEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.targeted_campaign_entry
   */
  readonly targeted_campaign_entry?: Maybe<ITargetedCampaignEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.terminal_debt_entry
   */
  readonly terminal_debt_entry?: Maybe<ITerminalDebtEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.total_to_collect_adjustment_log_entry
   */
  readonly total_to_collect_adjustment_log_entry?: Maybe<ITotalToCollectAdjustmentLogEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.unsubscribe_request
   */
  readonly unsubscribe_request?: Maybe<IUnsubscribe_UnsubscribeRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.validate_payment_options_attempt
   */
  readonly validate_payment_options_attempt?: Maybe<IValidatePaymentOptionsAttempt>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.validate_payment_options_result
   */
  readonly validate_payment_options_result?: Maybe<IValidatePaymentOptionsResult>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.validation_result_entry
   */
  readonly validation_result_entry?: Maybe<IValidationResultEntry>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntry.verification_status_change
   */
  readonly verification_status_change?: Maybe<IVerificationStatusChange>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DebtLogEntryHeader
 */
export interface IDebtLogEntryHeader {
  readonly __typename?: "DebtLogEntryHeader";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntryHeader.bulk_update_id
   */
  readonly bulk_update_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntryHeader.date_and_time
   */
  readonly date_and_time?: Maybe<IDateAndTime>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntryHeader.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntryHeader.source
   */
  readonly source?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtLogEntryHeader.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut
 */
export interface IDebtOut {
  readonly __typename?: "DebtOut";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut._include_debt
   */
  readonly _include_debt?: Maybe<IDebt>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.account_number_for_display
   */
  readonly account_number_for_display?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.account_open_timestamp
   */
  readonly account_open_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.accrued_interest
   */
  readonly accrued_interest?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.active_military_information
   */
  readonly active_military_information: ReadonlyArray<Maybe<IActiveMilitaryInformation>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.attorney_information
   */
  readonly attorney_information?: Maybe<IApi_DebtOut_AttorneyInformation>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.available_payment_methods
   */
  readonly available_payment_methods?: Maybe<IApi_DebtOut_AvailablePaymentMethods>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.balance
   */
  readonly balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.balance_details
   */
  readonly balance_details?: Maybe<IApi_DebtOut_BalanceDetails>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.balance_details_at_placement
   */
  readonly balance_details_at_placement?: Maybe<IApi_DebtOut_BalanceDetails>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.bankruptcy_information
   */
  readonly bankruptcy_information: ReadonlyArray<Maybe<IBankruptcyInformation>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.calculated_out_of_statute_date
   */
  readonly calculated_out_of_statute_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.calculated_out_of_statute_flag
   */
  readonly calculated_out_of_statute_flag?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.calculated_payment_offers
   */
  readonly calculated_payment_offers?: Maybe<IApi_PaymentOffersOut>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.collection_pauses
   */
  readonly collection_pauses: ReadonlyArray<Maybe<ICollectionPaused>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.collections_stage_information
   */
  readonly collections_stage_information?: Maybe<IApi_DebtOut_CollectionsStageInformation>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.commerce_type
   */
  readonly commerce_type?: Maybe<ICommerceType | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.communications_information
   */
  readonly communications_information?: Maybe<IApi_DebtOut_CommunicationsInformation>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.creditor
   */
  readonly creditor?: Maybe<IApi_CreditorOut>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.creditor_customer
   */
  readonly creditor_customer?: Maybe<IApi_CreditorCustomerOut>;
  /** OneToMany */
  readonly debt_states?: Maybe<ReadonlyArray<Maybe<IDebtState>>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.deceased_information
   */
  readonly deceased_information: ReadonlyArray<Maybe<IDeceasedInformation>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.dsc_information
   */
  readonly dsc_information?: Maybe<IApi_DebtOut_DscInformation>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.effective_max_discount_percent
   */
  readonly effective_max_discount_percent?: Maybe<IApi_DebtOut_OverridableMaxDiscountPercent>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.expected_return_date
   */
  readonly expected_return_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.feature_flags
   */
  readonly feature_flags?: Maybe<IFeatureFlags>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.fico_details
   */
  readonly fico_details?: Maybe<IApi_DebtOut_FicoDetails>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.initial_total
   */
  readonly initial_total?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.internal_configuration
   */
  readonly internal_configuration?: Maybe<IApi_DebtOut_InternalConfiguration>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.is_pre_legal
   */
  readonly is_pre_legal?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.is_proactive_email_permanently_denied
   */
  readonly is_proactive_email_permanently_denied?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.is_timebar_compliant
   */
  readonly is_timebar_compliant?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.location_created
   */
  readonly location_created?: Maybe<IApi_DebtOut_LocationCreated>;
  /** OneToMany */
  readonly log_entries?: Maybe<ReadonlyArray<Maybe<IDebtLogEntry>>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.max_discount_percent
   */
  readonly max_discount_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.max_payment_plan_length_in_months
   */
  readonly max_payment_plan_length_in_months?: Maybe<IOptionalInt32>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.min_settlement_percent
   */
  readonly min_settlement_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.oos_oral_disclosure
   */
  readonly oos_oral_disclosure?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.oos_oral_disclosure_spanish
   */
  readonly oos_oral_disclosure_spanish?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.oos_written_disclosure
   */
  readonly oos_written_disclosure?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.original_creditor_account_number_last4
   */
  readonly original_creditor_account_number_last4?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.payment_offers
   */
  readonly payment_offers?: Maybe<IPaymentOffers>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.payment_plan_id
   */
  readonly payment_plan_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.pending_risky_payment
   */
  readonly pending_risky_payment?: Maybe<IRisky_Payment_RiskyPayment>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.pending_status
   */
  readonly pending_status?: Maybe<IApi_DebtOut_PendingStatus | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.placement
   */
  readonly placement?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.placement_type
   */
  readonly placement_type?: Maybe<IApi_DebtOut_PlacementType | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.portfolio_information
   */
  readonly portfolio_information?: Maybe<IApi_DebtOut_Obsolete_PortfolioInformation>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.presentation_configuration
   */
  readonly presentation_configuration?: Maybe<IApi_DebtOut_PresentationConfiguration>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.prohibited_actions
   */
  readonly prohibited_actions: ReadonlyArray<Maybe<IProhibitedDebtAction>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.recent_transaction_info
   */
  readonly recent_transaction_info?: Maybe<IApi_DebtOut_RecentTransactionInfo>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.repeated_placements
   */
  readonly repeated_placements: ReadonlyArray<Maybe<IRepeatedPlacement>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.simplified_status
   */
  readonly simplified_status?: Maybe<IApi_SimplifiedStatus | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.special_program_out
   */
  readonly special_program_out: ReadonlyArray<Maybe<IApi_SpecialProgramOut>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.special_ui_treatment
   */
  readonly special_ui_treatment?: Maybe<IApi_DebtOut_SpecialUiTreatmentCategory | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.ssn_last4
   */
  readonly ssn_last4?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.status_change_enum_reason
   */
  readonly status_change_enum_reason?: Maybe<IApi_DebtOut_StatusChangeEnumReason | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.status_change_reason
   */
  readonly status_change_reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.transaction_id_last4
   */
  readonly transaction_id_last4?: Maybe<Scalars["String"]>;
  /** OneToMany */
  readonly unified_payment_entries?: Maybe<ReadonlyArray<Maybe<IUnifiedPaymentEntry>>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.unsettled_payments_amount
   */
  readonly unsettled_payments_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.unsubscribed_channels
   */
  readonly unsubscribed_channels: ReadonlyArray<
    Maybe<ICommunications_Channel_Channel | "%future added value">
  >;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState
 */
export interface IDebtState {
  readonly __typename?: "DebtState";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.experiment_context
   */
  readonly experiment_context?: Maybe<IDebtState_ExperimentContext>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.origination_info
   */
  readonly origination_info?: Maybe<IDebtState_OriginationInfo>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.part_payment
   */
  readonly part_payment?: Maybe<IDebtState_PartPayment>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.payment_plan
   */
  readonly payment_plan?: Maybe<IDebtState_PaymentPlan>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.payment_plan_email_notification
   */
  readonly payment_plan_email_notification?: Maybe<IDebtState_PaymentPlanEmailNotification>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.payment_plan_offer_OBSOLETE
   */
  readonly payment_plan_offer_OBSOLETE?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.payment_plan_v2
   */
  readonly payment_plan_v2?: Maybe<IDebtState_PaymentPlanV2>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.was_on_payment_plan_with_another_agency
   */
  readonly was_on_payment_plan_with_another_agency?: Maybe<
    Scalars["DebtState_WasOnPaymentPlanWithAnotherAgency"]
  >;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.ExperimentContext
 */
export interface IDebtState_ExperimentContext {
  readonly __typename?: "DebtState_ExperimentContext";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.debt_history_stats
   */
  readonly debt_history_stats?: Maybe<IDebtState_ExperimentContext_DebtHistoryStats>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.debt_strategy_context
   */
  readonly debt_strategy_context?: Maybe<IDebtState_ExperimentContext_DebtStrategyContext>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.email_context
   */
  readonly email_context?: Maybe<IDebtState_ExperimentContext_EmailContext>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.next_js_pages
   */
  readonly next_js_pages: ReadonlyArray<Maybe<IDebtState_NextJsPage | "%future added value">>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.page_display_options
   */
  readonly page_display_options?: Maybe<IDebtState_ExperimentContext_PageDisplayOptions>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.payment_constraints
   */
  readonly payment_constraints?: Maybe<IDebtState_ExperimentContext_PaymentConstraints>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.payment_preferences
   */
  readonly payment_preferences?: Maybe<IDebtState_ExperimentContext_PaymentPreferences>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.push_notification_landing_page_slug
   */
  readonly push_notification_landing_page_slug?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.unused
   */
  readonly unused?: Maybe<IDebtState_ExperimentContext_Unused>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.ExperimentContext.DebtHistoryStats
 */
export interface IDebtState_ExperimentContext_DebtHistoryStats {
  readonly __typename?: "DebtState_ExperimentContext_DebtHistoryStats";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.DebtHistoryStats.number_emails_delivered
   */
  readonly number_emails_delivered?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.DebtHistoryStats.number_emails_opened
   */
  readonly number_emails_opened?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.DebtHistoryStats.number_views_selection_page
   */
  readonly number_views_selection_page?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.DebtHistoryStats.number_website_visits
   */
  readonly number_website_visits?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.ExperimentContext.DebtStrategyContext
 */
export interface IDebtState_ExperimentContext_DebtStrategyContext {
  readonly __typename?: "DebtState_ExperimentContext_DebtStrategyContext";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.DebtStrategyContext.expected_return_timestamp
   */
  readonly expected_return_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.DebtStrategyContext.months_since_stagger
   */
  readonly months_since_stagger?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.DebtStrategyContext.months_until_return
   */
  readonly months_until_return?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.ExperimentContext.EmailContext
 */
export interface IDebtState_ExperimentContext_EmailContext {
  readonly __typename?: "DebtState_ExperimentContext_EmailContext";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.EmailContext.email_reply_subject_line
   */
  readonly email_reply_subject_line?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.EmailContext.show_account_number_header
   */
  readonly show_account_number_header?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions
 */
export interface IDebtState_ExperimentContext_PageDisplayOptions {
  readonly __typename?: "DebtState_ExperimentContext_PageDisplayOptions";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.redirect_logo_to_consumer_public_page
   */
  readonly redirect_logo_to_consumer_public_page?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_apollo_offer_banner
   */
  readonly show_apollo_offer_banner?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_legal_talk_off_banner
   */
  readonly show_legal_talk_off_banner?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_multiple_accounts_widget
   */
  readonly show_multiple_accounts_widget?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_oasis_offer_banner
   */
  readonly show_oasis_offer_banner?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_payment_options_cta
   */
  readonly show_payment_options_cta?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_push_notification_consent_request
   */
  readonly show_push_notification_consent_request?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_settlement_explicit_tax_banner
   */
  readonly show_settlement_explicit_tax_banner?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_settlement_offer_banner
   */
  readonly show_settlement_offer_banner?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PageDisplayOptions.show_urgent_settlement_offer_banner
   */
  readonly show_urgent_settlement_offer_banner?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.ExperimentContext.PaymentConstraints
 */
export interface IDebtState_ExperimentContext_PaymentConstraints {
  readonly __typename?: "DebtState_ExperimentContext_PaymentConstraints";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PaymentConstraints.disallow_ach
   */
  readonly disallow_ach?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.ExperimentContext.PaymentPreferences
 */
export interface IDebtState_ExperimentContext_PaymentPreferences {
  readonly __typename?: "DebtState_ExperimentContext_PaymentPreferences";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.PaymentPreferences.settlement_discount_percent
   */
  readonly settlement_discount_percent?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.ExperimentContext.Unused
 */
export interface IDebtState_ExperimentContext_Unused {
  readonly __typename?: "DebtState_ExperimentContext_Unused";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.ExperimentContext.Unused.do_not_use
   */
  readonly do_not_use?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.NextJsPage
 */
export enum IDebtState_NextJsPage {
  DisputeQuestion = "DISPUTE_QUESTION",
  Faqs = "FAQS",
  NyConsent = "NY_CONSENT",
  Offers = "OFFERS",
  Options = "OPTIONS",
  SettlementThankYou = "SETTLEMENT_THANK_YOU",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.OriginationInfo
 */
export interface IDebtState_OriginationInfo {
  readonly __typename?: "DebtState_OriginationInfo";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.OriginationInfo.agent_user_account_id
   */
  readonly agent_user_account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.OriginationInfo.payment_offer_id
   */
  readonly payment_offer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.OriginationInfo.settlement_calculation_basis
   */
  readonly settlement_calculation_basis?: Maybe<
    IDebtState_OriginationInfo_SettlementCalculationBasis | "%future added value"
  >;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.OriginationInfo.SettlementCalculationBasis
 */
export enum IDebtState_OriginationInfo_SettlementCalculationBasis {
  CurrentBalance = "CURRENT_BALANCE",
  TotalToCollectBalance = "TOTAL_TO_COLLECT_BALANCE"
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PartPayment
 */
export interface IDebtState_PartPayment {
  readonly __typename?: "DebtState_PartPayment";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PartPayment.due_date
   */
  readonly due_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PartPayment.is_settlement
   */
  readonly is_settlement?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PartPayment.new_amount
   */
  readonly new_amount?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PartPayment.pause
   */
  readonly pause?: Maybe<IDebtState_PartPayment_Pause>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PartPayment.settlement
   */
  readonly settlement?: Maybe<Scalars["DebtState_PartPayment_Settlement"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PartPayment.Pause
 */
export interface IDebtState_PartPayment_Pause {
  readonly __typename?: "DebtState_PartPayment_Pause";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PartPayment.Pause.pause_length_days
   */
  readonly pause_length_days?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlan
 */
export interface IDebtState_PaymentPlan {
  readonly __typename?: "DebtState_PaymentPlan";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlan.adjustment
   */
  readonly adjustment?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlan.amount
   */
  readonly amount?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlan.next_payment_date
   */
  readonly next_payment_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlan.payment_frequency
   */
  readonly payment_frequency?: Maybe<IDebtState_PaymentPlan_PaymentFrequency>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlanEmailNotification
 */
export interface IDebtState_PaymentPlanEmailNotification {
  readonly __typename?: "DebtState_PaymentPlanEmailNotification";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanEmailNotification.installment
   */
  readonly installment?: Maybe<IPaymentPlanApplication_Installment>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanEmailNotification.notification_type
   */
  readonly notification_type?: Maybe<
    IDebtState_PaymentPlanEmailNotification_NotificationType | "%future added value"
  >;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanEmailNotification.payment_plan_id
   */
  readonly payment_plan_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlanEmailNotification.NotificationType
 */
export enum IDebtState_PaymentPlanEmailNotification_NotificationType {
  AchWillResubmitWarning = "ACH_WILL_RESUBMIT_WARNING",
  ObsoletePaymentDueIn_1Week = "OBSOLETE_PAYMENT_DUE_IN_1_WEEK",
  Payment_1WeekLate = "PAYMENT_1_WEEK_LATE",
  Payment_1WeekLateInDispute = "PAYMENT_1_WEEK_LATE_IN_DISPUTE",
  Payment_2WeeksLate = "PAYMENT_2_WEEKS_LATE",
  Payment_3WeeksLate = "PAYMENT_3_WEEKS_LATE",
  PaymentDueIn_10Days = "PAYMENT_DUE_IN_10_DAYS",
  PaymentDueToday = "PAYMENT_DUE_TODAY",
  PlanRevoked = "PLAN_REVOKED",
  RecurringPaymentReminder = "RECURRING_PAYMENT_REMINDER"
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlanV2
 */
export interface IDebtState_PaymentPlanV2 {
  readonly __typename?: "DebtState_PaymentPlanV2";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.application
   */
  readonly application?: Maybe<IPaymentPlanApplication>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.conclusion
   */
  readonly conclusion?: Maybe<IDebtState_PaymentPlanV2_Conclusion>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.deferred_payment_type
   */
  readonly deferred_payment_type?: Maybe<
    IDebtState_PaymentPlanV2_DeferredPaymentType | "%future added value"
  >;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.is_recurring
   */
  readonly is_recurring?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.old_applications
   */
  readonly old_applications: ReadonlyArray<Maybe<IPaymentPlanApplication>>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.recurring_payment_tokens
   */
  readonly recurring_payment_tokens: ReadonlyArray<Maybe<IRecurringPaymentToken>>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.revocation
   */
  readonly revocation?: Maybe<IDebtState_PaymentPlanV2_Revocation>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlanV2.Conclusion
 */
export interface IDebtState_PaymentPlanV2_Conclusion {
  readonly __typename?: "DebtState_PaymentPlanV2_Conclusion";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.Conclusion.conclusion_reason
   */
  readonly conclusion_reason?: Maybe<
    IDebtState_PaymentPlanV2_Conclusion_ConclusionReason | "%future added value"
  >;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.Conclusion.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlanV2.Conclusion.ConclusionReason
 */
export enum IDebtState_PaymentPlanV2_Conclusion_ConclusionReason {
  FullyPaidOldPlan = "FULLY_PAID_OLD_PLAN",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlanV2.DeferredPaymentType
 */
export enum IDebtState_PaymentPlanV2_DeferredPaymentType {
  Pif = "PIF",
  Sif = "SIF"
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlanV2.Revocation
 */
export interface IDebtState_PaymentPlanV2_Revocation {
  readonly __typename?: "DebtState_PaymentPlanV2_Revocation";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.Revocation.date
   */
  readonly date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.Revocation.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlanV2.Revocation.revocation_reason
   */
  readonly revocation_reason?: Maybe<
    IDebtState_PaymentPlanV2_Revocation_RevocationReason | "%future added value"
  >;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlanV2.Revocation.RevocationReason
 */
export enum IDebtState_PaymentPlanV2_Revocation_RevocationReason {
  AlternativeResolution = "ALTERNATIVE_RESOLUTION",
  ClientRetracted = "CLIENT_RETRACTED",
  Closed = "CLOSED",
  InDispute = "IN_DISPUTE",
  MiscRemoval = "MISC_REMOVAL",
  NewPlanRequest = "NEW_PLAN_REQUEST",
  PlanBreakage = "PLAN_BREAKAGE",
  RequestedPlanCancellation = "REQUESTED_PLAN_CANCELLATION"
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlan.PaymentFrequency
 */
export interface IDebtState_PaymentPlan_PaymentFrequency {
  readonly __typename?: "DebtState_PaymentPlan_PaymentFrequency";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlan.PaymentFrequency.monthly
   */
  readonly monthly?: Maybe<IDebtState_PaymentPlan_PaymentFrequency_MonthlyPayment>;
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlan.PaymentFrequency.weekly
   */
  readonly weekly?: Maybe<IDebtState_PaymentPlan_PaymentFrequency_WeeklyPayment>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlan.PaymentFrequency.MonthlyPayment
 */
export interface IDebtState_PaymentPlan_PaymentFrequency_MonthlyPayment {
  readonly __typename?: "DebtState_PaymentPlan_PaymentFrequency_MonthlyPayment";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlan.PaymentFrequency.MonthlyPayment.day_of_month
   */
  readonly day_of_month?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/debt_states.proto
 * com.trueaccord.proto.DebtState.PaymentPlan.PaymentFrequency.WeeklyPayment
 */
export interface IDebtState_PaymentPlan_PaymentFrequency_WeeklyPayment {
  readonly __typename?: "DebtState_PaymentPlan_PaymentFrequency_WeeklyPayment";
  /**
   * File: collectr/proto/debt_states.proto
   * com.trueaccord.proto.DebtState.PaymentPlan.PaymentFrequency.WeeklyPayment.day_of_week
   */
  readonly day_of_week?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DebtStatusChangeEntry
 */
export interface IDebtStatusChangeEntry {
  readonly __typename?: "DebtStatusChangeEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtStatusChangeEntry.change_reason
   */
  readonly change_reason?: Maybe<IDebtStatusChangeEntry_ChangeReason | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtStatusChangeEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtStatusChangeEntry.new_status
   */
  readonly new_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtStatusChangeEntry.old_status
   */
  readonly old_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DebtStatusChangeEntry.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DebtStatusChangeEntry.ChangeReason
 */
export enum IDebtStatusChangeEntry_ChangeReason {
  Exhausted = "EXHAUSTED",
  FraudOrIdentityTheft = "FRAUD_OR_IDENTITY_THEFT",
  IncorrectPlacement = "INCORRECT_PLACEMENT",
  LowBalance = "LOW_BALANCE",
  Other = "OTHER",
  OutOfStatute = "OUT_OF_STATUTE",
  PermanentHardship = "PERMANENT_HARDSHIP"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.DebtType
 */
export enum IDebtType {
  DebtTypeCommercial = "DEBT_TYPE_COMMERCIAL",
  DebtTypeConsumer = "DEBT_TYPE_CONSUMER",
  DebtTypeNone = "DEBT_TYPE_NONE"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.AdditionalPerson
 */
export interface IDebt_AdditionalPerson {
  readonly __typename?: "Debt_AdditionalPerson";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.AdditionalPerson.business
   */
  readonly business?: Maybe<Scalars["Debt_AdditionalPerson_Business"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.AdditionalPerson.comaker
   */
  readonly comaker?: Maybe<IDebt_AdditionalPerson_Comaker>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.AdditionalPerson.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.AdditionalPerson.secondary_person
   */
  readonly secondary_person?: Maybe<Scalars["Debt_AdditionalPerson_SecondaryPerson"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.AdditionalPerson.Comaker
 */
export interface IDebt_AdditionalPerson_Comaker {
  readonly __typename?: "Debt_AdditionalPerson_Comaker";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.AdditionalPerson.Comaker.priority
   */
  readonly priority?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.CollectionsStageInformation
 */
export interface IDebt_CollectionsStageInformation {
  readonly __typename?: "Debt_CollectionsStageInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.CollectionsStageInformation.cycles_delinquent
   */
  readonly cycles_delinquent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.CollectionsStageInformation.delinquent_date
   */
  readonly delinquent_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.CollectionsStageInformation.next_due_date
   */
  readonly next_due_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.CollectionsStageInformation.product_description
   */
  readonly product_description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.CollectionsStageInformation.product_type
   */
  readonly product_type?: Maybe<IDebt_CollectionsStageInformation_ProductType | "%future added value">;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.CollectionsStageInformation.ProductType
 */
export enum IDebt_CollectionsStageInformation_ProductType {
  Auto = "AUTO",
  Card = "CARD",
  Hel = "HEL",
  Heloc = "HELOC",
  Unsecured = "UNSECURED"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.ExtendedInformation
 */
export interface IDebt_ExtendedInformation {
  readonly __typename?: "Debt_ExtendedInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.ExtendedInformation.bank_loan
   */
  readonly bank_loan?: Maybe<IDebt_ExtendedInformation_BankLoan>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.ExtendedInformation.BankLoan
 */
export interface IDebt_ExtendedInformation_BankLoan {
  readonly __typename?: "Debt_ExtendedInformation_BankLoan";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.ExtendedInformation.BankLoan.bank_name
   */
  readonly bank_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.ExtendedInformation.BankLoan.co_applicant
   */
  readonly co_applicant?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.FinServInformation
 */
export interface IDebt_FinServInformation {
  readonly __typename?: "Debt_FinServInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.FinServInformation.transfer_date
   */
  readonly transfer_date?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InterestRate
 */
export interface IDebt_InterestRate {
  readonly __typename?: "Debt_InterestRate";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InterestRate.rate_e6
   */
  readonly rate_e6?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation
 */
export interface IDebt_InternalInformation {
  readonly __typename?: "Debt_InternalInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.aim_info
   */
  readonly aim_info?: Maybe<IDebt_InternalInformation_AimInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.amex_information
   */
  readonly amex_information?: Maybe<IDebt_InternalInformation_AmexInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.environment
   */
  readonly environment?: Maybe<IEnvironment | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.fico_info
   */
  readonly fico_info?: Maybe<IDebt_InternalInformation_FicoInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.pnc_collections_information
   */
  readonly pnc_collections_information?: Maybe<IDebt_InternalInformation_PncCollectionsInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.resurgent_info
   */
  readonly resurgent_info?: Maybe<IDebt_InternalInformation_ResurgentInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.s3_paths
   */
  readonly s3_paths: ReadonlyArray<Maybe<IDebtFileS3Path>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.AIMInformation
 */
export interface IDebt_InternalInformation_AimInformation {
  readonly __typename?: "Debt_InternalInformation_AIMInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.initial_bucket_amounts
   */
  readonly initial_bucket_amounts: ReadonlyArray<Maybe<IDebt_InternalInformation_AimInformation_BucketInfo>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.initial_last_paid_amount
   */
  readonly initial_last_paid_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.initial_last_paid_date
   */
  readonly initial_last_paid_date?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.BucketInfo
 */
export interface IDebt_InternalInformation_AimInformation_BucketInfo {
  readonly __typename?: "Debt_InternalInformation_AIMInformation_BucketInfo";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.BucketInfo.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AIMInformation.BucketInfo.index
   */
  readonly index?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.AmexInformation
 */
export interface IDebt_InternalInformation_AmexInformation {
  readonly __typename?: "Debt_InternalInformation_AmexInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.apollo
   */
  readonly apollo?: Maybe<IDebt_InternalInformation_AmexInformation_Apollo>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.customer_id
   */
  readonly customer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.loan_code
   */
  readonly loan_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.oasis
   */
  readonly oasis?: Maybe<IDebt_InternalInformation_AmexInformation_Oasis>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.recoverer_code
   */
  readonly recoverer_code?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Apollo
 */
export interface IDebt_InternalInformation_AmexInformation_Apollo {
  readonly __typename?: "Debt_InternalInformation_AmexInformation_Apollo";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Apollo.accept_on_another_account
   */
  readonly accept_on_another_account?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Apollo.below_minimum_amount
   */
  readonly below_minimum_amount?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Apollo.card_balance
   */
  readonly card_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Apollo.global_amount
   */
  readonly global_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Apollo.other_ineligible
   */
  readonly other_ineligible?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Apollo.reinstatement
   */
  readonly reinstatement?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Apollo.reinstatement_date
   */
  readonly reinstatement_date?: Maybe<IDate>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Oasis
 */
export interface IDebt_InternalInformation_AmexInformation_Oasis {
  readonly __typename?: "Debt_InternalInformation_AmexInformation_Oasis";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Oasis.oasis_eligibility_flag
   */
  readonly oasis_eligibility_flag?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Oasis.oasis_flag
   */
  readonly oasis_flag?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Oasis.oasis_line_calculation
   */
  readonly oasis_line_calculation?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.AmexInformation.Oasis.oasis_scrambled_account_number
   */
  readonly oasis_scrambled_account_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.FicoInformation
 */
export interface IDebt_InternalInformation_FicoInformation {
  readonly __typename?: "Debt_InternalInformation_FicoInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.balance_too_low_to_collect
   */
  readonly balance_too_low_to_collect?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.brand_at_conversion
   */
  readonly brand_at_conversion?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.fraud_claim
   */
  readonly fraud_claim?: Maybe<IDebt_InternalInformation_FicoInformation_FraudClaim>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.pnet_id
   */
  readonly pnet_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.scoreBand
   */
  readonly scoreBand?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.specific_creditor_entity_name
   */
  readonly specific_creditor_entity_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim
 */
export interface IDebt_InternalInformation_FicoInformation_FraudClaim {
  readonly __typename?: "Debt_InternalInformation_FicoInformation_FraudClaim";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.bad_application
   */
  readonly bad_application?: Maybe<IDebt_InternalInformation_FicoInformation_FraudClaim_BadApplication>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.bad_transactions
   */
  readonly bad_transactions: ReadonlyArray<
    Maybe<IDebt_InternalInformation_FicoInformation_FraudClaim_BadTransaction>
  >;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.current_address
   */
  readonly current_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.current_phone_number
   */
  readonly current_phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.investigation_end_date
   */
  readonly investigation_end_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.investigation_start_date
   */
  readonly investigation_start_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.resolution_notes
   */
  readonly resolution_notes?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadApplication
 */
export interface IDebt_InternalInformation_FicoInformation_FraudClaim_BadApplication {
  readonly __typename?: "Debt_InternalInformation_FicoInformation_FraudClaim_BadApplication";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadApplication.address_when_account_was_opened
   */
  readonly address_when_account_was_opened?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadApplication.social_security_number
   */
  readonly social_security_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadTransaction
 */
export interface IDebt_InternalInformation_FicoInformation_FraudClaim_BadTransaction {
  readonly __typename?: "Debt_InternalInformation_FicoInformation_FraudClaim_BadTransaction";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadTransaction.item_name
   */
  readonly item_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadTransaction.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadTransaction.timeframe_of_last_card_use
   */
  readonly timeframe_of_last_card_use?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadTransaction.transaction_amount
   */
  readonly transaction_amount?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadTransaction.transaction_date
   */
  readonly transaction_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.FicoInformation.FraudClaim.BadTransaction.vendor_name
   */
  readonly vendor_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.PNCCollectionsInformation
 */
export interface IDebt_InternalInformation_PncCollectionsInformation {
  readonly __typename?: "Debt_InternalInformation_PNCCollectionsInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.PNCCollectionsInformation.location_code
   */
  readonly location_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.PNCCollectionsInformation.third_party_id
   */
  readonly third_party_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.PNCCollectionsInformation.vin
   */
  readonly vin?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.InternalInformation.ResurgentInformation
 */
export interface IDebt_InternalInformation_ResurgentInformation {
  readonly __typename?: "Debt_InternalInformation_ResurgentInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.ResurgentInformation.DEPRECATED_send_cbr_negative_notice
   */
  readonly DEPRECATED_send_cbr_negative_notice?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.ResurgentInformation.account_type
   */
  readonly account_type?: Maybe<IManagement_Systems_Resurgent_AccountType | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.InternalInformation.ResurgentInformation.send_oos_notice
   */
  readonly send_oos_notice?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.PostChargeOffInformation
 */
export interface IDebt_PostChargeOffInformation {
  readonly __typename?: "Debt_PostChargeOffInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PostChargeOffInformation.adjustments_made_since_charge_off
   */
  readonly adjustments_made_since_charge_off?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PostChargeOffInformation.balance_at_charge_off
   */
  readonly balance_at_charge_off?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PostChargeOffInformation.charge_off_timestamp
   */
  readonly charge_off_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PostChargeOffInformation.fees_accrued_since_charge_off
   */
  readonly fees_accrued_since_charge_off?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PostChargeOffInformation.interest_accrued_since_charge_off
   */
  readonly interest_accrued_since_charge_off?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PostChargeOffInformation.last_payment_amount_since_charge_off
   */
  readonly last_payment_amount_since_charge_off?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PostChargeOffInformation.non_interest_charges_accrued_since_charge_off
   */
  readonly non_interest_charges_accrued_since_charge_off?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PostChargeOffInformation.total_paid_since_charge_off
   */
  readonly total_paid_since_charge_off?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.PreChargeOffInformation
 */
export interface IDebt_PreChargeOffInformation {
  readonly __typename?: "Debt_PreChargeOffInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PreChargeOffInformation.cycles_delinquent
   */
  readonly cycles_delinquent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PreChargeOffInformation.delinquent_date
   */
  readonly delinquent_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PreChargeOffInformation.next_due_date
   */
  readonly next_due_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PreChargeOffInformation.product_description
   */
  readonly product_description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Debt.PreChargeOffInformation.product_type
   */
  readonly product_type?: Maybe<IDebt_PreChargeOffInformation_ProductType | "%future added value">;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Debt.PreChargeOffInformation.ProductType
 */
export enum IDebt_PreChargeOffInformation_ProductType {
  Auto = "AUTO",
  Card = "CARD",
  Unsecured = "UNSECURED"
}

/**
 * File: collectr/proto/features.proto
 * com.trueaccord.proto.DebtorFeatures
 */
export interface IDebtorFeatures {
  readonly __typename?: "DebtorFeatures";
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.DebtorFeatures.features
   */
  readonly features: ReadonlyArray<Maybe<IDebtorFeatures_FeatureSelection>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.DebtorFeatures.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/features.proto
 * com.trueaccord.proto.DebtorFeatures.FeatureSelection
 */
export interface IDebtorFeatures_FeatureSelection {
  readonly __typename?: "DebtorFeatures_FeatureSelection";
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.DebtorFeatures.FeatureSelection.feature_id
   */
  readonly feature_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.DebtorFeatures.FeatureSelection.feature_label_ids
   */
  readonly feature_label_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/terminal_debt_information.proto
 * com.trueaccord.proto.DeceasedInformation
 */
export interface IDeceasedInformation {
  readonly __typename?: "DeceasedInformation";
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.DeceasedInformation.authority
   */
  readonly authority?: Maybe<ITerminalDebt_Authority | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.DeceasedInformation.deceased
   */
  readonly deceased?: Maybe<ITerminalDebt_Deceased>;
  /**
   * File: collectr/proto/terminal_debt_information.proto
   * com.trueaccord.proto.DeceasedInformation.time_created
   */
  readonly time_created?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/desk/desk_com_etags.proto
 * com.trueaccord.proto.DeskEtag
 */
export interface IDeskEtag {
  readonly __typename?: "DeskEtag";
  /**
   * File: collectr/proto/desk/desk_com_etags.proto
   * com.trueaccord.proto.DeskEtag.endpoint
   */
  readonly endpoint?: Maybe<IDeskEtag_Endpoint | "%future added value">;
  /**
   * File: collectr/proto/desk/desk_com_etags.proto
   * com.trueaccord.proto.DeskEtag.entity_id
   */
  readonly entity_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/desk/desk_com_etags.proto
   * com.trueaccord.proto.DeskEtag.etag
   */
  readonly etag?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/desk/desk_com_etags.proto
   * com.trueaccord.proto.DeskEtag.instance_url
   */
  readonly instance_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/desk/desk_com_etags.proto
   * com.trueaccord.proto.DeskEtag.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/desk/desk_com_etags.proto
 * com.trueaccord.proto.DeskEtag.Endpoint
 */
export enum IDeskEtag_Endpoint {
  ListReplies = "ListReplies"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DisclosureProvisionEntry
 */
export interface IDisclosureProvisionEntry {
  readonly __typename?: "DisclosureProvisionEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.call_recording
   */
  readonly call_recording?: Maybe<Scalars["DisclosureProvisionEntry_CallRecording"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.card_application_disclosure
   */
  readonly card_application_disclosure?: Maybe<Scalars["DisclosureProvisionEntry_CardApplicationDisclosure"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.credit_bureau_reporting
   */
  readonly credit_bureau_reporting?: Maybe<Scalars["DisclosureProvisionEntry_CreditBureauReporting"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.form_1099_disclosure
   */
  readonly form_1099_disclosure?: Maybe<Scalars["DisclosureProvisionEntry_Form1099Disclosure"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.mini_miranda
   */
  readonly mini_miranda?: Maybe<Scalars["DisclosureProvisionEntry_MiniMiranda"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.non_liability
   */
  readonly non_liability?: Maybe<Scalars["DisclosureProvisionEntry_NonLiability"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.ok_to_speak
   */
  readonly ok_to_speak?: Maybe<Scalars["DisclosureProvisionEntry_OkToSpeak"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.out_of_statute
   */
  readonly out_of_statute?: Maybe<Scalars["DisclosureProvisionEntry_OutOfStatute"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.pre_legal
   */
  readonly pre_legal?: Maybe<Scalars["DisclosureProvisionEntry_PreLegal"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisclosureProvisionEntry.unreported_debt
   */
  readonly unreported_debt?: Maybe<Scalars["DisclosureProvisionEntry_UnreportedDebt"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.Dispute
 */
export interface IDispute {
  readonly __typename?: "Dispute";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.claim
   */
  readonly claim?: Maybe<IDisputeClaim>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.claim_timestamp
   */
  readonly claim_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.consent
   */
  readonly consent?: Maybe<IDisputeConsent>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.consent_timestamp
   */
  readonly consent_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.debt_verification_file_ids
   */
  readonly debt_verification_file_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.forwarded_files
   */
  readonly forwarded_files?: Maybe<IDispute_ForwardedFiles>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.rejected_files
   */
  readonly rejected_files: ReadonlyArray<Maybe<IDispute_RejectedFiles>>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.resolution
   */
  readonly resolution?: Maybe<IDisputeResolution>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.resolution_timestamp
   */
  readonly resolution_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.status
   */
  readonly status?: Maybe<IDispute_Status | "%future added value">;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim
 */
export interface IDisputeClaim {
  readonly __typename?: "DisputeClaim";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.additional_requirements
   */
  readonly additional_requirements: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.arc_info
   */
  readonly arc_info?: Maybe<IDisputeClaim_ArcDisputeInformation>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.bankruptcy
   */
  readonly bankruptcy?: Maybe<ITerminalDebt_Bankruptcy>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.custom
   */
  readonly custom?: Maybe<IDisputeClaim_CustomInputContainer>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.customer_comments
   */
  readonly customer_comments?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.fico_info
   */
  readonly fico_info?: Maybe<IDisputeClaim_FicoDisputeInformation>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.reason
   */
  readonly reason?: Maybe<IDisputeClaim_Reason | "%future added value">;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.resurgent_info
   */
  readonly resurgent_info?: Maybe<IDisputeClaim_ResurgentDisputeInformation>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.source_type
   */
  readonly source_type?: Maybe<IDisputeClaim_Source | "%future added value">;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.source_url
   */
  readonly source_url?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DisputeClaimEntry
 */
export interface IDisputeClaimEntry {
  readonly __typename?: "DisputeClaimEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeClaimEntry.claim
   */
  readonly claim?: Maybe<IDisputeClaim>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeClaimEntry.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeClaimEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ARCDisputeInformation
 */
export interface IDisputeClaim_ArcDisputeInformation {
  readonly __typename?: "DisputeClaim_ARCDisputeInformation";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ARCDisputeInformation.additional_source_info
   */
  readonly additional_source_info?: Maybe<
    IDisputeClaim_ArcDisputeInformation_AdditionalSourceInfo | "%future added value"
  >;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ARCDisputeInformation.creditor_dispute_id
   */
  readonly creditor_dispute_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ARCDisputeInformation.disputes_responsibility
   */
  readonly disputes_responsibility?: Maybe<
    Scalars["DisputeClaim_ARCDisputeInformation_InDisputeWithCreditor"]
  >;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ARCDisputeInformation.AdditionalSourceInfo
 */
export enum IDisputeClaim_ArcDisputeInformation_AdditionalSourceInfo {
  Attorney = "ATTORNEY",
  Debtor = "DEBTOR",
  DebtConsolidator = "DEBT_CONSOLIDATOR"
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.CustomInputContainer
 */
export interface IDisputeClaim_CustomInputContainer {
  readonly __typename?: "DisputeClaim_CustomInputContainer";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.CustomInputContainer.amount_fields
   */
  readonly amount_fields: ReadonlyArray<Maybe<IDisputeClaim_KeyAmountPair>>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.CustomInputContainer.date_fields
   */
  readonly date_fields: ReadonlyArray<Maybe<IDisputeClaim_KeyDatePair>>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.CustomInputContainer.reason_name
   */
  readonly reason_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.CustomInputContainer.text_fields
   */
  readonly text_fields: ReadonlyArray<Maybe<IKeyValue>>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation
 */
export interface IDisputeClaim_FicoDisputeInformation {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.application_fraud
   */
  readonly application_fraud?: Maybe<IDisputeClaim_FicoDisputeInformation_ApplicationFraud>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.bankruptcy
   */
  readonly bankruptcy?: Maybe<ITerminalDebt_Bankruptcy>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.claim_of_prior_paid_or_settled_in_full
   */
  readonly claim_of_prior_paid_or_settled_in_full?: Maybe<IDisputeClaim_FicoDisputeInformation_ClaimOfPriorPaidOrSettledInFull>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.incorrect_balance
   */
  readonly incorrect_balance?: Maybe<IDisputeClaim_FicoDisputeInformation_IncorrectBalance>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.incorrect_credit_bureau_status
   */
  readonly incorrect_credit_bureau_status?: Maybe<IDisputeClaim_FicoDisputeInformation_IncorrectCreditBureauStatus>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.missing_or_incorrect_payment
   */
  readonly missing_or_incorrect_payment?: Maybe<IDisputeClaim_FicoDisputeInformation_MissingOrIncorrectPayment>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.receipt_of_1099c_disputed
   */
  readonly receipt_of_1099c_disputed?: Maybe<IDisputeClaim_FicoDisputeInformation_ReceiptOf1099cDispute>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.should_not_have_been_charged_off
   */
  readonly should_not_have_been_charged_off?: Maybe<IDisputeClaim_FicoDisputeInformation_IncorrectChargeOff>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.transaction_fraud
   */
  readonly transaction_fraud?: Maybe<IDisputeClaim_FicoDisputeInformation_TransactionFraud>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.warm_transferred
   */
  readonly warm_transferred?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ApplicationFraud
 */
export interface IDisputeClaim_FicoDisputeInformation_ApplicationFraud {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_ApplicationFraud";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ApplicationFraud.current_address
   */
  readonly current_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ApplicationFraud.current_phone_number
   */
  readonly current_phone_number?: Maybe<IPhoneNumber>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ApplicationFraud.optional_information
   */
  readonly optional_information?: Maybe<IDisputeClaim_FicoDisputeInformation_ApplicationFraud_OptionalInformation>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ApplicationFraud.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ApplicationFraud.OptionalInformation
 */
export interface IDisputeClaim_FicoDisputeInformation_ApplicationFraud_OptionalInformation {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_ApplicationFraud_OptionalInformation";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ApplicationFraud.OptionalInformation.address_when_account_was_opened
   */
  readonly address_when_account_was_opened?: Maybe<IPostalAddress>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ClaimOfPriorPaidOrSettledInFull
 */
export interface IDisputeClaim_FicoDisputeInformation_ClaimOfPriorPaidOrSettledInFull {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_ClaimOfPriorPaidOrSettledInFull";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ClaimOfPriorPaidOrSettledInFull.common_fields
   */
  readonly common_fields?: Maybe<IDisputeClaim_FicoDisputeInformation_DisputeCommonFields>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ClaimOfPriorPaidOrSettledInFull.date_of_agreed_settlement
   */
  readonly date_of_agreed_settlement?: Maybe<IDate>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ClaimOfPriorPaidOrSettledInFull.final_payment_information
   */
  readonly final_payment_information?: Maybe<IDisputeClaim_FicoDisputeInformation_PaymentInformation>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.DisputeCommonFields
 */
export interface IDisputeClaim_FicoDisputeInformation_DisputeCommonFields {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_DisputeCommonFields";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.DisputeCommonFields.current_phone_number
   */
  readonly current_phone_number?: Maybe<IPhoneNumber>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.DisputeCommonFields.total_current_balance
   */
  readonly total_current_balance?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectBalance
 */
export interface IDisputeClaim_FicoDisputeInformation_IncorrectBalance {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_IncorrectBalance";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectBalance.amount_being_disputed
   */
  readonly amount_being_disputed?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectBalance.common_fields
   */
  readonly common_fields?: Maybe<IDisputeClaim_FicoDisputeInformation_DisputeCommonFields>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectBalance.description_of_disputed_balance
   */
  readonly description_of_disputed_balance?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectChargeOff
 */
export interface IDisputeClaim_FicoDisputeInformation_IncorrectChargeOff {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_IncorrectChargeOff";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectChargeOff.common_fields
   */
  readonly common_fields?: Maybe<IDisputeClaim_FicoDisputeInformation_DisputeCommonFields>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectChargeOff.date
   */
  readonly date?: Maybe<IDate>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectChargeOff.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectChargeOff.reason_for_dispute
   */
  readonly reason_for_dispute?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectChargeOff.timeframe
   */
  readonly timeframe?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectCreditBureauStatus
 */
export interface IDisputeClaim_FicoDisputeInformation_IncorrectCreditBureauStatus {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_IncorrectCreditBureauStatus";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectCreditBureauStatus.common_fields
   */
  readonly common_fields?: Maybe<IDisputeClaim_FicoDisputeInformation_DisputeCommonFields>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.IncorrectCreditBureauStatus.status_being_disputed
   */
  readonly status_being_disputed?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.MissingOrIncorrectPayment
 */
export interface IDisputeClaim_FicoDisputeInformation_MissingOrIncorrectPayment {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_MissingOrIncorrectPayment";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.MissingOrIncorrectPayment.common_fields
   */
  readonly common_fields?: Maybe<IDisputeClaim_FicoDisputeInformation_DisputeCommonFields>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.MissingOrIncorrectPayment.payment_information
   */
  readonly payment_information?: Maybe<IDisputeClaim_FicoDisputeInformation_PaymentInformation>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.PaymentInformation
 */
export interface IDisputeClaim_FicoDisputeInformation_PaymentInformation {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_PaymentInformation";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.PaymentInformation.payment_amount
   */
  readonly payment_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.PaymentInformation.payment_date
   */
  readonly payment_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.PaymentInformation.to_whom_was_payment_made_payable
   */
  readonly to_whom_was_payment_made_payable?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute
 */
export interface IDisputeClaim_FicoDisputeInformation_ReceiptOf1099cDispute {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_ReceiptOf1099cDispute";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute.common_fields
   */
  readonly common_fields?: Maybe<IDisputeClaim_FicoDisputeInformation_DisputeCommonFields>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute.error_type
   */
  readonly error_type?: Maybe<
    IDisputeClaim_FicoDisputeInformation_ReceiptOf1099cDispute_ErrorType | "%future added value"
  >;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute.optional_information
   */
  readonly optional_information?: Maybe<IDisputeClaim_FicoDisputeInformation_ReceiptOf1099cDispute_OptionalInformation>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute.year_of_1099c
   */
  readonly year_of_1099c?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute.ErrorType
 */
export enum IDisputeClaim_FicoDisputeInformation_ReceiptOf1099cDispute_ErrorType {
  IncorrectBalance = "INCORRECT_BALANCE",
  IssuanceError = "ISSUANCE_ERROR",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute.OptionalInformation
 */
export interface IDisputeClaim_FicoDisputeInformation_ReceiptOf1099cDispute_OptionalInformation {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_ReceiptOf1099cDispute_OptionalInformation";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute.OptionalInformation.comments
   */
  readonly comments?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.ReceiptOf1099cDispute.OptionalInformation.expected_balance
   */
  readonly expected_balance?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud
 */
export interface IDisputeClaim_FicoDisputeInformation_TransactionFraud {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_TransactionFraud";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.current_address
   */
  readonly current_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.current_phone_number
   */
  readonly current_phone_number?: Maybe<IPhoneNumber>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.date
   */
  readonly date?: Maybe<IDate>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.disputed_transactions
   */
  readonly disputed_transactions: ReadonlyArray<
    Maybe<IDisputeClaim_FicoDisputeInformation_TransactionFraud_Transaction>
  >;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.reason_for_dispute
   */
  readonly reason_for_dispute?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.timeframe
   */
  readonly timeframe?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.Transaction
 */
export interface IDisputeClaim_FicoDisputeInformation_TransactionFraud_Transaction {
  readonly __typename?: "DisputeClaim_FicoDisputeInformation_TransactionFraud_Transaction";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.Transaction.items
   */
  readonly items: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.Transaction.merchant
   */
  readonly merchant?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.Transaction.transaction_amount
   */
  readonly transaction_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.FicoDisputeInformation.TransactionFraud.Transaction.transaction_date
   */
  readonly transaction_date?: Maybe<IDate>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.KeyAmountPair
 */
export interface IDisputeClaim_KeyAmountPair {
  readonly __typename?: "DisputeClaim_KeyAmountPair";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.KeyAmountPair.key
   */
  readonly key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.KeyAmountPair.value
   */
  readonly value?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.KeyDatePair
 */
export interface IDisputeClaim_KeyDatePair {
  readonly __typename?: "DisputeClaim_KeyDatePair";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.KeyDatePair.key
   */
  readonly key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.KeyDatePair.value
   */
  readonly value?: Maybe<IDate>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.Reason
 */
export enum IDisputeClaim_Reason {
  ActiveMilitary = "ACTIVE_MILITARY",
  AlreadyPaid = "ALREADY_PAID",
  AlreadyPaidInFull = "ALREADY_PAID_IN_FULL",
  AlreadySettledInFull = "ALREADY_SETTLED_IN_FULL",
  AmountIncorrect = "AMOUNT_INCORRECT",
  Bankruptcy = "BANKRUPTCY",
  BillingInquiry = "BILLING_INQUIRY",
  CreditorSpecific = "CREDITOR_SPECIFIC",
  DebtInvalid = "DEBT_INVALID",
  DelinquencyFees = "DELINQUENCY_FEES",
  IdentityTheft = "IDENTITY_THEFT",
  MediaRequest = "MEDIA_REQUEST",
  MembershipFees = "MEMBERSHIP_FEES"
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation
 */
export interface IDisputeClaim_ResurgentDisputeInformation {
  readonly __typename?: "DisputeClaim_ResurgentDisputeInformation";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.additional_source_info
   */
  readonly additional_source_info?: Maybe<
    IDisputeClaim_ResurgentDisputeInformation_AdditionalSourceInfo | "%future added value"
  >;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.cease_received
   */
  readonly cease_received?: Maybe<Scalars["DisputeClaim_ResurgentDisputeInformation_CeaseReceived"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.claims_fraud
   */
  readonly claims_fraud?: Maybe<Scalars["DisputeClaim_ResurgentDisputeInformation_ClaimsFraud"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.creditor_dispute_id
   */
  readonly creditor_dispute_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.debt_substantiation
   */
  readonly debt_substantiation?: Maybe<
    Scalars["DisputeClaim_ResurgentDisputeInformation_DebtSubstantiation"]
  >;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.debt_verification
   */
  readonly debt_verification?: Maybe<Scalars["DisputeClaim_ResurgentDisputeInformation_DebtVerification"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.disputes_balance
   */
  readonly disputes_balance?: Maybe<IDisputeClaim_ResurgentDisputeInformation_DisputesBalance>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.disputes_responsibility
   */
  readonly disputes_responsibility?: Maybe<IDisputeClaim_ResurgentDisputeInformation_DisputesResponsibility>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.merchandise_dispute
   */
  readonly merchandise_dispute?: Maybe<IDisputeClaim_ResurgentDisputeInformation_MerchandiseDispute>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.other
   */
  readonly other?: Maybe<IDisputeClaim_ResurgentDisputeInformation_Other>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.paid_other_agency
   */
  readonly paid_other_agency?: Maybe<IDisputeClaim_ResurgentDisputeInformation_PaidOtherAgency>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.paid_prior_seller
   */
  readonly paid_prior_seller?: Maybe<IDisputeClaim_ResurgentDisputeInformation_PaidPriorSeller>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.soldiers_and_sailors
   */
  readonly soldiers_and_sailors?: Maybe<
    Scalars["DisputeClaim_ResurgentDisputeInformation_SoldiersAndSailors"]
  >;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.AdditionalSourceInfo
 */
export enum IDisputeClaim_ResurgentDisputeInformation_AdditionalSourceInfo {
  Attorney = "ATTORNEY",
  Debtor = "DEBTOR",
  DebtConsolidator = "DEBT_CONSOLIDATOR"
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.DisputesBalance
 */
export interface IDisputeClaim_ResurgentDisputeInformation_DisputesBalance {
  readonly __typename?: "DisputeClaim_ResurgentDisputeInformation_DisputesBalance";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.DisputesBalance.correct_balance
   */
  readonly correct_balance?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.DisputesResponsibility
 */
export interface IDisputeClaim_ResurgentDisputeInformation_DisputesResponsibility {
  readonly __typename?: "DisputeClaim_ResurgentDisputeInformation_DisputesResponsibility";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.DisputesResponsibility.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.MerchandiseDispute
 */
export interface IDisputeClaim_ResurgentDisputeInformation_MerchandiseDispute {
  readonly __typename?: "DisputeClaim_ResurgentDisputeInformation_MerchandiseDispute";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.MerchandiseDispute.additional_information
   */
  readonly additional_information?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.Other
 */
export interface IDisputeClaim_ResurgentDisputeInformation_Other {
  readonly __typename?: "DisputeClaim_ResurgentDisputeInformation_Other";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.Other.description
   */
  readonly description?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.PaidOtherAgency
 */
export interface IDisputeClaim_ResurgentDisputeInformation_PaidOtherAgency {
  readonly __typename?: "DisputeClaim_ResurgentDisputeInformation_PaidOtherAgency";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.PaidOtherAgency.date_paid
   */
  readonly date_paid?: Maybe<IDate>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.PaidOtherAgency.prior_payer_name
   */
  readonly prior_payer_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.PaidPriorSeller
 */
export interface IDisputeClaim_ResurgentDisputeInformation_PaidPriorSeller {
  readonly __typename?: "DisputeClaim_ResurgentDisputeInformation_PaidPriorSeller";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.PaidPriorSeller.date_paid
   */
  readonly date_paid?: Maybe<IDate>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeClaim.ResurgentDisputeInformation.PaidPriorSeller.prior_payer_name
   */
  readonly prior_payer_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeClaim.Source
 */
export enum IDisputeClaim_Source {
  Creditor = "CREDITOR",
  Email = "EMAIL",
  LandingPage = "LANDING_PAGE",
  Phone = "PHONE",
  SnailMail = "SNAIL_MAIL"
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeConsent
 */
export interface IDisputeConsent {
  readonly __typename?: "DisputeConsent";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeConsent.agent_user_account_id
   */
  readonly agent_user_account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeConsent.consent_to_email
   */
  readonly consent_to_email?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeConsent.source_type
   */
  readonly source_type?: Maybe<IDisputeClaim_Source | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DisputeConsentEntry
 */
export interface IDisputeConsentEntry {
  readonly __typename?: "DisputeConsentEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeConsentEntry.consent
   */
  readonly consent?: Maybe<IDisputeConsent>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeConsentEntry.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeConsentEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
}

export interface IDisputeContextHelp {
  readonly __typename?: "DisputeContextHelp";
  readonly documentExamples?: Maybe<Scalars["String"]>;
  readonly goal?: Maybe<Scalars["String"]>;
  readonly matchRequirements?: Maybe<Scalars["String"]>;
  readonly required?: Maybe<Scalars["String"]>;
  readonly stateRequirements?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeResolution
 */
export interface IDisputeResolution {
  readonly __typename?: "DisputeResolution";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeResolution.details
   */
  readonly details?: Maybe<IDisputeResolution_Details | "%future added value">;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeResolution.notes
   */
  readonly notes?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.DisputeResolution.resolution_type
   */
  readonly resolution_type?: Maybe<IDisputeResolution_ResolutionType | "%future added value">;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeResolution.Details
 */
export enum IDisputeResolution_Details {
  CreditorDeclinedToProvideDv = "CREDITOR_DECLINED_TO_PROVIDE_DV",
  CreditorTaskExpired = "CREDITOR_TASK_EXPIRED",
  CustomerCancelledDispute = "CUSTOMER_CANCELLED_DISPUTE",
  CustomerProvidedInformation = "CUSTOMER_PROVIDED_INFORMATION",
  DisputeOpenedInError = "DISPUTE_OPENED_IN_ERROR",
  DvUnverified = "DV_UNVERIFIED",
  DvVerified = "DV_VERIFIED"
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.DisputeResolution.ResolutionType
 */
export enum IDisputeResolution_ResolutionType {
  Cancelled = "CANCELLED",
  Extinguished = "EXTINGUISHED",
  Invalid = "INVALID",
  Unresolved = "UNRESOLVED",
  Valid = "VALID"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DisputeResponseEntry
 */
export interface IDisputeResponseEntry {
  readonly __typename?: "DisputeResponseEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeResponseEntry.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeResponseEntry.file_ids
   */
  readonly file_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeResponseEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeResponseEntry.resolution
   */
  readonly resolution?: Maybe<IDisputeResolution>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.DisputeResponseEntry.status_update
   */
  readonly status_update?: Maybe<IDisputeResponseEntry_StatusUpdate | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.DisputeResponseEntry.StatusUpdate
 */
export enum IDisputeResponseEntry_StatusUpdate {
  CreditorIsInvestigating = "CREDITOR_IS_INVESTIGATING",
  DeletedFiles = "DELETED_FILES",
  DvReceived = "DV_RECEIVED",
  DvRequested = "DV_REQUESTED",
  EtApprovedForwardedFiles = "ET_APPROVED_FORWARDED_FILES",
  EtRejectedFiles = "ET_REJECTED_FILES",
  EtUploadedFiles = "ET_UPLOADED_FILES",
  ManualReview = "MANUAL_REVIEW"
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.Dispute.ForwardedFiles
 */
export interface IDispute_ForwardedFiles {
  readonly __typename?: "Dispute_ForwardedFiles";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.ForwardedFiles.approval_timestamp
   */
  readonly approval_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.ForwardedFiles.file_ids
   */
  readonly file_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.Dispute.RejectedFiles
 */
export interface IDispute_RejectedFiles {
  readonly __typename?: "Dispute_RejectedFiles";
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.RejectedFiles.file_ids
   */
  readonly file_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/dispute.proto
   * com.trueaccord.proto.Dispute.RejectedFiles.reject_timestamp
   */
  readonly reject_timestamp?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/dispute.proto
 * com.trueaccord.proto.Dispute.Status
 */
export enum IDispute_Status {
  DvReceived = "DV_RECEIVED",
  DvRequested = "DV_REQUESTED",
  ManualReview = "MANUAL_REVIEW",
  New = "NEW",
  Resolved = "RESOLVED"
}

/**
 * File: collectr/proto/epay.proto
 * com.trueaccord.proto.EPayTransaction
 */
export interface IEPayTransaction {
  readonly __typename?: "EPayTransaction";
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.acs_url
   */
  readonly acs_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.auth_amount
   */
  readonly auth_amount?: Maybe<Scalars["Float"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.auth_code
   */
  readonly auth_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.avs_result
   */
  readonly avs_result?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.avs_result_code
   */
  readonly avs_result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.batch_num
   */
  readonly batch_num?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.batch_ref_num
   */
  readonly batch_ref_num?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.card_code_result
   */
  readonly card_code_result?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.card_code_result_code
   */
  readonly card_code_result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.card_level_result
   */
  readonly card_level_result?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.card_level_result_code
   */
  readonly card_level_result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.card_token
   */
  readonly card_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.conversion_rate
   */
  readonly conversion_rate?: Maybe<Scalars["Float"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.converted_amount
   */
  readonly converted_amount?: Maybe<Scalars["Float"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.converted_amount_currency
   */
  readonly converted_amount_currency?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.cust_num
   */
  readonly cust_num?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.error
   */
  readonly error?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.error_code
   */
  readonly error_code?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.fraud_profiler
   */
  readonly fraud_profiler?: Maybe<IEPayTransaction_FraudProfiler>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.invoice
   */
  readonly invoice?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.is_duplicate
   */
  readonly is_duplicate?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.payload
   */
  readonly payload?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.processor
   */
  readonly processor?: Maybe<IProcessor | "%future added value">;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.ref_num
   */
  readonly ref_num?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.remaining_balance
   */
  readonly remaining_balance?: Maybe<Scalars["Float"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.result
   */
  readonly result?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.result_code
   */
  readonly result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.status_code
   */
  readonly status_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.transaction_key
   */
  readonly transaction_key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.vpas_result_code
   */
  readonly vpas_result_code?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/epay.proto
 * com.trueaccord.proto.EPayTransaction.FraudProfiler
 */
export interface IEPayTransaction_FraudProfiler {
  readonly __typename?: "EPayTransaction_FraudProfiler";
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.FraudProfiler.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.FraudProfiler.response
   */
  readonly response?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.EPayTransaction.FraudProfiler.score
   */
  readonly score?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.EmailAddress
 */
export interface IEmailAddress {
  readonly __typename?: "EmailAddress";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddress.consent
   */
  readonly consent?: Maybe<IContactInformationConsent>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddress.data_source
   */
  readonly data_source?: Maybe<IDataSource>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddress.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddress.email_append
   */
  readonly email_append?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddress.is_unsubscribed
   */
  readonly is_unsubscribed?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddress.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddress.types
   */
  readonly types: ReadonlyArray<Maybe<IEmailAddress_Type | "%future added value">>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddress.validation
   */
  readonly validation?: Maybe<IEmailAddress_Validation | "%future added value">;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.EmailAddressWithName
 */
export interface IEmailAddressWithName {
  readonly __typename?: "EmailAddressWithName";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddressWithName.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.EmailAddressWithName.name
   */
  readonly name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.EmailAddress.Type
 */
export enum IEmailAddress_Type {
  Home = "HOME",
  UnknownType = "UNKNOWN_TYPE",
  Work = "WORK"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.EmailAddress.Validation
 */
export enum IEmailAddress_Validation {
  Deliverable = "DELIVERABLE",
  NotAttempted = "NOT_ATTEMPTED",
  Risky = "RISKY",
  Undeliverable = "UNDELIVERABLE"
}

/**
 * File: collectr/proto/email_throttler.proto
 * com.trueaccord.proto.EmailThrottler
 */
export interface IEmailThrottler {
  readonly __typename?: "EmailThrottler";
  /**
   * File: collectr/proto/email_throttler.proto
   * com.trueaccord.proto.EmailThrottler.hourly_rate
   */
  readonly hourly_rate?: Maybe<Scalars["Int"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/email_throttler.proto
   * com.trueaccord.proto.EmailThrottler.identifier
   */
  readonly identifier?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/email_throttler.proto
   * com.trueaccord.proto.EmailThrottler.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/email_throttler.proto
   * com.trueaccord.proto.EmailThrottler.pool_name
   */
  readonly pool_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/email_throttler.proto
   * com.trueaccord.proto.EmailThrottler.throttler_type
   */
  readonly throttler_type?: Maybe<IEmailThrottler_ThrottlerType | "%future added value">;
}

/**
 * File: collectr/proto/email_throttler.proto
 * com.trueaccord.proto.EmailThrottler.ThrottlerType
 */
export enum IEmailThrottler_ThrottlerType {
  Bursty = "BURSTY",
  Uniform = "UNIFORM",
  Zero = "ZERO"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.EmailVerificationAttempt
 */
export interface IEmailVerificationAttempt {
  readonly __typename?: "EmailVerificationAttempt";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.EmailVerificationAttempt.contact_information_type
   */
  readonly contact_information_type?: Maybe<
    IEmailVerificationAttempt_ContactInformationType | "%future added value"
  >;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.EmailVerificationAttempt.email_id
   */
  readonly email_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.EmailVerificationAttempt.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.EmailVerificationAttempt.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.EmailVerificationAttempt.referring_email_verification_event_id
   */
  readonly referring_email_verification_event_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.EmailVerificationAttempt.result
   */
  readonly result?: Maybe<IEmailVerificationAttempt_Result | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.EmailVerificationAttempt.ContactInformationType
 */
export enum IEmailVerificationAttempt_ContactInformationType {
  Address = "ADDRESS",
  PhoneNumber = "PHONE_NUMBER"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.EmailVerificationAttempt.Result
 */
export enum IEmailVerificationAttempt_Result {
  Failure = "FAILURE",
  Success = "SUCCESS"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Environment
 */
export enum IEnvironment {
  InternalTest = "INTERNAL_TEST",
  Live = "LIVE",
  Test = "TEST"
}

/**
 * File: collectr/proto/features.proto
 * com.trueaccord.proto.Feature
 */
export interface IFeature {
  readonly __typename?: "Feature";
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.Feature.display_name
   */
  readonly display_name?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.Feature.labels
   */
  readonly labels: ReadonlyArray<Maybe<IFeatureLabel>>;
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.Feature.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.FeatureFlags
 */
export interface IFeatureFlags {
  readonly __typename?: "FeatureFlags";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.FeatureFlags.flags
   */
  readonly flags: ReadonlyArray<Maybe<IFeatureFlags_FeatureFlag | "%future added value">>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.FeatureFlags.FeatureFlag
 */
export enum IFeatureFlags_FeatureFlag {
  CalculatedPaymentOffers = "CALCULATED_PAYMENT_OFFERS",
  NoOpFlagForTestingOnly = "NO_OP_FLAG_FOR_TESTING_ONLY",
  RegeLetterFallback = "REGE_LETTER_FALLBACK"
}

/**
 * File: collectr/proto/features.proto
 * com.trueaccord.proto.FeatureLabel
 */
export interface IFeatureLabel {
  readonly __typename?: "FeatureLabel";
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.FeatureLabel.display_name
   */
  readonly display_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/features.proto
   * com.trueaccord.proto.FeatureLabel.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

export interface IFileRequestReview {
  readonly __typename?: "FileRequestReview";
  readonly comment?: Maybe<Scalars["String"]>;
  readonly id: Scalars["ID"];
  readonly reviewTimestamp?: Maybe<Scalars["JsDate"]>;
  readonly reviewedByUserId?: Maybe<Scalars["String"]>;
  readonly status?: Maybe<IFileReviewStatus | "%future added value">;
}

export enum IFileReviewStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.FormLogEntry
 */
export interface IFormLogEntry {
  readonly __typename?: "FormLogEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.dialog_form_name
   */
  readonly dialog_form_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.form_fields
   */
  readonly form_fields: ReadonlyArray<Maybe<IFormLogEntry_FormField>>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.form_slug_name
   */
  readonly form_slug_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.helpdesk_meta_data
   */
  readonly helpdesk_meta_data?: Maybe<IFormLogEntry_HelpdeskMetaData>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.FormLogEntry.FormField
 */
export interface IFormLogEntry_FormField {
  readonly __typename?: "FormLogEntry_FormField";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.FormField.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.FormField.value
   */
  readonly value?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.FormLogEntry.HelpdeskMetaData
 */
export interface IFormLogEntry_HelpdeskMetaData {
  readonly __typename?: "FormLogEntry_HelpdeskMetaData";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.HelpdeskMetaData.labels
   */
  readonly labels: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.FormLogEntry.HelpdeskMetaData.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.Frequency
 */
export enum IFrequency {
  BiWeekly = "BI_WEEKLY",
  Custom = "CUSTOM",
  Monthly = "MONTHLY",
  Unknown = "UNKNOWN",
  Weekly = "WEEKLY"
}

/**
 * File: collectr/proto/epay.proto
 * com.trueaccord.proto.HealpayPaymentToken
 */
export interface IHealpayPaymentToken {
  readonly __typename?: "HealpayPaymentToken";
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.HealpayPaymentToken.card_token
   */
  readonly card_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.HealpayPaymentToken.epay_token
   */
  readonly epay_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.HealpayPaymentToken.payment_method_info
   */
  readonly payment_method_info?: Maybe<ICardPaymentMethodInfo>;
}

/**
 * File: collectr/proto/historical_transaction.proto
 * com.trueaccord.proto.HistoricalCollectionRestriction
 */
export interface IHistoricalCollectionRestriction {
  readonly __typename?: "HistoricalCollectionRestriction";
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalCollectionRestriction.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalCollectionRestriction.end_date
   */
  readonly end_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalCollectionRestriction.restriction_type
   */
  readonly restriction_type?: Maybe<IHistoricalCollectionRestriction_RestrictionType | "%future added value">;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalCollectionRestriction.source
   */
  readonly source?: Maybe<IHistoricalCollectionRestriction_Source | "%future added value">;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalCollectionRestriction.start_date
   */
  readonly start_date?: Maybe<IDate>;
}

/**
 * File: collectr/proto/historical_transaction.proto
 * com.trueaccord.proto.HistoricalCollectionRestriction.RestrictionType
 */
export enum IHistoricalCollectionRestriction_RestrictionType {
  DoNotCall = "DO_NOT_CALL",
  DoNotLetter = "DO_NOT_LETTER",
  SingleLetterThenSuspend = "SINGLE_LETTER_THEN_SUSPEND",
  SuspendAllCollections = "SUSPEND_ALL_COLLECTIONS"
}

/**
 * File: collectr/proto/historical_transaction.proto
 * com.trueaccord.proto.HistoricalCollectionRestriction.Source
 */
export enum IHistoricalCollectionRestriction_Source {
  Cease = "CEASE",
  Dispute = "DISPUTE",
  LegalAction = "LEGAL_ACTION",
  SellerRequest = "SELLER_REQUEST",
  Unknown = "UNKNOWN",
  WeatherDisaster = "WEATHER_DISASTER"
}

/**
 * File: collectr/proto/historical_transaction.proto
 * com.trueaccord.proto.HistoricalDispute
 */
export interface IHistoricalDispute {
  readonly __typename?: "HistoricalDispute";
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.end_date
   */
  readonly end_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.fico_info
   */
  readonly fico_info?: Maybe<IDisputeClaim_FicoDisputeInformation>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.investigated_by
   */
  readonly investigated_by?: Maybe<IHistoricalDispute_InvestigatedBy | "%future added value">;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.reason
   */
  readonly reason?: Maybe<IDisputeClaim_Reason | "%future added value">;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.resolution_type
   */
  readonly resolution_type?: Maybe<IDisputeResolution_ResolutionType | "%future added value">;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.resurgent_info
   */
  readonly resurgent_info?: Maybe<IDisputeClaim_ResurgentDisputeInformation>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.source
   */
  readonly source?: Maybe<IDisputeClaim_Source | "%future added value">;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalDispute.start_date
   */
  readonly start_date?: Maybe<IDate>;
}

/**
 * File: collectr/proto/historical_transaction.proto
 * com.trueaccord.proto.HistoricalDispute.InvestigatedBy
 */
export enum IHistoricalDispute_InvestigatedBy {
  Agency = "AGENCY",
  Creditor = "CREDITOR",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/historical_transaction.proto
 * com.trueaccord.proto.HistoricalTransaction
 */
export interface IHistoricalTransaction {
  readonly __typename?: "HistoricalTransaction";
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalTransaction.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalTransaction.date
   */
  readonly date?: Maybe<IDate>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalTransaction.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalTransaction.timeframe
   */
  readonly timeframe?: Maybe<IHistoricalTransaction_Timeframe | "%future added value">;
  /**
   * File: collectr/proto/historical_transaction.proto
   * com.trueaccord.proto.HistoricalTransaction.transaction_type
   */
  readonly transaction_type?: Maybe<IHistoricalTransaction_TransactionType | "%future added value">;
}

/**
 * File: collectr/proto/historical_transaction.proto
 * com.trueaccord.proto.HistoricalTransaction.Timeframe
 */
export enum IHistoricalTransaction_Timeframe {
  PostChargeOff = "POST_CHARGE_OFF",
  PreChargeOff = "PRE_CHARGE_OFF",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/historical_transaction.proto
 * com.trueaccord.proto.HistoricalTransaction.TransactionType
 */
export enum IHistoricalTransaction_TransactionType {
  Charge = "CHARGE",
  GenericCredit = "GENERIC_CREDIT",
  GenericDebit = "GENERIC_DEBIT",
  Payment = "PAYMENT",
  PaymentReversal = "PAYMENT_REVERSAL"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ImmutableRepeatedPlacement
 */
export interface IImmutableRepeatedPlacement {
  readonly __typename?: "ImmutableRepeatedPlacement";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ImmutableRepeatedPlacement.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ImmutableRepeatedPlacement.relationship
   */
  readonly relationship?: Maybe<IImmutableRepeatedPlacement_Relationship | "%future added value">;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ImmutableRepeatedPlacement.Relationship
 */
export enum IImmutableRepeatedPlacement_Relationship {
  RepeatedBy = "REPEATED_BY",
  RepeatOf = "REPEAT_OF"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.InboundSmsEntry
 */
export interface IInboundSmsEntry {
  readonly __typename?: "InboundSmsEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundSmsEntry.body
   */
  readonly body?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundSmsEntry.common_fields
   */
  readonly common_fields?: Maybe<ICommonInboundTransmissionFields>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundSmsEntry.desk_com_case_id
   */
  readonly desk_com_case_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundSmsEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundSmsEntry.num_media
   */
  readonly num_media?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundSmsEntry.sms_message_sid
   */
  readonly sms_message_sid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundSmsEntry.sms_sid
   */
  readonly sms_sid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundSmsEntry.sms_status
   */
  readonly sms_status?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.InboundVoiceEntry
 */
export interface IInboundVoiceEntry {
  readonly __typename?: "InboundVoiceEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.call_sid
   */
  readonly call_sid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.call_status
   */
  readonly call_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.called_address
   */
  readonly called_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.called_number
   */
  readonly called_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.caller_address
   */
  readonly caller_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.caller_number
   */
  readonly caller_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.common_fields
   */
  readonly common_fields?: Maybe<ICommonInboundTransmissionFields>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.desk_com_case_id
   */
  readonly desk_com_case_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.direction
   */
  readonly direction?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.InboundVoiceEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.KeyValue
 */
export interface IKeyValue {
  readonly __typename?: "KeyValue";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.KeyValue.key
   */
  readonly key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.KeyValue.value
   */
  readonly value?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/desk/desk_admin.proto
 * com.trueaccord.proto.Label
 */
export interface ILabel {
  readonly __typename?: "Label";
  /**
   * File: collectr/proto/desk/desk_admin.proto
   * com.trueaccord.proto.Label.color
   */
  readonly color?: Maybe<ILabel_Color | "%future added value">;
  /**
   * File: collectr/proto/desk/desk_admin.proto
   * com.trueaccord.proto.Label.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/desk/desk_admin.proto
   * com.trueaccord.proto.Label.enabled
   */
  readonly enabled?: Maybe<Scalars["Boolean"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/desk/desk_admin.proto
   * com.trueaccord.proto.Label.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/desk/desk_admin.proto
   * com.trueaccord.proto.Label.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/desk/desk_admin.proto
   * com.trueaccord.proto.Label.types
   */
  readonly types: ReadonlyArray<Maybe<ILabel_Type | "%future added value">>;
}

/**
 * File: collectr/proto/desk/desk_admin.proto
 * com.trueaccord.proto.Label.Color
 */
export enum ILabel_Color {
  Black = "BLACK",
  Blue = "BLUE",
  Brown = "BROWN",
  Default = "DEFAULT",
  Green = "GREEN",
  Grey = "GREY",
  Orange = "ORANGE",
  Pink = "PINK",
  Purple = "PURPLE",
  Red = "RED",
  White = "WHITE",
  Yellow = "YELLOW"
}

/**
 * File: collectr/proto/desk/desk_admin.proto
 * com.trueaccord.proto.Label.Type
 */
export enum ILabel_Type {
  Case = "CASE",
  Company = "COMPANY",
  Customer = "CUSTOMER",
  Macro = "MACRO",
  Opportunity = "OPPORTUNITY"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.LanguagePreferred
 */
export interface ILanguagePreferred {
  readonly __typename?: "LanguagePreferred";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.LanguagePreferred.language
   */
  readonly language?: Maybe<ILanguagePreferred_Language | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.LanguagePreferred.language_channel
   */
  readonly language_channel?: Maybe<ILanguagePreferred_LanguageChannel | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.LanguagePreferred.language_date
   */
  readonly language_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.LanguagePreferred.other_unsupported_language
   */
  readonly other_unsupported_language?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.LanguagePreferred.Language
 */
export enum ILanguagePreferred_Language {
  Arabic = "ARABIC",
  Bengali = "BENGALI",
  Cantonese = "CANTONESE",
  English = "ENGLISH",
  French = "FRENCH",
  HaitianCreole = "HAITIAN_CREOLE",
  Italian = "ITALIAN",
  Korean = "KOREAN",
  Mandarin = "MANDARIN",
  Other = "OTHER",
  Polish = "POLISH",
  Russian = "RUSSIAN",
  Spanish = "SPANISH"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.LanguagePreferred.LanguageChannel
 */
export enum ILanguagePreferred_LanguageChannel {
  Email = "EMAIL",
  Phone = "PHONE",
  SelfService = "SELF_SERVICE"
}

/**
 * File: collectr/proto/epay.proto
 * com.trueaccord.proto.LucentpayPaymentToken
 */
export interface ILucentpayPaymentToken {
  readonly __typename?: "LucentpayPaymentToken";
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.LucentpayPaymentToken.card_token
   */
  readonly card_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.LucentpayPaymentToken.epay_token
   */
  readonly epay_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.LucentpayPaymentToken.payment_method_info
   */
  readonly payment_method_info?: Maybe<ICardPaymentMethodInfo>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ManualSMSEntry
 */
export interface IManualSmsEntry {
  readonly __typename?: "ManualSMSEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ManualSMSEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ManualSMSEntry.mms_content
   */
  readonly mms_content?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ManualSMSEntry.opted_out
   */
  readonly opted_out?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ManualSMSEntry.recipient
   */
  readonly recipient?: Maybe<IPhoneNumber>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ManualSMSEntry.sender
   */
  readonly sender?: Maybe<IPhoneNumber>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ManualSMSEntry.sms_body
   */
  readonly sms_body?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.MediaRequestAfterAction
 */
export interface IMediaRequestAfterAction {
  readonly __typename?: "MediaRequestAfterAction";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.MediaRequestAfterAction.action
   */
  readonly action?: Maybe<IMediaRequestAfterAction_Action | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.MediaRequestAfterAction.is_action_for_vod_request
   */
  readonly is_action_for_vod_request?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.MediaRequestAfterAction.Action
 */
export enum IMediaRequestAfterAction_Action {
  DvSentToDebtor = "DV_SENT_TO_DEBTOR"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.MetaData
 */
export interface IMetaData {
  readonly __typename?: "MetaData";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.is_active
   */
  readonly is_active?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.is_primary
   */
  readonly is_primary?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.last_modified
   */
  readonly last_modified?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.time_created
   */
  readonly time_created?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.version_info
   */
  readonly version_info?: Maybe<IMetaData_VersionInformation>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.MetaData.VersionInformation
 */
export interface IMetaData_VersionInformation {
  readonly __typename?: "MetaData_VersionInformation";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.VersionInformation.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.VersionInformation.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MetaData.VersionInformation.version_number
   */
  readonly version_number?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.MonetaryAmount
 */
export interface IMonetaryAmount {
  readonly __typename?: "MonetaryAmount";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MonetaryAmount.amount
   */
  readonly amount?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.MonetaryAmount.currency
   */
  readonly currency?: Maybe<Scalars["String"]>;
}

export interface IMutation {
  readonly __typename?: "Mutation";
  /** Creates a comment for a task by id. */
  readonly insert_camunda_Comment?: Maybe<ICamunda_Comment>;
  /** Creates a new decision definition or a new version of the existing process definition when the diagram has changes. */
  readonly insert_camunda_DecisionDefinition?: Maybe<ICamunda_DecisionDefinition>;
  /** Creates a new process definition or a new version of the existing process definition when the diagram has changes. */
  readonly insert_camunda_ProcessDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** Starts a new process instance in the latest version of the process definition with the given key. */
  readonly insert_camunda_ProcessInstance?: Maybe<ICamunda_ProcessInstance>;
  /** Correlate message for process instance */
  readonly update_camunda_ProcessInstance_correlate?: Maybe<ICamunda_ProcessInstance>;
  /** Sets the assignee of a Task */
  readonly update_camunda_Task_assignee?: Maybe<ICamunda_Task>;
  /** Claim responsibility for a task: the given user is made assignee for the task. The difference with setAssignee(String, String) is that here a check is done if the task already has a user assigned to it. No check is done whether the user is known by the identity component. */
  readonly update_camunda_Task_claim?: Maybe<ICamunda_Task>;
  /** Marks a task as done and continues process execution. This method is typically called by a task list user interface after a task form has been submitted by the assignee and the required task parameters have been provided. */
  readonly update_camunda_Task_complete?: Maybe<ICamunda_ProcessInstance>;
  /** Update task variables */
  readonly update_camunda_Task_update?: Maybe<ICamunda_Task>;
}

export type IMutationInsert_Camunda_CommentArgs = {
  id: Scalars["ID"];
  message: Scalars["String"];
};

export type IMutationInsert_Camunda_DecisionDefinitionArgs = {
  diagram: Scalars["String"];
  resource?: InputMaybe<Scalars["String"]>;
};

export type IMutationInsert_Camunda_ProcessDefinitionArgs = {
  migrate?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  resources: ReadonlyArray<ICamunda_DeploymentResource>;
};

export type IMutationInsert_Camunda_ProcessInstanceArgs = {
  messageName?: InputMaybe<Scalars["String"]>;
  processDefinitionKey: Scalars["String"];
  variables?: InputMaybe<ReadonlyArray<InputMaybe<ICamunda_KeyValuePairInput>>>;
};

export type IMutationUpdate_Camunda_ProcessInstance_CorrelateArgs = {
  messageName: Scalars["String"];
  processInstanceId: Scalars["ID"];
  processVariables?: InputMaybe<ReadonlyArray<InputMaybe<ICamunda_KeyValuePairInput>>>;
};

export type IMutationUpdate_Camunda_Task_AssigneeArgs = {
  id: Scalars["ID"];
  userId: Scalars["ID"];
};

export type IMutationUpdate_Camunda_Task_ClaimArgs = {
  id: Scalars["ID"];
  userId?: InputMaybe<Scalars["ID"]>;
};

export type IMutationUpdate_Camunda_Task_CompleteArgs = {
  id: Scalars["ID"];
  variables?: InputMaybe<ReadonlyArray<InputMaybe<ICamunda_KeyValuePairInput>>>;
};

export type IMutationUpdate_Camunda_Task_UpdateArgs = {
  id: Scalars["ID"];
  variables?: InputMaybe<ReadonlyArray<InputMaybe<ICamunda_KeyValuePairInput>>>;
};

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.OptionalInt32
 */
export interface IOptionalInt32 {
  readonly __typename?: "OptionalInt32";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.OptionalInt32.value
   */
  readonly value?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.OverridableValue.OverrideType
 */
export enum IOverridableValue_OverrideType {
  CofExperiment = "COF_EXPERIMENT",
  Creditor = "CREDITOR",
  Debt = "DEBT",
  Heartbeat = "HEARTBEAT",
  NoOverride = "NO_OVERRIDE",
  Portfolio = "PORTFOLIO"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.OverrideMaxDiscountPercent
 */
export interface IOverrideMaxDiscountPercent {
  readonly __typename?: "OverrideMaxDiscountPercent";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.OverrideMaxDiscountPercent.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.OverrideMaxDiscountPercent.max_discount_percent
   */
  readonly max_discount_percent?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PaymentCardInfo
 */
export interface IPaymentCardInfo {
  readonly __typename?: "PaymentCardInfo";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.address_city
   */
  readonly address_city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.address_country
   */
  readonly address_country?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.address_line1
   */
  readonly address_line1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.address_line2
   */
  readonly address_line2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.address_state
   */
  readonly address_state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.address_zip
   */
  readonly address_zip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.bin_number
   */
  readonly bin_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.card_code
   */
  readonly card_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.deprecated_name
   */
  readonly deprecated_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.expiration_month
   */
  readonly expiration_month?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.expiration_year
   */
  readonly expiration_year?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.fingerprint
   */
  readonly fingerprint?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.last4
   */
  readonly last4?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.name
   */
  readonly name?: Maybe<IPersonName>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PaymentCardInfo.type
   */
  readonly type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry
 */
export interface IPaymentLogEntry {
  readonly __typename?: "PaymentLogEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry._internal_information
   */
  readonly _internal_information?: Maybe<IPaymentLogEntry_InternalInformation>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ach_now_transaction
   */
  readonly ach_now_transaction?: Maybe<IPaymentLogEntry_AchNowTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.added_by_user_id
   */
  readonly added_by_user_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.agent_user_account_id
   */
  readonly agent_user_account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.amount
   */
  readonly amount?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.authorization
   */
  readonly authorization?: Maybe<IPaymentLogEntry_Authorization>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.authorize_net_transaction
   */
  readonly authorize_net_transaction?: Maybe<IPaymentLogEntry_AuthorizeNetTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.balance_adjustment
   */
  readonly balance_adjustment?: Maybe<IPaymentLogEntry_BalanceAdjustment>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.charge_response
   */
  readonly charge_response?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.check_payment
   */
  readonly check_payment?: Maybe<IPaymentLogEntry_CheckPayment>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.commission_free
   */
  readonly commission_free?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.contribution
   */
  readonly contribution?: Maybe<IContribution>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.credit_card
   */
  readonly credit_card?: Maybe<IPaymentCardInfo>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.currency
   */
  readonly currency?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.delay_pifsif_notifications
   */
  readonly delay_pifsif_notifications?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.direct_payment_to_creditor
   */
  readonly direct_payment_to_creditor?: Maybe<IPaymentLogEntry_DirectPaymentToCreditor>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.error_code
   */
  readonly error_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.forced_reattempt
   */
  readonly forced_reattempt?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.forte_ach_transaction
   */
  readonly forte_ach_transaction?: Maybe<IPaymentLogEntry_ForteAchTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.healpay_ach_transaction
   */
  readonly healpay_ach_transaction?: Maybe<IPaymentLogEntry_HealpayAchTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.healpay_transaction
   */
  readonly healpay_transaction?: Maybe<IEPayTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.is_entered_by_agent
   */
  readonly is_entered_by_agent?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.is_interactive_payment
   */
  readonly is_interactive_payment?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.lucentpay_ach_transaction
   */
  readonly lucentpay_ach_transaction?: Maybe<IPaymentLogEntry_LucentpayAchTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.lucentpay_transaction
   */
  readonly lucentpay_transaction?: Maybe<IEPayTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.made_debt_terminal
   */
  readonly made_debt_terminal?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.manually_confirmed_transaction
   */
  readonly manually_confirmed_transaction?: Maybe<IPaymentLogEntry_ManuallyConfirmedTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.payment_ivr_session_id
   */
  readonly payment_ivr_session_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.payment_plan_id
   */
  readonly payment_plan_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.paypal_payment_notification
   */
  readonly paypal_payment_notification?: Maybe<IPaymentLogEntry_PayPalPaymentNotification>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.pending_payment_id
   */
  readonly pending_payment_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.promotional_credit
   */
  readonly promotional_credit?: Maybe<IPaymentLogEntry_PromotionalCredit>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.received_by_third_party
   */
  readonly received_by_third_party?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.recurring_payment_token_id
   */
  readonly recurring_payment_token_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.refund
   */
  readonly refund?: Maybe<IPaymentLogEntry_Refund>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.reversed_payment_log_entry_id
   */
  readonly reversed_payment_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.reward_transaction
   */
  readonly reward_transaction?: Maybe<IPaymentLogEntry_Reward>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.source_key_name
   */
  readonly source_key_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.spreedly_transaction
   */
  readonly spreedly_transaction?: Maybe<ISpreedlyTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.status
   */
  readonly status?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.stripe_token_id
   */
  readonly stripe_token_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.super_rewards_credit
   */
  readonly super_rewards_credit?: Maybe<IPaymentLogEntry_Reward_SuperRewardsCredit>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.transaction_fee
   */
  readonly transaction_fee?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.usaepay_transaction
   */
  readonly usaepay_transaction?: Maybe<IEPayTransaction>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction
 */
export interface IPaymentLogEntry_AchNowTransaction {
  readonly __typename?: "PaymentLogEntry_AchNowTransaction";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.batch_id
   */
  readonly batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.effective_date
   */
  readonly effective_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.raw_data
   */
  readonly raw_data?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.reference
   */
  readonly reference?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.return_code
   */
  readonly return_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.return_date
   */
  readonly return_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.return_reason
   */
  readonly return_reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.settle_date
   */
  readonly settle_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.source
   */
  readonly source?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.source_date
   */
  readonly source_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.status
   */
  readonly status?: Maybe<IPaymentLogEntry_AchNowTransaction_Status | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.submit_count
   */
  readonly submit_count?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.submit_date
   */
  readonly submit_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.transaction_id
   */
  readonly transaction_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.transaction_type
   */
  readonly transaction_type?: Maybe<
    IPaymentLogEntry_AchNowTransaction_TransactionType | "%future added value"
  >;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.Status
 */
export enum IPaymentLogEntry_AchNowTransaction_Status {
  BadData = "BAD_DATA",
  BatchWaiting = "BATCH_WAITING",
  Cancelled = "CANCELLED",
  Correction = "CORRECTION",
  Deleted = "DELETED",
  DoNotAch = "DO_NOT_ACH",
  Dupe = "DUPE",
  ErrorRecord = "ERROR_RECORD",
  NewTransaction = "NEW_TRANSACTION",
  OfacTrapped = "OFAC_TRAPPED",
  RejectedBatch = "REJECTED_BATCH",
  Resubmitted = "RESUBMITTED",
  Returned = "RETURNED",
  Reversal = "REVERSAL",
  SentToAch = "SENT_TO_ACH",
  SentToProcessor = "SENT_TO_PROCESSOR",
  Settled = "SETTLED",
  Voided = "VOIDED",
  WebInitiated = "WEB_INITIATED"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.AchNowTransaction.TransactionType
 */
export enum IPaymentLogEntry_AchNowTransaction_TransactionType {
  CcdCredit = "CCD_CREDIT",
  CcdDebit = "CCD_DEBIT",
  PpdCredit = "PPD_CREDIT",
  PpdDebit = "PPD_DEBIT",
  TelDebit = "TEL_DEBIT",
  WebDebit = "WEB_DEBIT"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.Authorization
 */
export interface IPaymentLogEntry_Authorization {
  readonly __typename?: "PaymentLogEntry_Authorization";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Authorization.is_voided
   */
  readonly is_voided?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Authorization.status
   */
  readonly status?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction
 */
export interface IPaymentLogEntry_AuthorizeNetTransaction {
  readonly __typename?: "PaymentLogEntry_AuthorizeNetTransaction";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.account_type
   */
  readonly account_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.auth_code
   */
  readonly auth_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.avs_result_code
   */
  readonly avs_result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.cavv_result_code
   */
  readonly cavv_result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.cvv_result_code
   */
  readonly cvv_result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.deprecated_cavv_result_code
   */
  readonly deprecated_cavv_result_code?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.ref_trans_id
   */
  readonly ref_trans_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.response_code
   */
  readonly response_code?: Maybe<
    IPaymentLogEntry_AuthorizeNetTransaction_ResponseCode | "%future added value"
  >;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.trans_hash
   */
  readonly trans_hash?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.trans_id
   */
  readonly trans_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.AuthorizeNetTransaction.ResponseCode
 */
export enum IPaymentLogEntry_AuthorizeNetTransaction_ResponseCode {
  Approved = "APPROVED",
  Declined = "DECLINED",
  Error = "ERROR",
  HeldForReview = "HELD_FOR_REVIEW"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment
 */
export interface IPaymentLogEntry_BalanceAdjustment {
  readonly __typename?: "PaymentLogEntry_BalanceAdjustment";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment.added_by
   */
  readonly added_by?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment.costs
   */
  readonly costs?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment.creditor_note
   */
  readonly creditor_note?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment.fees
   */
  readonly fees?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment.interest
   */
  readonly interest?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment.principal
   */
  readonly principal?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.BalanceAdjustment.transaction_reference
   */
  readonly transaction_reference?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.CheckPayment
 */
export interface IPaymentLogEntry_CheckPayment {
  readonly __typename?: "PaymentLogEntry_CheckPayment";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.CheckPayment.check_number
   */
  readonly check_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.CheckPayment.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.DirectPaymentToCreditor
 */
export interface IPaymentLogEntry_DirectPaymentToCreditor {
  readonly __typename?: "PaymentLogEntry_DirectPaymentToCreditor";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.DirectPaymentToCreditor.added_by
   */
  readonly added_by?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.DirectPaymentToCreditor.creditor_note
   */
  readonly creditor_note?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.DirectPaymentToCreditor.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.DirectPaymentToCreditor.payment_timestamp
   */
  readonly payment_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.DirectPaymentToCreditor.transaction_reference
   */
  readonly transaction_reference?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction
 */
export interface IPaymentLogEntry_ForteAchTransaction {
  readonly __typename?: "PaymentLogEntry_ForteAchTransaction";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.addenda_indicator
   */
  readonly addenda_indicator?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.additional_info
   */
  readonly additional_info?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.authorization_code
   */
  readonly authorization_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.customer_address
   */
  readonly customer_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.customer_email_address
   */
  readonly customer_email_address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.customer_name
   */
  readonly customer_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.customer_phone_number
   */
  readonly customer_phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.debit_credit
   */
  readonly debit_credit?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.external_customer_id
   */
  readonly external_customer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.external_transaction_id
   */
  readonly external_transaction_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.external_transaction_id2
   */
  readonly external_transaction_id2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.item_description
   */
  readonly item_description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.response_code
   */
  readonly response_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.response_date
   */
  readonly response_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.response_type
   */
  readonly response_type?: Maybe<IPaymentLogEntry_ForteAchTransaction_ResponseType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.trace_number
   */
  readonly trace_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.transaction_indicator
   */
  readonly transaction_indicator?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.transaction_source
   */
  readonly transaction_source?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.ForteAchTransaction.ResponseType
 */
export enum IPaymentLogEntry_ForteAchTransaction_ResponseType {
  ApprovedVerification = "APPROVED_VERIFICATION",
  BatchConfirm = "BATCH_CONFIRM",
  DeclinedVerification = "DECLINED_VERIFICATION",
  Funded = "FUNDED",
  Rejected = "REJECTED",
  Uncollectible = "UNCOLLECTIBLE",
  Unknown = "UNKNOWN",
  ZRejected = "Z_REJECTED"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.HealpayAchTransaction
 */
export interface IPaymentLogEntry_HealpayAchTransaction {
  readonly __typename?: "PaymentLogEntry_HealpayAchTransaction";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.HealpayAchTransaction.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.HealpayAchTransaction.check_status
   */
  readonly check_status?: Maybe<IPaymentLogEntry_HealpayAchTransaction_CheckStatus | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.HealpayAchTransaction.response
   */
  readonly response?: Maybe<IEPayTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.HealpayAchTransaction.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.HealpayAchTransaction.CheckStatus
 */
export enum IPaymentLogEntry_HealpayAchTransaction_CheckStatus {
  Returned = "RETURNED",
  Settled = "SETTLED",
  Voided = "VOIDED"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.InternalInformation
 */
export interface IPaymentLogEntry_InternalInformation {
  readonly __typename?: "PaymentLogEntry_InternalInformation";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.InternalInformation.aim_info
   */
  readonly aim_info?: Maybe<IAimInformation>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.LucentpayAchTransaction
 */
export interface IPaymentLogEntry_LucentpayAchTransaction {
  readonly __typename?: "PaymentLogEntry_LucentpayAchTransaction";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.LucentpayAchTransaction.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.LucentpayAchTransaction.check_status
   */
  readonly check_status?: Maybe<IPaymentLogEntry_LucentpayAchTransaction_CheckStatus | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.LucentpayAchTransaction.response
   */
  readonly response?: Maybe<IEPayTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.LucentpayAchTransaction.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.LucentpayAchTransaction.CheckStatus
 */
export enum IPaymentLogEntry_LucentpayAchTransaction_CheckStatus {
  Returned = "RETURNED",
  Settled = "SETTLED",
  Voided = "VOIDED"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.ManuallyConfirmedTransaction
 */
export interface IPaymentLogEntry_ManuallyConfirmedTransaction {
  readonly __typename?: "PaymentLogEntry_ManuallyConfirmedTransaction";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ManuallyConfirmedTransaction.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ManuallyConfirmedTransaction.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ManuallyConfirmedTransaction.payment_timestamp
   */
  readonly payment_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.ManuallyConfirmedTransaction.recurring_payment_token
   */
  readonly recurring_payment_token?: Maybe<IRecurringPaymentToken>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification
 */
export interface IPaymentLogEntry_PayPalPaymentNotification {
  readonly __typename?: "PaymentLogEntry_PayPalPaymentNotification";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.payer_address
   */
  readonly payer_address?: Maybe<IAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.payer_email
   */
  readonly payer_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.payer_id
   */
  readonly payer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.payer_status
   */
  readonly payer_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.payment_date
   */
  readonly payment_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.payment_type
   */
  readonly payment_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.receiver_email
   */
  readonly receiver_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.receiver_id
   */
  readonly receiver_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.receiver_residence_country
   */
  readonly receiver_residence_country?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.test_ipn
   */
  readonly test_ipn?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.transaction_id
   */
  readonly transaction_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PayPalPaymentNotification.transaction_type
   */
  readonly transaction_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.PromotionalCredit
 */
export interface IPaymentLogEntry_PromotionalCredit {
  readonly __typename?: "PaymentLogEntry_PromotionalCredit";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PromotionalCredit.added_by
   */
  readonly added_by?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PromotionalCredit.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.PromotionalCredit.transaction_id
   */
  readonly transaction_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.Refund
 */
export interface IPaymentLogEntry_Refund {
  readonly __typename?: "PaymentLogEntry_Refund";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Refund.added_by
   */
  readonly added_by?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Refund.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Refund.processed_by
   */
  readonly processed_by?: Maybe<IProcessingEntityEnumContainer_ProcessingEntity | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Refund.refund_reason
   */
  readonly refund_reason?: Maybe<IRefundReasonEnumContainer_RefundReason | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.Reward
 */
export interface IPaymentLogEntry_Reward {
  readonly __typename?: "PaymentLogEntry_Reward";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.crowd_flower
   */
  readonly crowd_flower?: Maybe<IPaymentLogEntry_Reward_CrowdFlower>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.super_rewards_credit
   */
  readonly super_rewards_credit?: Maybe<IPaymentLogEntry_Reward_SuperRewardsCredit>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.trial_pay_credit
   */
  readonly trial_pay_credit?: Maybe<IPaymentLogEntry_Reward_TrialPayCredit>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.Reward.CrowdFlower
 */
export interface IPaymentLogEntry_Reward_CrowdFlower {
  readonly __typename?: "PaymentLogEntry_Reward_CrowdFlower";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.CrowdFlower.conversion_id
   */
  readonly conversion_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.Reward.SuperRewardsCredit
 */
export interface IPaymentLogEntry_Reward_SuperRewardsCredit {
  readonly __typename?: "PaymentLogEntry_Reward_SuperRewardsCredit";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.SuperRewardsCredit.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.SuperRewardsCredit.new_credits
   */
  readonly new_credits?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.SuperRewardsCredit.oid
   */
  readonly oid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.SuperRewardsCredit.total_credits
   */
  readonly total_credits?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.SuperRewardsCredit.uid
   */
  readonly uid?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit
 */
export interface IPaymentLogEntry_Reward_TrialPayCredit {
  readonly __typename?: "PaymentLogEntry_Reward_TrialPayCredit";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.category
   */
  readonly category?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.country
   */
  readonly country?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.oid
   */
  readonly oid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.order_date
   */
  readonly order_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.product_id
   */
  readonly product_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.product_price
   */
  readonly product_price?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.revenue
   */
  readonly revenue?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.reward_amount
   */
  readonly reward_amount?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.sid
   */
  readonly sid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.traffic_source_id
   */
  readonly traffic_source_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.transaction_type
   */
  readonly transaction_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PaymentLogEntry.Reward.TrialPayCredit.zip
   */
  readonly zip?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/payment_offers.proto
 * com.trueaccord.proto.PaymentOffers
 */
export interface IPaymentOffers {
  readonly __typename?: "PaymentOffers";
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.active_plan_max_installment_length
   */
  readonly active_plan_max_installment_length?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.active_plan_min_installment_length
   */
  readonly active_plan_min_installment_length?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.available_agent_settlement
   */
  readonly available_agent_settlement?: Maybe<IPaymentOffers_SettlementOffer>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.available_pp_configurations
   */
  readonly available_pp_configurations: ReadonlyArray<Maybe<IPaymentPlanOfferConfiguration>>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.available_settlement
   */
  readonly available_settlement?: Maybe<IPaymentOffers_SettlementOffer>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.custom_plans_max_installments
   */
  readonly custom_plans_max_installments?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.custom_strategy_name
   */
  readonly custom_strategy_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.disallow_custom_plans
   */
  readonly disallow_custom_plans?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.highlighted_pp_configuration
   */
  readonly highlighted_pp_configuration?: Maybe<IPaymentPlanOfferConfiguration>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/payment_offers.proto
 * com.trueaccord.proto.PaymentOffers.SettlementOffer
 */
export interface IPaymentOffers_SettlementOffer {
  readonly __typename?: "PaymentOffers_SettlementOffer";
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.SettlementOffer.discount_percent_current_balance
   */
  readonly discount_percent_current_balance?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.SettlementOffer.discount_percent_initial_balance
   */
  readonly discount_percent_initial_balance?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.SettlementOffer.expiration_timestamp
   */
  readonly expiration_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.SettlementOffer.num_days_expires
   */
  readonly num_days_expires?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentOffers.SettlementOffer.settlementPercent
   */
  readonly settlementPercent?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication
 */
export interface IPaymentPlanApplication {
  readonly __typename?: "PaymentPlanApplication";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.amount_to_pay
   */
  readonly amount_to_pay?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.analytics
   */
  readonly analytics?: Maybe<IPaymentPlanApplication_Analytics>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.consent
   */
  readonly consent?: Maybe<IPaymentPlanApplication_Consent>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.created_by_user_account_id
   */
  readonly created_by_user_account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.discount
   */
  readonly discount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.frequency
   */
  readonly frequency?: Maybe<IFrequency | "%future added value">;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.installments
   */
  readonly installments: ReadonlyArray<Maybe<IPaymentPlanApplication_Installment>>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.number_of_installments
   */
  readonly number_of_installments?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.number_of_late_payment_credits
   */
  readonly number_of_late_payment_credits?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.originating_token_id
   */
  readonly originating_token_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.payment_amount
   */
  readonly payment_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.payment_schedule
   */
  readonly payment_schedule?: Maybe<IPaymentPlanApplication_PaymentSchedule>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.reason
   */
  readonly reason?: Maybe<IPaymentPlanApplication_Reason | "%future added value">;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.start_calendar_day
   */
  readonly start_calendar_day?: Maybe<IDate>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.start_date
   */
  readonly start_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.start_date_deferral_days
   */
  readonly start_date_deferral_days?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.workflow_creation_source
   */
  readonly workflow_creation_source?: Maybe<
    IPaymentPlanApplication_WorkflowCreationSource | "%future added value"
  >;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.Analytics
 */
export interface IPaymentPlanApplication_Analytics {
  readonly __typename?: "PaymentPlanApplication_Analytics";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.Analytics.plan_configuration_ids
   */
  readonly plan_configuration_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.Analytics.plan_configuration_type
   */
  readonly plan_configuration_type?: Maybe<
    IPaymentPlanApplication_Analytics_ConfigurationType | "%future added value"
  >;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.Analytics.plan_position
   */
  readonly plan_position?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.Analytics.selected_plan_configuration_id
   */
  readonly selected_plan_configuration_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.Analytics.ConfigurationType
 */
export enum IPaymentPlanApplication_Analytics_ConfigurationType {
  Custom = "CUSTOM",
  PaymentOffers = "PAYMENT_OFFERS",
  Predefined = "PREDEFINED"
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.Consent
 */
export interface IPaymentPlanApplication_Consent {
  readonly __typename?: "PaymentPlanApplication_Consent";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.Consent.ip
   */
  readonly ip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.Consent.time
   */
  readonly time?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.Installment
 */
export interface IPaymentPlanApplication_Installment {
  readonly __typename?: "PaymentPlanApplication_Installment";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.Installment.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.Installment.due_date
   */
  readonly due_date?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule
 */
export interface IPaymentPlanApplication_PaymentSchedule {
  readonly __typename?: "PaymentPlanApplication_PaymentSchedule";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.bi_weekly
   */
  readonly bi_weekly?: Maybe<IPaymentPlanApplication_PaymentSchedule_BiWeeklySchedule>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.fridays
   */
  readonly fridays?: Maybe<Scalars["PaymentPlanApplication_PaymentSchedule_FridaySchedule"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.monthly
   */
  readonly monthly?: Maybe<IPaymentPlanApplication_PaymentSchedule_MonthlySchedule>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.reason
   */
  readonly reason?: Maybe<IPaymentPlanApplication_PaymentSchedule_Reason | "%future added value">;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.semi_monthly
   */
  readonly semi_monthly?: Maybe<IPaymentPlanApplication_PaymentSchedule_SemiMonthlySchedule>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.start_on_next_payday
   */
  readonly start_on_next_payday?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.weekly
   */
  readonly weekly?: Maybe<IPaymentPlanApplication_PaymentSchedule_WeeklySchedule>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.BiWeeklySchedule
 */
export interface IPaymentPlanApplication_PaymentSchedule_BiWeeklySchedule {
  readonly __typename?: "PaymentPlanApplication_PaymentSchedule_BiWeeklySchedule";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.BiWeeklySchedule.day_of_week
   */
  readonly day_of_week?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.BiWeeklySchedule.next_pay_calendar_day
   */
  readonly next_pay_calendar_day?: Maybe<IDate>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.BiWeeklySchedule.next_pay_day
   */
  readonly next_pay_day?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.MonthlySchedule
 */
export interface IPaymentPlanApplication_PaymentSchedule_MonthlySchedule {
  readonly __typename?: "PaymentPlanApplication_PaymentSchedule_MonthlySchedule";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.MonthlySchedule.day_of_month
   */
  readonly day_of_month?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.Reason
 */
export enum IPaymentPlanApplication_PaymentSchedule_Reason {
  Payday = "PAYDAY",
  UserRequested = "USER_REQUESTED"
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.SemiMonthlySchedule
 */
export interface IPaymentPlanApplication_PaymentSchedule_SemiMonthlySchedule {
  readonly __typename?: "PaymentPlanApplication_PaymentSchedule_SemiMonthlySchedule";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.SemiMonthlySchedule.first_day
   */
  readonly first_day?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.SemiMonthlySchedule.second_day
   */
  readonly second_day?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.WeeklySchedule
 */
export interface IPaymentPlanApplication_PaymentSchedule_WeeklySchedule {
  readonly __typename?: "PaymentPlanApplication_PaymentSchedule_WeeklySchedule";
  /**
   * File: collectr/proto/payment_plans.proto
   * com.trueaccord.proto.PaymentPlanApplication.PaymentSchedule.WeeklySchedule.day_of_week
   */
  readonly day_of_week?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.Reason
 */
export enum IPaymentPlanApplication_Reason {
  BuyingTime = "BUYING_TIME",
  Other = "OTHER",
  RequestForSpecificTerms = "REQUEST_FOR_SPECIFIC_TERMS",
  SelfService = "SELF_SERVICE",
  VerbalAgreement = "VERBAL_AGREEMENT"
}

/**
 * File: collectr/proto/payment_plans.proto
 * com.trueaccord.proto.PaymentPlanApplication.WorkflowCreationSource
 */
export enum IPaymentPlanApplication_WorkflowCreationSource {
  CustomPlanGuideMe = "CUSTOM_PLAN_GUIDE_ME",
  CustomPlanQuickSetup = "CUSTOM_PLAN_QUICK_SETUP",
  PaymentOffers = "PAYMENT_OFFERS"
}

/**
 * File: collectr/proto/payment_offers.proto
 * com.trueaccord.proto.PaymentPlanOfferConfiguration
 */
export interface IPaymentPlanOfferConfiguration {
  readonly __typename?: "PaymentPlanOfferConfiguration";
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentPlanOfferConfiguration.discount_percent_current_balance
   */
  readonly discount_percent_current_balance?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentPlanOfferConfiguration.discount_percent_initial_balance
   */
  readonly discount_percent_initial_balance?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentPlanOfferConfiguration.frequency
   */
  readonly frequency?: Maybe<IFrequency | "%future added value">;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentPlanOfferConfiguration.number_of_installments
   */
  readonly number_of_installments?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/payment_offers.proto
   * com.trueaccord.proto.PaymentPlanOfferConfiguration.settlement_percent
   */
  readonly settlement_percent?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PaymentPlanOut
 */
export interface IPaymentPlanOut {
  readonly __typename?: "PaymentPlanOut";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut._include_legacy_payment_plan
   */
  readonly _include_legacy_payment_plan?: Maybe<IDebtState_PaymentPlan>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.application
   */
  readonly application?: Maybe<IPaymentPlanApplication>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.debt_state_id
   */
  readonly debt_state_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.fully_paid
   */
  readonly fully_paid?: Maybe<Scalars["Boolean"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.installments
   */
  readonly installments: ReadonlyArray<Maybe<IApi_PaymentPlanOut_Installment>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.is_recurring
   */
  readonly is_recurring?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.next_installment
   */
  readonly next_installment?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.payment_method_info
   */
  readonly payment_method_info?: Maybe<IApi_PaymentMethodInfoOut>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.revocation
   */
  readonly revocation?: Maybe<IDebtState_PaymentPlanV2_Revocation>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.PaymentToken
 */
export interface IPaymentToken {
  readonly __typename?: "PaymentToken";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.PaymentToken.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.PaymentToken.is_good_faith_payment
   */
  readonly is_good_faith_payment?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.PaymentToken.monetary_amount
   */
  readonly monetary_amount?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PendingPaymentEntry
 */
export interface IPendingPaymentEntry {
  readonly __typename?: "PendingPaymentEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ach_now_payment
   */
  readonly ach_now_payment?: Maybe<IPendingPaymentEntry_AchNowPayment>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.agent_user_account_id
   */
  readonly agent_user_account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.contribution
   */
  readonly contribution?: Maybe<IContribution>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.forced_reattempt
   */
  readonly forced_reattempt?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.forte_ach_payment
   */
  readonly forte_ach_payment?: Maybe<IPendingPaymentEntry_ForteAchPayment>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.healpay_ach_payment
   */
  readonly healpay_ach_payment?: Maybe<IPendingPaymentEntry_HealpayAchPayment>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.is_entered_by_agent
   */
  readonly is_entered_by_agent?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.is_interactive_payment
   */
  readonly is_interactive_payment?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.lucentpay_ach_payment
   */
  readonly lucentpay_ach_payment?: Maybe<IPendingPaymentEntry_LucentpayAchPayment>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.made_debt_terminal
   */
  readonly made_debt_terminal?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.monetary_amount
   */
  readonly monetary_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.payment_ivr_session_id
   */
  readonly payment_ivr_session_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.payment_plan_id
   */
  readonly payment_plan_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.post_dated_check
   */
  readonly post_dated_check?: Maybe<IPendingPaymentEntry_PostDatedCheck>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.previous_debt_status
   */
  readonly previous_debt_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.recurring_payment_token_id
   */
  readonly recurring_payment_token_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.response_text
   */
  readonly response_text?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.response_type
   */
  readonly response_type?: Maybe<IPendingPaymentEntry_ResponseType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.speedpay_ach_payment
   */
  readonly speedpay_ach_payment?: Maybe<IPendingPaymentEntry_SpeedpayAchPayment>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PendingPaymentEntry.AchNowPayment
 */
export interface IPendingPaymentEntry_AchNowPayment {
  readonly __typename?: "PendingPaymentEntry_AchNowPayment";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.AchNowPayment.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.AchNowPayment.response_code
   */
  readonly response_code?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.AchNowPayment.response_msg
   */
  readonly response_msg?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.AchNowPayment.response_text
   */
  readonly response_text?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.AchNowPayment.transaction_id
   */
  readonly transaction_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.AchNowPayment.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment
 */
export interface IPendingPaymentEntry_ForteAchPayment {
  readonly __typename?: "PendingPaymentEntry_ForteAchPayment";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.authorization_code
   */
  readonly authorization_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.preauth_code
   */
  readonly preauth_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.preauth_description
   */
  readonly preauth_description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.preauth_result
   */
  readonly preauth_result?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.response_body
   */
  readonly response_body?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.response_code
   */
  readonly response_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.response_description
   */
  readonly response_description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.trace_number
   */
  readonly trace_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.ForteAchPayment.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PendingPaymentEntry.HealpayAchPayment
 */
export interface IPendingPaymentEntry_HealpayAchPayment {
  readonly __typename?: "PendingPaymentEntry_HealpayAchPayment";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.HealpayAchPayment.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.HealpayAchPayment.response
   */
  readonly response?: Maybe<IEPayTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.HealpayAchPayment.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PendingPaymentEntry.LucentpayAchPayment
 */
export interface IPendingPaymentEntry_LucentpayAchPayment {
  readonly __typename?: "PendingPaymentEntry_LucentpayAchPayment";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.LucentpayAchPayment.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.LucentpayAchPayment.response
   */
  readonly response?: Maybe<IEPayTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.LucentpayAchPayment.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PendingPaymentEntry.PostDatedCheck
 */
export interface IPendingPaymentEntry_PostDatedCheck {
  readonly __typename?: "PendingPaymentEntry_PostDatedCheck";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.PostDatedCheck.check_amount
   */
  readonly check_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.PostDatedCheck.check_number
   */
  readonly check_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.PostDatedCheck.payment_date_on_check
   */
  readonly payment_date_on_check?: Maybe<IDate>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.PostDatedCheck.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PendingPaymentEntry.ResponseType
 */
export enum IPendingPaymentEntry_ResponseType {
  Approved = "APPROVED",
  Declined = "DECLINED",
  Error = "ERROR",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PendingPaymentEntry.SpeedpayAchPayment
 */
export interface IPendingPaymentEntry_SpeedpayAchPayment {
  readonly __typename?: "PendingPaymentEntry_SpeedpayAchPayment";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.SpeedpayAchPayment.billing_address
   */
  readonly billing_address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.SpeedpayAchPayment.response
   */
  readonly response?: Maybe<ISpeedpayTransaction>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PendingPaymentEntry.SpeedpayAchPayment.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/pepipost_event.proto
 * com.trueaccord.proto.PepipostEvent
 */
export interface IPepipostEvent {
  readonly __typename?: "PepipostEvent";
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.DEPRECATED_bounce_reasonid
   */
  readonly DEPRECATED_bounce_reasonid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.DEPRECATED_clientid
   */
  readonly DEPRECATED_clientid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.DEPRECATED_rcptdid
   */
  readonly DEPRECATED_rcptdid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.DEPRECATED_transid
   */
  readonly DEPRECATED_transid?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.bouncereasonid
   */
  readonly bouncereasonid?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.debt_log_entry_id
   */
  readonly debt_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.event
   */
  readonly event?: Maybe<IPepipostEvent_Event | "%future added value">;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.fromaddress
   */
  readonly fromaddress?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.ip
   */
  readonly ip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.msize
   */
  readonly msize?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.raw_pepipost_event_batch_id
   */
  readonly raw_pepipost_event_batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.response
   */
  readonly response?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.sg_message_id
   */
  readonly sg_message_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.tags
   */
  readonly tags?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.type
   */
  readonly type?: Maybe<IPepipostEvent_BounceType | "%future added value">;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.url
   */
  readonly url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/pepipost_event.proto
   * com.trueaccord.proto.PepipostEvent.useragent
   */
  readonly useragent?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/pepipost_event.proto
 * com.trueaccord.proto.PepipostEvent.BounceType
 */
export enum IPepipostEvent_BounceType {
  Block = "BLOCK",
  Hardbounce = "HARDBOUNCE",
  Softbounce = "SOFTBOUNCE",
  Techbounce = "TECHBOUNCE"
}

/**
 * File: collectr/proto/pepipost_event.proto
 * com.trueaccord.proto.PepipostEvent.Event
 */
export enum IPepipostEvent_Event {
  DeprecatedInvalid = "DEPRECATED_invalid",
  DeprecatedSpam = "DEPRECATED_spam",
  Abuse = "abuse",
  Bounce = "bounce",
  Click = "click",
  Delivered = "delivered",
  Dropped = "dropped",
  Open = "open",
  Unsubscribe = "unsubscribe"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person
 */
export interface IPerson {
  readonly __typename?: "Person";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person._internal_information
   */
  readonly _internal_information?: Maybe<IPerson_InternalInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.addresses
   */
  readonly addresses: ReadonlyArray<Maybe<IPostalAddress>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.alternate_references
   */
  readonly alternate_references: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.businesses
   */
  readonly businesses: ReadonlyArray<Maybe<IPerson_Business>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.date_of_birth
   */
  readonly date_of_birth?: Maybe<IDate>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.emails
   */
  readonly emails: ReadonlyArray<Maybe<IEmailAddress>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.fico_score
   */
  readonly fico_score?: Maybe<Scalars["String"]>;
  readonly fullName: Scalars["String"];
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.language_preference
   */
  readonly language_preference?: Maybe<ILanguagePreferred_Language | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.language_preferred
   */
  readonly language_preferred?: Maybe<ILanguagePreferred>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.name
   */
  readonly name?: Maybe<IPersonName>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.phones
   */
  readonly phones: ReadonlyArray<Maybe<IPhoneNumber>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.reference
   */
  readonly reference?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PersonName
 */
export interface IPersonName {
  readonly __typename?: "PersonName";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PersonName.data_source
   */
  readonly data_source?: Maybe<IDataSource>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PersonName.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PersonName.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PersonName.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PersonName.middle_name
   */
  readonly middle_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PersonOut
 */
export interface IPersonOut {
  readonly __typename?: "PersonOut";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut._include_person
   */
  readonly _include_person?: Maybe<IPerson>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.additional_information
   */
  readonly additional_information?: Maybe<IApi_PersonOut_AdditionalInformation>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.debts
   */
  readonly debts: ReadonlyArray<Maybe<IDebtOut>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.is_address_in_new_york_city
   */
  readonly is_address_in_new_york_city?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.language_preferred
   */
  readonly language_preferred?: Maybe<ILanguagePreferred>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.matched_debts
   */
  readonly matched_debts: ReadonlyArray<Maybe<IDebtOut>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.Business
 */
export interface IPerson_Business {
  readonly __typename?: "Person_Business";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.Business.data_source
   */
  readonly data_source?: Maybe<IDataSource>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.Business.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.Business.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.Business.url
   */
  readonly url?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation
 */
export interface IPerson_InternalInformation {
  readonly __typename?: "Person_InternalInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.aim_info
   */
  readonly aim_info?: Maybe<IPerson_InternalInformation_AimInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.amex_information
   */
  readonly amex_information?: Maybe<IPerson_InternalInformation_AmexInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.discoverInformation
   */
  readonly discoverInformation?: Maybe<IPerson_InternalInformation_DiscoverInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.dmp_information
   */
  readonly dmp_information?: Maybe<IPerson_InternalInformation_DmpInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.environment
   */
  readonly environment?: Maybe<IEnvironment | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.fico_info
   */
  readonly fico_info?: Maybe<IPerson_InternalInformation_FicoInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.paypal_information
   */
  readonly paypal_information?: Maybe<IPerson_InternalInformation_PaypalInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.s3_paths
   */
  readonly s3_paths: ReadonlyArray<Maybe<IDebtFileS3Path>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.AIMInformation
 */
export interface IPerson_InternalInformation_AimInformation {
  readonly __typename?: "Person_InternalInformation_AIMInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.bankruptcy_records
   */
  readonly bankruptcy_records: ReadonlyArray<Maybe<IPerson_InternalInformation_AimInformation_BankruptInfo>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.cdxx_index
   */
  readonly cdxx_index?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.deceased_records
   */
  readonly deceased_records: ReadonlyArray<Maybe<IPerson_InternalInformation_AimInformation_DeceasedInfo>>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.AIMInformation.BankruptInfo
 */
export interface IPerson_InternalInformation_AimInformation_BankruptInfo {
  readonly __typename?: "Person_InternalInformation_AIMInformation_BankruptInfo";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.BankruptInfo.aim_log_entry_id
   */
  readonly aim_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.BankruptInfo.entries
   */
  readonly entries: ReadonlyArray<Maybe<IAim_Cbkp>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.AIMInformation.DeceasedInfo
 */
export interface IPerson_InternalInformation_AimInformation_DeceasedInfo {
  readonly __typename?: "Person_InternalInformation_AIMInformation_DeceasedInfo";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.DeceasedInfo.aim_log_entry_id
   */
  readonly aim_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AIMInformation.DeceasedInfo.entries
   */
  readonly entries: ReadonlyArray<Maybe<IAim_Cdec>>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.AmexInformation
 */
export interface IPerson_InternalInformation_AmexInformation {
  readonly __typename?: "Person_InternalInformation_AmexInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.AmexInformation.priority
   */
  readonly priority?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.DMPInformation
 */
export interface IPerson_InternalInformation_DmpInformation {
  readonly __typename?: "Person_InternalInformation_DMPInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.DMPInformation.file_no
   */
  readonly file_no?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.DMPInformation.placement_date
   */
  readonly placement_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.DMPInformation.placement_id
   */
  readonly placement_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.DMPInformation.raw_first_name
   */
  readonly raw_first_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.DiscoverInformation
 */
export interface IPerson_InternalInformation_DiscoverInformation {
  readonly __typename?: "Person_InternalInformation_DiscoverInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.DiscoverInformation.work_group
   */
  readonly work_group?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.FicoInformation
 */
export interface IPerson_InternalInformation_FicoInformation {
  readonly __typename?: "Person_InternalInformation_FicoInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.capital_one_info
   */
  readonly capital_one_info?: Maybe<IPerson_InternalInformation_FicoInformation_CapitalOneInformation>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.pnet_id
   */
  readonly pnet_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.reference_number
   */
  readonly reference_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.secondary_reference_number
   */
  readonly secondary_reference_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.sequence_number
   */
  readonly sequence_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.FicoInformation.CapitalOneInformation
 */
export interface IPerson_InternalInformation_FicoInformation_CapitalOneInformation {
  readonly __typename?: "Person_InternalInformation_FicoInformation_CapitalOneInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.CapitalOneInformation.account_status
   */
  readonly account_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.CapitalOneInformation.disclosure_number
   */
  readonly disclosure_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.FicoInformation.CapitalOneInformation.secured_card
   */
  readonly secured_card?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Person.InternalInformation.PaypalInformation
 */
export interface IPerson_InternalInformation_PaypalInformation {
  readonly __typename?: "Person_InternalInformation_PaypalInformation";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_address_1
   */
  readonly raw_address_1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_address_2
   */
  readonly raw_address_2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_address_3
   */
  readonly raw_address_3?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_city
   */
  readonly raw_city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_county
   */
  readonly raw_county?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_date_of_birth
   */
  readonly raw_date_of_birth?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_home_phone
   */
  readonly raw_home_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_person_name
   */
  readonly raw_person_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_salutation
   */
  readonly raw_salutation?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_state
   */
  readonly raw_state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Person.InternalInformation.PaypalInformation.raw_zipcode
   */
  readonly raw_zipcode?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PhoneNumber
 */
export interface IPhoneNumber {
  readonly __typename?: "PhoneNumber";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.consent
   */
  readonly consent?: Maybe<IContactInformationConsent>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.data_source
   */
  readonly data_source?: Maybe<IDataSource>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.opted_in_modes
   */
  readonly opted_in_modes: ReadonlyArray<Maybe<IPhoneNumber_Mode | "%future added value">>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.ownership
   */
  readonly ownership?: Maybe<IPhoneOwnership>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.phone_append
   */
  readonly phone_append?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.sms_opted_in_codes
   */
  readonly sms_opted_in_codes: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.types
   */
  readonly types: ReadonlyArray<Maybe<IPhoneNumber_Type | "%future added value">>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneNumber.unsubscribed_modes
   */
  readonly unsubscribed_modes: ReadonlyArray<Maybe<IPhoneNumber_Mode | "%future added value">>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PhoneNumber.Mode
 */
export enum IPhoneNumber_Mode {
  Call = "CALL",
  Sms = "SMS",
  Voicemail = "VOICEMAIL"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PhoneNumber.Type
 */
export enum IPhoneNumber_Type {
  Business = "BUSINESS",
  Cell = "CELL",
  Fax = "FAX",
  Home = "HOME",
  UnknownType = "UNKNOWN_TYPE",
  Work = "WORK"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PhoneOwnership
 */
export interface IPhoneOwnership {
  readonly __typename?: "PhoneOwnership";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.compliance
   */
  readonly compliance?: Maybe<IPhoneOwnership_Compliance>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.in_service_status
   */
  readonly in_service_status?: Maybe<IPhoneOwnership_InServiceStatus | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.ownership
   */
  readonly ownership?: Maybe<IPhoneOwnership_Ownership | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.phone_type
   */
  readonly phone_type?: Maybe<IPhoneOwnership_PhoneType | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.prepaid
   */
  readonly prepaid?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PhoneOwnership.Compliance
 */
export interface IPhoneOwnership_Compliance {
  readonly __typename?: "PhoneOwnership_Compliance";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.Compliance.can_call
   */
  readonly can_call?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.Compliance.can_sms
   */
  readonly can_sms?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PhoneOwnership.Compliance.can_voicemail
   */
  readonly can_voicemail?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PhoneOwnership.InServiceStatus
 */
export enum IPhoneOwnership_InServiceStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PhoneOwnership.Ownership
 */
export enum IPhoneOwnership_Ownership {
  NotOwnedByPerson = "NOT_OWNED_BY_PERSON",
  OwnedByPerson = "OWNED_BY_PERSON",
  OwnershipNotChecked = "OWNERSHIP_NOT_CHECKED",
  OwnershipUnknown = "OWNERSHIP_UNKNOWN"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PhoneOwnership.PhoneType
 */
export enum IPhoneOwnership_PhoneType {
  Landline = "LANDLINE",
  PhoneTypeNotChecked = "PHONE_TYPE_NOT_CHECKED",
  PhoneTypeUnknown = "PHONE_TYPE_UNKNOWN",
  Wireless = "WIRELESS"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PlacementScrubEntry
 */
export interface IPlacementScrubEntry {
  readonly __typename?: "PlacementScrubEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PlacementScrubEntry.capital_one
   */
  readonly capital_one?: Maybe<IPlacementScrubEntry_CapitalOne>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PlacementScrubEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PlacementScrubEntry.CapitalOne
 */
export interface IPlacementScrubEntry_CapitalOne {
  readonly __typename?: "PlacementScrubEntry_CapitalOne";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PlacementScrubEntry.CapitalOne.scrub_reasons
   */
  readonly scrub_reasons: ReadonlyArray<
    Maybe<IPlacementScrubEntry_CapitalOne_ScrubReason | "%future added value">
  >;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PlacementScrubEntry.CapitalOne.ScrubReason
 */
export enum IPlacementScrubEntry_CapitalOne_ScrubReason {
  CardHolderLivesInBlockedState = "CARD_HOLDER_LIVES_IN_BLOCKED_STATE",
  ForeignAddress = "FOREIGN_ADDRESS",
  LowBalance = "LOW_BALANCE",
  ManualScrubCodedNs = "MANUAL_SCRUB_CODED_NS",
  ManualScrubCodedTx = "MANUAL_SCRUB_CODED_TX",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PostalAddress
 */
export interface IPostalAddress {
  readonly __typename?: "PostalAddress";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.city
   */
  readonly city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.consent
   */
  readonly consent?: Maybe<IContactInformationConsent>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.country_code
   */
  readonly country_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.data_source
   */
  readonly data_source?: Maybe<IDataSource>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.is_unsubscribed
   */
  readonly is_unsubscribed?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.name
   */
  readonly name?: Maybe<IPersonName>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.street_line1
   */
  readonly street_line1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.street_line2
   */
  readonly street_line2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.street_line3
   */
  readonly street_line3?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.types
   */
  readonly types: ReadonlyArray<Maybe<IPostalAddress_Type | "%future added value">>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.PostalAddress.zipcode
   */
  readonly zipcode?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.PostalAddress.Type
 */
export enum IPostalAddress_Type {
  Home = "HOME",
  UnknownType = "UNKNOWN_TYPE",
  Work = "WORK"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PreRecallNotificationEntry
 */
export interface IPreRecallNotificationEntry {
  readonly __typename?: "PreRecallNotificationEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallNotificationEntry.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallNotificationEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallNotificationEntry.pending_recall_date
   */
  readonly pending_recall_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallNotificationEntry.pre_recall_response_id
   */
  readonly pre_recall_response_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallNotificationEntry.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallNotificationEntry.recall_timestamp
   */
  readonly recall_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallNotificationEntry.recall_type
   */
  readonly recall_type?: Maybe<IPreRecallNotificationEntry_RecallType | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PreRecallNotificationEntry.RecallType
 */
export enum IPreRecallNotificationEntry_RecallType {
  HardRecall = "HARD_RECALL",
  SoftRecall = "SOFT_RECALL"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PreRecallResponseEntry
 */
export interface IPreRecallResponseEntry {
  readonly __typename?: "PreRecallResponseEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallResponseEntry.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallResponseEntry.decision
   */
  readonly decision?: Maybe<IPreRecallResponseEntry_Decision | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallResponseEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallResponseEntry.pre_recall_notification_id
   */
  readonly pre_recall_notification_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.PreRecallResponseEntry.reason
   */
  readonly reason?: Maybe<IPreRecallResponseEntry_Reason | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PreRecallResponseEntry.Decision
 */
export enum IPreRecallResponseEntry_Decision {
  Keep = "KEEP",
  Relinquish = "RELINQUISH"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.PreRecallResponseEntry.Reason
 */
export enum IPreRecallResponseEntry_Reason {
  AllEffortsExhausted = "ALL_EFFORTS_EXHAUSTED",
  CustomerEngagedRecently = "CUSTOMER_ENGAGED_RECENTLY",
  InDispute = "IN_DISPUTE",
  InPaymentPlan = "IN_PAYMENT_PLAN",
  InProgress = "IN_PROGRESS",
  PifSifCloseout = "PIF_SIF_CLOSEOUT",
  PromiseToPay = "PROMISE_TO_PAY",
  RecentPayment = "RECENT_PAYMENT"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.ProcessingEntityEnumContainer.ProcessingEntity
 */
export enum IProcessingEntityEnumContainer_ProcessingEntity {
  Creditor = "CREDITOR",
  Trueaccord = "TRUEACCORD"
}

/**
 * File: collectr/proto/epay.proto
 * com.trueaccord.proto.Processor
 */
export enum IProcessor {
  Lucentpay = "LUCENTPAY",
  Merchante = "MERCHANTE",
  Tratta = "TRATTA"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.ProhibitedDebtAction
 */
export interface IProhibitedDebtAction {
  readonly __typename?: "ProhibitedDebtAction";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.ProhibitedDebtAction.debt_action
   */
  readonly debt_action?: Maybe<IDebtAction | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.ProhibitedDebtAction.prohibition_reasons
   */
  readonly prohibition_reasons: ReadonlyArray<
    Maybe<IProhibitedDebtAction_ProhibitionReason | "%future added value">
  >;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.ProhibitedDebtAction.ProhibitionReason
 */
export enum IProhibitedDebtAction_ProhibitionReason {
  CreditorStatus = "CREDITOR_STATUS",
  DebtTerminal = "DEBT_TERMINAL",
  Location = "LOCATION",
  PendingPayment = "PENDING_PAYMENT"
}

export interface IQuery {
  readonly __typename?: "Query";
  readonly AIMLogEntries?: Maybe<ReadonlyArray<Maybe<IAimLogEntry>>>;
  readonly AIMLogEntry?: Maybe<IAimLogEntry>;
  readonly AdqwOutboundEntries?: Maybe<ReadonlyArray<Maybe<IAdqwOutboundEntry>>>;
  readonly AdqwOutboundEntry?: Maybe<IAdqwOutboundEntry>;
  readonly Alert?: Maybe<IAlert>;
  readonly Alerts?: Maybe<ReadonlyArray<Maybe<IAlert>>>;
  readonly AnalystState?: Maybe<IAnalystState>;
  readonly AnalystStates?: Maybe<ReadonlyArray<Maybe<IAnalystState>>>;
  readonly ApiKey?: Maybe<IApiKey>;
  readonly ApiKeys?: Maybe<ReadonlyArray<Maybe<IApiKey>>>;
  readonly BinNumber?: Maybe<IApi_BinNumber>;
  readonly BinNumbers?: Maybe<ReadonlyArray<Maybe<IApi_BinNumber>>>;
  readonly Blacklist?: Maybe<IBlacklist>;
  readonly Blacklists?: Maybe<ReadonlyArray<Maybe<IBlacklist>>>;
  readonly BrandMapping?: Maybe<IBrandMapping>;
  readonly BrandMappings?: Maybe<ReadonlyArray<Maybe<IBrandMapping>>>;
  readonly BulkEntries?: Maybe<ReadonlyArray<Maybe<IBulk_Update_BulkEntry>>>;
  readonly BulkEntry?: Maybe<IBulk_Update_BulkEntry>;
  readonly CohortStats?: Maybe<IApi_CohortStats>;
  readonly CohortStatses?: Maybe<ReadonlyArray<Maybe<IApi_CohortStats>>>;
  readonly Comment?: Maybe<IComment>;
  readonly Comments?: Maybe<ReadonlyArray<Maybe<IComment>>>;
  readonly CommentsByObjectIds?: Maybe<ReadonlyArray<Maybe<IComment>>>;
  readonly Creditor?: Maybe<ICreditor>;
  readonly CreditorCustomer?: Maybe<ICreditorCustomer>;
  readonly CreditorCustomers?: Maybe<ReadonlyArray<Maybe<ICreditorCustomer>>>;
  readonly CreditorGroup?: Maybe<ICreditorGroup>;
  readonly CreditorGroups?: Maybe<ReadonlyArray<Maybe<ICreditorGroup>>>;
  readonly CreditorStats?: Maybe<IApi_CreditorStats>;
  readonly CreditorStatses?: Maybe<ReadonlyArray<Maybe<IApi_CreditorStats>>>;
  readonly CreditorTask?: Maybe<ICreditorTask>;
  readonly CreditorTasks?: Maybe<ReadonlyArray<Maybe<ICreditorTask>>>;
  readonly Creditors?: Maybe<ReadonlyArray<Maybe<ICreditor>>>;
  readonly Debt?: Maybe<IDebt>;
  readonly DebtCache?: Maybe<IDebtCache>;
  readonly DebtCaches?: Maybe<ReadonlyArray<Maybe<IDebtCache>>>;
  readonly DebtLogEntries?: Maybe<ReadonlyArray<Maybe<IDebtLogEntry>>>;
  readonly DebtLogEntry?: Maybe<IDebtLogEntry>;
  readonly DebtOut?: Maybe<IDebtOut>;
  readonly DebtOuts?: Maybe<ReadonlyArray<Maybe<IDebtOut>>>;
  readonly DebtState?: Maybe<IDebtState>;
  readonly DebtStates?: Maybe<ReadonlyArray<Maybe<IDebtState>>>;
  readonly DebtorFeatures?: Maybe<IDebtorFeatures>;
  readonly DebtorFeatureses?: Maybe<ReadonlyArray<Maybe<IDebtorFeatures>>>;
  readonly Debts?: Maybe<ReadonlyArray<Maybe<IDebt>>>;
  readonly DeskEtag?: Maybe<IDeskEtag>;
  readonly DeskEtags?: Maybe<ReadonlyArray<Maybe<IDeskEtag>>>;
  readonly Dispute?: Maybe<IDispute>;
  readonly DisputeTask?: Maybe<IDispute_Task_DisputeTask>;
  readonly DisputeTasks?: Maybe<ReadonlyArray<Maybe<IDispute_Task_DisputeTask>>>;
  readonly EmailThrottler?: Maybe<IEmailThrottler>;
  readonly EmailThrottlers?: Maybe<ReadonlyArray<Maybe<IEmailThrottler>>>;
  readonly ExportBatch?: Maybe<IManagement_Systems_Export_ExportBatch>;
  readonly ExportBatches?: Maybe<ReadonlyArray<Maybe<IManagement_Systems_Export_ExportBatch>>>;
  readonly FCTRecords?: Maybe<IFct_FctRecords>;
  readonly FCTRecordses?: Maybe<ReadonlyArray<Maybe<IFct_FctRecords>>>;
  readonly Feature?: Maybe<IFeature>;
  readonly Features?: Maybe<ReadonlyArray<Maybe<IFeature>>>;
  readonly Label?: Maybe<ILabel>;
  readonly Labels?: Maybe<ReadonlyArray<Maybe<ILabel>>>;
  readonly MagicBusFile?: Maybe<ICom_Trueaccord_Magicbus_Protos_MagicBusFile>;
  readonly MagicBusFiles?: Maybe<ReadonlyArray<Maybe<ICom_Trueaccord_Magicbus_Protos_MagicBusFile>>>;
  readonly PA2Records?: Maybe<IPa2_Pa2Records>;
  readonly PA2Recordses?: Maybe<ReadonlyArray<Maybe<IPa2_Pa2Records>>>;
  readonly PageViewData?: Maybe<IAnalytics_PageViewData>;
  readonly PageViewDatas?: Maybe<ReadonlyArray<Maybe<IAnalytics_PageViewData>>>;
  readonly PaymentOffers?: Maybe<IPaymentOffers>;
  readonly PaymentOfferses?: Maybe<ReadonlyArray<Maybe<IPaymentOffers>>>;
  readonly PaymentPlanOut?: Maybe<IPaymentPlanOut>;
  readonly PepipostEvent?: Maybe<IPepipostEvent>;
  readonly PepipostEvents?: Maybe<ReadonlyArray<Maybe<IPepipostEvent>>>;
  readonly Person?: Maybe<IPerson>;
  readonly PersonOut?: Maybe<IPersonOut>;
  readonly Persons?: Maybe<ReadonlyArray<Maybe<IPerson>>>;
  readonly Profile?: Maybe<IImporter_Profile>;
  readonly Profiles?: Maybe<ReadonlyArray<Maybe<IImporter_Profile>>>;
  readonly PumpTask?: Maybe<IPump_PumpTask>;
  readonly PumpTasks?: Maybe<ReadonlyArray<Maybe<IPump_PumpTask>>>;
  readonly RateLimitedAction?: Maybe<IRateLimitedAction>;
  readonly RateLimitedActions?: Maybe<ReadonlyArray<Maybe<IRateLimitedAction>>>;
  readonly RemoteFile?: Maybe<IRemoteFile>;
  readonly RemoteFiles?: Maybe<ReadonlyArray<Maybe<IRemoteFile>>>;
  readonly RepoQueueItem?: Maybe<IRepoQueueItem>;
  readonly RepoQueueItems?: Maybe<ReadonlyArray<Maybe<IRepoQueueItem>>>;
  readonly RiskyPayment?: Maybe<IRisky_Payment_RiskyPayment>;
  readonly RiskyPayments?: Maybe<ReadonlyArray<Maybe<IRisky_Payment_RiskyPayment>>>;
  readonly RoleSettings?: Maybe<IAuth_RoleSettings>;
  readonly RoleSettingses?: Maybe<ReadonlyArray<Maybe<IAuth_RoleSettings>>>;
  readonly SUFRecords?: Maybe<IManagement_Systems_Resurgent_Suf_SufRecords>;
  readonly SUFRecordses?: Maybe<ReadonlyArray<Maybe<IManagement_Systems_Resurgent_Suf_SufRecords>>>;
  readonly SendGridEvent?: Maybe<ISendGridEvent>;
  readonly SendGridEvents?: Maybe<ReadonlyArray<Maybe<ISendGridEvent>>>;
  readonly Sitemap?: Maybe<ISitemap>;
  readonly Sitemaps?: Maybe<ReadonlyArray<Maybe<ISitemap>>>;
  readonly TalkDeskEvent?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent>;
  readonly TalkDeskEvents?: Maybe<ReadonlyArray<Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent>>>;
  readonly TegEvent?: Maybe<ITeg_Event_TegEvent>;
  readonly TegEvents?: Maybe<ReadonlyArray<Maybe<ITeg_Event_TegEvent>>>;
  readonly TegTask?: Maybe<ITeg_Event_TegTask>;
  readonly TegTasks?: Maybe<ReadonlyArray<Maybe<ITeg_Event_TegTask>>>;
  readonly TemplateAudit?: Maybe<ITemplates_TemplateAudit>;
  readonly TemplateAudits?: Maybe<ReadonlyArray<Maybe<ITemplates_TemplateAudit>>>;
  readonly Token?: Maybe<IToken>;
  readonly Tokens?: Maybe<ReadonlyArray<Maybe<IToken>>>;
  readonly TwilioEvent?: Maybe<ITwilio_Event_TwilioEvent>;
  readonly TwilioEvents?: Maybe<ReadonlyArray<Maybe<ITwilio_Event_TwilioEvent>>>;
  readonly UserAccount?: Maybe<IAuth_UserAccount>;
  readonly UserAccounts?: Maybe<ReadonlyArray<Maybe<IAuth_UserAccount>>>;
  readonly UserSession?: Maybe<IAuth_UserSession>;
  readonly UserSessions?: Maybe<ReadonlyArray<Maybe<IAuth_UserSession>>>;
  readonly VoAppsEvent?: Maybe<IVoAppsEvent>;
  readonly VoAppsEvents?: Maybe<ReadonlyArray<Maybe<IVoAppsEvent>>>;
  readonly camunda_AuthenticatedUser?: Maybe<ICamunda_User>;
  readonly camunda_Authorization?: Maybe<ICamunda_Authorization>;
  readonly camunda_Authorizations?: Maybe<ReadonlyArray<Maybe<ICamunda_Authorization>>>;
  readonly camunda_Comment?: Maybe<ICamunda_Comment>;
  /** Allows programmatic querying of task comments based on the public Interface org.camunda.bpm.engine.TaskService */
  readonly camunda_Comments?: Maybe<ReadonlyArray<Maybe<ICamunda_Comment>>>;
  readonly camunda_DecisionDefinition?: Maybe<ICamunda_DecisionDefinition>;
  readonly camunda_DecisionDefinitions?: Maybe<ReadonlyArray<Maybe<ICamunda_DecisionDefinition>>>;
  readonly camunda_Group?: Maybe<ICamunda_Group>;
  readonly camunda_Groups?: Maybe<ReadonlyArray<Maybe<ICamunda_Group>>>;
  readonly camunda_HistoricActivityInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_HistoricActivityInstance>>>;
  readonly camunda_HistoricProcessInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  readonly camunda_HistoricProcessInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_HistoricProcessInstance>>>;
  readonly camunda_HistoricTask?: Maybe<ICamunda_HistoricTask>;
  readonly camunda_HistoricTasks?: Maybe<ReadonlyArray<Maybe<ICamunda_HistoricTask>>>;
  readonly camunda_ProcessDefinition?: Maybe<ICamunda_ProcessDefinition>;
  readonly camunda_ProcessDefinitions?: Maybe<ReadonlyArray<Maybe<ICamunda_ProcessDefinition>>>;
  readonly camunda_ProcessInstance?: Maybe<ICamunda_ProcessInstance>;
  readonly camunda_ProcessInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_ProcessInstance>>>;
  readonly camunda_Task?: Maybe<ICamunda_Task>;
  /** Allows programmatic querying of Tasks based on the public Interface org.camunda.bpm.engine.task.TaskQuery */
  readonly camunda_Tasks?: Maybe<ReadonlyArray<Maybe<ICamunda_Task>>>;
  /** Allows programmatic querying of Tasks based on the public Interface org.camunda.bpm.engine.task.TaskQuery */
  readonly camunda_Tasks_stats?: Maybe<ICamundaTaskStats>;
  readonly camunda_User?: Maybe<ICamunda_User>;
  readonly camunda_Users?: Maybe<ReadonlyArray<Maybe<ICamunda_User>>>;
}

export type IQueryAimLogEntriesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryAimLogEntryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryAdqwOutboundEntriesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryAdqwOutboundEntryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryAlertArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryAlertsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryAnalystStateArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryAnalystStatesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryApiKeyArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryApiKeysArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryBinNumberArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryBinNumbersArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryBlacklistArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryBlacklistsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryBrandMappingArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryBrandMappingsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryBulkEntriesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryBulkEntryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryCohortStatsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryCohortStatsesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCommentArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryCommentsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCommentsByObjectIdsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCreditorArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryCreditorCustomerArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryCreditorCustomersArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCreditorGroupArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryCreditorGroupsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCreditorStatsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryCreditorStatsesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCreditorTaskArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryCreditorTasksArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCreditorsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryDebtArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDebtCacheArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDebtCachesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryDebtLogEntriesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryDebtLogEntryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDebtOutArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDebtOutsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryDebtStateArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDebtStatesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryDebtorFeaturesArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDebtorFeaturesesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryDebtsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryDeskEtagArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDeskEtagsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryDisputeArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDisputeTaskArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryDisputeTasksArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryEmailThrottlerArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryEmailThrottlersArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryExportBatchArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryExportBatchesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryFctRecordsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryFctRecordsesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryFeatureArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryFeaturesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryLabelArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryLabelsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryMagicBusFileArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryMagicBusFilesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryPa2RecordsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryPa2RecordsesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryPageViewDataArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryPageViewDatasArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryPaymentOffersArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryPaymentOffersesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryPaymentPlanOutArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryPepipostEventArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryPepipostEventsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryPersonArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryPersonOutArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryPersonsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryProfileArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryProfilesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryPumpTaskArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryPumpTasksArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryRateLimitedActionArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryRateLimitedActionsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryRemoteFileArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryRemoteFilesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryRepoQueueItemArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryRepoQueueItemsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryRiskyPaymentArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryRiskyPaymentsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryRoleSettingsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryRoleSettingsesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQuerySufRecordsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQuerySufRecordsesArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQuerySendGridEventArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQuerySendGridEventsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQuerySitemapArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQuerySitemapsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryTalkDeskEventArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryTalkDeskEventsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryTegEventArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryTegEventsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryTegTaskArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryTegTasksArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryTemplateAuditArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryTemplateAuditsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryTokenArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryTokensArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryTwilioEventArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryTwilioEventsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryUserAccountArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryUserAccountsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryUserSessionArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryUserSessionsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryVoAppsEventArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type IQueryVoAppsEventsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCamunda_AuthorizationArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_AuthorizationsArgs = {
  groupIdIn?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  resourceIdIn?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  userIdIn?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
};

export type IQueryCamunda_CommentArgs = {
  commentId: Scalars["ID"];
  id: Scalars["ID"];
};

export type IQueryCamunda_CommentsArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_DecisionDefinitionArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_DecisionDefinitionsArgs = {
  latest?: InputMaybe<Scalars["Boolean"]>;
};

export type IQueryCamunda_GroupArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_GroupsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameLike?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type IQueryCamunda_HistoricActivityInstancesArgs = {
  businessKey?: InputMaybe<Scalars["String"]>;
  processInstanceIds?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
};

export type IQueryCamunda_HistoricProcessInstanceArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_HistoricProcessInstancesArgs = {
  businessKey?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
};

export type IQueryCamunda_HistoricTaskArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_HistoricTasksArgs = {
  assignee?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameLike?: InputMaybe<Scalars["String"]>;
};

export type IQueryCamunda_ProcessDefinitionArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_ProcessDefinitionsArgs = {
  key?: InputMaybe<Scalars["String"]>;
  latest?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  startableInTasklist?: InputMaybe<Scalars["Boolean"]>;
  suspended?: InputMaybe<Scalars["Boolean"]>;
  version?: InputMaybe<Scalars["Int"]>;
};

export type IQueryCamunda_ProcessInstanceArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_ProcessInstancesArgs = {
  businessKey?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
};

export type IQueryCamunda_TaskArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_TasksArgs = {
  assignee?: InputMaybe<Scalars["String"]>;
  businessKey?: InputMaybe<Scalars["String"]>;
  filterJson?: InputMaybe<Scalars["String"]>;
  firstResult: Scalars["Int"];
  isAssigned?: InputMaybe<Scalars["Boolean"]>;
  maxResults: Scalars["Int"];
  name?: InputMaybe<Scalars["String"]>;
};

export type IQueryCamunda_Tasks_StatsArgs = {
  assignee?: InputMaybe<Scalars["String"]>;
  businessKey?: InputMaybe<Scalars["String"]>;
  filterJson?: InputMaybe<Scalars["String"]>;
  isAssigned?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type IQueryCamunda_UserArgs = {
  id: Scalars["ID"];
};

export type IQueryCamunda_UsersArgs = {
  email?: InputMaybe<Scalars["String"]>;
  emailLike?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  firstNameLike?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["ID"]>;
  ids?: InputMaybe<ReadonlyArray<Scalars["ID"]>>;
  lastName?: InputMaybe<Scalars["String"]>;
  lastNameLike?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RateLimitedAction
 */
export interface IRateLimitedAction {
  readonly __typename?: "RateLimitedAction";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.action_party
   */
  readonly action_party?: Maybe<IRateLimitedAction_ActionParty | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.action_type
   */
  readonly action_type?: Maybe<IRateLimitedAction_ActionType | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.email_address
   */
  readonly email_address?: Maybe<IRateLimitedAction_EmailAddress>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.phone_number
   */
  readonly phone_number?: Maybe<IRateLimitedAction_PhoneNumber>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.push_notification
   */
  readonly push_notification?: Maybe<IRateLimitedAction_PushNotification>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RateLimitedAction.ActionParty
 */
export enum IRateLimitedAction_ActionParty {
  PncEarlyStageCollections = "PNC_EARLY_STAGE_COLLECTIONS",
  TrueAccord = "TRUE_ACCORD"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RateLimitedAction.ActionType
 */
export enum IRateLimitedAction_ActionType {
  Call = "CALL",
  Email = "EMAIL",
  Letter = "LETTER",
  PushNotification = "PUSH_NOTIFICATION",
  Sms = "SMS",
  Voicemail = "VOICEMAIL",
  WarmTransfer = "WARM_TRANSFER"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RateLimitedAction.EmailAddress
 */
export interface IRateLimitedAction_EmailAddress {
  readonly __typename?: "RateLimitedAction_EmailAddress";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.EmailAddress.key
   */
  readonly key?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RateLimitedAction.PhoneNumber
 */
export interface IRateLimitedAction_PhoneNumber {
  readonly __typename?: "RateLimitedAction_PhoneNumber";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.PhoneNumber.key
   */
  readonly key?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RateLimitedAction.PushNotification
 */
export interface IRateLimitedAction_PushNotification {
  readonly __typename?: "RateLimitedAction_PushNotification";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RateLimitedAction.PushNotification.key
   */
  readonly key?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ReEnablePaymentOptionEntry
 */
export interface IReEnablePaymentOptionEntry {
  readonly __typename?: "ReEnablePaymentOptionEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ReEnablePaymentOptionEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ReEnablePaymentOptionEntry.payment_option_type
   */
  readonly payment_option_type?: Maybe<IReEnablePaymentOptionEntry_PaymentOptionType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ReEnablePaymentOptionEntry.reason
   */
  readonly reason?: Maybe<IReEnablePaymentOptionEntry_ReEnableReason | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ReEnablePaymentOptionEntry.PaymentOptionType
 */
export enum IReEnablePaymentOptionEntry_PaymentOptionType {
  Ach = "ACH",
  CreditCard = "CREDIT_CARD",
  DebitCard = "DEBIT_CARD"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ReEnablePaymentOptionEntry.ReEnableReason
 */
export enum IReEnablePaymentOptionEntry_ReEnableReason {
  CustomerError = "CUSTOMER_ERROR",
  DebtConsolidatorError = "DEBT_CONSOLIDATOR_ERROR",
  FailedPaymentException = "FAILED_PAYMENT_EXCEPTION",
  TaError = "TA_ERROR",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RecurringPaymentToken
 */
export interface IRecurringPaymentToken {
  readonly __typename?: "RecurringPaymentToken";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RecurringPaymentToken.ach_transaction_token
   */
  readonly ach_transaction_token?: Maybe<IAchTransactionToken>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RecurringPaymentToken.healpay_payment_token
   */
  readonly healpay_payment_token?: Maybe<IHealpayPaymentToken>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RecurringPaymentToken.lucentpay_payment_token
   */
  readonly lucentpay_payment_token?: Maybe<ILucentpayPaymentToken>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RecurringPaymentToken.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RecurringPaymentToken.spreedly_payment_token
   */
  readonly spreedly_payment_token?: Maybe<ISpreedlyPaymentToken>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RecurringPaymentToken.usaepay_payment_token
   */
  readonly usaepay_payment_token?: Maybe<IUsaepayPaymentToken>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RefundReasonEnumContainer.RefundReason
 */
export enum IRefundReasonEnumContainer_RefundReason {
  Chargeback = "CHARGEBACK",
  CustomerRequest = "CUSTOMER_REQUEST",
  IncorrectAmount = "INCORRECT_AMOUNT",
  NsfCheck = "NSF_CHECK"
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile
 */
export interface IRemoteFile {
  readonly __typename?: "RemoteFile";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.batch_id
   */
  readonly batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.bulk_operation_upload
   */
  readonly bulk_operation_upload?: Maybe<IRemoteFile_BulkOperationUpload>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.bulk_rendered_content
   */
  readonly bulk_rendered_content?: Maybe<IRemoteFile_BulkRenderedContent>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.cascade_pdp_report_request
   */
  readonly cascade_pdp_report_request?: Maybe<ITask_Runner_CascadePdpReportRequest>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.cms_asset
   */
  readonly cms_asset?: Maybe<IRemoteFile_CmsAsset>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.content_type
   */
  readonly content_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.creditor_upload
   */
  readonly creditor_upload?: Maybe<IRemoteFile_CreditorUpload>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.debt_snapshot
   */
  readonly debt_snapshot?: Maybe<IRemoteFile_DebtSnapshot>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.debt_verification_upload
   */
  readonly debt_verification_upload?: Maybe<IRemoteFile_DebtVerificationUpload>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.debtor_communications_request
   */
  readonly debtor_communications_request?: Maybe<ITask_Runner_DebtorCommunicationsRequest>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.forwarded_file_upload
   */
  readonly forwarded_file_upload?: Maybe<IRemoteFile_ForwardedFileUpload>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.full_path
   */
  readonly full_path?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.media_request_upload
   */
  readonly media_request_upload?: Maybe<IRemoteFile_MediaRequestUpload>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.render_content_request
   */
  readonly render_content_request?: Maybe<ITask_Runner_RenderContentRequest>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.rendered_collection_report
   */
  readonly rendered_collection_report?: Maybe<IRenderedCollectionReport>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.sha1_fingerprint
   */
  readonly sha1_fingerprint?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.size
   */
  readonly size?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.tags
   */
  readonly tags: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.BulkOperationUpload
 */
export interface IRemoteFile_BulkOperationUpload {
  readonly __typename?: "RemoteFile_BulkOperationUpload";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.BulkOperationUpload.operation
   */
  readonly operation?: Maybe<IBulk_Operations_BulkOperationType | "%future added value">;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.BulkOperationUpload.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.BulkOperationUpload.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.BulkRenderedContent
 */
export interface IRemoteFile_BulkRenderedContent {
  readonly __typename?: "RemoteFile_BulkRenderedContent";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.BulkRenderedContent.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.BulkRenderedContent.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.CmsAsset
 */
export interface IRemoteFile_CmsAsset {
  readonly __typename?: "RemoteFile_CmsAsset";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.audio_info
   */
  readonly audio_info?: Maybe<Scalars["RemoteFile_CmsAsset_AudioInfo"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.csv_info
   */
  readonly csv_info?: Maybe<Scalars["RemoteFile_CmsAsset_CsvInfo"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.filename
   */
  readonly filename?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.image_info
   */
  readonly image_info?: Maybe<IRemoteFile_CmsAsset_ImageInfo>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.pdf_info
   */
  readonly pdf_info?: Maybe<Scalars["RemoteFile_CmsAsset_PdfInfo"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.status
   */
  readonly status?: Maybe<IRemoteFile_CmsAsset_Status | "%future added value">;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.CmsAsset.ImageInfo
 */
export interface IRemoteFile_CmsAsset_ImageInfo {
  readonly __typename?: "RemoteFile_CmsAsset_ImageInfo";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.ImageInfo.height
   */
  readonly height?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CmsAsset.ImageInfo.width
   */
  readonly width?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.CmsAsset.Status
 */
export enum IRemoteFile_CmsAsset_Status {
  Published = "PUBLISHED",
  Unsubmitted = "UNSUBMITTED"
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.CreditorUpload
 */
export interface IRemoteFile_CreditorUpload {
  readonly __typename?: "RemoteFile_CreditorUpload";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CreditorUpload.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CreditorUpload.original_filename
   */
  readonly original_filename?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CreditorUpload.status
   */
  readonly status?: Maybe<IRemoteFile_CreditorUpload_Status | "%future added value">;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.CreditorUpload.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.CreditorUpload.Status
 */
export enum IRemoteFile_CreditorUpload_Status {
  Approved = "APPROVED",
  Defected = "DEFECTED",
  Processed = "PROCESSED",
  Processing = "PROCESSING",
  Rejected = "REJECTED",
  RequiresApproval = "REQUIRES_APPROVAL",
  Submitted = "SUBMITTED",
  Unsubmitted = "UNSUBMITTED",
  Unsupported = "UNSUPPORTED"
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.DebtSnapshot
 */
export interface IRemoteFile_DebtSnapshot {
  readonly __typename?: "RemoteFile_DebtSnapshot";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.DebtSnapshot.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.DebtSnapshot.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.DebtSnapshot.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.DebtVerificationUpload
 */
export interface IRemoteFile_DebtVerificationUpload {
  readonly __typename?: "RemoteFile_DebtVerificationUpload";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.DebtVerificationUpload.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.DebtVerificationUpload.original_filename
   */
  readonly original_filename?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.DebtVerificationUpload.status
   */
  readonly status?: Maybe<IRemoteFile_DebtVerificationUpload_Status | "%future added value">;
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.DebtVerificationUpload.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.DebtVerificationUpload.Status
 */
export enum IRemoteFile_DebtVerificationUpload_Status {
  Discarded = "DISCARDED",
  PendingUpload = "PENDING_UPLOAD",
  Received = "RECEIVED",
  Rejected = "REJECTED",
  Uploaded = "UPLOADED"
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.ForwardedFileUpload
 */
export interface IRemoteFile_ForwardedFileUpload {
  readonly __typename?: "RemoteFile_ForwardedFileUpload";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.ForwardedFileUpload.original_filename
   */
  readonly original_filename?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/remote_files.proto
 * com.trueaccord.proto.RemoteFile.MediaRequestUpload
 */
export interface IRemoteFile_MediaRequestUpload {
  readonly __typename?: "RemoteFile_MediaRequestUpload";
  /**
   * File: collectr/proto/remote_files.proto
   * com.trueaccord.proto.RemoteFile.MediaRequestUpload.original_filename
   */
  readonly original_filename?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RenderedCollectionReport
 */
export interface IRenderedCollectionReport {
  readonly __typename?: "RenderedCollectionReport";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RenderedCollectionReport.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RenderedCollectionReport.file_format
   */
  readonly file_format?: Maybe<IRenderedCollectionReport_FileFormat | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RenderedCollectionReport.is_void
   */
  readonly is_void?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RenderedCollectionReport.no_payment_activity
   */
  readonly no_payment_activity?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.RenderedCollectionReport.report_period
   */
  readonly report_period?: Maybe<ICreditors_ReportPeriod>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RenderedCollectionReport.FileFormat
 */
export enum IRenderedCollectionReport_FileFormat {
  Xlsx = "XLSX"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.RepeatedPlacement
 */
export interface IRepeatedPlacement {
  readonly __typename?: "RepeatedPlacement";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.RepeatedPlacement.creditor_customer_id
   */
  readonly creditor_customer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.RepeatedPlacement.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.RepeatedPlacement.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.RepeatedPlacement.relationship
   */
  readonly relationship?: Maybe<IImmutableRepeatedPlacement_Relationship | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.RepeatedPlacement.time_created
   */
  readonly time_created?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.RepeatedPlacementEntry
 */
export interface IRepeatedPlacementEntry {
  readonly __typename?: "RepeatedPlacementEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.RepeatedPlacementEntry.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.RepeatedPlacementEntry.repeated_placement
   */
  readonly repeated_placement?: Maybe<IImmutableRepeatedPlacement>;
}

/**
 * File: collectr/proto/repo_queue.proto
 * com.trueaccord.proto.RepoQueueItem
 */
export interface IRepoQueueItem {
  readonly __typename?: "RepoQueueItem";
  /**
   * File: collectr/proto/repo_queue.proto
   * com.trueaccord.proto.RepoQueueItem.body
   */
  readonly body?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/repo_queue.proto
   * com.trueaccord.proto.RepoQueueItem.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/repo_queue.proto
   * com.trueaccord.proto.RepoQueueItem.queue_name
   */
  readonly queue_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/repo_queue.proto
   * com.trueaccord.proto.RepoQueueItem.sequence_num
   */
  readonly sequence_num?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ReportCorrectionEntry
 */
export interface IReportCorrectionEntry {
  readonly __typename?: "ReportCorrectionEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ReportCorrectionEntry.correction_type
   */
  readonly correction_type?: Maybe<IReportCorrectionEntry_CorrectionType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ReportCorrectionEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ReportCorrectionEntry.original_entry_id
   */
  readonly original_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ReportCorrectionEntry.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ReportCorrectionEntry.CorrectionType
 */
export enum IReportCorrectionEntry_CorrectionType {
  Resend = "RESEND",
  Unknown = "UNKNOWN",
  Void = "VOID"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.RequestedBy
 */
export enum IRequestedBy {
  Attorney = "ATTORNEY",
  AttorneyDebtConsolidator = "ATTORNEY_DEBT_CONSOLIDATOR",
  Creditor = "CREDITOR",
  Customer = "CUSTOMER",
  DebtConsolidator = "DEBT_CONSOLIDATOR",
  ObsoleteSecondary = "OBSOLETE_SECONDARY",
  PowerOfAttorney = "POWER_OF_ATTORNEY",
  RequestByUnknown = "REQUEST_BY_UNKNOWN",
  Trueaccord = "TRUEACCORD"
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.RequiredAgentDisclosures
 */
export enum IRequiredAgentDisclosures {
  DiscoverCreditBureau = "DISCOVER_CREDIT_BUREAU",
  ObsoleteAmexCreditReporting = "OBSOLETE_AMEX_CREDIT_REPORTING"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.RequiredCommunicationClause
 */
export enum IRequiredCommunicationClause {
  DiscoverAccountId = "DISCOVER_ACCOUNT_ID",
  Lendup = "LENDUP",
  ObsoleteResurgentOregon = "OBSOLETE_RESURGENT_OREGON",
  PaypalAccountId = "PAYPAL_ACCOUNT_ID",
  Resurgent = "RESURGENT"
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.ScraDebtScrub
 */
export interface IScraDebtScrub {
  readonly __typename?: "ScraDebtScrub";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.experian
   */
  readonly experian?: Maybe<IScraDebtScrub_Experian>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.scra_dmdc
   */
  readonly scra_dmdc?: Maybe<IScraDebtScrub_ScraDmdc>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.ScraDebtScrub.ActiveDutyMilitaryFlag
 */
export enum IScraDebtScrub_ActiveDutyMilitaryFlag {
  NotOnActiveDutyOnStatusDate = "NOT_ON_ACTIVE_DUTY_ON_STATUS_DATE",
  NoActiveDutyInfoOrError = "NO_ACTIVE_DUTY_INFO_OR_ERROR",
  OnActiveDutyOnStatusContinuing = "ON_ACTIVE_DUTY_ON_STATUS_CONTINUING",
  OnActiveDutyOnStatusDateEnded = "ON_ACTIVE_DUTY_ON_STATUS_DATE_ENDED"
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.ScraDebtScrub.Experian
 */
export interface IScraDebtScrub_Experian {
  readonly __typename?: "ScraDebtScrub_Experian";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.request
   */
  readonly request?: Maybe<IScraDebtScrub_Experian_Request>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.response
   */
  readonly response?: Maybe<IScraDebtScrub_Experian_Response>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.state
   */
  readonly state?: Maybe<IState>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.ScraDebtScrub.Experian.Request
 */
export interface IScraDebtScrub_Experian_Request {
  readonly __typename?: "ScraDebtScrub_Experian_Request";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Request.address
   */
  readonly address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Request.date_of_birth
   */
  readonly date_of_birth?: Maybe<IDate>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Request.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Request.person_name
   */
  readonly person_name?: Maybe<IPersonName>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Request.phones
   */
  readonly phones: ReadonlyArray<Maybe<IPhoneNumber>>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Request.scra_scrub
   */
  readonly scra_scrub?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Request.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Request.ssn_provided
   */
  readonly ssn_provided?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.ScraDebtScrub.Experian.Response
 */
export interface IScraDebtScrub_Experian_Response {
  readonly __typename?: "ScraDebtScrub_Experian_Response";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.active_duty_military_details
   */
  readonly active_duty_military_details?: Maybe<IScraDebtScrub_Experian_Response_ActiveDutyMilitary>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.active_military_process
   */
  readonly active_military_process?: Maybe<
    IScraDebtScrub_Experian_Response_ActiveDutyMilitaryProcessFlag | "%future added value"
  >;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary
 */
export interface IScraDebtScrub_Experian_Response_ActiveDutyMilitary {
  readonly __typename?: "ScraDebtScrub_Experian_Response_ActiveDutyMilitary";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_active_duty
   */
  readonly military_active_duty?: Maybe<IScraDebtScrub_ActiveDutyMilitaryFlag | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_active_duty_begin_date
   */
  readonly military_active_duty_begin_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_active_duty_end_date
   */
  readonly military_active_duty_end_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_active_duty_recall
   */
  readonly military_active_duty_recall?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_certificate_id
   */
  readonly military_certificate_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_date_of_match
   */
  readonly military_date_of_match?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_eid_begin_date
   */
  readonly military_eid_begin_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_eid_end_date
   */
  readonly military_eid_end_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_eid_service_comp
   */
  readonly military_eid_service_comp?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_error_code
   */
  readonly military_error_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_left_active_duty
   */
  readonly military_left_active_duty?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_match_result_code
   */
  readonly military_match_result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitary.military_service_comp
   */
  readonly military_service_comp?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.ScraDebtScrub.Experian.Response.ActiveDutyMilitaryProcessFlag
 */
export enum IScraDebtScrub_Experian_Response_ActiveDutyMilitaryProcessFlag {
  ActiveDutyMilitaryReturned = "ACTIVE_DUTY_MILITARY_RETURNED",
  RecordNotSentToDmdc = "RECORD_NOT_SENT_TO_DMDC",
  RecordSentToDmdc = "RECORD_SENT_TO_DMDC"
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.ScraDebtScrub.ScraDmdc
 */
export interface IScraDebtScrub_ScraDmdc {
  readonly __typename?: "ScraDebtScrub_ScraDmdc";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.ScraDmdc.active_duty_end_date
   */
  readonly active_duty_end_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.ScraDmdc.active_duty_start_date
   */
  readonly active_duty_start_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.ScraDmdc.certificate_id
   */
  readonly certificate_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.ScraDmdc.military_active_duty
   */
  readonly military_active_duty?: Maybe<IScraDebtScrub_ActiveDutyMilitaryFlag | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.ScraDebtScrub.ScraDmdc.response_received_date
   */
  readonly response_received_date?: Maybe<IDate>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ScrubEntry
 */
export interface IScrubEntry {
  readonly __typename?: "ScrubEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ScrubEntry.scrub_reasons
   */
  readonly scrub_reasons: ReadonlyArray<Maybe<IScrubEntry_ScrubReason | "%future added value">>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ScrubEntry.ScrubReason
 */
export enum IScrubEntry_ScrubReason {
  LowBalance = "LOW_BALANCE",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/sendgrid_event.proto
 * com.trueaccord.proto.SendGridEvent
 */
export interface ISendGridEvent {
  readonly __typename?: "SendGridEvent";
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.attempt
   */
  readonly attempt?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.creditor_log_entry_id
   */
  readonly creditor_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.debt_log_entry_id
   */
  readonly debt_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.event
   */
  readonly event?: Maybe<ISendGridEvent_Event | "%future added value">;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.ip
   */
  readonly ip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.raw_send_grid_event_batch_id
   */
  readonly raw_send_grid_event_batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.response
   */
  readonly response?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.sg_event_id
   */
  readonly sg_event_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.sg_message_id
   */
  readonly sg_message_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.true_accord_engage_communication_activity_entry_id
   */
  readonly true_accord_engage_communication_activity_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.type
   */
  readonly type?: Maybe<IBounceTypeMessage_BounceTypeEnum | "%future added value">;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.url
   */
  readonly url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/sendgrid_event.proto
   * com.trueaccord.proto.SendGridEvent.useragent
   */
  readonly useragent?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/sendgrid_event.proto
 * com.trueaccord.proto.SendGridEvent.Event
 */
export enum ISendGridEvent_Event {
  Bounce = "bounce",
  Click = "click",
  Deferred = "deferred",
  Delivered = "delivered",
  Dropped = "dropped",
  Open = "open",
  Processed = "processed",
  Spamreport = "spamreport",
  Unsubscribe = "unsubscribe"
}

/**
 * File: collectr/proto/sitemaps.proto
 * com.trueaccord.proto.Sitemap
 */
export interface ISitemap {
  readonly __typename?: "Sitemap";
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/sitemaps.proto
   * com.trueaccord.proto.Sitemap.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/sitemaps.proto
   * com.trueaccord.proto.Sitemap.urls
   */
  readonly urls: ReadonlyArray<Maybe<ISitemap_Url>>;
}

/**
 * File: collectr/proto/sitemaps.proto
 * com.trueaccord.proto.Sitemap.Url
 */
export interface ISitemap_Url {
  readonly __typename?: "Sitemap_Url";
  /**
   * File: collectr/proto/sitemaps.proto
   * com.trueaccord.proto.Sitemap.Url.loc
   */
  readonly loc?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.SpecialProgramDiscussed
 */
export interface ISpecialProgramDiscussed {
  readonly __typename?: "SpecialProgramDiscussed";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramDiscussed.discussed_date
   */
  readonly discussed_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramDiscussed.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramDiscussed.recorded_by
   */
  readonly recorded_by?: Maybe<ISpecialProgram_RecordedBy | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramDiscussed.special_program_type
   */
  readonly special_program_type?: Maybe<ISpecialProgram_SpecialProgramType | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.SpecialProgramOptInOptOut
 */
export interface ISpecialProgramOptInOptOut {
  readonly __typename?: "SpecialProgramOptInOptOut";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramOptInOptOut.et_agent
   */
  readonly et_agent?: Maybe<Scalars["SpecialProgramOptInOptOut_ETAgent"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramOptInOptOut.form
   */
  readonly form?: Maybe<ISpecialProgramOptInOptOut_Form>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramOptInOptOut.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramOptInOptOut.opt_decision
   */
  readonly opt_decision?: Maybe<ISpecialProgramOptInOptOut_OptDecision | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramOptInOptOut.special_program_type
   */
  readonly special_program_type?: Maybe<ISpecialProgram_SpecialProgramType | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.SpecialProgramOptInOptOut.Form
 */
export interface ISpecialProgramOptInOptOut_Form {
  readonly __typename?: "SpecialProgramOptInOptOut_Form";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.SpecialProgramOptInOptOut.Form.agreed_to_oasis_terms
   */
  readonly agreed_to_oasis_terms?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.SpecialProgramOptInOptOut.OptDecision
 */
export enum ISpecialProgramOptInOptOut_OptDecision {
  In = "IN",
  Out = "OUT"
}

/**
 * File: collectr/proto/special_programs.proto
 * com.trueaccord.proto.SpecialProgram.RecordedBy
 */
export enum ISpecialProgram_RecordedBy {
  EtAgent = "ET_AGENT",
  Form = "FORM"
}

/**
 * File: collectr/proto/special_programs.proto
 * com.trueaccord.proto.SpecialProgram.SpecialProgramType
 */
export enum ISpecialProgram_SpecialProgramType {
  Apollo = "APOLLO",
  Oasis = "OASIS"
}

/**
 * File: collectr/proto/speedpay.proto
 * com.trueaccord.proto.SpeedpayTransaction
 */
export interface ISpeedpayTransaction {
  readonly __typename?: "SpeedpayTransaction";
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.ach_guarantee_code
   */
  readonly ach_guarantee_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.auth_code
   */
  readonly auth_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.bank_name
   */
  readonly bank_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.check_number
   */
  readonly check_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.custom_result_information
   */
  readonly custom_result_information: ReadonlyArray<Maybe<IKeyValue>>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.error_text
   */
  readonly error_text?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.fee_check_number
   */
  readonly fee_check_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.payment_id
   */
  readonly payment_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.recurring_schedule_id
   */
  readonly recurring_schedule_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.reference_id
   */
  readonly reference_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.response_code
   */
  readonly response_code?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/speedpay.proto
   * com.trueaccord.proto.SpeedpayTransaction.result_value
   */
  readonly result_value?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/spreedly.proto
 * com.trueaccord.proto.SpreedlyCommonFields
 */
export interface ISpreedlyCommonFields {
  readonly __typename?: "SpreedlyCommonFields";
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyCommonFields.created_at
   */
  readonly created_at?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyCommonFields.token
   */
  readonly token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyCommonFields.updated_at
   */
  readonly updated_at?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/spreedly.proto
 * com.trueaccord.proto.SpreedlyCommonTransactionFields
 */
export interface ISpreedlyCommonTransactionFields {
  readonly __typename?: "SpreedlyCommonTransactionFields";
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyCommonTransactionFields.message
   */
  readonly message?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyCommonTransactionFields.raw_data
   */
  readonly raw_data?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyCommonTransactionFields.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyCommonTransactionFields.succeeded
   */
  readonly succeeded?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyCommonTransactionFields.transaction_type
   */
  readonly transaction_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/spreedly.proto
 * com.trueaccord.proto.SpreedlyPaymentMethod
 */
export interface ISpreedlyPaymentMethod {
  readonly __typename?: "SpreedlyPaymentMethod";
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyPaymentMethod._include_common_fields
   */
  readonly _include_common_fields?: Maybe<ISpreedlyCommonFields>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyPaymentMethod.credit_card_info
   */
  readonly credit_card_info?: Maybe<IPaymentCardInfo>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyPaymentMethod.email
   */
  readonly email?: Maybe<IEmailAddress>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyPaymentMethod.phone_number
   */
  readonly phone_number?: Maybe<IPhoneNumber>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyPaymentMethod.storage_state
   */
  readonly storage_state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyPaymentMethod.test
   */
  readonly test?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/spreedly.proto
 * com.trueaccord.proto.SpreedlyPaymentToken
 */
export interface ISpreedlyPaymentToken {
  readonly __typename?: "SpreedlyPaymentToken";
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyPaymentToken.payment_method_info
   */
  readonly payment_method_info?: Maybe<ICardPaymentMethodInfo>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyPaymentToken.payment_token
   */
  readonly payment_token?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/spreedly.proto
 * com.trueaccord.proto.SpreedlyTransaction
 */
export interface ISpreedlyTransaction {
  readonly __typename?: "SpreedlyTransaction";
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction._include_common_fields
   */
  readonly _include_common_fields?: Maybe<ISpreedlyCommonFields>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction._include_common_transaction_fields
   */
  readonly _include_common_transaction_fields?: Maybe<ISpreedlyCommonTransactionFields>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.amount
   */
  readonly amount?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.currency_code
   */
  readonly currency_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.email
   */
  readonly email?: Maybe<IEmailAddress>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.gateway_token
   */
  readonly gateway_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.gateway_transaction_id
   */
  readonly gateway_transaction_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.ip
   */
  readonly ip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.merchant_location_descriptor
   */
  readonly merchant_location_descriptor?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.merchant_name_descriptor
   */
  readonly merchant_name_descriptor?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.on_test_gateway
   */
  readonly on_test_gateway?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.order_id
   */
  readonly order_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.payment_method
   */
  readonly payment_method?: Maybe<ISpreedlyPaymentMethod>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.transaction_response
   */
  readonly transaction_response?: Maybe<ISpreedlyTransaction_SpreedlyTransactionResponse>;
}

/**
 * File: collectr/proto/spreedly.proto
 * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse
 */
export interface ISpreedlyTransaction_SpreedlyTransactionResponse {
  readonly __typename?: "SpreedlyTransaction_SpreedlyTransactionResponse";
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse._include_common_fields
   */
  readonly _include_common_fields?: Maybe<ISpreedlyCommonFields>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.avs_code
   */
  readonly avs_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.avs_message
   */
  readonly avs_message?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.cancelled
   */
  readonly cancelled?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.cvv_code
   */
  readonly cvv_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.cvv_message
   */
  readonly cvv_message?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.error_code
   */
  readonly error_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.error_detail
   */
  readonly error_detail?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.message
   */
  readonly message?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.pending
   */
  readonly pending?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/spreedly.proto
   * com.trueaccord.proto.SpreedlyTransaction.SpreedlyTransactionResponse.success
   */
  readonly success?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.State
 */
export interface IState {
  readonly __typename?: "State";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.State.file_name
   */
  readonly file_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.State.requested_msec
   */
  readonly requested_msec?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.State.status
   */
  readonly status?: Maybe<IState_Status | "%future added value">;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.State.Status
 */
export enum IState_Status {
  Completed = "COMPLETED",
  CompletedWithError = "COMPLETED_WITH_ERROR",
  CompletedWithSystemError = "COMPLETED_WITH_SYSTEM_ERROR",
  New = "NEW",
  Requested = "REQUESTED"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.StatuteOfLimitationsUpdateEntry
 */
export interface IStatuteOfLimitationsUpdateEntry {
  readonly __typename?: "StatuteOfLimitationsUpdateEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.StatuteOfLimitationsUpdateEntry.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.StatuteOfLimitationsUpdateEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.StatuteOfLimitationsUpdateEntry.source
   */
  readonly source?: Maybe<IStatuteOfLimitationsUpdateEntry_Source | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.StatuteOfLimitationsUpdateEntry.updated_date
   */
  readonly updated_date?: Maybe<IDate>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.StatuteOfLimitationsUpdateEntry.Source
 */
export enum IStatuteOfLimitationsUpdateEntry_Source {
  Creditor = "CREDITOR",
  Trueaccord = "TRUEACCORD"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TargetedCampaignEntry
 */
export interface ITargetedCampaignEntry {
  readonly __typename?: "TargetedCampaignEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.action
   */
  readonly action?: Maybe<ITargetedCampaignEntry_ActionType | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.batch_id
   */
  readonly batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.channel
   */
  readonly channel?: Maybe<ICommunications_Channel_Channel | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.end_timestamp
   */
  readonly end_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.maxDiscountOverride
   */
  readonly maxDiscountOverride?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.sequence_id
   */
  readonly sequence_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.start_timestamp
   */
  readonly start_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.template_id
   */
  readonly template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TargetedCampaignEntry.valid_debt_statuses
   */
  readonly valid_debt_statuses: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TargetedCampaignEntry.ActionType
 */
export enum ITargetedCampaignEntry_ActionType {
  CancelBatch = "CANCEL_BATCH",
  OfferDiscount = "OFFER_DISCOUNT",
  OfferDiscountAndEmail = "OFFER_DISCOUNT_AND_EMAIL",
  ReplaceContentForNextSend = "REPLACE_CONTENT_FOR_NEXT_SEND",
  SendNewContentAsap = "SEND_NEW_CONTENT_ASAP"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TerminalDebtEntry
 */
export interface ITerminalDebtEntry {
  readonly __typename?: "TerminalDebtEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.direct_input
   */
  readonly direct_input?: Maybe<ITerminalDebtEntry_DirectInput>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.scra_debt_scrub
   */
  readonly scra_debt_scrub?: Maybe<IScraDebtScrub>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.terminal_debt_scrub
   */
  readonly terminal_debt_scrub?: Maybe<ITerminalDebtScrub>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TerminalDebtEntry.DirectInput
 */
export interface ITerminalDebtEntry_DirectInput {
  readonly __typename?: "TerminalDebtEntry_DirectInput";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.bankruptcy
   */
  readonly bankruptcy?: Maybe<ITerminalDebt_Bankruptcy>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.deceased
   */
  readonly deceased?: Maybe<ITerminalDebt_Deceased>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.from_amex
   */
  readonly from_amex?: Maybe<ITerminalDebtEntry_DirectInput_Amex>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.from_customer
   */
  readonly from_customer?: Maybe<ITerminalDebtEntry_DirectInput_Customer>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.from_dmp
   */
  readonly from_dmp?: Maybe<ITerminalDebtEntry_DirectInput_Dmp>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.from_encore
   */
  readonly from_encore?: Maybe<ITerminalDebtEntry_DirectInput_Encore>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.from_fico
   */
  readonly from_fico?: Maybe<ITerminalDebtEntry_DirectInput_Fico>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.from_resurgent
   */
  readonly from_resurgent?: Maybe<ITerminalDebtEntry_DirectInput_Resurgent>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.scra
   */
  readonly scra?: Maybe<ITerminalDebt_Scra>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TerminalDebtEntry.DirectInput.AMEX
 */
export interface ITerminalDebtEntry_DirectInput_Amex {
  readonly __typename?: "TerminalDebtEntry_DirectInput_AMEX";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.AMEX.is_from_amex
   */
  readonly is_from_amex?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TerminalDebtEntry.DirectInput.Customer
 */
export interface ITerminalDebtEntry_DirectInput_Customer {
  readonly __typename?: "TerminalDebtEntry_DirectInput_Customer";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.Customer.description
   */
  readonly description?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TerminalDebtEntry.DirectInput.DMP
 */
export interface ITerminalDebtEntry_DirectInput_Dmp {
  readonly __typename?: "TerminalDebtEntry_DirectInput_DMP";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.DMP.is_from_dmp
   */
  readonly is_from_dmp?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TerminalDebtEntry.DirectInput.Encore
 */
export interface ITerminalDebtEntry_DirectInput_Encore {
  readonly __typename?: "TerminalDebtEntry_DirectInput_Encore";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.Encore.is_from_encore
   */
  readonly is_from_encore?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TerminalDebtEntry.DirectInput.Fico
 */
export interface ITerminalDebtEntry_DirectInput_Fico {
  readonly __typename?: "TerminalDebtEntry_DirectInput_Fico";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.Fico.raw_line_ids
   */
  readonly raw_line_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TerminalDebtEntry.DirectInput.Resurgent
 */
export interface ITerminalDebtEntry_DirectInput_Resurgent {
  readonly __typename?: "TerminalDebtEntry_DirectInput_Resurgent";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TerminalDebtEntry.DirectInput.Resurgent.is_from_resurgent
   */
  readonly is_from_resurgent?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub
 */
export interface ITerminalDebtScrub {
  readonly __typename?: "TerminalDebtScrub";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.experian
   */
  readonly experian?: Maybe<ITerminalDebtScrub_Experian>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian
 */
export interface ITerminalDebtScrub_Experian {
  readonly __typename?: "TerminalDebtScrub_Experian";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.request
   */
  readonly request?: Maybe<ITerminalDebtScrub_Experian_Request>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.response
   */
  readonly response?: Maybe<ITerminalDebtScrub_Experian_Response>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.retry_count
   */
  readonly retry_count?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.state
   */
  readonly state?: Maybe<IState>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Request
 */
export interface ITerminalDebtScrub_Experian_Request {
  readonly __typename?: "TerminalDebtScrub_Experian_Request";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Request.address
   */
  readonly address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Request.date_of_birth
   */
  readonly date_of_birth?: Maybe<IDate>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Request.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Request.first_sweep_account_date
   */
  readonly first_sweep_account_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Request.person_name
   */
  readonly person_name?: Maybe<IPersonName>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Request.phones
   */
  readonly phones: ReadonlyArray<Maybe<IPhoneNumber>>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Request.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Request.ssn_provided
   */
  readonly ssn_provided?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Response
 */
export interface ITerminalDebtScrub_Experian_Response {
  readonly __typename?: "TerminalDebtScrub_Experian_Response";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.address_match
   */
  readonly address_match?: Maybe<ITerminalDebtScrub_Experian_Response_AddressMatch | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.bankruptcy
   */
  readonly bankruptcy?: Maybe<ITerminalDebtScrub_Experian_Response_Bankruptcy>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.deceased
   */
  readonly deceased?: Maybe<ITerminalDebtScrub_Experian_Response_Deceased>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.name_match
   */
  readonly name_match?: Maybe<ITerminalDebtScrub_Experian_Response_NameMatch | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.primary_result_code
   */
  readonly primary_result_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.return_code
   */
  readonly return_code?: Maybe<ITerminalDebtScrub_Experian_Response_ReturnCode | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.ssn_match
   */
  readonly ssn_match?: Maybe<ITerminalDebtScrub_Experian_Response_SsnMatch | "%future added value">;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.ssn_return_code
   */
  readonly ssn_return_code?: Maybe<
    ITerminalDebtScrub_Experian_Response_SsnReturnCode | "%future added value"
  >;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.AddressMatch
 */
export enum ITerminalDebtScrub_Experian_Response_AddressMatch {
  AddressMatch = "ADDRESS_MATCH",
  AddressMatchUnknown = "ADDRESS_MATCH_UNKNOWN",
  AddressNoMatch = "ADDRESS_NO_MATCH"
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy
 */
export interface ITerminalDebtScrub_Experian_Response_Bankruptcy {
  readonly __typename?: "TerminalDebtScrub_Experian_Response_Bankruptcy";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.asset_no_asset_indicator
   */
  readonly asset_no_asset_indicator?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_address_1
   */
  readonly attorney_address_1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_address_2
   */
  readonly attorney_address_2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_address_3
   */
  readonly attorney_address_3?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_address_4
   */
  readonly attorney_address_4?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_city
   */
  readonly attorney_city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_email
   */
  readonly attorney_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_name
   */
  readonly attorney_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_phone
   */
  readonly attorney_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_state
   */
  readonly attorney_state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.attorney_zip
   */
  readonly attorney_zip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.bankruptcy_court_id
   */
  readonly bankruptcy_court_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.case_number
   */
  readonly case_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.chapter
   */
  readonly chapter?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.code_341_date
   */
  readonly code_341_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.code_341_location
   */
  readonly code_341_location?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.code_341_time
   */
  readonly code_341_time?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.court_address_1
   */
  readonly court_address_1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.court_address_2
   */
  readonly court_address_2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.court_city
   */
  readonly court_city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.court_district
   */
  readonly court_district?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.court_email
   */
  readonly court_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.court_phone
   */
  readonly court_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.court_state
   */
  readonly court_state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.court_zip
   */
  readonly court_zip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_closed
   */
  readonly date_closed?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_confirmed
   */
  readonly date_confirmed?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_discharged
   */
  readonly date_discharged?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_dismissal_vacated
   */
  readonly date_dismissal_vacated?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_dismissed
   */
  readonly date_dismissed?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_filed
   */
  readonly date_filed?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_of_claim
   */
  readonly date_of_claim?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_of_objection
   */
  readonly date_of_objection?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_reopened
   */
  readonly date_reopened?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_transferred
   */
  readonly date_transferred?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.date_withdrawn
   */
  readonly date_withdrawn?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_address_1
   */
  readonly debtor_address_1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_address_2
   */
  readonly debtor_address_2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_address_3
   */
  readonly debtor_address_3?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_address_4
   */
  readonly debtor_address_4?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_address_phone
   */
  readonly debtor_address_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_alias_1
   */
  readonly debtor_alias_1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_alias_2
   */
  readonly debtor_alias_2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_alias_3
   */
  readonly debtor_alias_3?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_alias_4
   */
  readonly debtor_alias_4?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_city
   */
  readonly debtor_city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_county
   */
  readonly debtor_county?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_email
   */
  readonly debtor_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_first_name
   */
  readonly debtor_first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_last_name
   */
  readonly debtor_last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_law_firm
   */
  readonly debtor_law_firm?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_middle_name
   */
  readonly debtor_middle_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_ssn
   */
  readonly debtor_ssn?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_state
   */
  readonly debtor_state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_suffix
   */
  readonly debtor_suffix?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_tax_id
   */
  readonly debtor_tax_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_type
   */
  readonly debtor_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.debtor_zip
   */
  readonly debtor_zip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.federal_court_divisional_office_number
   */
  readonly federal_court_divisional_office_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.federal_court_jurisdiction_id
   */
  readonly federal_court_jurisdiction_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.judge_initials
   */
  readonly judge_initials?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.judge_name
   */
  readonly judge_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.notice_type
   */
  readonly notice_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_address_1
   */
  readonly trustee_address_1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_address_2
   */
  readonly trustee_address_2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_address_3
   */
  readonly trustee_address_3?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_address_4
   */
  readonly trustee_address_4?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_city
   */
  readonly trustee_city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_email
   */
  readonly trustee_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_name
   */
  readonly trustee_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_phone
   */
  readonly trustee_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_state
   */
  readonly trustee_state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Bankruptcy.trustee_zip
   */
  readonly trustee_zip?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased
 */
export interface ITerminalDebtScrub_Experian_Response_Deceased {
  readonly __typename?: "TerminalDebtScrub_Experian_Response_Deceased";
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased.date_of_birth
   */
  readonly date_of_birth?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased.date_of_death
   */
  readonly date_of_death?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased.last_known_zip
   */
  readonly last_known_zip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased.last_payment_zip
   */
  readonly last_payment_zip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased.middle_name
   */
  readonly middle_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/terminal_debt_scrub.proto
   * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.Deceased.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.NameMatch
 */
export enum ITerminalDebtScrub_Experian_Response_NameMatch {
  FirstNameMatch = "FIRST_NAME_MATCH",
  FullNameMatch = "FULL_NAME_MATCH",
  LastNameMatch = "LAST_NAME_MATCH",
  NameMatchNoMatch = "NAME_MATCH_NO_MATCH",
  NameMatchUnknown = "NAME_MATCH_UNKNOWN"
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.ReturnCode
 */
export enum ITerminalDebtScrub_Experian_Response_ReturnCode {
  Bankrupt = "BANKRUPT",
  BankruptcyDismissed = "BANKRUPTCY_DISMISSED",
  Deceased = "DECEASED",
  DeceasedAndBankrupt = "DECEASED_AND_BANKRUPT",
  NoHit = "NO_HIT",
  ReturnCodeUnknown = "RETURN_CODE_UNKNOWN"
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.SsnMatch
 */
export enum ITerminalDebtScrub_Experian_Response_SsnMatch {
  SsnMatch_4Digits = "SSN_MATCH_4_DIGITS",
  SsnMatch_9Digits = "SSN_MATCH_9_DIGITS",
  SsnMatchUnknown = "SSN_MATCH_UNKNOWN",
  SsnNoMatch = "SSN_NO_MATCH"
}

/**
 * File: collectr/proto/terminal_debt_scrub.proto
 * com.trueaccord.proto.TerminalDebtScrub.Experian.Response.SsnReturnCode
 */
export enum ITerminalDebtScrub_Experian_Response_SsnReturnCode {
  AlternativeExperianSsnUsed = "ALTERNATIVE_EXPERIAN_SSN_USED",
  ExperianSsnUsed = "EXPERIAN_SSN_USED",
  SsnFromClientInputUsed = "SSN_FROM_CLIENT_INPUT_USED",
  SsnReturnCodeUnknown = "SSN_RETURN_CODE_UNKNOWN"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.TerminalDebt.Authority
 */
export enum ITerminalDebt_Authority {
  Creditor = "CREDITOR",
  Customer = "CUSTOMER",
  Dmdc = "DMDC",
  Experian = "EXPERIAN",
  OtherThirdParty = "OTHER_THIRD_PARTY",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.TerminalDebt.Bankruptcy
 */
export interface ITerminalDebt_Bankruptcy {
  readonly __typename?: "TerminalDebt_Bankruptcy";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.attorney_information
   */
  readonly attorney_information?: Maybe<IContactInformation>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.case_number
   */
  readonly case_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.chapter
   */
  readonly chapter?: Maybe<ITerminalDebt_Bankruptcy_Chapter | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.date_converted
   */
  readonly date_converted?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.date_discharged
   */
  readonly date_discharged?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.date_dismissed
   */
  readonly date_dismissed?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.date_filed
   */
  readonly date_filed?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.date_of_notification
   */
  readonly date_of_notification?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Bankruptcy.is_self_represented
   */
  readonly is_self_represented?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.TerminalDebt.Bankruptcy.Chapter
 */
export enum ITerminalDebt_Bankruptcy_Chapter {
  Chapter_7 = "CHAPTER_7",
  Chapter_11 = "CHAPTER_11",
  Chapter_13 = "CHAPTER_13",
  Other = "OTHER",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.TerminalDebt.Deceased
 */
export interface ITerminalDebt_Deceased {
  readonly __typename?: "TerminalDebt_Deceased";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Deceased.date_of_death
   */
  readonly date_of_death?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Deceased.executor
   */
  readonly executor?: Maybe<IContactInformation>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Deceased.informer
   */
  readonly informer?: Maybe<IContactInformation>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.TerminalDebt.Scra
 */
export interface ITerminalDebt_Scra {
  readonly __typename?: "TerminalDebt_Scra";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Scra.active_duty_date
   */
  readonly active_duty_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Scra.date_of_birth
   */
  readonly date_of_birth?: Maybe<IDate>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.TerminalDebt.Scra.name
   */
  readonly name?: Maybe<IPersonName>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.Time
 */
export interface ITime {
  readonly __typename?: "Time";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.Time.hour
   */
  readonly hour?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.Time.minute
   */
  readonly minute?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.Time.second
   */
  readonly second?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Token
 */
export interface IToken {
  readonly __typename?: "Token";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.expiration_timestamp_msec
   */
  readonly expiration_timestamp_msec?: Maybe<Scalars["Long"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.oauth_request_token
   */
  readonly oauth_request_token?: Maybe<IToken_OAuthRequestToken>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.password_reset_token
   */
  readonly password_reset_token?: Maybe<IToken_PasswordResetToken>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.payment_plan_application
   */
  readonly payment_plan_application?: Maybe<IPaymentPlanApplication>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.payment_token
   */
  readonly payment_token?: Maybe<IPaymentToken>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.referral_token
   */
  readonly referral_token?: Maybe<IToken_ReferralToken>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.sign_up_token
   */
  readonly sign_up_token?: Maybe<IToken_SignUpToken>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Token.OAuthRequestToken
 */
export interface IToken_OAuthRequestToken {
  readonly __typename?: "Token_OAuthRequestToken";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.OAuthRequestToken.realm_id
   */
  readonly realm_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.OAuthRequestToken.secret
   */
  readonly secret?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.OAuthRequestToken.token
   */
  readonly token?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Token.PasswordResetToken
 */
export interface IToken_PasswordResetToken {
  readonly __typename?: "Token_PasswordResetToken";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.PasswordResetToken.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.PasswordResetToken.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Token.ReferralToken
 */
export interface IToken_ReferralToken {
  readonly __typename?: "Token_ReferralToken";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.ReferralToken.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/collectr.proto
 * com.trueaccord.proto.Token.SignUpToken
 */
export interface IToken_SignUpToken {
  readonly __typename?: "Token_SignUpToken";
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.SignUpToken.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.SignUpToken.full_name
   */
  readonly full_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.SignUpToken.is_creditor
   */
  readonly is_creditor?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.SignUpToken.password
   */
  readonly password?: Maybe<IAuth_HashedKey>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.SignUpToken.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.SignUpToken.provider_id
   */
  readonly provider_id?: Maybe<IAuth_UserIdentity_IdentityProviderId | "%future added value">;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.SignUpToken.referral_token_id
   */
  readonly referral_token_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/collectr.proto
   * com.trueaccord.proto.Token.SignUpToken.user_id
   */
  readonly user_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry
 */
export interface ITotalToCollectAdjustmentLogEntry {
  readonly __typename?: "TotalToCollectAdjustmentLogEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.adjustment_id
   */
  readonly adjustment_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.aim_breakdown
   */
  readonly aim_breakdown?: Maybe<ITotalToCollectAdjustmentLogEntry_AimBreakdown>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.block_changes_from_bal_file
   */
  readonly block_changes_from_bal_file?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.collections_snapshot
   */
  readonly collections_snapshot?: Maybe<IAccounting_CollectionsBalanceBreakdown>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.creditor_note
   */
  readonly creditor_note?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.delta
   */
  readonly delta?: Maybe<IAccounting_BalanceBreakdown>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.do_not_notify_customer
   */
  readonly do_not_notify_customer?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.snapshot
   */
  readonly snapshot?: Maybe<IAccounting_BalanceBreakdown>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.AIMBreakdown
 */
export interface ITotalToCollectAdjustmentLogEntry_AimBreakdown {
  readonly __typename?: "TotalToCollectAdjustmentLogEntry_AIMBreakdown";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.AIMBreakdown.aim_info
   */
  readonly aim_info?: Maybe<IAimInformation>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.TotalToCollectAdjustmentLogEntry.AIMBreakdown.bucket_amounts
   */
  readonly bucket_amounts: ReadonlyArray<Maybe<IDebt_InternalInformation_AimInformation_BucketInfo>>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.USBankAccount
 */
export interface IUsBankAccount {
  readonly __typename?: "USBankAccount";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.USBankAccount.account_name
   */
  readonly account_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.USBankAccount.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.USBankAccount.account_type
   */
  readonly account_type?: Maybe<IUsBankAccount_AccountType | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.USBankAccount.bank_name
   */
  readonly bank_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.USBankAccount.routing_number
   */
  readonly routing_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.USBankAccount.AccountType
 */
export enum IUsBankAccount_AccountType {
  Checking = "CHECKING",
  Savings = "SAVINGS"
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.UnifiedPaymentEntry
 */
export interface IUnifiedPaymentEntry {
  readonly __typename?: "UnifiedPaymentEntry";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.is_entered_by_agent
   */
  readonly is_entered_by_agent?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.is_ivr_payment
   */
  readonly is_ivr_payment?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.is_pending
   */
  readonly is_pending?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.is_successful
   */
  readonly is_successful?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.payment_entry_id
   */
  readonly payment_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.payment_method_info
   */
  readonly payment_method_info?: Maybe<IApi_PaymentMethodInfoOut>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.payment_time
   */
  readonly payment_time?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.UnifiedPaymentEntry.transaction_type
   */
  readonly transaction_type?: Maybe<IApi_PaymentTransactionType | "%future added value">;
}

/**
 * File: collectr/proto/epay.proto
 * com.trueaccord.proto.UsaepayPaymentToken
 */
export interface IUsaepayPaymentToken {
  readonly __typename?: "UsaepayPaymentToken";
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.UsaepayPaymentToken.card_token
   */
  readonly card_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.UsaepayPaymentToken.epay_token
   */
  readonly epay_token?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/epay.proto
   * com.trueaccord.proto.UsaepayPaymentToken.payment_method_info
   */
  readonly payment_method_info?: Maybe<ICardPaymentMethodInfo>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ValidatePaymentOptionsAttempt
 */
export interface IValidatePaymentOptionsAttempt {
  readonly __typename?: "ValidatePaymentOptionsAttempt";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.ach_transaction_request
   */
  readonly ach_transaction_request?: Maybe<Scalars["ValidatePaymentOptionsAttempt_AchTransactionRequest"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.check_payment_transaction_request
   */
  readonly check_payment_transaction_request?: Maybe<
    Scalars["ValidatePaymentOptionsAttempt_CheckPaymentTransactionRequest"]
  >;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.contribution
   */
  readonly contribution?: Maybe<IContribution>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.creditor_payment_options
   */
  readonly creditor_payment_options?: Maybe<ICreditor_Policy_PaymentOptions>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.is_entered_by_agent
   */
  readonly is_entered_by_agent?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.payment_method_token_transaction_request
   */
  readonly payment_method_token_transaction_request?: Maybe<IValidatePaymentOptionsAttempt_PaymentMethodTokenTransactionRequest>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.transaction_context_request
   */
  readonly transaction_context_request?: Maybe<IValidatePaymentOptionsAttempt_TransactionContextRequest>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ValidatePaymentOptionsAttempt.ContactInformationUpdate
 */
export interface IValidatePaymentOptionsAttempt_ContactInformationUpdate {
  readonly __typename?: "ValidatePaymentOptionsAttempt_ContactInformationUpdate";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.ContactInformationUpdate.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.ContactInformationUpdate.phone
   */
  readonly phone?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ValidatePaymentOptionsAttempt.PaymentMethodTokenTransactionRequest
 */
export interface IValidatePaymentOptionsAttempt_PaymentMethodTokenTransactionRequest {
  readonly __typename?: "ValidatePaymentOptionsAttempt_PaymentMethodTokenTransactionRequest";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.PaymentMethodTokenTransactionRequest.bin_number
   */
  readonly bin_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ValidatePaymentOptionsAttempt.TransactionContextRequest
 */
export interface IValidatePaymentOptionsAttempt_TransactionContextRequest {
  readonly __typename?: "ValidatePaymentOptionsAttempt_TransactionContextRequest";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.TransactionContextRequest.contact_information_update
   */
  readonly contact_information_update?: Maybe<IValidatePaymentOptionsAttempt_ContactInformationUpdate>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsAttempt.TransactionContextRequest.payment_plan_application_token_id
   */
  readonly payment_plan_application_token_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ValidatePaymentOptionsResult
 */
export interface IValidatePaymentOptionsResult {
  readonly __typename?: "ValidatePaymentOptionsResult";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsResult.ach_pending_risk_assessment
   */
  readonly ach_pending_risk_assessment?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsResult.failure_reason
   */
  readonly failure_reason?: Maybe<IValidatePaymentOptionsResult_FailureReason | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsResult.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsResult.originating_attempt_id
   */
  readonly originating_attempt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidatePaymentOptionsResult.success
   */
  readonly success?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ValidatePaymentOptionsResult.FailureReason
 */
export enum IValidatePaymentOptionsResult_FailureReason {
  AchDisabled = "ACH_DISABLED",
  AgentsAreUnableToEnterPaymentType = "AGENTS_ARE_UNABLE_TO_ENTER_PAYMENT_TYPE",
  CreditCardDisabled = "CREDIT_CARD_DISABLED",
  DebitCardDisabled = "DEBIT_CARD_DISABLED",
  DisabledCardsInPaymentPlan = "DISABLED_CARDS_IN_PAYMENT_PLAN",
  MissingBinNumber = "MISSING_BIN_NUMBER",
  Unknown = "UNKNOWN",
  ValidationThrewException = "VALIDATION_THREW_EXCEPTION"
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ValidationResult
 */
export interface IValidationResult {
  readonly __typename?: "ValidationResult";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.failure
   */
  readonly failure?: Maybe<IValidationResult_FailureResult>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.name
   */
  readonly name?: Maybe<IValidationResult_ValidationName | "%future added value">;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.not_applicable
   */
  readonly not_applicable?: Maybe<Scalars["ValidationResult_NotApplicableResult"]>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.not_attempted
   */
  readonly not_attempted?: Maybe<IValidationResult_NotAttemptedResult>;
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.success
   */
  readonly success?: Maybe<Scalars["ValidationResult_SuccessfulResult"]>;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.ValidationResultEntry
 */
export interface IValidationResultEntry {
  readonly __typename?: "ValidationResultEntry";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidationResultEntry.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.ValidationResultEntry.validation_result
   */
  readonly validation_result: ReadonlyArray<Maybe<IValidationResult>>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ValidationResult.FailureResult
 */
export interface IValidationResult_FailureResult {
  readonly __typename?: "ValidationResult_FailureResult";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.FailureResult.failure_message
   */
  readonly failure_message?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ValidationResult.NotAttemptedResult
 */
export interface IValidationResult_NotAttemptedResult {
  readonly __typename?: "ValidationResult_NotAttemptedResult";
  /**
   * File: collectr/proto/base.proto
   * com.trueaccord.proto.ValidationResult.NotAttemptedResult.error_message
   */
  readonly error_message?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/base.proto
 * com.trueaccord.proto.ValidationResult.ValidationName
 */
export enum IValidationResult_ValidationName {
  AlwaysValid = "ALWAYS_VALID",
  DeliverableEmail = "DELIVERABLE_EMAIL",
  RepeatedPlacements = "REPEATED_PLACEMENTS",
  StateZipcodeSuppression = "STATE_ZIPCODE_SUPPRESSION"
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.VerificationStatusChange
 */
export interface IVerificationStatusChange {
  readonly __typename?: "VerificationStatusChange";
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.VerificationStatusChange.email_id
   */
  readonly email_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.VerificationStatusChange.header
   */
  readonly header?: Maybe<IDebtLogEntryHeader>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.VerificationStatusChange.new_status
   */
  readonly new_status?: Maybe<IDataSource_VerificationStatus | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.VerificationStatusChange.old_status
   */
  readonly old_status?: Maybe<IDataSource_VerificationStatus | "%future added value">;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.VerificationStatusChange.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/debt_log_entries.proto
   * com.trueaccord.proto.VerificationStatusChange.reason
   */
  readonly reason?: Maybe<IVerificationStatusChange_VerificationStatusChangeReason | "%future added value">;
}

/**
 * File: collectr/proto/debt_log_entries.proto
 * com.trueaccord.proto.VerificationStatusChange.VerificationStatusChangeReason
 */
export enum IVerificationStatusChange_VerificationStatusChangeReason {
  ClickedAnEmail = "CLICKED_AN_EMAIL",
  EmailAndPhoneNumberMatchedFacebookAccount = "EMAIL_AND_PHONE_NUMBER_MATCHED_FACEBOOK_ACCOUNT",
  FailedVerificationAttempt = "FAILED_VERIFICATION_ATTEMPT",
  ProvidedCorrectAddress = "PROVIDED_CORRECT_ADDRESS",
  ProvidedCorrectPhoneNumber = "PROVIDED_CORRECT_PHONE_NUMBER",
  ProvidedIncorrectInformation = "PROVIDED_INCORRECT_INFORMATION",
  RemovedByUser = "REMOVED_BY_USER",
  VerifiedByPhone = "VERIFIED_BY_PHONE"
}

/**
 * File: collectr/proto/voapps_event.proto
 * com.trueaccord.proto.VoAppsEvent
 */
export interface IVoAppsEvent {
  readonly __typename?: "VoAppsEvent";
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.context
   */
  readonly context?: Maybe<IVoAppsEvent_Context>;
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.delivery_status
   */
  readonly delivery_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.delivery_status_code
   */
  readonly delivery_status_code?: Maybe<IVoAppsEvent_DeliveryStatusCode | "%future added value">;
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.delivery_status_code_raw
   */
  readonly delivery_status_code_raw?: Maybe<Scalars["Int"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.record_id
   */
  readonly record_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/voapps_event.proto
 * com.trueaccord.proto.VoAppsEvent.Context
 */
export interface IVoAppsEvent_Context {
  readonly __typename?: "VoAppsEvent_Context";
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.Context.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/voapps_event.proto
   * com.trueaccord.proto.VoAppsEvent.Context.debt_log_entry_id
   */
  readonly debt_log_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/voapps_event.proto
 * com.trueaccord.proto.VoAppsEvent.DeliveryStatusCode
 */
export enum IVoAppsEvent_DeliveryStatusCode {
  Canceled = "CANCELED",
  DuplicateNumber = "DUPLICATE_NUMBER",
  Expired = "EXPIRED",
  NotAValidUsNumber = "NOT_A_VALID_US_NUMBER",
  NotAWirelessNumber = "NOT_A_WIRELESS_NUMBER",
  NotInService = "NOT_IN_SERVICE",
  Pending = "PENDING",
  Restricted = "RESTRICTED",
  RestrictedForFrequency = "RESTRICTED_FOR_FREQUENCY",
  RestrictedGeographicalRegion = "RESTRICTED_GEOGRAPHICAL_REGION",
  RestrictedIndividualNumber = "RESTRICTED_INDIVIDUAL_NUMBER",
  RestrictedWebrecon = "RESTRICTED_WEBRECON",
  Running = "RUNNING",
  SuccessfullyDelivered = "SUCCESSFULLY_DELIVERED",
  Undeliverable = "UNDELIVERABLE",
  UnsuccessfulDeliveryAttempt = "UNSUCCESSFUL_DELIVERY_ATTEMPT",
  VoicemailFull = "VOICEMAIL_FULL",
  VoicemailNotSetup = "VOICEMAIL_NOT_SETUP"
}

/**
 * File: collectr/proto/accounting.proto
 * com.trueaccord.proto.accounting.BalanceBreakdown
 */
export interface IAccounting_BalanceBreakdown {
  readonly __typename?: "accounting_BalanceBreakdown";
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.BalanceBreakdown.buckets
   */
  readonly buckets: ReadonlyArray<Maybe<IAccounting_Bucket>>;
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.BalanceBreakdown.sub_transactions
   */
  readonly sub_transactions: ReadonlyArray<Maybe<IAccounting_BalanceBreakdown_SubTransaction>>;
}

/**
 * File: collectr/proto/accounting.proto
 * com.trueaccord.proto.accounting.BalanceBreakdown.SubTransaction
 */
export interface IAccounting_BalanceBreakdown_SubTransaction {
  readonly __typename?: "accounting_BalanceBreakdown_SubTransaction";
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.BalanceBreakdown.SubTransaction.buckets
   */
  readonly buckets: ReadonlyArray<Maybe<IAccounting_Bucket>>;
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.BalanceBreakdown.SubTransaction.sub_transaction_id
   */
  readonly sub_transaction_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/accounting.proto
 * com.trueaccord.proto.accounting.Bucket
 */
export interface IAccounting_Bucket {
  readonly __typename?: "accounting_Bucket";
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.Bucket.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.Bucket.bucket_type
   */
  readonly bucket_type?: Maybe<IAccounting_Bucket_BucketType | "%future added value">;
}

/**
 * File: collectr/proto/accounting.proto
 * com.trueaccord.proto.accounting.Bucket.BucketType
 */
export enum IAccounting_Bucket_BucketType {
  Costs = "COSTS",
  Fees = "FEES",
  Interest = "INTEREST",
  Overcharges = "OVERCHARGES",
  Principal = "PRINCIPAL"
}

/**
 * File: collectr/proto/accounting.proto
 * com.trueaccord.proto.accounting.CollectionsBalanceBreakdown
 */
export interface IAccounting_CollectionsBalanceBreakdown {
  readonly __typename?: "accounting_CollectionsBalanceBreakdown";
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.CollectionsBalanceBreakdown.buckets
   */
  readonly buckets: ReadonlyArray<Maybe<IAccounting_CollectionsBucket>>;
}

/**
 * File: collectr/proto/accounting.proto
 * com.trueaccord.proto.accounting.CollectionsBucket
 */
export interface IAccounting_CollectionsBucket {
  readonly __typename?: "accounting_CollectionsBucket";
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.CollectionsBucket.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/accounting.proto
   * com.trueaccord.proto.accounting.CollectionsBucket.bucket_type
   */
  readonly bucket_type?: Maybe<IAccounting_CollectionsBucket_BucketType | "%future added value">;
}

/**
 * File: collectr/proto/accounting.proto
 * com.trueaccord.proto.accounting.CollectionsBucket.BucketType
 */
export enum IAccounting_CollectionsBucket_BucketType {
  CurrentAmount = "CURRENT_AMOUNT",
  CurrentBalance = "CURRENT_BALANCE",
  DelinquentAmount = "DELINQUENT_AMOUNT",
  TotalAmount = "TOTAL_AMOUNT"
}

/**
 * File: aim/aack.proto
 * aim.AACK
 */
export interface IAim_Aack {
  readonly __typename?: "aim_AACK";
  /**
   * File: aim/aack.proto
   * aim.AACK.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack.proto
   * aim.AACK.current_balance
   */
  readonly current_balance?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack.proto
   * aim.AACK.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack.proto
   * aim.AACK.last_payment_amount
   */
  readonly last_payment_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack.proto
   * aim.AACK.last_payment_date
   */
  readonly last_payment_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack.proto
   * aim.AACK.original_balance
   */
  readonly original_balance?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack.proto
   * aim.AACK.received_date
   */
  readonly received_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack.proto
   * aim.AACK.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/aack_atrl.proto
 * aim.AACK_ATRL
 */
export interface IAim_Aack_Atrl {
  readonly __typename?: "aim_AACK_ATRL";
  /**
   * File: aim/aack_atrl.proto
   * aim.AACK_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack_atrl.proto
   * aim.AACK_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack_atrl.proto
   * aim.AACK_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack_atrl.proto
   * aim.AACK_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack_atrl.proto
   * aim.AACK_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack_atrl.proto
   * aim.AACK_ATRL.total_current_balance
   */
  readonly total_current_balance?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aack_atrl.proto
   * aim.AACK_ATRL.total_original_balance
   */
  readonly total_original_balance?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/aack_file.proto
 * aim.AACK_File
 */
export interface IAim_Aack_File {
  readonly __typename?: "aim_AACK_File";
  /**
   * File: aim/aack_file.proto
   * aim.AACK_File.aack
   */
  readonly aack: ReadonlyArray<Maybe<IAim_Aack>>;
  /**
   * File: aim/aack_file.proto
   * aim.AACK_File.atrl
   */
  readonly atrl?: Maybe<IAim_Aack_Atrl>;
}

/**
 * File: aim/aast.proto
 * aim.AAST
 */
export interface IAim_Aast {
  readonly __typename?: "aim_AAST";
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_assets_asset_type
   */
  readonly debtor_assets_asset_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_assets_current_value
   */
  readonly debtor_assets_current_value?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_assets_description
   */
  readonly debtor_assets_description?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_assets_id
   */
  readonly debtor_assets_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_assets_lien_amount
   */
  readonly debtor_assets_lien_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_assets_lien_verified
   */
  readonly debtor_assets_lien_verified?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_assets_name
   */
  readonly debtor_assets_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_assets_value_verified
   */
  readonly debtor_assets_value_verified?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast.proto
   * aim.AAST.na
   */
  readonly na?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/aast_atrl.proto
 * aim.AAST_ATRL
 */
export interface IAim_Aast_Atrl {
  readonly __typename?: "aim_AAST_ATRL";
  /**
   * File: aim/aast_atrl.proto
   * aim.AAST_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast_atrl.proto
   * aim.AAST_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast_atrl.proto
   * aim.AAST_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast_atrl.proto
   * aim.AAST_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aast_atrl.proto
   * aim.AAST_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/aast_file.proto
 * aim.AAST_File
 */
export interface IAim_Aast_File {
  readonly __typename?: "aim_AAST_File";
  /**
   * File: aim/aast_file.proto
   * aim.AAST_File.aast
   */
  readonly aast: ReadonlyArray<Maybe<IAim_Aast>>;
  /**
   * File: aim/aast_file.proto
   * aim.AAST_File.atrl
   */
  readonly atrl?: Maybe<IAim_Aast_Atrl>;
}

/**
 * File: aim/abkp.proto
 * aim.ABKP
 */
export interface IAim_Abkp {
  readonly __typename?: "aim_ABKP";
  /**
   * File: aim/abkp.proto
   * aim.ABKP.auction_amount
   */
  readonly auction_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.auction_applied_amount
   */
  readonly auction_applied_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.auction_date
   */
  readonly auction_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.auction_fee_amount
   */
  readonly auction_fee_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.auction_house
   */
  readonly auction_house?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.case_number
   */
  readonly case_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.chapter
   */
  readonly chapter?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.comments
   */
  readonly comments?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.confirmation_hearing_date
   */
  readonly confirmation_hearing_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.converted_from_chapter
   */
  readonly converted_from_chapter?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.court_address1
   */
  readonly court_address1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.court_address2
   */
  readonly court_address2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.court_city
   */
  readonly court_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.court_district
   */
  readonly court_district?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.court_division
   */
  readonly court_division?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.court_phone
   */
  readonly court_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.court_state
   */
  readonly court_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.court_zipcode
   */
  readonly court_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.date_filed
   */
  readonly date_filed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.discharge_date
   */
  readonly discharge_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.dismissal_date
   */
  readonly dismissal_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.has_asset
   */
  readonly has_asset?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.notice_date
   */
  readonly notice_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.proof_filed_date
   */
  readonly proof_filed_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.reaffirm_amount
   */
  readonly reaffirm_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.reaffirm_filed_date
   */
  readonly reaffirm_filed_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.reaffirm_flag
   */
  readonly reaffirm_flag?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.reaffirm_terms
   */
  readonly reaffirm_terms?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.secured_amount
   */
  readonly secured_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.secured_percentage
   */
  readonly secured_percentage?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.surrender_date
   */
  readonly surrender_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.surrender_method
   */
  readonly surrender_method?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.three_forty_one_date
   */
  readonly three_forty_one_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.three_forty_one_info_flag
   */
  readonly three_forty_one_info_flag?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.three_forty_one_location
   */
  readonly three_forty_one_location?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.transmit_date
   */
  readonly transmit_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.trustee
   */
  readonly trustee?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.trustee_address1
   */
  readonly trustee_address1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.trustee_address2
   */
  readonly trustee_address2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.trustee_city
   */
  readonly trustee_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.trustee_phone
   */
  readonly trustee_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.trustee_state
   */
  readonly trustee_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.trustee_zipcode
   */
  readonly trustee_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.unsecured_amount
   */
  readonly unsecured_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.unsecured_percentage
   */
  readonly unsecured_percentage?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.voluntary_amount
   */
  readonly voluntary_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.voluntary_date
   */
  readonly voluntary_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp.proto
   * aim.ABKP.voluntary_terms
   */
  readonly voluntary_terms?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/abkp_atrl.proto
 * aim.ABKP_ATRL
 */
export interface IAim_Abkp_Atrl {
  readonly __typename?: "aim_ABKP_ATRL";
  /**
   * File: aim/abkp_atrl.proto
   * aim.ABKP_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp_atrl.proto
   * aim.ABKP_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp_atrl.proto
   * aim.ABKP_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp_atrl.proto
   * aim.ABKP_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/abkp_atrl.proto
   * aim.ABKP_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/abkp_file.proto
 * aim.ABKP_File
 */
export interface IAim_Abkp_File {
  readonly __typename?: "aim_ABKP_File";
  /**
   * File: aim/abkp_file.proto
   * aim.ABKP_File.abkp
   */
  readonly abkp: ReadonlyArray<Maybe<IAim_Abkp>>;
  /**
   * File: aim/abkp_file.proto
   * aim.ABKP_File.atrl
   */
  readonly atrl?: Maybe<IAim_Abkp_Atrl>;
}

/**
 * File: aim/acls.proto
 * aim.ACLS
 */
export interface IAim_Acls {
  readonly __typename?: "aim_ACLS";
  /**
   * File: aim/acls.proto
   * aim.ACLS.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/acls.proto
   * aim.ACLS.close_date
   */
  readonly close_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/acls.proto
   * aim.ACLS.close_status_code
   */
  readonly close_status_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/acls.proto
   * aim.ACLS.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/acls.proto
   * aim.ACLS.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/acls_atrl.proto
 * aim.ACLS_ATRL
 */
export interface IAim_Acls_Atrl {
  readonly __typename?: "aim_ACLS_ATRL";
  /**
   * File: aim/acls_atrl.proto
   * aim.ACLS_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/acls_atrl.proto
   * aim.ACLS_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/acls_atrl.proto
   * aim.ACLS_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/acls_atrl.proto
   * aim.ACLS_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/acls_atrl.proto
   * aim.ACLS_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/acls_file.proto
 * aim.ACLS_File
 */
export interface IAim_Acls_File {
  readonly __typename?: "aim_ACLS_File";
  /**
   * File: aim/acls_file.proto
   * aim.ACLS_File.acls
   */
  readonly acls: ReadonlyArray<Maybe<IAim_Acls>>;
  /**
   * File: aim/acls_file.proto
   * aim.ACLS_File.atrl
   */
  readonly atrl?: Maybe<IAim_Acls_Atrl>;
}

/**
 * File: aim/adec.proto
 * aim.ADEC
 */
export interface IAim_Adec {
  readonly __typename?: "aim_ADEC";
  /**
   * File: aim/adec.proto
   * aim.ADEC.case_number
   */
  readonly case_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.claim_deadline_date
   */
  readonly claim_deadline_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.court_city
   */
  readonly court_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.court_district
   */
  readonly court_district?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.court_division
   */
  readonly court_division?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.court_phone
   */
  readonly court_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.court_state
   */
  readonly court_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.court_street1
   */
  readonly court_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.court_street2
   */
  readonly court_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.court_zipcode
   */
  readonly court_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.date_of_birth
   */
  readonly date_of_birth?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.date_of_death
   */
  readonly date_of_death?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.executor
   */
  readonly executor?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.executor_city
   */
  readonly executor_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.executor_fax
   */
  readonly executor_fax?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.executor_phone
   */
  readonly executor_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.executor_state
   */
  readonly executor_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.executor_street1
   */
  readonly executor_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.executor_street2
   */
  readonly executor_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.executor_zipcode
   */
  readonly executor_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.filed_date
   */
  readonly filed_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.match_code
   */
  readonly match_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.postal_code
   */
  readonly postal_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec.proto
   * aim.ADEC.transmit_date
   */
  readonly transmit_date?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/adec_atrl.proto
 * aim.ADEC_ATRL
 */
export interface IAim_Adec_Atrl {
  readonly __typename?: "aim_ADEC_ATRL";
  /**
   * File: aim/adec_atrl.proto
   * aim.ADEC_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec_atrl.proto
   * aim.ADEC_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec_atrl.proto
   * aim.ADEC_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec_atrl.proto
   * aim.ADEC_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adec_atrl.proto
   * aim.ADEC_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/adec_file.proto
 * aim.ADEC_File
 */
export interface IAim_Adec_File {
  readonly __typename?: "aim_ADEC_File";
  /**
   * File: aim/adec_file.proto
   * aim.ADEC_File.adec
   */
  readonly adec: ReadonlyArray<Maybe<IAim_Adec>>;
  /**
   * File: aim/adec_file.proto
   * aim.ADEC_File.atrl
   */
  readonly atrl?: Maybe<IAim_Adec_Atrl>;
}

/**
 * File: aim/adem_atrl.proto
 * aim.ADEM_ATRL
 */
export interface IAim_Adem_Atrl {
  readonly __typename?: "aim_ADEM_ATRL";
  /**
   * File: aim/adem_atrl.proto
   * aim.ADEM_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adem_atrl.proto
   * aim.ADEM_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adem_atrl.proto
   * aim.ADEM_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adem_atrl.proto
   * aim.ADEM_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/adem_atrl.proto
   * aim.ADEM_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/adem_file.proto
 * aim.ADEM_File
 */
export interface IAim_Adem_File {
  readonly __typename?: "aim_ADEM_File";
  /**
   * File: aim/adem_file.proto
   * aim.ADEM_File.atrl
   */
  readonly atrl?: Maybe<IAim_Adem_Atrl>;
  /**
   * File: aim/adem_file.proto
   * aim.ADEM_File.auad
   */
  readonly auad: ReadonlyArray<Maybe<IAim_Auad>>;
  /**
   * File: aim/adem_file.proto
   * aim.ADEM_File.auph
   */
  readonly auph: ReadonlyArray<Maybe<IAim_Auph>>;
  /**
   * File: aim/adem_file.proto
   * aim.ADEM_File.aupp
   */
  readonly aupp: ReadonlyArray<Maybe<IAim_Aupp>>;
}

/**
 * File: aim/ajdg.proto
 * aim.AJDG
 */
export interface IAim_Ajdg {
  readonly __typename?: "aim_AJDG";
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.arbitration_date
   */
  readonly arbitration_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.attorney_ack_date
   */
  readonly attorney_ack_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.case_number
   */
  readonly case_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_city
   */
  readonly court_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_clerk_first_name
   */
  readonly court_clerk_first_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_clerk_last_name
   */
  readonly court_clerk_last_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_clerk_middle_name
   */
  readonly court_clerk_middle_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_county
   */
  readonly court_county?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_date
   */
  readonly court_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_fax
   */
  readonly court_fax?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_name
   */
  readonly court_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_notes
   */
  readonly court_notes?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_phone
   */
  readonly court_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_room
   */
  readonly court_room?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_salutation
   */
  readonly court_salutation?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_state
   */
  readonly court_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_street1
   */
  readonly court_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_street2
   */
  readonly court_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.court_zipcode
   */
  readonly court_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.date_answered
   */
  readonly date_answered?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.date_filed
   */
  readonly date_filed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.defendant
   */
  readonly defendant?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.discovery_cutoff
   */
  readonly discovery_cutoff?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.discovery_reply_date
   */
  readonly discovery_reply_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.int_from_date
   */
  readonly int_from_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judge
   */
  readonly judge?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_amt
   */
  readonly judgement_amt?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_attorney_cost_award
   */
  readonly judgement_attorney_cost_award?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_book
   */
  readonly judgement_book?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_cost_award
   */
  readonly judgement_cost_award?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_date
   */
  readonly judgement_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_flag
   */
  readonly judgement_flag?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_int_award
   */
  readonly judgement_int_award?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_int_rate
   */
  readonly judgement_int_rate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_other_award
   */
  readonly judgement_other_award?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_page
   */
  readonly judgement_page?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.judgement_recorded_date
   */
  readonly judgement_recorded_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.last_summary_judgement_date
   */
  readonly last_summary_judgement_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.misc_info1
   */
  readonly misc_info1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.misc_info2
   */
  readonly misc_info2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.motion_cutoff
   */
  readonly motion_cutoff?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.plaintiff
   */
  readonly plaintiff?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.remarks
   */
  readonly remarks?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.service_date
   */
  readonly service_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.service_type
   */
  readonly service_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg.proto
   * aim.AJDG.statute_deadline
   */
  readonly statute_deadline?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/ajdg_atrl.proto
 * aim.AJDG_ATRL
 */
export interface IAim_Ajdg_Atrl {
  readonly __typename?: "aim_AJDG_ATRL";
  /**
   * File: aim/ajdg_atrl.proto
   * aim.AJDG_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg_atrl.proto
   * aim.AJDG_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg_atrl.proto
   * aim.AJDG_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg_atrl.proto
   * aim.AJDG_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ajdg_atrl.proto
   * aim.AJDG_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/ajdg_file.proto
 * aim.AJDG_File
 */
export interface IAim_Ajdg_File {
  readonly __typename?: "aim_AJDG_File";
  /**
   * File: aim/ajdg_file.proto
   * aim.AJDG_File.ajdg
   */
  readonly ajdg: ReadonlyArray<Maybe<IAim_Ajdg>>;
  /**
   * File: aim/ajdg_file.proto
   * aim.AJDG_File.atrl
   */
  readonly atrl?: Maybe<IAim_Ajdg_Atrl>;
}

/**
 * File: aim/anot.proto
 * aim.ANOT
 */
export interface IAim_Anot {
  readonly __typename?: "aim_ANOT";
  /**
   * File: aim/anot.proto
   * aim.ANOT.created_datetime
   */
  readonly created_datetime?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot.proto
   * aim.ANOT.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot.proto
   * aim.ANOT.is_private
   */
  readonly is_private?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot.proto
   * aim.ANOT.note_action
   */
  readonly note_action?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot.proto
   * aim.ANOT.note_comment
   */
  readonly note_comment?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot.proto
   * aim.ANOT.note_result
   */
  readonly note_result?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot.proto
   * aim.ANOT.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/anot_atrl.proto
 * aim.ANOT_ATRL
 */
export interface IAim_Anot_Atrl {
  readonly __typename?: "aim_ANOT_ATRL";
  /**
   * File: aim/anot_atrl.proto
   * aim.ANOT_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot_atrl.proto
   * aim.ANOT_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot_atrl.proto
   * aim.ANOT_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot_atrl.proto
   * aim.ANOT_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/anot_atrl.proto
   * aim.ANOT_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/anot_file.proto
 * aim.ANOT_File
 */
export interface IAim_Anot_File {
  readonly __typename?: "aim_ANOT_File";
  /**
   * File: aim/anot_file.proto
   * aim.ANOT_File.anot
   */
  readonly anot: ReadonlyArray<Maybe<IAim_Anot>>;
  /**
   * File: aim/anot_file.proto
   * aim.ANOT_File.atrl
   */
  readonly atrl?: Maybe<IAim_Anot_Atrl>;
}

/**
 * File: aim/aobj.proto
 * aim.AOBJ
 */
export interface IAim_Aobj {
  readonly __typename?: "aim_AOBJ";
  /**
   * File: aim/aobj.proto
   * aim.AOBJ.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aobj.proto
   * aim.AOBJ.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aobj.proto
   * aim.AOBJ.objection_date
   */
  readonly objection_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aobj.proto
   * aim.AOBJ.objection_reason_code
   */
  readonly objection_reason_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aobj.proto
   * aim.AOBJ.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/aobj_atrl.proto
 * aim.AOBJ_ATRL
 */
export interface IAim_Aobj_Atrl {
  readonly __typename?: "aim_AOBJ_ATRL";
  /**
   * File: aim/aobj_atrl.proto
   * aim.AOBJ_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aobj_atrl.proto
   * aim.AOBJ_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aobj_atrl.proto
   * aim.AOBJ_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aobj_atrl.proto
   * aim.AOBJ_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aobj_atrl.proto
   * aim.AOBJ_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/aobj_file.proto
 * aim.AOBJ_File
 */
export interface IAim_Aobj_File {
  readonly __typename?: "aim_AOBJ_File";
  /**
   * File: aim/aobj_file.proto
   * aim.AOBJ_File.aobj
   */
  readonly aobj: ReadonlyArray<Maybe<IAim_Aobj>>;
  /**
   * File: aim/aobj_file.proto
   * aim.AOBJ_File.atrl
   */
  readonly atrl?: Maybe<IAim_Aobj_Atrl>;
}

/**
 * File: aim/apay.proto
 * aim.APAY
 */
export interface IAim_Apay {
  readonly __typename?: "aim_APAY";
  /**
   * File: aim/apay.proto
   * aim.APAY.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount
   */
  readonly fee_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket1
   */
  readonly fee_amount_bucket1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket2
   */
  readonly fee_amount_bucket2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket3
   */
  readonly fee_amount_bucket3?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket4
   */
  readonly fee_amount_bucket4?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket5
   */
  readonly fee_amount_bucket5?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket6
   */
  readonly fee_amount_bucket6?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket7
   */
  readonly fee_amount_bucket7?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket8
   */
  readonly fee_amount_bucket8?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.fee_amount_bucket9
   */
  readonly fee_amount_bucket9?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.invoice_identifier
   */
  readonly invoice_identifier?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.is_sif
   */
  readonly is_sif?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount
   */
  readonly payment_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket1
   */
  readonly payment_amount_bucket1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket2
   */
  readonly payment_amount_bucket2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket3
   */
  readonly payment_amount_bucket3?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket4
   */
  readonly payment_amount_bucket4?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket5
   */
  readonly payment_amount_bucket5?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket6
   */
  readonly payment_amount_bucket6?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket7
   */
  readonly payment_amount_bucket7?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket8
   */
  readonly payment_amount_bucket8?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_amount_bucket9
   */
  readonly payment_amount_bucket9?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_date
   */
  readonly payment_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_identifier
   */
  readonly payment_identifier?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_method
   */
  readonly payment_method?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.payment_type
   */
  readonly payment_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay.proto
   * aim.APAY.remit_amount
   */
  readonly remit_amount?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/apay_atrl.proto
 * aim.APAY_ATRL
 */
export interface IAim_Apay_Atrl {
  readonly __typename?: "aim_APAY_ATRL";
  /**
   * File: aim/apay_atrl.proto
   * aim.APAY_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay_atrl.proto
   * aim.APAY_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay_atrl.proto
   * aim.APAY_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay_atrl.proto
   * aim.APAY_ATRL.payments
   */
  readonly payments?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay_atrl.proto
   * aim.APAY_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apay_atrl.proto
   * aim.APAY_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/apay_file.proto
 * aim.APAY_File
 */
export interface IAim_Apay_File {
  readonly __typename?: "aim_APAY_File";
  /**
   * File: aim/apay_file.proto
   * aim.APAY_File.apay
   */
  readonly apay: ReadonlyArray<Maybe<IAim_Apay>>;
  /**
   * File: aim/apay_file.proto
   * aim.APAY_File.atrl
   */
  readonly atrl?: Maybe<IAim_Apay_Atrl>;
}

/**
 * File: aim/apdt.proto
 * aim.APDT
 */
export interface IAim_Apdt {
  readonly __typename?: "aim_APDT";
  /**
   * File: aim/apdt.proto
   * aim.APDT.amount
   */
  readonly amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apdt.proto
   * aim.APDT.duedate
   */
  readonly duedate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apdt.proto
   * aim.APDT.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apdt.proto
   * aim.APDT.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/apdt_atrl.proto
 * aim.APDT_ATRL
 */
export interface IAim_Apdt_Atrl {
  readonly __typename?: "aim_APDT_ATRL";
  /**
   * File: aim/apdt_atrl.proto
   * aim.APDT_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apdt_atrl.proto
   * aim.APDT_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apdt_atrl.proto
   * aim.APDT_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apdt_atrl.proto
   * aim.APDT_ATRL.payments
   */
  readonly payments?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apdt_atrl.proto
   * aim.APDT_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/apdt_atrl.proto
   * aim.APDT_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/apdt_file.proto
 * aim.APDT_File
 */
export interface IAim_Apdt_File {
  readonly __typename?: "aim_APDT_File";
  /**
   * File: aim/apdt_file.proto
   * aim.APDT_File.apdt
   */
  readonly apdt: ReadonlyArray<Maybe<IAim_Apdt>>;
  /**
   * File: aim/apdt_file.proto
   * aim.APDT_File.atrl
   */
  readonly atrl?: Maybe<IAim_Apdt_Atrl>;
}

/**
 * File: aim/arar.proto
 * aim.ARAR
 */
export interface IAim_Arar {
  readonly __typename?: "aim_ARAR";
  /**
   * File: aim/arar.proto
   * aim.ARAR.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar.proto
   * aim.ARAR.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar.proto
   * aim.ARAR.request_code
   */
  readonly request_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar.proto
   * aim.ARAR.request_id
   */
  readonly request_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar.proto
   * aim.ARAR.response_code
   */
  readonly response_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar.proto
   * aim.ARAR.text
   */
  readonly text?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/arar_atrl.proto
 * aim.ARAR_ATRL
 */
export interface IAim_Arar_Atrl {
  readonly __typename?: "aim_ARAR_ATRL";
  /**
   * File: aim/arar_atrl.proto
   * aim.ARAR_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar_atrl.proto
   * aim.ARAR_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar_atrl.proto
   * aim.ARAR_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar_atrl.proto
   * aim.ARAR_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arar_atrl.proto
   * aim.ARAR_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/arar_file.proto
 * aim.ARAR_File
 */
export interface IAim_Arar_File {
  readonly __typename?: "aim_ARAR_File";
  /**
   * File: aim/arar_file.proto
   * aim.ARAR_File.arar
   */
  readonly arar: ReadonlyArray<Maybe<IAim_Arar>>;
  /**
   * File: aim/arar_file.proto
   * aim.ARAR_File.atrl
   */
  readonly atrl?: Maybe<IAim_Arar_Atrl>;
}

/**
 * File: aim/arec.proto
 * aim.AREC
 */
export interface IAim_Arec {
  readonly __typename?: "aim_AREC";
  /**
   * File: aim/arec.proto
   * aim.AREC.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec.proto
   * aim.AREC.current_balance
   */
  readonly current_balance?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec.proto
   * aim.AREC.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec.proto
   * aim.AREC.last_payment_amount
   */
  readonly last_payment_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec.proto
   * aim.AREC.last_payment_date
   */
  readonly last_payment_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec.proto
   * aim.AREC.original_balance
   */
  readonly original_balance?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec.proto
   * aim.AREC.received_date
   */
  readonly received_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec.proto
   * aim.AREC.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/arec_atrl.proto
 * aim.AREC_ATRL
 */
export interface IAim_Arec_Atrl {
  readonly __typename?: "aim_AREC_ATRL";
  /**
   * File: aim/arec_atrl.proto
   * aim.AREC_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec_atrl.proto
   * aim.AREC_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec_atrl.proto
   * aim.AREC_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec_atrl.proto
   * aim.AREC_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec_atrl.proto
   * aim.AREC_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec_atrl.proto
   * aim.AREC_ATRL.total_current_balance
   */
  readonly total_current_balance?: Maybe<Scalars["String"]>;
  /**
   * File: aim/arec_atrl.proto
   * aim.AREC_ATRL.total_original_balance
   */
  readonly total_original_balance?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/arec_file.proto
 * aim.AREC_File
 */
export interface IAim_Arec_File {
  readonly __typename?: "aim_AREC_File";
  /**
   * File: aim/arec_file.proto
   * aim.AREC_File.arec
   */
  readonly arec: ReadonlyArray<Maybe<IAim_Arec>>;
  /**
   * File: aim/arec_file.proto
   * aim.AREC_File.atrl
   */
  readonly atrl?: Maybe<IAim_Arec_Atrl>;
}

/**
 * File: aim/asts.proto
 * aim.ASTS
 */
export interface IAim_Asts {
  readonly __typename?: "aim_ASTS";
  /**
   * File: aim/asts.proto
   * aim.ASTS.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts.proto
   * aim.ASTS.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts.proto
   * aim.ASTS.note
   */
  readonly note?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts.proto
   * aim.ASTS.note_date
   */
  readonly note_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts.proto
   * aim.ASTS.notification
   */
  readonly notification?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts.proto
   * aim.ASTS.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts.proto
   * aim.ASTS.status
   */
  readonly status?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/asts_atrl.proto
 * aim.ASTS_ATRL
 */
export interface IAim_Asts_Atrl {
  readonly __typename?: "aim_ASTS_ATRL";
  /**
   * File: aim/asts_atrl.proto
   * aim.ASTS_ATRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts_atrl.proto
   * aim.ASTS_ATRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts_atrl.proto
   * aim.ASTS_ATRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts_atrl.proto
   * aim.ASTS_ATRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/asts_atrl.proto
   * aim.ASTS_ATRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/asts_file.proto
 * aim.ASTS_File
 */
export interface IAim_Asts_File {
  readonly __typename?: "aim_ASTS_File";
  /**
   * File: aim/asts_file.proto
   * aim.ASTS_File.asts
   */
  readonly asts: ReadonlyArray<Maybe<IAim_Asts>>;
  /**
   * File: aim/asts_file.proto
   * aim.ASTS_File.atrl
   */
  readonly atrl?: Maybe<IAim_Asts_Atrl>;
}

/**
 * File: aim/auad.proto
 * aim.AUAD
 */
export interface IAim_Auad {
  readonly __typename?: "aim_AUAD";
  /**
   * File: aim/auad.proto
   * aim.AUAD.date_updated
   */
  readonly date_updated?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.new_city
   */
  readonly new_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.new_state
   */
  readonly new_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.new_street1
   */
  readonly new_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.new_street2
   */
  readonly new_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.new_zipcode
   */
  readonly new_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.old_city
   */
  readonly old_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.old_state
   */
  readonly old_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.old_street1
   */
  readonly old_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.old_street2
   */
  readonly old_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.old_zipcode
   */
  readonly old_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auad.proto
   * aim.AUAD.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/auph.proto
 * aim.AUPH
 */
export interface IAim_Auph {
  readonly __typename?: "aim_AUPH";
  /**
   * File: aim/auph.proto
   * aim.AUPH.date_updated
   */
  readonly date_updated?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auph.proto
   * aim.AUPH.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auph.proto
   * aim.AUPH.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auph.proto
   * aim.AUPH.new_number
   */
  readonly new_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auph.proto
   * aim.AUPH.old_number
   */
  readonly old_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auph.proto
   * aim.AUPH.phone_type
   */
  readonly phone_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/auph.proto
   * aim.AUPH.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/aupp.proto
 * aim.AUPP
 */
export interface IAim_Aupp {
  readonly __typename?: "aim_AUPP";
  /**
   * File: aim/aupp.proto
   * aim.AUPP.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.on_hold
   */
  readonly on_hold?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.phone_ext
   */
  readonly phone_ext?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.phone_name
   */
  readonly phone_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.phone_status_id
   */
  readonly phone_status_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.phone_type
   */
  readonly phone_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.relationship
   */
  readonly relationship?: Maybe<Scalars["String"]>;
  /**
   * File: aim/aupp.proto
   * aim.AUPP.source
   */
  readonly source?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/caaa.proto
 * aim.CAAA
 */
export interface IAim_Caaa {
  readonly __typename?: "aim_CAAA";
  /**
   * File: aim/caaa.proto
   * aim.CAAA.appraisal_received_date
   */
  readonly appraisal_received_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caaa.proto
   * aim.CAAA.appraisal_source_publication
   */
  readonly appraisal_source_publication?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caaa.proto
   * aim.CAAA.appraiser_code
   */
  readonly appraiser_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caaa.proto
   * aim.CAAA.average_value
   */
  readonly average_value?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caaa.proto
   * aim.CAAA.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caaa.proto
   * aim.CAAA.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
  /**
   * File: aim/caaa.proto
   * aim.CAAA.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caaa.proto
   * aim.CAAA.retail_value
   */
  readonly retail_value?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/caab.proto
 * aim.CAAB
 */
export interface IAim_Caab {
  readonly __typename?: "aim_CAAB";
  /**
   * File: aim/caab.proto
   * aim.CAAB.accept_bid
   */
  readonly accept_bid?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caab.proto
   * aim.CAAB.bid_amount
   */
  readonly bid_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caab.proto
   * aim.CAAB.bid_date
   */
  readonly bid_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caab.proto
   * aim.CAAB.bidder_code
   */
  readonly bidder_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caab.proto
   * aim.CAAB.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caab.proto
   * aim.CAAB.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
  /**
   * File: aim/caab.proto
   * aim.CAAB.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/caau.proto
 * aim.CAAU
 */
export interface IAim_Caau {
  readonly __typename?: "aim_CAAU";
  /**
   * File: aim/caau.proto
   * aim.CAAU.auction_expense
   */
  readonly auction_expense?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.bid_close_date
   */
  readonly bid_close_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.buyer_po_number
   */
  readonly buyer_po_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.collateral_appraiser_code
   */
  readonly collateral_appraiser_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.collateral_repairs_needed
   */
  readonly collateral_repairs_needed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.collateral_sale_price
   */
  readonly collateral_sale_price?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.collateral_stock_number
   */
  readonly collateral_stock_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_appraisal_received
   */
  readonly date_appraisal_received?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_appraisal_verified
   */
  readonly date_appraisal_verified?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_collateral_availablefor_sale
   */
  readonly date_collateral_availablefor_sale?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_collateral_sold
   */
  readonly date_collateral_sold?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_notice_sentto_guarantor
   */
  readonly date_notice_sentto_guarantor?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_notice_sentto_maker
   */
  readonly date_notice_sentto_maker?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_notice_sentto_other
   */
  readonly date_notice_sentto_other?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_payment_receivedfor_collatoral
   */
  readonly date_payment_receivedfor_collatoral?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_repairs_approved
   */
  readonly date_repairs_approved?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_repairs_completed
   */
  readonly date_repairs_completed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.date_repairs_ordered
   */
  readonly date_repairs_ordered?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.dateof_letterto_lien_holder1
   */
  readonly dateof_letterto_lien_holder1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.dateof_letterto_lien_holder2
   */
  readonly dateof_letterto_lien_holder2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.inspection_date
   */
  readonly inspection_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.location
   */
  readonly location?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.repair_comments
   */
  readonly repair_comments?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.repair_description
   */
  readonly repair_description?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.repaired_value
   */
  readonly repaired_value?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.sell_as_isor_repaired
   */
  readonly sell_as_isor_repaired?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.title_ordered_date
   */
  readonly title_ordered_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.title_received_date
   */
  readonly title_received_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caau.proto
   * aim.CAAU.title_sentto_auction
   */
  readonly title_sentto_auction?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/caco.proto
 * aim.CACO
 */
export interface IAim_Caco {
  readonly __typename?: "aim_CACO";
  /**
   * File: aim/caco.proto
   * aim.CACO.add_ons
   */
  readonly add_ons?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.collateral_damaged
   */
  readonly collateral_damaged?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.collateral_miles_hours
   */
  readonly collateral_miles_hours?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.collateral_totaled
   */
  readonly collateral_totaled?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.collateral_year
   */
  readonly collateral_year?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.color
   */
  readonly color?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.condition_description
   */
  readonly condition_description?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.dealer_code
   */
  readonly dealer_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.dealer_endorsement_code
   */
  readonly dealer_endorsement_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.dealer_reserve_chargeback
   */
  readonly dealer_reserve_chargeback?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.fair_market_value
   */
  readonly fair_market_value?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.finance_charge_due
   */
  readonly finance_charge_due?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.have_title
   */
  readonly have_title?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.ignition_key_number
   */
  readonly ignition_key_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.late_charge_due
   */
  readonly late_charge_due?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.legal_code
   */
  readonly legal_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.make
   */
  readonly make?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.manufacturing_code
   */
  readonly manufacturing_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.miles_hours
   */
  readonly miles_hours?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.model
   */
  readonly model?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.msrp
   */
  readonly msrp?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.other_key_number
   */
  readonly other_key_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.purchase_amount
   */
  readonly purchase_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.sell_collateral
   */
  readonly sell_collateral?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.series_identifier
   */
  readonly series_identifier?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.tag_decal_number
   */
  readonly tag_decal_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.tag_decal_state
   */
  readonly tag_decal_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.tag_decal_year
   */
  readonly tag_decal_year?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.termination_date
   */
  readonly termination_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.termination_effectiven_date
   */
  readonly termination_effectiven_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.title_position
   */
  readonly title_position?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.title_state
   */
  readonly title_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.title_status
   */
  readonly title_status?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.vin
   */
  readonly vin?: Maybe<Scalars["String"]>;
  /**
   * File: aim/caco.proto
   * aim.CACO.volume_date
   */
  readonly volume_date?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cact.proto
 * aim.CACT
 */
export interface IAim_Cact {
  readonly __typename?: "aim_CACT";
  /**
   * File: aim/cact.proto
   * aim.CACT.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.aim_investor_group_name
   */
  readonly aim_investor_group_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.aim_seller_group_name
   */
  readonly aim_seller_group_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.chargeoffdate
   */
  readonly chargeoffdate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.clialc
   */
  readonly clialc?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.clialp
   */
  readonly clialp?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.clidlc
   */
  readonly clidlc?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.clidlp
   */
  readonly clidlp?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.contractdate
   */
  readonly contractdate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.customer
   */
  readonly customer?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.customer_alphacode
   */
  readonly customer_alphacode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.customer_branch
   */
  readonly customer_branch?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.customer_company
   */
  readonly customer_company?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.customer_district
   */
  readonly customer_district?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.customer_division
   */
  readonly customer_division?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.customer_name
   */
  readonly customer_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.delinquencydate
   */
  readonly delinquencydate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.desk
   */
  readonly desk?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.id1
   */
  readonly id1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.id2
   */
  readonly id2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.interest
   */
  readonly interest?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.interest_rate
   */
  readonly interest_rate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.last_charge
   */
  readonly last_charge?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.last_interest
   */
  readonly last_interest?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.last_paid
   */
  readonly last_paid?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.last_paid_amount
   */
  readonly last_paid_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.original_creditor
   */
  readonly original_creditor?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.other3
   */
  readonly other3?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.other4
   */
  readonly other4?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.other5
   */
  readonly other5?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.other6
   */
  readonly other6?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.other7
   */
  readonly other7?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.other8
   */
  readonly other8?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.other9
   */
  readonly other9?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.previous_creditor
   */
  readonly previous_creditor?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.principal
   */
  readonly principal?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.purchaseddate
   */
  readonly purchaseddate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.userdate1
   */
  readonly userdate1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.userdate2
   */
  readonly userdate2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cact.proto
   * aim.CACT.userdate3
   */
  readonly userdate3?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cals.proto
 * aim.CALS
 */
export interface IAim_Cals {
  readonly __typename?: "aim_CALS";
  /**
   * File: aim/cals.proto
   * aim.CALS.contract_miles
   */
  readonly contract_miles?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.contract_obligation
   */
  readonly contract_obligation?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.disposal_assessed_amount
   */
  readonly disposal_assessed_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.disposition_date
   */
  readonly disposition_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.effective_date
   */
  readonly effective_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.endof_term_miles
   */
  readonly endof_term_miles?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.endof_term_tax_assessed
   */
  readonly endof_term_tax_assessed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.excess_mileage
   */
  readonly excess_mileage?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.excess_miles
   */
  readonly excess_miles?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.inception_miles
   */
  readonly inception_miles?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.inspection_received_date
   */
  readonly inspection_received_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.major_wear_charge
   */
  readonly major_wear_charge?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.maturity_date
   */
  readonly maturity_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.mileage_credit
   */
  readonly mileage_credit?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.minor_wear_charge
   */
  readonly minor_wear_charge?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.original_miles
   */
  readonly original_miles?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.other_tax_assessed
   */
  readonly other_tax_assessed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.purchase_miles
   */
  readonly purchase_miles?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.residual
   */
  readonly residual?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.residual_gain_loss
   */
  readonly residual_gain_loss?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.return_date
   */
  readonly return_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.security_deposit
   */
  readonly security_deposit?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.term_months
   */
  readonly term_months?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.unpaid_months_payment
   */
  readonly unpaid_months_payment?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.unpaid_tax
   */
  readonly unpaid_tax?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.unused_miles
   */
  readonly unused_miles?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cals.proto
   * aim.CALS.wearand_tear
   */
  readonly wearand_tear?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/carb.proto
 * aim.CARB
 */
export interface IAim_Carb {
  readonly __typename?: "aim_CARB";
  /**
   * File: aim/carb.proto
   * aim.CARB.accept_estimate
   */
  readonly accept_estimate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carb.proto
   * aim.CARB.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carb.proto
   * aim.CARB.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
  /**
   * File: aim/carb.proto
   * aim.CARB.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carb.proto
   * aim.CARB.repair_code
   */
  readonly repair_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carb.proto
   * aim.CARB.repair_estimate
   */
  readonly repair_estimate?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/carp.proto
 * aim.CARP
 */
export interface IAim_Carp {
  readonly __typename?: "aim_CARP";
  /**
   * File: aim/carp.proto
   * aim.CARP.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.agency_phone
   */
  readonly agency_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.balance_at_repo
   */
  readonly balance_at_repo?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.collateral_condition
   */
  readonly collateral_condition?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.collateral_drivable
   */
  readonly collateral_drivable?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.collateral_lease_end_repo
   */
  readonly collateral_lease_end_repo?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.collateral_redeemedby
   */
  readonly collateral_redeemedby?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.collateral_released
   */
  readonly collateral_released?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.collateral_repo_code
   */
  readonly collateral_repo_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.collatoral_storage_location
   */
  readonly collatoral_storage_location?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.date_collateral_releasedto_buyer
   */
  readonly date_collateral_releasedto_buyer?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.date_repo_assigned
   */
  readonly date_repo_assigned?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.date_repo_completed
   */
  readonly date_repo_completed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.dateinto_storage
   */
  readonly dateinto_storage?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.impound_fee
   */
  readonly impound_fee?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.key_cut_fee
   */
  readonly key_cut_fee?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.misc_fees
   */
  readonly misc_fees?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.police_entity
   */
  readonly police_entity?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.property_storage_fee
   */
  readonly property_storage_fee?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.redemption_amount
   */
  readonly redemption_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.redemption_date
   */
  readonly redemption_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.repo_address1
   */
  readonly repo_address1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.repo_address2
   */
  readonly repo_address2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.repo_city
   */
  readonly repo_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.repo_expenses
   */
  readonly repo_expenses?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.repo_fees
   */
  readonly repo_fees?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.repo_state
   */
  readonly repo_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.repo_zipcode
   */
  readonly repo_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: aim/carp.proto
   * aim.CARP.storage_comment
   */
  readonly storage_comment?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cast.proto
 * aim.CAST
 */
export interface IAim_Cast {
  readonly __typename?: "aim_CAST";
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_assets_asset_id
   */
  readonly debtor_assets_asset_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_assets_asset_type
   */
  readonly debtor_assets_asset_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_assets_current_value
   */
  readonly debtor_assets_current_value?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_assets_description
   */
  readonly debtor_assets_description?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_assets_lien_amount
   */
  readonly debtor_assets_lien_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_assets_lien_verified
   */
  readonly debtor_assets_lien_verified?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_assets_name
   */
  readonly debtor_assets_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_assets_value_verified
   */
  readonly debtor_assets_value_verified?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast.proto
   * aim.CAST.na
   */
  readonly na?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cast_ctrl.proto
 * aim.CAST_CTRL
 */
export interface IAim_Cast_Ctrl {
  readonly __typename?: "aim_CAST_CTRL";
  /**
   * File: aim/cast_ctrl.proto
   * aim.CAST_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast_ctrl.proto
   * aim.CAST_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast_ctrl.proto
   * aim.CAST_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast_ctrl.proto
   * aim.CAST_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cast_ctrl.proto
   * aim.CAST_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cast_file.proto
 * aim.CAST_File
 */
export interface IAim_Cast_File {
  readonly __typename?: "aim_CAST_File";
  /**
   * File: aim/cast_file.proto
   * aim.CAST_File.cast
   */
  readonly cast: ReadonlyArray<Maybe<IAim_Cast>>;
  /**
   * File: aim/cast_file.proto
   * aim.CAST_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cast_Ctrl>;
}

/**
 * File: aim/cbkp.proto
 * aim.CBKP
 */
export interface IAim_Cbkp {
  readonly __typename?: "aim_CBKP";
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.auction_amount
   */
  readonly auction_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.auction_applied_amount
   */
  readonly auction_applied_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.auction_date
   */
  readonly auction_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.auction_fee_amount
   */
  readonly auction_fee_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.auction_house
   */
  readonly auction_house?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.case_number
   */
  readonly case_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.chapter
   */
  readonly chapter?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.comments
   */
  readonly comments?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.confirmation_hearing_date
   */
  readonly confirmation_hearing_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.converted_from_chapter
   */
  readonly converted_from_chapter?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.court_address1
   */
  readonly court_address1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.court_address2
   */
  readonly court_address2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.court_city
   */
  readonly court_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.court_district
   */
  readonly court_district?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.court_division
   */
  readonly court_division?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.court_phone
   */
  readonly court_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.court_state
   */
  readonly court_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.court_zipcode
   */
  readonly court_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.date_filed
   */
  readonly date_filed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.discharge_date
   */
  readonly discharge_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.dismissal_date
   */
  readonly dismissal_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.has_asset
   */
  readonly has_asset?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.notice_date
   */
  readonly notice_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.proof_filed_date
   */
  readonly proof_filed_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.reaffirm_amount
   */
  readonly reaffirm_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.reaffirm_filed_date
   */
  readonly reaffirm_filed_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.reaffirm_flag
   */
  readonly reaffirm_flag?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.reaffirm_terms
   */
  readonly reaffirm_terms?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.secured_amount
   */
  readonly secured_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.secured_percentage
   */
  readonly secured_percentage?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.surrender_date
   */
  readonly surrender_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.surrender_method
   */
  readonly surrender_method?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.three_forty_one_date
   */
  readonly three_forty_one_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.three_forty_one_info_flag
   */
  readonly three_forty_one_info_flag?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.three_forty_one_location
   */
  readonly three_forty_one_location?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.transmit_date
   */
  readonly transmit_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.trustee
   */
  readonly trustee?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.trustee_address1
   */
  readonly trustee_address1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.trustee_address2
   */
  readonly trustee_address2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.trustee_city
   */
  readonly trustee_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.trustee_phone
   */
  readonly trustee_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.trustee_state
   */
  readonly trustee_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.trustee_zipcode
   */
  readonly trustee_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.unsecured_amount
   */
  readonly unsecured_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.unsecured_percentage
   */
  readonly unsecured_percentage?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.voluntary_amount
   */
  readonly voluntary_amount?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.voluntary_date
   */
  readonly voluntary_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp.proto
   * aim.CBKP.voluntary_terms
   */
  readonly voluntary_terms?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cbkp_ctrl.proto
 * aim.CBKP_CTRL
 */
export interface IAim_Cbkp_Ctrl {
  readonly __typename?: "aim_CBKP_CTRL";
  /**
   * File: aim/cbkp_ctrl.proto
   * aim.CBKP_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp_ctrl.proto
   * aim.CBKP_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp_ctrl.proto
   * aim.CBKP_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp_ctrl.proto
   * aim.CBKP_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cbkp_ctrl.proto
   * aim.CBKP_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cbkp_file.proto
 * aim.CBKP_File
 */
export interface IAim_Cbkp_File {
  readonly __typename?: "aim_CBKP_File";
  /**
   * File: aim/cbkp_file.proto
   * aim.CBKP_File.cbkp
   */
  readonly cbkp: ReadonlyArray<Maybe<IAim_Cbkp>>;
  /**
   * File: aim/cbkp_file.proto
   * aim.CBKP_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cbkp_Ctrl>;
}

/**
 * File: aim/cdec.proto
 * aim.CDEC
 */
export interface IAim_Cdec {
  readonly __typename?: "aim_CDEC";
  /**
   * File: aim/cdec.proto
   * aim.CDEC.case_number
   */
  readonly case_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.claim_deadline_date
   */
  readonly claim_deadline_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.court_city
   */
  readonly court_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.court_district
   */
  readonly court_district?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.court_division
   */
  readonly court_division?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.court_phone
   */
  readonly court_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.court_state
   */
  readonly court_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.court_street1
   */
  readonly court_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.court_street2
   */
  readonly court_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.court_zipcode
   */
  readonly court_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.date_of_birth
   */
  readonly date_of_birth?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.date_of_death
   */
  readonly date_of_death?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.executor
   */
  readonly executor?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.executor_city
   */
  readonly executor_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.executor_fax
   */
  readonly executor_fax?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.executor_phone
   */
  readonly executor_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.executor_state
   */
  readonly executor_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.executor_street1
   */
  readonly executor_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.executor_street2
   */
  readonly executor_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.executor_zipcode
   */
  readonly executor_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.filed_date
   */
  readonly filed_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.match_code
   */
  readonly match_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.postal_code
   */
  readonly postal_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec.proto
   * aim.CDEC.transmit_date
   */
  readonly transmit_date?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cdec_ctrl.proto
 * aim.CDEC_CTRL
 */
export interface IAim_Cdec_Ctrl {
  readonly __typename?: "aim_CDEC_CTRL";
  /**
   * File: aim/cdec_ctrl.proto
   * aim.CDEC_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec_ctrl.proto
   * aim.CDEC_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec_ctrl.proto
   * aim.CDEC_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec_ctrl.proto
   * aim.CDEC_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdec_ctrl.proto
   * aim.CDEC_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cdec_file.proto
 * aim.CDEC_File
 */
export interface IAim_Cdec_File {
  readonly __typename?: "aim_CDEC_File";
  /**
   * File: aim/cdec_file.proto
   * aim.CDEC_File.cdec
   */
  readonly cdec: ReadonlyArray<Maybe<IAim_Cdec>>;
  /**
   * File: aim/cdec_file.proto
   * aim.CDEC_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cdec_Ctrl>;
}

/**
 * File: aim/cdem_ctrl.proto
 * aim.CDEM_CTRL
 */
export interface IAim_Cdem_Ctrl {
  readonly __typename?: "aim_CDEM_CTRL";
  /**
   * File: aim/cdem_ctrl.proto
   * aim.CDEM_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdem_ctrl.proto
   * aim.CDEM_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdem_ctrl.proto
   * aim.CDEM_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdem_ctrl.proto
   * aim.CDEM_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdem_ctrl.proto
   * aim.CDEM_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cdem_file.proto
 * aim.CDEM_File
 */
export interface IAim_Cdem_File {
  readonly __typename?: "aim_CDEM_File";
  /**
   * File: aim/cdem_file.proto
   * aim.CDEM_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cdem_Ctrl>;
  /**
   * File: aim/cdem_file.proto
   * aim.CDEM_File.cuad
   */
  readonly cuad: ReadonlyArray<Maybe<IAim_Cuad>>;
  /**
   * File: aim/cdem_file.proto
   * aim.CDEM_File.cuph
   */
  readonly cuph: ReadonlyArray<Maybe<IAim_Cuph>>;
  /**
   * File: aim/cdem_file.proto
   * aim.CDEM_File.cupp
   */
  readonly cupp: ReadonlyArray<Maybe<IAim_Cupp>>;
}

/**
 * File: aim/cdxx.proto
 * aim.CDXX
 */
export interface IAim_Cdxx {
  readonly __typename?: "aim_CDXX";
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_city
   */
  readonly attorney_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_fax
   */
  readonly attorney_fax?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_firm
   */
  readonly attorney_firm?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_name
   */
  readonly attorney_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_notes
   */
  readonly attorney_notes?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_phone
   */
  readonly attorney_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_state
   */
  readonly attorney_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_street1
   */
  readonly attorney_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_street2
   */
  readonly attorney_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.attorney_zipcode
   */
  readonly attorney_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.city
   */
  readonly city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.country
   */
  readonly country?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.county
   */
  readonly county?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.date_of_birth
   */
  readonly date_of_birth?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.home_phone
   */
  readonly home_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.job_city_state_zipcode
   */
  readonly job_city_state_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.job_name
   */
  readonly job_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.job_street1
   */
  readonly job_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.job_street2
   */
  readonly job_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.mail_return
   */
  readonly mail_return?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.other_name
   */
  readonly other_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.spouse_home_phone
   */
  readonly spouse_home_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.spouse_job_city_state_zipcode
   */
  readonly spouse_job_city_state_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.spouse_job_name
   */
  readonly spouse_job_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.spouse_job_street1
   */
  readonly spouse_job_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.spouse_job_street2
   */
  readonly spouse_job_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.spouse_name
   */
  readonly spouse_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.spouse_work_phone
   */
  readonly spouse_work_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.street1
   */
  readonly street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.street2
   */
  readonly street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.work_phone
   */
  readonly work_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cdxx.proto
   * aim.CDXX.zipcode
   */
  readonly zipcode?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/ceqp.proto
 * aim.CEQP
 */
export interface IAim_Ceqp {
  readonly __typename?: "aim_CEQP";
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.act
   */
  readonly act?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.collat_desc
   */
  readonly collat_desc?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.color
   */
  readonly color?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.commissionable
   */
  readonly commissionable?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.cond
   */
  readonly cond?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.dlr
   */
  readonly dlr?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.dsp_anal
   */
  readonly dsp_anal?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.dsp_cd
   */
  readonly dsp_cd?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.dsp_dt
   */
  readonly dsp_dt?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.equipment_id
   */
  readonly equipment_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.fil_dt
   */
  readonly fil_dt?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.fil_loc
   */
  readonly fil_loc?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.ins
   */
  readonly ins?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.key_cd
   */
  readonly key_cd?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.lic
   */
  readonly lic?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.ln
   */
  readonly ln?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.loc
   */
  readonly loc?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.mdl
   */
  readonly mdl?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.mk
   */
  readonly mk?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.pln_cd
   */
  readonly pln_cd?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.prd_cmplt
   */
  readonly prd_cmplt?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.reas_cd
   */
  readonly reas_cd?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.rec_mthd
   */
  readonly rec_mthd?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.recovered
   */
  readonly recovered?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.recovered_date
   */
  readonly recovered_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.repo_dt
   */
  readonly repo_dt?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.ser
   */
  readonly ser?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.tag
   */
  readonly tag?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.typ_co_cd
   */
  readonly typ_co_cd?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.ucc_cd
   */
  readonly ucc_cd?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.val
   */
  readonly val?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.vin
   */
  readonly vin?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.when_loaded
   */
  readonly when_loaded?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.x_coll
   */
  readonly x_coll?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp.proto
   * aim.CEQP.yr
   */
  readonly yr?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/ceqp_ctrl.proto
 * aim.CEQP_CTRL
 */
export interface IAim_Ceqp_Ctrl {
  readonly __typename?: "aim_CEQP_CTRL";
  /**
   * File: aim/ceqp_ctrl.proto
   * aim.CEQP_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp_ctrl.proto
   * aim.CEQP_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp_ctrl.proto
   * aim.CEQP_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp_ctrl.proto
   * aim.CEQP_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/ceqp_ctrl.proto
   * aim.CEQP_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/ceqp_file.proto
 * aim.CEQP_File
 */
export interface IAim_Ceqp_File {
  readonly __typename?: "aim_CEQP_File";
  /**
   * File: aim/ceqp_file.proto
   * aim.CEQP_File.ceqp
   */
  readonly ceqp: ReadonlyArray<Maybe<IAim_Ceqp>>;
  /**
   * File: aim/ceqp_file.proto
   * aim.CEQP_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Ceqp_Ctrl>;
}

/**
 * File: aim/cfin.proto
 * aim.CFIN
 */
export interface IAim_Cfin {
  readonly __typename?: "aim_CFIN";
  /**
   * File: aim/cfin.proto
   * aim.CFIN.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cfin.proto
   * aim.CFIN.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cfin.proto
   * aim.CFIN.recall_reason
   */
  readonly recall_reason?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cfin.proto
   * aim.CFIN.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cins.proto
 * aim.CINS
 */
export interface IAim_Cins {
  readonly __typename?: "aim_CINS";
  /**
   * File: aim/cins.proto
   * aim.CINS.accept_assignment
   */
  readonly accept_assignment?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.auth_pmt_to_providor
   */
  readonly auth_pmt_to_providor?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.carrier_city
   */
  readonly carrier_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.carrier_doc_provider_number
   */
  readonly carrier_doc_provider_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.carrier_name
   */
  readonly carrier_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.carrier_ref_doc_provider_number
   */
  readonly carrier_ref_doc_provider_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.carrier_state
   */
  readonly carrier_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.carrier_street1
   */
  readonly carrier_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.carrier_street2
   */
  readonly carrier_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.carrier_zip
   */
  readonly carrier_zip?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.employer_health_plan
   */
  readonly employer_health_plan?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.group_name
   */
  readonly group_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.group_number
   */
  readonly group_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insurance_id
   */
  readonly insurance_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_birthday
   */
  readonly insured_birthday?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_city
   */
  readonly insured_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_employer
   */
  readonly insured_employer?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_name
   */
  readonly insured_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_phone
   */
  readonly insured_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_sex
   */
  readonly insured_sex?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_state
   */
  readonly insured_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_street1
   */
  readonly insured_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_street2
   */
  readonly insured_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.insured_zip
   */
  readonly insured_zip?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.notused
   */
  readonly notused?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.patient_relation_to_insured
   */
  readonly patient_relation_to_insured?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.policy_number
   */
  readonly policy_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cins.proto
   * aim.CINS.program
   */
  readonly program?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cjdg.proto
 * aim.CJDG
 */
export interface IAim_Cjdg {
  readonly __typename?: "aim_CJDG";
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.arbitration_date
   */
  readonly arbitration_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.attorney_ack_date
   */
  readonly attorney_ack_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.case_number
   */
  readonly case_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_city
   */
  readonly court_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_clerk_first_name
   */
  readonly court_clerk_first_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_clerk_last_name
   */
  readonly court_clerk_last_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_clerk_middle_name
   */
  readonly court_clerk_middle_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_county
   */
  readonly court_county?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_date
   */
  readonly court_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_fax
   */
  readonly court_fax?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_name
   */
  readonly court_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_notes
   */
  readonly court_notes?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_phone
   */
  readonly court_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_room
   */
  readonly court_room?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_salutation
   */
  readonly court_salutation?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_state
   */
  readonly court_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_street1
   */
  readonly court_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_street2
   */
  readonly court_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.court_zipcode
   */
  readonly court_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.date_answered
   */
  readonly date_answered?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.date_filed
   */
  readonly date_filed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.defendant
   */
  readonly defendant?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.discovery_cutoff
   */
  readonly discovery_cutoff?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.discovery_reply_date
   */
  readonly discovery_reply_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.int_from_date
   */
  readonly int_from_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judge
   */
  readonly judge?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_amt
   */
  readonly judgement_amt?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_attorney_cost_award
   */
  readonly judgement_attorney_cost_award?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_book
   */
  readonly judgement_book?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_cost_award
   */
  readonly judgement_cost_award?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_date
   */
  readonly judgement_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_flag
   */
  readonly judgement_flag?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_int_award
   */
  readonly judgement_int_award?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_int_rate
   */
  readonly judgement_int_rate?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_other_award
   */
  readonly judgement_other_award?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_page
   */
  readonly judgement_page?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.judgement_recorded_date
   */
  readonly judgement_recorded_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.last_summary_judgement_date
   */
  readonly last_summary_judgement_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.misc_info1
   */
  readonly misc_info1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.misc_info2
   */
  readonly misc_info2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.motion_cutoff
   */
  readonly motion_cutoff?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.plaintiff
   */
  readonly plaintiff?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.remarks
   */
  readonly remarks?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.service_date
   */
  readonly service_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.service_type
   */
  readonly service_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.status
   */
  readonly status?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg.proto
   * aim.CJDG.statute_deadline
   */
  readonly statute_deadline?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cjdg_ctrl.proto
 * aim.CJDG_CTRL
 */
export interface IAim_Cjdg_Ctrl {
  readonly __typename?: "aim_CJDG_CTRL";
  /**
   * File: aim/cjdg_ctrl.proto
   * aim.CJDG_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg_ctrl.proto
   * aim.CJDG_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg_ctrl.proto
   * aim.CJDG_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg_ctrl.proto
   * aim.CJDG_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cjdg_ctrl.proto
   * aim.CJDG_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cjdg_file.proto
 * aim.CJDG_File
 */
export interface IAim_Cjdg_File {
  readonly __typename?: "aim_CJDG_File";
  /**
   * File: aim/cjdg_file.proto
   * aim.CJDG_File.cjdg
   */
  readonly cjdg: ReadonlyArray<Maybe<IAim_Cjdg>>;
  /**
   * File: aim/cjdg_file.proto
   * aim.CJDG_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cjdg_Ctrl>;
}

/**
 * File: aim/cmis.proto
 * aim.CMIS
 */
export interface IAim_Cmis {
  readonly __typename?: "aim_CMIS";
  /**
   * File: aim/cmis.proto
   * aim.CMIS.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cmis.proto
   * aim.CMIS.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cmis.proto
   * aim.CMIS.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cmis.proto
   * aim.CMIS.thedata
   */
  readonly thedata?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cmis.proto
   * aim.CMIS.title
   */
  readonly title?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cmis_ctrl.proto
 * aim.CMIS_CTRL
 */
export interface IAim_Cmis_Ctrl {
  readonly __typename?: "aim_CMIS_CTRL";
  /**
   * File: aim/cmis_ctrl.proto
   * aim.CMIS_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cmis_ctrl.proto
   * aim.CMIS_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cmis_ctrl.proto
   * aim.CMIS_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cmis_ctrl.proto
   * aim.CMIS_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cmis_ctrl.proto
   * aim.CMIS_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cmis_file.proto
 * aim.CMIS_File
 */
export interface IAim_Cmis_File {
  readonly __typename?: "aim_CMIS_File";
  /**
   * File: aim/cmis_file.proto
   * aim.CMIS_File.cmis
   */
  readonly cmis: ReadonlyArray<Maybe<IAim_Cmis>>;
  /**
   * File: aim/cmis_file.proto
   * aim.CMIS_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cmis_Ctrl>;
}

/**
 * File: aim/cnot.proto
 * aim.CNOT
 */
export interface IAim_Cnot {
  readonly __typename?: "aim_CNOT";
  /**
   * File: aim/cnot.proto
   * aim.CNOT.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot.proto
   * aim.CNOT.note_action
   */
  readonly note_action?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot.proto
   * aim.CNOT.note_comment
   */
  readonly note_comment?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot.proto
   * aim.CNOT.note_created_datetime
   */
  readonly note_created_datetime?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot.proto
   * aim.CNOT.note_result
   */
  readonly note_result?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot.proto
   * aim.CNOT.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cnot_ctrl.proto
 * aim.CNOT_CTRL
 */
export interface IAim_Cnot_Ctrl {
  readonly __typename?: "aim_CNOT_CTRL";
  /**
   * File: aim/cnot_ctrl.proto
   * aim.CNOT_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot_ctrl.proto
   * aim.CNOT_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot_ctrl.proto
   * aim.CNOT_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot_ctrl.proto
   * aim.CNOT_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cnot_ctrl.proto
   * aim.CNOT_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cnot_file.proto
 * aim.CNOT_File
 */
export interface IAim_Cnot_File {
  readonly __typename?: "aim_CNOT_File";
  /**
   * File: aim/cnot_file.proto
   * aim.CNOT_File.cnot
   */
  readonly cnot: ReadonlyArray<Maybe<IAim_Cnot>>;
  /**
   * File: aim/cnot_file.proto
   * aim.CNOT_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cnot_Ctrl>;
}

/**
 * File: aim/cpat.proto
 * aim.CPAT
 */
export interface IAim_Cpat {
  readonly __typename?: "aim_CPAT";
  /**
   * File: aim/cpat.proto
   * aim.CPAT.admission_date
   */
  readonly admission_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.age
   */
  readonly age?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.city
   */
  readonly city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.country
   */
  readonly country?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.discharge_date
   */
  readonly discharge_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.dob
   */
  readonly dob?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.doctor_fax
   */
  readonly doctor_fax?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.doctor_name
   */
  readonly doctor_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.doctor_phone
   */
  readonly doctor_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.employer_name
   */
  readonly employer_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_city
   */
  readonly facility_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_country
   */
  readonly facility_country?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_fax
   */
  readonly facility_fax?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_name
   */
  readonly facility_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_phone
   */
  readonly facility_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_state
   */
  readonly facility_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_street1
   */
  readonly facility_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_street2
   */
  readonly facility_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.facility_zip_code
   */
  readonly facility_zip_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.guarantor_rec_number
   */
  readonly guarantor_rec_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.kin_city
   */
  readonly kin_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.kin_country
   */
  readonly kin_country?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.kin_name
   */
  readonly kin_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.kin_phone
   */
  readonly kin_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.kin_state
   */
  readonly kin_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.kin_street1
   */
  readonly kin_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.kin_street2
   */
  readonly kin_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.kin_zip_code
   */
  readonly kin_zip_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.marital_status
   */
  readonly marital_status?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.notused
   */
  readonly notused?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.patient_rec_number
   */
  readonly patient_rec_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.phone
   */
  readonly phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.service_date
   */
  readonly service_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.sex
   */
  readonly sex?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.street1
   */
  readonly street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.street2
   */
  readonly street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.work_phone
   */
  readonly work_phone?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpat.proto
   * aim.CPAT.zip_code
   */
  readonly zip_code?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cpay.proto
 * aim.CPAY
 */
export interface IAim_Cpay {
  readonly __typename?: "aim_CPAY";
  /**
   * File: aim/cpay.proto
   * aim.CPAY.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.batch_type
   */
  readonly batch_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.interest
   */
  readonly interest?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.other3
   */
  readonly other3?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.other4
   */
  readonly other4?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.other5
   */
  readonly other5?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.other6
   */
  readonly other6?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.other7
   */
  readonly other7?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.other8
   */
  readonly other8?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.other9
   */
  readonly other9?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.payment_date
   */
  readonly payment_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.payment_identifier
   */
  readonly payment_identifier?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.principal
   */
  readonly principal?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay.proto
   * aim.CPAY.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cpay_ctrl.proto
 * aim.CPAY_CTRL
 */
export interface IAim_Cpay_Ctrl {
  readonly __typename?: "aim_CPAY_CTRL";
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.interest
   */
  readonly interest?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.other3
   */
  readonly other3?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.other4
   */
  readonly other4?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.other5
   */
  readonly other5?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.other6
   */
  readonly other6?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.other7
   */
  readonly other7?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.other8
   */
  readonly other8?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.other9
   */
  readonly other9?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.principal
   */
  readonly principal?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpay_ctrl.proto
   * aim.CPAY_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cpay_file.proto
 * aim.CPAY_File
 */
export interface IAim_Cpay_File {
  readonly __typename?: "aim_CPAY_File";
  /**
   * File: aim/cpay_file.proto
   * aim.CPAY_File.cpay
   */
  readonly cpay: ReadonlyArray<Maybe<IAim_Cpay>>;
  /**
   * File: aim/cpay_file.proto
   * aim.CPAY_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cpay_Ctrl>;
}

/**
 * File: aim/cpen.proto
 * aim.CPEN
 */
export interface IAim_Cpen {
  readonly __typename?: "aim_CPEN";
  /**
   * File: aim/cpen.proto
   * aim.CPEN.account
   */
  readonly account?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpen.proto
   * aim.CPEN.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpen.proto
   * aim.CPEN.pending_date
   */
  readonly pending_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpen.proto
   * aim.CPEN.recall_reason
   */
  readonly recall_reason?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cpen.proto
   * aim.CPEN.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cplc_ctrl.proto
 * aim.CPLC_CTRL
 */
export interface IAim_Cplc_Ctrl {
  readonly __typename?: "aim_CPLC_CTRL";
  /**
   * File: aim/cplc_ctrl.proto
   * aim.CPLC_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cplc_ctrl.proto
   * aim.CPLC_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cplc_ctrl.proto
   * aim.CPLC_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cplc_ctrl.proto
   * aim.CPLC_CTRL.dollars_placed
   */
  readonly dollars_placed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cplc_ctrl.proto
   * aim.CPLC_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cplc_ctrl.proto
   * aim.CPLC_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cplc_file.proto
 * aim.CPLC_File
 */
export interface IAim_Cplc_File {
  readonly __typename?: "aim_CPLC_File";
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.caaa
   */
  readonly caaa: ReadonlyArray<Maybe<IAim_Caaa>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.caab
   */
  readonly caab: ReadonlyArray<Maybe<IAim_Caab>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.caau
   */
  readonly caau: ReadonlyArray<Maybe<IAim_Caau>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.caco
   */
  readonly caco: ReadonlyArray<Maybe<IAim_Caco>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cact
   */
  readonly cact: ReadonlyArray<Maybe<IAim_Cact>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cals
   */
  readonly cals: ReadonlyArray<Maybe<IAim_Cals>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.carb
   */
  readonly carb: ReadonlyArray<Maybe<IAim_Carb>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.carp
   */
  readonly carp: ReadonlyArray<Maybe<IAim_Carp>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cast
   */
  readonly cast: ReadonlyArray<Maybe<IAim_Cast>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cbkp
   */
  readonly cbkp: ReadonlyArray<Maybe<IAim_Cbkp>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cdec
   */
  readonly cdec: ReadonlyArray<Maybe<IAim_Cdec>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cdxx
   */
  readonly cdxx: ReadonlyArray<Maybe<IAim_Cdxx>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.ceqp
   */
  readonly ceqp: ReadonlyArray<Maybe<IAim_Ceqp>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cins
   */
  readonly cins: ReadonlyArray<Maybe<IAim_Cins>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cjdg
   */
  readonly cjdg: ReadonlyArray<Maybe<IAim_Cjdg>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cmis
   */
  readonly cmis: ReadonlyArray<Maybe<IAim_Cmis>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cnot
   */
  readonly cnot: ReadonlyArray<Maybe<IAim_Cnot>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cpat
   */
  readonly cpat: ReadonlyArray<Maybe<IAim_Cpat>>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Cplc_Ctrl>;
  /**
   * File: aim/cplc_file.proto
   * aim.CPLC_File.cupp
   */
  readonly cupp: ReadonlyArray<Maybe<IAim_Cupp>>;
}

/**
 * File: aim/crar.proto
 * aim.CRAR
 */
export interface IAim_Crar {
  readonly __typename?: "aim_CRAR";
  /**
   * File: aim/crar.proto
   * aim.CRAR.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar.proto
   * aim.CRAR.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar.proto
   * aim.CRAR.request_code
   */
  readonly request_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar.proto
   * aim.CRAR.request_id
   */
  readonly request_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar.proto
   * aim.CRAR.response_code
   */
  readonly response_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar.proto
   * aim.CRAR.text
   */
  readonly text?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/crar_ctrl.proto
 * aim.CRAR_CTRL
 */
export interface IAim_Crar_Ctrl {
  readonly __typename?: "aim_CRAR_CTRL";
  /**
   * File: aim/crar_ctrl.proto
   * aim.CRAR_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar_ctrl.proto
   * aim.CRAR_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar_ctrl.proto
   * aim.CRAR_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar_ctrl.proto
   * aim.CRAR_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crar_ctrl.proto
   * aim.CRAR_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/crar_file.proto
 * aim.CRAR_File
 */
export interface IAim_Crar_File {
  readonly __typename?: "aim_CRAR_File";
  /**
   * File: aim/crar_file.proto
   * aim.CRAR_File.crar
   */
  readonly crar: ReadonlyArray<Maybe<IAim_Crar>>;
  /**
   * File: aim/crar_file.proto
   * aim.CRAR_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Crar_Ctrl>;
}

/**
 * File: aim/crcl_ctrl.proto
 * aim.CRCL_CTRL
 */
export interface IAim_Crcl_Ctrl {
  readonly __typename?: "aim_CRCL_CTRL";
  /**
   * File: aim/crcl_ctrl.proto
   * aim.CRCL_CTRL.agency_id
   */
  readonly agency_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crcl_ctrl.proto
   * aim.CRCL_CTRL.agency_name
   */
  readonly agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crcl_ctrl.proto
   * aim.CRCL_CTRL.created_date
   */
  readonly created_date?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crcl_ctrl.proto
   * aim.CRCL_CTRL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/crcl_ctrl.proto
   * aim.CRCL_CTRL.records
   */
  readonly records?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/crcl_file.proto
 * aim.CRCL_File
 */
export interface IAim_Crcl_File {
  readonly __typename?: "aim_CRCL_File";
  /**
   * File: aim/crcl_file.proto
   * aim.CRCL_File.cfin
   */
  readonly cfin: ReadonlyArray<Maybe<IAim_Cfin>>;
  /**
   * File: aim/crcl_file.proto
   * aim.CRCL_File.cpen
   */
  readonly cpen: ReadonlyArray<Maybe<IAim_Cpen>>;
  /**
   * File: aim/crcl_file.proto
   * aim.CRCL_File.ctrl
   */
  readonly ctrl?: Maybe<IAim_Crcl_Ctrl>;
}

/**
 * File: aim/cuad.proto
 * aim.CUAD
 */
export interface IAim_Cuad {
  readonly __typename?: "aim_CUAD";
  /**
   * File: aim/cuad.proto
   * aim.CUAD.date_updated
   */
  readonly date_updated?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.new_city
   */
  readonly new_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.new_state
   */
  readonly new_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.new_street1
   */
  readonly new_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.new_street2
   */
  readonly new_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.new_zipcode
   */
  readonly new_zipcode?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.old_city
   */
  readonly old_city?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.old_state
   */
  readonly old_state?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.old_street1
   */
  readonly old_street1?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.old_street2
   */
  readonly old_street2?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.old_zip_code
   */
  readonly old_zip_code?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuad.proto
   * aim.CUAD.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cuph.proto
 * aim.CUPH
 */
export interface IAim_Cuph {
  readonly __typename?: "aim_CUPH";
  /**
   * File: aim/cuph.proto
   * aim.CUPH.date_changed
   */
  readonly date_changed?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuph.proto
   * aim.CUPH.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuph.proto
   * aim.CUPH.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuph.proto
   * aim.CUPH.new_number
   */
  readonly new_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuph.proto
   * aim.CUPH.old_number
   */
  readonly old_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuph.proto
   * aim.CUPH.phone_type
   */
  readonly phone_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cuph.proto
   * aim.CUPH.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: aim/cupp.proto
 * aim.CUPP
 */
export interface IAim_Cupp {
  readonly __typename?: "aim_CUPP";
  /**
   * File: aim/cupp.proto
   * aim.CUPP.debtor_number
   */
  readonly debtor_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.file_number
   */
  readonly file_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.on_hold
   */
  readonly on_hold?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.phone_ext
   */
  readonly phone_ext?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.phone_name
   */
  readonly phone_name?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.phone_status_id
   */
  readonly phone_status_id?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.phone_type
   */
  readonly phone_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.relationship
   */
  readonly relationship?: Maybe<Scalars["String"]>;
  /**
   * File: aim/cupp.proto
   * aim.CUPP.source
   */
  readonly source?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/page_view_data.proto
 * com.trueaccord.proto.analytics.PageViewData
 */
export interface IAnalytics_PageViewData {
  readonly __typename?: "analytics_PageViewData";
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.consumer_id
   */
  readonly consumer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.contentful_details
   */
  readonly contentful_details?: Maybe<IAnalytics_PageViewData_ContentfulDetails>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.debt_ids
   */
  readonly debt_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.django_template
   */
  readonly django_template?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.django_user_id
   */
  readonly django_user_id?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.domain
   */
  readonly domain?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.events
   */
  readonly events: ReadonlyArray<Maybe<IAnalytics_PageViewData_Event | "%future added value">>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.exception
   */
  readonly exception?: Maybe<IAnalytics_PageViewData_Exception>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.from_header
   */
  readonly from_header?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.get_params
   */
  readonly get_params: ReadonlyArray<Maybe<IAnalytics_PageViewData_Param>>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.http_method
   */
  readonly http_method?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.interaction_event
   */
  readonly interaction_event?: Maybe<IAnalytics_PageViewData_InteractionEvent>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.ip_address
   */
  readonly ip_address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.is_staff
   */
  readonly is_staff?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.multiple_account_details
   */
  readonly multiple_account_details?: Maybe<IAnalytics_PageViewData_MultipleAccountDetails>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.page_slug
   */
  readonly page_slug?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.page_template_id
   */
  readonly page_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.path
   */
  readonly path?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.payment_offer_id
   */
  readonly payment_offer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.payment_page_template
   */
  readonly payment_page_template?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.post_params
   */
  readonly post_params: ReadonlyArray<Maybe<IAnalytics_PageViewData_Param>>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.redirection_experiment_context
   */
  readonly redirection_experiment_context?: Maybe<IExperiment_Out_ExperimentContext>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.referrer
   */
  readonly referrer?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.request_time
   */
  readonly request_time?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.response_code
   */
  readonly response_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.response_content_type
   */
  readonly response_content_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.response_latency_msec
   */
  readonly response_latency_msec?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.session_key
   */
  readonly session_key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.timing_event
   */
  readonly timing_event?: Maybe<IAnalytics_PageViewData_TimingEvent>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.user_agent
   */
  readonly user_agent?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.variation_experiment_context
   */
  readonly variation_experiment_context?: Maybe<IExperiment_Out_ExperimentContext>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.visitor_id
   */
  readonly visitor_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/page_view_data.proto
 * com.trueaccord.proto.analytics.PageViewData.ContentfulDetails
 */
export interface IAnalytics_PageViewData_ContentfulDetails {
  readonly __typename?: "analytics_PageViewData_ContentfulDetails";
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.ContentfulDetails.content_type_id
   */
  readonly content_type_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.ContentfulDetails.entry_id
   */
  readonly entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.ContentfulDetails.is_creditor_restricted
   */
  readonly is_creditor_restricted?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/page_view_data.proto
 * com.trueaccord.proto.analytics.PageViewData.Event
 */
export enum IAnalytics_PageViewData_Event {
  Login = "LOGIN",
  Logout = "LOGOUT"
}

/**
 * File: collectr/proto/page_view_data.proto
 * com.trueaccord.proto.analytics.PageViewData.Exception
 */
export interface IAnalytics_PageViewData_Exception {
  readonly __typename?: "analytics_PageViewData_Exception";
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.Exception.class_name
   */
  readonly class_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.Exception.message
   */
  readonly message?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/page_view_data.proto
 * com.trueaccord.proto.analytics.PageViewData.InteractionEvent
 */
export interface IAnalytics_PageViewData_InteractionEvent {
  readonly __typename?: "analytics_PageViewData_InteractionEvent";
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.InteractionEvent.element_id
   */
  readonly element_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.InteractionEvent.element_type
   */
  readonly element_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.InteractionEvent.event_type
   */
  readonly event_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.InteractionEvent.pageview_id
   */
  readonly pageview_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/page_view_data.proto
 * com.trueaccord.proto.analytics.PageViewData.MultipleAccountDetails
 */
export interface IAnalytics_PageViewData_MultipleAccountDetails {
  readonly __typename?: "analytics_PageViewData_MultipleAccountDetails";
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.MultipleAccountDetails.debt_ids
   */
  readonly debt_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/page_view_data.proto
 * com.trueaccord.proto.analytics.PageViewData.Param
 */
export interface IAnalytics_PageViewData_Param {
  readonly __typename?: "analytics_PageViewData_Param";
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.Param.key
   */
  readonly key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.Param.value
   */
  readonly value?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/page_view_data.proto
 * com.trueaccord.proto.analytics.PageViewData.TimingEvent
 */
export interface IAnalytics_PageViewData_TimingEvent {
  readonly __typename?: "analytics_PageViewData_TimingEvent";
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.TimingEvent.dom_interactive_ms
   */
  readonly dom_interactive_ms?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.TimingEvent.page_load_ms
   */
  readonly page_load_ms?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.TimingEvent.pageview_id
   */
  readonly pageview_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/page_view_data.proto
   * com.trueaccord.proto.analytics.PageViewData.TimingEvent.time_to_first_byte_ms
   */
  readonly time_to_first_byte_ms?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/payments.proto
 * com.trueaccord.proto.api.BinNumber
 */
export interface IApi_BinNumber {
  readonly __typename?: "api_BinNumber";
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.bank
   */
  readonly bank?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.brand
   */
  readonly brand?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.country_code
   */
  readonly country_code?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.info
   */
  readonly info?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.level
   */
  readonly level?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.number
   */
  readonly number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.phone
   */
  readonly phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.type
   */
  readonly type?: Maybe<IApi_BinNumber_Type | "%future added value">;
  /**
   * File: collectr/proto/payments.proto
   * com.trueaccord.proto.api.BinNumber.www
   */
  readonly www?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/payments.proto
 * com.trueaccord.proto.api.BinNumber.Type
 */
export enum IApi_BinNumber_Type {
  Credit = "CREDIT",
  Debit = "DEBIT"
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CohortStats
 */
export interface IApi_CohortStats {
  readonly __typename?: "api_CohortStats";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.cohort_info
   */
  readonly cohort_info: ReadonlyArray<Maybe<IApi_CohortStats_CohortInfo>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.committed_pay_days
   */
  readonly committed_pay_days: ReadonlyArray<Maybe<IApi_CohortStats_ByDay>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.debt_days
   */
  readonly debt_days: ReadonlyArray<Maybe<IApi_CohortStats_ByDay>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.pay_days
   */
  readonly pay_days: ReadonlyArray<Maybe<IApi_CohortStats_ByDay>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.revenue_days
   */
  readonly revenue_days: ReadonlyArray<Maybe<IApi_CohortStats_ByDay>>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CohortStats.ByDay
 */
export interface IApi_CohortStats_ByDay {
  readonly __typename?: "api_CohortStats_ByDay";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.ByDay.cohort
   */
  readonly cohort?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.ByDay.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.ByDay.date
   */
  readonly date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.ByDay.volume
   */
  readonly volume?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CohortStats.CohortInfo
 */
export interface IApi_CohortStats_CohortInfo {
  readonly __typename?: "api_CohortStats_CohortInfo";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.CohortInfo.cohort
   */
  readonly cohort?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.CohortInfo.count
   */
  readonly count?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.CohortInfo.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.CohortInfo.status
   */
  readonly status?: Maybe<IApi_SimplifiedStatus | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CohortStats.CohortInfo.volume
   */
  readonly volume?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CreditorCustomerOut
 */
export interface IApi_CreditorCustomerOut {
  readonly __typename?: "api_CreditorCustomerOut";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorCustomerOut.additional_information
   */
  readonly additional_information?: Maybe<IApi_CreditorCustomerOut_AdditionalInformation>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorCustomerOut.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CreditorCustomerOut.AdditionalInformation
 */
export interface IApi_CreditorCustomerOut_AdditionalInformation {
  readonly __typename?: "api_CreditorCustomerOut_AdditionalInformation";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorCustomerOut.AdditionalInformation.fico_reference_number_last4
   */
  readonly fico_reference_number_last4?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CreditorOut
 */
export interface IApi_CreditorOut {
  readonly __typename?: "api_CreditorOut";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.activation_status
   */
  readonly activation_status?: Maybe<ICreditor_ActivationStatus | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.brand
   */
  readonly brand?: Maybe<ICreditor_Brand>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.client_name
   */
  readonly client_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.commission_percent_basis_point
   */
  readonly commission_percent_basis_point?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.company_name
   */
  readonly company_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.creditor_settings
   */
  readonly creditor_settings?: Maybe<IApi_CreditorOut_CreditorSettings>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.environment
   */
  readonly environment?: Maybe<IEnvironment | "%future added value">;
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.industry
   */
  readonly industry?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.original_brand
   */
  readonly original_brand?: Maybe<ICreditor_Brand>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.policy
   */
  readonly policy?: Maybe<IApi_CreditorOut_Policy>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.volume_placed_commission_rate
   */
  readonly volume_placed_commission_rate?: Maybe<ICreditor_VolumePlacedCommissionRate>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CreditorOut.CreditorSettings
 */
export interface IApi_CreditorOut_CreditorSettings {
  readonly __typename?: "api_CreditorOut_CreditorSettings";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.allowed_creditor_letterhead_template_types
   */
  readonly allowed_creditor_letterhead_template_types: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.channels_requiring_opt_in
   */
  readonly channels_requiring_opt_in: ReadonlyArray<
    Maybe<ICommunications_Channel_Channel | "%future added value">
  >;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.comment_types
   */
  readonly comment_types: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.contract_type
   */
  readonly contract_type?: Maybe<ICreditor_CreditorSettings_ContractType | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.creditor_time_zone
   */
  readonly creditor_time_zone?: Maybe<ICreditor_CreditorSettings_CreditorTimeZone | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.creditor_value
   */
  readonly creditor_value?: Maybe<ICreditor_CreditorSettings_CreditorValue | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.customer_call_authentication_settings
   */
  readonly customer_call_authentication_settings?: Maybe<ICustomerCallAuthenticationSettings>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.days_to_wait_until_settlement_offer
   */
  readonly days_to_wait_until_settlement_offer?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.debt_category
   */
  readonly debt_category?: Maybe<ICreditor_CreditorSettings_DebtCategory>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.expected_return_days
   */
  readonly expected_return_days?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.is_debt_buyer
   */
  readonly is_debt_buyer?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.is_first_party_relationship
   */
  readonly is_first_party_relationship?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.is_tribal
   */
  readonly is_tribal?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.lowest_previous_settlement_offered
   */
  readonly lowest_previous_settlement_offered?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.number_of_previous_agencies
   */
  readonly number_of_previous_agencies?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.out_of_statute_methodology
   */
  readonly out_of_statute_methodology?: Maybe<
    ICreditor_CreditorSettings_OutOfStatuteMethodology | "%future added value"
  >;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.reporting_settings
   */
  readonly reporting_settings?: Maybe<ICreditor_CreditorSettings_ReportingSettings>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.required_agent_disclosures
   */
  readonly required_agent_disclosures: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.required_initial_communication_channel
   */
  readonly required_initial_communication_channel?: Maybe<
    ICommunications_Channel_Channel | "%future added value"
  >;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.return_debt_on_dispute
   */
  readonly return_debt_on_dispute?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.servicing_type
   */
  readonly servicing_type?: Maybe<ICreditor_CreditorSettings_ServicingType | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.CreditorSettings.special_call_restrictions
   */
  readonly special_call_restrictions?: Maybe<ICreditor_CreditorSettings_SpecialCallRestrictions>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CreditorOut.Policy
 */
export interface IApi_CreditorOut_Policy {
  readonly __typename?: "api_CreditorOut_Policy";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorOut.Policy.is_ach_enabled
   */
  readonly is_ach_enabled?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CreditorStats
 */
export interface IApi_CreditorStats {
  readonly __typename?: "api_CreditorStats";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.committed_in_payment_plans
   */
  readonly committed_in_payment_plans?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.debts_by_status
   */
  readonly debts_by_status: ReadonlyArray<Maybe<IApi_CreditorStats_DebtStatusInfo>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.estimated_next_payout
   */
  readonly estimated_next_payout?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.estimated_next_payout_date
   */
  readonly estimated_next_payout_date?: Maybe<IDate>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.incoming_events
   */
  readonly incoming_events: ReadonlyArray<Maybe<IApi_CreditorStats_IntervalStats>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.is_fake
   */
  readonly is_fake?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.outgoing_events
   */
  readonly outgoing_events: ReadonlyArray<Maybe<IApi_CreditorStats_IntervalStats>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.payments_summary
   */
  readonly payments_summary: ReadonlyArray<Maybe<IApi_CreditorStats_IntervalStats>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.total_paid_out
   */
  readonly total_paid_out?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CreditorStats.DebtStatusInfo
 */
export interface IApi_CreditorStats_DebtStatusInfo {
  readonly __typename?: "api_CreditorStats_DebtStatusInfo";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.DebtStatusInfo.count
   */
  readonly count?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.DebtStatusInfo.status
   */
  readonly status?: Maybe<IApi_SimplifiedStatus | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.DebtStatusInfo.volume
   */
  readonly volume?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.CreditorStats.IntervalStats
 */
export interface IApi_CreditorStats_IntervalStats {
  readonly __typename?: "api_CreditorStats_IntervalStats";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.IntervalStats.count
   */
  readonly count?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.IntervalStats.end_timestamp
   */
  readonly end_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.IntervalStats.interval_name
   */
  readonly interval_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.IntervalStats.monetary_amount
   */
  readonly monetary_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.IntervalStats.paid_to_creditor
   */
  readonly paid_to_creditor?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.IntervalStats.paid_to_trueaccord
   */
  readonly paid_to_trueaccord?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.CreditorStats.IntervalStats.start_timestamp
   */
  readonly start_timestamp?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.AttorneyInformation
 */
export interface IApi_DebtOut_AttorneyInformation {
  readonly __typename?: "api_DebtOut_AttorneyInformation";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.AttorneyInformation.time_created
   */
  readonly time_created?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.AvailablePaymentMethods
 */
export interface IApi_DebtOut_AvailablePaymentMethods {
  readonly __typename?: "api_DebtOut_AvailablePaymentMethods";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.AvailablePaymentMethods.check
   */
  readonly check?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.AvailablePaymentMethods.credit_card
   */
  readonly credit_card?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.AvailablePaymentMethods.debit_card
   */
  readonly debit_card?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.BalanceDetails
 */
export interface IApi_DebtOut_BalanceDetails {
  readonly __typename?: "api_DebtOut_BalanceDetails";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.BalanceDetails.costs
   */
  readonly costs?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.BalanceDetails.fees
   */
  readonly fees?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.BalanceDetails.interest
   */
  readonly interest?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.BalanceDetails.is_settled_in_full
   */
  readonly is_settled_in_full?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.BalanceDetails.overcharges
   */
  readonly overcharges?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.BalanceDetails.principal
   */
  readonly principal?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation
 */
export interface IApi_DebtOut_CollectionsStageInformation {
  readonly __typename?: "api_DebtOut_CollectionsStageInformation";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.auto_information
   */
  readonly auto_information?: Maybe<IApi_DebtOut_CollectionsStageInformation_AutoInformation>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.current_amount_due
   */
  readonly current_amount_due?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.current_balance
   */
  readonly current_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.cycles_delinquent
   */
  readonly cycles_delinquent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.delinquent_timestamp
   */
  readonly delinquent_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.due_date
   */
  readonly due_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.product_description
   */
  readonly product_description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.product_type
   */
  readonly product_type?: Maybe<IDebt_CollectionsStageInformation_ProductType | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.total_amount_due
   */
  readonly total_amount_due?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.total_delinquent_amount
   */
  readonly total_delinquent_amount?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.AutoInformation
 */
export interface IApi_DebtOut_CollectionsStageInformation_AutoInformation {
  readonly __typename?: "api_DebtOut_CollectionsStageInformation_AutoInformation";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CollectionsStageInformation.AutoInformation.vin
   */
  readonly vin?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.CommunicationsInformation
 */
export interface IApi_DebtOut_CommunicationsInformation {
  readonly __typename?: "api_DebtOut_CommunicationsInformation";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CommunicationsInformation.had_initial_communication
   */
  readonly had_initial_communication?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CommunicationsInformation.initial_communication_debt_log_entry_id
   */
  readonly initial_communication_debt_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.CommunicationsInformation.required_communication_clauses
   */
  readonly required_communication_clauses: ReadonlyArray<
    Maybe<IRequiredCommunicationClause | "%future added value">
  >;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.DSCInformation
 */
export interface IApi_DebtOut_DscInformation {
  readonly __typename?: "api_DebtOut_DSCInformation";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.DSCInformation.time_created
   */
  readonly time_created?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.FicoDetails
 */
export interface IApi_DebtOut_FicoDetails {
  readonly __typename?: "api_DebtOut_FicoDetails";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.FicoDetails.brand_at_conversion
   */
  readonly brand_at_conversion?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.FicoDetails.exit_date
   */
  readonly exit_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.FicoDetails.functional_area
   */
  readonly functional_area?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.FicoDetails.pnet_human_readable_name
   */
  readonly pnet_human_readable_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.FicoDetails.pnet_id
   */
  readonly pnet_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.FicoDetails.recent_historical_transactions
   */
  readonly recent_historical_transactions: ReadonlyArray<Maybe<IHistoricalTransaction>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.FicoDetails.scoreband
   */
  readonly scoreband?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.FicoDetails.specific_creditor_entity_name
   */
  readonly specific_creditor_entity_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.InternalConfiguration
 */
export interface IApi_DebtOut_InternalConfiguration {
  readonly __typename?: "api_DebtOut_InternalConfiguration";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.InternalConfiguration.show_special_dispute_treatment
   */
  readonly show_special_dispute_treatment?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.LocationCreated
 */
export interface IApi_DebtOut_LocationCreated {
  readonly __typename?: "api_DebtOut_LocationCreated";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.LocationCreated.physical
   */
  readonly physical?: Maybe<IPostalAddress>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.OBSOLETE_PortfolioInformation
 */
export interface IApi_DebtOut_Obsolete_PortfolioInformation {
  readonly __typename?: "api_DebtOut_OBSOLETE_PortfolioInformation";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.OBSOLETE_PortfolioInformation.client_service_tier
   */
  readonly client_service_tier?: Maybe<
    ICreditor_Obsolete_Portfolio_Obsolete_ClientServiceTier | "%future added value"
  >;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.OBSOLETE_PortfolioInformation.expected_return_days
   */
  readonly expected_return_days?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.OverridableMaxDiscountPercent
 */
export interface IApi_DebtOut_OverridableMaxDiscountPercent {
  readonly __typename?: "api_DebtOut_OverridableMaxDiscountPercent";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.OverridableMaxDiscountPercent.max_discount_percent
   */
  readonly max_discount_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.OverridableMaxDiscountPercent.min_settlement_percent
   */
  readonly min_settlement_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.OverridableMaxDiscountPercent.override_type
   */
  readonly override_type?: Maybe<IOverridableValue_OverrideType | "%future added value">;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.PendingStatus
 */
export enum IApi_DebtOut_PendingStatus {
  NoChange = "NO_CHANGE",
  PaidInFull = "PAID_IN_FULL",
  SettledInFull = "SETTLED_IN_FULL"
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.PlacementType
 */
export enum IApi_DebtOut_PlacementType {
  Original = "ORIGINAL",
  Purchased = "PURCHASED"
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.PresentationConfiguration
 */
export interface IApi_DebtOut_PresentationConfiguration {
  readonly __typename?: "api_DebtOut_PresentationConfiguration";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.brand_disclaimer
   */
  readonly brand_disclaimer?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.payment_options
   */
  readonly payment_options?: Maybe<IApi_DebtOut_PresentationConfiguration_PaymentOptions>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.show_charge_off_information
   */
  readonly show_charge_off_information?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.show_pif
   */
  readonly show_pif?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.PaymentOptions
 */
export interface IApi_DebtOut_PresentationConfiguration_PaymentOptions {
  readonly __typename?: "api_DebtOut_PresentationConfiguration_PaymentOptions";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.PaymentOptions.hide_ach
   */
  readonly hide_ach?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.PaymentOptions.hide_cards_for_plans
   */
  readonly hide_cards_for_plans?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.PaymentOptions.hide_credit_card
   */
  readonly hide_credit_card?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.PaymentOptions.hide_debit_card
   */
  readonly hide_debit_card?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.PresentationConfiguration.PaymentOptions.prefer_ach
   */
  readonly prefer_ach?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.RecentTransactionInfo
 */
export interface IApi_DebtOut_RecentTransactionInfo {
  readonly __typename?: "api_DebtOut_RecentTransactionInfo";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.RecentTransactionInfo.last_charge_amount
   */
  readonly last_charge_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.RecentTransactionInfo.last_charge_date
   */
  readonly last_charge_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.RecentTransactionInfo.last_payment_amount
   */
  readonly last_payment_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.DebtOut.RecentTransactionInfo.last_payment_date
   */
  readonly last_payment_date?: Maybe<IDate>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.SpecialUiTreatmentCategory
 */
export enum IApi_DebtOut_SpecialUiTreatmentCategory {
  Afterpay = "AFTERPAY",
  Amex = "AMEX",
  Cavalry = "CAVALRY",
  Cof = "COF",
  Encore = "ENCORE",
  Merrick = "MERRICK",
  None = "NONE",
  Onemain = "ONEMAIN",
  Resurgent = "RESURGENT"
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.DebtOut.StatusChangeEnumReason
 */
export enum IApi_DebtOut_StatusChangeEnumReason {
  Exhausted = "EXHAUSTED",
  FraudOrIdentityTheft = "FRAUD_OR_IDENTITY_THEFT",
  IncorrectPlacement = "INCORRECT_PLACEMENT",
  LowBalance = "LOW_BALANCE",
  Other = "OTHER",
  OutOfStatute = "OUT_OF_STATUTE",
  PermanentHardship = "PERMANENT_HARDSHIP"
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PaymentMethodInfoOut
 */
export interface IApi_PaymentMethodInfoOut {
  readonly __typename?: "api_PaymentMethodInfoOut";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentMethodInfoOut.ach_info
   */
  readonly ach_info?: Maybe<IAchPaymentMethodInfo>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentMethodInfoOut.card_info
   */
  readonly card_info?: Maybe<ICardPaymentMethodInfo>;
}

/**
 * File: collectr/proto/api_payment_offers.proto
 * com.trueaccord.proto.api.PaymentOffersOut
 */
export interface IApi_PaymentOffersOut {
  readonly __typename?: "api_PaymentOffersOut";
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentOffersOut.available_agent_settlement_offer
   */
  readonly available_agent_settlement_offer?: Maybe<IApi_SettlementOfferOut>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentOffersOut.available_plans
   */
  readonly available_plans: ReadonlyArray<Maybe<IApi_PaymentPlanOfferOut>>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentOffersOut.available_settlement_offer
   */
  readonly available_settlement_offer?: Maybe<IApi_SettlementOfferOut>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentOffersOut.custom_plans_max_installments
   */
  readonly custom_plans_max_installments?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentOffersOut.delayed_one_time_payment
   */
  readonly delayed_one_time_payment?: Maybe<IApi_PaymentPlanOfferOut>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentOffersOut.disallow_custom_plans
   */
  readonly disallow_custom_plans?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentOffersOut.highlighted_plan
   */
  readonly highlighted_plan?: Maybe<IApi_PaymentPlanOfferOut>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentOffersOut.settlement_constraints
   */
  readonly settlement_constraints?: Maybe<IApi_SettlementConstraints>;
}

/**
 * File: collectr/proto/api_payment_offers.proto
 * com.trueaccord.proto.api.PaymentPlanOfferOut
 */
export interface IApi_PaymentPlanOfferOut {
  readonly __typename?: "api_PaymentPlanOfferOut";
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.amount_of_balance
   */
  readonly amount_of_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.application
   */
  readonly application?: Maybe<IPaymentPlanApplication>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.discount_percent
   */
  readonly discount_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.installment_amount
   */
  readonly installment_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.is_sif
   */
  readonly is_sif?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.number_of_installments
   */
  readonly number_of_installments?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.percent_of_current_balance
   */
  readonly percent_of_current_balance?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.savings_amount
   */
  readonly savings_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.settlement_percent
   */
  readonly settlement_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.PaymentPlanOfferOut.total_to_pay
   */
  readonly total_to_pay?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PaymentPlanOut.Installment
 */
export interface IApi_PaymentPlanOut_Installment {
  readonly __typename?: "api_PaymentPlanOut_Installment";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.amount_due
   */
  readonly amount_due?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.date_paid
   */
  readonly date_paid?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.due_date
   */
  readonly due_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.installment_amount
   */
  readonly installment_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.is_entered_by_agent
   */
  readonly is_entered_by_agent?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.is_ivr_payment
   */
  readonly is_ivr_payment?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.is_paid
   */
  readonly is_paid?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.payment_method
   */
  readonly payment_method?: Maybe<IApi_PaymentPlanOut_Installment_PaymentMethod | "%future added value">;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PaymentPlanOut.Installment.status
   */
  readonly status?: Maybe<IApi_PaymentPlanOut_Installment_Status | "%future added value">;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PaymentPlanOut.Installment.PaymentMethod
 */
export enum IApi_PaymentPlanOut_Installment_PaymentMethod {
  Ach = "ACH",
  Card = "CARD",
  Check = "CHECK",
  Direct2Creditor = "DIRECT2CREDITOR",
  Other = "OTHER"
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PaymentPlanOut.Installment.Status
 */
export enum IApi_PaymentPlanOut_Installment_Status {
  PaidLate = "PAID_LATE",
  PaidOnTime = "PAID_ON_TIME",
  Unpaid = "UNPAID",
  UnpaidLate = "UNPAID_LATE",
  UnpaidOverDue = "UNPAID_OVER_DUE"
}

/**
 * File: collectr/proto/payments.proto
 * com.trueaccord.proto.api.PaymentTransactionType
 */
export enum IApi_PaymentTransactionType {
  Ach = "ACH",
  AchReturn = "ACH_RETURN",
  Authorization = "AUTHORIZATION",
  BalanceAdjustment = "BALANCE_ADJUSTMENT",
  Card = "CARD",
  Check = "CHECK",
  CrowdFlowerReward = "CROWD_FLOWER_REWARD",
  DirectPaymentToCreditor = "DIRECT_PAYMENT_TO_CREDITOR",
  Other = "OTHER",
  Paypal = "PAYPAL",
  PromotionalCredit = "PROMOTIONAL_CREDIT",
  Refund = "REFUND",
  SuperRewardsCredit = "SUPER_REWARDS_CREDIT",
  WireTransfer = "WIRE_TRANSFER",
  WireTransferReturn = "WIRE_TRANSFER_RETURN"
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PersonOut.AdditionalInformation
 */
export interface IApi_PersonOut_AdditionalInformation {
  readonly __typename?: "api_PersonOut_AdditionalInformation";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.AdditionalInformation.references
   */
  readonly references: ReadonlyArray<Maybe<IApi_PersonOut_AdditionalInformation_CreditorReference>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.AdditionalInformation.timeZone
   */
  readonly timeZone?: Maybe<IApi_PersonOut_AdditionalInformation_TimeZone>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.AdditionalInformation.user_account
   */
  readonly user_account?: Maybe<IAuth_UserAccount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PersonOut.AdditionalInformation.CreditorReference
 */
export interface IApi_PersonOut_AdditionalInformation_CreditorReference {
  readonly __typename?: "api_PersonOut_AdditionalInformation_CreditorReference";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.AdditionalInformation.CreditorReference.alternate_references
   */
  readonly alternate_references: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.AdditionalInformation.CreditorReference.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.AdditionalInformation.CreditorReference.reference
   */
  readonly reference?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.PersonOut.AdditionalInformation.TimeZone
 */
export interface IApi_PersonOut_AdditionalInformation_TimeZone {
  readonly __typename?: "api_PersonOut_AdditionalInformation_TimeZone";
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.AdditionalInformation.TimeZone.local_time
   */
  readonly local_time?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api.proto
   * com.trueaccord.proto.api.PersonOut.AdditionalInformation.TimeZone.name
   */
  readonly name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/api_payment_offers.proto
 * com.trueaccord.proto.api.SettlementConstraints
 */
export interface IApi_SettlementConstraints {
  readonly __typename?: "api_SettlementConstraints";
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementConstraints.max
   */
  readonly max?: Maybe<IApi_SettlementConstraints_Constraint>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementConstraints.min
   */
  readonly min?: Maybe<IApi_SettlementConstraints_Constraint>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementConstraints.settlements_available
   */
  readonly settlements_available?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/api_payment_offers.proto
 * com.trueaccord.proto.api.SettlementConstraints.Constraint
 */
export interface IApi_SettlementConstraints_Constraint {
  readonly __typename?: "api_SettlementConstraints_Constraint";
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementConstraints.Constraint.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementConstraints.Constraint.percent
   */
  readonly percent?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/api_payment_offers.proto
 * com.trueaccord.proto.api.SettlementOfferOut
 */
export interface IApi_SettlementOfferOut {
  readonly __typename?: "api_SettlementOfferOut";
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.amount_of_balance
   */
  readonly amount_of_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.application
   */
  readonly application?: Maybe<IApi_SettlementOfferOut_SettlementApplication>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.discount_percent
   */
  readonly discount_percent?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.expiration_date
   */
  readonly expiration_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.percent_of_current_balance
   */
  readonly percent_of_current_balance?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.savings_amount
   */
  readonly savings_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.settlement_percent
   */
  readonly settlement_percent?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/api_payment_offers.proto
 * com.trueaccord.proto.api.SettlementOfferOut.SettlementApplication
 */
export interface IApi_SettlementOfferOut_SettlementApplication {
  readonly __typename?: "api_SettlementOfferOut_SettlementApplication";
  /**
   * File: collectr/proto/api_payment_offers.proto
   * com.trueaccord.proto.api.SettlementOfferOut.SettlementApplication.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/api.proto
 * com.trueaccord.proto.api.SimplifiedStatus
 */
export enum IApi_SimplifiedStatus {
  Closed = "CLOSED",
  InDispute = "IN_DISPUTE",
  InPaymentPlan = "IN_PAYMENT_PLAN",
  InProgress = "IN_PROGRESS",
  ObsoleteDischarged = "OBSOLETE_DISCHARGED",
  PaidInFull = "PAID_IN_FULL",
  PartiallyPaid = "PARTIALLY_PAID",
  Recalled = "RECALLED",
  SettledInFull = "SETTLED_IN_FULL"
}

/**
 * File: collectr/proto/api_special_program.proto
 * com.trueaccord.proto.api.SpecialProgramOut
 */
export interface IApi_SpecialProgramOut {
  readonly __typename?: "api_SpecialProgramOut";
  /**
   * File: collectr/proto/api_special_program.proto
   * com.trueaccord.proto.api.SpecialProgramOut.apollo
   */
  readonly apollo?: Maybe<IDebt_InternalInformation_AmexInformation_Apollo>;
  /**
   * File: collectr/proto/api_special_program.proto
   * com.trueaccord.proto.api.SpecialProgramOut.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api_special_program.proto
   * com.trueaccord.proto.api.SpecialProgramOut.oasis
   */
  readonly oasis?: Maybe<IDebt_InternalInformation_AmexInformation_Oasis>;
  /**
   * File: collectr/proto/api_special_program.proto
   * com.trueaccord.proto.api.SpecialProgramOut.opt_status
   */
  readonly opt_status?: Maybe<IApi_SpecialProgramOut_OptStatus | "%future added value">;
  /**
   * File: collectr/proto/api_special_program.proto
   * com.trueaccord.proto.api.SpecialProgramOut.special_program_name
   */
  readonly special_program_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/api_special_program.proto
   * com.trueaccord.proto.api.SpecialProgramOut.special_program_type
   */
  readonly special_program_type?: Maybe<ISpecialProgram_SpecialProgramType | "%future added value">;
}

/**
 * File: collectr/proto/api_special_program.proto
 * com.trueaccord.proto.api.SpecialProgramOut.OptStatus
 */
export enum IApi_SpecialProgramOut_OptStatus {
  Discussed = "DISCUSSED",
  NotRequired = "NOT_REQUIRED",
  OptedIn = "OPTED_IN",
  OptedOut = "OPTED_OUT",
  Required = "REQUIRED"
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.CreditorCapability
 */
export enum IAuth_CreditorCapability {
  ContentApprover = "CONTENT_APPROVER",
  Read = "READ",
  ViewCommunicationHistory = "VIEW_COMMUNICATION_HISTORY",
  Write = "WRITE"
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.CreditorRole
 */
export interface IAuth_CreditorRole {
  readonly __typename?: "auth_CreditorRole";
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.CreditorRole.capabilities
   */
  readonly capabilities: ReadonlyArray<Maybe<IAuth_CreditorCapability | "%future added value">>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.CreditorRole.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.DeskCredentials
 */
export interface IAuth_DeskCredentials {
  readonly __typename?: "auth_DeskCredentials";
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.DeskCredentials.desk_agent_id
   */
  readonly desk_agent_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.DeskCredentials.desk_instance
   */
  readonly desk_instance?: Maybe<IAuth_DeskCredentials_DeskInstance | "%future added value">;
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.DeskCredentials.DeskInstance
 */
export enum IAuth_DeskCredentials_DeskInstance {
  AllClients = "ALL_CLIENTS",
  CapitalOne = "CAPITAL_ONE",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.FailedLoginAttempt
 */
export interface IAuth_FailedLoginAttempt {
  readonly __typename?: "auth_FailedLoginAttempt";
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.FailedLoginAttempt.ip
   */
  readonly ip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.FailedLoginAttempt.password_used
   */
  readonly password_used?: Maybe<IAuth_HashedKey>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.FailedLoginAttempt.reason
   */
  readonly reason?: Maybe<IAuth_FailedLoginAttempt_Reason | "%future added value">;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.FailedLoginAttempt.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.FailedLoginAttempt.Reason
 */
export enum IAuth_FailedLoginAttempt_Reason {
  AccountHasNoPassword = "ACCOUNT_HAS_NO_PASSWORD",
  AccountTemporarilyLocked = "ACCOUNT_TEMPORARILY_LOCKED",
  InvalidPassword = "INVALID_PASSWORD"
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.HashedKey
 */
export interface IAuth_HashedKey {
  readonly __typename?: "auth_HashedKey";
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.HashedKey.algorithm
   */
  readonly algorithm?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.HashedKey.hashed
   */
  readonly hashed?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.HashedKey.is_disabled
   */
  readonly is_disabled?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.HashedKey.iterations
   */
  readonly iterations?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.HashedKey.salt
   */
  readonly salt?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.RoleSettings
 */
export interface IAuth_RoleSettings {
  readonly __typename?: "auth_RoleSettings";
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.RoleSettings.allowed_ips
   */
  readonly allowed_ips: ReadonlyArray<Maybe<Scalars["String"]>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.RoleSettings.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.UserAccount
 */
export interface IAuth_UserAccount {
  readonly __typename?: "auth_UserAccount";
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.agent_information
   */
  readonly agent_information?: Maybe<IAuth_UserAccount_AgentInformation>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.creditor_roles
   */
  readonly creditor_roles: ReadonlyArray<Maybe<IAuth_CreditorRole>>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.desk_credentials
   */
  readonly desk_credentials: ReadonlyArray<Maybe<IAuth_DeskCredentials>>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.failed_logins
   */
  readonly failed_logins: ReadonlyArray<Maybe<IAuth_FailedLoginAttempt>>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.identities
   */
  readonly identities: ReadonlyArray<Maybe<IAuth_UserIdentity>>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.is_admin
   */
  readonly is_admin?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.last_login_timestamp
   */
  readonly last_login_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.last_tokenization_allow_request
   */
  readonly last_tokenization_allow_request?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.role_ids
   */
  readonly role_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.UserAccount.AgentInformation
 */
export interface IAuth_UserAccount_AgentInformation {
  readonly __typename?: "auth_UserAccount_AgentInformation";
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserAccount.AgentInformation.agent_id
   */
  readonly agent_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.UserIdentity
 */
export interface IAuth_UserIdentity {
  readonly __typename?: "auth_UserIdentity";
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.full_name
   */
  readonly full_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.gender
   */
  readonly gender?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.last_modified_password
   */
  readonly last_modified_password?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.link
   */
  readonly link?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.locale
   */
  readonly locale?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.password
   */
  readonly password?: Maybe<IAuth_HashedKey>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.picture_url
   */
  readonly picture_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.provider_id
   */
  readonly provider_id?: Maybe<IAuth_UserIdentity_IdentityProviderId | "%future added value">;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.timezone_offset
   */
  readonly timezone_offset?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserIdentity.user_id
   */
  readonly user_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.UserIdentity.IdentityProviderId
 */
export enum IAuth_UserIdentity_IdentityProviderId {
  Facebook = "FACEBOOK",
  Google = "GOOGLE",
  Password = "PASSWORD",
  Quickbooks = "QUICKBOOKS"
}

/**
 * File: collectr/proto/auth.proto
 * com.trueaccord.proto.auth.UserSession
 */
export interface IAuth_UserSession {
  readonly __typename?: "auth_UserSession";
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserSession.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/auth.proto
   * com.trueaccord.proto.auth.UserSession.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/bulk_operations.proto
 * com.trueaccord.proto.bulk_operations.BulkOperationType
 */
export enum IBulk_Operations_BulkOperationType {
  ContentActivityStatus = "CONTENT_ACTIVITY_STATUS",
  DebtorUpdate = "DEBTOR_UPDATE",
  RenderContent = "RENDER_CONTENT",
  ResurgentUpdateEmailInPlx = "RESURGENT_UPDATE_EMAIL_IN_PLX",
  SmsSubscriber = "SMS_SUBSCRIBER",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/bulk_update.proto
 * com.trueaccord.proto.bulk_update.BulkCommand
 */
export interface IBulk_Update_BulkCommand {
  readonly __typename?: "bulk_update_BulkCommand";
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.addDebtsAndPause
   */
  readonly addDebtsAndPause?: Maybe<IBulk_Update_BulkCommand_AddDebtsAndPauseCommand>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.note
   */
  readonly note?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.partialUnpause
   */
  readonly partialUnpause?: Maybe<IBulk_Update_BulkCommand_PartialUnpauseCommand>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.pause
   */
  readonly pause?: Maybe<IBulk_Update_BulkCommand_PauseCommand>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.unpause
   */
  readonly unpause?: Maybe<Scalars["bulk_update_BulkCommand_UnpauseCommand"]>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/bulk_update.proto
 * com.trueaccord.proto.bulk_update.BulkCommand.AddDebtsAndPauseCommand
 */
export interface IBulk_Update_BulkCommand_AddDebtsAndPauseCommand {
  readonly __typename?: "bulk_update_BulkCommand_AddDebtsAndPauseCommand";
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.AddDebtsAndPauseCommand.debt_ids
   */
  readonly debt_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.AddDebtsAndPauseCommand.pause_length_in_days
   */
  readonly pause_length_in_days?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.AddDebtsAndPauseCommand.reason
   */
  readonly reason?: Maybe<ICollectionPaused_PauseReason | "%future added value">;
}

/**
 * File: collectr/proto/bulk_update.proto
 * com.trueaccord.proto.bulk_update.BulkCommand.PartialUnpauseCommand
 */
export interface IBulk_Update_BulkCommand_PartialUnpauseCommand {
  readonly __typename?: "bulk_update_BulkCommand_PartialUnpauseCommand";
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.PartialUnpauseCommand.debt_ids
   */
  readonly debt_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/bulk_update.proto
 * com.trueaccord.proto.bulk_update.BulkCommand.PauseCommand
 */
export interface IBulk_Update_BulkCommand_PauseCommand {
  readonly __typename?: "bulk_update_BulkCommand_PauseCommand";
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.PauseCommand.pause_length_in_days
   */
  readonly pause_length_in_days?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkCommand.PauseCommand.reason
   */
  readonly reason?: Maybe<ICollectionPaused_PauseReason | "%future added value">;
}

/**
 * File: collectr/proto/bulk_update.proto
 * com.trueaccord.proto.bulk_update.BulkEntry
 */
export interface IBulk_Update_BulkEntry {
  readonly __typename?: "bulk_update_BulkEntry";
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkEntry.applied_commands
   */
  readonly applied_commands: ReadonlyArray<Maybe<IBulk_Update_BulkCommand>>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkEntry.attempted_commands
   */
  readonly attempted_commands: ReadonlyArray<Maybe<IBulk_Update_BulkCommand>>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkEntry.debt_ids
   */
  readonly debt_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkEntry.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/bulk_update.proto
   * com.trueaccord.proto.bulk_update.BulkEntry.meta
   */
  readonly meta?: Maybe<IMetaData>;
}

export interface ICamunda_ActivityInstance {
  readonly __typename?: "camunda_ActivityInstance";
  /** The id of the activity. */
  readonly activityId: Scalars["ID"];
  /** The name of the activity. */
  readonly activityName?: Maybe<Scalars["String"]>;
  /** The type of activity (corresponds to the XML element name in the BPMN 2.0, e.g., 'userTask'). */
  readonly activityType?: Maybe<Scalars["String"]>;
  /** A list of child activity instances. */
  readonly childActivityInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_ActivityInstance>>>;
  /** A list of child transition instances. A transition instance represents an execution waiting in an asynchronous continuation. */
  readonly childTransitionInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_TransitionInstance>>>;
  /** A list of execution ids. */
  readonly executionIds?: Maybe<ReadonlyArray<Scalars["ID"]>>;
  readonly executions?: Maybe<ReadonlyArray<Maybe<ICamunda_Execution>>>;
  /** The id of the activity instance. */
  readonly id: Scalars["ID"];
  /** A list of incident ids. */
  readonly incidentIds?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  /** A list of JSON objects containing incident specific properties */
  readonly incidents?: Maybe<ReadonlyArray<Maybe<ICamunda_Incident>>>;
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition. */
  readonly processDefinitionId: Scalars["ID"];
  readonly processInstance?: Maybe<ICamunda_ProcessInstance>;
  /** The id of the process instance this activity instance is part of. */
  readonly processInstanceId: Scalars["ID"];
}

/** Definition of a authorization resource */
export interface ICamunda_Authorization {
  readonly __typename?: "camunda_Authorization";
  readonly group?: Maybe<ICamunda_Group>;
  /** The id of the group this authorization has been created for. */
  readonly groupId?: Maybe<Scalars["ID"]>;
  /** The id of the authorization. */
  readonly id: Scalars["ID"];
  /** An array of strings representing the permissions assigned by this authentication. */
  readonly permissions?: Maybe<ReadonlyArray<Maybe<Scalars["String"]>>>;
  /**
   * The removal time indicates the date a historic instance authorization is cleaned up.
   * A removal time can only be assigned to a historic instance authorization.
   * Can be null when not related to a historic instance resource or when the removal time strategy
   * is end and the root process instance is not finished.
   * Default format yyyy-MM-dd'T'HH:mm:ss.SSSZ.
   */
  readonly removalTime?: Maybe<Scalars["String"]>;
  /** The resource Id. The value '*' represents an authorization ranging over all instances of a resource. */
  readonly resourceId?: Maybe<Scalars["String"]>;
  /** A string representing the resource type. */
  readonly resourceType?: Maybe<Scalars["String"]>;
  readonly rootProcessInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  /** The process instance id of the root process instance the historic instance authorization is related to. Can be null if not related to a historic instance resource. */
  readonly rootProcessInstanceId?: Maybe<Scalars["ID"]>;
  /** The type of the authorization. */
  readonly type?: Maybe<Scalars["String"]>;
  readonly user?: Maybe<ICamunda_User>;
  /** The id of the user this authorization has been created for. The value '*' represents a global authorization ranging over all users. */
  readonly userId?: Maybe<Scalars["ID"]>;
}

export interface ICamunda_Comment {
  readonly __typename?: "camunda_Comment";
  /** The id of the task comment. */
  readonly id: Scalars["ID"];
  /** The content of the comment. */
  readonly message?: Maybe<Scalars["String"]>;
  /** The time after which the comment should be removed by the History Cleanup job. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly removalTime?: Maybe<Scalars["String"]>;
  readonly rootProcessInstance?: Maybe<ICamunda_ProcessInstance>;
  /** The process instance id of the root process instance that initiated the process containing the task. */
  readonly rootProcessInstanceId?: Maybe<Scalars["ID"]>;
  readonly task?: Maybe<ICamunda_Task>;
  /** The id of the task to which the comment belongs. */
  readonly taskId: Scalars["ID"];
  /** The time when the comment was created. */
  readonly time?: Maybe<Scalars["String"]>;
  readonly user?: Maybe<ICamunda_User>;
  /** The id of the user who created the comment. */
  readonly userId: Scalars["ID"];
}

/** Definition of a decision resource */
export interface ICamunda_DecisionDefinition {
  readonly __typename?: "camunda_DecisionDefinition";
  /** The category of the decision definition. */
  readonly category?: Maybe<Scalars["String"]>;
  /** The id of the decision requirements definition this decision definition belongs to. */
  readonly decisionRequirementsDefinitionId?: Maybe<Scalars["ID"]>;
  /** The key of the decision requirements definition this decision definition belongs to. */
  readonly decisionRequirementsDefinitionKey?: Maybe<Scalars["String"]>;
  readonly deployment?: Maybe<ICamunda_Deployment>;
  /** The deployment id of the decision definition. */
  readonly deploymentId: Scalars["ID"];
  /** XML serialization of the decision definition DMN. */
  readonly diagram?: Maybe<Scalars["String"]>;
  /** History time to live value of the decision definition. Is used within History cleanup. */
  readonly historyTimeToLive?: Maybe<Scalars["Int"]>;
  /** The id of the decision definition. */
  readonly id: Scalars["ID"];
  /** The key of the decision definition, i.e., the id of the DMN 1.0 XML decision definition. */
  readonly key?: Maybe<Scalars["String"]>;
  /** The name of the decision definition. */
  readonly name?: Maybe<Scalars["String"]>;
  /** The file name of the decision definition. */
  readonly resource?: Maybe<Scalars["String"]>;
  /** The tenant id of the decision definition. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
  /** The version of the decision definition that the engine assigned to it. */
  readonly version?: Maybe<Scalars["Int"]>;
  /** The version tag of the process definition. */
  readonly versionTag?: Maybe<Scalars["String"]>;
}

export interface ICamunda_Deployment {
  readonly __typename?: "camunda_Deployment";
  /** The date and time of the deployment. */
  readonly deploymentTime?: Maybe<Scalars["String"]>;
  /** The id of the deployment. */
  readonly id: Scalars["ID"];
  /** The name of the deployment. */
  readonly name?: Maybe<Scalars["String"]>;
  /** The source of the deployment. */
  readonly source?: Maybe<Scalars["String"]>;
  /** The tenant id of the deployment. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
}

export interface ICamunda_DeploymentResource {
  /** The filename of the resource. */
  readonly name: Scalars["String"];
  /** String or XML serialization of the resource. */
  readonly payload: Scalars["String"];
}

export interface ICamunda_Execution {
  readonly __typename?: "camunda_Execution";
  /** A flag indicating whether the execution has ended or not. */
  readonly ended?: Maybe<Scalars["Boolean"]>;
  /** The id of the execution. */
  readonly id: Scalars["ID"];
  readonly processInstance?: Maybe<ICamunda_ProcessInstance>;
  /** The id of the process instance that this execution instance belongs to. */
  readonly processInstanceId: Scalars["ID"];
  /** A flag indicating whether the exeuction is suspended or not. */
  readonly suspended?: Maybe<Scalars["Boolean"]>;
  /** The tenant id of the execution. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
}

export interface ICamunda_FormField {
  readonly __typename?: "camunda_FormField";
  readonly id: Scalars["ID"];
  /** value: camunda_TypedValue */
  readonly isBusinessKey: Scalars["Boolean"];
  readonly label?: Maybe<Scalars["String"]>;
  readonly properties?: Maybe<ReadonlyArray<Maybe<ICamunda_KeyValuePair>>>;
  readonly type: ICamunda_FormFieldType;
  readonly typeName: Scalars["String"];
  readonly validationConstraints?: Maybe<ReadonlyArray<Maybe<ICamunda_FormFieldValidationConstraint>>>;
}

export interface ICamunda_FormFieldType {
  readonly __typename?: "camunda_FormFieldType";
  readonly name: Scalars["String"];
}

export interface ICamunda_FormFieldValidationConstraint {
  readonly __typename?: "camunda_FormFieldValidationConstraint";
  readonly name: Scalars["String"];
}

export interface ICamunda_Group {
  readonly __typename?: "camunda_Group";
  readonly id: Scalars["ID"];
  readonly members?: Maybe<ReadonlyArray<Maybe<ICamunda_User>>>;
  readonly name?: Maybe<Scalars["String"]>;
  readonly type?: Maybe<Scalars["String"]>;
}

export interface ICamunda_HistoricActivityInstance {
  readonly __typename?: "camunda_HistoricActivityInstance";
  /** The id of the activity that this object is an instance of. */
  readonly activityId?: Maybe<Scalars["ID"]>;
  /** The name of the activity that this object is an instance of. */
  readonly activityName?: Maybe<Scalars["String"]>;
  /** The type of the activity that this object is an instance of. */
  readonly activityType?: Maybe<Scalars["String"]>;
  readonly assignee?: Maybe<ICamunda_User>;
  /** The assignee of the task that is associated to this activity instance. Is only set if the activity is a user task. */
  readonly assigneeId?: Maybe<Scalars["ID"]>;
  /** The id of the called case instance. Is only set if the activity is a call activity and the called instance a case instance. */
  readonly calledCaseInstanceId?: Maybe<Scalars["ID"]>;
  readonly calledProcessInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  /** The id of the called process instance. Is only set if the activity is a call activity and the called instance a process instance. */
  readonly calledProcessInstanceId?: Maybe<Scalars["ID"]>;
  /** If true, this activity instance is canceled. */
  readonly canceled?: Maybe<Scalars["Boolean"]>;
  /** If true, this activity instance did complete a BPMN 2.0 scope. */
  readonly completeScope?: Maybe<Scalars["Boolean"]>;
  /** The time the instance took to finish (in milliseconds). */
  readonly durationInMillis?: Maybe<Scalars["Int"]>;
  /** The time the instance ended. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly endTime?: Maybe<Scalars["String"]>;
  readonly execution?: Maybe<ICamunda_Execution>;
  /** The id of the execution that executed this activity instance. */
  readonly executionId?: Maybe<Scalars["ID"]>;
  /** The id of the activity instance. */
  readonly id: Scalars["ID"];
  readonly parentActivityInstance?: Maybe<ICamunda_HistoricActivityInstance>;
  /** The id of the parent activity instance, for example a sub process instance. */
  readonly parentActivityInstanceId?: Maybe<Scalars["ID"]>;
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition that this activity instance belongs to. */
  readonly processDefinitionId?: Maybe<Scalars["ID"]>;
  /** The key of the process definition that this activity instance belongs to. */
  readonly processDefinitionKey?: Maybe<Scalars["String"]>;
  readonly processInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  /** The id of the process instance that this activity instance belongs to. */
  readonly processInstanceId?: Maybe<Scalars["ID"]>;
  /** The time after which the activity instance should be removed by the History Cleanup job. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly removalTime?: Maybe<Scalars["String"]>;
  readonly rootProcessInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  /** The process instance id of the root process instance that initiated the process containing this activity instance. */
  readonly rootProcessInstanceId?: Maybe<Scalars["ID"]>;
  /** The time the instance was started. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly startTime?: Maybe<Scalars["String"]>;
  readonly task?: Maybe<ICamunda_HistoricTask>;
  /** The id of the task that is associated to this activity instance. Is only set if the activity is a user task. */
  readonly taskId?: Maybe<Scalars["ID"]>;
  /** The tenant id of the activity instance. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
  readonly variables?: Maybe<ReadonlyArray<Maybe<ICamunda_KeyValuePair>>>;
}

/** Definition of a historic process instance resource */
export interface ICamunda_HistoricProcessInstance {
  readonly __typename?: "camunda_HistoricProcessInstance";
  readonly activityInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_HistoricActivityInstance>>>;
  /** The business key of the process instance. */
  readonly businessKey?: Maybe<Scalars["String"]>;
  /** The id of the parent case instance, if it exists. */
  readonly caseInstanceId?: Maybe<Scalars["ID"]>;
  /** The provided delete reason in case the process instance was canceled during execution. */
  readonly deleteReason?: Maybe<Scalars["String"]>;
  readonly domProcessInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_HistoricProcessInstance>>>;
  /** The time the instance took to finish (in milliseconds). */
  readonly durationInMillis?: Maybe<Scalars["Int"]>;
  /** The time the instance ended. Default format* yyyy-MM-dd’T’HH:mm:ss.SSSZ. */
  readonly endTime?: Maybe<Scalars["String"]>;
  /** The id of the process instance. */
  readonly id: Scalars["ID"];
  readonly incidents?: Maybe<ReadonlyArray<Maybe<ICamunda_Incident>>>;
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition that this process instance belongs to. */
  readonly processDefinitionId: Scalars["ID"];
  /** The key of the process definition that this process instance belongs to. */
  readonly processDefinitionKey?: Maybe<Scalars["String"]>;
  /** The name of the process definition that this process instance belongs to. */
  readonly processDefinitionName?: Maybe<Scalars["String"]>;
  /** The version of the process definition that this process instance belongs to. */
  readonly processDefinitionVersion?: Maybe<Scalars["Int"]>;
  /** The time after which the instance should be removed by the History Cleanup job. Default format* yyyy-MM-dd’T’HH:mm:ss.SSSZ. */
  readonly removalTime?: Maybe<Scalars["String"]>;
  readonly rootProcessInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  /** The process instance id of the root process instance that initiated the process. */
  readonly rootProcessInstanceId?: Maybe<Scalars["ID"]>;
  /** The id of the initial activity that was executed (e.g., a start event). */
  readonly startActivityId?: Maybe<Scalars["ID"]>;
  /** The time the instance was started. Default format* yyyy-MM-dd’T’HH:mm:ss.SSSZ. */
  readonly startTime?: Maybe<Scalars["String"]>;
  readonly startUser?: Maybe<ICamunda_User>;
  /** The id of the user who started the process instance. */
  readonly startUserId?: Maybe<Scalars["ID"]>;
  /** last state of the process instance: ACTIVE, SUSPENDED, COMPLETED, EXTERNALLY_TERMINATED or INTERNALLY_TERMINATED. */
  readonly state?: Maybe<Scalars["String"]>;
  readonly subProcessInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_HistoricProcessInstance>>>;
  /** The id of the parent case instance, if it exists. */
  readonly superCaseInstanceId?: Maybe<Scalars["ID"]>;
  /** The id of the parent process instance, if it exists. */
  readonly superProcessInstanceId?: Maybe<Scalars["ID"]>;
  /** The tenant id of the process instance. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
  readonly topProcessInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  readonly variables?: Maybe<ReadonlyArray<Maybe<ICamunda_KeyValuePair>>>;
}

export interface ICamunda_HistoricTask {
  readonly __typename?: "camunda_HistoricTask";
  /** The id of the activity that this object is an instance of. */
  readonly activityInstanceId: Scalars["ID"];
  readonly assignee?: Maybe<ICamunda_User>;
  /** The assignee's id. */
  readonly assigneeId?: Maybe<Scalars["String"]>;
  /** The id of the case definition the task belongs to. */
  readonly caseDefinitionId?: Maybe<Scalars["ID"]>;
  /** The key of the case definition the task belongs to. */
  readonly caseDefinitionKey?: Maybe<Scalars["String"]>;
  /** The id of the case execution the task belongs to. */
  readonly caseExecutionId?: Maybe<Scalars["ID"]>;
  /** The id of the case instance the task belongs to. */
  readonly caseInstanceId?: Maybe<Scalars["ID"]>;
  /** The task's delete reason. */
  readonly deleteReason?: Maybe<Scalars["String"]>;
  /** The task's description. */
  readonly description?: Maybe<Scalars["String"]>;
  /** The task's due date. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly due?: Maybe<Scalars["String"]>;
  /** The time the task took to finish (in milliseconds). */
  readonly duration?: Maybe<Scalars["Int"]>;
  /** The time the task ended. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly endTime?: Maybe<Scalars["String"]>;
  /** The id of the execution the task belongs to. */
  readonly executionId: Scalars["ID"];
  /** The follow-up date for the task. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly followUp?: Maybe<Scalars["String"]>;
  /** The task id. */
  readonly id: Scalars["ID"];
  /** The task name. */
  readonly name?: Maybe<Scalars["String"]>;
  readonly owner?: Maybe<ICamunda_User>;
  /** The owner's id. */
  readonly ownerId?: Maybe<Scalars["ID"]>;
  /** The id of the parent task, if this task is a subtask. */
  readonly parentTaskId?: Maybe<Scalars["ID"]>;
  /** The task's priority. */
  readonly priority?: Maybe<Scalars["Int"]>;
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition the task belongs to. */
  readonly processDefinitionId: Scalars["ID"];
  /** The key of the process definition the task belongs to. */
  readonly processDefinitionKey?: Maybe<Scalars["String"]>;
  readonly processInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  /** The id of the process instance the task belongs to. */
  readonly processInstanceId: Scalars["ID"];
  /** The time after which the task should be removed by the History Cleanup job. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly removalTime?: Maybe<Scalars["String"]>;
  readonly rootProcessInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  /** The process instance id of the root process instance that initiated the process containing this task. */
  readonly rootProcessInstanceId?: Maybe<Scalars["ID"]>;
  /** The time the task was started. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly startTime?: Maybe<Scalars["String"]>;
  /** The task's key. */
  readonly taskDefinitionKey?: Maybe<Scalars["String"]>;
  /** The tenant id of the task instance. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
  readonly variables?: Maybe<ReadonlyArray<Maybe<ICamunda_KeyValuePair>>>;
}

export interface ICamunda_Incident {
  readonly __typename?: "camunda_Incident";
  /** The id of the activity this incident is associated with. */
  readonly activityId: Scalars["ID"];
  /** The annotation set to the incident. */
  readonly annotation?: Maybe<Scalars["String"]>;
  readonly causeIncident?: Maybe<ICamunda_Incident>;
  /** The id of the associated cause incident which has been triggered. */
  readonly causeIncidentId?: Maybe<Scalars["ID"]>;
  /** The payload of this incident. */
  readonly configuration?: Maybe<Scalars["String"]>;
  readonly execution?: Maybe<ICamunda_Execution>;
  /** The id of the execution this incident is associated with. */
  readonly executionId?: Maybe<Scalars["String"]>;
  /** The id of the activity on which the last exception occurred. */
  readonly failedActivityId: Scalars["ID"];
  /** The id of the incident. */
  readonly id: Scalars["ID"];
  /** The message of this incident. */
  readonly incidentMessage?: Maybe<Scalars["String"]>;
  /** The time this incident happened. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly incidentTimestamp?: Maybe<Scalars["String"]>;
  /** The type of incident, for example: failedJobs will be returned in case of an incident which identified a failed job during the execution of a process instance. See the User Guide for a list of incident types. */
  readonly incidentType?: Maybe<Scalars["String"]>;
  readonly jobDefinition?: Maybe<ICamunda_JobDefinition>;
  /** The job definition id the incident is associated with. */
  readonly jobDefinitionId: Scalars["ID"];
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition this incident is associated with. */
  readonly processDefinitionId: Scalars["ID"];
  readonly processInstance?: Maybe<ICamunda_ProcessInstance>;
  /** The id of the process instance this incident is associated with. */
  readonly processInstanceId: Scalars["ID"];
  readonly rootCauseIncident?: Maybe<ICamunda_Incident>;
  /** The id of the associated root cause incident which has been triggered. */
  readonly rootCauseIncidentId?: Maybe<Scalars["ID"]>;
  /** The id of the tenant this incident is associated with. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
}

export interface ICamunda_Job {
  readonly __typename?: "camunda_Job";
  /** The date on which this job has been created. */
  readonly createTime?: Maybe<Scalars["String"]>;
  /** The date on which this job is supposed to be processed. */
  readonly dueDate?: Maybe<Scalars["String"]>;
  /** The message of the exception that occurred, the last time the job was executed. Is null when no exception occurred. */
  readonly exceptionMessage?: Maybe<Scalars["String"]>;
  readonly execution?: Maybe<ICamunda_Execution>;
  /** The specific execution id on which the job was created. */
  readonly executionId: Scalars["ID"];
  /** The id of the activity on which the last exception occurred, the last time the job was executed. Is null when no exception occurred. */
  readonly failedActivityId?: Maybe<Scalars["String"]>;
  /** The id of the job. */
  readonly id: Scalars["ID"];
  readonly jobDefinition?: Maybe<ICamunda_JobDefinition>;
  /** The id of the associated job definition. */
  readonly jobDefinitionId: Scalars["ID"];
  /** The job's priority for execution. */
  readonly priority?: Maybe<Scalars["Int"]>;
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition which this job belongs to. */
  readonly processDefinitionId: Scalars["ID"];
  /** The key of the process definition which this job belongs to. */
  readonly processDefinitionKey?: Maybe<Scalars["String"]>;
  readonly processInstance?: Maybe<ICamunda_ProcessInstance>;
  /** The id of the process instance which execution created the job. */
  readonly processInstanceId: Scalars["ID"];
  /** The number of retries this job has left. */
  readonly retries?: Maybe<Scalars["Int"]>;
  /** A flag indicating whether the job is suspended or not. */
  readonly suspended?: Maybe<Scalars["Boolean"]>;
  /** The id of the tenant which this job belongs to. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
}

export interface ICamunda_JobDefinition {
  readonly __typename?: "camunda_JobDefinition";
  /** The id of the activity (in BPMN 2.0 XML) this job definition is associated with. */
  readonly activityId?: Maybe<Scalars["ID"]>;
  readonly deployment?: Maybe<ICamunda_Deployment>;
  /** The id of the deployment this job definition is related to. In a deployment-aware setup, this leads to all jobs of the same definition being executed on the same node. */
  readonly deploymentId: Scalars["ID"];
  /** The id of the job definition. */
  readonly id: Scalars["ID"];
  /** The configuration of a job definition provides details about the jobs which will be created. For example: for timer jobs it is the timer configuration. */
  readonly jobConfiguration?: Maybe<Scalars["String"]>;
  /** The type of the job which is running for this job definition. See the User Guide for more information about job types. */
  readonly jobType?: Maybe<Scalars["String"]>;
  /** The execution priority defined for jobs that are created based on this definition. May be null when the priority has not been overridden on the job definition level. */
  readonly overridingJobPriority?: Maybe<Scalars["Int"]>;
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition this job definition is associated with. */
  readonly processDefinitionId: Scalars["ID"];
  /** The key of the process definition this job definition is associated with. */
  readonly processDefinitionKey?: Maybe<Scalars["String"]>;
  /** Indicates whether this job definition is suspended or not. */
  readonly suspended?: Maybe<Scalars["Boolean"]>;
  /** The id of the tenant this job definition is associated with. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
}

export interface ICamunda_KeyValuePair {
  readonly __typename?: "camunda_KeyValuePair";
  readonly key: Scalars["String"];
  readonly value: Scalars["String"];
  readonly valueType?: Maybe<ICamunda_ValueTypeEnum | "%future added value">;
}

export interface ICamunda_KeyValuePairInput {
  readonly key: Scalars["String"];
  readonly value: Scalars["String"];
  readonly valueType?: InputMaybe<ICamunda_ValueTypeEnum | "%future added value">;
}

/** An object structure representing an executable process composed of activities and transitions. */
export interface ICamunda_ProcessDefinition {
  readonly __typename?: "camunda_ProcessDefinition";
  /** The category of the process definition. */
  readonly category?: Maybe<Scalars["String"]>;
  readonly deployment?: Maybe<ICamunda_Deployment>;
  /** The deployment id of the process definition. */
  readonly deploymentId: Scalars["ID"];
  /** The description of the process definition. */
  readonly description?: Maybe<Scalars["String"]>;
  /** XML serialization of the process definition BPMN. */
  readonly diagram?: Maybe<Scalars["String"]>;
  /** History time to live value of the process definition. Is used within History cleanup. */
  readonly historyTimeToLive?: Maybe<Scalars["Int"]>;
  /** The id of the process definition. */
  readonly id: Scalars["ID"];
  /** The key of the process definition, i.e., the id of the BPMN 2.0 XML process definition. */
  readonly key?: Maybe<Scalars["String"]>;
  /** The name of the process definition. */
  readonly name?: Maybe<Scalars["String"]>;
  /** The file name of the process definition. */
  readonly resource?: Maybe<Scalars["String"]>;
  /** A flag indicating whether the process definition is startable in Tasklist or not. */
  readonly startableInTasklist?: Maybe<Scalars["Boolean"]>;
  /** A flag indicating whether the definition is suspended or not. */
  readonly suspended?: Maybe<Scalars["Boolean"]>;
  /** The tenant id of the process definition. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
  /** The version of the process definition that the engine assigned to it. */
  readonly version?: Maybe<Scalars["Int"]>;
  /** The version tag of the process or null when no version tag is set. */
  readonly versionTag?: Maybe<Scalars["String"]>;
}

/** Represents one execution of a ProcessDefinition. */
export interface ICamunda_ProcessInstance {
  readonly __typename?: "camunda_ProcessInstance";
  readonly activityInstance?: Maybe<ICamunda_ActivityInstance>;
  /** The business key of the process instance. */
  readonly businessKey?: Maybe<Scalars["String"]>;
  /** The id of the case instance associated with the process instance. */
  readonly caseInstanceId?: Maybe<Scalars["ID"]>;
  readonly definition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition this instance belongs to. */
  readonly definitionId: Scalars["ID"];
  readonly domProcessInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_ProcessInstance>>>;
  readonly dueDate?: Maybe<Scalars["JsDate"]>;
  /** A flag indicating whether the process instance has ended or not. Deprecated: will always be false! */
  readonly ended?: Maybe<Scalars["Boolean"]>;
  readonly executions?: Maybe<ReadonlyArray<Maybe<ICamunda_Execution>>>;
  readonly historicActivityInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_HistoricActivityInstance>>>;
  /** The id of the process instance. */
  readonly id: Scalars["ID"];
  readonly incidents?: Maybe<ReadonlyArray<Maybe<ICamunda_Incident>>>;
  readonly jobs?: Maybe<ReadonlyArray<Maybe<ICamunda_Job>>>;
  /** The time last historic activity instance ended. Default format* yyyy-MM-dd'T'HH:mm:ss.SSSZ. */
  readonly lastHistoricActivityAt?: Maybe<Scalars["String"]>;
  readonly lastUpdateDate?: Maybe<Scalars["JsDate"]>;
  readonly rootProcessInstance?: Maybe<ICamunda_ProcessInstance>;
  readonly subProcessInstances?: Maybe<ReadonlyArray<Maybe<ICamunda_ProcessInstance>>>;
  /** A flag indicating whether the process instance is suspended or not. */
  readonly suspended?: Maybe<Scalars["Boolean"]>;
  readonly tasks?: Maybe<ReadonlyArray<Maybe<ICamunda_Task>>>;
  readonly tasksByBusinessKey?: Maybe<ReadonlyArray<Maybe<ICamunda_Task>>>;
  /** The tenant id of the process instance. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
  readonly topProcessInstance?: Maybe<ICamunda_ProcessInstance>;
  readonly variables?: Maybe<ReadonlyArray<Maybe<ICamunda_KeyValuePair>>>;
}

/** Represents one execution of a ProcessDefinition. */
export type ICamunda_ProcessInstanceVariablesArgs = {
  keys?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
};

export interface ICamunda_Task {
  readonly __typename?: "camunda_Task";
  readonly allowsInternationalAgents?: Maybe<Scalars["Boolean"]>;
  readonly assignee?: Maybe<ICamunda_User>;
  /** The assignee's id. */
  readonly assigneeId?: Maybe<Scalars["ID"]>;
  /** The id of the case definition the task belongs to. */
  readonly caseDefinitionId?: Maybe<Scalars["ID"]>;
  /** The id of the case execution the task belongs to. */
  readonly caseExecutionId?: Maybe<Scalars["ID"]>;
  /** The id of the case instance the task belongs to. */
  readonly caseInstanceId?: Maybe<Scalars["ID"]>;
  readonly comments?: Maybe<ReadonlyArray<Maybe<ICamunda_Comment>>>;
  /** The date the task was created on. Has the format yyyy-MM-dd'T'HH:mm:ss. */
  readonly created?: Maybe<Scalars["String"]>;
  readonly creditor?: Maybe<ICreditor>;
  readonly creditorTask?: Maybe<ICreditorTask>;
  readonly creditorTaskId?: Maybe<Scalars["String"]>;
  readonly debt?: Maybe<IDebt>;
  /** The task's delegation state. Possible values are PENDING and RESOLVED. */
  readonly delegationState?: Maybe<Scalars["String"]>;
  /** The task's description. */
  readonly description?: Maybe<Scalars["String"]>;
  readonly disputeContextHelp?: Maybe<IDisputeContextHelp>;
  readonly disputeType?: Maybe<IDisputeClaim_Reason | "%future added value">;
  /** The task's due date. Has the format yyyy-MM-dd'T'HH:mm:ss. */
  readonly due?: Maybe<Scalars["String"]>;
  readonly dueDate?: Maybe<Scalars["JsDate"]>;
  readonly execution?: Maybe<ICamunda_Execution>;
  /** The id of the execution the task belongs to. */
  readonly executionId: Scalars["ID"];
  readonly fileRequestsReviews?: Maybe<ReadonlyArray<IFileRequestReview>>;
  /** The follow-up date for the task. Format yyyy-MM-dd'T'HH:mm:ss. */
  readonly followUp?: Maybe<Scalars["String"]>;
  readonly form?: Maybe<ICamunda_TaskFormData>;
  /** If not null, the form key for the task. */
  readonly formKey?: Maybe<Scalars["String"]>;
  readonly formVariables?: Maybe<ReadonlyArray<Maybe<ICamunda_KeyValuePair>>>;
  readonly historicProcessInstance?: Maybe<ICamunda_HistoricProcessInstance>;
  /** The task id. */
  readonly id: Scalars["ID"];
  /** The task name. */
  readonly name?: Maybe<Scalars["String"]>;
  readonly owner?: Maybe<ICamunda_User>;
  /** The owner's id. */
  readonly ownerId?: Maybe<Scalars["ID"]>;
  /** The id the parent task, if this task is a subtask. */
  readonly parentTaskId?: Maybe<Scalars["ID"]>;
  /** The task's priority. */
  readonly priority?: Maybe<Scalars["Int"]>;
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition the task belongs to. */
  readonly processDefinitionId: Scalars["ID"];
  readonly processInstance?: Maybe<ICamunda_ProcessInstance>;
  /** The id of the process instance the task belongs to. */
  readonly processInstanceId: Scalars["ID"];
  /** Whether the task belongs to a process instance that is suspended. */
  readonly suspended?: Maybe<Scalars["Boolean"]>;
  /** The task's key. */
  readonly taskDefinitionKey?: Maybe<Scalars["String"]>;
  /** If not null, the tenant id of the task. */
  readonly tenantId?: Maybe<Scalars["ID"]>;
  readonly variables?: Maybe<ReadonlyArray<Maybe<ICamunda_KeyValuePair>>>;
}

export type ICamunda_TaskVariablesArgs = {
  keys?: InputMaybe<ReadonlyArray<Scalars["String"]>>;
};

export interface ICamunda_TaskFormData {
  readonly __typename?: "camunda_TaskFormData";
  readonly deploymentId: Scalars["ID"];
  readonly formFields?: Maybe<ReadonlyArray<Maybe<ICamunda_FormField>>>;
  readonly formKey?: Maybe<Scalars["ID"]>;
}

export interface ICamunda_TransitionInstance {
  readonly __typename?: "camunda_TransitionInstance";
  /** The id of the activity. */
  readonly activityId: Scalars["ID"];
  /** The name of the activity. */
  readonly activityName?: Maybe<Scalars["String"]>;
  /** The type of activity (corresponds to the XML element name in the BPMN 2.0, e.g., 'userTask'). */
  readonly activityType?: Maybe<Scalars["String"]>;
  readonly execution?: Maybe<ICamunda_Execution>;
  /** An execution id. */
  readonly executionId: Scalars["ID"];
  /** The id of the transition instance. */
  readonly id: Scalars["ID"];
  /** A list of incident ids. */
  readonly incidentIds?: Maybe<ReadonlyArray<Maybe<Scalars["ID"]>>>;
  /** A list of JSON objects containing incident specific properties */
  readonly incidents?: Maybe<ReadonlyArray<Maybe<ICamunda_Incident>>>;
  readonly processDefinition?: Maybe<ICamunda_ProcessDefinition>;
  /** The id of the process definition. */
  readonly processDefinitionId: Scalars["ID"];
  readonly processInstance?: Maybe<ICamunda_ProcessInstance>;
  /** The id of the process instance this transition instance is part of. */
  readonly processInstanceId: Scalars["ID"];
}

export interface ICamunda_User {
  readonly __typename?: "camunda_User";
  readonly authorizations?: Maybe<ReadonlyArray<Maybe<ICamunda_Authorization>>>;
  /** The email of the user. */
  readonly email?: Maybe<Scalars["String"]>;
  /** The first name of the user. */
  readonly firstName?: Maybe<Scalars["String"]>;
  readonly groups?: Maybe<ReadonlyArray<Maybe<ICamunda_Group>>>;
  readonly historicTasks?: Maybe<ReadonlyArray<Maybe<ICamunda_HistoricTask>>>;
  /** The id of the user. */
  readonly id: Scalars["ID"];
  /** The last name of the user. */
  readonly lastName?: Maybe<Scalars["String"]>;
  /** The full name of the user. */
  readonly name?: Maybe<Scalars["String"]>;
  readonly regionRestriction: Scalars["Boolean"];
  readonly tasks?: Maybe<ReadonlyArray<Maybe<ICamunda_Task>>>;
}

export type ICamunda_UserRegionRestrictionArgs = {
  setTo: Scalars["Boolean"];
};

export enum ICamunda_ValueTypeEnum {
  Boolean = "BOOLEAN",
  Date = "DATE",
  Double = "DOUBLE",
  Enum = "ENUM",
  Float = "FLOAT",
  Int = "INT",
  Long = "LONG",
  Object = "OBJECT",
  String = "STRING"
}

/**
 * File: collectr/proto/magicbus/magicbus.proto
 * com.trueaccord.magicbus.protos.MagicBusFile
 */
export interface ICom_Trueaccord_Magicbus_Protos_MagicBusFile {
  readonly __typename?: "com_trueaccord_magicbus_protos_MagicBusFile";
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.failure_reason
   */
  readonly failure_reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.file_name
   */
  readonly file_name?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.md5_hex
   */
  readonly md5_hex?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.outboundDestinationStatuses
   */
  readonly outboundDestinationStatuses: ReadonlyArray<
    Maybe<ICom_Trueaccord_Magicbus_Protos_MagicBusFile_OutboundDestinationStatus>
  >;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.s3_bucket
   */
  readonly s3_bucket?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.s3_key
   */
  readonly s3_key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.source
   */
  readonly source?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.status
   */
  readonly status?: Maybe<ICom_Trueaccord_Magicbus_Protos_MagicBusFile_Status | "%future added value">;
}

/**
 * File: collectr/proto/magicbus/magicbus.proto
 * com.trueaccord.magicbus.protos.MagicBusFile.OutboundDestinationStatus
 */
export interface ICom_Trueaccord_Magicbus_Protos_MagicBusFile_OutboundDestinationStatus {
  readonly __typename?: "com_trueaccord_magicbus_protos_MagicBusFile_OutboundDestinationStatus";
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.OutboundDestinationStatus.dest
   */
  readonly dest?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.OutboundDestinationStatus.destination_type
   */
  readonly destination_type?: Maybe<
    | ICom_Trueaccord_Magicbus_Protos_MagicBusFile_OutboundDestinationStatus_DestinationType
    | "%future added value"
  >;
  /**
   * File: collectr/proto/magicbus/magicbus.proto
   * com.trueaccord.magicbus.protos.MagicBusFile.OutboundDestinationStatus.status
   */
  readonly status?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/magicbus/magicbus.proto
 * com.trueaccord.magicbus.protos.MagicBusFile.OutboundDestinationStatus.DestinationType
 */
export enum ICom_Trueaccord_Magicbus_Protos_MagicBusFile_OutboundDestinationStatus_DestinationType {
  Email = "EMAIL",
  Sftp = "SFTP"
}

/**
 * File: collectr/proto/magicbus/magicbus.proto
 * com.trueaccord.magicbus.protos.MagicBusFile.Status
 */
export enum ICom_Trueaccord_Magicbus_Protos_MagicBusFile_Status {
  ProcessedFail = "PROCESSED_FAIL",
  ProcessedOk = "PROCESSED_OK",
  Skip = "SKIP",
  Unprocessed = "UNPROCESSED"
}

/**
 * File: collectr/proto/communications/communication_event.proto
 * com.trueaccord.proto.communications.Header.CommunicationType
 */
export enum ICommunications_Header_CommunicationType {
  AgentTriggeredEmail = "AGENT_TRIGGERED_EMAIL",
  AutomatedEmail = "AUTOMATED_EMAIL",
  AutomatedLetter = "AUTOMATED_LETTER",
  AutomatedSms = "AUTOMATED_SMS",
  HelpdeskEmail = "HELPDESK_EMAIL",
  HelpdeskPhoneCallNoContact = "HELPDESK_PHONE_CALL_NO_CONTACT",
  HelpdeskPhoneCallWithContact = "HELPDESK_PHONE_CALL_WITH_CONTACT",
  UnknownType = "UNKNOWN_TYPE",
  WarmTransferPhoneCall = "WARM_TRANSFER_PHONE_CALL",
  Webform = "WEBFORM"
}

/**
 * File: collectr/proto/communications/channel.proto
 * com.trueaccord.proto.communications.channel.Channel
 */
export enum ICommunications_Channel_Channel {
  Call = "CALL",
  Email = "EMAIL",
  Letter = "LETTER",
  PushNotification = "PUSH_NOTIFICATION",
  Sms = "SMS",
  Voicemail = "VOICEMAIL",
  WarmTransfer = "WARM_TRANSFER"
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.account
   */
  readonly account?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Account>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.agent
   */
  readonly agent?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Agent>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.agent_provided
   */
  readonly agent_provided?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_AgentProvided>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.call
   */
  readonly call?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Call>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.call_aux_data
   */
  readonly call_aux_data?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_CallAuxData>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.contact
   */
  readonly contact?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Contact>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.context
   */
  readonly context?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Context>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.custom
   */
  readonly custom?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Custom>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.desk
   */
  readonly desk?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Desk>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.event_date_time
   */
  readonly event_date_time?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_EventDateTime>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.event_type
   */
  readonly event_type?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_EventType | "%future added value">;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.instance_url
   */
  readonly instance_url?: Maybe<
    ICommunications_Talkdesk_Event_TalkDeskEvent_InstanceUrl | "%future added value"
  >;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.ivr
   */
  readonly ivr?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Ivr>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.raw_talk_desk_event_batch_id
   */
  readonly raw_talk_desk_event_batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.transfer
   */
  readonly transfer?: Maybe<ICommunications_Talkdesk_Event_TalkDeskEvent_Transfer>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Account
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Account {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_Account";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Account.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Account.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Account.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Account.number
   */
  readonly number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Agent
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Agent {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_Agent";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Agent.current_status
   */
  readonly current_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Agent.emails
   */
  readonly emails: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Agent.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Agent.names
   */
  readonly names: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Agent.previous_status
   */
  readonly previous_status?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Agent.ringing_groups
   */
  readonly ringing_groups: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.AgentProvided
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_AgentProvided {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_AgentProvided";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.AgentProvided.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.AgentProvided.hang_up_reason
   */
  readonly hang_up_reason?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.AgentProvided.mood_score
   */
  readonly mood_score?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.AgentProvided.notes_content
   */
  readonly notes_content?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.AgentProvided.notes_title
   */
  readonly notes_title?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Call {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_Call";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.agent_speed_to_answer
   */
  readonly agent_speed_to_answer?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.call_type
   */
  readonly call_type?: Maybe<
    ICommunications_Talkdesk_Event_TalkDeskEvent_Call_CallType | "%future added value"
  >;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.call_uuid
   */
  readonly call_uuid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.callback_from_queue
   */
  readonly callback_from_queue?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.called_number
   */
  readonly called_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.callers_number
   */
  readonly callers_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.direction
   */
  readonly direction?: Maybe<
    ICommunications_Talkdesk_Event_TalkDeskEvent_Call_CallDirection | "%future added value"
  >;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.display_name
   */
  readonly display_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.disposition
   */
  readonly disposition?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.duration
   */
  readonly duration?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.holding_time
   */
  readonly holding_time?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.queue_length_for_ringing_groups
   */
  readonly queue_length_for_ringing_groups?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.recording_url
   */
  readonly recording_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.ringing_groups
   */
  readonly ringing_groups: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.start_time
   */
  readonly start_time?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.talkdesk_number
   */
  readonly talkdesk_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.total_duration
   */
  readonly total_duration?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.transcription
   */
  readonly transcription?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.transcription_url
   */
  readonly transcription_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.voicemail_duration
   */
  readonly voicemail_duration?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.waiting_queue_size
   */
  readonly waiting_queue_size?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.waiting_time
   */
  readonly waiting_time?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.CallAuxData
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_CallAuxData {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_CallAuxData";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.CallAuxData.csat_score
   */
  readonly csat_score?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.CallAuxData.extension
   */
  readonly extension?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.CallAuxData.in_business_hours
   */
  readonly in_business_hours?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.CallAuxData.is_external
   */
  readonly is_external?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.CallAuxData.phones_sms_integration
   */
  readonly phones_sms_integration?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.CallDirection
 */
export enum ICommunications_Talkdesk_Event_TalkDeskEvent_Call_CallDirection {
  In = "IN",
  Out = "OUT"
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Call.CallType
 */
export enum ICommunications_Talkdesk_Event_TalkDeskEvent_Call_CallType {
  Abandoned = "ABANDONED",
  Inbound = "INBOUND",
  Missed = "MISSED",
  Outbound = "OUTBOUND",
  OutboundMissed = "OUTBOUND_MISSED",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Contact {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_Contact";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.address
   */
  readonly address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.import_external_id
   */
  readonly import_external_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.import_external_url
   */
  readonly import_external_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.importer_type
   */
  readonly importer_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.phone
   */
  readonly phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.photo_url
   */
  readonly photo_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.title
   */
  readonly title?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Contact.website
   */
  readonly website?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Context
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Context {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_Context";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Context.person_ids
   */
  readonly person_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Custom
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Custom {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_Custom";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Custom.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Custom.debtor_id
   */
  readonly debtor_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Desk
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Desk {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_Desk";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Desk.desk_type
   */
  readonly desk_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Desk.external_id
   */
  readonly external_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Desk.external_url
   */
  readonly external_url?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.EventDateTime
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_EventDateTime {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_EventDateTime";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.EventDateTime.present_date
   */
  readonly present_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.EventDateTime.present_time
   */
  readonly present_time?: Maybe<ITime>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.EventType
 */
export enum ICommunications_Talkdesk_Event_TalkDeskEvent_EventType {
  AgentLogin = "AGENT_LOGIN",
  AgentLogoff = "AGENT_LOGOFF",
  AgentStatusChanges = "AGENT_STATUS_CHANGES",
  CallAbandoned = "CALL_ABANDONED",
  CallEntersWaitingQueue = "CALL_ENTERS_WAITING_QUEUE",
  CallLeavesWaitingQueue = "CALL_LEAVES_WAITING_QUEUE",
  CallMissed = "CALL_MISSED",
  CallShortAbandoned = "CALL_SHORT_ABANDONED",
  ContactCreated = "CONTACT_CREATED",
  ContactUpdated = "CONTACT_UPDATED",
  CsatScoreReceived = "CSAT_SCORE_RECEIVED",
  InboundCallEnds = "INBOUND_CALL_ENDS",
  InboundCallRinging = "INBOUND_CALL_RINGING",
  InboundCallStarts = "INBOUND_CALL_STARTS",
  InboundDispositionCodeSet = "INBOUND_DISPOSITION_CODE_SET",
  NoteCreated = "NOTE_CREATED",
  OutboundCallEnds = "OUTBOUND_CALL_ENDS",
  OutboundCallMissed = "OUTBOUND_CALL_MISSED",
  OutboundCallRinging = "OUTBOUND_CALL_RINGING",
  OutboundCallStarts = "OUTBOUND_CALL_STARTS",
  OutboundDispositionCodeSet = "OUTBOUND_DISPOSITION_CODE_SET",
  TransferAnswered = "TRANSFER_ANSWERED",
  Unknown = "UNKNOWN",
  VoicemailAssigned = "VOICEMAIL_ASSIGNED",
  VoicemailLeft = "VOICEMAIL_LEFT",
  VoicemailTranscriptAvailable = "VOICEMAIL_TRANSCRIPT_AVAILABLE"
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.IVR
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Ivr {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_IVR";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.IVR.digits
   */
  readonly digits: ReadonlyArray<Maybe<Scalars["Int"]>>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.IVR.using_ivr
   */
  readonly using_ivr?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.InstanceUrl
 */
export enum ICommunications_Talkdesk_Event_TalkDeskEvent_InstanceUrl {
  TaCof = "TA_COF",
  TaTest = "TA_TEST"
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Transfer
 */
export interface ICommunications_Talkdesk_Event_TalkDeskEvent_Transfer {
  readonly __typename?: "communications_talkdesk_event_TalkDeskEvent_Transfer";
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Transfer.number_of_agents
   */
  readonly number_of_agents?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Transfer.previous_agent_email
   */
  readonly previous_agent_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Transfer.previous_agent_id
   */
  readonly previous_agent_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Transfer.previous_agent_name
   */
  readonly previous_agent_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Transfer.previous_agent_tags
   */
  readonly previous_agent_tags?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/communications/talkdesk/event.proto
   * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Transfer.transfer_type
   */
  readonly transfer_type?: Maybe<
    ICommunications_Talkdesk_Event_TalkDeskEvent_Transfer_TransferType | "%future added value"
  >;
}

/**
 * File: collectr/proto/communications/talkdesk/event.proto
 * com.trueaccord.proto.communications.talkdesk.event.TalkDeskEvent.Transfer.TransferType
 */
export enum ICommunications_Talkdesk_Event_TalkDeskEvent_Transfer_TransferType {
  Blind = "BLIND",
  Unknown = "UNKNOWN",
  Warm = "WARM"
}

/**
 * File: collectr/proto/compliance.proto
 * com.trueaccord.proto.compliance.ComplianceCapability.Capability
 */
export enum ICompliance_ComplianceCapability_Capability {
  Allowed = "ALLOWED",
  PermanentlyDenied = "PERMANENTLY_DENIED",
  TemporarilyDenied = "TEMPORARILY_DENIED"
}

/**
 * File: collectr/proto/compliance.proto
 * com.trueaccord.proto.compliance.ComplianceCapability.ChannelCapability
 */
export interface ICompliance_ComplianceCapability_ChannelCapability {
  readonly __typename?: "compliance_ComplianceCapability_ChannelCapability";
  /**
   * File: collectr/proto/compliance.proto
   * com.trueaccord.proto.compliance.ComplianceCapability.ChannelCapability.capability
   */
  readonly capability?: Maybe<ICompliance_ComplianceCapability_Capability | "%future added value">;
  /**
   * File: collectr/proto/compliance.proto
   * com.trueaccord.proto.compliance.ComplianceCapability.ChannelCapability.channel
   */
  readonly channel?: Maybe<ICommunications_Channel_Channel | "%future added value">;
  /**
   * File: collectr/proto/compliance.proto
   * com.trueaccord.proto.compliance.ComplianceCapability.ChannelCapability.failures
   */
  readonly failures: ReadonlyArray<Maybe<ICompliance_ComplianceCapability_Failure | "%future added value">>;
  /**
   * File: collectr/proto/compliance.proto
   * com.trueaccord.proto.compliance.ComplianceCapability.ChannelCapability.usecase
   */
  readonly usecase?: Maybe<ITemplates_TemplateAnnotation_Usecase | "%future added value">;
}

/**
 * File: collectr/proto/compliance.proto
 * com.trueaccord.proto.compliance.ComplianceCapability.Failure
 */
export enum ICompliance_ComplianceCapability_Failure {
  BlacklistedEmail = "BLACKLISTED_EMAIL",
  BlacklistedPhone = "BLACKLISTED_PHONE",
  BlacklistedSms = "BLACKLISTED_SMS",
  CallLikeRateLimitExceeded = "CALL_LIKE_RATE_LIMIT_EXCEEDED",
  CollectionPausedPermanently = "COLLECTION_PAUSED_PERMANENTLY",
  CollectionPausedTemporarily = "COLLECTION_PAUSED_TEMPORARILY",
  ConsentEmailViolatesLocationRules = "CONSENT_EMAIL_VIOLATES_LOCATION_RULES",
  CreditorLetterDisabled = "CREDITOR_LETTER_DISABLED",
  CreditorNotActive = "CREDITOR_NOT_ACTIVE",
  CreditorPushNotificationDisabled = "CREDITOR_PUSH_NOTIFICATION_DISABLED",
  CreditorSmsDisabled = "CREDITOR_SMS_DISABLED",
  CustomCallLikeRule = "CUSTOM_CALL_LIKE_RULE",
  CustomMailLikeRule = "CUSTOM_MAIL_LIKE_RULE",
  DebtConsolidationCompanyIncorrectEmail = "DEBT_CONSOLIDATION_COMPANY_INCORRECT_EMAIL",
  DebtIsUncollectible = "DEBT_IS_UNCOLLECTIBLE",
  DebtTimebarred = "DEBT_TIMEBARRED",
  DebtTimebarredCollectionNotAllowed = "DEBT_TIMEBARRED_COLLECTION_NOT_ALLOWED",
  DefaultDateOlderThanTwoYears = "DEFAULT_DATE_OLDER_THAN_TWO_YEARS",
  DeprecatedCallRequiresInitialCommunication = "DEPRECATED_CALL_REQUIRES_INITIAL_COMMUNICATION",
  DeskEmailCaseIsNotResolved = "DESK_EMAIL_CASE_IS_NOT_RESOLVED",
  EmailFrequencyTooHigh = "EMAIL_FREQUENCY_TOO_HIGH",
  InInitialCommunicationWaitPeriod = "IN_INITIAL_COMMUNICATION_WAIT_PERIOD",
  InSmsCommunicationWaitPeriod = "IN_SMS_COMMUNICATION_WAIT_PERIOD",
  IsDebtBuyer = "IS_DEBT_BUYER",
  IsOnActiveMilitaryDuty = "IS_ON_ACTIVE_MILITARY_DUTY",
  LacksInitialCommunication = "LACKS_INITIAL_COMMUNICATION",
  MailLikeRateLimitExceeded = "MAIL_LIKE_RATE_LIMIT_EXCEEDED",
  NyCollectionsRelatedEmailViolatesConsent = "NY_COLLECTIONS_RELATED_EMAIL_VIOLATES_CONSENT",
  OpenDispute = "OPEN_DISPUTE",
  PersonBankrupt = "PERSON_BANKRUPT",
  PersonDeceased = "PERSON_DECEASED",
  PersonLacksAllowedAddress = "PERSON_LACKS_ALLOWED_ADDRESS",
  PhoneOwnershipCallNotAllowed = "PHONE_OWNERSHIP_CALL_NOT_ALLOWED",
  PhoneOwnershipSmsNotAllowed = "PHONE_OWNERSHIP_SMS_NOT_ALLOWED",
  PhoneOwnershipVoicemailNotAllowed = "PHONE_OWNERSHIP_VOICEMAIL_NOT_ALLOWED",
  RepresentedByAttorneyAttorneyOnlyIncorrectEmail = "REPRESENTED_BY_ATTORNEY_ATTORNEY_ONLY_INCORRECT_EMAIL",
  RepresentedByAttorneyAttorneyOnlyNonEmailChannel = "REPRESENTED_BY_ATTORNEY_ATTORNEY_ONLY_NON_EMAIL_CHANNEL",
  RepresentedByAttorneyAttorneyOnlyRegEOnly = "REPRESENTED_BY_ATTORNEY_ATTORNEY_ONLY_REG_E_ONLY",
  RepresentedByAttorneyHardStop = "REPRESENTED_BY_ATTORNEY_HARD_STOP",
  RepresentedByDebtConsolidationStopComm = "REPRESENTED_BY_DEBT_CONSOLIDATION_STOP_COMM",
  RequiredInitialCallUnsatisfied = "REQUIRED_INITIAL_CALL_UNSATISFIED",
  RequiredInitialEmailUnsatisfied = "REQUIRED_INITIAL_EMAIL_UNSATISFIED",
  RequiredInitialLetterUnsatisfied = "REQUIRED_INITIAL_LETTER_UNSATISFIED",
  RequiredInitialPushNotificationUnsatisfied = "REQUIRED_INITIAL_PUSH_NOTIFICATION_UNSATISFIED",
  RequiredInitialSmsUnsatisfied = "REQUIRED_INITIAL_SMS_UNSATISFIED",
  RequiredInitialVoicemailUnsatisfied = "REQUIRED_INITIAL_VOICEMAIL_UNSATISFIED",
  RequiredInitialWarmTransferUnsatisfied = "REQUIRED_INITIAL_WARM_TRANSFER_UNSATISFIED",
  RequiredScrubsNotCompleted = "REQUIRED_SCRUBS_NOT_COMPLETED",
  RequiresSmsOptIn = "REQUIRES_SMS_OPT_IN",
  RetractedAccountEmailRule = "RETRACTED_ACCOUNT_EMAIL_RULE",
  StateBlockedByCreditor = "STATE_BLOCKED_BY_CREDITOR",
  TemplateAnnotationBlacklistedState = "TEMPLATE_ANNOTATION_BLACKLISTED_STATE",
  TemplateMissingApproval = "TEMPLATE_MISSING_APPROVAL",
  TemplateNotAllowedForDebt = "TEMPLATE_NOT_ALLOWED_FOR_DEBT",
  TemplateStageNotAllowedForDebt = "TEMPLATE_STAGE_NOT_ALLOWED_FOR_DEBT",
  TemplateViolatesLanguagePreference = "TEMPLATE_VIOLATES_LANGUAGE_PREFERENCE",
  UnknownAddress = "UNKNOWN_ADDRESS",
  UnknownEmail = "UNKNOWN_EMAIL",
  UnknownPhone = "UNKNOWN_PHONE",
  UnknownPushNotificationSubscription = "UNKNOWN_PUSH_NOTIFICATION_SUBSCRIPTION",
  UnsafeCallTime = "UNSAFE_CALL_TIME",
  UnsafeConsentEmailTime = "UNSAFE_CONSENT_EMAIL_TIME",
  UnsafeFollowupEmailTime = "UNSAFE_FOLLOWUP_EMAIL_TIME",
  UnsafePrerequisiteEmailTime = "UNSAFE_PREREQUISITE_EMAIL_TIME",
  UnsafeProactiveEmailTime = "UNSAFE_PROACTIVE_EMAIL_TIME",
  UnsafeReactiveEmailTime = "UNSAFE_REACTIVE_EMAIL_TIME",
  UnsafeSmsTime = "UNSAFE_SMS_TIME",
  UnsafeSystemFollowupEmailTime = "UNSAFE_SYSTEM_FOLLOWUP_EMAIL_TIME",
  UnsafeSystemInteractiveEmailTime = "UNSAFE_SYSTEM_INTERACTIVE_EMAIL_TIME",
  UnsafeVoicemailTime = "UNSAFE_VOICEMAIL_TIME",
  UnsafeWarmTransferTime = "UNSAFE_WARM_TRANSFER_TIME",
  UnsubscribedAddress = "UNSUBSCRIBED_ADDRESS",
  UnsubscribedEmail = "UNSUBSCRIBED_EMAIL",
  UnsubscribedPhone = "UNSUBSCRIBED_PHONE",
  UnsubscribedSms = "UNSUBSCRIBED_SMS",
  UnverifiedEmail = "UNVERIFIED_EMAIL",
  ValidDispute = "VALID_DISPUTE",
  ViolatesLocationRules = "VIOLATES_LOCATION_RULES",
  WorkingWithDebtConsolidationCompany = "WORKING_WITH_DEBT_CONSOLIDATION_COMPANY",
  ZendeskEmailCaseIsNotResolved = "ZENDESK_EMAIL_CASE_IS_NOT_RESOLVED"
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest
 */
export interface ICreditors_CreditorTask_DebtVerificationRequest {
  readonly __typename?: "creditors_CreditorTask_DebtVerificationRequest";
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.consent_to_release
   */
  readonly consent_to_release?: Maybe<ICreditors_CreditorTask_DebtVerificationRequest_ConsentToRelease>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.debt_state_id
   */
  readonly debt_state_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.in_camunda
   */
  readonly in_camunda?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.requested_files
   */
  readonly requested_files: ReadonlyArray<Maybe<ICreditors_FileRequest>>;
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.ConsentToRelease
 */
export interface ICreditors_CreditorTask_DebtVerificationRequest_ConsentToRelease {
  readonly __typename?: "creditors_CreditorTask_DebtVerificationRequest_ConsentToRelease";
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.ConsentToRelease.ip_address
   */
  readonly ip_address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.ConsentToRelease.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.DebtVerificationRequest.ConsentToRelease.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.CreditorTask.Header
 */
export interface ICreditors_CreditorTask_Header {
  readonly __typename?: "creditors_CreditorTask_Header";
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.Header.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.Header.resolution
   */
  readonly resolution?: Maybe<ICreditors_Resolution | "%future added value">;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.Header.status
   */
  readonly status?: Maybe<ICreditors_Status | "%future added value">;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.Header.task_id
   */
  readonly task_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.Header.time_created
   */
  readonly time_created?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.Header.time_due_by
   */
  readonly time_due_by?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.Header.time_notification_sent
   */
  readonly time_notification_sent?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.CreditorTask.Header.time_resolved
   */
  readonly time_resolved?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.FileRequest
 */
export interface ICreditors_FileRequest {
  readonly __typename?: "creditors_FileRequest";
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.FileRequest.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.FileRequest.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.FileRequest.remote_file_ids
   */
  readonly remote_file_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
  /** OneToMany */
  readonly remote_files?: Maybe<ReadonlyArray<Maybe<IRemoteFile>>>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.FileRequest.review
   */
  readonly review?: Maybe<ICreditors_Review>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.FileRequest.title
   */
  readonly title?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/creditors/accounting.proto
 * com.trueaccord.proto.creditors.OutgoingCheckPayment
 */
export interface ICreditors_OutgoingCheckPayment {
  readonly __typename?: "creditors_OutgoingCheckPayment";
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.OutgoingCheckPayment.payee_name
   */
  readonly payee_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.OutgoingCheckPayment.postal_address
   */
  readonly postal_address?: Maybe<IPostalAddress>;
}

/**
 * File: collectr/proto/creditors/accounting.proto
 * com.trueaccord.proto.creditors.ReportPeriod
 */
export interface ICreditors_ReportPeriod {
  readonly __typename?: "creditors_ReportPeriod";
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.ReportPeriod.display_name
   */
  readonly display_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.ReportPeriod.end_msec
   */
  readonly end_msec?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.ReportPeriod.start_msec
   */
  readonly start_msec?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.Resolution
 */
export enum ICreditors_Resolution {
  Completed = "COMPLETED",
  CreditorDeclined = "CREDITOR_DECLINED",
  InProgress = "IN_PROGRESS"
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.Review
 */
export interface ICreditors_Review {
  readonly __typename?: "creditors_Review";
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.Review.feedback
   */
  readonly feedback?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.Review.reviewed_item_id
   */
  readonly reviewed_item_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.Review.status
   */
  readonly status?: Maybe<ICreditors_Review_Status | "%future added value">;
  /**
   * File: collectr/proto/creditors/task.proto
   * com.trueaccord.proto.creditors.Review.time_reviewed
   */
  readonly time_reviewed?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.Review.Status
 */
export enum ICreditors_Review_Status {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED"
}

/**
 * File: collectr/proto/creditors/accounting.proto
 * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings
 */
export interface ICreditors_SensitiveCreditorSettings_DisbursementSettings {
  readonly __typename?: "creditors_SensitiveCreditorSettings_DisbursementSettings";
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.add_remittance_sheet
   */
  readonly add_remittance_sheet?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.disbursement_day_of_week
   */
  readonly disbursement_day_of_week?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.disbursement_mode
   */
  readonly disbursement_mode?: Maybe<
    ICreditors_SensitiveCreditorSettings_DisbursementSettings_DisbursementMode | "%future added value"
  >;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.payment_frequency
   */
  readonly payment_frequency?: Maybe<
    ICreditors_SensitiveCreditorSettings_DisbursementSettings_PaymentFrequency | "%future added value"
  >;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.payment_methods
   */
  readonly payment_methods: ReadonlyArray<
    Maybe<ICreditors_SensitiveCreditorSettings_DisbursementSettings_PaymentMethod>
  >;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.start_reporting_from_date
   */
  readonly start_reporting_from_date?: Maybe<IDate>;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.tax_address
   */
  readonly tax_address?: Maybe<IPostalAddress>;
}

/**
 * File: collectr/proto/creditors/accounting.proto
 * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.DisbursementMode
 */
export enum ICreditors_SensitiveCreditorSettings_DisbursementSettings_DisbursementMode {
  DisburseCollectionsInvoiceFees = "DISBURSE_COLLECTIONS_INVOICE_FEES",
  DisburseOrInvoiceBalance = "DISBURSE_OR_INVOICE_BALANCE"
}

/**
 * File: collectr/proto/creditors/accounting.proto
 * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.PaymentFrequency
 */
export enum ICreditors_SensitiveCreditorSettings_DisbursementSettings_PaymentFrequency {
  Biweekly = "BIWEEKLY",
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
  Semimonthly = "SEMIMONTHLY",
  Weekly = "WEEKLY"
}

/**
 * File: collectr/proto/creditors/accounting.proto
 * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.PaymentMethod
 */
export interface ICreditors_SensitiveCreditorSettings_DisbursementSettings_PaymentMethod {
  readonly __typename?: "creditors_SensitiveCreditorSettings_DisbursementSettings_PaymentMethod";
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.PaymentMethod.check_payment
   */
  readonly check_payment?: Maybe<ICreditors_OutgoingCheckPayment>;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.PaymentMethod.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/creditors/accounting.proto
   * com.trueaccord.proto.creditors.SensitiveCreditorSettings.DisbursementSettings.PaymentMethod.us_bank_account
   */
  readonly us_bank_account?: Maybe<IUsBankAccount>;
}

/**
 * File: collectr/proto/creditors/task.proto
 * com.trueaccord.proto.creditors.Status
 */
export enum ICreditors_Status {
  Draft = "DRAFT",
  NotificationSent = "NOTIFICATION_SENT",
  PendingFixes = "PENDING_FIXES",
  Resolved = "RESOLVED",
  UnderReview = "UNDER_REVIEW"
}

/**
 * File: collectr/proto/dispute_task.proto
 * com.trueaccord.proto.dispute_task.DisputeTask
 */
export interface IDispute_Task_DisputeTask {
  readonly __typename?: "dispute_task_DisputeTask";
  /**
   * File: collectr/proto/dispute_task.proto
   * com.trueaccord.proto.dispute_task.DisputeTask.creditor_task_id
   */
  readonly creditor_task_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute_task.proto
   * com.trueaccord.proto.dispute_task.DisputeTask.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/dispute_task.proto
   * com.trueaccord.proto.dispute_task.DisputeTask.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/dispute_task.proto
   * com.trueaccord.proto.dispute_task.DisputeTask.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/dispute_task.proto
   * com.trueaccord.proto.dispute_task.DisputeTask.state
   */
  readonly state?: Maybe<IDispute_Task_DisputeTask_State | "%future added value">;
}

/**
 * File: collectr/proto/dispute_task.proto
 * com.trueaccord.proto.dispute_task.DisputeTask.State
 */
export enum IDispute_Task_DisputeTask_State {
  AwaitingCreditorResponse = "AWAITING_CREDITOR_RESPONSE",
  Bankruptcy = "BANKRUPTCY",
  CreditorTask = "CREDITOR_TASK",
  EtTask = "ET_TASK",
  Final = "FINAL",
  Initial = "INITIAL",
  ObsoletedBankruptcyOrIdentityTheft = "OBSOLETED_BANKRUPTCY_OR_IDENTITY_THEFT",
  ObsoletedIdentityTheft = "OBSOLETED_IDENTITY_THEFT"
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.CommunicatorMetrics
 */
export interface IExecutor_CommunicatorMetrics {
  readonly __typename?: "executor_CommunicatorMetrics";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CommunicatorMetrics.communicator_type
   */
  readonly communicator_type?: Maybe<IExecutor_CommunicatorMetrics_CommunicatorType | "%future added value">;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.CommunicatorMetrics.CommunicatorType
 */
export enum IExecutor_CommunicatorMetrics_CommunicatorType {
  Executor = "EXECUTOR",
  Modern = "MODERN",
  Repo = "REPO",
  Unknown = "UNKNOWN"
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.ContactFormData
 */
export interface IExecutor_ContactFormData {
  readonly __typename?: "executor_ContactFormData";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.company
   */
  readonly company?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.form_fields
   */
  readonly form_fields: ReadonlyArray<Maybe<IExecutor_ContactFormData_FormField>>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.ip
   */
  readonly ip?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.lead_type
   */
  readonly lead_type?: Maybe<IExecutor_SendLeadSignUpEmailRequest_LeadType | "%future added value">;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.notes
   */
  readonly notes?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.page_slug
   */
  readonly page_slug?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.rating
   */
  readonly rating?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.reason
   */
  readonly reason?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.ContactFormData.FormField
 */
export interface IExecutor_ContactFormData_FormField {
  readonly __typename?: "executor_ContactFormData_FormField";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.FormField.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.ContactFormData.FormField.value
   */
  readonly value?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.CreateCallRequest
 */
export interface IExecutor_CreateCallRequest {
  readonly __typename?: "executor_CreateCallRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.body
   */
  readonly body?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.call_type
   */
  readonly call_type?: Maybe<IExecutor_CreateCallRequest_CallType | "%future added value">;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.case_id
   */
  readonly case_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.debtor_id
   */
  readonly debtor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.labels
   */
  readonly labels: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.priority
   */
  readonly priority?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.rate_limiter_action_id
   */
  readonly rate_limiter_action_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.CreateCallRequest.recipient
   */
  readonly recipient?: Maybe<IExecutor_PhoneNumber>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.CreateCallRequest.CallType
 */
export enum IExecutor_CreateCallRequest_CallType {
  AlmostPaid = "ALMOST_PAID",
  CantAffordItCheckIn = "CANT_AFFORD_IT_CHECK_IN",
  CantAffordItTimeUp = "CANT_AFFORD_IT_TIME_UP",
  DebtBuyerDebt = "DEBT_BUYER_DEBT",
  EmailVerificationCheckUp = "EMAIL_VERIFICATION_CHECK_UP",
  EngagedOpenersCb = "ENGAGED_OPENERS_CB",
  EngageElusive = "ENGAGE_ELUSIVE",
  ExpiringCardOnPlan = "EXPIRING_CARD_ON_PLAN",
  FailedPayment = "FAILED_PAYMENT",
  FailedPaymentOnPlan = "FAILED_PAYMENT_ON_PLAN",
  FailureCall = "FAILURE_CALL",
  Introduction = "INTRODUCTION",
  KeepsOpening = "KEEPS_OPENING",
  MilestoneOpens = "MILESTONE_OPENS",
  MissedCallCallback = "MISSED_CALL_CALLBACK",
  MissingEmailContact = "MISSING_EMAIL_CONTACT",
  NaiveExperience = "NAIVE_EXPERIENCE",
  NysAwaitingConsent = "NYS_AWAITING_CONSENT",
  PartialPaymentReactivate = "PARTIAL_PAYMENT_REACTIVATE",
  PaymentAttemptFailure = "PAYMENT_ATTEMPT_FAILURE",
  PaymentInterestIndicated = "PAYMENT_INTEREST_INDICATED",
  PromiseCall = "PROMISE_CALL",
  QualifierIntroCheckIn = "QUALIFIER_INTRO_CHECK_IN",
  ReactivePageView = "REACTIVE_PAGE_VIEW",
  RecentPageView = "RECENT_PAGE_VIEW",
  Reengagement = "REENGAGEMENT",
  RejectedReengagement = "REJECTED_REENGAGEMENT",
  RequestedCallBack = "REQUESTED_CALL_BACK",
  ReturnImminent = "RETURN_IMMINENT",
  Spammer = "SPAMMER",
  SpecialCase = "SPECIAL_CASE",
  TerminalEngageElusive = "TERMINAL_ENGAGE_ELUSIVE",
  TerminalReengagement = "TERMINAL_REENGAGEMENT",
  UnengagedOpenEvent = "UNENGAGED_OPEN_EVENT"
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.DebtDescription
 */
export interface IExecutor_DebtDescription {
  readonly __typename?: "executor_DebtDescription";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.amount
   */
  readonly amount?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.balance
   */
  readonly balance?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.biller_name
   */
  readonly biller_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.creditor_name
   */
  readonly creditor_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.currency
   */
  readonly currency?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.debtor_company_name
   */
  readonly debtor_company_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.debtor_id
   */
  readonly debtor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.environment
   */
  readonly environment?: Maybe<IEnvironment | "%future added value">;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.fee
   */
  readonly fee?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DebtDescription.product
   */
  readonly product?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.DeliveryHints
 */
export interface IExecutor_DeliveryHints {
  readonly __typename?: "executor_DeliveryHints";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints._OBSOLETE_email_ip_pool
   */
  readonly _OBSOLETE_email_ip_pool?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.accounts_dot_zebit_dot_com
   */
  readonly accounts_dot_zebit_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.autoservice_dot_onefinance_dot_com
   */
  readonly autoservice_dot_onefinance_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.confirm_dot_trueaccord_dot_com
   */
  readonly confirm_dot_trueaccord_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.connect_dot_americanfirstfinance_dot_com
   */
  readonly connect_dot_americanfirstfinance_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.email_dot_trueaccord_dot_com
   */
  readonly email_dot_trueaccord_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.email_ip_sub_pool
   */
  readonly email_ip_sub_pool?: Maybe<IExecutor_EmailIpSubPoolEnum_EmailIpSubPool | "%future added value">;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.email_service_provider
   */
  readonly email_service_provider?: Maybe<
    IExecutor_EmailServiceProviderEnum_EmailServiceProvider | "%future added value"
  >;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.firstenergy_dot_firstenergycorp_dot_com
   */
  readonly firstenergy_dot_firstenergycorp_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.hello_dot_trueaccord_dot_com
   */
  readonly hello_dot_trueaccord_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.humanserviceassistance_dot_firstenergycorp_dot_com
   */
  readonly humanserviceassistance_dot_firstenergycorp_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.mail_dot_payment_dot_pnc_dot_com
   */
  readonly mail_dot_payment_dot_pnc_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.notice_dot_snapfinance_dot_com
   */
  readonly notice_dot_snapfinance_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.notify_dot_trueaccord_dot_com
   */
  readonly notify_dot_trueaccord_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.payment_dot_oneblinc_dot_com
   */
  readonly payment_dot_oneblinc_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.payments_dot_aspire_dot_com
   */
  readonly payments_dot_aspire_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.payments_dot_hey_artis_dot_com
   */
  readonly payments_dot_hey_artis_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.payments_dot_hey_manor_credit_dot_com
   */
  readonly payments_dot_hey_manor_credit_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.payments_dot_jasper_card_dot_com
   */
  readonly payments_dot_jasper_card_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.payments_dot_myfortiva_dot_com
   */
  readonly payments_dot_myfortiva_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.sender
   */
  readonly sender?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.servicing_dot_futurepay_dot_com
   */
  readonly servicing_dot_futurepay_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.takecontrolmail_dot_capitalone_dot_com
   */
  readonly takecontrolmail_dot_capitalone_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.trueaccord_dot_com
   */
  readonly trueaccord_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.trueaccord_notifications_dot_com
   */
  readonly trueaccord_notifications_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.trueaccord_notify_dot_com
   */
  readonly trueaccord_notify_dot_com?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.DeliveryHints.trueaccord_verify_dot_com
   */
  readonly trueaccord_verify_dot_com?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation
 */
export interface IExecutor_EmailAnnotation {
  readonly __typename?: "executor_EmailAnnotation";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.analyst_server_decision_id
   */
  readonly analyst_server_decision_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.cease_and_desist_goodbye
   */
  readonly cease_and_desist_goodbye?: Maybe<IExecutor_EmailAnnotation_CeaseAndDesistGoodbye>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.dispute_approved
   */
  readonly dispute_approved?: Maybe<IExecutor_EmailAnnotation_DisputeApproved>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.dispute_received
   */
  readonly dispute_received?: Maybe<IExecutor_EmailAnnotation_DisputeReceived>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.dispute_rejected
   */
  readonly dispute_rejected?: Maybe<IExecutor_EmailAnnotation_DisputeRejected>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.email_address_consent
   */
  readonly email_address_consent?: Maybe<IExecutor_EmailAnnotation_EmailAddressConsent>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.email_address_verification
   */
  readonly email_address_verification?: Maybe<IExecutor_EmailAnnotation_EmailAddressVerification>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.paid_in_full
   */
  readonly paid_in_full?: Maybe<IExecutor_EmailAnnotation_PaidInFull>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.payment_plan_email_notification
   */
  readonly payment_plan_email_notification?: Maybe<IDebtState_PaymentPlanEmailNotification>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.payment_thank_you
   */
  readonly payment_thank_you?: Maybe<IExecutor_EmailAnnotation_PaymentThankYou>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.risky_payment
   */
  readonly risky_payment?: Maybe<IExecutor_EmailAnnotation_RiskyPaymentInfo>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.sequence_info
   */
  readonly sequence_info?: Maybe<IExecutor_EmailAnnotation_TemplateSequenceInformation>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.settled_in_full
   */
  readonly settled_in_full?: Maybe<IExecutor_EmailAnnotation_SettledInFull>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.skip_compliance_check
   */
  readonly skip_compliance_check?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.skip_debt_log_entry_creation
   */
  readonly skip_debt_log_entry_creation?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.skip_rate_limit
   */
  readonly skip_rate_limit?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.total_to_collect_adjustment
   */
  readonly total_to_collect_adjustment?: Maybe<IExecutor_EmailAnnotation_TotalToCollectAdjustment>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.CeaseAndDesistGoodbye
 */
export interface IExecutor_EmailAnnotation_CeaseAndDesistGoodbye {
  readonly __typename?: "executor_EmailAnnotation_CeaseAndDesistGoodbye";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.CeaseAndDesistGoodbye.unsubscribe_debt_log_entry_id
   */
  readonly unsubscribe_debt_log_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.DisputeApproved
 */
export interface IExecutor_EmailAnnotation_DisputeApproved {
  readonly __typename?: "executor_EmailAnnotation_DisputeApproved";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.DisputeApproved.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.DisputeReceived
 */
export interface IExecutor_EmailAnnotation_DisputeReceived {
  readonly __typename?: "executor_EmailAnnotation_DisputeReceived";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.DisputeReceived.claim_id
   */
  readonly claim_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.DisputeReceived.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.DisputeRejected
 */
export interface IExecutor_EmailAnnotation_DisputeRejected {
  readonly __typename?: "executor_EmailAnnotation_DisputeRejected";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.DisputeRejected.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.EmailAddressConsent
 */
export interface IExecutor_EmailAnnotation_EmailAddressConsent {
  readonly __typename?: "executor_EmailAnnotation_EmailAddressConsent";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.EmailAddressConsent.email_id
   */
  readonly email_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.EmailAddressConsent.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.EmailAddressVerification
 */
export interface IExecutor_EmailAnnotation_EmailAddressVerification {
  readonly __typename?: "executor_EmailAnnotation_EmailAddressVerification";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.EmailAddressVerification.email_id
   */
  readonly email_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.EmailAddressVerification.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.PaidInFull
 */
export interface IExecutor_EmailAnnotation_PaidInFull {
  readonly __typename?: "executor_EmailAnnotation_PaidInFull";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.PaidInFull.last_payment_debt_log_entry_id
   */
  readonly last_payment_debt_log_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.PaymentThankYou
 */
export interface IExecutor_EmailAnnotation_PaymentThankYou {
  readonly __typename?: "executor_EmailAnnotation_PaymentThankYou";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.PaymentThankYou.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.PaymentThankYou.payment_entry_id
   */
  readonly payment_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.RiskyPaymentInfo
 */
export interface IExecutor_EmailAnnotation_RiskyPaymentInfo {
  readonly __typename?: "executor_EmailAnnotation_RiskyPaymentInfo";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.RiskyPaymentInfo.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.RiskyPaymentInfo.id
   */
  readonly id?: Maybe<Scalars["ID"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.SettledInFull
 */
export interface IExecutor_EmailAnnotation_SettledInFull {
  readonly __typename?: "executor_EmailAnnotation_SettledInFull";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.SettledInFull.last_payment_debt_log_entry_id
   */
  readonly last_payment_debt_log_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.TemplateSequenceInformation
 */
export interface IExecutor_EmailAnnotation_TemplateSequenceInformation {
  readonly __typename?: "executor_EmailAnnotation_TemplateSequenceInformation";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.TemplateSequenceInformation.sequence_index
   */
  readonly sequence_index?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.TemplateSequenceInformation.sequence_name
   */
  readonly sequence_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailAnnotation.TotalToCollectAdjustment
 */
export interface IExecutor_EmailAnnotation_TotalToCollectAdjustment {
  readonly __typename?: "executor_EmailAnnotation_TotalToCollectAdjustment";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.EmailAnnotation.TotalToCollectAdjustment.total_to_collect_adjustment_log_entry_id
   */
  readonly total_to_collect_adjustment_log_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailIpSubPoolEnum.EmailIpSubPool
 */
export enum IExecutor_EmailIpSubPoolEnum_EmailIpSubPool {
  DefaultSubPool = "DEFAULT_SUB_POOL",
  SubPool_2 = "SUB_POOL_2"
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.EmailServiceProviderEnum.EmailServiceProvider
 */
export enum IExecutor_EmailServiceProviderEnum_EmailServiceProvider {
  Mailgun = "MAILGUN",
  Mandrill = "MANDRILL",
  Pepipost = "PEPIPOST",
  Sendgrid = "SENDGRID",
  Sparkpost = "SPARKPOST"
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.HtmlLikeOutputFormat
 */
export interface IExecutor_HtmlLikeOutputFormat {
  readonly __typename?: "executor_HtmlLikeOutputFormat";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.HtmlLikeOutputFormat.format
   */
  readonly format?: Maybe<IExecutor_HtmlLikeOutputFormat_HtmlLike | "%future added value">;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.HtmlLikeOutputFormat.HtmlLike
 */
export enum IExecutor_HtmlLikeOutputFormat_HtmlLike {
  Html = "HTML"
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.LetterAnnotation
 */
export interface IExecutor_LetterAnnotation {
  readonly __typename?: "executor_LetterAnnotation";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.LetterAnnotation.skip_compliance_check
   */
  readonly skip_compliance_check?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.MultipleDeliveryHints
 */
export interface IExecutor_MultipleDeliveryHints {
  readonly __typename?: "executor_MultipleDeliveryHints";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.MultipleDeliveryHints.delivery_hints
   */
  readonly delivery_hints: ReadonlyArray<Maybe<IExecutor_DeliveryHints>>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.PdfLikeOutputFormat
 */
export interface IExecutor_PdfLikeOutputFormat {
  readonly __typename?: "executor_PdfLikeOutputFormat";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.PdfLikeOutputFormat.format
   */
  readonly format?: Maybe<IExecutor_PdfLikeOutputFormat_PdfLike | "%future added value">;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.PdfLikeOutputFormat.PdfLike
 */
export enum IExecutor_PdfLikeOutputFormat_PdfLike {
  ChromePdf = "CHROME_PDF",
  WkHtmlToPdf = "WK_HTML_TO_PDF"
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.PhoneNumber
 */
export interface IExecutor_PhoneNumber {
  readonly __typename?: "executor_PhoneNumber";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.PhoneNumber.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.PhoneNumber.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.RequestHeader
 */
export interface IExecutor_RequestHeader {
  readonly __typename?: "executor_RequestHeader";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.RequestHeader.communication_intent_id
   */
  readonly communication_intent_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.RequestHeader.request_id
   */
  readonly request_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.RequestHeader.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendEmailRequest
 */
export interface IExecutor_SendEmailRequest {
  readonly __typename?: "executor_SendEmailRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.UNUSED_overrides
   */
  readonly UNUSED_overrides?: Maybe<IExecutor_SendEmailRequest_Overrides>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.content_html
   */
  readonly content_html?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.content_text
   */
  readonly content_text?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.delivery_hints
   */
  readonly delivery_hints?: Maybe<IExecutor_DeliveryHints>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.email_template_id
   */
  readonly email_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.header
   */
  readonly header?: Maybe<IExecutor_RequestHeader>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.metrics
   */
  readonly metrics?: Maybe<IExecutor_CommunicatorMetrics>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.model_request
   */
  readonly model_request?: Maybe<ITemplate_View_Model_Requests_TemplateViewModelRequest>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.multiple_delivery_hints
   */
  readonly multiple_delivery_hints?: Maybe<IExecutor_MultipleDeliveryHints>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.output_format
   */
  readonly output_format?: Maybe<IExecutor_HtmlLikeOutputFormat>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.priority
   */
  readonly priority?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.recipient
   */
  readonly recipient?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.render_only
   */
  readonly render_only?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.response
   */
  readonly response?: Maybe<IExecutor_SendEmailResponse>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.sender
   */
  readonly sender?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendEmailRequest.Overrides
 */
export interface IExecutor_SendEmailRequest_Overrides {
  readonly __typename?: "executor_SendEmailRequest_Overrides";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.Overrides.DEPRECATED_markup_template_id
   */
  readonly DEPRECATED_markup_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailRequest.Overrides.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendEmailResponse
 */
export interface IExecutor_SendEmailResponse {
  readonly __typename?: "executor_SendEmailResponse";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.effective_delivery_hints
   */
  readonly effective_delivery_hints?: Maybe<IExecutor_DeliveryHints>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.effective_email_service_provider
   */
  readonly effective_email_service_provider?: Maybe<
    IExecutor_EmailServiceProviderEnum_EmailServiceProvider | "%future added value"
  >;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.email_template_id
   */
  readonly email_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.external_send_failed
   */
  readonly external_send_failed?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.mailgun_id
   */
  readonly mailgun_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.mandrill_id
   */
  readonly mandrill_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.rendered_content_s3_path
   */
  readonly rendered_content_s3_path?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.spark_post_id
   */
  readonly spark_post_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.variation_experiment_context
   */
  readonly variation_experiment_context?: Maybe<IExperiment_Out_ExperimentContext>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailResponse.webhook_payload
   */
  readonly webhook_payload?: Maybe<IExecutor_WebhookPayload>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendEmailToDebtorRequest
 */
export interface IExecutor_SendEmailToDebtorRequest {
  readonly __typename?: "executor_SendEmailToDebtorRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.UNUSED_overrides
   */
  readonly UNUSED_overrides?: Maybe<IExecutor_SendEmailToDebtorRequest_Overrides>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.annotation
   */
  readonly annotation?: Maybe<IExecutor_EmailAnnotation>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.delivery_hints
   */
  readonly delivery_hints?: Maybe<IExecutor_DeliveryHints>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.deprecated_subject
   */
  readonly deprecated_subject?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.email_template_id
   */
  readonly email_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.header
   */
  readonly header?: Maybe<IExecutor_RequestHeader>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.metrics
   */
  readonly metrics?: Maybe<IExecutor_CommunicatorMetrics>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.model_request
   */
  readonly model_request?: Maybe<ITemplate_View_Model_Requests_TemplateViewModelRequest>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.multiple_delivery_hints
   */
  readonly multiple_delivery_hints?: Maybe<IExecutor_MultipleDeliveryHints>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.output_format
   */
  readonly output_format?: Maybe<IExecutor_HtmlLikeOutputFormat>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.priority
   */
  readonly priority?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.recipient
   */
  readonly recipient?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.render_only
   */
  readonly render_only?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.response
   */
  readonly response?: Maybe<IExecutor_SendEmailResponse>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.sender
   */
  readonly sender?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.shorten_pay_url
   */
  readonly shorten_pay_url?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.skip_compliance_check
   */
  readonly skip_compliance_check?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendEmailToDebtorRequest.Overrides
 */
export interface IExecutor_SendEmailToDebtorRequest_Overrides {
  readonly __typename?: "executor_SendEmailToDebtorRequest_Overrides";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.Overrides.DEPRECATED_markup_template_id
   */
  readonly DEPRECATED_markup_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.Overrides.page_template_id
   */
  readonly page_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToDebtorRequest.Overrides.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendEmailToSupportRequest
 */
export interface IExecutor_SendEmailToSupportRequest {
  readonly __typename?: "executor_SendEmailToSupportRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToSupportRequest.data
   */
  readonly data?: Maybe<IExecutor_ContactFormData>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToSupportRequest.has_lead_contacted_us_before
   */
  readonly has_lead_contacted_us_before?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToSupportRequest.log_request
   */
  readonly log_request?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToSupportRequest.render_only
   */
  readonly render_only?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToSupportRequest.response
   */
  readonly response?: Maybe<IExecutor_SendEmailResponse>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToSupportRequest.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendEmailToSupportRequest.template_name
   */
  readonly template_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest
 */
export interface IExecutor_SendLeadSignUpEmailRequest {
  readonly __typename?: "executor_SendLeadSignUpEmailRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest.lead_type
   */
  readonly lead_type?: Maybe<IExecutor_SendLeadSignUpEmailRequest_LeadType | "%future added value">;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest.recipient
   */
  readonly recipient?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest.related_debts
   */
  readonly related_debts: ReadonlyArray<Maybe<IExecutor_DebtDescription>>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest.render_only
   */
  readonly render_only?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest.sender
   */
  readonly sender?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest.template_name
   */
  readonly template_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendLeadSignUpEmailRequest.LeadType
 */
export enum IExecutor_SendLeadSignUpEmailRequest_LeadType {
  Business = "BUSINESS",
  Customer = "CUSTOMER"
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendLetterResponse
 */
export interface IExecutor_SendLetterResponse {
  readonly __typename?: "executor_SendLetterResponse";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterResponse.letter_id
   */
  readonly letter_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterResponse.letter_template_id
   */
  readonly letter_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterResponse.rendered_content_s3_path
   */
  readonly rendered_content_s3_path?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendLetterToDebtorRequest
 */
export interface IExecutor_SendLetterToDebtorRequest {
  readonly __typename?: "executor_SendLetterToDebtorRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.annotation
   */
  readonly annotation?: Maybe<IExecutor_LetterAnnotation>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.creditor_letterhead_request
   */
  readonly creditor_letterhead_request?: Maybe<IExecutor_SendLetterToDebtorRequest_CreditorLetterheadRequest>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.header
   */
  readonly header?: Maybe<IExecutor_RequestHeader>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.letter_template_id
   */
  readonly letter_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.metrics
   */
  readonly metrics?: Maybe<IExecutor_CommunicatorMetrics>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.model_request
   */
  readonly model_request?: Maybe<ITemplate_View_Model_Requests_TemplateViewModelRequest>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.output_format
   */
  readonly output_format?: Maybe<IExecutor_PdfLikeOutputFormat>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.recipient
   */
  readonly recipient?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.response
   */
  readonly response?: Maybe<IExecutor_SendLetterResponse>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.sender
   */
  readonly sender?: Maybe<IPostalAddress>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendLetterToDebtorRequest.CreditorLetterheadRequest
 */
export interface IExecutor_SendLetterToDebtorRequest_CreditorLetterheadRequest {
  readonly __typename?: "executor_SendLetterToDebtorRequest_CreditorLetterheadRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendLetterToDebtorRequest.CreditorLetterheadRequest.template_type
   */
  readonly template_type?: Maybe<
    ITemplates_TemplateAnnotation_SoftFilters_TemplateType | "%future added value"
  >;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest
 */
export interface IExecutor_SendPaymentThankYouEmailRequest {
  readonly __typename?: "executor_SendPaymentThankYouEmailRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest.amount
   */
  readonly amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest.model_request
   */
  readonly model_request?: Maybe<ITemplate_View_Model_Requests_TemplateViewModelRequest>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest.recipient
   */
  readonly recipient?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest.render_only
   */
  readonly render_only?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest.response
   */
  readonly response?: Maybe<IExecutor_SendEmailResponse>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest.sender
   */
  readonly sender?: Maybe<IEmailAddressWithName>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPaymentThankYouEmailRequest.template_name
   */
  readonly template_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendPushNotificationResponse
 */
export interface IExecutor_SendPushNotificationResponse {
  readonly __typename?: "executor_SendPushNotificationResponse";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPushNotificationResponse.external_send_failed
   */
  readonly external_send_failed?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendPushNotificationToDebtorRequest
 */
export interface IExecutor_SendPushNotificationToDebtorRequest {
  readonly __typename?: "executor_SendPushNotificationToDebtorRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPushNotificationToDebtorRequest.header
   */
  readonly header?: Maybe<IExecutor_RequestHeader>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPushNotificationToDebtorRequest.metrics
   */
  readonly metrics?: Maybe<IExecutor_CommunicatorMetrics>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPushNotificationToDebtorRequest.model_request
   */
  readonly model_request?: Maybe<ITemplate_View_Model_Requests_TemplateViewModelRequest>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPushNotificationToDebtorRequest.push_notification_template_id
   */
  readonly push_notification_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPushNotificationToDebtorRequest.recipient
   */
  readonly recipient?: Maybe<IPush_Notifications_PushNotificationEndpoint>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendPushNotificationToDebtorRequest.response
   */
  readonly response?: Maybe<IExecutor_SendPushNotificationResponse>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendSMSToDebtorRequest
 */
export interface IExecutor_SendSmsToDebtorRequest {
  readonly __typename?: "executor_SendSMSToDebtorRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.disregardWhitelist
   */
  readonly disregardWhitelist?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.header
   */
  readonly header?: Maybe<IExecutor_RequestHeader>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.metrics
   */
  readonly metrics?: Maybe<IExecutor_CommunicatorMetrics>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.model_request
   */
  readonly model_request?: Maybe<ITemplate_View_Model_Requests_TemplateViewModelRequest>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.output_format
   */
  readonly output_format?: Maybe<IExecutor_HtmlLikeOutputFormat>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.priority
   */
  readonly priority?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.recipient
   */
  readonly recipient?: Maybe<IExecutor_PhoneNumber>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.render_only
   */
  readonly render_only?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.response
   */
  readonly response?: Maybe<IExecutor_SendSmsResponse>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.sender
   */
  readonly sender?: Maybe<IExecutor_PhoneNumber>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.shorten_pay_url
   */
  readonly shorten_pay_url?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.skip_rate_limiter
   */
  readonly skip_rate_limiter?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.sms_body
   */
  readonly sms_body?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSMSToDebtorRequest.sms_template_id
   */
  readonly sms_template_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendSmsResponse
 */
export interface IExecutor_SendSmsResponse {
  readonly __typename?: "executor_SendSmsResponse";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSmsResponse.fragment_count
   */
  readonly fragment_count?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSmsResponse.sms_template_id
   */
  readonly sms_template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSmsResponse.twilio_error
   */
  readonly twilio_error?: Maybe<ITwilio_MessageError>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendSmsResponse.twilio_response
   */
  readonly twilio_response?: Maybe<ITwilio_MessageResponse>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendVoicemailRequest
 */
export interface IExecutor_SendVoicemailRequest {
  readonly __typename?: "executor_SendVoicemailRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailRequest.header
   */
  readonly header?: Maybe<IExecutor_RequestHeader>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailRequest.metrics
   */
  readonly metrics?: Maybe<IExecutor_CommunicatorMetrics>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailRequest.model_request
   */
  readonly model_request?: Maybe<ITemplate_View_Model_Requests_TemplateViewModelRequest>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailRequest.recipient
   */
  readonly recipient?: Maybe<IExecutor_PhoneNumber>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailRequest.response
   */
  readonly response?: Maybe<IExecutor_SendVoicemailResponse>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailRequest.sender
   */
  readonly sender?: Maybe<IExecutor_PhoneNumber>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailRequest.voicemail_template_id
   */
  readonly voicemail_template_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendVoicemailResponse
 */
export interface IExecutor_SendVoicemailResponse {
  readonly __typename?: "executor_SendVoicemailResponse";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailResponse.slybroadcast_session_id
   */
  readonly slybroadcast_session_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailResponse.voapps_response
   */
  readonly voapps_response?: Maybe<IExecutor_SendVoicemailResponse_VoAppsResponse>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailResponse.voicemail_template_id
   */
  readonly voicemail_template_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendVoicemailResponse.VoAppsResponse
 */
export interface IExecutor_SendVoicemailResponse_VoAppsResponse {
  readonly __typename?: "executor_SendVoicemailResponse_VoAppsResponse";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailResponse.VoAppsResponse.campaign_id
   */
  readonly campaign_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailResponse.VoAppsResponse.message_id
   */
  readonly message_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendVoicemailResponse.VoAppsResponse.record_id
   */
  readonly record_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest
 */
export interface IExecutor_SendWarmTransferToDebtorRequest {
  readonly __typename?: "executor_SendWarmTransferToDebtorRequest";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.call_type
   */
  readonly call_type?: Maybe<IExecutor_CreateCallRequest_CallType | "%future added value">;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.expiration_timestamp
   */
  readonly expiration_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.header
   */
  readonly header?: Maybe<IExecutor_RequestHeader>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.leave_voicemail_allowed
   */
  readonly leave_voicemail_allowed?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.metrics
   */
  readonly metrics?: Maybe<IExecutor_CommunicatorMetrics>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.priority
   */
  readonly priority?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.rate_limiter_action_id
   */
  readonly rate_limiter_action_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.recipient
   */
  readonly recipient?: Maybe<IExecutor_PhoneNumber>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.response
   */
  readonly response?: Maybe<Scalars["executor_SendWarmTransferResponse"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.teg
   */
  readonly teg?: Maybe<IExecutor_SendWarmTransferToDebtorRequest_Teg>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.Teg
 */
export interface IExecutor_SendWarmTransferToDebtorRequest_Teg {
  readonly __typename?: "executor_SendWarmTransferToDebtorRequest_Teg";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.SendWarmTransferToDebtorRequest.Teg.external_id
   */
  readonly external_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.WebhookPayload
 */
export interface IExecutor_WebhookPayload {
  readonly __typename?: "executor_WebhookPayload";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.WebhookPayload.creditor_log_entry_id
   */
  readonly creditor_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.WebhookPayload.debt_log_entry_id
   */
  readonly debt_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.WebhookPayload.true_accord_engage_communication_activity_entry_id
   */
  readonly true_accord_engage_communication_activity_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.WebhookPayload.true_accord_retain_gateway_entry
   */
  readonly true_accord_retain_gateway_entry?: Maybe<IExecutor_WebhookPayload_TrueAccordRetainEmailGatewayEntry>;
}

/**
 * File: collectr/proto/executor.proto
 * com.trueaccord.proto.executor.WebhookPayload.TrueAccordRetainEmailGatewayEntry
 */
export interface IExecutor_WebhookPayload_TrueAccordRetainEmailGatewayEntry {
  readonly __typename?: "executor_WebhookPayload_TrueAccordRetainEmailGatewayEntry";
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.WebhookPayload.TrueAccordRetainEmailGatewayEntry.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/executor.proto
   * com.trueaccord.proto.executor.WebhookPayload.TrueAccordRetainEmailGatewayEntry.gateway_id
   */
  readonly gateway_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/experiment_out.proto
 * com.trueaccord.proto.experiment_out.ExperimentContext
 */
export interface IExperiment_Out_ExperimentContext {
  readonly __typename?: "experiment_out_ExperimentContext";
  /**
   * File: collectr/proto/experiment_out.proto
   * com.trueaccord.proto.experiment_out.ExperimentContext.experiment_group_id
   */
  readonly experiment_group_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/experiment_out.proto
   * com.trueaccord.proto.experiment_out.ExperimentContext.experiment_id
   */
  readonly experiment_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/fct.proto
 * com.trueaccord.proto.fct.FCT
 */
export interface IFct_Fct {
  readonly __typename?: "fct_FCT";
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.as_of_date
   */
  readonly as_of_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.full_time_employees
   */
  readonly full_time_employees?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.max_new_business
   */
  readonly max_new_business?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.month_to_date_collections
   */
  readonly month_to_date_collections?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.new_business_forecast
   */
  readonly new_business_forecast?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.old_business_forecast
   */
  readonly old_business_forecast?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.placement_type
   */
  readonly placement_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.postdates_remaining
   */
  readonly postdates_remaining?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.servicer_id
   */
  readonly servicer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.special_servicing_type
   */
  readonly special_servicing_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.total_forecast
   */
  readonly total_forecast?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCT.total_month_to_date_collections
   */
  readonly total_month_to_date_collections?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/management_systems/resurgent/fct.proto
 * com.trueaccord.proto.fct.FCTRecords
 */
export interface IFct_FctRecords {
  readonly __typename?: "fct_FCTRecords";
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCTRecords.batch_id
   */
  readonly batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCTRecords.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCTRecords.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/management_systems/resurgent/fct.proto
   * com.trueaccord.proto.fct.FCTRecords.records
   */
  readonly records: ReadonlyArray<Maybe<IFct_Fct>>;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile
 */
export interface IImporter_Profile {
  readonly __typename?: "importer_Profile";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.add_debts_if_possible
   */
  readonly add_debts_if_possible?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.allow_missing_street_address
   */
  readonly allow_missing_street_address?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.default_country_code
   */
  readonly default_country_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.default_date_format
   */
  readonly default_date_format?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.fields
   */
  readonly fields?: Maybe<IImporter_Profile_Fields>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.is_initial_principal_before_payments_and_credits
   */
  readonly is_initial_principal_before_payments_and_credits?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.itemization_date_format
   */
  readonly itemization_date_format?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.last_payment_date_format
   */
  readonly last_payment_date_format?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.out_of_statute_date_format
   */
  readonly out_of_statute_date_format?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.requires_approval
   */
  readonly requires_approval?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.requires_manual_run
   */
  readonly requires_manual_run?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile.Fields
 */
export interface IImporter_Profile_Fields {
  readonly __typename?: "importer_Profile_Fields";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.addresses
   */
  readonly addresses: ReadonlyArray<Maybe<IImporter_Profile_Fields_Address>>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.biller
   */
  readonly biller?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.business
   */
  readonly business?: Maybe<IImporter_Profile_Fields_Business>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.charge_off_information
   */
  readonly charge_off_information?: Maybe<IImporter_Profile_Fields_ChargeOffInformation>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.creditor_name
   */
  readonly creditor_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.debt_comments
   */
  readonly debt_comments: ReadonlyArray<Maybe<IImporter_Profile_Fields_Comment>>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.debtor_comments
   */
  readonly debtor_comments: ReadonlyArray<Maybe<IImporter_Profile_Fields_Comment>>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.default_date
   */
  readonly default_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.emails
   */
  readonly emails: ReadonlyArray<Maybe<IImporter_Profile_Fields_Email>>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.fee
   */
  readonly fee?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.interest
   */
  readonly interest?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.itemization_date
   */
  readonly itemization_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.last_payment_date
   */
  readonly last_payment_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.names
   */
  readonly names: ReadonlyArray<Maybe<IImporter_Profile_Fields_Name>>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.original_creditor_account_number
   */
  readonly original_creditor_account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.original_creditor_name
   */
  readonly original_creditor_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.out_of_statute_date
   */
  readonly out_of_statute_date?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.payments_and_credits
   */
  readonly payments_and_credits?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.phone_numbers
   */
  readonly phone_numbers: ReadonlyArray<Maybe<IImporter_Profile_Fields_Phone>>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.principal
   */
  readonly principal?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.product
   */
  readonly product?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.reference
   */
  readonly reference?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.social_security_number
   */
  readonly social_security_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.transaction_id
   */
  readonly transaction_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile.Fields.Address
 */
export interface IImporter_Profile_Fields_Address {
  readonly __typename?: "importer_Profile_Fields_Address";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.address1
   */
  readonly address1?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.address2
   */
  readonly address2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.city
   */
  readonly city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.country
   */
  readonly country?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.country_code
   */
  readonly country_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.full_address
   */
  readonly full_address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.type
   */
  readonly type?: Maybe<IPostalAddress_Type | "%future added value">;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Address.zip_code
   */
  readonly zip_code?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile.Fields.Business
 */
export interface IImporter_Profile_Fields_Business {
  readonly __typename?: "importer_Profile_Fields_Business";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Business.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Business.url
   */
  readonly url?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile.Fields.ChargeOffInformation
 */
export interface IImporter_Profile_Fields_ChargeOffInformation {
  readonly __typename?: "importer_Profile_Fields_ChargeOffInformation";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.ChargeOffInformation.balance_at_charge_off
   */
  readonly balance_at_charge_off?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.ChargeOffInformation.fees_accrued_since_charge_off
   */
  readonly fees_accrued_since_charge_off?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.ChargeOffInformation.interest_accrued_since_charge_off
   */
  readonly interest_accrued_since_charge_off?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.ChargeOffInformation.total_paid_since_charge_off
   */
  readonly total_paid_since_charge_off?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile.Fields.Comment
 */
export interface IImporter_Profile_Fields_Comment {
  readonly __typename?: "importer_Profile_Fields_Comment";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Comment.comment
   */
  readonly comment?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile.Fields.Email
 */
export interface IImporter_Profile_Fields_Email {
  readonly __typename?: "importer_Profile_Fields_Email";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Email.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Email.type
   */
  readonly type?: Maybe<IEmailAddress_Type | "%future added value">;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile.Fields.Name
 */
export interface IImporter_Profile_Fields_Name {
  readonly __typename?: "importer_Profile_Fields_Name";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Name.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Name.full_name
   */
  readonly full_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Name.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Name.middle_name
   */
  readonly middle_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/importer.proto
 * com.trueaccord.proto.importer.Profile.Fields.Phone
 */
export interface IImporter_Profile_Fields_Phone {
  readonly __typename?: "importer_Profile_Fields_Phone";
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Phone.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/importer.proto
   * com.trueaccord.proto.importer.Profile.Fields.Phone.type
   */
  readonly type?: Maybe<IPhoneNumber_Type | "%future added value">;
}

export enum IJoin__Graph {
  Camunda = "CAMUNDA",
  Recover = "RECOVER"
}

/**
 * File: collectr/proto/management_systems/brand_mapping.proto
 * com.trueaccord.proto.management_systems.BrandMapping.Mapping
 */
export interface IManagement_Systems_BrandMapping_Mapping {
  readonly __typename?: "management_systems_BrandMapping_Mapping";
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.Mapping.DEPRECATED_stanza
   */
  readonly DEPRECATED_stanza?: Maybe<
    IManagement_Systems_BrandMapping_Mapping_Deprecated_Stanza | "%future added value"
  >;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.Mapping.UNUSED_merchant_field_value
   */
  readonly UNUSED_merchant_field_value?: Maybe<IManagement_Systems_BrandMapping_Mapping_FieldValue>;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.Mapping.UNUSED_substituted_merchant_name
   */
  readonly UNUSED_substituted_merchant_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.Mapping.merchant_field_reference
   */
  readonly merchant_field_reference?: Maybe<
    IManagement_Systems_BrandMapping_Mapping_FieldReference | "%future added value"
  >;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.Mapping.original_creditor_field_reference
   */
  readonly original_creditor_field_reference?: Maybe<
    IManagement_Systems_BrandMapping_Mapping_FieldReference | "%future added value"
  >;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.Mapping.original_creditor_field_value
   */
  readonly original_creditor_field_value?: Maybe<IManagement_Systems_BrandMapping_Mapping_FieldValue>;
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.Mapping.product_type
   */
  readonly product_type?: Maybe<ICreditor_Brand_BrandDetails_ProductType | "%future added value">;
}

/**
 * File: collectr/proto/management_systems/brand_mapping.proto
 * com.trueaccord.proto.management_systems.BrandMapping.Mapping.DEPRECATED_Stanza
 */
export enum IManagement_Systems_BrandMapping_Mapping_Deprecated_Stanza {
  OriginalCreditorIssuerOfYourMerchantCard = "ORIGINAL_CREDITOR_ISSUER_OF_YOUR_MERCHANT_CARD",
  OriginalCreditorServicedByMerchant = "ORIGINAL_CREDITOR_SERVICED_BY_MERCHANT",
  UnknownStanza = "UNKNOWN_STANZA"
}

/**
 * File: collectr/proto/management_systems/brand_mapping.proto
 * com.trueaccord.proto.management_systems.BrandMapping.Mapping.FieldReference
 */
export enum IManagement_Systems_BrandMapping_Mapping_FieldReference {
  Field_1 = "FIELD_1",
  Field_2 = "FIELD_2",
  Field_3 = "FIELD_3",
  Field_4 = "FIELD_4",
  Field_5 = "FIELD_5",
  Field_6 = "FIELD_6",
  Field_7 = "FIELD_7",
  Field_8 = "FIELD_8",
  Field_9 = "FIELD_9",
  UnknownField = "UNKNOWN_FIELD"
}

/**
 * File: collectr/proto/management_systems/brand_mapping.proto
 * com.trueaccord.proto.management_systems.BrandMapping.Mapping.FieldValue
 */
export interface IManagement_Systems_BrandMapping_Mapping_FieldValue {
  readonly __typename?: "management_systems_BrandMapping_Mapping_FieldValue";
  /**
   * File: collectr/proto/management_systems/brand_mapping.proto
   * com.trueaccord.proto.management_systems.BrandMapping.Mapping.FieldValue.specific_value
   */
  readonly specific_value?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/export.proto
 * com.trueaccord.proto.management_systems.export.ExportBatch
 */
export interface IManagement_Systems_Export_ExportBatch {
  readonly __typename?: "management_systems_export_ExportBatch";
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.resurgent_fct
   */
  readonly resurgent_fct?: Maybe<IManagement_Systems_Export_ExportBatch_ResurgentFct>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.resurgent_pa2
   */
  readonly resurgent_pa2?: Maybe<IManagement_Systems_Export_ExportBatch_ResurgentPa2>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.resurgent_suf
   */
  readonly resurgent_suf?: Maybe<IManagement_Systems_Export_ExportBatch_ResurgentSuf>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.status
   */
  readonly status?: Maybe<IManagement_Systems_Export_ExportBatch_Status | "%future added value">;
}

/**
 * File: collectr/proto/management_systems/export.proto
 * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentFCT
 */
export interface IManagement_Systems_Export_ExportBatch_ResurgentFct {
  readonly __typename?: "management_systems_export_ExportBatch_ResurgentFCT";
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentFCT.file_id
   */
  readonly file_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentFCT.frequency
   */
  readonly frequency?: Maybe<IManagement_Systems_Export_Frequency | "%future added value">;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentFCT.read_up_to_timestamp
   */
  readonly read_up_to_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentFCT.target_filename
   */
  readonly target_filename?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/export.proto
 * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentPA2
 */
export interface IManagement_Systems_Export_ExportBatch_ResurgentPa2 {
  readonly __typename?: "management_systems_export_ExportBatch_ResurgentPA2";
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentPA2.file_id
   */
  readonly file_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentPA2.frequency
   */
  readonly frequency?: Maybe<IManagement_Systems_Export_Frequency | "%future added value">;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentPA2.read_up_to_timestamp
   */
  readonly read_up_to_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentPA2.target_filename
   */
  readonly target_filename?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/export.proto
 * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentSUF
 */
export interface IManagement_Systems_Export_ExportBatch_ResurgentSuf {
  readonly __typename?: "management_systems_export_ExportBatch_ResurgentSUF";
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentSUF.file_id
   */
  readonly file_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentSUF.frequency
   */
  readonly frequency?: Maybe<IManagement_Systems_Export_Frequency | "%future added value">;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentSUF.read_up_to_timestamp
   */
  readonly read_up_to_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/export.proto
   * com.trueaccord.proto.management_systems.export.ExportBatch.ResurgentSUF.target_filename
   */
  readonly target_filename?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/export.proto
 * com.trueaccord.proto.management_systems.export.ExportBatch.Status
 */
export enum IManagement_Systems_Export_ExportBatch_Status {
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  ReadyForShipping = "READY_FOR_SHIPPING",
  Shipped = "SHIPPED"
}

/**
 * File: collectr/proto/management_systems/export.proto
 * com.trueaccord.proto.management_systems.export.Frequency
 */
export enum IManagement_Systems_Export_Frequency {
  AdHoc = "AD_HOC",
  Daily = "DAILY",
  Monthly = "MONTHLY",
  Weekly = "WEEKLY"
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.ALT
 */
export interface IManagement_Systems_Resurgent_Alt {
  readonly __typename?: "management_systems_resurgent_ALT";
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.address
   */
  readonly address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.address2
   */
  readonly address2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.alternative_contact_type_id
   */
  readonly alternative_contact_type_id?: Maybe<
    IManagement_Systems_Resurgent_AlternativeContactTypeId | "%future added value"
  >;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.city
   */
  readonly city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.name
   */
  readonly name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.notification_date
   */
  readonly notification_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.phone_contact
   */
  readonly phone_contact?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.wrong_contact_alternative_address
   */
  readonly wrong_contact_alternative_address?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.wrong_contact_alternative_phone
   */
  readonly wrong_contact_alternative_phone?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.ALT.zip
   */
  readonly zip?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/account_type.proto
 * com.trueaccord.proto.management_systems.resurgent.AccountType
 */
export enum IManagement_Systems_Resurgent_AccountType {
  AccountTypeOther = "ACCOUNT_TYPE_OTHER",
  Auto = "AUTO",
  Backcard = "BACKCARD",
  Commercial = "COMMERCIAL",
  Heloc = "HELOC",
  Installment = "INSTALLMENT",
  Medical = "MEDICAL",
  OverdraftAccount = "OVERDRAFT_ACCOUNT",
  RealEstate = "REAL_ESTATE",
  Retail = "RETAIL",
  StudentLoan = "STUDENT_LOAN",
  Telecomm = "TELECOMM"
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.AlternativeContactTypeId
 */
export enum IManagement_Systems_Resurgent_AlternativeContactTypeId {
  Attorney = "ATTORNEY",
  AuthorizedRepresentative = "AUTHORIZED_REPRESENTATIVE",
  DebtorInformationAsOfEndOf_2013 = "DEBTOR_INFORMATION_AS_OF_END_OF_2013",
  DebtorInformationAtTimeOfChargeOff = "DEBTOR_INFORMATION_AT_TIME_OF_CHARGE_OFF",
  DebtSettlementAgency = "DEBT_SETTLEMENT_AGENCY",
  Employer = "EMPLOYER",
  ExecutorOfEstate = "EXECUTOR_OF_ESTATE",
  ExtraEmployer = "EXTRA_EMPLOYER",
  Guardian = "GUARDIAN",
  PowerOfAttorney = "POWER_OF_ATTORNEY",
  PowerOfAttorneyLimited = "POWER_OF_ATTORNEY_LIMITED",
  ProbateCourt = "PROBATE_COURT"
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.BKY
 */
export interface IManagement_Systems_Resurgent_Bky {
  readonly __typename?: "management_systems_resurgent_BKY";
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BKY.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BKY.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BKY.bankruptcy_case_number
   */
  readonly bankruptcy_case_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BKY.bankruptcy_chapter
   */
  readonly bankruptcy_chapter?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BKY.bankruptcy_file_date
   */
  readonly bankruptcy_file_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BKY.bankruptcy_status
   */
  readonly bankruptcy_status?: Maybe<IManagement_Systems_Resurgent_BankruptcyStatus | "%future added value">;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BKY.bankruptcy_status_date
   */
  readonly bankruptcy_status_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BKY.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.BWR
 */
export interface IManagement_Systems_Resurgent_Bwr {
  readonly __typename?: "management_systems_resurgent_BWR";
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.address
   */
  readonly address?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.address2
   */
  readonly address2?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.bank_name
   */
  readonly bank_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.borrower_sequence_number
   */
  readonly borrower_sequence_number?: Maybe<
    IManagement_Systems_Resurgent_BorrowerSequenceNumber | "%future added value"
  >;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.cell_phone
   */
  readonly cell_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.city
   */
  readonly city?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.date_of_birth
   */
  readonly date_of_birth?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.employment_status
   */
  readonly employment_status?: Maybe<IManagement_Systems_Resurgent_EmploymentStatus | "%future added value">;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.first_name
   */
  readonly first_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.home_phone
   */
  readonly home_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.language_code
   */
  readonly language_code?: Maybe<IManagement_Systems_Resurgent_LanguageCode | "%future added value">;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.last_name
   */
  readonly last_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.other_phone
   */
  readonly other_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.ssn
   */
  readonly ssn?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.state
   */
  readonly state?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.verified_home_owner
   */
  readonly verified_home_owner?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.work_phone
   */
  readonly work_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.wrong_contact_address
   */
  readonly wrong_contact_address?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.wrong_contact_cell_phone
   */
  readonly wrong_contact_cell_phone?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.wrong_contact_home_phone
   */
  readonly wrong_contact_home_phone?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.wrong_contact_other_phone
   */
  readonly wrong_contact_other_phone?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.wrong_contact_work_phone
   */
  readonly wrong_contact_work_phone?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.BWR.zip
   */
  readonly zip?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/bankruptcy_status.proto
 * com.trueaccord.proto.management_systems.resurgent.BankruptcyStatus
 */
export enum IManagement_Systems_Resurgent_BankruptcyStatus {
  Converted = "CONVERTED",
  Discharged = "DISCHARGED",
  Dismissed = "DISMISSED",
  Filed = "FILED"
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.BorrowerSequenceNumber
 */
export enum IManagement_Systems_Resurgent_BorrowerSequenceNumber {
  Borrower = "BORROWER",
  CoBorrower = "CO_BORROWER"
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.CollectionStatus
 */
export enum IManagement_Systems_Resurgent_CollectionStatus {
  AccountInReceivership = "ACCOUNT_IN_RECEIVERSHIP",
  Active = "ACTIVE",
  AllEffortsExhausted = "ALL_EFFORTS_EXHAUSTED",
  BankruptcyFiled = "BANKRUPTCY_FILED",
  CeaseAndDesist = "CEASE_AND_DESIST",
  ConsumerCounseledAccount = "CONSUMER_COUNSELED_ACCOUNT",
  CustomerRetainedAttorney = "CUSTOMER_RETAINED_ATTORNEY",
  DebtSettlementArrangement = "DEBT_SETTLEMENT_ARRANGEMENT",
  DebtSettlementArrangementDrop = "DEBT_SETTLEMENT_ARRANGEMENT_DROP",
  Deceased = "DECEASED",
  DeceasedAndProbateClaimFiled = "DECEASED_AND_PROBATE_CLAIM_FILED",
  HotLeadAccts = "HOT_LEAD_ACCTS",
  PaidInFull = "PAID_IN_FULL",
  PaymentPlan = "PAYMENT_PLAN",
  PostdatedCheckPlan = "POSTDATED_CHECK_PLAN",
  PromiseToPay = "PROMISE_TO_PAY",
  SettledInFull = "SETTLED_IN_FULL",
  SkipAccounts = "SKIP_ACCOUNTS",
  VerbalDispute = "VERBAL_DISPUTE",
  WrittenDisputeOrFraud = "WRITTEN_DISPUTE_OR_FRAUD"
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.DEC
 */
export interface IManagement_Systems_Resurgent_Dec {
  readonly __typename?: "management_systems_resurgent_DEC";
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.DEC.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.DEC.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.DEC.borrower_sequence_number
   */
  readonly borrower_sequence_number?: Maybe<
    IManagement_Systems_Resurgent_BorrowerSequenceNumber | "%future added value"
  >;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.DEC.date_of_death
   */
  readonly date_of_death?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/base.proto
   * com.trueaccord.proto.management_systems.resurgent.DEC.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.EmploymentStatus
 */
export enum IManagement_Systems_Resurgent_EmploymentStatus {
  FullTime = "FULL_TIME",
  PartTime = "PART_TIME",
  Retired = "RETIRED",
  SelfEmployed = "SELF_EMPLOYED",
  SocialSecurityIncome = "SOCIAL_SECURITY_INCOME",
  Unemployed = "UNEMPLOYED"
}

/**
 * File: collectr/proto/management_systems/resurgent/base.proto
 * com.trueaccord.proto.management_systems.resurgent.LanguageCode
 */
export enum IManagement_Systems_Resurgent_LanguageCode {
  African = "AFRICAN",
  Afrikaans = "AFRIKAANS",
  Albanian = "ALBANIAN",
  Aleut = "ALEUT",
  AmericanIndian = "AMERICAN_INDIAN",
  Amharic = "AMHARIC",
  Arabic = "ARABIC",
  Arawakian = "ARAWAKIAN",
  Armenian = "ARMENIAN",
  Assamese = "ASSAMESE",
  Azerbaijani = "AZERBAIJANI",
  Aztecan = "AZTECAN",
  Balinese = "BALINESE",
  Balochi = "BALOCHI",
  Bantu = "BANTU",
  Basque = "BASQUE",
  Bengali = "BENGALI",
  Berber = "BERBER",
  Bielorussian = "BIELORUSSIAN",
  Bihari = "BIHARI",
  Bisayan = "BISAYAN",
  BrailleEnglish = "BRAILLE_ENGLISH",
  BrailleSpanish = "BRAILLE_SPANISH",
  Bulgarian = "BULGARIAN",
  Burmese = "BURMESE",
  Cajun = "CAJUN",
  Cantonese = "CANTONESE",
  Catalonian = "CATALONIAN",
  Caucasian = "CAUCASIAN",
  Chadic = "CHADIC",
  Chamorro = "CHAMORRO",
  Cherokee = "CHEROKEE",
  Chibchan = "CHIBCHAN",
  Chinese = "CHINESE",
  Choctaw = "CHOCTAW",
  Comanche = "COMANCHE",
  Cree = "CREE",
  Croatian = "CROATIAN",
  Cushite = "CUSHITE",
  Czech = "CZECH",
  Danish = "DANISH",
  Delaware = "DELAWARE",
  DeltaRiverYuman = "DELTA_RIVER_YUMAN",
  Dravidian = "DRAVIDIAN",
  Dutch = "DUTCH",
  Efik = "EFIK",
  English = "ENGLISH",
  Estonian = "ESTONIAN",
  Fijian = "FIJIAN",
  Finnish = "FINNISH",
  Formosan = "FORMOSAN",
  French = "FRENCH",
  FrenchCreole = "FRENCH_CREOLE",
  Frisian = "FRISIAN",
  Fuchow = "FUCHOW",
  Fulani = "FULANI",
  German = "GERMAN",
  Gondi = "GONDI",
  Greek = "GREEK",
  Gujarati = "GUJARATI",
  Gullah = "GULLAH",
  Gur = "GUR",
  Hakka = "HAKKA",
  Han = "HAN",
  Hawaiian = "HAWAIIAN",
  HearingImpairedEnglish = "HEARING_IMPAIRED_ENGLISH",
  HearingImpairedSpanish = "HEARING_IMPAIRED_SPANISH",
  Hebrew = "HEBREW",
  Hidatsa = "HIDATSA",
  Hindi = "HINDI",
  Hmong = "HMONG",
  Hungarian = "HUNGARIAN",
  Icelandic = "ICELANDIC",
  Ilocano = "ILOCANO",
  IndiaNec3 = "INDIA_NEC3",
  Indonesian = "INDONESIAN",
  IrishGaelic = "IRISH_GAELIC",
  Italian = "ITALIAN",
  JamaicanCreole = "JAMAICAN_CREOLE",
  Japanese = "JAPANESE",
  Javanese = "JAVANESE",
  Kannada = "KANNADA",
  Karachay = "KARACHAY",
  Karen = "KAREN",
  Karok = "KAROK",
  Kashmiri = "KASHMIRI",
  Kazakh = "KAZAKH",
  Keres = "KERES",
  Kirghiz = "KIRGHIZ",
  Korean = "KOREAN",
  Krio = "KRIO",
  KruIboYoruba = "KRU_IBO_YORUBA",
  Kuchin = "KUCHIN",
  Kurdish = "KURDISH",
  Kusaiean = "KUSAIEAN",
  Ladino = "LADINO",
  LanguageCodeOther = "LANGUAGE_CODE_OTHER",
  Laotian = "LAOTIAN",
  LargePrintEnglish = "LARGE_PRINT_ENGLISH",
  LargePrintSpanish = "LARGE_PRINT_SPANISH",
  Latvian = "LATVIAN",
  Lithuanian = "LITHUANIAN",
  Luxembourgian = "LUXEMBOURGIAN",
  Macedonian = "MACEDONIAN",
  Malagasy = "MALAGASY",
  Malay = "MALAY",
  Malayalam = "MALAYALAM",
  Mandarin = "MANDARIN",
  Mande = "MANDE",
  Marathi = "MARATHI",
  Marshallese = "MARSHALLESE",
  MayanLanguages = "MAYAN_LANGUAGES",
  MbumAndRelated = "MBUM_AND_RELATED",
  Melanesian = "MELANESIAN",
  Micmac = "MICMAC",
  Micronesian = "MICRONESIAN",
  Mien = "MIEN",
  Mohawk = "MOHAWK",
  Mongolian = "MONGOLIAN",
  MonKhmerCambodian = "MON_KHMER_CAMBODIAN",
  Munda = "MUNDA",
  Muskogee = "MUSKOGEE",
  Navajo = "NAVAJO",
  Nepali = "NEPALI",
  Nilotic = "NILOTIC",
  NiloHamitic = "NILO_HAMITIC",
  NiloSaharan = "NILO_SAHARAN",
  Norwegian = "NORWEGIAN",
  Ojibwa = "OJIBWA",
  Okanogan = "OKANOGAN",
  Oriya = "ORIYA",
  Ossete = "OSSETE",
  OtoManguen = "OTO_MANGUEN",
  Ottawa = "OTTAWA",
  Paiute = "PAIUTE",
  PakistanNec3 = "PAKISTAN_NEC3",
  Palau = "PALAU",
  Pampangan = "PAMPANGAN",
  Pangasinan = "PANGASINAN",
  Panjabi = "PANJABI",
  PapiaMentae = "PAPIA_MENTAE",
  Pashto = "PASHTO",
  Patois = "PATOIS",
  PennsylvaniaDutch = "PENNSYLVANIA_DUTCH",
  Persian = "PERSIAN",
  Pidgin = "PIDGIN",
  Pima = "PIMA",
  Polish = "POLISH",
  Portuguese = "PORTUGUESE",
  Potawatomi = "POTAWATOMI",
  Quechua = "QUECHUA",
  Rajasthani = "RAJASTHANI",
  Romanian = "ROMANIAN",
  Romany = "ROMANY",
  Russian = "RUSSIAN",
  Samoan = "SAMOAN",
  Santiam = "SANTIAM",
  Saramacca = "SARAMACCA",
  ScotticGaelic = "SCOTTIC_GAELIC",
  Sebuano = "SEBUANO",
  Seneca = "SENECA",
  Serbian = "SERBIAN",
  Serbocroatian = "SERBOCROATIAN",
  Shawnee = "SHAWNEE",
  Sindhi = "SINDHI",
  Sinhalese = "SINHALESE",
  Slovak = "SLOVAK",
  Slovene = "SLOVENE",
  Spanish = "SPANISH",
  SpeechImpairedEnglish = "SPEECH_IMPAIRED_ENGLISH",
  SpeechImpairedSpanish = "SPEECH_IMPAIRED_SPANISH",
  Sudanic = "SUDANIC",
  Swahili = "SWAHILI",
  Swedish = "SWEDISH",
  Syriac = "SYRIAC",
  Tadzhik = "TADZHIK",
  Tagalog = "TAGALOG",
  Tamil = "TAMIL",
  Tanana = "TANANA",
  Telugu = "TELUGU",
  Tewa = "TEWA",
  Thai = "THAI",
  Tibetan = "TIBETAN",
  Tiwa = "TIWA",
  Tongan = "TONGAN",
  Trukese = "TRUKESE",
  Tungus = "TUNGUS",
  TupiGuarani = "TUPI_GUARANI",
  Turkish = "TURKISH",
  Tuscarora = "TUSCARORA",
  Uighur = "UIGHUR",
  Ukrainian = "UKRAINIAN",
  Urdu = "URDU",
  Vietnamese = "VIETNAMESE",
  Walapai = "WALAPAI",
  Welsh = "WELSH",
  Wintun = "WINTUN",
  Wu = "WU",
  Yiddish = "YIDDISH",
  Yuma = "YUMA",
  Yupik = "YUPIK"
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.ABL
 */
export interface IManagement_Systems_Resurgent_Suf_Abl {
  readonly __typename?: "management_systems_resurgent_suf_ABL";
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.attorney_fee_balance
   */
  readonly attorney_fee_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.cost_balance
   */
  readonly cost_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.interest_balance
   */
  readonly interest_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.last_payment_amount
   */
  readonly last_payment_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.last_payment_date
   */
  readonly last_payment_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.principal_balance
   */
  readonly principal_balance?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.ABL.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.CCA
 */
export interface IManagement_Systems_Resurgent_Suf_Cca {
  readonly __typename?: "management_systems_resurgent_suf_CCA";
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.CCA.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.CCA.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.CCA.cca_agency_name
   */
  readonly cca_agency_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.CCA.cca_agency_phone
   */
  readonly cca_agency_phone?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.CCA.cca_proposal_date
   */
  readonly cca_proposal_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.CCA.proposed_amount
   */
  readonly proposed_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.CCA.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.KPI
 */
export interface IManagement_Systems_Resurgent_Suf_Kpi {
  readonly __typename?: "management_systems_resurgent_suf_KPI";
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.date_of_last_sif_offered
   */
  readonly date_of_last_sif_offered?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.last_sif_offered
   */
  readonly last_sif_offered?: Maybe<Scalars["Float"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.number_of_call_attempts
   */
  readonly number_of_call_attempts?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.number_of_connects
   */
  readonly number_of_connects?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.number_of_letters
   */
  readonly number_of_letters?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.number_of_right_party_contacts
   */
  readonly number_of_right_party_contacts?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.KPI.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.PDC
 */
export interface IManagement_Systems_Resurgent_Suf_Pdc {
  readonly __typename?: "management_systems_resurgent_suf_PDC";
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.ach_account_type
   */
  readonly ach_account_type?: Maybe<
    IManagement_Systems_Resurgent_Suf_Pdc_AchAccountType | "%future added value"
  >;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.bank_account_number
   */
  readonly bank_account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.bank_routing_number
   */
  readonly bank_routing_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.credit_card_account_type
   */
  readonly credit_card_account_type?: Maybe<
    IManagement_Systems_Resurgent_Suf_Pdc_CcAccountType | "%future added value"
  >;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.payment_amount
   */
  readonly payment_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.payment_scheduled_date
   */
  readonly payment_scheduled_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.payment_type
   */
  readonly payment_type?: Maybe<IManagement_Systems_Resurgent_Suf_Pdc_PaymentType | "%future added value">;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PDC.standard_entry_class_code
   */
  readonly standard_entry_class_code?: Maybe<
    IManagement_Systems_Resurgent_Suf_Pdc_StandardEntryClassCode | "%future added value"
  >;
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.PDC.AchAccountType
 */
export enum IManagement_Systems_Resurgent_Suf_Pdc_AchAccountType {
  PersonalChecking = "PERSONAL_CHECKING",
  PersonalSavings = "PERSONAL_SAVINGS"
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.PDC.CcAccountType
 */
export enum IManagement_Systems_Resurgent_Suf_Pdc_CcAccountType {
  AmericanExpress = "AMERICAN_EXPRESS",
  Discover = "DISCOVER",
  MasterCard = "MASTER_CARD",
  Visa = "VISA"
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.PDC.PaymentType
 */
export enum IManagement_Systems_Resurgent_Suf_Pdc_PaymentType {
  Ach = "ACH",
  CreditCard = "CREDIT_CARD",
  DebitCard = "DEBIT_CARD"
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.PDC.StandardEntryClassCode
 */
export enum IManagement_Systems_Resurgent_Suf_Pdc_StandardEntryClassCode {
  InternetPayment = "INTERNET_PAYMENT",
  TelephonePayment = "TELEPHONE_PAYMENT"
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.PRB
 */
export interface IManagement_Systems_Resurgent_Suf_Prb {
  readonly __typename?: "management_systems_resurgent_suf_PRB";
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PRB.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PRB.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PRB.probate_bar_date
   */
  readonly probate_bar_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PRB.probate_case_number
   */
  readonly probate_case_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PRB.probate_file_date
   */
  readonly probate_file_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.PRB.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords
 */
export interface IManagement_Systems_Resurgent_Suf_SufRecords {
  readonly __typename?: "management_systems_resurgent_suf_SUFRecords";
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.batch_id
   */
  readonly batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.wrapped_records
   */
  readonly wrapped_records: ReadonlyArray<Maybe<IManagement_Systems_Resurgent_Suf_SufRecords_Wrapper>>;
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper
 */
export interface IManagement_Systems_Resurgent_Suf_SufRecords_Wrapper {
  readonly __typename?: "management_systems_resurgent_suf_SUFRecords_Wrapper";
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.abl
   */
  readonly abl?: Maybe<IManagement_Systems_Resurgent_Suf_Abl>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.alt
   */
  readonly alt?: Maybe<IManagement_Systems_Resurgent_Alt>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.bky
   */
  readonly bky?: Maybe<IManagement_Systems_Resurgent_Bky>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.bwr
   */
  readonly bwr?: Maybe<IManagement_Systems_Resurgent_Bwr>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.cca
   */
  readonly cca?: Maybe<IManagement_Systems_Resurgent_Suf_Cca>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.dec
   */
  readonly dec?: Maybe<IManagement_Systems_Resurgent_Dec>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.kpi
   */
  readonly kpi?: Maybe<IManagement_Systems_Resurgent_Suf_Kpi>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.pdc
   */
  readonly pdc?: Maybe<IManagement_Systems_Resurgent_Suf_Pdc>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.prb
   */
  readonly prb?: Maybe<IManagement_Systems_Resurgent_Suf_Prb>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.SUFRecords.Wrapper.wor
   */
  readonly wor?: Maybe<IManagement_Systems_Resurgent_Suf_Wor>;
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.WOR
 */
export interface IManagement_Systems_Resurgent_Suf_Wor {
  readonly __typename?: "management_systems_resurgent_suf_WOR";
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.close_and_return
   */
  readonly close_and_return?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.collection_status
   */
  readonly collection_status?: Maybe<IManagement_Systems_Resurgent_CollectionStatus | "%future added value">;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.commission_rate
   */
  readonly commission_rate?: Maybe<Scalars["Float"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.last_call_date
   */
  readonly last_call_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.last_glb_notice_date
   */
  readonly last_glb_notice_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.last_letter_date
   */
  readonly last_letter_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.last_skip_date
   */
  readonly last_skip_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.other_assets
   */
  readonly other_assets?: Maybe<IManagement_Systems_Resurgent_Suf_Wor_OtherAssets | "%future added value">;
  /**
   * File: collectr/proto/management_systems/resurgent/suf.proto
   * com.trueaccord.proto.management_systems.resurgent.suf.WOR.record_type
   */
  readonly record_type?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/suf.proto
 * com.trueaccord.proto.management_systems.resurgent.suf.WOR.OtherAssets
 */
export enum IManagement_Systems_Resurgent_Suf_Wor_OtherAssets {
  Auto = "AUTO",
  Boat = "BOAT",
  Motorcycle = "MOTORCYCLE",
  Rv = "RV"
}

/**
 * File: collectr/proto/management_systems/resurgent/pa2.proto
 * com.trueaccord.proto.pa2.PA2
 */
export interface IPa2_Pa2 {
  readonly __typename?: "pa2_PA2";
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.account_id
   */
  readonly account_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.account_number
   */
  readonly account_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.agency_transaction_id
   */
  readonly agency_transaction_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.check_number
   */
  readonly check_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.commission_amount
   */
  readonly commission_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.placement_batch_id
   */
  readonly placement_batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.reference_text
   */
  readonly reference_text?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.transaction_amount
   */
  readonly transaction_amount?: Maybe<IMonetaryAmount>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.transaction_code
   */
  readonly transaction_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2.transaction_date
   */
  readonly transaction_date?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/management_systems/resurgent/pa2.proto
 * com.trueaccord.proto.pa2.PA2Records
 */
export interface IPa2_Pa2Records {
  readonly __typename?: "pa2_PA2Records";
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2Records.batch_id
   */
  readonly batch_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2Records.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2Records.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/management_systems/resurgent/pa2.proto
   * com.trueaccord.proto.pa2.PA2Records.records
   */
  readonly records: ReadonlyArray<Maybe<IPa2_Pa2>>;
}

/**
 * File: collectr/proto/pump.proto
 * com.trueaccord.proto.pump.PumpTask
 */
export interface IPump_PumpTask {
  readonly __typename?: "pump_PumpTask";
  /**
   * File: collectr/proto/pump.proto
   * com.trueaccord.proto.pump.PumpTask.desired_return_timestamp
   */
  readonly desired_return_timestamp?: Maybe<Scalars["Long"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/pump.proto
   * com.trueaccord.proto.pump.PumpTask.last_timestamp
   */
  readonly last_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/pump.proto
   * com.trueaccord.proto.pump.PumpTask.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/pump.proto
   * com.trueaccord.proto.pump.PumpTask.next_timestamp
   */
  readonly next_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/pump.proto
   * com.trueaccord.proto.pump.PumpTask.priority_level
   */
  readonly priority_level?: Maybe<IPump_PumpTask_PriorityLevel | "%future added value">;
  /**
   * File: collectr/proto/pump.proto
   * com.trueaccord.proto.pump.PumpTask.scheduling_reason
   */
  readonly scheduling_reason?: Maybe<IPump_PumpTask_SchedulingReason | "%future added value">;
}

/**
 * File: collectr/proto/pump.proto
 * com.trueaccord.proto.pump.PumpTask.PriorityLevel
 */
export enum IPump_PumpTask_PriorityLevel {
  EmailEvent = "EMAIL_EVENT",
  Reactive = "REACTIVE",
  Staggered = "STAGGERED",
  Unprioritized = "UNPRIORITIZED"
}

/**
 * File: collectr/proto/pump.proto
 * com.trueaccord.proto.pump.PumpTask.SchedulingReason
 */
export enum IPump_PumpTask_SchedulingReason {
  AcknowledgedInQueue = "ACKNOWLEDGED_IN_QUEUE",
  DefaultSchedulingReason = "DEFAULT_SCHEDULING_REASON",
  RetryWriteToQueue = "RETRY_WRITE_TO_QUEUE",
  WrittenToQueue = "WRITTEN_TO_QUEUE"
}

/**
 * File: collectr/proto/push_notifications.proto
 * com.trueaccord.proto.push_notifications.PushNotificationEndpoint
 */
export interface IPush_Notifications_PushNotificationEndpoint {
  readonly __typename?: "push_notifications_PushNotificationEndpoint";
  /**
   * File: collectr/proto/push_notifications.proto
   * com.trueaccord.proto.push_notifications.PushNotificationEndpoint.endpoint
   */
  readonly endpoint?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/risky_payment.proto
 * com.trueaccord.proto.risky_payment.RiskyPayment
 */
export interface IRisky_Payment_RiskyPayment {
  readonly __typename?: "risky_payment_RiskyPayment";
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.bank_account_out
   */
  readonly bank_account_out?: Maybe<IUsBankAccount>;
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.context
   */
  readonly context?: Maybe<IRisky_Payment_RiskyPayment_Context | "%future added value">;
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.decision
   */
  readonly decision?: Maybe<IRisky_Payment_RiskyPayment_Decision>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.name_on_payment_form
   */
  readonly name_on_payment_form?: Maybe<IPersonName>;
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.transaction_amount
   */
  readonly transaction_amount?: Maybe<IMonetaryAmount>;
}

/**
 * File: collectr/proto/risky_payment.proto
 * com.trueaccord.proto.risky_payment.RiskyPayment.Context
 */
export enum IRisky_Payment_RiskyPayment_Context {
  DeferredPlan = "DEFERRED_PLAN",
  Payment = "PAYMENT",
  StandardPlan = "STANDARD_PLAN"
}

/**
 * File: collectr/proto/risky_payment.proto
 * com.trueaccord.proto.risky_payment.RiskyPayment.Decision
 */
export interface IRisky_Payment_RiskyPayment_Decision {
  readonly __typename?: "risky_payment_RiskyPayment_Decision";
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.Decision.outcome
   */
  readonly outcome?: Maybe<IRisky_Payment_RiskyPayment_Decision_Outcome | "%future added value">;
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.Decision.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/risky_payment.proto
   * com.trueaccord.proto.risky_payment.RiskyPayment.Decision.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/risky_payment.proto
 * com.trueaccord.proto.risky_payment.RiskyPayment.Decision.Outcome
 */
export enum IRisky_Payment_RiskyPayment_Decision_Outcome {
  Approved = "APPROVED",
  Denied = "DENIED"
}

/**
 * File: collectr/proto/task_runner/task_runner.proto
 * com.trueaccord.proto.task_runner.CascadePdpReportRequest
 */
export interface ITask_Runner_CascadePdpReportRequest {
  readonly __typename?: "task_runner_CascadePdpReportRequest";
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.CascadePdpReportRequest.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.CascadePdpReportRequest.file_name
   */
  readonly file_name?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.CascadePdpReportRequest.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.CascadePdpReportRequest.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/task_runner/task_runner.proto
 * com.trueaccord.proto.task_runner.DebtorCommunicationsRequest
 */
export interface ITask_Runner_DebtorCommunicationsRequest {
  readonly __typename?: "task_runner_DebtorCommunicationsRequest";
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.DebtorCommunicationsRequest.communication_types
   */
  readonly communication_types: ReadonlyArray<
    Maybe<ICommunications_Header_CommunicationType | "%future added value">
  >;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.DebtorCommunicationsRequest.creditor_customer_id
   */
  readonly creditor_customer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.DebtorCommunicationsRequest.end_timestamp
   */
  readonly end_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.DebtorCommunicationsRequest.start_timestamp
   */
  readonly start_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.DebtorCommunicationsRequest.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/task_runner/task_runner.proto
 * com.trueaccord.proto.task_runner.RenderContentRequest
 */
export interface ITask_Runner_RenderContentRequest {
  readonly __typename?: "task_runner_RenderContentRequest";
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.RenderContentRequest.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.RenderContentRequest.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.RenderContentRequest.template_informations
   */
  readonly template_informations: ReadonlyArray<Maybe<ITask_Runner_RenderContentRequest_TemplateInformation>>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.RenderContentRequest.timestamp
   */
  readonly timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.RenderContentRequest.user_account_id
   */
  readonly user_account_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/task_runner/task_runner.proto
 * com.trueaccord.proto.task_runner.RenderContentRequest.TemplateInformation
 */
export interface ITask_Runner_RenderContentRequest_TemplateInformation {
  readonly __typename?: "task_runner_RenderContentRequest_TemplateInformation";
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.RenderContentRequest.TemplateInformation.content_type
   */
  readonly content_type?: Maybe<ITemplates_TemplateEntryContainer_ContentType | "%future added value">;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.RenderContentRequest.TemplateInformation.template_id
   */
  readonly template_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/task_runner/task_runner.proto
   * com.trueaccord.proto.task_runner.RenderContentRequest.TemplateInformation.template_name
   */
  readonly template_name?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/teg_event.proto
 * com.trueaccord.proto.teg_event.TegEvent
 */
export interface ITeg_Event_TegEvent {
  readonly __typename?: "teg_event_TegEvent";
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.agent_id
   */
  readonly agent_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.agent_notes
   */
  readonly agent_notes?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.call_length
   */
  readonly call_length?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.call_result
   */
  readonly call_result?: Maybe<IWarm_Transfers_CallResult>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.call_timestamp
   */
  readonly call_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.callback_phone_number
   */
  readonly callback_phone_number?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.callback_timestamp
   */
  readonly callback_timestamp?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.context
   */
  readonly context?: Maybe<ITeg_Event_TegEvent_Context>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.non_connect_result
   */
  readonly non_connect_result?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.recording
   */
  readonly recording?: Maybe<ITeg_Event_TegEvent_Recording>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.recording_url
   */
  readonly recording_url?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.source
   */
  readonly source?: Maybe<ITeg_Event_TegEvent_Source | "%future added value">;
}

/**
 * File: collectr/proto/teg_event.proto
 * com.trueaccord.proto.teg_event.TegEvent.Context
 */
export interface ITeg_Event_TegEvent_Context {
  readonly __typename?: "teg_event_TegEvent_Context";
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.Context.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.Context.debt_log_entry_id
   */
  readonly debt_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.Context.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/teg_event.proto
 * com.trueaccord.proto.teg_event.TegEvent.Recording
 */
export interface ITeg_Event_TegEvent_Recording {
  readonly __typename?: "teg_event_TegEvent_Recording";
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegEvent.Recording.s3_path
   */
  readonly s3_path?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/teg_event.proto
 * com.trueaccord.proto.teg_event.TegEvent.Source
 */
export enum ITeg_Event_TegEvent_Source {
  Teg = "TEG",
  Trueaccord = "TRUEACCORD"
}

/**
 * File: collectr/proto/teg_event.proto
 * com.trueaccord.proto.teg_event.TegTask
 */
export interface ITeg_Event_TegTask {
  readonly __typename?: "teg_event_TegTask";
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegTask.debt_log_entry_id
   */
  readonly debt_log_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegTask.expire_request
   */
  readonly expire_request?: Maybe<ITeg_Event_TegTask_ExpireRequest>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegTask.fetch_call_recording
   */
  readonly fetch_call_recording?: Maybe<ITeg_Event_TegTask_FetchCallRecording>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegTask.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegTask.teg_event_id
   */
  readonly teg_event_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/teg_event.proto
 * com.trueaccord.proto.teg_event.TegTask.ExpireRequest
 */
export interface ITeg_Event_TegTask_ExpireRequest {
  readonly __typename?: "teg_event_TegTask_ExpireRequest";
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegTask.ExpireRequest.expiration_timestamp
   */
  readonly expiration_timestamp?: Maybe<Scalars["Long"]>;
}

/**
 * File: collectr/proto/teg_event.proto
 * com.trueaccord.proto.teg_event.TegTask.FetchCallRecording
 */
export interface ITeg_Event_TegTask_FetchCallRecording {
  readonly __typename?: "teg_event_TegTask_FetchCallRecording";
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegTask.FetchCallRecording.recording_key
   */
  readonly recording_key?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/teg_event.proto
   * com.trueaccord.proto.teg_event.TegTask.FetchCallRecording.recording_url
   */
  readonly recording_url?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/template_view_model_requests.proto
 * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest
 */
export interface ITemplate_View_Model_Requests_TemplateViewModelRequest {
  readonly __typename?: "template_view_model_requests_TemplateViewModelRequest";
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.account_activation
   */
  readonly account_activation?: Maybe<ITemplates_TemplateMessages_AccountActivation>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.collection_report
   */
  readonly collection_report?: Maybe<IRenderedCollectionReport>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.creditor_id
   */
  readonly creditor_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.dispute_id
   */
  readonly dispute_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.email_model
   */
  readonly email_model?: Maybe<ITemplates_TemplateMessages_EmailModel>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.event_id
   */
  readonly event_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.experiment_context_id
   */
  readonly experiment_context_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.heartbeat_hints
   */
  readonly heartbeat_hints?: Maybe<ITemplates_HeartbeatHints>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.installment_due_date
   */
  readonly installment_due_date?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.letter_model
   */
  readonly letter_model?: Maybe<ITemplates_TemplateMessages_LetterModel>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.part_payment_id
   */
  readonly part_payment_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.password_recovery
   */
  readonly password_recovery?: Maybe<ITemplates_TemplateMessages_PasswordRecovery>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.payment_entry_id
   */
  readonly payment_entry_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.payment_offer_id
   */
  readonly payment_offer_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.payment_plan_application_token_id
   */
  readonly payment_plan_application_token_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.payment_plan_id
   */
  readonly payment_plan_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.payment_plan_offer_id_OBSOLETE
   */
  readonly payment_plan_offer_id_OBSOLETE?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.payment_token_id
   */
  readonly payment_token_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.person_id
   */
  readonly person_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.risky_payment_id
   */
  readonly risky_payment_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.sms_model
   */
  readonly sms_model?: Maybe<ITemplates_TemplateMessages_SmsModel>;
  /**
   * File: collectr/proto/template_view_model_requests.proto
   * com.trueaccord.proto.template_view_model_requests.TemplateViewModelRequest.source
   */
  readonly source?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.HeartbeatHints
 */
export interface ITemplates_HeartbeatHints {
  readonly __typename?: "templates_HeartbeatHints";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.HeartbeatHints.email_banner
   */
  readonly email_banner?: Maybe<ITemplates_HeartbeatHints_EmailBanner | "%future added value">;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.HeartbeatHints.EmailBanner
 */
export enum ITemplates_HeartbeatHints_EmailBanner {
  DuplicateEmail = "DUPLICATE_EMAIL",
  NoBanner = "NO_BANNER"
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateAnnotation.SoftFilters.TemplateType
 */
export enum ITemplates_TemplateAnnotation_SoftFilters_TemplateType {
  AccountingOfDebtLetter = "ACCOUNTING_OF_DEBT_LETTER",
  AccountPasswordRecovery = "ACCOUNT_PASSWORD_RECOVERY",
  AccountSignUpConfirmation = "ACCOUNT_SIGN_UP_CONFIRMATION",
  AccountSummary = "ACCOUNT_SUMMARY",
  AccountSummaryDebtBuyer = "ACCOUNT_SUMMARY_DEBT_BUYER",
  AccountSummaryNonDebtBuyer = "ACCOUNT_SUMMARY_NON_DEBT_BUYER",
  AchConfirmInfo = "ACH_CONFIRM_INFO",
  AchInfoApproved = "ACH_INFO_APPROVED",
  AchInfoDenied = "ACH_INFO_DENIED",
  AchScheduled = "ACH_SCHEDULED",
  AchScheduledPif = "ACH_SCHEDULED_PIF",
  AchScheduledSif = "ACH_SCHEDULED_SIF",
  AchWillResubmitWarning = "ACH_WILL_RESUBMIT_WARNING",
  AlmostPaid = "ALMOST_PAID",
  AlmostPaidSupport = "ALMOST_PAID_SUPPORT",
  AssignBlame = "ASSIGN_BLAME",
  Bankruptcy = "BANKRUPTCY",
  BasicExperience = "BASIC_EXPERIENCE",
  BuyerIntroFlow = "BUYER_INTRO_FLOW",
  BuyerLendingPlatformIntro = "BUYER_LENDING_PLATFORM_INTRO",
  BuyerOnlineSmbLenderIntro = "BUYER_ONLINE_SMB_LENDER_INTRO",
  BuyerPplanOfferIntro = "BUYER_PPLAN_OFFER_INTRO",
  BuyerProductIntro = "BUYER_PRODUCT_INTRO",
  CannotProcessPaymentInsufficientInformation = "CANNOT_PROCESS_PAYMENT_INSUFFICIENT_INFORMATION",
  CannotProcessPaymentNoPoa = "CANNOT_PROCESS_PAYMENT_NO_POA",
  CannotProcessPaymentRetraction = "CANNOT_PROCESS_PAYMENT_RETRACTION",
  CardExpirationAlert = "CARD_EXPIRATION_ALERT",
  CeaseAndDesistGeneric = "CEASE_AND_DESIST_GENERIC",
  CeaseAndDesistTeg = "CEASE_AND_DESIST_TEG",
  Challengerejected = "CHALLENGEREJECTED",
  CollectionReport = "COLLECTION_REPORT",
  ConfirmationOfConsentToEmailDvDocs = "CONFIRMATION_OF_CONSENT_TO_EMAIL_DV_DOCS",
  ConvoFollowup = "CONVO_FOLLOWUP",
  CounterOfferFollowup = "COUNTER_OFFER_FOLLOWUP",
  CustomPlanRequestAccept = "CUSTOM_PLAN_REQUEST_ACCEPT",
  CustomPlanRequestNegotiate = "CUSTOM_PLAN_REQUEST_NEGOTIATE",
  CustomPlanRequestReject = "CUSTOM_PLAN_REQUEST_REJECT",
  Debtintroductiongeneric = "DEBTINTRODUCTIONGENERIC",
  DefaultPaymentVisitFollowup = "DEFAULT_PAYMENT_VISIT_FOLLOWUP",
  DeferredAchConfirmInfo = "DEFERRED_ACH_CONFIRM_INFO",
  DeferredAchInfoApproved = "DEFERRED_ACH_INFO_APPROVED",
  DeferredAchInfoDenied = "DEFERRED_ACH_INFO_DENIED",
  Deprecated = "DEPRECATED",
  DirectToCreditorPayment = "DIRECT_TO_CREDITOR_PAYMENT",
  DisputeDebtVerificationCreated = "DISPUTE_DEBT_VERIFICATION_CREATED",
  DisputeExpiredWhileWaitingForCustomerDocs = "DISPUTE_EXPIRED_WHILE_WAITING_FOR_CUSTOMER_DOCS",
  DisputeFollowup = "DISPUTE_FOLLOWUP",
  DisputeIdFraudInvalid = "DISPUTE_ID_FRAUD_INVALID",
  DisputeInvalid = "DISPUTE_INVALID",
  DisputeReceived = "DISPUTE_RECEIVED",
  DisputeReceivedDebtReturned = "DISPUTE_RECEIVED_DEBT_RETURNED",
  DisputeReturnedToCreditor = "DISPUTE_RETURNED_TO_CREDITOR",
  DisputeValid = "DISPUTE_VALID",
  DisputeVisitFollowup = "DISPUTE_VISIT_FOLLOWUP",
  Dissolution = "DISSOLUTION",
  Dvprovided = "DVPROVIDED",
  Dvprovidedidtheft = "DVPROVIDEDIDTHEFT",
  EmailVerificationNew = "EMAIL_VERIFICATION_NEW",
  EscFriendlyReminder = "ESC_FRIENDLY_REMINDER",
  EscStraightforwardReminder = "ESC_STRAIGHTFORWARD_REMINDER",
  EscUrgentReminder = "ESC_URGENT_REMINDER",
  ExpectCall = "EXPECT_CALL",
  ExpectLetter = "EXPECT_LETTER",
  FailedAchUpdatedPlanDetails = "FAILED_ACH_UPDATED_PLAN_DETAILS",
  FailedNonRecurringAchPayment = "FAILED_NON_RECURRING_ACH_PAYMENT",
  FailedOnPlanFollowup = "FAILED_ON_PLAN_FOLLOWUP",
  FailedPayment = "FAILED_PAYMENT",
  FailedPaymentFollowup = "FAILED_PAYMENT_FOLLOWUP",
  FailedRecurringPayment = "FAILED_RECURRING_PAYMENT",
  Failure = "FAILURE",
  FailureGroup = "FAILURE_GROUP",
  FailureGroupAfterReporting = "FAILURE_GROUP_AFTER_REPORTING",
  FinalPaymentAchFailure = "FINAL_PAYMENT_ACH_FAILURE",
  FinancialDiffVisitFollowup = "FINANCIAL_DIFF_VISIT_FOLLOWUP",
  FinancialHealth = "FINANCIAL_HEALTH",
  FinServOffer = "FIN_SERV_OFFER",
  FinServPaymentPending = "FIN_SERV_PAYMENT_PENDING",
  FinServPifConfirmation = "FIN_SERV_PIF_CONFIRMATION",
  Friendlygenericfollowup = "FRIENDLYGENERICFOLLOWUP",
  GenericSubmissionFollowup = "GENERIC_SUBMISSION_FOLLOWUP",
  GenericVisitFollowup = "GENERIC_VISIT_FOLLOWUP",
  Goodfaith = "GOODFAITH",
  HangupFollowup = "HANGUP_FOLLOWUP",
  ImportantNotices = "IMPORTANT_NOTICES",
  InitialDisclosures = "INITIAL_DISCLOSURES",
  InTheDoldrumsFlow = "IN_THE_DOLDRUMS_FLOW",
  IvrBankruptcyLawyer = "IVR_BANKRUPTCY_LAWYER",
  IvrModifyPlan = "IVR_MODIFY_PLAN",
  IvrMoreInfo = "IVR_MORE_INFO",
  IvrPayfullBalance = "IVR_PAYFULL_BALANCE",
  IvrPaymentArrangement = "IVR_PAYMENT_ARRANGEMENT",
  IvrPaymentQuestions = "IVR_PAYMENT_QUESTIONS",
  IvrSetupPplan = "IVR_SETUP_PPLAN",
  MatchPayApplied = "MATCH_PAY_APPLIED",
  MissedCallFollowup = "MISSED_CALL_FOLLOWUP",
  MoreInformation = "MORE_INFORMATION",
  NonCollectionsInformational = "NON_COLLECTIONS_INFORMATIONAL",
  NoActiveEmail = "NO_ACTIVE_EMAIL",
  NystateInitialEmail = "NYSTATE_INITIAL_EMAIL",
  NystateRequestConsent = "NYSTATE_REQUEST_CONSENT",
  Odeskdissatisfaction = "ODESKDISSATISFACTION",
  OfferSettlement = "OFFER_SETTLEMENT",
  OpenMilestone_5 = "OPEN_MILESTONE_5",
  OpenMilestone_10 = "OPEN_MILESTONE_10",
  OpenMilestone_15 = "OPEN_MILESTONE_15",
  Page = "PAGE",
  PaidInFull = "PAID_IN_FULL",
  PartialPaymentFollowup = "PARTIAL_PAYMENT_FOLLOWUP",
  PartialPaymentReactivate = "PARTIAL_PAYMENT_REACTIVATE",
  PartialPayCongrats = "PARTIAL_PAY_CONGRATS",
  Payinfulllater = "PAYINFULLLATER",
  Payinfullnow = "PAYINFULLNOW",
  Payment = "PAYMENT",
  Paymenterror = "PAYMENTERROR",
  Paymentplanaccountability = "PAYMENTPLANACCOUNTABILITY",
  Paymentplangreat = "PAYMENTPLANGREAT",
  Paymentplanlifeistough = "PAYMENTPLANLIFEISTOUGH",
  PaymentThanksUpdatedPlanDetails = "PAYMENT_THANKS_UPDATED_PLAN_DETAILS",
  PendingAchUpdatedPlanDetails = "PENDING_ACH_UPDATED_PLAN_DETAILS",
  PendingPlanRetry = "PENDING_PLAN_RETRY",
  Placatecantdoit = "PLACATECANTDOIT",
  Placatewilldoit = "PLACATEWILLDOIT",
  PostDatedCheckReceived = "POST_DATED_CHECK_RECEIVED",
  PostDvFollowup = "POST_DV_FOLLOWUP",
  PplanFollowup = "PPLAN_FOLLOWUP",
  PplanModified = "PPLAN_MODIFIED",
  PplanModifiedPlanOffer = "PPLAN_MODIFIED_PLAN_OFFER",
  PplanNewPifPlanOffer = "PPLAN_NEW_PIF_PLAN_OFFER",
  PplanNewSifPlanOffer = "PPLAN_NEW_SIF_PLAN_OFFER",
  PplanOfferContext = "PPLAN_OFFER_CONTEXT",
  PplanOfferNoContext = "PPLAN_OFFER_NO_CONTEXT",
  PplanPayment_1WeekLate = "PPLAN_PAYMENT_1_WEEK_LATE",
  PplanPayment_1WeekLateInDispute = "PPLAN_PAYMENT_1_WEEK_LATE_IN_DISPUTE",
  PplanPayment_2WeeksLate = "PPLAN_PAYMENT_2_WEEKS_LATE",
  PplanPayment_3WeeksLate = "PPLAN_PAYMENT_3_WEEKS_LATE",
  PplanPaymentDueIn_1Week = "PPLAN_PAYMENT_DUE_IN_1_WEEK",
  PplanPaymentDueIn_10Days = "PPLAN_PAYMENT_DUE_IN_10_DAYS",
  PplanPaymentDueToday = "PPLAN_PAYMENT_DUE_TODAY",
  PplanPaymentVisitFollowup = "PPLAN_PAYMENT_VISIT_FOLLOWUP",
  PplanRecurringPaymentReminder = "PPLAN_RECURRING_PAYMENT_REMINDER",
  PplanRevoked = "PPLAN_REVOKED",
  PplanSettle = "PPLAN_SETTLE",
  PplanSummary = "PPLAN_SUMMARY",
  PplanVisitFollowup = "PPLAN_VISIT_FOLLOWUP",
  PplanWelcome = "PPLAN_WELCOME",
  Predispute = "PREDISPUTE",
  PreAcknowledgment = "PRE_ACKNOWLEDGMENT",
  ProactivePromoCreditApplied = "PROACTIVE_PROMO_CREDIT_APPLIED",
  Promisefailed = "PROMISEFAILED",
  Promisein10 = "PROMISEIN10",
  Promisein20 = "PROMISEIN20",
  Promisein30 = "PROMISEIN30",
  Promisetoday = "PROMISETODAY",
  PromiseToPayFollowup = "PROMISE_TO_PAY_FOLLOWUP",
  Promotion = "PROMOTION",
  QualifierIntro = "QUALIFIER_INTRO",
  QualifierOutro = "QUALIFIER_OUTRO",
  QualifierOutroCefu = "QUALIFIER_OUTRO_CEFU",
  QualifierOutroExtended = "QUALIFIER_OUTRO_EXTENDED",
  ReachingOut = "REACHING_OUT",
  ReactiveOpens = "REACTIVE_OPENS",
  Refund = "REFUND",
  ReturnedPayment = "RETURNED_PAYMENT",
  ReturnedRecurringPayment = "RETURNED_RECURRING_PAYMENT",
  SelfSelect = "SELF_SELECT",
  SettledInFull = "SETTLED_IN_FULL",
  SettlementPaymentDropoff = "SETTLEMENT_PAYMENT_DROPOFF",
  SettlementStrategy = "SETTLEMENT_STRATEGY",
  SettlementVisitFollowup = "SETTLEMENT_VISIT_FOLLOWUP",
  SmbLenderIntro = "SMB_LENDER_INTRO",
  Spammer = "SPAMMER",
  Squarechargeback = "SQUARECHARGEBACK",
  Stripechargeback = "STRIPECHARGEBACK",
  SuccessfulNonRecurringPayment = "SUCCESSFUL_NON_RECURRING_PAYMENT",
  SuccessfulRecurringPayment = "SUCCESSFUL_RECURRING_PAYMENT",
  System = "SYSTEM",
  TargetedCampaign = "TARGETED_CAMPAIGN",
  Temporaryfinancialdifficulties = "TEMPORARYFINANCIALDIFFICULTIES",
  TotalToCollectAdjustment = "TOTAL_TO_COLLECT_ADJUSTMENT",
  UnsubscribeVisitFollowup = "UNSUBSCRIBE_VISIT_FOLLOWUP",
  V2Pp = "V2PP",
  ValidIdTheftNotification = "VALID_ID_THEFT_NOTIFICATION"
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateAnnotation.Usecase
 */
export enum ITemplates_TemplateAnnotation_Usecase {
  Consent = "CONSENT",
  Followup = "FOLLOWUP",
  Prerequisite = "PREREQUISITE",
  Proactive = "PROACTIVE",
  Reactive = "REACTIVE",
  SystemFollowup = "SYSTEM_FOLLOWUP",
  SystemInteractive = "SYSTEM_INTERACTIVE"
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateAudit
 */
export interface ITemplates_TemplateAudit {
  readonly __typename?: "templates_TemplateAudit";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.client
   */
  readonly client?: Maybe<ITemplates_TemplateAudit_ClientAudit>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.closed_timestamp
   */
  readonly closed_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.description
   */
  readonly description?: Maybe<Scalars["String"]>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.internal
   */
  readonly internal?: Maybe<ITemplates_TemplateAudit_InternalAudit>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.participants
   */
  readonly participants: ReadonlyArray<Maybe<Scalars["String"]>>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.title
   */
  readonly title?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateAudit.ClientAudit
 */
export interface ITemplates_TemplateAudit_ClientAudit {
  readonly __typename?: "templates_TemplateAudit_ClientAudit";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.ClientAudit.creator
   */
  readonly creator?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.ClientAudit.creditor_ids
   */
  readonly creditor_ids: ReadonlyArray<Maybe<Scalars["String"]>>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateAudit.InternalAudit
 */
export interface ITemplates_TemplateAudit_InternalAudit {
  readonly __typename?: "templates_TemplateAudit_InternalAudit";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateAudit.InternalAudit.creator
   */
  readonly creator?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateEntryContainer.ContentType
 */
export enum ITemplates_TemplateEntryContainer_ContentType {
  Email = "EMAIL",
  Letter = "LETTER",
  Page = "PAGE",
  PushNotification = "PUSH_NOTIFICATION",
  Sms = "SMS",
  Snippet = "SNIPPET",
  Voicemail = "VOICEMAIL"
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateMessages.AccountActivation
 */
export interface ITemplates_TemplateMessages_AccountActivation {
  readonly __typename?: "templates_TemplateMessages_AccountActivation";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.AccountActivation.confirmation_link
   */
  readonly confirmation_link?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateMessages.EmailModel
 */
export interface ITemplates_TemplateMessages_EmailModel {
  readonly __typename?: "templates_TemplateMessages_EmailModel";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.EmailModel.sender_email
   */
  readonly sender_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.EmailModel.sender_name
   */
  readonly sender_name?: Maybe<IPersonName>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.EmailModel.subject
   */
  readonly subject?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.EmailModel.urls
   */
  readonly urls?: Maybe<ITemplates_TemplateMessages_Urls>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateMessages.LetterModel
 */
export interface ITemplates_TemplateMessages_LetterModel {
  readonly __typename?: "templates_TemplateMessages_LetterModel";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.LetterModel.address_id
   */
  readonly address_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.LetterModel.sender_name
   */
  readonly sender_name?: Maybe<IPersonName>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateMessages.PasswordRecovery
 */
export interface ITemplates_TemplateMessages_PasswordRecovery {
  readonly __typename?: "templates_TemplateMessages_PasswordRecovery";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.PasswordRecovery.link
   */
  readonly link?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateMessages.SMSModel
 */
export interface ITemplates_TemplateMessages_SmsModel {
  readonly __typename?: "templates_TemplateMessages_SMSModel";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.SMSModel.urls
   */
  readonly urls?: Maybe<ITemplates_TemplateMessages_Urls>;
}

/**
 * File: collectr/proto/templates.proto
 * com.trueaccord.proto.templates.TemplateMessages.Urls
 */
export interface ITemplates_TemplateMessages_Urls {
  readonly __typename?: "templates_TemplateMessages_Urls";
  /**
   * File: collectr/proto/templates.proto
   * com.trueaccord.proto.templates.TemplateMessages.Urls.primary_action
   */
  readonly primary_action?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/twilio.proto
 * com.trueaccord.proto.twilio.MessageChunkResponse
 */
export interface ITwilio_MessageChunkResponse {
  readonly __typename?: "twilio_MessageChunkResponse";
  /**
   * File: collectr/proto/twilio.proto
   * com.trueaccord.proto.twilio.MessageChunkResponse.message_sid
   */
  readonly message_sid?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/twilio.proto
   * com.trueaccord.proto.twilio.MessageChunkResponse.message_status
   */
  readonly message_status?: Maybe<ITwilio_MessageStatus | "%future added value">;
}

/**
 * File: collectr/proto/twilio.proto
 * com.trueaccord.proto.twilio.MessageError
 */
export interface ITwilio_MessageError {
  readonly __typename?: "twilio_MessageError";
  /**
   * File: collectr/proto/twilio.proto
   * com.trueaccord.proto.twilio.MessageError.code
   */
  readonly code?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/twilio.proto
   * com.trueaccord.proto.twilio.MessageError.error_message
   */
  readonly error_message?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/twilio.proto
   * com.trueaccord.proto.twilio.MessageError.more_info
   */
  readonly more_info?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/twilio.proto
   * com.trueaccord.proto.twilio.MessageError.status
   */
  readonly status?: Maybe<Scalars["Int"]>;
}

/**
 * File: collectr/proto/twilio.proto
 * com.trueaccord.proto.twilio.MessageResponse
 */
export interface ITwilio_MessageResponse {
  readonly __typename?: "twilio_MessageResponse";
  /**
   * File: collectr/proto/twilio.proto
   * com.trueaccord.proto.twilio.MessageResponse.chunks
   */
  readonly chunks: ReadonlyArray<Maybe<ITwilio_MessageChunkResponse>>;
}

/**
 * File: collectr/proto/twilio.proto
 * com.trueaccord.proto.twilio.MessageStatus
 */
export enum ITwilio_MessageStatus {
  Accepted = "ACCEPTED",
  Delivered = "DELIVERED",
  Failed = "FAILED",
  Queued = "QUEUED",
  Received = "RECEIVED",
  Receiving = "RECEIVING",
  Sending = "SENDING",
  Sent = "SENT",
  Undelivered = "UNDELIVERED"
}

/**
 * File: collectr/proto/twilio_event.proto
 * com.trueaccord.proto.twilio_event.Context
 */
export interface ITwilio_Event_Context {
  readonly __typename?: "twilio_event_Context";
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.Context.debt_id
   */
  readonly debt_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.Context.debt_log_entry_id
   */
  readonly debt_log_entry_id?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/twilio_event.proto
 * com.trueaccord.proto.twilio_event.TwilioEvent
 */
export interface ITwilio_Event_TwilioEvent {
  readonly __typename?: "twilio_event_TwilioEvent";
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.TwilioEvent.context
   */
  readonly context?: Maybe<ITwilio_Event_Context>;
  /** FieldAlias */
  readonly id: Scalars["ID"];
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.TwilioEvent.meta
   */
  readonly meta?: Maybe<IMetaData>;
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.TwilioEvent.status
   */
  readonly status?: Maybe<ITwilio_Event_TwilioMessageStatus>;
}

/**
 * File: collectr/proto/twilio_event.proto
 * com.trueaccord.proto.twilio_event.TwilioMessageStatus
 */
export interface ITwilio_Event_TwilioMessageStatus {
  readonly __typename?: "twilio_event_TwilioMessageStatus";
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.TwilioMessageStatus.body
   */
  readonly body?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.TwilioMessageStatus.common_fields
   */
  readonly common_fields?: Maybe<ICommonInboundTransmissionFields>;
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.TwilioMessageStatus.error_code
   */
  readonly error_code?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/twilio_event.proto
   * com.trueaccord.proto.twilio_event.TwilioMessageStatus.message_status
   */
  readonly message_status?: Maybe<ITwilio_MessageStatus | "%future added value">;
}

/**
 * File: collectr/proto/unsubscribe.proto
 * com.trueaccord.proto.unsubscribe.UnsubscribeRequest
 */
export interface IUnsubscribe_UnsubscribeRequest {
  readonly __typename?: "unsubscribe_UnsubscribeRequest";
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.attorney_information
   */
  readonly attorney_information?: Maybe<IContactInformation>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.cease_and_desist
   */
  readonly cease_and_desist?: Maybe<Scalars["Int"]>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.customer_response_to_email
   */
  readonly customer_response_to_email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.reason
   */
  readonly reason?: Maybe<IUnsubscribe_UnsubscribeRequest_Reason | "%future added value">;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.request_timestamp
   */
  readonly request_timestamp?: Maybe<Scalars["Long"]>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.requested_by
   */
  readonly requested_by?: Maybe<IUnsubscribe_UnsubscribeRequest_RequestedBy | "%future added value">;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.resubscribe_channels
   */
  readonly resubscribe_channels?: Maybe<IUnsubscribe_UnsubscribeRequest_Channels>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.resubscribe_contacts
   */
  readonly resubscribe_contacts?: Maybe<IUnsubscribe_UnsubscribeRequest_Contacts>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.sms_provider
   */
  readonly sms_provider?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.source
   */
  readonly source?: Maybe<IUnsubscribe_UnsubscribeRequest_Source | "%future added value">;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.teg_event_id
   */
  readonly teg_event_id?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.unsubscribe_channels
   */
  readonly unsubscribe_channels?: Maybe<IUnsubscribe_UnsubscribeRequest_Channels>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.unsubscribe_contacts
   */
  readonly unsubscribe_contacts?: Maybe<IUnsubscribe_UnsubscribeRequest_Contacts>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.user
   */
  readonly user?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/unsubscribe.proto
 * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Channels
 */
export interface IUnsubscribe_UnsubscribeRequest_Channels {
  readonly __typename?: "unsubscribe_UnsubscribeRequest_Channels";
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Channels.channels
   */
  readonly channels: ReadonlyArray<Maybe<ICommunications_Channel_Channel | "%future added value">>;
}

/**
 * File: collectr/proto/unsubscribe.proto
 * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contact
 */
export interface IUnsubscribe_UnsubscribeRequest_Contact {
  readonly __typename?: "unsubscribe_UnsubscribeRequest_Contact";
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contact.address
   */
  readonly address?: Maybe<IPostalAddress>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contact.email
   */
  readonly email?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contact.phone
   */
  readonly phone?: Maybe<IUnsubscribe_UnsubscribeRequest_Contact_Phone>;
}

/**
 * File: collectr/proto/unsubscribe.proto
 * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contact.Phone
 */
export interface IUnsubscribe_UnsubscribeRequest_Contact_Phone {
  readonly __typename?: "unsubscribe_UnsubscribeRequest_Contact_Phone";
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contact.Phone.modes
   */
  readonly modes: ReadonlyArray<Maybe<IPhoneNumber_Mode | "%future added value">>;
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contact.Phone.phone_number
   */
  readonly phone_number?: Maybe<Scalars["String"]>;
}

/**
 * File: collectr/proto/unsubscribe.proto
 * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contacts
 */
export interface IUnsubscribe_UnsubscribeRequest_Contacts {
  readonly __typename?: "unsubscribe_UnsubscribeRequest_Contacts";
  /**
   * File: collectr/proto/unsubscribe.proto
   * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Contacts.contacts
   */
  readonly contacts: ReadonlyArray<Maybe<IUnsubscribe_UnsubscribeRequest_Contact>>;
}

/**
 * File: collectr/proto/unsubscribe.proto
 * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Reason
 */
export enum IUnsubscribe_UnsubscribeRequest_Reason {
  CeaseCommunications = "CEASE_COMMUNICATIONS",
  DifferentContactPreferred = "DIFFERENT_CONTACT_PREFERRED",
  InconvenientTimeProvided = "INCONVENIENT_TIME_PROVIDED",
  InvalidInformation = "INVALID_INFORMATION",
  Minor = "MINOR",
  ObsoleteBankruptcy = "OBSOLETE_BANKRUPTCY",
  ObsoleteDeceased = "OBSOLETE_DECEASED",
  ObsoleteIdTheft = "OBSOLETE_ID_THEFT",
  RefusalToPay = "REFUSAL_TO_PAY",
  RepresentedByAttorney = "REPRESENTED_BY_ATTORNEY",
  Unknown = "UNKNOWN",
  WorkingWithDebtConsolidator = "WORKING_WITH_DEBT_CONSOLIDATOR",
  WrongContact = "WRONG_CONTACT"
}

/**
 * File: collectr/proto/unsubscribe.proto
 * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.RequestedBy
 */
export enum IUnsubscribe_UnsubscribeRequest_RequestedBy {
  Attorney = "ATTORNEY",
  Customer = "CUSTOMER",
  DebtConsolidator = "DEBT_CONSOLIDATOR",
  None = "NONE",
  ThirdParty = "THIRD_PARTY"
}

/**
 * File: collectr/proto/unsubscribe.proto
 * com.trueaccord.proto.unsubscribe.UnsubscribeRequest.Source
 */
export enum IUnsubscribe_UnsubscribeRequest_Source {
  Creditor = "CREDITOR",
  Email = "EMAIL",
  Fax = "FAX",
  Letter = "LETTER",
  LinkInEmail = "LINK_IN_EMAIL",
  ObsoleteLandingPage = "OBSOLETE_LANDING_PAGE",
  Phone = "PHONE",
  Sms = "SMS",
  WarmTransfer = "WARM_TRANSFER"
}

/**
 * File: collectr/proto/warm_transfers.proto
 * com.trueaccord.proto.warm_transfers.CallResult
 */
export interface IWarm_Transfers_CallResult {
  readonly __typename?: "warm_transfers_CallResult";
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.answer_type
   */
  readonly answer_type?: Maybe<IWarm_Transfers_CallResult_AnswerType | "%future added value">;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.call_made
   */
  readonly call_made?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.call_rejection_reason
   */
  readonly call_rejection_reason?: Maybe<
    IWarm_Transfers_CallResult_CallRejectionReason | "%future added value"
  >;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.identity
   */
  readonly identity?: Maybe<IWarm_Transfers_CallResult_Identity | "%future added value">;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.is_number_connected
   */
  readonly is_number_connected?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.is_valid_number
   */
  readonly is_valid_number?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.raw_value
   */
  readonly raw_value?: Maybe<Scalars["String"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.right_party_contact_type
   */
  readonly right_party_contact_type?: Maybe<
    IWarm_Transfers_CallResult_RightPartyContactType | "%future added value"
  >;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.transfer_agreed_by_customer
   */
  readonly transfer_agreed_by_customer?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.transfer_attempted
   */
  readonly transfer_attempted?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.transfer_failure_reason
   */
  readonly transfer_failure_reason?: Maybe<
    IWarm_Transfers_CallResult_TransferFailureReason | "%future added value"
  >;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.transfer_offered
   */
  readonly transfer_offered?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.transfer_succeeded
   */
  readonly transfer_succeeded?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.voicemail_placement_attempted
   */
  readonly voicemail_placement_attempted?: Maybe<Scalars["Boolean"]>;
  /**
   * File: collectr/proto/warm_transfers.proto
   * com.trueaccord.proto.warm_transfers.CallResult.voicemail_placement_successful
   */
  readonly voicemail_placement_successful?: Maybe<Scalars["Boolean"]>;
}

/**
 * File: collectr/proto/warm_transfers.proto
 * com.trueaccord.proto.warm_transfers.CallResult.AnswerType
 */
export enum IWarm_Transfers_CallResult_AnswerType {
  Human = "HUMAN",
  NoAnswer = "NO_ANSWER",
  Voicemail = "VOICEMAIL"
}

/**
 * File: collectr/proto/warm_transfers.proto
 * com.trueaccord.proto.warm_transfers.CallResult.CallRejectionReason
 */
export enum IWarm_Transfers_CallResult_CallRejectionReason {
  Hangup = "HANGUP",
  IdVerificationRejection = "ID_VERIFICATION_REJECTION",
  NoRejection = "NO_REJECTION",
  TransferRejection = "TRANSFER_REJECTION",
  UnauthorizedToDiscussDebt = "UNAUTHORIZED_TO_DISCUSS_DEBT",
  UnavailableAtThatNumber = "UNAVAILABLE_AT_THAT_NUMBER",
  UnavailableToTalk = "UNAVAILABLE_TO_TALK",
  UnsubscribeAll = "UNSUBSCRIBE_ALL",
  UnsubscribeNumber = "UNSUBSCRIBE_NUMBER"
}

/**
 * File: collectr/proto/warm_transfers.proto
 * com.trueaccord.proto.warm_transfers.CallResult.Identity
 */
export enum IWarm_Transfers_CallResult_Identity {
  NotAvailable = "NOT_AVAILABLE",
  RightPartyContact = "RIGHT_PARTY_CONTACT",
  Unsure = "UNSURE",
  WrongPerson = "WRONG_PERSON"
}

/**
 * File: collectr/proto/warm_transfers.proto
 * com.trueaccord.proto.warm_transfers.CallResult.RightPartyContactType
 */
export enum IWarm_Transfers_CallResult_RightPartyContactType {
  ContactTypeBusiness = "CONTACT_TYPE_BUSINESS",
  ContactTypeNotAvailable = "CONTACT_TYPE_NOT_AVAILABLE",
  ContactTypePerson = "CONTACT_TYPE_PERSON"
}

/**
 * File: collectr/proto/warm_transfers.proto
 * com.trueaccord.proto.warm_transfers.CallResult.TransferFailureReason
 */
export enum IWarm_Transfers_CallResult_TransferFailureReason {
  CustomerHungUp = "CUSTOMER_HUNG_UP",
  EtUnavailable = "ET_UNAVAILABLE",
  NoFailure = "NO_FAILURE",
  UnknownFailure = "UNKNOWN_FAILURE"
}

export type ICommentsByObjectIdsVariables = Exact<{
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]> | Scalars["String"]>;
}>;

export type ICommentsByObjectIds = {
  readonly __typename?: "Query";
  readonly comments?:
    | ReadonlyArray<
        | {
            readonly __typename?: "Comment";
            readonly user_name?: string | null | undefined;
            readonly comment?: string | null | undefined;
            readonly internal_information?:
              | {
                  readonly __typename?: "Comment_InternalInformation";
                  readonly aim_info?:
                    | {
                        readonly __typename?: "Comment_InternalInformation_AIMInformation";
                        readonly creditor_id?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  readonly amex_information?:
                    | {
                        readonly __typename?: "Comment_InternalInformation_AmexInformation";
                        readonly flag?: boolean | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            readonly data_source?:
              | { readonly __typename?: "DataSource"; readonly type?: IDataSource_Type | null | undefined }
              | null
              | undefined;
            readonly meta?:
              | {
                  readonly __typename?: "MetaData";
                  readonly time_created?: number | null | undefined;
                  readonly id?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type IAccountViewVariables = Exact<{
  accountId: Scalars["ID"];
}>;

export type IAccountView = {
  readonly __typename?: "Query";
  readonly account?: ({ readonly __typename?: "DebtOut" } & IAccountModel) | null | undefined;
  readonly debt?: ({ readonly __typename?: "Debt" } & IDebtModel) | null | undefined;
};

export type IPersonOutQueryVariables = Exact<{
  personId: Scalars["ID"];
}>;

export type IPersonOutQuery = {
  readonly __typename?: "Query";
  readonly personOut?: ({ readonly __typename?: "PersonOut" } & IPersonOutModel) | null | undefined;
};

export type ICreditorsQueryAuthVariables = Exact<{
  creditorId: Scalars["ID"];
}>;

export type ICreditorsQueryAuth = {
  readonly __typename?: "Query";
  readonly creditor?:
    | {
        readonly __typename?: "Creditor";
        readonly creditor_settings?:
          | {
              readonly __typename?: "Creditor_CreditorSettings";
              readonly customer_call_authentication_settings?:
                | {
                    readonly __typename?: "CustomerCallAuthenticationSettings";
                    readonly criteria: ReadonlyArray<
                      | {
                          readonly __typename?: "CustomerCallAuthenticationSettings_CriterionOptions";
                          readonly options: ReadonlyArray<
                            ICustomerCallAuthenticationSettings_Criterion | null | undefined
                          >;
                        }
                      | null
                      | undefined
                    >;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ICommentsByObjectIdsCreditorNotesVariables = Exact<{
  ids?: InputMaybe<ReadonlyArray<Scalars["String"]> | Scalars["String"]>;
}>;

export type ICommentsByObjectIdsCreditorNotes = {
  readonly __typename?: "Query";
  readonly comments?:
    | ReadonlyArray<
        | {
            readonly __typename?: "Comment";
            readonly object_id?: string | null | undefined;
            readonly comment?: string | null | undefined;
            readonly comment_type?: string | null | undefined;
            readonly internal_information?:
              | {
                  readonly __typename?: "Comment_InternalInformation";
                  readonly aim_info?:
                    | {
                        readonly __typename?: "Comment_InternalInformation_AIMInformation";
                        readonly creditor_id?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  readonly amex_information?:
                    | {
                        readonly __typename?: "Comment_InternalInformation_AmexInformation";
                        readonly flag?: boolean | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            readonly data_source?:
              | { readonly __typename?: "DataSource"; readonly type?: IDataSource_Type | null | undefined }
              | null
              | undefined;
            readonly meta?:
              | {
                  readonly __typename?: "MetaData";
                  readonly time_created?: number | null | undefined;
                  readonly id?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type IUserAccountQueryVariables = Exact<{
  userAccountId: Scalars["ID"];
}>;

export type IUserAccountQuery = {
  readonly __typename?: "Query";
  readonly userAccount?:
    | {
        readonly __typename?: "auth_UserAccount";
        readonly identities: ReadonlyArray<
          | { readonly __typename?: "auth_UserIdentity"; readonly full_name?: string | null | undefined }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type IPaymentPlanOutQueryVariables = Exact<{
  planId: Scalars["ID"];
}>;

export type IPaymentPlanOutQuery = {
  readonly __typename?: "Query";
  readonly paymentPlanOut?:
    | {
        readonly __typename?: "PaymentPlanOut";
        readonly id: string;
        readonly is_recurring?: boolean | null | undefined;
        readonly next_installment?: number | null | undefined;
        readonly payment_method_info?:
          | {
              readonly __typename?: "api_PaymentMethodInfoOut";
              readonly card_info?:
                | {
                    readonly __typename?: "CardPaymentMethodInfo";
                    readonly card_type?: ICardPaymentMethodInfo_CardType | null | undefined;
                    readonly expiration_year?: string | null | undefined;
                    readonly expiration_month?: string | null | undefined;
                    readonly last_four_digits?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        readonly installments: ReadonlyArray<
          | {
              readonly __typename?: "api_PaymentPlanOut_Installment";
              readonly due_date?: number | null | undefined;
              readonly status?: IApi_PaymentPlanOut_Installment_Status | null | undefined;
              readonly installment_amount?:
                | {
                    readonly __typename?: "MonetaryAmount";
                    readonly amount?: number | null | undefined;
                    readonly currency?: string | null | undefined;
                  }
                | null
                | undefined;
              readonly amount_due?:
                | {
                    readonly __typename?: "MonetaryAmount";
                    readonly amount?: number | null | undefined;
                    readonly currency?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        readonly application?:
          | {
              readonly __typename?: "PaymentPlanApplication";
              readonly discount?:
                | {
                    readonly __typename?: "MonetaryAmount";
                    readonly amount?: number | null | undefined;
                    readonly currency?: string | null | undefined;
                  }
                | null
                | undefined;
              readonly amount_to_pay?:
                | {
                    readonly __typename?: "MonetaryAmount";
                    readonly amount?: number | null | undefined;
                    readonly currency?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ICreditorsQueryVariables = Exact<{ [key: string]: never }>;

export type ICreditorsQuery = {
  readonly __typename?: "Query";
  readonly creditors?:
    | ReadonlyArray<
        | {
            readonly __typename?: "Creditor";
            readonly id: string;
            readonly company_name?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type IBpmnViewerQueryVariables = Exact<{
  taskId: Scalars["ID"];
}>;

export type IBpmnViewerQuery = {
  readonly __typename?: "Query";
  readonly task?:
    | ({
        readonly __typename?: "camunda_Task";
        readonly processDefinition?:
          | {
              readonly __typename?: "camunda_ProcessDefinition";
              readonly diagram?: string | null | undefined;
              readonly id: string;
              readonly key?: string | null | undefined;
              readonly name?: string | null | undefined;
            }
          | null
          | undefined;
      } & ICamundaTaskModel)
    | null
    | undefined;
};

export type IDebtOutBalancesQueryVariables = Exact<{
  debtId: Scalars["ID"];
  debtorId: Scalars["ID"];
}>;

export type IDebtOutBalancesQuery = {
  readonly __typename?: "Query";
  readonly debtOutBalance?: ({ readonly __typename?: "DebtOut" } & IDebtOutBalanceModel) | null | undefined;
  readonly personOut?: ({ readonly __typename?: "PersonOut" } & IPersonOutModel) | null | undefined;
};

export type IDisputeTaskViewVariables = Exact<{
  taskId: Scalars["ID"];
  skipFileRequestReviews?: InputMaybe<Scalars["Boolean"]>;
}>;

export type IDisputeTaskView = {
  readonly __typename?: "Query";
  readonly task?: ({ readonly __typename?: "camunda_Task" } & IDisputeTaskModel) | null | undefined;
};

export type IDisputeTaskViewByBusinessKeyVariables = Exact<{
  name: Scalars["String"];
  businessKey: Scalars["String"];
  skipFileRequestReviews?: InputMaybe<Scalars["Boolean"]>;
}>;

export type IDisputeTaskViewByBusinessKey = {
  readonly __typename?: "Query";
  readonly tasks?:
    | ReadonlyArray<({ readonly __typename?: "camunda_Task" } & IDisputeTaskModel) | null | undefined>
    | null
    | undefined;
};

export type ICompleteDisputeTaskVariables = Exact<{
  taskId: Scalars["ID"];
  variables?: InputMaybe<ReadonlyArray<ICamunda_KeyValuePairInput> | ICamunda_KeyValuePairInput>;
}>;

export type ICompleteDisputeTask = {
  readonly __typename?: "Mutation";
  readonly update_camunda_Task_complete?:
    | { readonly __typename?: "camunda_ProcessInstance"; readonly id: string }
    | null
    | undefined;
};

export type IUpdateDisputeTaskVariables = Exact<{
  taskId: Scalars["ID"];
  variables?: InputMaybe<
    ReadonlyArray<InputMaybe<ICamunda_KeyValuePairInput>> | InputMaybe<ICamunda_KeyValuePairInput>
  >;
  skipFileRequestReviews?: InputMaybe<Scalars["Boolean"]>;
}>;

export type IUpdateDisputeTask = {
  readonly __typename?: "Mutation";
  readonly update_camunda_Task_update?:
    | ({ readonly __typename?: "camunda_Task" } & IDisputeTaskModel)
    | null
    | undefined;
};

export type IClaimedTasksListQueryVariables = Exact<{
  name: Scalars["String"];
  assigneeId: Scalars["String"];
  skipFileRequestReviews?: InputMaybe<Scalars["Boolean"]>;
}>;

export type IClaimedTasksListQuery = {
  readonly __typename?: "Query";
  readonly tasks?:
    | ReadonlyArray<({ readonly __typename?: "camunda_Task" } & IDisputeTaskModel) | null | undefined>
    | null
    | undefined;
};

export type ICamundaUsersQueryVariables = Exact<{
  supervisorsGroup: Scalars["ID"];
  agentsGroup: Scalars["ID"];
  internationalAgentsGroup: Scalars["ID"];
}>;

export type ICamundaUsersQuery = {
  readonly __typename?: "Query";
  readonly supervisors?:
    | ReadonlyArray<
        | ({ readonly __typename?: "camunda_User"; readonly regionRestriction: boolean } & ICamundaUserModel)
        | null
        | undefined
      >
    | null
    | undefined;
  readonly agents?:
    | ReadonlyArray<
        | ({ readonly __typename?: "camunda_User"; readonly regionRestriction: boolean } & ICamundaUserModel)
        | null
        | undefined
      >
    | null
    | undefined;
  readonly internationalAgents?:
    | ReadonlyArray<
        | ({ readonly __typename?: "camunda_User"; readonly regionRestriction: boolean } & ICamundaUserModel)
        | null
        | undefined
      >
    | null
    | undefined;
};

export type IDisputeTasksStatsQueryVariables = Exact<{
  taskName: Scalars["String"];
  forUserId?: InputMaybe<Scalars["String"]>;
  isAssigned?: InputMaybe<Scalars["Boolean"]>;
  filterJson?: InputMaybe<Scalars["String"]>;
}>;

export type IDisputeTasksStatsQuery = {
  readonly __typename?: "Query";
  readonly taskStats?:
    | { readonly __typename?: "CamundaTaskStats"; readonly count: number }
    | null
    | undefined;
};

export type IDisputeTasksListQueryVariables = Exact<{
  taskName: Scalars["String"];
  forUserId?: InputMaybe<Scalars["String"]>;
  isAssigned?: InputMaybe<Scalars["Boolean"]>;
  filterJson?: InputMaybe<Scalars["String"]>;
  firstResult: Scalars["Int"];
  maxResults: Scalars["Int"];
  skipFileRequestReviews?: InputMaybe<Scalars["Boolean"]>;
}>;

export type IDisputeTasksListQuery = {
  readonly __typename?: "Query";
  readonly tasks?:
    | ReadonlyArray<({ readonly __typename?: "camunda_Task" } & IDisputeTaskModel) | null | undefined>
    | null
    | undefined;
};

export type ISetDisputeTaskAssigneeVariables = Exact<{
  taskId: Scalars["ID"];
  assigneeId?: InputMaybe<Scalars["ID"]>;
  skipFileRequestReviews?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ISetDisputeTaskAssignee = {
  readonly __typename?: "Mutation";
  readonly update_camunda_Task_claim?:
    | ({ readonly __typename?: "camunda_Task" } & IDisputeTaskModel)
    | null
    | undefined;
};

export type ICamundaKeyValueModel = {
  readonly __typename?: "camunda_KeyValuePair";
  readonly key: string;
  readonly value: string;
  readonly valueType?: ICamunda_ValueTypeEnum | null | undefined;
};

export type ICamundaProcessInstanceModel = {
  readonly __typename?: "camunda_ProcessInstance";
  readonly id: string;
  readonly lastHistoricActivityAt?: string | null | undefined;
  readonly dueDate?: Date | null | undefined;
  readonly lastUpdateDate?: Date | null | undefined;
  readonly variables?:
    | ReadonlyArray<
        ({ readonly __typename?: "camunda_KeyValuePair" } & ICamundaKeyValueModel) | null | undefined
      >
    | null
    | undefined;
};

export type ICamundaTaskModel = {
  readonly __typename?: "camunda_Task";
  readonly id: string;
  readonly name?: string | null | undefined;
  readonly description?: string | null | undefined;
  readonly assigneeId?: string | null | undefined;
  readonly priority?: number | null | undefined;
  readonly due?: string | null | undefined;
  readonly created?: string | null | undefined;
  readonly followUp?: string | null | undefined;
  readonly formKey?: string | null | undefined;
  readonly processInstanceId: string;
  readonly dueDate?: Date | null | undefined;
  readonly allowsInternationalAgents?: boolean | null | undefined;
  readonly variables?:
    | ReadonlyArray<
        ({ readonly __typename?: "camunda_KeyValuePair" } & ICamundaKeyValueModel) | null | undefined
      >
    | null
    | undefined;
  readonly processInstance?:
    | ({ readonly __typename?: "camunda_ProcessInstance" } & ICamundaProcessInstanceModel)
    | null
    | undefined;
};

export type ICamundaUserModel = {
  readonly __typename?: "camunda_User";
  readonly id: string;
  readonly email?: string | null | undefined;
  readonly name?: string | null | undefined;
};

export type ICamundaUserModelWithRestrictions = {
  readonly __typename?: "camunda_User";
  readonly id: string;
  readonly email?: string | null | undefined;
  readonly name?: string | null | undefined;
  readonly regionRestriction: boolean;
};

export type IAccountModel = {
  readonly __typename?: "DebtOut";
  readonly id: string;
  readonly account_open_timestamp?: number | null | undefined;
  readonly expected_return_date?: number | null | undefined;
  readonly min_settlement_percent?: number | null | undefined;
  readonly payment_plan_id?: string | null | undefined;
  readonly account_number?: string | null | undefined;
  readonly status?: IApi_SimplifiedStatus | null | undefined;
  readonly balance?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly calculated_payment_offers?:
    | {
        readonly __typename?: "api_PaymentOffersOut";
        readonly available_plans: ReadonlyArray<
          | {
              readonly __typename?: "api_PaymentPlanOfferOut";
              readonly number_of_installments?: number | null | undefined;
              readonly application?:
                | {
                    readonly __typename?: "PaymentPlanApplication";
                    readonly frequency?: IFrequency | null | undefined;
                  }
                | null
                | undefined;
              readonly total_to_pay?:
                | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
                | null
                | undefined;
              readonly installment_amount?:
                | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        readonly available_settlement_offer?:
          | {
              readonly __typename?: "api_SettlementOfferOut";
              readonly settlement_percent?: number | null | undefined;
              readonly amount?:
                | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
                | null
                | undefined;
              readonly expiration_date?:
                | ({ readonly __typename?: "Date" } & IExpandedDate)
                | null
                | undefined;
            }
          | null
          | undefined;
        readonly highlighted_plan?:
          | {
              readonly __typename?: "api_PaymentPlanOfferOut";
              readonly application?:
                | {
                    readonly __typename?: "PaymentPlanApplication";
                    readonly number_of_installments?: number | null | undefined;
                    readonly frequency?: IFrequency | null | undefined;
                    readonly payment_amount?:
                      | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        readonly settlement_constraints?:
          | {
              readonly __typename?: "api_SettlementConstraints";
              readonly min?:
                | {
                    readonly __typename?: "api_SettlementConstraints_Constraint";
                    readonly percent?: number | null | undefined;
                    readonly amount?:
                      | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly effective_max_discount_percent?:
    | {
        readonly __typename?: "api_DebtOut_OverridableMaxDiscountPercent";
        readonly override_type?: IOverridableValue_OverrideType | null | undefined;
        readonly min_settlement_percent?: number | null | undefined;
      }
    | null
    | undefined;
  readonly max_payment_plan_length_in_months?:
    | { readonly __typename?: "OptionalInt32"; readonly value?: number | null | undefined }
    | null
    | undefined;
  readonly pending_risky_payment?:
    | {
        readonly __typename?: "risky_payment_RiskyPayment";
        readonly transaction_amount?:
          | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly debt?: ({ readonly __typename?: "Debt" } & IDebtModel) | null | undefined;
  readonly creditor?: ({ readonly __typename?: "api_CreditorOut" } & ICreditorOutModel) | null | undefined;
  readonly balance_details_at_placement?:
    | ({ readonly __typename?: "api_DebtOut_BalanceDetails" } & IBalanceDetails)
    | null
    | undefined;
  readonly initial_total?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly available_payment_methods?:
    | {
        readonly __typename?: "api_DebtOut_AvailablePaymentMethods";
        readonly check?: boolean | null | undefined;
        readonly credit_card?: boolean | null | undefined;
        readonly debit_card?: boolean | null | undefined;
      }
    | null
    | undefined;
  readonly unified_payment_entries?:
    | ReadonlyArray<
        | {
            readonly __typename?: "UnifiedPaymentEntry";
            readonly payment_entry_id?: string | null | undefined;
            readonly payment_time?: number | null | undefined;
            readonly is_pending?: boolean | null | undefined;
            readonly is_successful?: boolean | null | undefined;
            readonly transaction_type?: IApi_PaymentTransactionType | null | undefined;
            readonly payment_method_info?:
              | {
                  readonly __typename?: "api_PaymentMethodInfoOut";
                  readonly card_info?:
                    | {
                        readonly __typename?: "CardPaymentMethodInfo";
                        readonly last_four_digits?: string | null | undefined;
                        readonly card_type?: ICardPaymentMethodInfo_CardType | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            readonly amount?:
              | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type IBalanceDetails = {
  readonly __typename?: "api_DebtOut_BalanceDetails";
  readonly is_settled_in_full?: boolean | null | undefined;
  readonly principal?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly interest?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly fees?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly costs?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly overcharges?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
};

export type ICreditorModel = {
  readonly __typename?: "Creditor";
  readonly id: string;
  readonly company_name?: string | null | undefined;
  readonly creditor_settings?:
    | {
        readonly __typename?: "Creditor_CreditorSettings";
        readonly is_debt_buyer?: boolean | null | undefined;
        readonly is_tribal?: boolean | null | undefined;
        readonly is_first_party_relationship?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type ICreditorOutModel = {
  readonly __typename?: "api_CreditorOut";
  readonly id: string;
  readonly company_name?: string | null | undefined;
  readonly meta?: ({ readonly __typename?: "MetaData" } & IMetaDataModel) | null | undefined;
  readonly original_brand?:
    | { readonly __typename?: "Creditor_Brand"; readonly name?: string | null | undefined }
    | null
    | undefined;
  readonly creditor_settings?:
    | {
        readonly __typename?: "api_CreditorOut_CreditorSettings";
        readonly is_debt_buyer?: boolean | null | undefined;
        readonly is_tribal?: boolean | null | undefined;
        readonly is_first_party_relationship?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type ICreditorTaskModel = {
  readonly __typename?: "CreditorTask";
  readonly id: string;
  readonly lastMediaReceivedDate?: Date | null | undefined;
  readonly meta?: ({ readonly __typename?: "MetaData" } & IMetaDataModel) | null | undefined;
  readonly header?:
    | {
        readonly __typename?: "creditors_CreditorTask_Header";
        readonly task_id?: string | null | undefined;
        readonly creditor_id?: string | null | undefined;
        readonly status?: ICreditors_Status | null | undefined;
      }
    | null
    | undefined;
  readonly debt_verification_request?:
    | ({ readonly __typename?: "creditors_CreditorTask_DebtVerificationRequest" } & IDebtVerificationModel)
    | null
    | undefined;
};

export type IDebtLogEntryModel = {
  readonly __typename?: "DebtLogEntry";
  readonly id: string;
  readonly meta?:
    | { readonly __typename?: "MetaData"; readonly time_created?: number | null | undefined }
    | null
    | undefined;
  readonly total_to_collect_adjustment_log_entry?:
    | {
        readonly __typename?: "TotalToCollectAdjustmentLogEntry";
        readonly description?: string | null | undefined;
        readonly creditor_note?: string | null | undefined;
        readonly snapshot?:
          | {
              readonly __typename?: "accounting_BalanceBreakdown";
              readonly buckets: ReadonlyArray<
                | {
                    readonly __typename?: "accounting_Bucket";
                    readonly bucket_type?: IAccounting_Bucket_BucketType | null | undefined;
                    readonly amount?:
                      | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly send_email_to_debtor_request?:
    | {
        readonly __typename?: "executor_SendEmailToDebtorRequest";
        readonly response?:
          | {
              readonly __typename?: "executor_SendEmailResponse";
              readonly subject?: string | null | undefined;
              readonly rendered_content_s3_path?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly send_sms_to_debtor_request?:
    | {
        readonly __typename?: "executor_SendSMSToDebtorRequest";
        readonly sms_body?: string | null | undefined;
      }
    | null
    | undefined;
  readonly status_change_entry?:
    | {
        readonly __typename?: "DebtStatusChangeEntry";
        readonly new_status?: string | null | undefined;
        readonly old_status?: string | null | undefined;
      }
    | null
    | undefined;
  readonly payment_log_entry?:
    | {
        readonly __typename?: "PaymentLogEntry";
        readonly status?: boolean | null | undefined;
        readonly amount?: number | null | undefined;
        readonly currency?: string | null | undefined;
        readonly reversed_payment_log_entry_id?: string | null | undefined;
        readonly healpay_transaction?:
          | {
              readonly __typename?: "EPayTransaction";
              readonly ref_num?: string | null | undefined;
              readonly invoice?: string | null | undefined;
              readonly cust_num?: string | null | undefined;
              readonly processor?: IProcessor | null | undefined;
              readonly result?: string | null | undefined;
              readonly status?: string | null | undefined;
              readonly error?: string | null | undefined;
            }
          | null
          | undefined;
        readonly lucentpay_transaction?:
          | {
              readonly __typename?: "EPayTransaction";
              readonly ref_num?: string | null | undefined;
              readonly invoice?: string | null | undefined;
              readonly cust_num?: string | null | undefined;
              readonly processor?: IProcessor | null | undefined;
              readonly result?: string | null | undefined;
              readonly status?: string | null | undefined;
              readonly error?: string | null | undefined;
            }
          | null
          | undefined;
        readonly usaepay_transaction?:
          | {
              readonly __typename?: "EPayTransaction";
              readonly ref_num?: string | null | undefined;
              readonly invoice?: string | null | undefined;
              readonly cust_num?: string | null | undefined;
              readonly processor?: IProcessor | null | undefined;
              readonly result?: string | null | undefined;
              readonly status?: string | null | undefined;
              readonly error?: string | null | undefined;
            }
          | null
          | undefined;
        readonly lucentpay_ach_transaction?:
          | {
              readonly __typename?: "PaymentLogEntry_LucentpayAchTransaction";
              readonly check_status?: IPaymentLogEntry_LucentpayAchTransaction_CheckStatus | null | undefined;
              readonly response?:
                | {
                    readonly __typename?: "EPayTransaction";
                    readonly auth_code?: string | null | undefined;
                    readonly result?: string | null | undefined;
                    readonly ref_num?: string | null | undefined;
                    readonly error?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        readonly healpay_ach_transaction?:
          | {
              readonly __typename?: "PaymentLogEntry_HealpayAchTransaction";
              readonly check_status?: IPaymentLogEntry_HealpayAchTransaction_CheckStatus | null | undefined;
              readonly response?:
                | {
                    readonly __typename?: "EPayTransaction";
                    readonly auth_code?: string | null | undefined;
                    readonly result?: string | null | undefined;
                    readonly ref_num?: string | null | undefined;
                    readonly error?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        readonly direct_payment_to_creditor?:
          | {
              readonly __typename?: "PaymentLogEntry_DirectPaymentToCreditor";
              readonly description?: string | null | undefined;
              readonly payment_timestamp?: number | null | undefined;
            }
          | null
          | undefined;
        readonly check_payment?:
          | {
              readonly __typename?: "PaymentLogEntry_CheckPayment";
              readonly check_number?: string | null | undefined;
            }
          | null
          | undefined;
        readonly refund?:
          | {
              readonly __typename?: "PaymentLogEntry_Refund";
              readonly description?: string | null | undefined;
              readonly refund_reason?: IRefundReasonEnumContainer_RefundReason | null | undefined;
              readonly processed_by?: IProcessingEntityEnumContainer_ProcessingEntity | null | undefined;
            }
          | null
          | undefined;
        readonly promotional_credit?:
          | {
              readonly __typename?: "PaymentLogEntry_PromotionalCredit";
              readonly description?: string | null | undefined;
              readonly transaction_id?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IDebtModel = {
  readonly __typename?: "Debt";
  readonly id: string;
  readonly transaction_id?: string | null | undefined;
  readonly transaction_timestamp?: number | null | undefined;
  readonly last_payment_timestamp?: number | null | undefined;
  readonly original_creditor_account_number?: string | null | undefined;
  readonly product?: string | null | undefined;
  readonly account_number?: string | null | undefined;
  readonly status?: string | null | undefined;
  readonly meta?: ({ readonly __typename?: "MetaData" } & IMetaDataModel) | null | undefined;
  readonly last_payment_amount?:
    | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
    | null
    | undefined;
  readonly default_date?: ({ readonly __typename?: "Date" } & IExpandedDate) | null | undefined;
  readonly creditor?: ({ readonly __typename?: "Creditor" } & ICreditorModel) | null | undefined;
  readonly person?: ({ readonly __typename?: "Person" } & IPersonModel) | null | undefined;
};

export type IDebtOutBalanceModel = {
  readonly __typename?: "DebtOut";
  readonly id: string;
  readonly recent_transaction_info?:
    | {
        readonly __typename?: "api_DebtOut_RecentTransactionInfo";
        readonly last_payment_amount?:
          | {
              readonly __typename?: "MonetaryAmount";
              readonly amount?: number | null | undefined;
              readonly currency?: string | null | undefined;
            }
          | null
          | undefined;
        readonly last_payment_date?:
          | {
              readonly __typename?: "Date";
              readonly month?: number | null | undefined;
              readonly day?: number | null | undefined;
              readonly year?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly debt_states?:
    | ReadonlyArray<
        | {
            readonly __typename?: "DebtState";
            readonly id: string;
            readonly meta?:
              | { readonly __typename?: "MetaData"; readonly last_modified?: number | null | undefined }
              | null
              | undefined;
            readonly part_payment?:
              | {
                  readonly __typename?: "DebtState_PartPayment";
                  readonly is_settlement?: boolean | null | undefined;
                  readonly new_amount?: number | null | undefined;
                  readonly due_date?: ({ readonly __typename?: "Date" } & IExpandedDate) | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  readonly initial_total?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly _include_debt?:
    | {
        readonly __typename?: "Debt";
        readonly default_timestamp?: number | null | undefined;
        readonly id: string;
        readonly initial_principal?:
          | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
          | null
          | undefined;
        readonly initial_interest?:
          | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
          | null
          | undefined;
        readonly initial_fees?:
          | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
          | null
          | undefined;
        readonly initial_costs?:
          | ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel)
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly log_entries?:
    | ReadonlyArray<({ readonly __typename?: "DebtLogEntry" } & IDebtLogEntryModel) | null | undefined>
    | null
    | undefined;
  readonly balance_details_at_placement?:
    | ({ readonly __typename?: "api_DebtOut_BalanceDetails" } & IBalanceDetails)
    | null
    | undefined;
  readonly balance?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly balance_details?:
    | ({ readonly __typename?: "api_DebtOut_BalanceDetails" } & IBalanceDetails)
    | null
    | undefined;
};

export type IDebtTaskModel = {
  readonly __typename?: "camunda_Task";
  readonly debt?: ({ readonly __typename?: "Debt" } & IDebtModel) | null | undefined;
} & ICamundaTaskModel;

export type IDebtVerificationModel = {
  readonly __typename?: "creditors_CreditorTask_DebtVerificationRequest";
  readonly requested_files: ReadonlyArray<
    ({ readonly __typename?: "creditors_FileRequest" } & IFileRequestModel) | null | undefined
  >;
};

export type IDisputeContextHelpModel = {
  readonly __typename?: "DisputeContextHelp";
  readonly goal?: string | null | undefined;
  readonly matchRequirements?: string | null | undefined;
  readonly required?: string | null | undefined;
  readonly documentExamples?: string | null | undefined;
  readonly stateRequirements?: string | null | undefined;
};

export type IDisputeModel = {
  readonly __typename?: "Dispute";
  readonly id: string;
  readonly status?: IDispute_Status | null | undefined;
  readonly claim_timestamp?: number | null | undefined;
  readonly resolution_timestamp?: number | null | undefined;
  readonly resolution?:
    | {
        readonly __typename?: "DisputeResolution";
        readonly resolution_type?: IDisputeResolution_ResolutionType | null | undefined;
        readonly details?: IDisputeResolution_Details | null | undefined;
        readonly notes?: string | null | undefined;
      }
    | null
    | undefined;
  readonly claim?:
    | {
        readonly __typename?: "DisputeClaim";
        readonly reason?: IDisputeClaim_Reason | null | undefined;
        readonly source_type?: IDisputeClaim_Source | null | undefined;
        readonly customer_comments?: string | null | undefined;
        readonly additional_requirements: ReadonlyArray<string | null | undefined>;
        readonly source_url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type IDisputeTaskModel = {
  readonly __typename?: "camunda_Task";
  readonly disputeType?: IDisputeClaim_Reason | null | undefined;
  readonly creditorTaskId?: string | null | undefined;
  readonly disputeContextHelp?:
    | ({ readonly __typename?: "DisputeContextHelp" } & IDisputeContextHelpModel)
    | null
    | undefined;
  readonly debt?:
    | {
        readonly __typename?: "Debt";
        readonly creditorTasks?:
          | ReadonlyArray<({ readonly __typename?: "CreditorTask" } & ICreditorTaskModel) | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly creditorTask?: ({ readonly __typename?: "CreditorTask" } & ICreditorTaskModel) | null | undefined;
  readonly fileRequestsReviews?:
    | ReadonlyArray<{ readonly __typename?: "FileRequestReview" } & IFileRequestReviewModel>
    | null
    | undefined;
} & IDebtTaskModel;

export type IEmailModel = {
  readonly __typename?: "EmailAddress";
  readonly email?: string | null | undefined;
  readonly is_unsubscribed?: boolean | null | undefined;
};

export type IExpandedDate = {
  readonly __typename?: "Date";
  readonly day?: number | null | undefined;
  readonly month?: number | null | undefined;
  readonly year?: number | null | undefined;
};

export type IFileRequestModel = {
  readonly __typename?: "creditors_FileRequest";
  readonly id: string;
  readonly description?: string | null | undefined;
  readonly title?: string | null | undefined;
  readonly meta?: ({ readonly __typename?: "MetaData" } & IMetaDataModel) | null | undefined;
  readonly remote_files?:
    | ReadonlyArray<({ readonly __typename?: "RemoteFile" } & IRemoteFileModel) | null | undefined>
    | null
    | undefined;
};

export type IFileRequestReviewModel = {
  readonly __typename?: "FileRequestReview";
  readonly id: string;
  readonly status?: IFileReviewStatus | null | undefined;
  readonly comment?: string | null | undefined;
  readonly reviewedByUserId?: string | null | undefined;
  readonly reviewTimestamp?: Date | null | undefined;
};

export type IMetaDataModel = {
  readonly __typename?: "MetaData";
  readonly id?: string | null | undefined;
  readonly is_active?: boolean | null | undefined;
  readonly is_primary?: boolean | null | undefined;
  readonly time_created?: number | null | undefined;
  readonly last_modified?: number | null | undefined;
};

export type IMonetaryModel = {
  readonly __typename?: "MonetaryAmount";
  readonly amount?: number | null | undefined;
  readonly currency?: string | null | undefined;
};

export type IPaymentPlanOutModel = {
  readonly __typename?: "PaymentPlanOut";
  readonly id: string;
  readonly is_recurring?: boolean | null | undefined;
  readonly next_installment?: number | null | undefined;
  readonly payment_method_info?:
    | {
        readonly __typename?: "api_PaymentMethodInfoOut";
        readonly card_info?:
          | {
              readonly __typename?: "CardPaymentMethodInfo";
              readonly card_type?: ICardPaymentMethodInfo_CardType | null | undefined;
              readonly expiration_year?: string | null | undefined;
              readonly expiration_month?: string | null | undefined;
              readonly last_four_digits?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly installments: ReadonlyArray<
    | {
        readonly __typename?: "api_PaymentPlanOut_Installment";
        readonly due_date?: number | null | undefined;
        readonly status?: IApi_PaymentPlanOut_Installment_Status | null | undefined;
        readonly installment_amount?:
          | {
              readonly __typename?: "MonetaryAmount";
              readonly amount?: number | null | undefined;
              readonly currency?: string | null | undefined;
            }
          | null
          | undefined;
        readonly amount_due?:
          | {
              readonly __typename?: "MonetaryAmount";
              readonly amount?: number | null | undefined;
              readonly currency?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  readonly application?:
    | {
        readonly __typename?: "PaymentPlanApplication";
        readonly discount?:
          | {
              readonly __typename?: "MonetaryAmount";
              readonly amount?: number | null | undefined;
              readonly currency?: string | null | undefined;
            }
          | null
          | undefined;
        readonly amount_to_pay?:
          | {
              readonly __typename?: "MonetaryAmount";
              readonly amount?: number | null | undefined;
              readonly currency?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IPersonModel = {
  readonly __typename?: "Person";
  readonly id: string;
  readonly fullName: string;
  readonly language_preference?: ILanguagePreferred_Language | null | undefined;
  readonly reference?: string | null | undefined;
  readonly alternate_references: ReadonlyArray<string | null | undefined>;
  readonly name?: ({ readonly __typename?: "PersonName" } & IPersonNameModel) | null | undefined;
  readonly addresses: ReadonlyArray<
    ({ readonly __typename?: "PostalAddress" } & IPostalAddressModel) | null | undefined
  >;
  readonly emails: ReadonlyArray<({ readonly __typename?: "EmailAddress" } & IEmailModel) | null | undefined>;
  readonly date_of_birth?: ({ readonly __typename?: "Date" } & IExpandedDate) | null | undefined;
  readonly businesses: ReadonlyArray<
    { readonly __typename?: "Person_Business"; readonly name?: string | null | undefined } | null | undefined
  >;
  readonly phones: ReadonlyArray<({ readonly __typename?: "PhoneNumber" } & IPhoneModel) | null | undefined>;
};

export type IPersonNameModel = {
  readonly __typename?: "PersonName";
  readonly first_name?: string | null | undefined;
  readonly middle_name?: string | null | undefined;
  readonly last_name?: string | null | undefined;
};

export type IPersonOutDebt = {
  readonly __typename?: "DebtOut";
  readonly id: string;
  readonly simplified_status?: IApi_SimplifiedStatus | null | undefined;
  readonly _include_debt?:
    | { readonly __typename?: "Debt"; readonly id: string; readonly person_id?: string | null | undefined }
    | null
    | undefined;
  readonly balance?: ({ readonly __typename?: "MonetaryAmount" } & IMonetaryModel) | null | undefined;
  readonly creditor?:
    | {
        readonly __typename?: "api_CreditorOut";
        readonly company_name?: string | null | undefined;
        readonly meta?: ({ readonly __typename?: "MetaData" } & IMetaDataModel) | null | undefined;
      }
    | null
    | undefined;
};

export type IPersonOutModel = {
  readonly __typename?: "PersonOut";
  readonly id: string;
  readonly additional_information?:
    | {
        readonly __typename?: "api_PersonOut_AdditionalInformation";
        readonly references: ReadonlyArray<
          | {
              readonly __typename?: "api_PersonOut_AdditionalInformation_CreditorReference";
              readonly creditor_id?: string | null | undefined;
              readonly reference?: string | null | undefined;
              readonly alternate_references: ReadonlyArray<string | null | undefined>;
            }
          | null
          | undefined
        >;
        readonly user_account?:
          | { readonly __typename?: "auth_UserAccount"; readonly id: string }
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly debts: ReadonlyArray<({ readonly __typename?: "DebtOut" } & IPersonOutDebt) | null | undefined>;
};

export type IPhoneModel = {
  readonly __typename?: "PhoneNumber";
  readonly phone_number?: string | null | undefined;
};

export type IPostalAddressModel = {
  readonly __typename?: "PostalAddress";
  readonly city?: string | null | undefined;
  readonly state?: string | null | undefined;
  readonly types: ReadonlyArray<IPostalAddress_Type | null | undefined>;
  readonly street_line1?: string | null | undefined;
  readonly street_line2?: string | null | undefined;
  readonly street_line3?: string | null | undefined;
  readonly zipcode?: string | null | undefined;
  readonly country_code?: string | null | undefined;
  readonly meta?: ({ readonly __typename?: "MetaData" } & IMetaDataModel) | null | undefined;
  readonly data_source?:
    | { readonly __typename?: "DataSource"; readonly type?: IDataSource_Type | null | undefined }
    | null
    | undefined;
};

export type IRemoteFileModel = {
  readonly __typename?: "RemoteFile";
  readonly id: string;
  readonly size?: number | null | undefined;
  readonly full_path?: string | null | undefined;
  readonly content_type?: string | null | undefined;
  readonly meta?: ({ readonly __typename?: "MetaData" } & IMetaDataModel) | null | undefined;
  readonly creditor_upload?:
    | {
        readonly __typename?: "RemoteFile_CreditorUpload";
        readonly original_filename?: string | null | undefined;
      }
    | null
    | undefined;
  readonly debt_verification_upload?:
    | {
        readonly __typename?: "RemoteFile_DebtVerificationUpload";
        readonly original_filename?: string | null | undefined;
      }
    | null
    | undefined;
  readonly media_request_upload?:
    | {
        readonly __typename?: "RemoteFile_MediaRequestUpload";
        readonly original_filename?: string | null | undefined;
      }
    | null
    | undefined;
};

export type IAccountViewSsnVariables = Exact<{
  accountId: Scalars["ID"];
}>;

export type IAccountViewSsn = {
  readonly __typename?: "Query";
  readonly accountSSN?:
    | ({ readonly __typename?: "DebtOut"; readonly ssn_last4?: string | null | undefined } & IAccountModel)
    | null
    | undefined;
};

export const CamundaUserModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CamundaUserModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "camunda_User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "name" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICamundaUserModel, unknown>;
export const CamundaUserModelWithRestrictions = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CamundaUserModelWithRestrictions" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "camunda_User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "regionRestriction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "setTo" },
                value: { kind: "Variable", name: { kind: "Name", value: "regionRestrictionSetTo" } }
              }
            ],
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICamundaUserModelWithRestrictions, unknown>;
export const MonetaryModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MonetaryModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MonetaryAmount" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IMonetaryModel, unknown>;
export const ExpandedDate = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ExpandedDate" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "day" } },
          { kind: "Field", name: { kind: "Name", value: "month" } },
          { kind: "Field", name: { kind: "Name", value: "year" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IExpandedDate, unknown>;
export const MetaDataModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MetaDataModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MetaData" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "is_active" } },
          { kind: "Field", name: { kind: "Name", value: "is_primary" } },
          { kind: "Field", name: { kind: "Name", value: "time_created" } },
          { kind: "Field", name: { kind: "Name", value: "last_modified" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IMetaDataModel, unknown>;
export const CreditorModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditorModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Creditor" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "company_name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditor_settings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "is_debt_buyer" } },
                { kind: "Field", name: { kind: "Name", value: "is_tribal" } },
                { kind: "Field", name: { kind: "Name", value: "is_first_party_relationship" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICreditorModel, unknown>;
export const PersonNameModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PersonNameModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PersonName" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "first_name" } },
          { kind: "Field", name: { kind: "Name", value: "middle_name" } },
          { kind: "Field", name: { kind: "Name", value: "last_name" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IPersonNameModel, unknown>;
export const PostalAddressModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PostalAddressModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PostalAddress" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MetaDataModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "types" } },
          { kind: "Field", name: { kind: "Name", value: "street_line1" } },
          { kind: "Field", name: { kind: "Name", value: "street_line2" } },
          { kind: "Field", name: { kind: "Name", value: "street_line3" } },
          { kind: "Field", name: { kind: "Name", value: "zipcode" } },
          { kind: "Field", name: { kind: "Name", value: "country_code" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "data_source" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "type" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IPostalAddressModel, unknown>;
export const EmailModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EmailModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "EmailAddress" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "is_unsubscribed" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IEmailModel, unknown>;
export const PhoneModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PhoneModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PhoneNumber" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [{ kind: "Field", name: { kind: "Name", value: "phone_number" } }]
      }
    }
  ]
} as unknown as DocumentNode<IPhoneModel, unknown>;
export const PersonModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PersonModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Person" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fullName" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "PersonNameModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "addresses" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "PostalAddressModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "emails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "EmailModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "date_of_birth" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "ExpandedDate" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "businesses" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "phones" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "PhoneModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "language_preference" } },
          { kind: "Field", name: { kind: "Name", value: "reference" } },
          { kind: "Field", name: { kind: "Name", value: "alternate_references" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IPersonModel, unknown>;
export const DebtModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DebtModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Debt" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MetaDataModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "transaction_id" } },
          { kind: "Field", name: { kind: "Name", value: "transaction_timestamp" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "last_payment_amount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "default_date" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "ExpandedDate" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "last_payment_timestamp" } },
          { kind: "Field", name: { kind: "Name", value: "original_creditor_account_number" } },
          { kind: "Field", name: { kind: "Name", value: "last_payment_timestamp" } },
          { kind: "Field", name: { kind: "Name", value: "product" } },
          { kind: "Field", name: { kind: "Name", value: "account_number" } },
          { kind: "Field", name: { kind: "Name", value: "transaction_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "CreditorModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "person" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "PersonModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "status" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDebtModel, unknown>;
export const CreditorOutModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditorOutModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "api_CreditorOut" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MetaDataModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          { kind: "Field", name: { kind: "Name", value: "company_name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "original_brand" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditor_settings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "is_debt_buyer" } },
                { kind: "Field", name: { kind: "Name", value: "is_tribal" } },
                { kind: "Field", name: { kind: "Name", value: "is_first_party_relationship" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICreditorOutModel, unknown>;
export const BalanceDetails = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BalanceDetails" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "api_DebtOut_BalanceDetails" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "principal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "interest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "fees" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "costs" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "overcharges" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "is_settled_in_full" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IBalanceDetails, unknown>;
export const AccountModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AccountModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DebtOut" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "balance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "calculated_payment_offers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "available_plans" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "application" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "frequency" } }]
                        }
                      },
                      { kind: "Field", name: { kind: "Name", value: "number_of_installments" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total_to_pay" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "installment_amount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "effective_max_discount_percent" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "override_type" } },
                { kind: "Field", name: { kind: "Name", value: "min_settlement_percent" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "calculated_payment_offers" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "available_settlement_offer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "settlement_percent" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiration_date" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "ExpandedDate" } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "highlighted_plan" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "application" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "number_of_installments" } },
                            { kind: "Field", name: { kind: "Name", value: "frequency" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "payment_amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlement_constraints" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "min" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "percent" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "account_open_timestamp" } },
          { kind: "Field", name: { kind: "Name", value: "expected_return_date" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "max_payment_plan_length_in_months" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "pending_risky_payment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transaction_amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
                  }
                }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "min_settlement_percent" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "account_number" },
            name: { kind: "Name", value: "account_number_for_display" }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "debt" },
            name: { kind: "Name", value: "_include_debt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DebtModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "CreditorOutModel" } }]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "status" },
            name: { kind: "Name", value: "simplified_status" }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "balance_details_at_placement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "BalanceDetails" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "initial_total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "available_payment_methods" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "check" } },
                { kind: "Field", name: { kind: "Name", value: "credit_card" } },
                { kind: "Field", name: { kind: "Name", value: "debit_card" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "payment_plan_id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "unified_payment_entries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "payment_entry_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "payment_method_info" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "card_info" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "last_four_digits" } },
                            { kind: "Field", name: { kind: "Name", value: "card_type" } }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "payment_time" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "is_pending" } },
                { kind: "Field", name: { kind: "Name", value: "is_successful" } },
                { kind: "Field", name: { kind: "Name", value: "transaction_type" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IAccountModel, unknown>;
export const DebtLogEntryModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DebtLogEntryModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DebtLogEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "time_created" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "total_to_collect_adjustment_log_entry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "creditor_note" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "snapshot" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "buckets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "bucket_type" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "send_email_to_debtor_request" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "response" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "subject" } },
                      { kind: "Field", name: { kind: "Name", value: "rendered_content_s3_path" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "send_sms_to_debtor_request" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "sms_body" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "status_change_entry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "new_status" } },
                { kind: "Field", name: { kind: "Name", value: "old_status" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "payment_log_entry" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "healpay_transaction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ref_num" } },
                      { kind: "Field", name: { kind: "Name", value: "invoice" } },
                      { kind: "Field", name: { kind: "Name", value: "cust_num" } },
                      { kind: "Field", name: { kind: "Name", value: "processor" } },
                      { kind: "Field", name: { kind: "Name", value: "result" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "error" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lucentpay_transaction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ref_num" } },
                      { kind: "Field", name: { kind: "Name", value: "invoice" } },
                      { kind: "Field", name: { kind: "Name", value: "cust_num" } },
                      { kind: "Field", name: { kind: "Name", value: "processor" } },
                      { kind: "Field", name: { kind: "Name", value: "result" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "error" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "usaepay_transaction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "ref_num" } },
                      { kind: "Field", name: { kind: "Name", value: "invoice" } },
                      { kind: "Field", name: { kind: "Name", value: "cust_num" } },
                      { kind: "Field", name: { kind: "Name", value: "processor" } },
                      { kind: "Field", name: { kind: "Name", value: "result" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "error" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lucentpay_ach_transaction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "check_status" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "response" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "auth_code" } },
                            { kind: "Field", name: { kind: "Name", value: "result" } },
                            { kind: "Field", name: { kind: "Name", value: "ref_num" } },
                            { kind: "Field", name: { kind: "Name", value: "error" } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "healpay_ach_transaction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "check_status" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "response" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "auth_code" } },
                            { kind: "Field", name: { kind: "Name", value: "result" } },
                            { kind: "Field", name: { kind: "Name", value: "ref_num" } },
                            { kind: "Field", name: { kind: "Name", value: "error" } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "direct_payment_to_creditor" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "payment_timestamp" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "check_payment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "check_number" } }]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refund" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "refund_reason" } },
                      { kind: "Field", name: { kind: "Name", value: "processed_by" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "currency" } },
                { kind: "Field", name: { kind: "Name", value: "reversed_payment_log_entry_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "promotional_credit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      { kind: "Field", name: { kind: "Name", value: "transaction_id" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDebtLogEntryModel, unknown>;
export const DebtOutBalanceModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DebtOutBalanceModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DebtOut" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "recent_transaction_info" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_payment_amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "amount" } },
                      { kind: "Field", name: { kind: "Name", value: "currency" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "last_payment_date" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "month" } },
                      { kind: "Field", name: { kind: "Name", value: "day" } },
                      { kind: "Field", name: { kind: "Name", value: "year" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "debt_states" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "last_modified" } }]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "part_payment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "is_settlement" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "due_date" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "ExpandedDate" } }
                          ]
                        }
                      },
                      { kind: "Field", name: { kind: "Name", value: "new_amount" } }
                    ]
                  }
                }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "initial_total" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "_include_debt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "default_timestamp" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "initial_principal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "initial_interest" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "initial_fees" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "initial_costs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "log_entries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DebtLogEntryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "balance_details_at_placement" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "BalanceDetails" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "balance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "balance_details" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "BalanceDetails" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDebtOutBalanceModel, unknown>;
export const DisputeModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DisputeModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Dispute" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "resolution" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "resolution_type" } },
                { kind: "Field", name: { kind: "Name", value: "details" } },
                { kind: "Field", name: { kind: "Name", value: "notes" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "claim_timestamp" } },
          { kind: "Field", name: { kind: "Name", value: "resolution_timestamp" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "claim" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "reason" } },
                { kind: "Field", name: { kind: "Name", value: "source_type" } },
                { kind: "Field", name: { kind: "Name", value: "customer_comments" } },
                { kind: "Field", name: { kind: "Name", value: "additional_requirements" } },
                { kind: "Field", name: { kind: "Name", value: "source_url" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDisputeModel, unknown>;
export const CamundaKeyValueModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CamundaKeyValueModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "camunda_KeyValuePair" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "key" } },
          { kind: "Field", name: { kind: "Name", value: "value" } },
          { kind: "Field", name: { kind: "Name", value: "valueType" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICamundaKeyValueModel, unknown>;
export const CamundaProcessInstanceModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CamundaProcessInstanceModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "camunda_ProcessInstance" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "variables" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "keys" },
                value: {
                  kind: "ListValue",
                  values: [{ kind: "StringValue", value: "dueDate", block: false }]
                }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "CamundaKeyValueModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "lastHistoricActivityAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dueDate" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastUpdateDate" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICamundaProcessInstanceModel, unknown>;
export const CamundaTaskModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CamundaTaskModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "camunda_Task" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "assigneeId" } },
          { kind: "Field", name: { kind: "Name", value: "priority" } },
          { kind: "Field", name: { kind: "Name", value: "due" } },
          { kind: "Field", name: { kind: "Name", value: "created" } },
          { kind: "Field", name: { kind: "Name", value: "followUp" } },
          { kind: "Field", name: { kind: "Name", value: "formKey" } },
          { kind: "Field", name: { kind: "Name", value: "processInstanceId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "variables" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "CamundaKeyValueModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "processInstance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "CamundaProcessInstanceModel" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "dueDate" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "allowsInternationalAgents" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICamundaTaskModel, unknown>;
export const DebtTaskModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DebtTaskModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "camunda_Task" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "CamundaTaskModel" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "debt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DebtModel" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDebtTaskModel, unknown>;
export const DisputeContextHelpModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DisputeContextHelpModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DisputeContextHelp" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "goal" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "matchRequirements" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "required" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "documentExamples" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "stateRequirements" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDisputeContextHelpModel, unknown>;
export const RemoteFileModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RemoteFileModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RemoteFile" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MetaDataModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "size" } },
          { kind: "Field", name: { kind: "Name", value: "full_path" } },
          { kind: "Field", name: { kind: "Name", value: "content_type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditor_upload" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "original_filename" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "debt_verification_upload" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "original_filename" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "media_request_upload" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "original_filename" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IRemoteFileModel, unknown>;
export const FileRequestModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FileRequestModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "creditors_FileRequest" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "id" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MetaDataModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "remote_files" },
            directives: [
              {
                kind: "Directive",
                name: { kind: "Name", value: "skip" },
                arguments: [
                  {
                    kind: "Argument",
                    name: { kind: "Name", value: "if" },
                    value: { kind: "Variable", name: { kind: "Name", value: "skipFileRequestReviews" } }
                  }
                ]
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "RemoteFileModel" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IFileRequestModel, unknown>;
export const DebtVerificationModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DebtVerificationModel" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "creditors_CreditorTask_DebtVerificationRequest" }
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requested_files" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "FileRequestModel" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDebtVerificationModel, unknown>;
export const CreditorTaskModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreditorTaskModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CreditorTask" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MetaDataModel" } }]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "header" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "task_id" } },
                { kind: "Field", name: { kind: "Name", value: "creditor_id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "debt_verification_request" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DebtVerificationModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lastMediaReceivedDate" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICreditorTaskModel, unknown>;
export const FileRequestReviewModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FileRequestReviewModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FileRequestReview" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "comment" } },
          { kind: "Field", name: { kind: "Name", value: "reviewedByUserId" } },
          { kind: "Field", name: { kind: "Name", value: "reviewTimestamp" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IFileRequestReviewModel, unknown>;
export const DisputeTaskModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DisputeTaskModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "camunda_Task" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "DebtTaskModel" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "disputeType" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "disputeContextHelp" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "DisputeContextHelpModel" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "debt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditorTasks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "FragmentSpread", name: { kind: "Name", value: "CreditorTaskModel" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditorTaskId" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditorTask" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "CreditorTaskModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "fileRequestsReviews" },
            directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "FileRequestReviewModel" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDisputeTaskModel, unknown>;
export const PaymentPlanOutModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PaymentPlanOutModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PaymentPlanOut" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "payment_method_info" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "card_info" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "card_type" } },
                      { kind: "Field", name: { kind: "Name", value: "expiration_year" } },
                      { kind: "Field", name: { kind: "Name", value: "expiration_month" } },
                      { kind: "Field", name: { kind: "Name", value: "last_four_digits" } }
                    ]
                  }
                }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "is_recurring" } },
          { kind: "Field", name: { kind: "Name", value: "next_installment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "installments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "installment_amount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "amount" } },
                      { kind: "Field", name: { kind: "Name", value: "currency" } }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "due_date" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount_due" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "amount" } },
                      { kind: "Field", name: { kind: "Name", value: "currency" } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "application" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "amount" } },
                      { kind: "Field", name: { kind: "Name", value: "currency" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amount_to_pay" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "amount" } },
                      { kind: "Field", name: { kind: "Name", value: "currency" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IPaymentPlanOutModel, unknown>;
export const PersonOutDebt = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PersonOutDebt" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DebtOut" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "_include_debt" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "person_id" } }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "balance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MonetaryModel" } }]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MetaDataModel" } }]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "company_name" } }
              ]
            }
          },
          { kind: "Field", name: { kind: "Name", value: "simplified_status" } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IPersonOutDebt, unknown>;
export const PersonOutModel = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PersonOutModel" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PersonOut" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "additional_information" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "references" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "creditor_id" } },
                      { kind: "Field", name: { kind: "Name", value: "reference" } },
                      { kind: "Field", name: { kind: "Name", value: "alternate_references" } }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user_account" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }]
                  }
                }
              ]
            }
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "debts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "PersonOutDebt" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IPersonOutModel, unknown>;
export const CommentsByObjectIdsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CommentsByObjectIds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "comments" },
            name: { kind: "Name", value: "CommentsByObjectIds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ids" },
                value: { kind: "Variable", name: { kind: "Name", value: "ids" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "internal_information" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "aim_info" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "creditor_id" } }]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amex_information" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "flag" } }]
                        }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "user_name" } },
                { kind: "Field", name: { kind: "Name", value: "comment" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data_source" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "type" } }]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "time_created" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICommentsByObjectIds, ICommentsByObjectIdsVariables>;
export const AccountViewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountView" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accountId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "account" },
            name: { kind: "Name", value: "DebtOut" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "accountId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AccountModel" } }]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "debt" },
            name: { kind: "Name", value: "Debt" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "accountId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DebtModel" } }]
            }
          }
        ]
      }
    },
    ...AccountModel.definitions,
    ...MonetaryModel.definitions,
    ...ExpandedDate.definitions,
    ...DebtModel.definitions,
    ...MetaDataModel.definitions,
    ...CreditorModel.definitions,
    ...PersonModel.definitions,
    ...PersonNameModel.definitions,
    ...PostalAddressModel.definitions,
    ...EmailModel.definitions,
    ...PhoneModel.definitions,
    ...CreditorOutModel.definitions,
    ...BalanceDetails.definitions
  ]
} as unknown as DocumentNode<IAccountView, IAccountViewVariables>;
export const PersonOutQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PersonOutQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "personId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "personOut" },
            name: { kind: "Name", value: "PersonOut" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "personId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "PersonOutModel" } }]
            }
          }
        ]
      }
    },
    ...PersonOutModel.definitions,
    ...PersonOutDebt.definitions,
    ...MonetaryModel.definitions,
    ...MetaDataModel.definitions
  ]
} as unknown as DocumentNode<IPersonOutQuery, IPersonOutQueryVariables>;
export const CreditorsQueryAuthDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CreditorsQueryAuth" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "creditorId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "creditor" },
            name: { kind: "Name", value: "Creditor" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "creditorId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditor_settings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "customer_call_authentication_settings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "criteria" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "options" } }]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICreditorsQueryAuth, ICreditorsQueryAuthVariables>;
export const CommentsByObjectIdsCreditorNotesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CommentsByObjectIdsCreditorNotes" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "comments" },
            name: { kind: "Name", value: "CommentsByObjectIds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ids" },
                value: { kind: "Variable", name: { kind: "Name", value: "ids" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "internal_information" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "aim_info" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "creditor_id" } }]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amex_information" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "flag" } }]
                        }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "object_id" } },
                { kind: "Field", name: { kind: "Name", value: "comment" } },
                { kind: "Field", name: { kind: "Name", value: "comment_type" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data_source" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "type" } }]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "meta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "time_created" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICommentsByObjectIdsCreditorNotes, ICommentsByObjectIdsCreditorNotesVariables>;
export const UserAccountQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserAccountQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userAccountId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "userAccount" },
            name: { kind: "Name", value: "UserAccount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "userAccountId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "identities" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "full_name" } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IUserAccountQuery, IUserAccountQueryVariables>;
export const PaymentPlanOutQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "paymentPlanOutQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "planId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "paymentPlanOut" },
            name: { kind: "Name", value: "PaymentPlanOut" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "planId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "payment_method_info" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "card_info" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "card_type" } },
                            { kind: "Field", name: { kind: "Name", value: "expiration_year" } },
                            { kind: "Field", name: { kind: "Name", value: "expiration_month" } },
                            { kind: "Field", name: { kind: "Name", value: "last_four_digits" } }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: "Field", name: { kind: "Name", value: "is_recurring" } },
                { kind: "Field", name: { kind: "Name", value: "next_installment" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "installments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "installment_amount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "amount" } },
                            { kind: "Field", name: { kind: "Name", value: "currency" } }
                          ]
                        }
                      },
                      { kind: "Field", name: { kind: "Name", value: "due_date" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount_due" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "amount" } },
                            { kind: "Field", name: { kind: "Name", value: "currency" } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "application" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discount" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "amount" } },
                            { kind: "Field", name: { kind: "Name", value: "currency" } }
                          ]
                        }
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount_to_pay" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "amount" } },
                            { kind: "Field", name: { kind: "Name", value: "currency" } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IPaymentPlanOutQuery, IPaymentPlanOutQueryVariables>;
export const CreditorsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CreditorsQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "creditors" },
            name: { kind: "Name", value: "Creditors" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "company_name" } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICreditorsQuery, ICreditorsQueryVariables>;
export const BpmnViewerQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BpmnViewerQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "taskId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "task" },
            name: { kind: "Name", value: "camunda_Task" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "taskId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "CamundaTaskModel" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "processDefinition" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "diagram" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "key" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...CamundaTaskModel.definitions,
    ...CamundaKeyValueModel.definitions,
    ...CamundaProcessInstanceModel.definitions
  ]
} as unknown as DocumentNode<IBpmnViewerQuery, IBpmnViewerQueryVariables>;
export const DebtOutBalancesQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DebtOutBalancesQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "debtId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "debtorId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "debtOutBalance" },
            name: { kind: "Name", value: "DebtOut" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "debtId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DebtOutBalanceModel" } }]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "personOut" },
            name: { kind: "Name", value: "PersonOut" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "debtorId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "PersonOutModel" } }]
            }
          }
        ]
      }
    },
    ...DebtOutBalanceModel.definitions,
    ...ExpandedDate.definitions,
    ...MonetaryModel.definitions,
    ...DebtLogEntryModel.definitions,
    ...BalanceDetails.definitions,
    ...PersonOutModel.definitions,
    ...PersonOutDebt.definitions,
    ...MetaDataModel.definitions
  ]
} as unknown as DocumentNode<IDebtOutBalancesQuery, IDebtOutBalancesQueryVariables>;
export const DisputeTaskViewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DisputeTaskView" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "taskId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skipFileRequestReviews" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: false }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "task" },
            name: { kind: "Name", value: "camunda_Task" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "taskId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DisputeTaskModel" } }]
            }
          }
        ]
      }
    },
    ...DisputeTaskModel.definitions,
    ...DebtTaskModel.definitions,
    ...CamundaTaskModel.definitions,
    ...CamundaKeyValueModel.definitions,
    ...CamundaProcessInstanceModel.definitions,
    ...DebtModel.definitions,
    ...MetaDataModel.definitions,
    ...MonetaryModel.definitions,
    ...ExpandedDate.definitions,
    ...CreditorModel.definitions,
    ...PersonModel.definitions,
    ...PersonNameModel.definitions,
    ...PostalAddressModel.definitions,
    ...EmailModel.definitions,
    ...PhoneModel.definitions,
    ...DisputeContextHelpModel.definitions,
    ...CreditorTaskModel.definitions,
    ...DebtVerificationModel.definitions,
    ...FileRequestModel.definitions,
    ...RemoteFileModel.definitions,
    ...FileRequestReviewModel.definitions
  ]
} as unknown as DocumentNode<IDisputeTaskView, IDisputeTaskViewVariables>;
export const DisputeTaskViewByBusinessKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DisputeTaskViewByBusinessKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "businessKey" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skipFileRequestReviews" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: false }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "tasks" },
            name: { kind: "Name", value: "camunda_Tasks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "name" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "businessKey" },
                value: { kind: "Variable", name: { kind: "Name", value: "businessKey" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstResult" },
                value: { kind: "IntValue", value: "0" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "maxResults" },
                value: { kind: "IntValue", value: "1" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DisputeTaskModel" } }]
            }
          }
        ]
      }
    },
    ...DisputeTaskModel.definitions,
    ...DebtTaskModel.definitions,
    ...CamundaTaskModel.definitions,
    ...CamundaKeyValueModel.definitions,
    ...CamundaProcessInstanceModel.definitions,
    ...DebtModel.definitions,
    ...MetaDataModel.definitions,
    ...MonetaryModel.definitions,
    ...ExpandedDate.definitions,
    ...CreditorModel.definitions,
    ...PersonModel.definitions,
    ...PersonNameModel.definitions,
    ...PostalAddressModel.definitions,
    ...EmailModel.definitions,
    ...PhoneModel.definitions,
    ...DisputeContextHelpModel.definitions,
    ...CreditorTaskModel.definitions,
    ...DebtVerificationModel.definitions,
    ...FileRequestModel.definitions,
    ...RemoteFileModel.definitions,
    ...FileRequestReviewModel.definitions
  ]
} as unknown as DocumentNode<IDisputeTaskViewByBusinessKey, IDisputeTaskViewByBusinessKeyVariables>;
export const CompleteDisputeTaskDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CompleteDisputeTask" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "taskId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "variables" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "camunda_KeyValuePairInput" } }
            }
          }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_camunda_Task_complete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "taskId" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "variables" },
                value: { kind: "Variable", name: { kind: "Name", value: "variables" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ICompleteDisputeTask, ICompleteDisputeTaskVariables>;
export const UpdateDisputeTaskDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateDisputeTask" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "taskId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "variables" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "camunda_KeyValuePairInput" } }
          }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skipFileRequestReviews" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: false }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_camunda_Task_update" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "taskId" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "variables" },
                value: { kind: "Variable", name: { kind: "Name", value: "variables" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DisputeTaskModel" } }]
            }
          }
        ]
      }
    },
    ...DisputeTaskModel.definitions,
    ...DebtTaskModel.definitions,
    ...CamundaTaskModel.definitions,
    ...CamundaKeyValueModel.definitions,
    ...CamundaProcessInstanceModel.definitions,
    ...DebtModel.definitions,
    ...MetaDataModel.definitions,
    ...MonetaryModel.definitions,
    ...ExpandedDate.definitions,
    ...CreditorModel.definitions,
    ...PersonModel.definitions,
    ...PersonNameModel.definitions,
    ...PostalAddressModel.definitions,
    ...EmailModel.definitions,
    ...PhoneModel.definitions,
    ...DisputeContextHelpModel.definitions,
    ...CreditorTaskModel.definitions,
    ...DebtVerificationModel.definitions,
    ...FileRequestModel.definitions,
    ...RemoteFileModel.definitions,
    ...FileRequestReviewModel.definitions
  ]
} as unknown as DocumentNode<IUpdateDisputeTask, IUpdateDisputeTaskVariables>;
export const ClaimedTasksListQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClaimedTasksListQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assigneeId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skipFileRequestReviews" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: true }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "tasks" },
            name: { kind: "Name", value: "camunda_Tasks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "assignee" },
                value: { kind: "Variable", name: { kind: "Name", value: "assigneeId" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isAssigned" },
                value: { kind: "BooleanValue", value: true }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "name" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstResult" },
                value: { kind: "IntValue", value: "0" }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "maxResults" },
                value: { kind: "IntValue", value: "100" }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DisputeTaskModel" } }]
            }
          }
        ]
      }
    },
    ...DisputeTaskModel.definitions,
    ...DebtTaskModel.definitions,
    ...CamundaTaskModel.definitions,
    ...CamundaKeyValueModel.definitions,
    ...CamundaProcessInstanceModel.definitions,
    ...DebtModel.definitions,
    ...MetaDataModel.definitions,
    ...MonetaryModel.definitions,
    ...ExpandedDate.definitions,
    ...CreditorModel.definitions,
    ...PersonModel.definitions,
    ...PersonNameModel.definitions,
    ...PostalAddressModel.definitions,
    ...EmailModel.definitions,
    ...PhoneModel.definitions,
    ...DisputeContextHelpModel.definitions,
    ...CreditorTaskModel.definitions,
    ...DebtVerificationModel.definitions,
    ...FileRequestModel.definitions,
    ...RemoteFileModel.definitions,
    ...FileRequestReviewModel.definitions
  ]
} as unknown as DocumentNode<IClaimedTasksListQuery, IClaimedTasksListQueryVariables>;
export const CamundaUsersQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CamundaUsersQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "supervisorsGroup" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "agentsGroup" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "internationalAgentsGroup" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "supervisors" },
            name: { kind: "Name", value: "camunda_Users" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "groupId" },
                value: { kind: "Variable", name: { kind: "Name", value: "supervisorsGroup" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "CamundaUserModel" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regionRestriction" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "setTo" },
                      value: { kind: "BooleanValue", value: false }
                    }
                  ],
                  directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
                }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "agents" },
            name: { kind: "Name", value: "camunda_Users" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "groupId" },
                value: { kind: "Variable", name: { kind: "Name", value: "agentsGroup" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "CamundaUserModel" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regionRestriction" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "setTo" },
                      value: { kind: "BooleanValue", value: false }
                    }
                  ],
                  directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
                }
              ]
            }
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "internationalAgents" },
            name: { kind: "Name", value: "camunda_Users" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "groupId" },
                value: { kind: "Variable", name: { kind: "Name", value: "internationalAgentsGroup" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "CamundaUserModel" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regionRestriction" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "setTo" },
                      value: { kind: "BooleanValue", value: true }
                    }
                  ],
                  directives: [{ kind: "Directive", name: { kind: "Name", value: "client" } }]
                }
              ]
            }
          }
        ]
      }
    },
    ...CamundaUserModel.definitions
  ]
} as unknown as DocumentNode<ICamundaUsersQuery, ICamundaUsersQueryVariables>;
export const DisputeTasksStatsQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DisputeTasksStatsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "taskName" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "forUserId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "isAssigned" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filterJson" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "taskStats" },
            name: { kind: "Name", value: "camunda_Tasks_stats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "taskName" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "assignee" },
                value: { kind: "Variable", name: { kind: "Name", value: "forUserId" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isAssigned" },
                value: { kind: "Variable", name: { kind: "Name", value: "isAssigned" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterJson" },
                value: { kind: "Variable", name: { kind: "Name", value: "filterJson" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "count" } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<IDisputeTasksStatsQuery, IDisputeTasksStatsQueryVariables>;
export const DisputeTasksListQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DisputeTasksListQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "taskName" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "forUserId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "isAssigned" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filterJson" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "firstResult" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "maxResults" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skipFileRequestReviews" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: true }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "tasks" },
            name: { kind: "Name", value: "camunda_Tasks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: { kind: "Variable", name: { kind: "Name", value: "taskName" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "assignee" },
                value: { kind: "Variable", name: { kind: "Name", value: "forUserId" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isAssigned" },
                value: { kind: "Variable", name: { kind: "Name", value: "isAssigned" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filterJson" },
                value: { kind: "Variable", name: { kind: "Name", value: "filterJson" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "firstResult" },
                value: { kind: "Variable", name: { kind: "Name", value: "firstResult" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "maxResults" },
                value: { kind: "Variable", name: { kind: "Name", value: "maxResults" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DisputeTaskModel" } }]
            }
          }
        ]
      }
    },
    ...DisputeTaskModel.definitions,
    ...DebtTaskModel.definitions,
    ...CamundaTaskModel.definitions,
    ...CamundaKeyValueModel.definitions,
    ...CamundaProcessInstanceModel.definitions,
    ...DebtModel.definitions,
    ...MetaDataModel.definitions,
    ...MonetaryModel.definitions,
    ...ExpandedDate.definitions,
    ...CreditorModel.definitions,
    ...PersonModel.definitions,
    ...PersonNameModel.definitions,
    ...PostalAddressModel.definitions,
    ...EmailModel.definitions,
    ...PhoneModel.definitions,
    ...DisputeContextHelpModel.definitions,
    ...CreditorTaskModel.definitions,
    ...DebtVerificationModel.definitions,
    ...FileRequestModel.definitions,
    ...RemoteFileModel.definitions,
    ...FileRequestReviewModel.definitions
  ]
} as unknown as DocumentNode<IDisputeTasksListQuery, IDisputeTasksListQueryVariables>;
export const SetDisputeTaskAssigneeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SetDisputeTaskAssignee" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "taskId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assigneeId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skipFileRequestReviews" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
          defaultValue: { kind: "BooleanValue", value: true }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "update_camunda_Task_claim" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "taskId" } }
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: { kind: "Variable", name: { kind: "Name", value: "assigneeId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "DisputeTaskModel" } }]
            }
          }
        ]
      }
    },
    ...DisputeTaskModel.definitions,
    ...DebtTaskModel.definitions,
    ...CamundaTaskModel.definitions,
    ...CamundaKeyValueModel.definitions,
    ...CamundaProcessInstanceModel.definitions,
    ...DebtModel.definitions,
    ...MetaDataModel.definitions,
    ...MonetaryModel.definitions,
    ...ExpandedDate.definitions,
    ...CreditorModel.definitions,
    ...PersonModel.definitions,
    ...PersonNameModel.definitions,
    ...PostalAddressModel.definitions,
    ...EmailModel.definitions,
    ...PhoneModel.definitions,
    ...DisputeContextHelpModel.definitions,
    ...CreditorTaskModel.definitions,
    ...DebtVerificationModel.definitions,
    ...FileRequestModel.definitions,
    ...RemoteFileModel.definitions,
    ...FileRequestReviewModel.definitions
  ]
} as unknown as DocumentNode<ISetDisputeTaskAssignee, ISetDisputeTaskAssigneeVariables>;
export const AccountViewSsnDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountViewSSN" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "accountId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } }
        }
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "accountSSN" },
            name: { kind: "Name", value: "DebtOut" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "accountId" } }
              }
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "AccountModel" } },
                { kind: "Field", name: { kind: "Name", value: "ssn_last4" } }
              ]
            }
          }
        ]
      }
    },
    ...AccountModel.definitions,
    ...MonetaryModel.definitions,
    ...ExpandedDate.definitions,
    ...DebtModel.definitions,
    ...MetaDataModel.definitions,
    ...CreditorModel.definitions,
    ...PersonModel.definitions,
    ...PersonNameModel.definitions,
    ...PostalAddressModel.definitions,
    ...EmailModel.definitions,
    ...PhoneModel.definitions,
    ...CreditorOutModel.definitions,
    ...BalanceDetails.definitions
  ]
} as unknown as DocumentNode<IAccountViewSsn, IAccountViewSsnVariables>;

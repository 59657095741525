import type { QueryHookOptions } from "@apollo/client/react/types/types";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { Skeleton } from "@mui/material";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import type { ReactElement, ReactNode } from "react";
import React from "react";
import { useSafeQuery } from "~/core/utils/apollo";
import type { BlockProps } from "./Block";
import { Block } from "./Block";

interface QueryBlockProps<TData, TVariables> extends Omit<BlockProps, "children"> {
  children: (data: TData, isLoading: boolean) => ReactNode;
  query: TypedDocumentNode<TData, TVariables>;
  variables: TVariables;
  options?: QueryHookOptions<TData, TVariables>;
}

export function QueryBlock<TData, TVariables>(props: QueryBlockProps<TData, TVariables>): ReactElement {
  const theme = useTheme();
  const { children, query, variables, options, ...rest } = props;
  const { data, loading } = useSafeQuery(query, { ...options, variables });
  return (
    <Block {...rest} isLoading={loading}>
      {!loading && !data ? (
        <Typography variant={"body2"} color={theme.palette.error.main}>
          Failed to fetch data from the server.
        </Typography>
      ) : data ? (
        children(data, loading)
      ) : (
        <BlockSkeleton />
      )}
    </Block>
  );
}

function BlockSkeleton(): ReactElement {
  return (
    <Stack>
      <Skeleton animation="wave" width="50%" />
      <Skeleton variant="rectangular" width="90%" height={100} animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" width="80%" />
    </Stack>
  );
}

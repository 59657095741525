import { FormControl, RadioGroup } from "@mui/material";
import type { ReactElement } from "react";
import { useState } from "react";
import { titleCase } from "~/core/components/utils/content";
import { useCrmApi } from "~/core/contexts/ApiContext";
import { useUser } from "~/core/contexts/UserContext";
import type { IFileRequestModel, IFileRequestReviewModel, IPersonModel } from "~/gql/types";
import { IFileReviewStatus } from "~/gql/types";
import { DisputeActionItem } from "./DisputeActionItem";

export type ReviewAction = "approve" | "reject";

export interface IDisputeActionFormProps {
  debtId: string;
  taskId: string;
  debtor: IPersonModel;
  fileRequest: IFileRequestModel;
  fileRequestReview: IFileRequestReviewModel;
  onComplete: (updatedReview: IFileRequestReviewModel) => Promise<void>;
}

export function DisputeActionForm({
  onComplete,
  fileRequest,
  fileRequestReview,
  debtor,
  debtId
}: IDisputeActionFormProps): ReactElement {
  const [action, setAction] = useState<ReviewAction | null>(null);
  const crmApi = useCrmApi();
  const personName = debtor.fullName;
  const docsType = titleCase(fileRequest.title ?? "unknown type");
  const user = useUser();
  const onApprove = async (comment: string, shouldPostComment: boolean): Promise<void> => {
    if (shouldPostComment) {
      await crmApi.commentsControllerAddComment({
        objectId: debtId,
        comment: comment,
        userName: user.name,
      });
    }
    await onComplete({
      ...fileRequestReview,
      comment,
      status: IFileReviewStatus.Approved,
      reviewTimestamp: new Date()
    });
  };

  const onReject = async (comment: string, shouldPostComment: boolean): Promise<void> => {
    if (shouldPostComment) {
      await crmApi.commentsControllerAddComment({
        objectId: debtId,
        comment: comment,
        userName: user.name,
      });
    }
    await onComplete({
      ...fileRequestReview,
      comment,
      status: IFileReviewStatus.Rejected,
      reviewTimestamp: new Date()
    });
  };

  return (
    <FormControl component="fieldset" fullWidth={true}>
      <RadioGroup
        value={action}
        onChange={evt => setAction(evt.target.value as ReviewAction)}
        name="radio-buttons-group">
        <DisputeActionItem
          value="approve"
          title={`Accept Documents`}
          actionButtonTitle="Accept"
          isSelected={action === "approve"}
          resetState={() => setAction(null)}
          onAction={onApprove}
          successMessage={`Successfully approved provided documents for a dispute of ${personName}.`}
          failureMessage={`Failed to approve documents for a dispute of ${personName}.`}
          confirmationTitle={titleCase(`Approve ${docsType} Documents?`)}
          confirmationBody={
            <>
              Are you sure you want to approve validity of the {docsType} documents for a dispute of{" "}
              <strong>{personName}</strong>?
            </>
          }
          confirmationActionTitle="Yes, Accept"
        />

        <DisputeActionItem
          value="reject"
          title={`Reject Documents`}
          actionButtonTitle="Reject"
          isSelected={action === "reject"}
          resetState={() => setAction(null)}
          onAction={onReject}
          successMessage={`Successfully rejected provided documents for a dispute of ${personName}.`}
          failureMessage={`Failed to reject documents for a dispute of ${personName}.`}
          confirmationTitle={titleCase(`Reject ${docsType} Documents?`)}
          confirmationBody={
            <>
              Are you sure you want to reject {docsType} documents for this dispute of{" "}
              <strong>{personName}</strong> as invalid?
            </>
          }
          confirmationActionTitle={`Yes, Reject`}
        />
      </RadioGroup>
    </FormControl>
  );
}

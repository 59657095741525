import { Stack, Typography } from "@mui/material";
import type { Variant } from "@mui/material/styles/createTypography";
import { countryCodeEmoji } from "country-code-emoji";
import type { ReactElement } from "react";
import type { IPostalAddressModel } from "~/gql/types";
import { catchToNull } from "~/models/utils/helpers";

export interface IAddressProps {
  address: IPostalAddressModel | undefined;
  variant?: Variant;
  isBolder?: boolean;
}

export function Address({ variant, address, isBolder }: IAddressProps): ReactElement {
  const fontWeight = isBolder ? "600" : "normal";
  return (
    <Stack>
      {address?.street_line1 && (
        <Typography fontWeight={fontWeight} variant={variant}>
          {address?.street_line1},
        </Typography>
      )}
      {address?.street_line2 && (
        <Typography fontWeight={fontWeight} variant={variant}>
          {address?.street_line2},
        </Typography>
      )}
      {address?.street_line3 && (
        <Typography fontWeight={fontWeight} variant={variant}>
          {address?.street_line3},
        </Typography>
      )}
      <Typography fontWeight={fontWeight} variant={variant}>
        {address?.city}, {address?.state}, {address?.zipcode}{" "}
        {address?.country_code && (
          <span title={address?.country_code}>
            {catchToNull(() => countryCodeEmoji(address?.country_code ?? ""))}
          </span>
        )}
      </Typography>
    </Stack>
  );
}

import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { Tooltip } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";

interface IBlueDot {
  tooltip?: string;
  color?: string;
  keepPosition?: boolean;
}

export function BlueDot({ tooltip, color, keepPosition }: IBlueDot): ReactElement {
  const theme = useTheme();
  const inner = (
    <Box
      sx={{
        borderRadius: "50%",
        width: "8px",
        height: "8px",
        backgroundColor: color ? color : theme.palette.primary.dark,
        position: "relative",
        left: keepPosition ? "0px" : "-6px"
      }}
    />
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{inner}</Tooltip>;
  } else {
    return inner;
  }
}

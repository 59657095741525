import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { DialogTitle } from "@mui/material";
import type { Dispatch, ReactElement, ReactNode, SetStateAction } from "react";
import * as React from "react";

export interface ConfirmationState {
  title: string;
  body?: ReactNode;
  isOpen: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => Promise<unknown>;
  onCancel?: () => Promise<void>;
  onLoading?: () => Promise<void>;
  onFinish?: () => Promise<void>;
  onError?: (error: unknown) => Promise<void>;
}

export interface ConfirmationDialogActions {
  confirmState: ConfirmationState;
  closeConfirm: () => void;
  openConfirm: (opts: Omit<ConfirmationState, "isOpen">) => void;
  setConfirmation: Dispatch<SetStateAction<ConfirmationState>>;
}

export function useConfirmationDialog(
  initialState: Partial<ConfirmationState> = {}
): ConfirmationDialogActions {
  const [confirmation, setConfirmation] = React.useState<ConfirmationState>({
    title: "Are you sure?",
    isOpen: false,
    cancelLabel: "Cancel",
    confirmLabel: "Confirm",
    onConfirm: () => {
      throw "onConfirm for ConfirmationDialog is not defined.";
    },
    onCancel: async () => {},
    onLoading: async () => {},
    onFinish: async () => {},
    onError: async (error: unknown) => {},
    ...initialState
  });
  return {
    confirmState: confirmation,
    setConfirmation,
    openConfirm: opts =>
      setConfirmation(prevState => ({
        ...prevState,
        ...opts,
        isOpen: true
      })),
    closeConfirm: () =>
      setConfirmation(prevState => ({
        ...prevState,
        isOpen: false
      }))
  };
}

export interface IConfirmationDialogProps {
  state: ConfirmationState;
  onClose: () => void;
}

export function ConfirmationDialog({ state, onClose }: IConfirmationDialogProps): ReactElement {
  const [isLoading, setIsLoading] = React.useState(false);
  const onConfirm = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await state.onLoading?.();
      await state.onConfirm();
      await state.onFinish?.();
    } catch (error) {
      await state.onError?.(error);
    } finally {
      setIsLoading(false);
    }
    onClose();
  };
  const onCancel = async (): Promise<void> => {
    await state.onCancel?.();
    setIsLoading(false);
    onClose();
  };
  return (
    <Dialog
      keepMounted
      open={isLoading || state.isOpen}
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{state.title}</DialogTitle>
      {state.body && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{state.body}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button disabled={isLoading} onClick={onCancel} color="primary">
          {state.cancelLabel ?? "Cancel"}
        </Button>

        {isLoading ? (
          <Button
            disabled={true}
            variant="contained"
            color="primary"
            startIcon={<CircularProgress size={20} />}>
            {state.confirmLabel ?? "Confirm"}
          </Button>
        ) : (
          <Button onClick={onConfirm} variant="contained" color="primary">
            {state.confirmLabel ?? "Confirm"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

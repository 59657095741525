import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { gql, useQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import styled from "@mui/material/styles/styled";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import DoubleArrow from "../../../../core/assets/double-up-arrow_svg.svg";
import DoubleArrowBlue from "../../../../core/assets/double-up-arrow_svg-blue.svg";
import _ from "lodash";
import * as React from "react";
import { useSafeQuery } from "~/core/utils/apollo";
import type { ICommentsByObjectIds, IDebtLogEntryModel, IPaymentPlanOutModel } from "~/gql/types";
import { ProgressIndicator } from "../../widgets/ProgressIndicator";
import { Block } from "../blocks/Block";
import { DebtOutBalancesQuery } from "../blocks/CustomerInformationBlock";
import { ContactBlockButtons } from "../widgets/ContactBlockButtons";
import { AccountInformation } from "./AccountInformation";
import { CreditorBlock } from "./CreditorBlock";
import { CustomerContact } from "./CustomerContact";
import { PaymentHistory } from "./PaymentHistory";
import { HeaderDebtor } from "./HeaderDebtor";
import HistoryHighlights from "./HistoryHighlights";
import { AccountNotes, CommentsQuery } from "./AccountNotes";
import { AccountNotesAddComment } from "./AccountNotesAC";
import { ModalAccountNotes } from "./ModalAccountNotes";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { SideBar } from "./Sidebar";
import Typography from "@mui/material/Typography";
import type { IAccountModel, IDebtOutBalanceModel, IPersonOutModel, Maybe } from "~/gql/types";
import { ThreeDotsButton } from "../../widgets/ThreeDotsButton";
import AlertTitle from "@mui/material/AlertTitle";
import { ExternalLink } from "../../widgets/ExternalLink";
import { urlToDebtorLegacy } from "~/core/utils/routing";
import { useGenericConfig } from "~/core/config/context";
import { PaymentPlanOutQuery, PlanDetails } from "./PlanDetails";
import { PlanGuidelines } from "./PlanGuidelines";
import { ViewPlan } from "./ViewPlan";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Bankruptcy } from "./Bankruptcy";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveCreditorId } from "~/solo/features/creditor/creditor";
import { convertQueryToString, ISearchResults } from "~/solo/pages/accounts/SearchPage";
export interface IAccountViewProps {
  account: IAccountModel;
}

export const AccountViewQuery = gql(`
query AccountView($accountId: ID!) {
  account: DebtOut(id: $accountId) {
    ...AccountModel
  }
  debt: Debt(id: $accountId) {
    ...DebtModel
  }
}
`);

export const PersonOutQuery = gql(`
query PersonOutQuery($personId: ID!) {
  personOut: PersonOut(id: $personId) {
    ...PersonOutModel
  }
}
`);

export const StyledPopper = styled(Popper)`
  && {
    z-index: 1;
    .arrow {
      position: absolute;
      font-size: 10px;
      left: 186px;
      top: -11px;
      width: 0;
      height: 0;
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-bottom: 1em solid #a8abad;
      margin-left: -0.9em;
      &:before {
        border-width: 1em 1em 1em 0;
        border-color: transparent transparent transparent transparent;
      }
    }
  }
`;
const useStyles = makeStyles({
  iconClass: {
    "& .MuiAlert-icon": {
      color: "#FFF",
      backgroundColor: "rgba(0, 116, 217, 1)",
      padding: "11px",
      fontSize: "2em",
      "& .MuiSvgIcon-root": {
        position: "relative",
        top: "10px"
      }
    }
  },
  iconClassWarning: {
    "& .MuiAlert-icon": {
      color: "#FFF",
      backgroundColor: "#FF9F4D",
      padding: "11px",
      fontSize: "2em",
      "& .MuiSvgIcon-root": {
        position: "relative",
        top: "10px"
      }
    }
  }
});

const StyledTableRow = styled(TableRow)(() => ({
  "&.MuiTableRow-root:hover": {
    boxShadow: "0px 2px 9px -4px rgba(0,0,0,0.75)"
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#EAEAEB"
  }
}));

interface BoxAccountNotesProps {
  width: number;
}

const BoxAccountNotesClass = makeStyles({
  accountNotesClass: {
    position: "fixed",
    bottom: (props: BoxAccountNotesProps) => (props.width >= 1700 ? "-43px" : "-24px"),
    right: 0,
    height: (props: BoxAccountNotesProps) => (props.width >= 1700 ? "90px" : "62px"),
    width: (props: BoxAccountNotesProps) => (props.width >= 1700 ? "500px" : "328px"),
    borderRadius: "4px",
    boxShadow: "-5px -4px 13px -9px rgba(0, 0, 0,0.75)",
    zIndex: 3
  }
});

const BoxAccountNotes = ({
  children,
  width
}: {
  children: React.ReactElement;
  width: number;
}): React.ReactElement => {
  const { accountNotesClass } = BoxAccountNotesClass({ width: width });
  return <div className={`${accountNotesClass}`}>{children}</div>;
};

export interface IAccountViewProps {
  account: IAccountModel;
  refetchAccountQuery: () => void;
}

export function AccountView({ account, refetchAccountQuery }: IAccountViewProps): React.ReactElement | null {
  const { data: debtOutData, refetch: debtOutRefetch } = useSafeQuery(DebtOutBalancesQuery, {
    variables: { debtId: account.debt?.id ?? "undefined", debtorId: account.debt?.person?.id ?? "undefined" },
    skip: !(account.debt?.id ?? account.debt?.person?.id)
  });

  const { data: paymentPlanData, refetch: paymentPlanRefetch } = useSafeQuery(PaymentPlanOutQuery, {
    variables: { planId: account.payment_plan_id ?? "" },
    skip: _.isNil(account.payment_plan_id)
  });

  const { data: comments, refetch: refetchCommentsQuery } = useQuery(CommentsQuery, {
    variables: { ids: [account.debt?.id ?? "", account.debt?.person?.id ?? ""] }
  });

  const [isRefetchQueries, setRefetchQueries] = React.useState(false);

  React.useEffect(() => {
    if (isRefetchQueries) {
      Promise.allSettled([debtOutRefetch(), paymentPlanRefetch(), refetchAccountQuery()]).finally(() => {
        setRefetchQueries(false);
      });
    }
  }, [isRefetchQueries]);

  if (debtOutData?.personOut && debtOutData?.debtOutBalance) {
    return (
      <AccountViewContent
        personOut={debtOutData.personOut}
        paymentPlanOut={paymentPlanData?.paymentPlanOut}
        debtOutBalance={debtOutData.debtOutBalance}
        account={account}
        refetchDebOutBalanceAndPaymentPlanOut={() => setRefetchQueries(true)}
        comments={comments}
        refetchCommentsQuery={refetchCommentsQuery}
      />
    );
  }
  return <ProgressIndicator message={`Loading consumer information...`} />;
}

export default React.memo(AccountView);

interface AccountViewContentProps {
  personOut: IPersonOutModel;
  account: IAccountModel;
  debtOutBalance: IDebtOutBalanceModel;
  paymentPlanOut: IPaymentPlanOutModel | undefined | null;
  refetchDebOutBalanceAndPaymentPlanOut: () => void;
  comments: ICommentsByObjectIds | undefined;
  refetchCommentsQuery: () => void;
}

export function AccountViewContent({
  account,
  personOut,
  debtOutBalance,
  paymentPlanOut,
  refetchDebOutBalanceAndPaymentPlanOut,
  comments,
  refetchCommentsQuery
}: AccountViewContentProps): React.ReactElement | null {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [shouldMoveResults, setMoveResults] = React.useState(true);
  const [shouldShowBannerSize, setShowBannerSize] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAccountNotesOpen, setAccountNotesOpen] = React.useState(false);
  const [isOpenAddComment, setAddComment] = React.useState(false);
  const [isSetBackgroundColor, setBackgroundColor] = React.useState(true);
  const [isOpenModal, setOpenModal] = React.useState(false);
  const [isOpenSnackBar, setOpenSnackBar] = React.useState(false);
  const [isOpen, setOpenDrawer] = React.useState(false);

  const [isViewPlan, setViewPlan] = React.useState(false);
  const [isBK, setIsBK] = React.useState(false);
  const [valueCopied, setValueToCopy] = React.useState("");
  const { appConfig } = useGenericConfig();

  const [hasTriggeredLoadMoreTimeline, setTriggerLoadMoreTimeline] = React.useState(false);
  const debt = account.debt;
  const creditor = account.debt?.creditor;
  const [sortedHighLightsData, setSortedHighLightsData] =
    React.useState<{ log_entries: IDebtLogEntryModel[] }>();

  const logEntries = debtOutBalance.log_entries;

  React.useEffect(() => {
    const filterLogEntries = _.compact(_.orderBy(logEntries, entry => entry?.meta?.time_created)).reverse();
    setSortedHighLightsData({ log_entries: filterLogEntries });
  }, [logEntries]);
  const [searchTerms, setSearchTerms] = React.useState<string[]>([]);

  const [width, setWidth] = React.useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const [isOpenAdvanceSearch, setOpenAdvanceSearch] = React.useState(false);
  const [isOnlySearch, setOnlySearch] = React.useState(false);

  const [searchResults, setSearchResults] = React.useState<ISearchResults | undefined>();

  function getButtons(): React.ReactElement {
    return (
      <Stack direction="row" marginLeft={"auto !important"} alignItems="center">
        <ControlPointIcon
          onClick={openAccountAddComment}
          sx={{
            marginRight: 1.25,
            cursor: isOpenAddComment ? "unset" : "pointer",
            color: isOpenAddComment
              ? theme.palette.grey[800]
              : isSetBackgroundColor
              ? theme.palette.common.white
              : theme.palette.primary.dark
          }}
        />

        <Box
          display="flex"
          sx={{ cursor: "pointer", color: theme.palette.common.white }}
          onClick={e => {
            e.stopPropagation();
            handleClickOpenAN();
          }}>
          <img
            src={isSetBackgroundColor ? DoubleArrow : DoubleArrowBlue}
            width="30px"
            height="22px"
            alt="Open Account Notes"
          />
        </Box>
      </Stack>
    );
  }

  function handleClickOpenAN() {
    setAccountNotesOpen(true);
    setBackgroundColor(false);
  }

  const handleLoadMoreTimeline = (): void => setTriggerLoadMoreTimeline(true);

  function closeAccountNotes() {
    setAccountNotesOpen(false);
  }

  function closeAccountNotesAddComment() {
    setAddComment(false);
  }

  function openAccountAddComment(e: React.SyntheticEvent) {
    e.stopPropagation();
    setAddComment(true);
    setBackgroundColor(false);
  }

  function onCloseSnackBar() {
    setOpenSnackBar(false);
  }

  function onCloseSnackBarCopied() {
    setValueToCopy("");
  }

  const isJustTheCurrentAccount = personOut?.debts?.length === 1;
  const truncatedCreditorName = _.truncate(account.creditor?.company_name ?? "", {
    length: !isJustTheCurrentAccount ? 19 : 34,
    omission: "…"
  });

  function refetchComments(): boolean {
    return true;
  }

  function isThirdParty(state: boolean): boolean {
    return state;
  }

  const firstFixedBreakPoint = 1080;
  const middleRelativeBreakPoint = 1300;
  const secondFixedBreakpoint = 1440;
  const threeColumnLayoutBreakpoint = 1700;

  let accountNotesWidth;
  let rightPositionNoteInProgress;
  let rightPositionAddNote;
  let inputSearchBarSize;
  let inputsAdvancedSearch;
  if (width <= firstFixedBreakPoint) {
    accountNotesWidth = "327px";
    rightPositionNoteInProgress = "375px";
    rightPositionAddNote = "370px";
    inputSearchBarSize = "630px";
    inputsAdvancedSearch = "290px";
  } else if (width > firstFixedBreakPoint && width <= middleRelativeBreakPoint) {
    accountNotesWidth = "480px";
    rightPositionNoteInProgress = "466px";
    rightPositionAddNote = "544px";
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  } else if (width > middleRelativeBreakPoint && width <= secondFixedBreakpoint) {
    accountNotesWidth = "513px";
    rightPositionNoteInProgress = "550px";
    rightPositionAddNote = "550px";
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  } else if (width > secondFixedBreakpoint && width < threeColumnLayoutBreakpoint) {
    accountNotesWidth = "510px";
    rightPositionNoteInProgress = "570px";
    rightPositionAddNote = "550px";
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  } else if (width >= threeColumnLayoutBreakpoint) {
    accountNotesWidth = "576px";
    rightPositionNoteInProgress = "550px";
    rightPositionAddNote = "640px";
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  }
  const [creditorId, setCreditorId] = React.useState<string | undefined>("");

  function sendCreditorSelected(value: string | undefined): void {
    dispatch(saveCreditorId(value ?? ""));
    setCreditorId(value);
  }

  const [isSearchTermDeleted, searchTermDeleted] = React.useState(false);
  React.useEffect(() => {
    if (isSearchTermDeleted) {
      const searchResultsResult = searchResults?.results.map(item => {
        item.isCollapse = false;
        item.isCollapsePhone = false;
        return item;
      });
      setSearchResults({ results: searchResultsResult ?? [] });
      searchTermDeleted(false);
    }
  }, [isSearchTermDeleted]);

  if (!creditor || !debt) {
    return null;
  }
  return (
    <>
      {!isOnlySearch && (
        <Stack position="relative" pl={2} pt={"0px !important"} ml={6}>
          <Grid container direction={"row"} spacing={1} position="relative" left={"-23px"}>
            <Grid item xs={1} flexBasis={0} flexDirection="column" display="flex">
              <SideBar
                handleSearch={(isClickedSearch: boolean) => setOnlySearch(isClickedSearch)}
                isOpenAdvanceSearch={isOpenAdvanceSearch}
                setOpenAdvanceSearch={setOpenAdvanceSearch}
                searchTerms={searchTerms}
                setSearchTerms={setSearchTerms}
                inputSearchBarSize={inputSearchBarSize}
                inputsAdvancedSearch={inputsAdvancedSearch}
                sendCreditorSelected={sendCreditorSelected}
                shouldMoveResults={shouldMoveResults}
                setMoveResults={value => setMoveResults(value)}
                executeSearch={async items => {
                  const hasCreditor = items?.some(item => item.includes("Creditor:"));
                  items = items?.filter(item => !item.includes("Creditor:"));
                  const searchQuery = convertQueryToString(items);
                  if (!hasCreditor) {
                    history.push(`/app/accounts/search/advance?searchQuery=${searchQuery}`);
                  } else if (searchQuery != "") {
                    history.push(
                      `/app/accounts/search/advance?searchQuery=${searchQuery}&creditorId=${creditorId}`
                    );
                  } else if (searchQuery === "" && creditorId !== "") {
                    history.push(`/app/accounts/search/advance?creditorId=${creditorId}`);
                  }
                }}
                searchTermDeleted={(value: boolean) => searchTermDeleted(value)}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid
                ml={1.8}
                pt={"0px !important"}
                item
                xs={12}
                position={"fixed"}
                zIndex={1}
                pl={isOpen ? 1 : 2.75}
                width={isOpen ? "calc(100% - 167px)" : "calc(100% - 31px)"}>
                <HeaderDebtor
                  debtOutBalance={debtOutBalance}
                  openDrawer={isOpen}
                  account={account}
                  personOut={personOut}
                  width={width}
                  isViewPlan={isViewPlan}
                  isJustTheCurrentAccount={isJustTheCurrentAccount}
                  isBK={isBK}
                  setIsBK={() => setIsBK(true)}
                />
              </Grid>
              {!isViewPlan && !isBK && (
                <>
                  <Grid
                    mt={width >= 1700 && isOpen ? 22.5 : 22.625}
                    ml={"50px"}
                    mb={3}
                    xs={12}
                    pt={"0px !important"}
                    item>
                    <Box width={"calc(100% - -4px)"} height={"76px"} marginTop={"15px"}>
                      <Alert
                        action={
                          <Box position={"relative"} top={"31px"} left={"-10px"}>
                            <ExternalLink
                              href={urlToDebtorLegacy(debt?.person?.id ?? "", appConfig)}
                              title="Go to Current CE"
                              color={theme.palette.primary.dark}
                              variant={"h5"}
                            />
                          </Box>
                        }
                        className={classes.iconClass}
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.common.black,
                          position: "relative",
                          left: "-6px",
                          marginTop: isOpen
                            ? debt.status && debt.status.length >= 15
                              ? 2.75
                              : 0.7
                            : "unset",
                          padding: "0px 15px",
                          paddingLeft: "0px !important",
                          border: `2px solid ${theme.palette.primary.dark}`
                        }}
                        severity="info">
                        <AlertTitle>
                          <Stack direction={"row"}>
                            <Typography variant={"h5"} mt={1.14}>
                              Banners and Disclosures
                            </Typography>
                          </Stack>
                        </AlertTitle>
                        <Typography variant={"h6"}>Use current CE for all Banners and Disclosures</Typography>
                      </Alert>
                    </Box>
                  </Grid>

                  <Grid container ml={6.625} pl={0} py={0}>
                    <Grid item xs={11} sm={6} md={6} lg={width >= 1700 ? 4 : 0} pr={3}>
                      <Stack direction={"column"}>
                        <Block
                          paddingTopContent={"0px"}
                          customColorDivider={theme.palette.grey[200]}
                          titleTypography="h2"
                          tooltipValueTitle={isJustTheCurrentAccount ? account.creditor?.company_name : null}
                          isCard
                          title={truncatedCreditorName}
                          titlePostfix={<ThreeDotsButton alignRight />}>
                          <CreditorBlock
                            onCopy={type => setValueToCopy(type)}
                            drawerOpen={isOpen}
                            account={account}
                            debt={debt}
                          />
                        </Block>
                        <Box pt={3}>
                          <Block
                            paddingTopContent={"0px"}
                            customColorDivider={theme.palette.grey[200]}
                            title={"Debt Summary"}
                            titleTypography="h2"
                            isCard>
                            <AccountInformation
                              debtOutBalance={debtOutBalance}
                              personOut={personOut}
                              account={account}
                              debt={debt}
                            />
                          </Block>
                        </Box>
                        <Box pt={3}>
                          <Block
                            paddingTopContent={"0px"}
                            customColorDivider={theme.palette.grey[200]}
                            title={"Customer Contact"}
                            titleTypography="h2"
                            titlePostfix={<ContactBlockButtons />}
                            isCard>
                            <CustomerContact account={account} />
                          </Block>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={11}
                      sm={6}
                      md={6}
                      lg={width >= 1700 ? 4 : 0}
                      position="relative"
                      pr={0}
                      zIndex={0}>
                      <Stack direction={"column"}>
                        <PlanDetails
                          setViewPlan={() => setViewPlan(true)}
                          account={account}
                          debtOutBalance={debtOutBalance}
                          paymentPlanOut={paymentPlanOut}
                        />
                        <Box pt={3}>
                          <Block
                            paddingTopContent={"0px"}
                            customColorDivider={theme.palette.grey[200]}
                            title={"Plan Guidelines"}
                            titleTypography="h2"
                            isCard>
                            <PlanGuidelines account={account} debt={debt} />
                          </Block>
                        </Box>
                        <Box pt={3}>
                          <Block
                            heightCard={
                              account?.unified_payment_entries && account?.unified_payment_entries?.length > 0
                                ? "unset"
                                : "140px"
                            }
                            paddingTitle={"16px 16px 0px 16px"}
                            paddingTopContent={"5px"}
                            noBottomPadding
                            customColorDivider={theme.palette.grey[200]}
                            title={"Payment History"}
                            titleTypography="h2"
                            isCard>
                            <PaymentHistory
                              account={account}
                              logEntries={sortedHighLightsData?.log_entries}
                            />
                          </Block>
                        </Box>
                        {width < 1700 && (
                          <Stack pt={3} maxHeight={"420px"}>
                            <Block
                              heightCard={
                                sortedHighLightsData?.log_entries &&
                                sortedHighLightsData?.log_entries?.length > 0
                                  ? "unset"
                                  : "50px"
                              }
                              titleTypography="h2"
                              paddingTitle="16px 16px 0px 16px"
                              customColorDivider={theme.palette.grey[200]}
                              noBottomPadding
                              isCard
                              paddingTopContent={"0px"}
                              hasOverflowY={hasTriggeredLoadMoreTimeline}
                              title={"History Highlights"}>
                              <HistoryHighlights
                                entries={sortedHighLightsData}
                                handleLoadMoreTimeline={handleLoadMoreTimeline}
                                debtOutBalanceAll={debtOutBalance}
                              />
                            </Block>
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                    {width >= 1700 && (
                      <Grid item xs={11} sm={6} md={6} lg={4} position="relative" pl={3} pr={0} zIndex={0}>
                        <Stack maxHeight={"420px"}>
                          <Block
                            heightCard={
                              sortedHighLightsData?.log_entries &&
                              sortedHighLightsData?.log_entries?.length > 0
                                ? "unset"
                                : "50px"
                            }
                            titleTypography="h2"
                            paddingTitle="16px 16px 0px 16px"
                            customColorDivider={theme.palette.grey[200]}
                            noBottomPadding
                            isCard
                            paddingTopContent={"0px"}
                            hasOverflowY={hasTriggeredLoadMoreTimeline}
                            title={"History Highlights"}>
                            <HistoryHighlights
                              entries={sortedHighLightsData}
                              handleLoadMoreTimeline={handleLoadMoreTimeline}
                              debtOutBalanceAll={debtOutBalance}
                            />
                          </Block>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              {!isBK && isViewPlan && (
                <Stack
                  ml={6.625}
                  mt={17.125}
                  pt={2}
                  pb={2}
                  pl={2.25}
                  pr={0.475}
                  width={"100%"}
                  sx={{ backgroundColor: theme.palette.common.white }}>
                  <ViewPlan
                    width={width}
                    account={account}
                    debtStateId={account.payment_plan_id}
                    refetchDebOutBalanceAndPaymentPlanOut={() => refetchDebOutBalanceAndPaymentPlanOut()}
                    setViewPlan={() => setViewPlan(false)}
                    paymentPlanOut={paymentPlanOut}
                  />
                </Stack>
              )}

              {isBK && !isViewPlan && (
                <>
                  {shouldShowBannerSize && (
                    <Stack
                      position={"relative"}
                      top="130px"
                      left={"59px"}
                      width={"calc(100% - 54px)"}
                      margin={"-4px 0px"}>
                      <Alert
                        action={
                          <Box
                            position={"relative"}
                            left={"-5px"}
                            top="5px"
                            onClick={() => setShowBannerSize(false)}>
                            <CloseIcon sx={{ cursor: "pointer" }} fontSize="small" />
                          </Box>
                        }
                        className={classes.iconClassWarning}
                        sx={{
                          backgroundColor: theme.palette.common.white,
                          color: theme.palette.common.black,
                          position: "relative",
                          left: "-6px",
                          padding: "0px 15px",
                          paddingLeft: "0px !important",
                          border: `2px solid #FF9F4D`
                        }}
                        severity="info">
                        <AlertTitle>
                          <Stack direction={"row"}>
                            <Typography variant={"h5"} mt={1.14}>
                              Alert
                            </Typography>
                          </Stack>
                        </AlertTitle>
                        <Typography variant={"h6"}>
                          1 or more files have exceeded the file size limit of 48 MB (per file).
                        </Typography>
                      </Alert>
                    </Stack>
                  )}
                  <Stack
                    ml={6.625}
                    mt={18.2}
                    pt={2}
                    pb={2}
                    pl={2.25}
                    pr={0}
                    width={"100%"}
                    boxShadow={"0px 1px 4px 0px rgb(0 0 0 / 75%)"}
                    sx={{ backgroundColor: theme.palette.grey[600] }}>
                    <Bankruptcy
                      setShowBannerSize={(val: boolean) => setShowBannerSize(val)}
                      setIsBK={() => setIsBK(false)}
                      account={account}
                    />
                  </Stack>
                </>
              )}
            </Grid>

            <BoxAccountNotes width={width}>
              <Block
                heightCard={width >= 1700 ? "200px" : "42px"}
                paddingTitle={width >= 1700 ? "8px 10px" : "0px 10px"}
                noBottomPadding
                isCard
                titleColor={isSetBackgroundColor ? theme.palette.common.white : theme.palette.common.black}
                titleTypography={width >= 1700 ? "h2" : "h4"}
                titlePrefix={
                  <ForumOutlinedIcon
                    sx={{
                      color: isSetBackgroundColor ? theme.palette.common.white : theme.palette.common.black
                    }}
                  />
                }
                title={"Account Notes"}
                handleClickHeader={() => handleClickOpenAN()}
                embedded
                borderRadius="10px"
                customBackground={
                  isSetBackgroundColor ? theme.palette.primary.dark : theme.palette.common.white
                }
                titlePostfix={getButtons()}>
                {isAccountNotesOpen ? (
                  <AccountNotes
                    openAccountAddComment={openAccountAddComment}
                    closeAccountNotes={closeAccountNotes}
                    isOpenAddComment={isOpenAddComment}
                    width={width}
                    refetchComments={refetchComments}
                    accountNotesWidth={accountNotesWidth}
                    data={comments}
                    refetchCommentsQuery={refetchCommentsQuery}
                  />
                ) : (
                  <> </>
                )}
              </Block>
            </BoxAccountNotes>
            {isOpenAddComment && (
              <Box
                sx={{
                  position: "fixed",
                  bottom: 0,
                  right: "28.25em",
                  width: "23.875em"
                }}>
                <AccountNotesAddComment
                  openModalComments={state => setOpenModal(state)}
                  closeAccountNotesAddComment={closeAccountNotesAddComment}
                  setOpenSnackBar={setOpenSnackBar}
                  width={width}
                  account={account}
                  refetchComments={() => refetchComments()}
                  rightPositionNoteInProgress={rightPositionNoteInProgress}
                  rightPositionAddNote={rightPositionAddNote}
                />
              </Box>
            )}

            <ModalAccountNotes
              isOpenModal={isOpenModal}
              setOpenModal={setOpenModal}
              closeAccountNotesAddComment={closeAccountNotesAddComment}
            />

            <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar open={isOpenSnackBar} onClose={onCloseSnackBar} autoHideDuration={3000}>
                <Alert severity="success" sx={{ width: "100%" }}>
                  Your Account Note was added
                </Alert>
              </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar
                message={valueCopied}
                open={valueCopied.length > 0}
                onClose={onCloseSnackBarCopied}
                autoHideDuration={3000}
              />
            </Stack>
          </Grid>
        </Stack>
      )}
    </>
  );
}

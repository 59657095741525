import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import type { ReactElement } from "react";
export function Footer(): ReactElement {
  return (
    <Box pb={1}>
      {/* <Typography variant="subtitle2" textAlign={"left"}>
        ©{format(new Date(), "yyyy")} TrueAccord. All rights reserved.
      </Typography> */}
    </Box>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export interface ICreditor {
  value: string | undefined;
  name: string;
  allCreditors: Array<{ label: string | null | undefined; value: string | undefined }> | undefined;
}
const initialState: ICreditor = {
  value: "",
  name: "",
  allCreditors: []
};

export const creditorSlice = createSlice({
  name: "creditor",
  initialState,
  reducers: {
    saveCreditorId: (state, action: { type: string; payload: string }) => {
      state.value = action.payload;
    },
    saveCreditorName: (state, action: { type: string; payload: string }) => {
      state.name = action.payload;
    },
    cleanCreditorName: state => {
      state.name = "";
    },
    saveAllCreditors: (
      state,
      action: {
        type: string;
        payload: Array<{ label: string | null | undefined; value: string | undefined }> | undefined;
      }
    ) => {
      state.allCreditors = action.payload;
    }
  }
});

export const { saveCreditorId, saveCreditorName, cleanCreditorName, saveAllCreditors } =
  creditorSlice.actions;

export default creditorSlice.reducer;

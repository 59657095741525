import { gql } from "@apollo/client";
import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";
import type { ReactElement } from "react";
import React from "react";
import { BlockHeading } from "~/core/components/widgets/BlockHeading";
import { useUser } from "~/core/contexts/UserContext";
import { useSafeQuery } from "~/core/utils/apollo";
import { CamundaConstants } from "~/models/models/constants";
import { CountBadge } from "../../../widgets/CountBadge";
import { TaskCardGrid } from "./TaskCardGrid";

export const ClaimedTasksListQuery = gql(`
query ClaimedTasksListQuery($name: String!, $assigneeId: String!, $skipFileRequestReviews: Boolean = true) {
  tasks: camunda_Tasks(
    assignee: $assigneeId
    isAssigned: true
    name: $name
    firstResult: 0
    maxResults: 100
  ) {
    ...DisputeTaskModel
  }
}
`);

function ClaimedTasksListLive(): ReactElement {
  const user = useUser();

  const { data, loading } = useSafeQuery(ClaimedTasksListQuery, {
    variables: {
      name: CamundaConstants.taskNames.DEBT_VERIFICATION,
      assigneeId: user.camundaId
    },
    pollInterval: 60_000
  });

  const tasks = _.compact(data?.tasks ?? []);

  return (
    <>
      <BlockHeading title="My Unfinished Tasks">
        <CountBadge label={tasks.length.toString()} isLoading={loading} />
      </BlockHeading>
      <Stack spacing={1} px={2}>
        {tasks.length === 0 ? (
          <Box textAlign="center">
            <Typography>You don't have any unfinished tasks.</Typography>
          </Box>
        ) : (
          <TaskCardGrid tasks={tasks} />
        )}
      </Stack>
    </>
  );
}

export const ClaimedTasksList = React.memo(ClaimedTasksListLive);

import { gql, useApolloClient, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import type { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import _, { isEqual } from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import { useDeepState } from "~/core/components/utils/react";
import { BlockHeading } from "~/core/components/widgets/BlockHeading";
import { useGenericConfig } from "~/core/config/context";
import { useUser } from "~/core/contexts/UserContext";
import type { CamundaTaskQuery } from "~/core/types/camunda/api";
import { useSafeQuery } from "~/core/utils/apollo";
import type { ICamundaUserModel } from "~/gql/types";
import { CamundaConstants } from "~/models/models/constants";
import { CountBadge } from "../../../widgets/CountBadge";
import { makeCamundaTaskQuery } from "./filtering";
import { TaskListTable } from "./TaskListTable";

export const CamundaUsersQuery = gql(`
query CamundaUsersQuery($supervisorsGroup: ID!, $agentsGroup: ID!, $internationalAgentsGroup: ID!) {
  supervisors: camunda_Users(groupId: $supervisorsGroup) {
    ...CamundaUserModel
    regionRestriction(setTo: false) @client
  }
  agents: camunda_Users(groupId: $agentsGroup) {
    ...CamundaUserModel
    regionRestriction(setTo: false) @client
  }
  internationalAgents: camunda_Users(groupId: $internationalAgentsGroup) {
    ...CamundaUserModel
    regionRestriction(setTo: true) @client
  }
}
`);
export const DisputeTasksStatsQuery = gql(`
query DisputeTasksStatsQuery($taskName: String!, $forUserId: String, $isAssigned: Boolean, $filterJson: String) {
  taskStats: camunda_Tasks_stats(
    name: $taskName,
    assignee: $forUserId,
    isAssigned: $isAssigned,
    filterJson: $filterJson) {
    count
  }
}
`);

export const DisputeTasksListQuery = gql(`
query DisputeTasksListQuery($taskName: String!, $forUserId: String, $isAssigned: Boolean, $filterJson: String, $firstResult: Int!, $maxResults: Int!, $skipFileRequestReviews: Boolean = true) {
  tasks: camunda_Tasks(
    name: $taskName,
    assignee: $forUserId,
    isAssigned: $isAssigned,
    filterJson: $filterJson
    firstResult: $firstResult
    maxResults: $maxResults
  ) {
    ...DisputeTaskModel
  }
}
`);

export interface IDisputesTasksListProps {
  agents: ICamundaUserModel[];
  supervisors: ICamundaUserModel[];
}

function DisputeTasksListLive(): ReactElement {
  const user = useUser();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: []
  });
  const [sortModel, setSortModel] = useDeepState<GridSortModel>([{ field: "daysRemaining", sort: "asc" }]);
  const client = useApolloClient();

  const fixedQuery: CamundaTaskQuery = {
    variableNamesIgnoreCase: true,
    variableValuesIgnoreCase: true,
    candidateGroups: user.isDvSupervisor ? undefined : user.groups
  };
  const filterJson = useDeepCompareMemo(() => {
    const newQuery = { ...fixedQuery, ...makeCamundaTaskQuery(filterModel, sortModel) };
    return JSON.stringify(newQuery, null, 0).replace(/\\n/g, "");
  }, [fixedQuery, sortModel, filterModel]);

  const { camundaConfig } = useGenericConfig();
  const {
    data: {
      agents,
      supervisors,
      internationalAgents
    } = {
      agents: undefined,
      supervisors: undefined,
      internationalAgents: undefined
    }
  } = useSafeQuery(CamundaUsersQuery, {
    variables: {
      supervisorsGroup: camundaConfig.groups.debtVerificationSupervisors,
      agentsGroup: camundaConfig.groups.debtVerificationAgents,
      internationalAgentsGroup: camundaConfig.groups.debtVerificationInternationalAgents
    },
    pollInterval: 120_000,
    fetchPolicy: "cache-first"
  });
  const { data: statsData, error: statsError } = useQuery(DisputeTasksStatsQuery, {
    variables: {
      taskName: CamundaConstants.taskNames.DEBT_VERIFICATION,
      isAssigned: user.isDvSupervisor ? undefined : false,
      filterJson
    },
    pollInterval: 300_000,
    errorPolicy: "ignore",
    fetchPolicy: "cache-first"
  });
  if (statsError) {
    console.error(`DisputeTasksStatsQuery error`, statsError);
  }
  const { data, loading } = useSafeQuery(DisputeTasksListQuery, {
    variables: {
      taskName: CamundaConstants.taskNames.DEBT_VERIFICATION,
      isAssigned: user.isDvSupervisor ? undefined : false,
      filterJson,
      firstResult: page * pageSize,
      maxResults: pageSize
    },
    pollInterval: 60_000
  });
  const tasks = _.compact(data?.tasks ?? []);
  const tasksCount = statsData?.taskStats?.count ?? tasks.length;
  const countBadgeLabel = _.isEmpty(filterModel.items)
    ? tasksCount.toString()
    : `${tasks.length} / ${tasksCount}`;
  return (
    <>
      <BlockHeading title="All Tasks">
        <CountBadge label={countBadgeLabel} isLoading={loading} />
      </BlockHeading>
      <Stack spacing={1} px={2}>
        <TaskListTable
          tableId={"task-list-table"}
          isLoading={loading}
          tasks={tasks}
          tasksCount={tasksCount}
          agents={_.compact(_.union(agents, internationalAgents))} //combine agents and international agents
          supervisors={_.compact(supervisors)}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          sortModel={sortModel}
          setSortModel={setSortModel}
          onRefresh={() => void client.reFetchObservableQueries(true)}
        />
      </Stack>
    </>
  );
}

export const DisputeTasksList = React.memo(DisputeTasksListLive, isEqual);

import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
interface IStepper {
  isEnabledStepMiniMiranda: boolean;
}

export function Stepper({ isEnabledStepMiniMiranda }: IStepper): React.ReactElement {
  const theme = useTheme();
  const colorTextStepper = "#676B6E";
  return (
    <Stack
      direction={"row"}
      sx={{ backgroundColor: theme.palette.common.white }}
      justifyContent={"center"}
      alignItems="center"
      width={"unset !important"}
      height={"85px"}
      p={1}
      ml={1}
      mr={1}
      mt={0.25}>
      <Stack direction={"row"}>
        <Stack alignItems={"center"}>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: theme.palette.primary.dark
            }}>
            <Typography
              color={theme.palette.common.white}
              sx={{ left: "9px", position: "relative", top: "2px" }}
              fontWeight={"bold"}>
              {isEnabledStepMiniMiranda ? (
                <Box top={"-2px"} left={"-8px"} position={"relative"}>
                  <CheckIcon />
                </Box>
              ) : (
                1
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "60px",
              borderTop: `2px solid ${theme.palette.primary.dark}`,
              position: "relative",
              top: "-13px",
              right: "-50px"
            }}></Box>
          <Typography variant="body1" color={colorTextStepper}>
            Verification
          </Typography>
        </Stack>

        <Stack alignItems={"center"}>
          <Box
            sx={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              backgroundColor: isEnabledStepMiniMiranda
                ? theme.palette.primary.dark
                : theme.palette.common.white,
              border: `1px solid ${theme.palette.primary.dark}`,
              position: "relative",
              right: "-10px"
            }}>
            <Typography
              color={isEnabledStepMiniMiranda ? theme.palette.common.white : theme.palette.primary.dark}
              sx={{ left: "7px", position: "relative", top: "1px" }}
              fontWeight={"bold"}>
              2
            </Typography>
          </Box>
          <Typography
            sx={{ position: "relative", top: "2px", left: "17px" }}
            variant="body1"
            color={colorTextStepper}>
            Mini Miranda
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

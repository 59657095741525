import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { MonetaryValue } from "~/core/components/domain/widgets/MonetaryValue";
import { DataRow } from "~/core/components/widgets/DataRow";
import type { IAccountModel } from "~/gql/types";
import { Block } from "../blocks/Block";
import { useTheme, styled } from "@mui/material/styles";
import { formatDate, isValidDate } from "~/core/utils/formats";
import { ArrowedPopper } from "../../widgets/ArrowedPopper";
import _ from "lodash";

export interface IPlanGuidelinesProps {
  account: IAccountModel;
}

const TableCellCustom = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 8px;
  }
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#0074D91A"
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

function PopperArrow({ setRef }: { setRef: (anchor: HTMLElement | null) => void }): React.ReactElement {
  return <StyledArrow ref={setRef} className="MuiPopper-arrow" />;
}

const StyledArrow = styled("div")({
  position: "absolute",
  fontSize: 7,
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid"
  }
});

export function PlanGuidelines({ account }: IPlanGuidelinesProps): React.ReactElement {
  function getMonetaryValue(): React.ReactElement {
    return (
      <Stack direction={"row"}>
        <Typography marginRight={"0.5em"}>{`${account.min_settlement_percent ?? "—"}% (HB) or `}</Typography>
        <MonetaryValue
          variant="body1"
          monetaryAmount={{
            amount: account.calculated_payment_offers?.settlement_constraints?.min?.amount?.amount,
            currency: account.calculated_payment_offers?.settlement_constraints?.min?.amount?.currency
          }}
        />
      </Stack>
    );
  }

  function getLengthPlan(months: number | null | undefined): string {
    if (!months) {
      return "None";
    }
    return `${months} months`;
  }

  function PopoverActiveHB(): React.ReactElement {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (evt: React.BaseSyntheticEvent) => {
      setAnchorEl(evt.currentTarget);
      setRawPopOverOpen(true);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const [isPopOverOpen, setRawPopOverOpen] = React.useState(false);

    const setPopOverOpen = (isOpen: boolean, elem: HTMLElement | null) => {
      setAnchorEl(elem);
      setRawPopOverOpen(isOpen);
    };

    return (
      <>
        <Typography sx={{ cursor: "pointer" }} color={theme.palette.primary.dark} onClick={handleClick}>
          See All
        </Typography>

        <Stack>
          <ArrowedPopper open={isPopOverOpen} onClose={() => setPopOverOpen(false, null)} anchorEl={anchorEl}>
            <Block
              noBottomPadding
              noHorizontalPadding
              title="Active Heartbeat Payment Offers"
              titleTypography="h5"
              paddingTopContent={"0px"}
              paddingTitle={"16px 16px 0px 16px"}>
              <ActiveHBTable />
            </Block>
          </ArrowedPopper>
        </Stack>
      </>
    );
  }

  const information = [
    {
      label: "Max Length of Payment Plan:",
      value: getLengthPlan(account.max_payment_plan_length_in_months?.value)
    },
    {
      label: "Minimum Settlement %:",
      value: getMonetaryValue()
    },
    {
      label: "Override Eligible",
      value: account.effective_max_discount_percent?.override_type === "NO_OVERRIDE" ? "No" : "Yes" ?? "None"
    },
    {
      label: "Settlement Override Minimum",
      value: account.effective_max_discount_percent?.min_settlement_percent ?? "None"
    },
    {
      label: "Active HB Payment Offers",
      value: PopoverActiveHB()
    }
  ];

  return (
    <Stack direction="column" spacing={2}>
      {information.map((item, index) => (
        <DataRow
          key={index}
          inline
          title={item.label}
          titleTypography="body2"
          value={<Typography variant="body1">{item.value}</Typography>}
        />
      ))}
    </Stack>
  );

  function ActiveHBTable(): React.ReactElement {
    const theme = useTheme();
    const day = account.calculated_payment_offers?.available_settlement_offer?.expiration_date?.day;
    const month = account.calculated_payment_offers?.available_settlement_offer?.expiration_date?.month;
    const year = account.calculated_payment_offers?.available_settlement_offer?.expiration_date?.year;
    let date;
    let dateFormatted;
    if (!_.isNil(year) && !_.isNil(month) && !_.isNil(day)) {
      date = new Date(year, month - 1, day);
    } else {
      dateFormatted = "No entry";
    }

    if (date && isValidDate(date)) {
      dateFormatted = formatDate(date);
    } else {
      dateFormatted = "No entry";
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell>
                  <Typography variant="body2">Total Amount to Pay</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">No. of Installments</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">Frequency</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">Amount per Installment</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <TableCellCustom align="left">PIF</TableCellCustom>
                <TableCellCustom align="center">
                  <MonetaryValue variant="body1" monetaryAmount={account.balance} />
                </TableCellCustom>
                <TableCellCustom align="center">
                  <Typography variant="body1">1</Typography>
                </TableCellCustom>
                <TableCellCustom align="center"></TableCellCustom>
                <TableCellCustom align="center">
                  <MonetaryValue variant="body1" monetaryAmount={account.balance} />
                </TableCellCustom>
              </StyledTableRow>
              {account.calculated_payment_offers?.available_settlement_offer ? (
                <StyledTableRow>
                  <TableCellCustom align="left">
                    SIF ({account.calculated_payment_offers?.available_settlement_offer?.settlement_percent}%)
                    <Typography color={theme.palette.grey[700]} fontStyle={"italic"} fontSize={"0.8em"}>
                      Expires {dateFormatted}
                    </Typography>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <MonetaryValue
                      variant="body1"
                      monetaryAmount={account.calculated_payment_offers?.available_settlement_offer?.amount}
                    />
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Typography variant="body1">1</Typography>
                  </TableCellCustom>
                  <TableCellCustom align="center"></TableCellCustom>
                  <TableCellCustom align="center">
                    <MonetaryValue
                      variant="body1"
                      monetaryAmount={account.calculated_payment_offers?.available_settlement_offer?.amount}
                    />
                  </TableCellCustom>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <TableCellCustom align="left">No SIF offer at the time</TableCellCustom>
                  <TableCellCustom align="left"> </TableCellCustom>
                  <TableCellCustom align="left"> </TableCellCustom>
                  <TableCellCustom align="left"> </TableCellCustom>
                  <TableCellCustom align="left"> </TableCellCustom>
                </StyledTableRow>
              )}

              {account.calculated_payment_offers?.available_plans.map((item, index: number) => (
                <StyledTableRow key={index}>
                  <TableCellCustom align="left">{item?.number_of_installments}-mo</TableCellCustom>

                  <TableCellCustom align="center">
                    {<MonetaryValue variant="body1" monetaryAmount={item?.total_to_pay} />}
                  </TableCellCustom>

                  <TableCellCustom align="center">
                    <Typography variant="body1">{item?.number_of_installments}</Typography>
                  </TableCellCustom>
                  <TableCellCustom align="center">
                    <Typography variant="body1">{item?.application?.frequency}</Typography>
                  </TableCellCustom>

                  <TableCellCustom align="center">
                    {<MonetaryValue variant="body1" monetaryAmount={item?.installment_amount} />}
                  </TableCellCustom>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

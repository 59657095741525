import {
  PlayArrowRounded as PlayArrowRoundedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon
} from "@mui/icons-material";
import type { Theme } from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import { chain, lowerCase } from "lodash";
import { titleCase } from "~/core/components/utils/content";
import type { DataColumn, SelectOption } from "~/core/components/widgets/StyledDataGrid";
import { GridColumnBuilder } from "~/core/components/widgets/StyledDataGrid";
import type { IGenericAppConfig } from "~/core/config/app.config";
import { formatTime } from "~/core/utils/formats";
import { humanDisputeName } from "~/core/utils/models";
import { urlToCreditorLegacy, urlToDebtorLegacy } from "~/core/utils/routing";
import type {
  ICamundaUserModel, ICamundaUserModelWithRestrictions, IDisputeTaskModel, IPostalAddressModel
} from "~/gql/types";
import { IDisputeClaim_Reason } from "~/gql/types";
import { ClaimTaskButton } from "../../widgets/ClaimTaskButton";
import { DaysRemaining } from "../../widgets/DaysRemaining";
import { ViewTaskButton } from "../../widgets/ViewTaskButton";

export interface DisputeTaskRow {
  idx: number;
  id: string;
  assigneeId: string | undefined;
  task: IDisputeTaskModel;
  debtorAddress: IPostalAddressModel | undefined;
  taskId: string;
  creditorId: string;
  debtId: string;
  debtorId: string;
  debtorName: string | undefined;
  creditorName: string;
  taskDueDate: Date | undefined;
  lastActionDate: Date | undefined;
  processDueDate: Date | undefined;
}

export interface DefineColumnsOps {
  userId: string;
  theme: Theme;
  appConfig: IGenericAppConfig;
  isSupervisor: boolean;
  assignees: ICamundaUserModelWithRestrictions[]; // eslint-disable-next-line @typescript-eslint/no-explicit-any
  visibleColumns: string[];
}

export function defineColumns({
  userId,
  theme,
  appConfig,
  isSupervisor,
  assignees,
  visibleColumns
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
DefineColumnsOps): ReadonlyArray<DataColumn<DisputeTaskRow, any>> {
  const builder = new GridColumnBuilder<DisputeTaskRow>();
  builder.string("id", {
    title: "Camunda ID",
    sortable: false,
    filterable: false,
    hidden: true,
    getter: ({ id }) => id
  });
  addCreditorNameCol(builder, appConfig, theme);
  addDaysRemainingCol(builder, isSupervisor);
  addTaskDueDateCol(builder);
  addTaskTypeCol(builder);
  addDisputeTypesCol("disputeType", builder);
  addDebtorNameCol("debtorName", builder, appConfig, theme);
  addDebtorStateCol("debtorState", builder);
  isSupervisor && addAssigneesCol("assignee", assignees, builder);
  addDebtIdCol(builder);
  addLastActionAtCol(builder);

  // Disabled because it was causing queries that were too heavy
  // addMediaReceivedCol(builder);

  builder.actions(({ task }) => {
    const playIcon = <PlayArrowRoundedIcon fontSize={"small"} color={"primary"} />;
    const viewIcon = <VisibilityOutlinedIcon fontSize={"small"} />;
    return [
      <ViewTaskButton
        label="View Task"
        icon={task.assigneeId === userId ? playIcon : viewIcon}
        taskId={task.id}
        tooltip={
          task.assigneeId === userId
            ? "Continue working on this task"
            : "Preview this task without claiming it"
        }
        showInMenu={false}
      />,
      !task.assigneeId ? (
        <ClaimTaskButton label="Claim" icon={playIcon} task={task} showInMenu={false} />
      ) : undefined
    ];
  });

  return builder.columns.map(column => {
    if (visibleColumns.includes(column.field)) {
      return { ...column, columnDef: { ...column.columnDef, hide: false } };
    } else {
      return column;
    }
  });
}

function addMediaReceivedCol(builder: GridColumnBuilder<DisputeTaskRow>): void {
  // This one is incredibly bad for performance
  // builder.date("mediaReceived", {
  //   title: "Media Received",
  //   getter({ task: { creditorTask } }) {
  //     return creditorTask?.lastMediaReceivedDate;
  //   },
  //   render(lastMediaReceivedDate, { task: { processInstance } }) {
  //     if (!lastMediaReceivedDate) {
  //       return;
  //     }
  //     const hasNewUpdates =
  //       processInstance?.lastUpdateDate && isAfter(lastMediaReceivedDate, processInstance.lastUpdateDate);
  //     return (
  //       <>
  //         {hasNewUpdates && (
  //           <BlueDot
  //             tooltip={`New documents were uploaded since last task update on ${formatTime(
  //               processInstance.lastUpdateDate
  //             )}`}
  //           />
  //         )}
  //         <DateDisplay date={lastMediaReceivedDate} />
  //       </>
  //     );
  //   },
  //   tooltip: false
  // });
}

function addCreditorNameCol(
  builder: GridColumnBuilder<DisputeTaskRow>,
  appConfig: IGenericAppConfig,
  theme: Theme
): void {
  builder.string("creditorName", {
    title: "Creditor",
    getter: ({ creditorName }) => creditorName,
    render(creditorName, { creditorId }) {
      return (
        <MuiLink
          href={urlToCreditorLegacy(creditorId, appConfig).toString()}
          color={theme.palette.text.secondary}
          target={"_blank"}>
          {creditorName}
        </MuiLink>
      );
    }
  });
}

function addDaysRemainingCol(builder: GridColumnBuilder<DisputeTaskRow>, isSupervisor: boolean): void {
  builder.date("daysRemaining", {
    title: "Due",
    getter: ({ processDueDate }) => processDueDate,
    render(dueDate) {
      return (
        dueDate && (
          <DaysRemaining
            dueDate={dueDate}
            addWords={"embed"}
            digitsVariant={"body2"}
            wordsVariant={"body2"}
          />
        )
      );
    },
    tooltip: false,
    editable: isSupervisor,
    width: "wide"
  });
}

function addTaskTypeCol(builder: GridColumnBuilder<DisputeTaskRow>): void {
  builder.string("taskType", {
    title: "Task",
    width: "wide",
    getter: ({ task: { name } }) => name,
    formatter: name => (name ? titleCase(name) : name)
  });
}

function addDebtIdCol(builder: GridColumnBuilder<DisputeTaskRow>): void {
  builder.string("debtId", {
    title: "Debt ID",
    getter: ({ debtId }) => debtId,
    hidden: true
  });
}

function addLastActionAtCol(builder: GridColumnBuilder<DisputeTaskRow>): void {
  builder.dateTime("lastActionDate", {
    title: "Last Action",
    getter: ({ lastActionDate }) => lastActionDate,
    hidden: true,
    filterable: false,
    sortable: false,
    formatter: date => (date ? formatTime(date) : "None"),
    tooltip: true
  });
}

function addTaskDueDateCol(builder: GridColumnBuilder<DisputeTaskRow>): void {
  builder.date("taskDueDate", {
    title: "Task Due",
    getter: ({ taskDueDate }) => taskDueDate,
    render: dueDate => dueDate && <DaysRemaining dueDate={dueDate} />,
    hidden: true,
    tooltip: false
  });
}

function addDebtorStateCol(columnId: string, builder: GridColumnBuilder<DisputeTaskRow>): void {
  builder.string(columnId, {
    title: "Debtor State",
    getter({ debtorAddress }) {
      return debtorAddress?.state ?? "Unknown State";
    },
    formatter(state) {
      return state ?? "N/A";
    },
    tooltip: ({ debtorAddress }) => {
      if (debtorAddress) {
        return chain([debtorAddress.city, debtorAddress.state, debtorAddress.zipcode])
          .compact()
          .join(", ")
          .value();
      } else {
        return undefined;
      }
    },
    filterable: false,
    sortable: false
  });
}

function addDebtorNameCol(
  columnId: string,
  builder: GridColumnBuilder<DisputeTaskRow>,
  appConfig: IGenericAppConfig,
  theme: Theme
): void {
  builder.string(columnId, {
    title: "Debtor",
    width: "wide",
    getter({ debtorName }) {
      return debtorName ?? "Unknown Debtor";
    },
    render(fullName, { debtId, debtorId }) {
      return (
        <MuiLink
          href={urlToDebtorLegacy(debtorId, appConfig)}
          color={theme.palette.text.secondary}
          target={"_blank"}>
          {fullName}
        </MuiLink>
      );
    },
    filterable: false,
    sortable: false
  });
}

function addDisputeTypesCol(columnId: string, builder: GridColumnBuilder<DisputeTaskRow>): void {
  const disputeTypes = Object.values(IDisputeClaim_Reason).map(value => ({
    value: value.toString(),
    label: humanDisputeName(value)
  }));
  builder.select("disputeType", disputeTypes, {
    title: "Dispute",
    width: "wide",
    getter({ task: { disputeType } }) {
      return disputeType?.toString();
    },
    formatter(disputeType) {
      return disputeTypes.find(({ value }) => value === disputeType)?.label;
    }
  });
}

function makeAssigneeSelectOption({ id, name }: ICamundaUserModel): SelectOption<string> | undefined {
  return id && name
    ? {
        value: id,
        label: name
      }
    : undefined;
}

function addAssigneesCol(
  columnId: string,
  assignees: ICamundaUserModelWithRestrictions[],
  builder: GridColumnBuilder<DisputeTaskRow>
): void {
  const allAssigneeOpts = chain(assignees)
    .map(makeAssigneeSelectOption)
    .compact()
    .uniqBy(({ value }) => value)
    .sortBy(({ label }) => lowerCase(label))
    .push({ value: "", label: "None" })
    .value();
  const unrestrictedAssigneeOpts  = chain(assignees)
    .filter( a => !a.regionRestriction)
    .map(makeAssigneeSelectOption)
    .compact()
    .uniqBy(({ value }) => value)
    .sortBy(({ label }) => lowerCase(label))
    .push({ value: "", label: "None" })
    .value();
  builder.select(columnId, allAssigneeOpts, {
    title: "Assignee",
    width: "wide",
    getter({ assigneeId }) {
      return assigneeId ?? "";
    },
    formatter(assigneeId) {
      if (!assigneeId) {
        return "None";
      }
      return allAssigneeOpts.find(({ value }) => value === assigneeId)?.label ?? assigneeId;
    },
    tooltip({ task: { assigneeId } }) {
      return assigneeId ? `${assigneeId} — double click to reassign.` : "Double click to assign.";
    },
    editable: true,
    customization: {
      valueOptions: ({ row }) => {
        if (!row) {
          // The row is not available when filtering this column
          return allAssigneeOpts;
        }
        if (!row.task.allowsInternationalAgents) {
          // return unrestricted assignees
          return unrestrictedAssigneeOpts;
        } else {
          return allAssigneeOpts;
        }
      },
    }
  });
}

export function makeDataRow(task: IDisputeTaskModel, idx: number): DisputeTaskRow | undefined {
  if (!task.debt?.id || !task.debt.person?.id || !task.debt.creditor?.id) {
    console.error(`Skipping invalid task: ${JSON.stringify(task)}`);
    return undefined;
  }
  const debtorAddress = chain(task.debt.person.addresses)
    .compact()
    .filter(a => a.meta?.is_active ?? false)
    .sortBy(a => (a.meta?.is_primary ? 1 : 0))
    .head()
    .value();

  return {
    idx,
    id: task.id,
    task,
    taskDueDate: task.dueDate ?? undefined,
    processDueDate: task.processInstance?.dueDate ?? undefined,
    taskId: task.id,
    creditorId: task.debt.creditor.id,
    creditorName: task.debt.creditor.company_name ?? "Unknown Creditor",
    debtId: task.debt.id,
    debtorId: task.debt.person.id,
    debtorName: task.debt.person.fullName,
    assigneeId: task.assigneeId ?? undefined,
    debtorAddress,
    lastActionDate: task.processInstance?.lastUpdateDate ?? undefined
  };
}

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { Tooltip } from "@mui/material";
import type { Variant } from "@mui/material/styles/createTypography";
import type { ReactElement } from "react";
import * as React from "react";

export interface IExternalLinkProps {
  href: URL | string;
  title: string;
  color?: string;
  variant?: Variant;
}

export function ExternalLink({ href, title, color, variant }: IExternalLinkProps): ReactElement {
  return (
    <Tooltip title={title}>
      <IconButton aria-label="open in new window" size="small" href={href.toString()} target="_blank">
        {title.length > 0 ? (
          <Typography variant={variant ? variant : "body1"} color={color ? color : "#FFF"}>
            {title}
          </Typography>
        ) : (
          <OpenInNewIcon sx={{ color: color }} fontSize="inherit" />
        )}
      </IconButton>
    </Tooltip>
  );
}

import { gql, useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { LinearProgress, Stack, useTheme } from "@mui/material";
import type {
  GridColumnsState,
  DataGridProps,
  GridFilterModel,
  GridSortModel,
  GridState
} from "@mui/x-data-grid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import { formatISO, isSameDay } from "date-fns";
import * as _ from "lodash";
import { chain, isEmpty, trim } from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { useCallback } from "react";
import { useDeepCompareMemo } from "use-deep-compare";
import type { AlertsContextState } from "~/core/components/widgets/AlertContext";
import { useAlerts } from "~/core/components/widgets/AlertContext";
import { ConfirmationDialog, useConfirmationDialog } from "~/core/components/widgets/ConfirmationDialog";
import { StyledDataGrid } from "~/core/components/widgets/StyledDataGrid";
import { useGenericConfig } from "~/core/config/context";
import { useUser } from "~/core/contexts/UserContext";
import { renderDate } from "~/core/utils/formats";
import type { IThinTask } from "~/core/utils/models";
import { humanTaskName } from "~/core/utils/models";
import type { MutationFn } from "~/core/utils/types";
import type {
  ICamundaUserModel,
  IDisputeTaskModel,
  ICamundaUserModelWithRestrictions,
  ISetDisputeTaskAssignee,
  ISetDisputeTaskAssigneeVariables
} from "~/gql/types";
import { ICamunda_ValueTypeEnum } from "~/gql/types";
import { RefreshButton } from "../../../widgets/RefreshButton";
import { UpdateDisputeTask } from "../DisputeTaskView";
import { ClaimedTasksListQuery } from "./ClaimedTasksList";
import { defineColumns, makeDataRow } from "./columns";
import { DisputeTasksListQuery } from "./DisputeTasksList";

export const SetDisputeTaskAssignee = gql(`
mutation SetDisputeTaskAssignee(
  $taskId: ID!
  $assigneeId: ID
  $skipFileRequestReviews: Boolean = true
) {
  update_camunda_Task_claim(id: $taskId, userId: $assigneeId) {
    ...DisputeTaskModel
  }
}
`);

export interface FilterChangeOpts {
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  page: number;
  pageSize: number;
  readyToApplyFilters: boolean;
}

interface ITaskListTableProps {
  tableId: string;
  tasks: IDisputeTaskModel[];
  tasksCount: number;
  agents: ICamundaUserModelWithRestrictions[];
  supervisors: ICamundaUserModelWithRestrictions[];
  page: number;
  pageSize: number;
  setFilterModel: (filterModel: GridFilterModel) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  filterModel?: GridFilterModel;
  sortModel?: GridSortModel;
  isLoading: boolean;
  onStateChange?: (gridState: GridState) => void;
  onRefresh?: () => void;
}

async function onAssigneeEdit(
  task: IThinTask,
  newAssigneeId: string | undefined,
  assignees: ICamundaUserModel[],
  assignTask: MutationFn<ISetDisputeTaskAssignee, ISetDisputeTaskAssigneeVariables>,
  alerts: AlertsContextState
): Promise<void> {
  newAssigneeId = trim(newAssigneeId);
  if (isEmpty(newAssigneeId)) {
    newAssigneeId = undefined;
  }
  if (newAssigneeId && !assignees.map(user => user.id).includes(newAssigneeId)) {
    console.error(`Failed to assign task ${humanTaskName(task)} to ${newAssigneeId} (wrong user id).`);
    return;
  }
  if (newAssigneeId === task.assigneeId) {
    console.debug(`assigneeId hasn't changed (${newAssigneeId ?? "nobody"}), ignoring update attempt.`);
    return;
  }
  try {
    await assignTask({
      variables: {
        taskId: task.id,
        assigneeId: newAssigneeId
      }
    });
    alerts.addAlert({
      severity: "success",
      message: newAssigneeId
        ? `You've successfully assigned ${humanTaskName(task)} to ${newAssigneeId}.`
        : `You've successfully unassigned ${humanTaskName(task)} from ${task.assigneeId ?? "nobody"}.`
    });
  } catch (error) {
    console.error({ error });
    alerts.addAlert({
      severity: "error",
      message: newAssigneeId
        ? `Couldn't assign task ${humanTaskName(task)} to ${newAssigneeId}.`
        : `Couldn't unassign task ${humanTaskName(task)} from ${task.assigneeId ?? "nobody"}.`
    });
  }
}

export function TaskListTable({
  tableId,
  isLoading,
  tasks,
  tasksCount,
  agents,
  supervisors,
  page,
  setPage,
  pageSize,
  setPageSize,
  filterModel,
  setFilterModel,
  sortModel,
  setSortModel,
  onStateChange,
  onRefresh
}: ITaskListTableProps): ReactElement {
  const { appConfig } = useGenericConfig();
  const user = useUser();
  const [assignTask] = useMutation(SetDisputeTaskAssignee, {
    refetchQueries: _.compact([
      getOperationName(ClaimedTasksListQuery),
      getOperationName(DisputeTasksListQuery)
    ])
  });
  const [updateTask] = useMutation(UpdateDisputeTask, {
    refetchQueries: _.compact([
      getOperationName(ClaimedTasksListQuery),
      getOperationName(DisputeTasksListQuery)
    ])
  });
  const alerts = useAlerts();
  const theme = useTheme();
  const { confirmState, openConfirm, closeConfirm } = useConfirmationDialog();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onGridStateChange = useCallback(
    _.debounce((gridState: GridState) => {
      rememberVisibleColumns(tableId, gridState.columns);
      onStateChange?.(gridState);
    }),
    []
  );

  const isSupervisor = user.isDvSupervisor;
  const assignees = _.chain(agents)
    .union(supervisors)
    .sortBy(u => u.id)
    .value();
  const visibleColumns = readVisibleColumns(tableId);
  const columns = useDeepCompareMemo(
    () =>
      defineColumns({
        userId: user.camundaId,
        appConfig,
        theme,
        assignees,
        isSupervisor,
        visibleColumns
      }),
    [user.camundaId, appConfig, theme, assignees, isSupervisor, visibleColumns]
  );
  const rows = chain(tasks).map(makeDataRow).compact().value();

  const onCellEdit: DataGridProps["onCellEditCommit"] = ({ id: taskId, field, value: newValue }): void => {
    const task = tasks.find(t => t.id === taskId);
    if (!task) {
      return;
    }
    if (!_.isString(taskId)) {
      console.error(
        `Failed to edit table cell with params: ${JSON.stringify({
          taskId,
          field,
          newValue
        })}`
      );
      return;
    }
    switch (field) {
      case "assignee":
        if (newValue !== undefined && !_.isString(newValue)) {
          console.error(`Wrong assignee value: ${JSON.stringify(newValue)}`);
          return;
        }

        openConfirm({
          title: `Do you want to edit assignee for ${humanTaskName(task)}?`,
          body: `Assignee will be changed from "${task.assigneeId ?? "none"}" to "${newValue ?? "none"}".`,
          onConfirm: async () => onAssigneeEdit(task, newValue, assignees, assignTask, alerts)
        });
        break;
      case "daysRemaining":
        if (newValue !== null && !_.isDate(newValue)) {
          console.error(`Wrong daysRemaining value: ${JSON.stringify(newValue)}`);
          return;
        }

        const currentDueDate = task.processInstance?.dueDate;
        if (newValue === currentDueDate) {
          return;
        }
        if (newValue && currentDueDate && isSameDay(newValue, currentDueDate)) {
          return;
        }

        openConfirm({
          title: `Do you want to change process due date for ${humanTaskName(task)}?`,
          body: `Due date will be changed from ${renderDate(currentDueDate)} to ${renderDate(newValue)}.`,
          onConfirm: async () => {
            const value = newValue ? formatISO(newValue) : "";
            await updateTask({
              variables: {
                taskId,
                variables: [
                  {
                    value,
                    key: "dueDate",
                    valueType: ICamunda_ValueTypeEnum.Date
                  }
                ]
              }
            });
          }
        });
        break;
      default:
        console.error(`Editing field '${field}' is not supported yet.`);
    }
  };

  // Add more page size options in non-pcip env for easier testing of pagination
  const pageSizeOpts =
    appConfig.appEnv !== "pcip" ? [1, 3, 5, 10, 15, 20, 25, 50, 100] : [10, 15, 20, 25, 50, 100];

  return (
    <>
      <ConfirmationDialog state={confirmState} onClose={closeConfirm} />

      <StyledDataGrid
        loading={isLoading}
        pagination
        paginationMode={"server"}
        columns={columns.map(t => t.columnDef)}
        rows={rows}
        rowCount={tasksCount}
        rowsPerPageOptions={pageSizeOpts}
        page={page}
        onPageChange={setPage}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        autoHeight={true}
        filterMode={"server"}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        sortingMode={"server"}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        disableSelectionOnClick={true}
        onCellEditCommit={onCellEdit}
        onStateChange={onGridStateChange}
        components={{
          Toolbar: props => <CustomToolbar {...props} onRefresh={onRefresh} />,
          LoadingOverlay: CustomLoadingOverlay
        }}
      />
    </>
  );
}

function CustomToolbar({ onRefresh }: { onRefresh?: () => void }): ReactElement {
  return (
    <GridToolbarContainer>
      <Stack spacing={1} direction={"row"} ml={"auto"}>
        {onRefresh && <RefreshButton onClick={onRefresh} />}
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </Stack>
    </GridToolbarContainer>
  );
}

function CustomLoadingOverlay(): ReactElement {
  return (
    <div style={{ position: "absolute", top: 0, width: "100%" }}>
      <LinearProgress />
    </div>
  );
}

const COLUMN_STORE_KEY_PREFIX = `datagrid-columns-`;

function rememberVisibleColumns(storeId: string, columnState: GridColumnsState): void {
  const visibleColumns = _.chain(columnState.all)
    .map(columnName => columnState.lookup[columnName])
    .compact()
    .filter(column => !column.hide)
    .map(column => column.field)
    .sort()
    .value();
  storeColumns(storeId, visibleColumns);
}

function readVisibleColumns(storeId: string): string[] {
  const rawJson = localStorage.getItem(`${COLUMN_STORE_KEY_PREFIX}${storeId}`);
  if (rawJson) {
    return JSON.parse(rawJson) as string[];
  } else {
    return [];
  }
}

function storeColumns(storeId: string, columns: string[]): void {
  console.debug(`Persisting visible columns for ${storeId}:`, columns);
  localStorage.setItem(`${COLUMN_STORE_KEY_PREFIX}${storeId}`, JSON.stringify(columns));
}

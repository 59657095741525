import * as Sentry from "@sentry/react";
import type { History } from "history";
import type { ReactElement } from "react";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigContext } from "~/core/config/context";
import type { IConfig } from "./config/context";
import AppWithRouterAccess from "./core/AppWithRouterAccess";
import { LicenseInfo } from "@mui/x-license-pro";
import { store } from "./store";
import { Provider } from "react-redux";
function App({ config }: { config: IConfig; history: History }): ReactElement {
  LicenseInfo.setLicenseKey(
    "3135efdaa67d929491abbb655a295b5cT1JERVI6Mzg0NTcsRVhQSVJZPTE2NzcyNjE1OTcwMDAsS0VZVkVSU0lPTj0x"
  );
  return (
    <ConfigContext.Provider value={config}>
      <React.StrictMode>
        <Provider store={store}>
          <Router history={history}>
            <AppWithRouterAccess config={config} />
          </Router>
        </Provider>
      </React.StrictMode>
    </ConfigContext.Provider>
  );
}

export default Sentry.withProfiler(App);

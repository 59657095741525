import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import type { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { CenteredLayout } from "~/core/components/layouts/CenteredLayout";

export function Login(): ReactElement {
  const history = useHistory();

  return (
    <CenteredLayout>
      <Card
        sx={{ maxWidth: "50%", minHeight: "20em", marginTop: "4em", marginLeft: "auto", marginRight: "auto" }}
        variant="outlined">
        <CardContent
          sx={{
            padding: "2em",
            textAlign: "center",
            alignItems: "center",
            alignContent: "center"
          }}>
          <Typography gutterBottom variant="h3" component="div" sx={{ textAlign: "center" }}>
            Welcome to PROSPER!
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Live long and prosper!
          </Typography>
          <Typography variant="body1" color="text.secondary" pt={9} bgcolor={"grey"}>
            <strong>Restricted area. Authorized personnel only.</strong> <br />
            <br />
            <br />
            Prosper is the new name of the <strong>NextGen CRM</strong> (the CE replacement). You'll be taken
            to{" "}
            <a href="https://trueaccord.okta.com/" target="_blank">
              TrueAccord Okta
            </a>{" "}
            during sign-in for the authentication unless your authentication is current.
          </Typography>

          <Typography variant="body1" color="text.secondary" pt={4} bgcolor={"grey"}>
            <em>If you don't have access but believe you should, please contact your supervisor.</em>
          </Typography>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center", marginBottom: "1em" }}>
          <Button onClick={() => history.push("/app/tasks")}>Sign In</Button>
        </CardActions>
      </Card>
    </CenteredLayout>
  );
}

import { SecureRoute } from "@okta/okta-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { DEFAULT_TRACING_ORIGINS } from "@sentry/tracing/dist/browser/request";
import { Route } from "react-router-dom";
import { version } from "../../../package.json";
import type { IConfig } from "../config/context";

export const EnhancedRoute = Sentry.withSentryRouting(Route);
export const EnhancedSecureRoute = Sentry.withSentryRouting(SecureRoute);

export function enableSentry(config: IConfig): void {
  Sentry.init({
    dsn: config.appConfig.sentryDsn,
    environment: config.appConfig.appEnv,
    release: `${version}-${process.env.VERSION ?? "dev"}`,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracingOrigins: [
          ...DEFAULT_TRACING_ORIGINS,
          config.appConfig.apiBaseUrl.replace(/https?:\/\//, "").replace(/:\d+.*$/, "")
        ]
      })
    ],
    tracesSampleRate: config.appConfig.appEnv !== "pcip" ? 1.0 : 0.05
  });
}

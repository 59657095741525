import { CssBaseline, ThemeProvider } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import * as Sentry from "@sentry/react";
import { isBoolean } from "lodash";
import type { ReactElement, ReactNode } from "react";
import { CenteredLayout } from "~/core/components/layouts/CenteredLayout";
import { AlertsProvider } from "~/core/components/widgets/AlertContext";
import { ErrorAlert } from "~/core/components/widgets/ErrorAlert";
import { trueAccordTheme } from "../styles/TrueAccordTheme";
import { ApiProvider } from "./ApiContext";
import { ApolloContext } from "./ApolloContext";
import { UserContextProvider } from "./UserContext";

interface CoreProvidersProps {
  children?: ReactNode;
}

export function CoreProviders({ children }: CoreProvidersProps): ReactElement {
  const { authState } = useOktaAuth();
  return (
    <Sentry.ErrorBoundary
      onReset={resetClientState}
      fallback={({ error, resetError }) => (
        <CenteredLayout>
          <ErrorAlert error={error} resetErrorBoundary={resetError} />
        </CenteredLayout>
      )}
      showDialog
      dialogOptions={{
        user: {
          email: authState?.idToken?.claims?.email,
          name: authState?.idToken?.claims?.name
        }
      }}>
      <ApolloContext>
        <ApiProvider>
          <ThemeProvider theme={trueAccordTheme}>
            <CssBaseline />
            <UserContextProvider>
              <AlertsProvider>{children}</AlertsProvider>
            </UserContextProvider>
          </ThemeProvider>
        </ApiProvider>
      </ApolloContext>
    </Sentry.ErrorBoundary>
  );
}

function resetClientState(force: unknown = false): void {
  if (isBoolean(force) && force) {
    window.localStorage.clear();
  }
  window.location.reload();
}

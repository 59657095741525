import type { NonOptional } from "~/core/utils/types";
import type { IDebtLogEntryModel } from "~/gql/graphql";

export type EmailToDebtorDLE = NonOptional<IDebtLogEntryModel, "send_email_to_debtor_request"> & {
  type: "email_to_debtor";
};
export type SmsToDebtorDLE = NonOptional<IDebtLogEntryModel, "send_sms_to_debtor_request"> & {
  type: "sms_to_debtor";
};

export type StatusChangeDLE = {
  type: "status_change";
} & NonOptional<IDebtLogEntryModel, "status_change_entry">;

export type TotalAdjustmentDLE = NonOptional<IDebtLogEntryModel, "total_to_collect_adjustment_log_entry"> & {
  type: "total_to_collect_adjustment";
};

export type ClassifiedDLE =
  | EmailToDebtorDLE
  | SmsToDebtorDLE
  | StatusChangeDLE
  | TotalAdjustmentDLE
  | UnknownDLE;

export function classifyDebtLogEntry(entry: IDebtLogEntryModel): ClassifiedDLE {
  if (entry.send_email_to_debtor_request) {
    return {
      ...entry,
      type: "email_to_debtor",
      send_email_to_debtor_request: entry.send_email_to_debtor_request
    };
  } else if (entry.send_sms_to_debtor_request) {
    return {
      ...entry,
      type: "sms_to_debtor",
      send_sms_to_debtor_request: entry.send_sms_to_debtor_request
    };
  } else if (entry.status_change_entry) {
    return {
      ...entry,
      type: "status_change",
      status_change_entry: entry.status_change_entry
    };
  } else if (
    entry.total_to_collect_adjustment_log_entry &&
    (entry.total_to_collect_adjustment_log_entry.description ||
      entry.total_to_collect_adjustment_log_entry.creditor_note)
  ) {
    return {
      ...entry,
      type: "total_to_collect_adjustment",
      total_to_collect_adjustment_log_entry: entry.total_to_collect_adjustment_log_entry
    };
  } else {
    return {
      ...entry,
      type: "unknown"
    };
  }
}

export function isTotalAdjustmentDLE(dle: ClassifiedDLE): dle is TotalAdjustmentDLE {
  return dle.type === "total_to_collect_adjustment";
}

export function isUnknownDLE(dle: ClassifiedDLE): dle is UnknownDLE {
  return dle.type === "unknown";
}

export function isEmailToDebtorDLE(dle: ClassifiedDLE): dle is EmailToDebtorDLE {
  return dle.type === "email_to_debtor";
}

export function isSmsToDebtorDLE(dle: ClassifiedDLE): dle is SmsToDebtorDLE {
  return dle.type === "sms_to_debtor";
}

export function isStatusChangeDLE(dle: ClassifiedDLE): dle is StatusChangeDLE {
  return dle.type === "status_change";
}

export type UnknownDLE = IDebtLogEntryModel & {
  type: "unknown";
};

import { ClickAwayListener, Fade, Paper } from "@mui/material";
import Popper from "@mui/material/Popper";
import type { PopperProps } from "@mui/material/Popper/Popper";
import { styled } from "@mui/material";
import React from "react";

export function ArrowedPopper(
  props: PopperProps & { onClose: () => void; onRightEdge?: boolean }
): React.ReactElement {
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  return (
    <StyledArrowedPopper
      {...props}
      modifiers={
        props.modifiers ?? [
          { name: "hide" },
          {
            name: "offset",
            enabled: true,
            options: {
              offset: [0, 10]
              // offset: calculatePopperOffset
            }
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef
            }
          }
        ]
      }
      placement={props.placement ?? "auto"}
      transition
      popperOptions={{ strategy: "absolute", ...props.popperOptions }}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div>
            <ClickAwayListener onClickAway={props.onClose}>
              <Paper
                sx={{
                  position: props.onRightEdge ? "relative" : "unset",
                  left: props.onRightEdge ? "-10px" : "0px"
                }}>
                {props.children}
              </Paper>
            </ClickAwayListener>
            <PopperArrow setRef={setArrowRef} />
          </div>
        </Fade>
      )}
    </StyledArrowedPopper>
  );
}

export function PopperArrow({
  setRef
}: {
  setRef: (anchor: HTMLElement | null) => void;
}): React.ReactElement {
  return <StyledArrow ref={setRef} className="MuiPopper-arrow" />;
}

const StyledArrow = styled("div")({
  position: "absolute",
  fontSize: 7,
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid"
  }
});

const StyledArrowedPopper = styled(Popper)(() => ({
  zIndex: 1,
  "& > div": {
    position: "relative"
  },
  "&[data-popper-reference-hidden], &[data-popper-escaped]": {
    visibility: "hidden",
    pointerEvents: "none"
  },
  '&[data-popper-placement*="bottom"]': {
    "& > div": {
      marginTop: 2
    },
    "& .MuiPopper-arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent rgba(0,0,0,0.11) transparent`
      }
    }
  },
  '&[data-popper-placement*="top"]': {
    "& > div": {
      marginBottom: 2
    },
    "& .MuiPopper-arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `rgba(0,0,0,0.11) transparent transparent transparent`
      }
    }
  },
  '&[data-popper-placement*="right"]': {
    "& > div": {
      marginLeft: 2
    },
    "& .MuiPopper-arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent rgba(0,0,0,0.11) transparent transparent`
      }
    }
  },
  '&[data-popper-placement*="left"]': {
    "& > div": {
      marginRight: 2
    },
    "& .MuiPopper-arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent rgba(0,0,0,0.11)`
      }
    }
  }
}));

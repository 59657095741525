import {
  Business as BusinessIcon,
  LocationOn as LocationOnIcon,
  Person as PersonIcon
} from "@mui/icons-material";
import { Card, CardActions, CardContent, Grid, Stack, Tooltip, Typography } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import { Link } from "react-router-dom";
import { ContinueTaskButton } from "~/core/components/domain/widgets/ContinueTaskButton";
import { DaysRemaining } from "~/core/components/domain/widgets/DaysRemaining";
import type { IDisputeTaskModel } from "~/gql/types";

export interface ITaskCardGridProps {
  tasks: IDisputeTaskModel[];
}

export function TaskCardGrid({ tasks }: ITaskCardGridProps): ReactElement {
  return (
    <Grid container direction={"row"}>
      {tasks.map(task => {
        const dueDate = task.processInstance?.dueDate ?? task.dueDate;

        return (
          <Grid item key={task.id} mr={1} mb={1}>
            <Card
              variant={"outlined"}
              sx={{
                width: "15em",
                textAlign: "left"
              }}>
              <CardContent sx={{ paddingBottom: 0 }}>
                <Stack direction="column" spacing={2}>
                  {dueDate && <DaysRemaining dueDate={dueDate} addWords={"suffix"} />}
                  <Stack direction={"row"} alignContent={"center"} spacing={1}>
                    <Tooltip title={"Client"}>
                      <BusinessIcon />
                    </Tooltip>
                    <Typography>{task.debt?.creditor?.company_name}</Typography>
                  </Stack>
                  <Stack direction={"row"} alignContent={"center"} spacing={1}>
                    <Tooltip title={"Debtor"}>
                      <PersonIcon />
                    </Tooltip>
                    <Link to={`/app/accounts/${task.debt?.id}`}>{task.debt?.person?.fullName}</Link>
                  </Stack>
                  <Stack direction={"row"} alignContent={"center"} spacing={1}>
                    <Tooltip title={"Address"}>
                      <LocationOnIcon titleAccess={"Location"} />
                    </Tooltip>
                    <Typography> {task.debt?.person?.addresses[0]?.city} </Typography>
                  </Stack>
                </Stack>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "flex-end"
                }}>
                <ContinueTaskButton task={task} />
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

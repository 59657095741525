import { createTheme } from "@mui/material";

// Neat tool for editing the schema: https://bareynol.github.io/mui-theme-creator/

export const trueAccordTheme = createTheme({
  palette: {
    mode: "light",
    common: {
      black: "#323232",
      white: "#fff"
    },
    background: {
      paper: "#fefefe",
      default: "#ececec"
    },
    primary: {
      light: "#ADD8E6",
      main: "rgba(68, 123, 229, 1)",
      dark: "#003D98",
      contrastText: "#fff"
    },
    secondary: {
      light: "rgba(217, 140, 98, 1)",
      main: "rgba(234, 170, 69, 1)",
      dark: "rgba(209, 97, 36, 1)",
      contrastText: "#fff"
    },
    success: {
      main: "#207177",
      light: "#50BE4C",
      dark: "#348731"
    },
    error: {
      light: "#ffd1d1",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    warning: {
      main: "#0059a6",
      light: "#FF4136",
      dark: "#FCDB5D"
    },
    text: {
      primary: "#002169",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)"
    },
    grey: {
      100: "#f1f1f1",
      200: "#D4D5D6",
      300: '#A8ABAD',
      400: "#f4f4f4",
      500: "#f6f6f6",
      600: "#FBFBFB",
      700: '#616161',
      800: 'rgba(0, 0, 0, 0.26)',
      900: "#A8ABAD"
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: "Libre Franklin",
    h1: {
      fontSize: "1.75rem",
      fontWeight: "bold"
    },
    h2: {
      fontSize: "1.5rem",
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: "bold"
    },
    h4: {
      fontSize: "1.25rem"
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: "bold"
    },
    h6: {
      fontSize: "0.875rem",
    },
    subtitle1: {
      fontSize: "1.125rem"
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 600
    },
    body1: {
      fontSize: "0.875rem"
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 600
    }
  }
});

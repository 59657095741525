import Stack from "@mui/material/Stack";
import _ from "lodash";
import { formatTimestamp } from "~/core/utils/formats";
import type { IDebtLogEntryModel, IUnifiedPaymentEntry, Maybe } from "~/gql/types";
import { DataRow } from "../../widgets/DataRow";
import { MonetaryValue } from "../widgets/MonetaryValue";
import { gql } from "@apollo/client";
import { useSafeQuery } from "~/core/utils/apollo";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";
import { IApi_PaymentTransactionType } from "~/gql/graphql";

interface IPaymentDetails {
  entry: IUnifiedPaymentEntry;
  logEntry: IDebtLogEntryModel;
  reversedUnifiedPaymentEntry: Maybe<IUnifiedPaymentEntry>;
}

export const UserAccountQuery = gql(`
query UserAccountQuery($userAccountId: ID!) {
  userAccount: UserAccount(id: $userAccountId) {
    identities {
      full_name
    }
  }
}
`);

export function PaymentDetails({
  entry,
  logEntry,
  reversedUnifiedPaymentEntry
}: IPaymentDetails): React.ReactElement {
  const { data } = useSafeQuery(UserAccountQuery, {
    variables: { userAccountId: entry.payment_entry_id ?? "undefined" }
  });

  function viewCCDetail(logEntry: IDebtLogEntryModel | undefined) {
    const paymentLogEntry = logEntry;
    if (paymentLogEntry?.payment_log_entry?.healpay_transaction) {
      return {
        referenceNumber: paymentLogEntry.payment_log_entry?.healpay_transaction.ref_num,
        invoice: paymentLogEntry.payment_log_entry?.healpay_transaction.invoice,
        customerNumber: paymentLogEntry.payment_log_entry?.healpay_transaction.cust_num,
        paymentProcessor: "healpayTransaction",
        result: paymentLogEntry.payment_log_entry?.healpay_transaction.result,
        status: paymentLogEntry.payment_log_entry?.healpay_transaction.status,
        error: paymentLogEntry.payment_log_entry?.healpay_transaction.error
      };
    } else if (paymentLogEntry?.payment_log_entry?.lucentpay_transaction) {
      return {
        referenceNumber: paymentLogEntry.payment_log_entry?.lucentpay_transaction.ref_num,
        invoice: paymentLogEntry.payment_log_entry?.lucentpay_transaction.invoice,
        customerNumber: paymentLogEntry.payment_log_entry?.lucentpay_transaction.cust_num,
        paymentProcessor: "lucentpayTransaction",
        result: paymentLogEntry.payment_log_entry?.lucentpay_transaction.result,
        status: paymentLogEntry.payment_log_entry?.lucentpay_transaction.status,
        error: paymentLogEntry.payment_log_entry?.lucentpay_transaction.error
      };
    } else if (paymentLogEntry?.payment_log_entry?.usaepay_transaction) {
      return {
        referenceNumber: paymentLogEntry.payment_log_entry?.usaepay_transaction.ref_num,
        invoice: paymentLogEntry.payment_log_entry?.usaepay_transaction.invoice,
        customerNumber: paymentLogEntry.payment_log_entry?.usaepay_transaction.cust_num,
        paymentProcessor: "usaepayTransaction",
        result: paymentLogEntry.payment_log_entry?.usaepay_transaction.result,
        status: paymentLogEntry.payment_log_entry?.usaepay_transaction.status,
        error: paymentLogEntry.payment_log_entry?.usaepay_transaction.error
      };
    } else {
      return null;
    }
  }

  function viewACHDetail(logEntry: IDebtLogEntryModel | undefined) {
    const paymentLogEntry = logEntry;
    if (paymentLogEntry?.payment_log_entry?.healpay_ach_transaction) {
      const checkStatus = paymentLogEntry.payment_log_entry?.healpay_ach_transaction?.check_status;
      const checkStatusWithUppercase =
        String(checkStatus?.charAt(0).toUpperCase()) + String(checkStatus?.slice(1));
      return {
        checkStatus: checkStatusWithUppercase,
        authCode: paymentLogEntry.payment_log_entry?.healpay_ach_transaction?.response?.auth_code,
        result: paymentLogEntry.payment_log_entry?.healpay_ach_transaction?.response?.result,
        refNum: paymentLogEntry.payment_log_entry?.healpay_ach_transaction?.response?.ref_num,
        error: paymentLogEntry.payment_log_entry?.healpay_ach_transaction?.response?.error,
        paymentProcessor: "healpayAchTransaction"
      };
    } else if (paymentLogEntry?.payment_log_entry?.lucentpay_ach_transaction) {
      const checkStatus = paymentLogEntry.payment_log_entry?.lucentpay_ach_transaction?.check_status;
      const checkStatusWithUppercase =
        String(checkStatus?.charAt(0).toUpperCase()) + String(checkStatus?.slice(1));
      return {
        checkStatus: checkStatusWithUppercase,
        authCode: paymentLogEntry.payment_log_entry?.lucentpay_ach_transaction?.response?.auth_code,
        result: paymentLogEntry.payment_log_entry?.lucentpay_ach_transaction?.response?.result,
        refNum: paymentLogEntry.payment_log_entry?.lucentpay_ach_transaction?.response?.ref_num,
        error: paymentLogEntry.payment_log_entry?.lucentpay_ach_transaction?.response?.error,
        paymentProcessor: "lucentpayAchTransaction"
      };
    } else {
      return null;
    }
  }

  const CCDetail = viewCCDetail(logEntry);
  const ACHDetail = viewACHDetail(logEntry);

  const truncatedReverseId = _.truncate(logEntry?.payment_log_entry?.reversed_payment_log_entry_id ?? "", {
    length: 20,
    omission: "…"
  });

  const processingAgent = data?.userAccount?.identities.find(item => !_.isNil(item));

  let information;
  if (entry.transaction_type === IApi_PaymentTransactionType.Card && entry?.is_successful) {
    information = [
      {
        label: "Result:",
        value: CCDetail?.result
      },
      {
        label: "Authorizaton Amount:",
        value: <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
      },
      {
        label: "Type:",
        value: entry.payment_method_info?.card_info?.card_type
      },
      {
        label: "Last 4:",
        value: entry.payment_method_info?.card_info?.last_four_digits
      },
      {
        label: "Invoice:",
        value: CCDetail?.invoice
      },
      {
        label: "Reference Number:",
        value: CCDetail?.referenceNumber
      },
      {
        label: "Customer Number:",
        value: CCDetail?.customerNumber
      },
      {
        label: "Payment Processor:",
        value: CCDetail?.paymentProcessor
      }
    ];
  } else if (entry.transaction_type === IApi_PaymentTransactionType.Card && !entry?.is_successful) {
    information = [
      {
        label: "Status:",
        value: CCDetail?.status
      },
      {
        label: "Result:",
        value: CCDetail?.result
      },
      {
        label: "Payment Amount:",
        value: <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
      },
      {
        label: "Type:",
        value: entry.payment_method_info?.card_info?.card_type
      },
      {
        label: "Last 4:",
        value: entry?.payment_method_info?.card_info?.last_four_digits
      },
      {
        label: "Reference Number:",
        value: CCDetail?.referenceNumber
      },
      {
        label: "Error:",
        value: CCDetail?.error
      }
    ];
  } else if (
    entry.transaction_type === IApi_PaymentTransactionType.Ach &&
    entry?.is_successful &&
    !entry?.is_pending
  ) {
    information = [
      {
        label: "Check Status:",
        value: ACHDetail?.checkStatus
      },
      {
        label: "Authorizaton Code:",
        value: ACHDetail?.authCode
      },
      {
        label: "Authorizaton Amount:",
        value: <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
      },
      {
        label: "Result:",
        value: ACHDetail?.result
      },
      {
        label: "Reference Number:",
        value: ACHDetail?.refNum
      },
      {
        label: "Payment Processor:",
        value: ACHDetail?.paymentProcessor
      }
    ];
  } else if (
    entry.transaction_type === IApi_PaymentTransactionType.Ach &&
    !entry?.is_successful &&
    !entry?.is_pending
  ) {
    information = [
      {
        label: "Check Status:",
        value: ACHDetail?.checkStatus
      },
      {
        label: "Payment Amount:",
        value: <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
      },
      {
        label: "Reference Number:",
        value: ACHDetail?.refNum
      },
      {
        label: "Error Reason:",
        value: ACHDetail?.error
      }
    ];
  } else if (
    entry.transaction_type === IApi_PaymentTransactionType.Ach &&
    entry?.is_pending &&
    !entry?.is_successful
  ) {
    information = [
      {
        label: "Check Status:",
        value: ACHDetail?.checkStatus
      },
      {
        label: "Reference Number:",
        value: ACHDetail?.refNum
      },
      {
        label: "Pending Payment:",
        value: <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
      }
    ];
  } else if (entry.transaction_type === IApi_PaymentTransactionType.AchReturn && entry?.is_successful) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: entry?.amount?.currency ?? "USD"
    });

    const paymentAmount = formatter.format((entry?.amount?.amount ?? 0) / 100);
    information = [
      {
        label: "Check Status:",
        value: ACHDetail?.checkStatus
      },
      {
        label: "Reference Number:",
        value: ACHDetail?.refNum
      },
      {
        label: "Summary:",
        value: `"Payment amount: ${paymentAmount} charged on ${formatTimestamp(
          logEntry?.meta?.time_created,
          "MM/dd/yyyy, HH:mm OOOO"
        )}"`
      },
      {
        label: "reversedPaymentLogEntryId:",
        value: (
          <>
            {!_.isNil(logEntry?.payment_log_entry?.reversed_payment_log_entry_id) ? (
              <OverflowTooltip title={logEntry?.payment_log_entry?.reversed_payment_log_entry_id ?? ""}>
                {truncatedReverseId}
              </OverflowTooltip>
            ) : (
              "No Entry"
            )}
          </>
        )
      },
      {
        label: "Payment Processor",
        value: ACHDetail?.paymentProcessor
      }
    ];
  } else if (
    entry.transaction_type === IApi_PaymentTransactionType.Refund &&
    reversedUnifiedPaymentEntry?.transaction_type === "ACH"
  ) {
    information = [
      {
        label: "Check Status:",
        value: ACHDetail?.checkStatus
      },
      {
        label: "Reference Number:",
        value: ACHDetail?.refNum
      },
      {
        label: "Payment Processor:",
        value: ACHDetail?.paymentProcessor
      },
      {
        label: "Description:",
        value: logEntry?.payment_log_entry?.refund?.description
      },
      {
        label: "reversedPaymentLogEntryId:",
        value: (
          <>
            {!_.isNil(logEntry?.payment_log_entry?.reversed_payment_log_entry_id) ? (
              <OverflowTooltip title={logEntry?.payment_log_entry?.reversed_payment_log_entry_id ?? ""}>
                {truncatedReverseId}
              </OverflowTooltip>
            ) : (
              "No Entry"
            )}
          </>
        )
      }
    ];
  } else if (
    entry.transaction_type === IApi_PaymentTransactionType.DirectPaymentToCreditor &&
    entry?.is_successful
  ) {
    information = [
      {
        label: "Payment Amount:",
        value: <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
      },
      {
        label: "Description:",
        value: logEntry?.payment_log_entry?.direct_payment_to_creditor?.description
      },
      {
        label: "Payment Timestamp:",
        value: logEntry?.payment_log_entry?.direct_payment_to_creditor?.payment_timestamp
      },
      {
        label: "Processing Agent:",
        value: processingAgent?.full_name !== undefined ? processingAgent.full_name : "No entry"
      }
    ];
  } else if (entry.transaction_type === IApi_PaymentTransactionType.Check && entry?.is_successful) {
    information = [
      {
        label: "Payment Type:",
        value: "checkPayment"
      },
      {
        label: "Check Number:",
        value: logEntry?.payment_log_entry?.check_payment?.check_number
      },
      {
        label: "Payment Amount:",
        value: <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
      },
      {
        label: "Processing Agent:",
        value: processingAgent?.full_name !== undefined ? processingAgent.full_name : "No entry"
      }
    ];
  } else if (
    entry.transaction_type === IApi_PaymentTransactionType.Refund &&
    reversedUnifiedPaymentEntry?.transaction_type !== "ACH"
  ) {
    information = [
      {
        label: "Processed By:",
        value: logEntry?.payment_log_entry?.refund?.processed_by
      },
      {
        label: "Refund Reason:",
        value: logEntry?.payment_log_entry?.refund?.refund_reason
      },
      {
        label: "Payment Amount:",
        value: (
          <MonetaryValue
            variant="body1"
            monetaryAmount={{
              amount: logEntry?.payment_log_entry?.amount,
              currency: logEntry?.payment_log_entry?.currency
            }}
          />
        )
      },
      {
        label: "Currency:",
        value: logEntry?.payment_log_entry?.currency
      },
      {
        label: "ReversedPaymentLogEntry ID:",
        value: (
          <>
            {!_.isNil(logEntry?.payment_log_entry?.reversed_payment_log_entry_id) ? (
              <OverflowTooltip title={logEntry?.payment_log_entry?.reversed_payment_log_entry_id ?? ""}>
                {truncatedReverseId}
              </OverflowTooltip>
            ) : (
              "No Entry"
            )}
          </>
        )
      },
      {
        label: "Processing Agent:",
        value: processingAgent?.full_name !== undefined ? processingAgent.full_name : "No entry"
      }
    ];
  } else if (
    entry.transaction_type === IApi_PaymentTransactionType.PromotionalCredit &&
    entry?.is_successful
  ) {
    information = [
      {
        label: "Payment Type:",
        value: "Promotional Credit"
      },
      {
        label: "Description:",
        value: logEntry?.payment_log_entry?.promotional_credit?.description
      },
      {
        label: "Transaction ID:",
        value: logEntry?.payment_log_entry?.promotional_credit?.transaction_id
      },
      {
        label: "Status:",
        value: logEntry?.payment_log_entry?.status ? "True" : "False"
      },
      {
        label: "Payment Amount:",
        value: <MonetaryValue variant="body1" monetaryAmount={entry?.amount} />
      },
      {
        label: "Processing Agent:",
        value: processingAgent?.full_name !== undefined ? processingAgent.full_name : "No entry"
      }
    ];
  }

  return (
    <Stack direction="column" spacing={2} width={"455px"} p={2}>
      {information?.map((item, index) => (
        <DataRow
          key={index}
          inline
          title={item.label}
          titleTypography="body2"
          value={item.value ?? "No entry"}
        />
      ))}
    </Stack>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * Prosper API
 * REST API for Prosper
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';


/**
 * CrmApi - axios parameter creator
 * @export
 */
export const CrmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankruptcyControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bankruptcy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankruptcyControllerFindOnePreflight: async (id: string, contentType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankruptcyControllerFindOnePreflight', 'id', id)
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('bankruptcyControllerFindOnePreflight', 'contentType', contentType)
            const localVarPath = `/api/bankruptcy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a list of files as Bankruptcy objects and 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankruptcyControllerUpload: async (files?: Array<any>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bankruptcy/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post BK form
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankruptcyControllerUploadForm: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('bankruptcyControllerUploadForm', 'body', body)
            const localVarPath = `/api/bankruptcy/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a comment to any ObjectId. Using a PersonId or DebtId will show the comment on the main Debt View in CE
         * @param {string} objectId 
         * @param {string} comment 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsControllerAddComment: async (objectId: string, comment: string, userName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('commentsControllerAddComment', 'objectId', objectId)
            // verify required parameter 'comment' is not null or undefined
            assertParamExists('commentsControllerAddComment', 'comment', comment)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('commentsControllerAddComment', 'userName', userName)
            const localVarPath = `/api/comments/{objectId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (comment !== undefined) {
                localVarQueryParameter['comment'] = comment;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancelling a payment plan with a reason
         * @param {string} debtId 
         * @param {string} debtStateId 
         * @param {string} reason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansControllerCancelPaymentPlan: async (debtId: string, debtStateId: string, reason: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'debtId' is not null or undefined
            assertParamExists('paymentPlansControllerCancelPaymentPlan', 'debtId', debtId)
            // verify required parameter 'debtStateId' is not null or undefined
            assertParamExists('paymentPlansControllerCancelPaymentPlan', 'debtStateId', debtStateId)
            // verify required parameter 'reason' is not null or undefined
            assertParamExists('paymentPlansControllerCancelPaymentPlan', 'reason', reason)
            const localVarPath = `/api/payment-plans/{debtStateId}/cancel`
                .replace(`{${"debtStateId"}}`, encodeURIComponent(String(debtStateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (debtId !== undefined) {
                localVarQueryParameter['debtId'] = debtId;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete content of RemoteFile entity.
         * @param {string} remoteFileId 
         * @param {string} creditorId 
         * @param {string} creditorTaskId 
         * @param {string} fileRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerDelete: async (remoteFileId: string, creditorId: string, creditorTaskId: string, fileRequestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'remoteFileId' is not null or undefined
            assertParamExists('remoteFilesControllerDelete', 'remoteFileId', remoteFileId)
            // verify required parameter 'creditorId' is not null or undefined
            assertParamExists('remoteFilesControllerDelete', 'creditorId', creditorId)
            // verify required parameter 'creditorTaskId' is not null or undefined
            assertParamExists('remoteFilesControllerDelete', 'creditorTaskId', creditorTaskId)
            // verify required parameter 'fileRequestId' is not null or undefined
            assertParamExists('remoteFilesControllerDelete', 'fileRequestId', fileRequestId)
            const localVarPath = `/api/remote-files/{remoteFileId}`
                .replace(`{${"remoteFileId"}}`, encodeURIComponent(String(remoteFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (creditorId !== undefined) {
                localVarQueryParameter['creditorId'] = creditorId;
            }

            if (creditorTaskId !== undefined) {
                localVarQueryParameter['creditorTaskId'] = creditorTaskId;
            }

            if (fileRequestId !== undefined) {
                localVarQueryParameter['fileRequestId'] = fileRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/remote-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get content of RemoteFile entity.
         * @param {string} remoteFileId 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerFindOne: async (remoteFileId: string, contentType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'remoteFileId' is not null or undefined
            assertParamExists('remoteFilesControllerFindOne', 'remoteFileId', remoteFileId)
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('remoteFilesControllerFindOne', 'contentType', contentType)
            const localVarPath = `/api/remote-files/{remoteFileId}`
                .replace(`{${"remoteFileId"}}`, encodeURIComponent(String(remoteFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerFindOnePreflight: async (id: string, contentType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('remoteFilesControllerFindOnePreflight', 'id', id)
            // verify required parameter 'contentType' is not null or undefined
            assertParamExists('remoteFilesControllerFindOnePreflight', 'contentType', contentType)
            const localVarPath = `/api/remote-files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a list of files as RemoteFile objects and attach them one by one to debt verification CreditorTask.
         * @param {string} creditorId 
         * @param {string} creditorTaskId 
         * @param {string} fileRequestId 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerUpload: async (creditorId: string, creditorTaskId: string, fileRequestId: string, files?: Array<any>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditorId' is not null or undefined
            assertParamExists('remoteFilesControllerUpload', 'creditorId', creditorId)
            // verify required parameter 'creditorTaskId' is not null or undefined
            assertParamExists('remoteFilesControllerUpload', 'creditorTaskId', creditorTaskId)
            // verify required parameter 'fileRequestId' is not null or undefined
            assertParamExists('remoteFilesControllerUpload', 'fileRequestId', fileRequestId)
            const localVarPath = `/api/remote-files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (creditorId !== undefined) {
                localVarQueryParameter['creditorId'] = creditorId;
            }

            if (creditorTaskId !== undefined) {
                localVarQueryParameter['creditorTaskId'] = creditorTaskId;
            }

            if (fileRequestId !== undefined) {
                localVarQueryParameter['fileRequestId'] = fileRequestId;
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Making a search
         * @param {string} searchQuery 
         * @param {string} [creditorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearch: async (searchQuery: string, creditorId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchQuery' is not null or undefined
            assertParamExists('searchControllerSearch', 'searchQuery', searchQuery)
            const localVarPath = `/api/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (creditorId !== undefined) {
                localVarQueryParameter['creditorId'] = creditorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Authentication Stepper values
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepperControllerSubmitCallAuthentication: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('stepperControllerSubmitCallAuthentication', 'body', body)
            const localVarPath = `/api/stepper/callAuthentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Nonce required
            await setApiKeyToObject(localVarHeaderParameter, "X-Nonce", configuration)

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrmApi - functional programming interface
 * @export
 */
export const CrmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrmApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankruptcyControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankruptcyControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankruptcyControllerFindOnePreflight(id: string, contentType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankruptcyControllerFindOnePreflight(id, contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a list of files as Bankruptcy objects and 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankruptcyControllerUpload(files?: Array<any>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankruptcyControllerUpload(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post BK form
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankruptcyControllerUploadForm(body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankruptcyControllerUploadForm(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a comment to any ObjectId. Using a PersonId or DebtId will show the comment on the main Debt View in CE
         * @param {string} objectId 
         * @param {string} comment 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentsControllerAddComment(objectId: string, comment: string, userName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentsControllerAddComment(objectId, comment, userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancelling a payment plan with a reason
         * @param {string} debtId 
         * @param {string} debtStateId 
         * @param {string} reason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentPlansControllerCancelPaymentPlan(debtId: string, debtStateId: string, reason: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentPlansControllerCancelPaymentPlan(debtId, debtStateId, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete content of RemoteFile entity.
         * @param {string} remoteFileId 
         * @param {string} creditorId 
         * @param {string} creditorTaskId 
         * @param {string} fileRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteFilesControllerDelete(remoteFileId: string, creditorId: string, creditorTaskId: string, fileRequestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteFilesControllerDelete(remoteFileId, creditorId, creditorTaskId, fileRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteFilesControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteFilesControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get content of RemoteFile entity.
         * @param {string} remoteFileId 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteFilesControllerFindOne(remoteFileId: string, contentType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteFilesControllerFindOne(remoteFileId, contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteFilesControllerFindOnePreflight(id: string, contentType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteFilesControllerFindOnePreflight(id, contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a list of files as RemoteFile objects and attach them one by one to debt verification CreditorTask.
         * @param {string} creditorId 
         * @param {string} creditorTaskId 
         * @param {string} fileRequestId 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async remoteFilesControllerUpload(creditorId: string, creditorTaskId: string, fileRequestId: string, files?: Array<any>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.remoteFilesControllerUpload(creditorId, creditorTaskId, fileRequestId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Making a search
         * @param {string} searchQuery 
         * @param {string} [creditorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchControllerSearch(searchQuery: string, creditorId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchControllerSearch(searchQuery, creditorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post Authentication Stepper values
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepperControllerSubmitCallAuthentication(body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepperControllerSubmitCallAuthentication(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrmApi - factory interface
 * @export
 */
export const CrmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrmApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankruptcyControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.bankruptcyControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankruptcyControllerFindOnePreflight(id: string, contentType: string, options?: any): AxiosPromise<void> {
            return localVarFp.bankruptcyControllerFindOnePreflight(id, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a list of files as Bankruptcy objects and 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankruptcyControllerUpload(files?: Array<any>, options?: any): AxiosPromise<void> {
            return localVarFp.bankruptcyControllerUpload(files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post BK form
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankruptcyControllerUploadForm(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.bankruptcyControllerUploadForm(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a comment to any ObjectId. Using a PersonId or DebtId will show the comment on the main Debt View in CE
         * @param {string} objectId 
         * @param {string} comment 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsControllerAddComment(objectId: string, comment: string, userName: string, options?: any): AxiosPromise<void> {
            return localVarFp.commentsControllerAddComment(objectId, comment, userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancelling a payment plan with a reason
         * @param {string} debtId 
         * @param {string} debtStateId 
         * @param {string} reason 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentPlansControllerCancelPaymentPlan(debtId: string, debtStateId: string, reason: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentPlansControllerCancelPaymentPlan(debtId, debtStateId, reason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete content of RemoteFile entity.
         * @param {string} remoteFileId 
         * @param {string} creditorId 
         * @param {string} creditorTaskId 
         * @param {string} fileRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerDelete(remoteFileId: string, creditorId: string, creditorTaskId: string, fileRequestId: string, options?: any): AxiosPromise<void> {
            return localVarFp.remoteFilesControllerDelete(remoteFileId, creditorId, creditorTaskId, fileRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.remoteFilesControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get content of RemoteFile entity.
         * @param {string} remoteFileId 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerFindOne(remoteFileId: string, contentType: string, options?: any): AxiosPromise<void> {
            return localVarFp.remoteFilesControllerFindOne(remoteFileId, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerFindOnePreflight(id: string, contentType: string, options?: any): AxiosPromise<void> {
            return localVarFp.remoteFilesControllerFindOnePreflight(id, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a list of files as RemoteFile objects and attach them one by one to debt verification CreditorTask.
         * @param {string} creditorId 
         * @param {string} creditorTaskId 
         * @param {string} fileRequestId 
         * @param {Array<any>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remoteFilesControllerUpload(creditorId: string, creditorTaskId: string, fileRequestId: string, files?: Array<any>, options?: any): AxiosPromise<void> {
            return localVarFp.remoteFilesControllerUpload(creditorId, creditorTaskId, fileRequestId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Making a search
         * @param {string} searchQuery 
         * @param {string} [creditorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearch(searchQuery: string, creditorId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.searchControllerSearch(searchQuery, creditorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Authentication Stepper values
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepperControllerSubmitCallAuthentication(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.stepperControllerSubmitCallAuthentication(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bankruptcyControllerFindOnePreflight operation in CrmApi.
 * @export
 * @interface CrmApiBankruptcyControllerFindOnePreflightRequest
 */
export interface CrmApiBankruptcyControllerFindOnePreflightRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmApiBankruptcyControllerFindOnePreflight
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiBankruptcyControllerFindOnePreflight
     */
    readonly contentType: string
}

/**
 * Request parameters for bankruptcyControllerUpload operation in CrmApi.
 * @export
 * @interface CrmApiBankruptcyControllerUploadRequest
 */
export interface CrmApiBankruptcyControllerUploadRequest {
    /**
     * 
     * @type {Array<any>}
     * @memberof CrmApiBankruptcyControllerUpload
     */
    readonly files?: Array<any>
}

/**
 * Request parameters for bankruptcyControllerUploadForm operation in CrmApi.
 * @export
 * @interface CrmApiBankruptcyControllerUploadFormRequest
 */
export interface CrmApiBankruptcyControllerUploadFormRequest {
    /**
     * 
     * @type {object}
     * @memberof CrmApiBankruptcyControllerUploadForm
     */
    readonly body: object
}

/**
 * Request parameters for commentsControllerAddComment operation in CrmApi.
 * @export
 * @interface CrmApiCommentsControllerAddCommentRequest
 */
export interface CrmApiCommentsControllerAddCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmApiCommentsControllerAddComment
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiCommentsControllerAddComment
     */
    readonly comment: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiCommentsControllerAddComment
     */
    readonly userName: string
}

/**
 * Request parameters for paymentPlansControllerCancelPaymentPlan operation in CrmApi.
 * @export
 * @interface CrmApiPaymentPlansControllerCancelPaymentPlanRequest
 */
export interface CrmApiPaymentPlansControllerCancelPaymentPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmApiPaymentPlansControllerCancelPaymentPlan
     */
    readonly debtId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiPaymentPlansControllerCancelPaymentPlan
     */
    readonly debtStateId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiPaymentPlansControllerCancelPaymentPlan
     */
    readonly reason: string
}

/**
 * Request parameters for remoteFilesControllerDelete operation in CrmApi.
 * @export
 * @interface CrmApiRemoteFilesControllerDeleteRequest
 */
export interface CrmApiRemoteFilesControllerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerDelete
     */
    readonly remoteFileId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerDelete
     */
    readonly creditorId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerDelete
     */
    readonly creditorTaskId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerDelete
     */
    readonly fileRequestId: string
}

/**
 * Request parameters for remoteFilesControllerFindOne operation in CrmApi.
 * @export
 * @interface CrmApiRemoteFilesControllerFindOneRequest
 */
export interface CrmApiRemoteFilesControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerFindOne
     */
    readonly remoteFileId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerFindOne
     */
    readonly contentType: string
}

/**
 * Request parameters for remoteFilesControllerFindOnePreflight operation in CrmApi.
 * @export
 * @interface CrmApiRemoteFilesControllerFindOnePreflightRequest
 */
export interface CrmApiRemoteFilesControllerFindOnePreflightRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerFindOnePreflight
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerFindOnePreflight
     */
    readonly contentType: string
}

/**
 * Request parameters for remoteFilesControllerUpload operation in CrmApi.
 * @export
 * @interface CrmApiRemoteFilesControllerUploadRequest
 */
export interface CrmApiRemoteFilesControllerUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerUpload
     */
    readonly creditorId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerUpload
     */
    readonly creditorTaskId: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiRemoteFilesControllerUpload
     */
    readonly fileRequestId: string

    /**
     * 
     * @type {Array<any>}
     * @memberof CrmApiRemoteFilesControllerUpload
     */
    readonly files?: Array<any>
}

/**
 * Request parameters for searchControllerSearch operation in CrmApi.
 * @export
 * @interface CrmApiSearchControllerSearchRequest
 */
export interface CrmApiSearchControllerSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof CrmApiSearchControllerSearch
     */
    readonly searchQuery: string

    /**
     * 
     * @type {string}
     * @memberof CrmApiSearchControllerSearch
     */
    readonly creditorId?: string
}

/**
 * Request parameters for stepperControllerSubmitCallAuthentication operation in CrmApi.
 * @export
 * @interface CrmApiStepperControllerSubmitCallAuthenticationRequest
 */
export interface CrmApiStepperControllerSubmitCallAuthenticationRequest {
    /**
     * 
     * @type {object}
     * @memberof CrmApiStepperControllerSubmitCallAuthentication
     */
    readonly body: object
}

/**
 * CrmApi - object-oriented interface
 * @export
 * @class CrmApi
 * @extends {BaseAPI}
 */
export class CrmApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public bankruptcyControllerFindAll(options?: any) {
        return CrmApiFp(this.configuration).bankruptcyControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CrmApiBankruptcyControllerFindOnePreflightRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public bankruptcyControllerFindOnePreflight(requestParameters: CrmApiBankruptcyControllerFindOnePreflightRequest, options?: any) {
        return CrmApiFp(this.configuration).bankruptcyControllerFindOnePreflight(requestParameters.id, requestParameters.contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a list of files as Bankruptcy objects and 
     * @param {CrmApiBankruptcyControllerUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public bankruptcyControllerUpload(requestParameters: CrmApiBankruptcyControllerUploadRequest = {}, options?: any) {
        return CrmApiFp(this.configuration).bankruptcyControllerUpload(requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post BK form
     * @param {CrmApiBankruptcyControllerUploadFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public bankruptcyControllerUploadForm(requestParameters: CrmApiBankruptcyControllerUploadFormRequest, options?: any) {
        return CrmApiFp(this.configuration).bankruptcyControllerUploadForm(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a comment to any ObjectId. Using a PersonId or DebtId will show the comment on the main Debt View in CE
     * @param {CrmApiCommentsControllerAddCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public commentsControllerAddComment(requestParameters: CrmApiCommentsControllerAddCommentRequest, options?: any) {
        return CrmApiFp(this.configuration).commentsControllerAddComment(requestParameters.objectId, requestParameters.comment, requestParameters.userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancelling a payment plan with a reason
     * @param {CrmApiPaymentPlansControllerCancelPaymentPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public paymentPlansControllerCancelPaymentPlan(requestParameters: CrmApiPaymentPlansControllerCancelPaymentPlanRequest, options?: any) {
        return CrmApiFp(this.configuration).paymentPlansControllerCancelPaymentPlan(requestParameters.debtId, requestParameters.debtStateId, requestParameters.reason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete content of RemoteFile entity.
     * @param {CrmApiRemoteFilesControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public remoteFilesControllerDelete(requestParameters: CrmApiRemoteFilesControllerDeleteRequest, options?: any) {
        return CrmApiFp(this.configuration).remoteFilesControllerDelete(requestParameters.remoteFileId, requestParameters.creditorId, requestParameters.creditorTaskId, requestParameters.fileRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public remoteFilesControllerFindAll(options?: any) {
        return CrmApiFp(this.configuration).remoteFilesControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get content of RemoteFile entity.
     * @param {CrmApiRemoteFilesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public remoteFilesControllerFindOne(requestParameters: CrmApiRemoteFilesControllerFindOneRequest, options?: any) {
        return CrmApiFp(this.configuration).remoteFilesControllerFindOne(requestParameters.remoteFileId, requestParameters.contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CrmApiRemoteFilesControllerFindOnePreflightRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public remoteFilesControllerFindOnePreflight(requestParameters: CrmApiRemoteFilesControllerFindOnePreflightRequest, options?: any) {
        return CrmApiFp(this.configuration).remoteFilesControllerFindOnePreflight(requestParameters.id, requestParameters.contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a list of files as RemoteFile objects and attach them one by one to debt verification CreditorTask.
     * @param {CrmApiRemoteFilesControllerUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public remoteFilesControllerUpload(requestParameters: CrmApiRemoteFilesControllerUploadRequest, options?: any) {
        return CrmApiFp(this.configuration).remoteFilesControllerUpload(requestParameters.creditorId, requestParameters.creditorTaskId, requestParameters.fileRequestId, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Making a search
     * @param {CrmApiSearchControllerSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public searchControllerSearch(requestParameters: CrmApiSearchControllerSearchRequest, options?: any) {
        return CrmApiFp(this.configuration).searchControllerSearch(requestParameters.searchQuery, requestParameters.creditorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Authentication Stepper values
     * @param {CrmApiStepperControllerSubmitCallAuthenticationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmApi
     */
    public stepperControllerSubmitCallAuthentication(requestParameters: CrmApiStepperControllerSubmitCallAuthenticationRequest, options?: any) {
        return CrmApiFp(this.configuration).stepperControllerSubmitCallAuthentication(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}



import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface ICollapseInStepper {
  numberVerify: number;
  title: string;
  isCollapse?: boolean;
  setIsCollapse?: (value: boolean) => void;
  showArrows: boolean;
}

export function CollapseInStepper({
  numberVerify,
  title,
  isCollapse,
  showArrows,
  setIsCollapse
}: ICollapseInStepper): React.ReactElement {
  const theme = useTheme();

  return (
    <Stack
      direction={"row"}
      pt={2}
      pl={1.5}
      mr={1.5}
      mb={2}
      pb={2}
      pr={1}
      justifyContent={"space-between"}
      sx={{ backgroundColor: theme.palette.grey[100] }}>
      <Stack direction={"row"}>
        <Box
          sx={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            backgroundColor: theme.palette.common.black
          }}>
          <Typography
            color={theme.palette.common.white}
            sx={{ left: "12px", position: "relative", top: "5px" }}
            fontWeight={"bold"}>
            {numberVerify}
          </Typography>
        </Box>
        <Typography sx={{ position: "relative", top: "5px", left: "10px" }} variant="h4">
          {title}
        </Typography>
      </Stack>
      {showArrows && (
        <Stack onClick={() => setIsCollapse?.(!isCollapse)}>
          {isCollapse ? (
            <KeyboardArrowUpIcon sx={{ fontSize: "2.4em", cursor: "pointer" }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ fontSize: "2.4em", cursor: "pointer" }} />
          )}
        </Stack>
      )}
    </Stack>
  );
}

import * as React from "react";
import { useParams } from "react-router-dom";
import { AccountViewQuery } from "~/core/components/domain/accounts/AccountView";
import { WideLayout } from "~/core/components/layouts/WideLayout";
import { ProgressIndicator } from "~/core/components/widgets/ProgressIndicator";
import { useSafeQuery } from "~/core/utils/apollo";
import { AuthStepper } from "~/core/components/domain/accounts/AuthStepper";
import { gql } from "@apollo/client";

export interface IAuthenticate {
  accountId: string;
}

export const AccountViewAndSSNQuery = gql(`
query AccountViewSSN($accountId: ID!) {
  accountSSN: DebtOut(id: $accountId) {
    ...AccountModel
    ssn_last4
  }
}
`);

export default function Authenticate(): React.ReactElement {
  const { accountId } = useParams<IAuthenticate>();
  const { data } = useSafeQuery(AccountViewAndSSNQuery, {
    variables: { accountId }
  });

  if (data?.accountSSN?.debt) {
    return (
      <WideLayout widePadding>
        <AuthStepper account={data.accountSSN} ssn_last4={data.accountSSN.ssn_last4} />
      </WideLayout>
    );
  }

  return (
    <WideLayout widePadding>
      <ProgressIndicator message={`Loading account information...`} />
    </WideLayout>
  );
}

import type { FieldFunctionOptions, FieldReadFunction } from "@apollo/client";
import { isReference } from "@apollo/client";
import type { SafeReadonly } from "@apollo/client/cache/core/types/common";
import type { Reference } from "@apollo/client/utilities";
import Debug from "debug";
import * as _ from "lodash";
import type { ICamunda_KeyValuePair, Maybe } from "~/gql/types";
import { getOptionalVariable } from "./camunda";
import { catchToNull } from "./helpers";

const DEBUG = Debug("graphql");

export type TaggedReference<T> = Reference & { ["__refTag"]: T };

export type Unwrap<V> = V extends ReadonlyArray<infer I>
  ? Unwrap<I>
  : V extends Array<infer I>
  ? Unwrap<I>
  : Exclude<V, null | undefined>;

type ReadFieldInput<V> = Reference | Reference[] | V | V[];

type ReadFieldOutput<V> =
  | ReadonlyArray<Reference>
  | ReadonlyArray<V>
  | Reference
  | SafeReadonly<V>
  | undefined;

export function isTaggedReference<V>(value: ReadFieldOutput<V>): value is TaggedReference<Unwrap<V>> {
  return !_.isArray(value) && isReference(value);
}

function isArrayOfReferences<V>(value: ReadFieldOutput<V>): value is Array<TaggedReference<Unwrap<V>>> {
  return _.isArray(value) && _.every(value, isReference);
}

function isArrayOfPrimitives<V>(value: ReadFieldOutput<V>): value is SafeReadonly<V[]> {
  return _.isArray(value) && _.every(value, isPrimitive);
}

function isPrimitive<V>(value: ReadFieldOutput<V>): value is SafeReadonly<V> {
  if (_.isArray(value)) {
    return false;
  }
  return !isReference(value);
}

type ReadPrimitiveFn<T> = <K extends string & keyof T>(fieldName: K) => SafeReadonly<T[K]>;

const readPrimitive =
  <T>({ readField, fieldName, canRead, storage }: FieldFunctionOptions): ReadPrimitiveFn<T> =>
  <K extends string & keyof T>(name: K): SafeReadonly<T[K]> => {
    const ret = readField<ReadFieldInput<T[K]>>(name);
    DEBUG(`[policy][${fieldName}] readPrimitive(${name})`, {
      ret,
      storage
    });
    if (!canRead(storage)) {
      throw new MissingStorage(`[${fieldName}] Couldn't read field "${name}" from entity that's gone.`);
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ret === undefined) {
      throw new MissingValue(`[${fieldName}] Couldn't read primitive field "${name}".`);
    }
    if (isPrimitive(ret)) {
      return ret;
    } else {
      throw new InvalidValue(`[${fieldName}] Reading field ${name} didn't return expected primitive.`);
    }
  };

const readPrimitiveFrom =
  ({ readField, fieldName, canRead }: FieldFunctionOptions) =>
  <F>(from: TaggedReference<F> | undefined) =>
  <K extends string & keyof F>(name: K): SafeReadonly<F[K]> => {
    if (!canRead(from)) {
      throw new MissingStorage(`[${fieldName}] Couldn't read field "${name}" from entity that's gone.`);
    }
    if (!from) {
      throw new MissingValue(`[${fieldName}] Couldn't read field "${name}" from empty reference.`);
    }
    const ret = readField<ReadFieldInput<F[K]>>(name, from);
    DEBUG(`[policy][${fieldName}] readPrimitive(${name})`, {
      ret,
      from
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ret === undefined || ret === null) {
      throw new MissingValue(`[${fieldName}] Couldn't read primitive field "${name}" from ${from.__ref}.`);
    }
    if (isPrimitive(ret)) {
      return ret;
    } else {
      throw new InvalidValue(
        `[${fieldName}] Reading field ${name} from ${JSON.stringify(from)} didn't return a primitive.`
      );
    }
  };

type ReadReferenceFn<T> = <K extends string & keyof T>(fieldName: K) => TaggedReference<Unwrap<T[K]>>;

const readReference =
  <T>({ readField, fieldName, canRead, storage }: FieldFunctionOptions): ReadReferenceFn<T> =>
  <K extends string & keyof T>(name: K): TaggedReference<Unwrap<T[K]>> => {
    const ret = readField<ReadFieldInput<T[K]>>(name);
    DEBUG(`[policy][${fieldName}] readReference(${name})`, { ret });
    if (!canRead(storage)) {
      throw new MissingStorage(`[${fieldName}] Couldn't read field "${name}" from entity that's gone.`);
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ret === undefined || ret === null) {
      throw new MissingValue(`[${fieldName}] Couldn't read reference from field "${name}".`);
    }
    if (isTaggedReference(ret)) {
      return ret;
    } else {
      throw new InvalidValue(`[${fieldName}] Reading field ${name} didn't return a reference.`);
    }
  };

const readReferenceFrom =
  ({ readField, fieldName, canRead }: FieldFunctionOptions) =>
  <F>(from: TaggedReference<F> | undefined) =>
  <K extends string & keyof F>(name: K): TaggedReference<Unwrap<F[K]>> => {
    if (!canRead(from)) {
      throw new MissingStorage(`[${fieldName}] Couldn't read field "${name}" from entity that's gone.`);
    }
    if (!from) {
      throw new MissingValue(`[${fieldName}] Couldn't read field "${name}" from empty reference.`);
    }
    const ret = readField<ReadFieldInput<F[K]>>(name, from);
    DEBUG(`[policy][${fieldName}] readReference(${name})`, {
      ret,
      from
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ret === undefined || ret === null) {
      throw new MissingValue(`[${fieldName}] Couldn't read field "${name}" from ${from.__ref}.`);
    }
    if (isTaggedReference(ret)) {
      return ret;
    } else {
      throw new InvalidValue(
        `[${fieldName}] Reading field ${name} from ${JSON.stringify(from)} didn't return a reference.`
      );
    }
  };

type ReadReferencesFn<T> = <K extends string & keyof T>(fieldName: K) => Array<TaggedReference<Unwrap<T[K]>>>;

const readReferences =
  <T>({ readField, fieldName, canRead, storage }: FieldFunctionOptions): ReadReferencesFn<T> =>
  <K extends string & keyof T>(name: K): Array<TaggedReference<Unwrap<T[K]>>> => {
    const ret = readField<ReadFieldInput<T[K]>>(name);
    DEBUG(`[policy][${fieldName}] readReferences(${name})`, { ret });
    if (!canRead(storage)) {
      throw new MissingStorage(`[${fieldName}] Couldn't read field "${name}" from entity that's gone.`);
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ret === undefined || ret === null) {
      throw new MissingValue(`[${fieldName}] Couldn't read reference from field "${name}".`);
    }
    if (isArrayOfReferences(ret)) {
      return ret;
    } else {
      throw new InvalidValue(`[${fieldName}] Reading field ${name} didn't return an array of references.`);
    }
  };

const readReferencesFrom =
  ({ readField, fieldName, canRead }: FieldFunctionOptions) =>
  <F>(from: TaggedReference<F> | undefined) =>
  <K extends string & keyof F>(name: K): Array<TaggedReference<Unwrap<F[K]>>> => {
    if (!canRead(from)) {
      throw new MissingStorage(`[${fieldName}] Couldn't read field "${name}" from entity that's gone.`);
    }
    if (!from) {
      throw new MissingValue(`[${fieldName}] Couldn't read field "${name}" from empty reference.`);
    }
    const ret = readField<ReadFieldInput<F[K]>>(name, from);
    DEBUG(`[policy][${fieldName}] readReferences(${name})`, {
      ret,
      from
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ret === undefined) {
      throw new MissingValue(
        `[${fieldName}] Couldn't read references from field "${name}" from ${from.__ref}.`
      );
    }
    if (ret === null) {
      return [];
    } else if (isArrayOfReferences(ret)) {
      return ret;
    } else {
      throw new InvalidValue(`[${fieldName}] Reading field ${name} didn't return an array of references.`);
    }
  };

type ReadCamundaVarFn<T> = (fieldName: string) => string;

const readCamundaVar =
  <T>({ readField, fieldName, canRead, storage }: FieldFunctionOptions): ReadCamundaVarFn<T> =>
  (name: string): string => {
    if (!canRead(storage)) {
      throw new MissingStorage(
        `[${fieldName}] Couldn't read Camunda variables from field "${name}" from entity that's gone.`
      );
    }
    const vars = readField<ReadFieldInput<Maybe<ICamunda_KeyValuePair>>>("variables");
    if (vars === undefined || vars === null) {
      throw new MissingValue(`[${fieldName}] Couldn't read field "variables".`);
    }
    if (!isReference(vars) && isArrayOfPrimitives(vars)) {
      const value = getOptionalVariable(vars, name);
      DEBUG(`[policy][${fieldName}] readCamundaVar(${name})`, { value });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (value === undefined || value === null) {
        throw new MissingValue(
          `[${fieldName}] Couldn't read Camunda variable field "${name}" from "variables".`
        );
      }
      return value;
    } else {
      throw `[${fieldName}] Reading field ${name} didn't return an array of camunda variables.`;
    }
  };

export const defineReader = <T, R>(
  fn: (
    opts: FieldFunctionOptions & {
      readPrimitive: ReadPrimitiveFn<T>;
      readReference: ReadReferenceFn<T>;
      readReferences: ReadReferencesFn<T>;
      readCamundaVar: ReadCamundaVarFn<T>;
      readPrimitiveFrom: ReturnType<typeof readPrimitiveFrom>;
      readReferenceFrom: ReturnType<typeof readReferenceFrom>;
      readReferencesFrom: ReturnType<typeof readReferencesFrom>;
    }
  ) => R | null | undefined
): FieldReadFunction<R | null> => {
  return (existing, opts): R | null => {
    try {
      return (
        fn({
          ...opts,
          readPrimitive: readPrimitive<T>(opts),
          readReference: readReference<T>(opts),
          readReferences: readReferences<T>(opts),
          readCamundaVar: readCamundaVar<T>(opts),
          readPrimitiveFrom: readPrimitiveFrom(opts),
          readReferenceFrom: readReferenceFrom(opts),
          readReferencesFrom: readReferencesFrom(opts)
        }) ?? null
      );
    } catch (error) {
      if (error instanceof MissingStorage) {
        console.warn(error);
        return null;
      } else {
        throw error;
      }
    }
  };
};

export class TypePolicyError extends Error {}

export class MissingValue extends TypePolicyError {
  override name = "MissingValue";
}

export class MissingStorage extends TypePolicyError {
  override name = "MissingStorage";
}

export class InvalidValue extends TypePolicyError {
  override name = "MissingStorage";
}

export function missingAsNull<R>(fn: () => R): R | null {
  return catchToNull(fn, MissingValue);
}

import { styled } from "@mui/material";
import type {
  GridActionsCellItemProps,
  GridActionsColDef,
  GridAlignment,
  GridCellValue,
  GridColDef,
  GridColType,
  GridRowParams
} from "@mui/x-data-grid";
import { DataGrid, getGridDefaultColumnTypes } from "@mui/x-data-grid";
import * as _ from "lodash";
import { compact, isNumber, isString } from "lodash";
import type { ReactChild, ReactElement, ReactNode } from "react";
import * as React from "react";
import { OverflowTooltip } from "./OverflowTooltip";

type DataGridComponent = typeof DataGrid;
export const StyledDataGrid: DataGridComponent = styled(DataGrid)`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
    outline: none;
  }

  &.MuiDataGrid-root .actionMouse {
    cursor: pointer;
  }

  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }

  &.MuiDataGrid-root .MuiDataGrid-cell--editable {
    cursor: pointer;
  }

  &.MuiDataGrid-root .MuiDataGrid-editInputCell input[type="date"] {
    font-size: 0.8rem;
  }

  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
    font-weight: bolder;
  }

  &.MuiDataGrid-root .MuiDataGrid-columnHeader {
    padding: 0 5px;
    font-size: small;
  }

  &.MuiDataGrid-root .MuiDataGrid-sortIcon {
    font-size: small;
  }

  &.MuiDataGrid-root .MuiDataGrid-filterIcon {
    font-size: small;
  }

  &.MuiDataGrid-root .MuiDataGrid-menuIcon {
    font-size: small;
  }

  &.MuiDataGrid-root .MuiDataGrid-menuIconButton {
    padding: 0;
  }

  &.MuiDataGrid-root .MuiDataGrid-menuIconButton:hover {
    padding: 0;
    background-color: transparent;
  }

  &.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none;
  }

  &.MuiDataGrid-root .MuiDataGrid-columnHeader,
  &.MuiDataGrid-root .MuiDataGrid-cell {
    border-right: ${({ showCellRightBorder }) => (showCellRightBorder ? "1px" : "0px")} solid
      ${({ theme }) => (theme.palette.mode === "light" ? "#f0f0f0" : "#303030")};
  }

  .MuiDataGrid-row > .MuiDataGrid-cell:nth-last-of-type(1) {
    border-right: none;
  }

  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
  }
` as any as DataGridComponent;

// Controls `flex` attribute of the column cell.
const dataGridColumnWidths = {
  small: 1,
  medium: 1.3,
  wide: 1.5,
  wider: 2
};

const dataGridDefaultColumnDef: Partial<GridColDef> = {
  hideSortIcons: false,
  headerAlign: "center",
  align: "left",
  disableReorder: false,
  resizable: false
};

type TooltipRenderArg<Row> = boolean | string | ((row: Row) => TooltipValue | null | undefined);

function renderWithTooltip<Row>(
  field: string,
  fieldValue: ReactChild | null | undefined,
  tooltipCandidateValue: TooltipValue | undefined,
  row: Row,
  tooltip: TooltipRenderArg<Row>,
  shouldOverflow = true
): ReactNode {
  if (tooltip === false) {
    return fieldValue;
  }
  let tooltipValue: TooltipValue | undefined;
  if (tooltip === true) {
    // Use field value as a tooltip, but only if it's a string or a number
    if (tooltipCandidateValue !== undefined) {
      tooltipValue = tooltipCandidateValue;
    } else {
      console.warn(`Column ${field} has tooltip set to true, but renders to a non-string`);
    }
  } else if (_.isString(tooltip)) {
    tooltipValue = tooltip;
  } else {
    tooltipValue = tooltip(row) ?? undefined;
  }
  if (tooltipValue) {
    return (
      <OverflowTooltip title={tooltipValue} overflow={shouldOverflow}>
        {fieldValue}
      </OverflowTooltip>
    );
  } else {
    return fieldValue;
  }
}

export interface SelectOption<V extends number | string> {
  value: V | undefined;
  label: string;
}

export interface DataGridTypeMap {
  [x: GridColType]: GridCellValue;
  string?: string | null;
  number?: string | null;
  date?: Date | null;
  dateTime?: Date | null;
  boolean?: boolean | null;
  singleSelect?: GridCellValue;
}

interface DataGridColumn<Row, ValueType extends GridCellValue> {
  title?: string;
  width?: number | keyof typeof dataGridColumnWidths;
  hidden?: boolean;
  getter: (row: Row) => ValueType | null | undefined;
  formatter?: (value: ValueType | null | undefined, row: Row) => number | string | null | undefined;
  tooltip?: TooltipRenderArg<Row>;
  tooltipOverflow?: boolean;
  action?: (row: Row) => ReactNode;
  disabled?: boolean;
  editable?: boolean;
  values?: Array<SelectOption<number | string>>;
  render?: (value: ValueType | null | undefined, row: Row) => ReactChild | null | undefined;
  align?: GridAlignment;
  filterable?: boolean;
  sortable?: boolean;
  customization?: Partial<GridColDef>;
}

export interface ITableColumn<Row> {
  readonly field: string;
  readonly hasActions: boolean;
  readonly columnDef: GridActionsColDef | GridColDef;
}

export type TooltipValue = number | string;

function canBeTooltip(value: unknown): value is TooltipValue {
  return _.isString(value) || _.isNumber(value);
}

export class DataColumn<Row, Type extends GridCellValue> implements ITableColumn<Row> {
  readonly columnDef: GridColDef;

  get hasActions(): boolean {
    return this.columnDef.type === "actions";
  }

  get field(): string {
    return this.columnDef.field;
  }

  constructor(
    field: string,
    type: keyof DataGridTypeMap,
    {
      title,
      width = "medium",
      hidden,
      getter,
      formatter,
      tooltip = true,
      tooltipOverflow = true,
      action,
      editable,
      values,
      render,
      filterable = true,
      sortable = true,
      align = "center",
      customization
    }: DataGridColumn<Row, Type>
  ) {
    const defaultColumnAttrs = getGridDefaultColumnTypes()[type];
    this.columnDef = {
      ...defaultColumnAttrs,
      ...dataGridDefaultColumnDef,
      flex: _.isString(width) ? dataGridColumnWidths[width] : undefined,
      width: _.isNumber(width) ? width : undefined,
      field,
      headerName: title ?? "",
      valueGetter({ row }) {
        return getter(row as Row);
      },
      ...(formatter
        ? {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            valueFormatter({ row, value }: any) {
              return formatter(value as Type, row as Row);
            }
          }
        : undefined),
      renderCell({ row, value }) {
        const typedRow = row as Row;
        let fieldValue: ReactChild | null | undefined;
        if (isString(value) || isNumber(value)) {
          fieldValue = value;
        }
        let tooltipValue: TooltipValue | undefined;
        if (canBeTooltip(value)) {
          tooltipValue = value;
        }
        if (formatter) {
          fieldValue = formatter(value as Type, typedRow);
          if (canBeTooltip(fieldValue)) {
            tooltipValue = fieldValue;
          }
        }
        if (render) {
          fieldValue = render(value as Type, typedRow);
          if (canBeTooltip(fieldValue)) {
            tooltipValue = fieldValue;
          }
        }
        const inner = renderWithTooltip(field, fieldValue, tooltipValue, typedRow, tooltip, tooltipOverflow);
        if (action) {
          return (
            <>
              {inner}
              {action(typedRow)}
            </>
          );
        } else {
          return inner;
        }
      },
      hide: hidden,
      valueOptions: values,
      editable,
      align,
      filterable,
      sortable,
      ...customization
    };
  }
}

export class GridColumnBuilder<Row> {
  private readonly _columns: Array<ITableColumn<Row>> = [];

  get columns(): ReadonlyArray<ITableColumn<Row>> {
    return this._columns;
  }

  string(field: string, params: DataGridColumn<Row, string>): void {
    !!params.disabled || this._columns.push(new DataColumn(field, "string", params));
  }

  date(field: string, params: DataGridColumn<Row, Date>): void {
    !!params.disabled || this._columns.push(new DataColumn(field, "date", params));
  }

  dateTime(field: string, params: DataGridColumn<Row, Date>): void {
    !!params.disabled || this._columns.push(new DataColumn(field, "dateTime", params));
  }

  select<V extends number | string>(
    field: string,
    values: Array<SelectOption<V>>,
    params: DataGridColumn<Row, V>
  ): void {
    !!params.disabled ||
      this._columns.push(
        new DataColumn(field, "singleSelect", {
          ...params,
          values
        })
      );
  }

  column<K extends keyof DataGridTypeMap>(
    field: string,
    type: K,
    params: DataGridColumn<Row, DataGridTypeMap[K]>
  ): void {
    !!params.disabled || this._columns.push(new DataColumn(field, type, params));
  }

  actions(
    buttons: (row: Row) => Array<ReactElement<GridActionsCellItemProps> | undefined>,
    columnDef: Omit<GridActionsColDef, "field" | "getActions" | "type"> = {}
  ): void {
    this._columns.push({
      field: "actions",
      hasActions: true,
      columnDef: {
        field: "actions",
        type: "actions",
        disableColumnMenu: true,
        align: "right",
        disableReorder: true,
        sortable: false,
        filterable: false,
        getActions(params: GridRowParams) {
          return compact(buttons(params.row as Row));
        },
        ...columnDef
      }
    });
  }
}

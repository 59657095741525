import {
  Button,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { chain } from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { MonetaryValue } from "~/core/components/domain/widgets/MonetaryValue";
import type { IDebtOutBalanceModel, IMonetaryModel, Maybe } from "~/gql/types";
import { Block } from "./Block";

export interface BalanceDetailRow {
  label: string;
  principal: Maybe<IMonetaryModel>;
  interest: Maybe<IMonetaryModel>;
  fees: Maybe<IMonetaryModel>;
  cost: Maybe<IMonetaryModel>;
  total: Maybe<IMonetaryModel>;
}

export interface IBalanceDetailsProps {
  debtBalances: IDebtOutBalanceModel;
}

export function getBalanceDetails(debtBalances: IDebtOutBalanceModel) {
  const balanceAtPlacement = sumMonetaryValues([
    debtBalances?.balance_details_at_placement?.principal,
    debtBalances?.balance_details_at_placement?.interest,
    debtBalances?.balance_details_at_placement?.fees,
    debtBalances?.balance_details_at_placement?.costs
  ]);

  const totalAmountAtPlacement: BalanceDetailRow = {
    label: "Total Amount At Placement",
    principal: debtBalances.balance_details_at_placement?.principal,
    interest: debtBalances.balance_details_at_placement?.interest,
    fees: debtBalances.balance_details_at_placement?.fees,
    cost: debtBalances.balance_details_at_placement?.costs,
    total: balanceAtPlacement
  };
  const totalAmountToCollect: BalanceDetailRow = {
    label: "Total Amount to Collect",
    principal: debtBalances._include_debt?.initial_principal,
    interest: debtBalances._include_debt?.initial_interest,
    fees: debtBalances._include_debt?.initial_fees,
    cost: debtBalances._include_debt?.initial_costs,
    total: debtBalances.initial_total
  };
  const outstandingAmountsToCollect: BalanceDetailRow = {
    label: "Outstanding Amounts to Collect",
    principal: debtBalances.balance_details?.principal,
    interest: debtBalances.balance_details?.interest,
    fees: debtBalances.balance_details?.fees,
    cost: debtBalances.balance_details?.costs,
    total: debtBalances.balance
  };

  const result = {
    balanceAtPlacement,
    totalAmountAtPlacement,
    totalAmountToCollect,
    outstandingAmountsToCollect
  };

  return result;
}

export function BalanceDetails({ debtBalances }: IBalanceDetailsProps): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClose = () => void setAnchorEl(null);

  const isOpen = !!anchorEl;
  const buttonId = isOpen ? "balance-details-table" : undefined;

  const balanceDetails = getBalanceDetails(debtBalances);

  const rows: BalanceDetailRow[] = [
    balanceDetails.totalAmountAtPlacement,
    balanceDetails.totalAmountToCollect,
    balanceDetails.outstandingAmountsToCollect
  ];

  return (
    <>
      <Button aria-describedby={buttonId} variant="text" onClick={evt => setAnchorEl(evt.currentTarget)}>
        <MonetaryValue monetaryAmount={balanceDetails.balanceAtPlacement} />
      </Button>
      <Popover
        id={buttonId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}>
        <Block
          title="Balance Details"
          titlePostfix={
            <Button sx={{ marginLeft: "auto" }} onClick={handleClose}>
              Close
            </Button>
          }>
          <BalanceDetailsTable rows={rows} />
        </Block>
      </Popover>
    </>
  );
}

export interface IBalanceDetailTableProps {
  rows: BalanceDetailRow[];
}

function sumMonetaryValues(values: Array<Maybe<IMonetaryModel>>): IMonetaryModel {
  return chain(values)
    .compact()
    .reduce(
      (result, balance) => {
        return {
          amount: result.amount + (balance.amount ?? 0),
          currency: balance.currency ?? result.currency
        };
      },
      {
        amount: 0,
        currency: "USD"
      }
    )
    .value();
}

function BalanceDetailsTable({ rows }: IBalanceDetailTableProps): ReactElement {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>Principal</TableCell>
              <TableCell>Interest</TableCell>
              <TableCell>Fees</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ label, principal, interest, fees, cost, total }) => (
              <TableRow key={label}>
                <TableCell>{label}</TableCell>
                <TableCell>
                  <MonetaryValue monetaryAmount={principal} />
                </TableCell>
                <TableCell>
                  <MonetaryValue monetaryAmount={interest} />
                </TableCell>
                <TableCell>
                  <MonetaryValue monetaryAmount={fees} />
                </TableCell>
                <TableCell>
                  <MonetaryValue monetaryAmount={cost} />
                </TableCell>
                <TableCell>
                  <MonetaryValue monetaryAmount={total} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

import { Box, Typography } from "@mui/material";
import { Card } from "@mui/material";
import { CardContent } from "@mui/material";
import { CardHeader } from "@mui/material";
import { Divider } from "@mui/material";
import { LinearProgress } from "@mui/material";
import { Skeleton } from "@mui/material";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import type { Variant } from "@mui/material/styles/createTypography";
import _ from "lodash";
import type { ReactElement, ReactNode } from "react";
import * as React from "react";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";

export interface BlockProps {
  title?: string | null | undefined;
  titlePostfix?: ReactNode;
  titlePrefix?: ReactNode;
  noBottomPadding?: boolean;
  noHorizontalPadding?: boolean;
  isCard?: boolean;
  subheader?: ReactNode;
  titleTypography?: Variant;
  embedded?: boolean;
  customBackground?: string;
  paddingTopHeader?: string;
  isLoading?: boolean;
  customColorDivider?: string;
  handleClickHeader?: () => void;
  borderRadius?: string;
  paddingTitle?: string;
  heightCard?: string;
  paddingTopContent?: string | number;
  hasOverflowY?: boolean;
  tooltipValueTitle?: string | null | undefined;
  titleColor?: string;
  width?: number;
}

export function Block({
  title,
  titlePrefix,
  titlePostfix,
  noBottomPadding = false,
  noHorizontalPadding = false,
  paddingTopHeader,
  embedded = false,
  customBackground = "#FFF",
  customColorDivider,
  isCard,
  subheader,
  titleTypography,
  isLoading = false,
  handleClickHeader,
  borderRadius,
  heightCard,
  paddingTitle,
  paddingTopContent,
  hasOverflowY,
  tooltipValueTitle,
  titleColor,
  width,
  ...restProps
}: BlockProps &
  (
    | { children: ReactNode; skeleton?: undefined }
    | { skeleton: number; children?: undefined }
  )): ReactElement {
  titleTypography ??= embedded ? "h6" : "h5";
  const theme = useTheme();
  const cardTitle = (
    <Stack direction={"row"} spacing={1} alignItems={"center"}>
      {titlePrefix}
      {tooltipValueTitle ? (
        <OverflowTooltip title={tooltipValueTitle}>{title}</OverflowTooltip>
      ) : (
        <Typography
          variant={titleTypography}
          sx={{ color: titleColor ? titleColor : theme.palette.common.black }}>
          {title}
        </Typography>
      )}
      <>{titlePostfix}</>
    </Stack>
  );
  return (
    <Card
      variant={isCard ? "elevation" : "outlined"}
      sx={{
        borderRadius: embedded ? (borderRadius ? borderRadius : 0.375) : undefined,
        minWidth: "15rem",
        backgroundColor: customBackground,
        height: heightCard,
        overflowY: hasOverflowY ? "scroll" : "hidden"
      }}>
      {isLoading ? <LinearProgress /> : <Box sx={{ height: width && width >= 1700 ? "4px" : "9px" }} />}
      <CardHeader
        title={cardTitle}
        sx={{
          padding:
            paddingTopHeader && !paddingTitle
              ? `${paddingTopHeader} 16px 0px 16px !important`
              : paddingTitle
              ? paddingTitle
              : 2,
          alignItems: "center",
          cursor: handleClickHeader ? "pointer" : "unset"
        }}
        titleTypographyProps={{ variant: titleTypography }}
        onClick={handleClickHeader}
        subheader={
          <Stack>
            {subheader}
            {!embedded && (
              <Divider
                color={customColorDivider ? customColorDivider : theme.palette.grey[700]}
                sx={{ marginTop: subheader ? undefined : 1.6 }}
              />
            )}
          </Stack>
        }
      />

      <CardContent
        sx={{
          ":last-child": noBottomPadding
            ? { paddingBottom: 0 }
            : { paddingBottom: embedded ? "1ex" : undefined },
          paddingLeft: noHorizontalPadding ? 0 : undefined,
          paddingRight: noHorizontalPadding ? 0 : undefined,
          ...(paddingTopContent && { paddingTop: paddingTopContent })
        }}>
        {restProps.children ? (
          restProps.children
        ) : (
          <Stack spacing={1}>
            {_.times(restProps.skeleton ?? 1).map(idx => (
              <Skeleton key={idx} variant="rectangular" animation={false} />
            ))}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

import * as _ from "lodash";
import type { IGenericAppConfig } from "../config/app.config";
import type { ICamundaConfig } from "../config/camunda.config";

interface LegacyURLParams {
  path?: string[];
  params?: Record<string, boolean | number | string>;
  terminalSlash?: boolean;
  appConfig: IGenericAppConfig;
}

export function urlToLegacy({ path, params, terminalSlash, appConfig }: LegacyURLParams): string {
  const url = new URL(appConfig.ceBaseUrl);
  if (!url.pathname.endsWith("/") && !_.isEmpty(path)) {
    url.pathname += "/";
  }
  url.pathname += _.map(path, encodeURIComponent).join("/");
  if (terminalSlash) {
    url.pathname += "/";
  }
  _.forEach(params, (v, k) => {
    url.searchParams.set(k, _.toString(v));
  });
  return url.toString();
}

export function urlToCreditorLegacy(creditorId: string, appConfig: IGenericAppConfig): string {
  return urlToLegacy({
    path: ["app", "creditor"],
    params: { viewAs: creditorId },
    terminalSlash: true,
    appConfig
  });
}

export function urlToAccountNumLegacy(accountNum: string, appConfig: IGenericAppConfig): string {
  return urlToLegacy({
    path: ["app", "admin"],
    params: { q: `reference:${_.trim(accountNum)}` },
    terminalSlash: true,
    appConfig
  });
}

export function urlToDebtorLegacy(debtorId: string, appConfig: IGenericAppConfig): string {
  return urlToLegacy({
    path: ["app", "admin", "explorer", "debtor", debtorId],
    terminalSlash: true,
    appConfig
  });
}

export function urlToDownloadRemoteFile(remoteFileId: string, appConfig: IGenericAppConfig): string {
  return [appConfig.ceBaseUrl, "files", "disputes", encodeURIComponent(remoteFileId)].join("/");
}

export function urlToCamundaCockpitTask(processInstanceId: string, { baseUrl }: ICamundaConfig): string {
  return [
    baseUrl,
    "app",
    "cockpit",
    "default",
    "#",
    "process-instance",
    encodeURIComponent(processInstanceId),
    "runtime?detailsTab=user-tasks-tab"
  ].join("/");
}

export function urlToTemplateEmail(dleId: string, appConfig: IGenericAppConfig): string {
  return [appConfig.ceBaseUrl, "api", "v1", "objects", "debtLogEntries", dleId, "renderedContent"].join("/");
}

import { isApolloError } from "@apollo/client";
import { ExpandLess, ExpandMore, Handyman, Refresh } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import * as _ from "lodash";
import type { ReactElement } from "react";
import { useState } from "react";

export interface IErrorAlertProps {
  message?: string;
  details?: string;
  error?: unknown;
  resetErrorBoundary?: (...args: unknown[]) => void;
}

export function ErrorAlert({ error, message, details, resetErrorBoundary }: IErrorAlertProps): ReactElement {
  console.error(error);
  console.error({ ErrorAlert: { message, details } });
  const [isExpanded, setExpanded] = useState(false);
  const theme = useTheme();
  const btnTextColor: string = theme.palette.error.contrastText;

  const expandDetailsBtn = (
    <IconButton onClick={() => setExpanded(!isExpanded)} sx={{ color: btnTextColor }} size={"small"}>
      {isExpanded ? <ExpandLess fontSize={"small"} /> : <ExpandMore fontSize={"small"} />}
    </IconButton>
  );
  const refreshBtn = (
    <Tooltip title={"Reload the page."}>
      <IconButton onClick={() => resetErrorBoundary?.(false)} sx={{ color: btnTextColor }} size={"small"}>
        <Refresh fontSize={"small"} />
      </IconButton>
    </Tooltip>
  );
  const recoverBtn = (
    <Tooltip title={"Reset the caches, sign out and reload the page."}>
      <IconButton onClick={() => resetErrorBoundary?.(true)} sx={{ color: btnTextColor }} size={"small"}>
        <Handyman fontSize={"small"} />
      </IconButton>
    </Tooltip>
  );

  const shouldShowErrorDetails = _.isError(error) || details;

  const actions = (
    <Stack direction={"row"} justifyItems={"center"}>
      {resetErrorBoundary ? recoverBtn : undefined}
      {resetErrorBoundary ? refreshBtn : undefined}
      {shouldShowErrorDetails ? expandDetailsBtn : undefined}
    </Stack>
  );
  return (
    <Alert
      variant="filled"
      severity="error"
      action={actions}
      sx={{
        margin: "auto",
        marginBottom: "1em",
        width: "90%",
        textAlign: "left",
        ".MuiAlert-message": {
          flexGrow: 3
        }
      }}>
      <AlertTitle>Unexpected Error</AlertTitle>
      {message ?? (_.isError(error) ? error?.message : details)}
      {shouldShowErrorDetails && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <ErrorDetailsContainer variant={"outlined"}>
            {error ? (
              <Stack>
                {details}
                <DescribeError error={error} />
              </Stack>
            ) : (
              details
            )}
          </ErrorDetailsContainer>
        </Collapse>
      )}
    </Alert>
  );
}

const ErrorDetailsContainer = styled(Paper)`
  margin-top: 1em;
  overflow-x: scroll;
  font-size: small;
  word-wrap: none;
  padding: 1em;
  ${({ theme }) => `
  background-color: ${theme.palette.error.light};
  color: ${theme.palette.error.contrastText};
  font-weight: normal;
  `}
`;

function ErrorStackTrace({ error }: { error: Error }): ReactElement {
  return (
    <Stack>
      {(error.stack?.split("\n") ?? []).map((line, i) => (
        <Typography key={i}>{line}</Typography>
      ))}
    </Stack>
  );
}

function DescribeError({ error }: { error: unknown }): ReactElement {
  const elems: ReactElement[] = [];
  let idx = 0;
  if (_.isError(error)) {
    elems.push(<ErrorStackTrace key={idx++} error={error} />);
    if (isApolloError(error)) {
      elems.push(...error.graphQLErrors.map(e => <ErrorStackTrace key={idx++} error={e} />));
      if (error.networkError) {
        elems.push(<ErrorStackTrace key={idx++} error={error.networkError} />);
      }
      elems.push(...error.clientErrors.map(e => <ErrorStackTrace key={idx++} error={e} />));
    }
  } else if (_.isString(error)) {
    elems.push(<Typography key={idx++}>{error}</Typography>);
  } else {
    elems.push(<pre key={idx++}>{JSON.stringify(error)}</pre>);
  }
  return <>{elems}</>;
}

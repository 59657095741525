import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import type { IAccountModel, IDebtModel, IDebtOutBalanceModel, IPersonOutModel } from "~/gql/types";
import { formatDate, formatTimestamp, isValidDate, renderDate } from "../../../utils/formats";
import { DataRow } from "../../widgets/DataRow";
import { MonetaryValue } from "../widgets/MonetaryValue";
import { chain } from "lodash";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";
import _ from "lodash";
import { getLastChargeDate } from "./helpers";

export interface IAccountInformationProps {
  account: IAccountModel;
  debt: IDebtModel;
  personOut: IPersonOutModel;
  debtOutBalance: IDebtOutBalanceModel;
}

export function AccountInformation({
  account,
  debt,
  personOut,
  debtOutBalance
}: IAccountInformationProps): React.ReactElement {
  const accountOpenedDate = formatTimestamp(account.account_open_timestamp);
  const lastChargeDate = getLastChargeDate(debtOutBalance);
  const expectedReturnDate = formatTimestamp(account.expected_return_date);
  const created = formatTimestamp(debt.meta?.time_created);
  const defaultDate = formatTimestamp(debtOutBalance._include_debt?.default_timestamp);

  const customerRefence = chain(personOut.additional_information?.references)
    .compact()
    .filter(ref => ref.creditor_id === debt?.creditor?.id)
    .value()[0];

  let customerReferenceTruncated;

  if (!_.isNil(customerRefence?.reference)) {
    customerReferenceTruncated = _.truncate(customerRefence?.reference, {
      length: 30,
      omission: "…"
    });
  }

  const information = [
    {
      label: "TA Number:",
      value: debt.account_number
    },
    {
      label: "Created:",
      value: created
    },
    {
      label: "Account Opened:",
      value: accountOpenedDate
    },
    {
      label: "Expected Return Date:",
      value: expectedReturnDate
    },
    {
      label: "Defaulted On:",
      value: defaultDate
    },
    {
      label: "Transaction ID: ",
      value: debt.transaction_id ?? "None"
    },
    {
      label: "Transaction Timestamp: ",
      value: formatTimestamp(debt.transaction_timestamp) ?? "None"
    },
    {
      label: "Customer Reference:",
      value: customerRefence?.reference ? (
        customerReferenceTruncated && customerReferenceTruncated?.length >= 30 ? (
          <OverflowTooltip title={customerRefence?.reference}>
            <Typography variant="body1">{customerReferenceTruncated}</Typography>
          </OverflowTooltip>
        ) : (
          <Typography variant="body1">{customerRefence?.reference}</Typography>
        )
      ) : (
        <Typography variant="body1">None</Typography>
      )
    },
    {
      label: "Original Creditor Acct #: ",
      value: debt.original_creditor_account_number ?? "None"
    },
    {
      label: "Last Charge Date:",
      value: lastChargeDate
    },
    {
      label: "Last Payment Amount:",
      value: (
        <MonetaryValue
          variant="body1"
          monetaryAmount={debtOutBalance.recent_transaction_info?.last_payment_amount}
        />
      )
    },
    {
      label: "Product:",
      value: debt.product ?? "None"
    }
  ];

  return (
    <Stack direction="column" spacing={2}>
      {information.map((item, index) => (
        <DataRow
          key={index}
          inline
          title={item.label}
          titleTypography="body2"
          value={<Typography variant="body1">{item.value}</Typography>}
        />
      ))}
    </Stack>
  );
}

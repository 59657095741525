import { useApolloClient } from "@apollo/client";
import { SecureRoute } from "@okta/okta-react";
import type { ReactElement } from "react";
import * as React from "react";
import { CenteredLayout } from "~/core/components/layouts/CenteredLayout";
import { ProgressIndicator } from "~/core/components/widgets/ProgressIndicator";
import { UserContext } from "~/core/contexts/UserContext";
import { SearchPage } from "../pages/accounts/SearchPage";
import ShowAccount from "../pages/accounts/ShowAccount";
import { ListTasks } from "../pages/tasks/ListTasks";
import { ShowTaskByBusinessKey } from "../pages/tasks/ShowTaskByBusinessKey";
import { ShowTaskById } from "../pages/tasks/ShowTaskById";
import Authenticate from "../pages/accounts/Authenticate";

export function ProtectedLanding(): ReactElement {
  const { isAuthenticated } = React.useContext(UserContext);
  const apolloClient = useApolloClient();
  if (!isAuthenticated) {
    return (
      <CenteredLayout>
        <ProgressIndicator message={`Fetching user profile...`} width={"40%"} />
      </CenteredLayout>
    );
  }
  if (apolloClient.disableNetworkFetches) {
    return (
      <CenteredLayout>
        <ProgressIndicator message={`Waiting for subspace communications network to respond`} width={"60%"} />
      </CenteredLayout>
    );
  }
  return (
    <>
      <SecureRoute path="/app" exact component={ListTasks} />
      <SecureRoute path="/app/tasks" exact component={ListTasks} />
      <SecureRoute path="/app/tasks/:taskId" exact component={ShowTaskById} />
      <SecureRoute path="/app/accounts/:accountId" exact component={ShowAccount} />
      <SecureRoute path="/app/accounts/search/advance" exact component={SearchPage} />
      <SecureRoute path="/app/accounts/authenticate/:accountId" exact component={Authenticate} />
      <SecureRoute path="/app/tasks/byBusinessKey/:businessKey" exact component={ShowTaskByBusinessKey} />
    </>
  );
}

import type { Theme } from "@mui/material";
import { Card } from "@mui/material";
import { CardContent } from "@mui/material";
import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import type { ReactNode } from "react";
import * as React from "react";
import type { ITheme } from "react-doc-viewer";

interface FileRef {
  fileName: string;
  downloadUrl: string;
  contentType: string | undefined;
}

type AnalyzedFileRef = Required<FileRef> & { isSupported: boolean };

interface IProps {
  file: FileRef;
}

const FilePreviewBlock = React.lazy(async () => {
  const {
    default: DocViewer,
    BMPRenderer,
    HTMLRenderer,
    JPGRenderer,
    MSDocRenderer,
    MSGRenderer,
    PDFRenderer,
    PNGRenderer,
    TIFFRenderer,
    TXTRenderer
  } = await import("react-doc-viewer");
  const FilePreviewBlockSync: React.FC<IProps> = ({ file }) => {
    const analyzedFile = analyzeFile(file);
    const theme = useTheme();
    let inner: ReactNode;
    if (analyzedFile.isSupported) {
      inner = (
        <DocViewer
          key={analyzedFile.downloadUrl}
          theme={docViewerPaletteFromMui(theme)}
          config={{ header: { disableHeader: true } }}
          pluginRenderers={[
            BMPRenderer,
            HTMLRenderer,
            JPGRenderer,
            MSDocRenderer,
            MSGRenderer,
            PDFRenderer,
            PNGRenderer,
            TIFFRenderer,
            TXTRenderer
          ]}
          documents={[
            {
              uri: analyzedFile.downloadUrl,
              fileType: analyzedFile.contentType
            }
          ]}
        />
      );
    } else {
      inner = (
        <Typography variant={"body2"}>
          Preview for the file type {analyzedFile.contentType} is not available.
        </Typography>
      );
    }

    return (
      <Card variant={"outlined"}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {file.fileName}
          </Typography>
          {inner}
        </CardContent>
      </Card>
    );
  };

  return { default: FilePreviewBlockSync };
});

export default FilePreviewBlock;

function docViewerPaletteFromMui(muiTheme: Theme): ITheme {
  return {
    primary: muiTheme.palette.background.default,
    secondary: muiTheme.palette.secondary.light,
    tertiary: "white",
    text_primary: muiTheme.palette.text.primary,
    text_secondary: muiTheme.palette.text.secondary,
    disableThemeScrollbar: true
  };
}

const supportedFileTypes = new Set([
  "application/pdf",
  "text/htm",
  "text/html",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-outlook",
  "text/plain"
]);

function canPreviewFile(contentType: string): boolean {
  if (contentType.startsWith("image/")) {
    return true;
  }
  return supportedFileTypes.has(contentType);
}

function analyzeFile(file: FileRef): AnalyzedFileRef {
  let contentType = file.contentType;
  if (file.fileName.endsWith(".csv")) {
    contentType = "text/plain";
  } else {
    contentType ??= "application/octet-stream";
  }
  return {
    ...file,
    contentType,
    isSupported: canPreviewFile(contentType)
  };
}

import { Typography } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import type { IMonetaryAmount, Maybe } from "~/gql/types";

export interface IMonetaryValueProps {
  monetaryAmount?: Maybe<IMonetaryAmount>;
  value?: number;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | undefined;
  isWithWhiteSpace?: boolean;
}

export function MonetaryValue({
  value,
  variant,
  monetaryAmount,
  isWithWhiteSpace
}: IMonetaryValueProps): ReactElement {
  const { amount, currency } = monetaryAmount ?? {
    amount: value ?? 0,
    currency: "USD"
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ?? "USD"
  });
  return (
    <Typography variant={variant ? variant : "subtitle2"}>
      {isWithWhiteSpace
        ? formatter.format((amount ?? 0) / 100).replace("$", `$ \u00A0\u00A0`)
        : formatter.format((amount ?? 0) / 100)}
    </Typography>
  );
}

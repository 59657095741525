import { formatDistanceToNow } from "date-fns";
import type { ReactElement } from "react";
import * as React from "react";
import type { Maybe } from "~/gql/types";
import { renderTime } from "../../utils/formats";
import { OverflowTooltip } from "./OverflowTooltip";

export function TimeDisplay({ date }: { date: Maybe<Date> }): ReactElement {
  if (!date) {
    return <span>None</span>;
  } else {
    return (
      <OverflowTooltip title={renderTime(date)} value={formatDistanceToNow(date, { addSuffix: true })} />
    );
  }
}

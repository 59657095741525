import { ClickAwayListener } from "@mui/base";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React from "react";

interface IChooser {
  field: string;
  selectedOptions: any;
  isDisabled: boolean;
  index: number;
  toggleDisable: (field: string, index: number) => void;
  valueField: string;
  valueFormControl: string;
  label: string;
  handleChange: () => void;
}

export function RadioChooserStepper({
  field,
  selectedOptions,
  isDisabled,
  index,
  toggleDisable,
  valueField,
  valueFormControl,
  label,
  handleChange
}: IChooser): React.ReactElement {
  return (
    <Grid item xs={9}>
      <FormControl fullWidth>
        <RadioGroup name="radio-buttons-group">
          <FormControlLabel
            value={valueFormControl}
            control={
              <Radio
                checked={selectedOptions[field]?.isCheck}
                onChange={() => {
                  if (selectedOptions?.[field]?.isCheck) selectedOptions[field].isCheck = true;
                }}
                onClick={() => {
                  toggleDisable(field, index);
                  handleChange();
                }}
                disabled={isDisabled}
              />
            }
            sx={{
              marginTop: "25px",
              ".MuiTypography-root": {
                width: "100%"
              },
              ".MuiFormControl-root": {
                width: "100%",
                fieldset: {
                  border: "none"
                },
                label: {
                  top: "7px"
                }
              }
            }}
            label={
              <FormGroup>
                <TextField
                  InputProps={{
                    readOnly: true
                  }}
                  disabled={isDisabled}
                  onClick={() => {
                    toggleDisable(field, index);
                    handleChange();
                  }}
                  key={1}
                  label={label}
                  value={valueField}
                />
              </FormGroup>
            }
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

interface IChooserAddress {
  field: string;
  selectedOptions: any;
  isDisabled: boolean;
  toggleDisable: (field: string, index: number, isRemove?: boolean) => void;
  index: number;
  addressFiltered: any;
  addressType: string;
  isCurrentAddressDisabled: boolean;
  handleChangeCurrentAddress: (event: any) => void;
  addressLengthOneValue: string;
  valueFormControl: string;
  revertLabel: () => void;
  indexToDelete: number;
}

export function RadioChooserStepperAddress({
  field,
  selectedOptions,
  isDisabled,
  valueFormControl,
  toggleDisable,
  index,
  addressFiltered,
  addressType,
  addressLengthOneValue,
  handleChangeCurrentAddress,
  isCurrentAddressDisabled,
  revertLabel,
  indexToDelete
}: IChooserAddress): React.ReactElement {
  const [currentAddress, setAddress] = React.useState("");
  const isCheck = selectedOptions[field]?.isCheck;
  React.useEffect(() => {
    if (!isCheck && indexToDelete === index) {
      setAddress("");
    }
  }, [indexToDelete, index, isCheck]);

  const text = valueFormControl === "currentAddress" ? "Choose Current Address" : "Choose Previous Address";
  const [open, setOpen] = React.useState(false);
  return (
    <Grid item xs={9}>
      <FormControl fullWidth>
        <RadioGroup name="radio-buttons-group">
          <FormControlLabel
            value={valueFormControl}
            control={
              <Radio
                checked={selectedOptions[field]?.isCheck}
                onChange={() => {
                  if (addressFiltered?.length === 1) {
                    selectedOptions[field].isCheck = true;
                  } else {
                    if (selectedOptions?.[field]?.isCheck && currentAddress !== "") {
                      selectedOptions[field].isCheck = true;
                    } else {
                      selectedOptions[field].isCheck = false;
                    }
                  }
                }}
                onClick={() => {
                  setOpen(!open);
                  if (addressFiltered?.length === 1) {
                    revertLabel();
                  }
                  toggleDisable(field, index);
                }}
                disabled={isDisabled}
              />
            }
            sx={{
              marginTop: "25px",
              ".MuiTypography-root": {
                width: "100%"
              },
              ".MuiFormControl-root": {
                width: "100%",
                fieldset: {
                  border: addressFiltered.length === 1 ? "none" : "1px solid"
                },
                label: {
                  top: addressFiltered.length === 1 ? "7px" : "0px"
                }
              },
              ".MuiInputLabel-root": {
                display: currentAddress === "" && addressFiltered.length > 1 ? "none" : "block"
              }
            }}
            label={
              <FormGroup>
                {addressFiltered && addressFiltered.length > 1 ? (
                  <FormControl>
                    <InputLabel id="demo-multiple-chip-label">{addressType}</InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          sx={{
                            ".MuiSvgIcon-root": {
                              fontSize: "3rem !important"
                            }
                          }}
                          id="select-multiple-chip-address"
                          label={addressType}
                        />
                      }
                      value={currentAddress}
                      disabled={isCurrentAddressDisabled}
                      fullWidth
                      onClick={e => {
                        e.preventDefault();

                        const isDisabled =
                          selectedOptions?.[field] !== undefined &&
                          selectedOptions[field]?.index !== index &&
                          selectedOptions[field]?.isCheck;

                        if (isDisabled) {
                          return;
                        }

                        setOpen(!open);
                        if (currentAddress === "") {
                          return;
                        }
                        toggleDisable(field, index);
                      }}
                      open={open}
                      displayEmpty
                      renderValue={currentAddress !== "" ? undefined : () => text}
                      onChange={e => {
                        toggleDisable(field, index);
                        handleChangeCurrentAddress(e);
                        setAddress(e.target.value);
                      }}>
                      {addressFiltered.map((item: any, index: number) => {
                        return (
                          <MenuItem key={index} value={index + 1}>
                            {item?.street_line1}
                            <br />
                            {item?.city},{item?.state} {item?.zipcode}
                            <br />
                            {item?.country_code}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    {addressFiltered?.length === 1 && (
                      <TextField
                        InputProps={{
                          readOnly: true
                        }}
                        label={addressType}
                        onClick={() => {
                          toggleDisable(field, index);
                        }}
                        value={addressLengthOneValue}
                      />
                    )}
                  </>
                )}
              </FormGroup>
            }
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

interface IChooserEmailPhone {
  field: string;
  selectedOptions: any;
  isDisabled: boolean;
  toggleDisable: (field: string, index: number) => void;
  index: number;
  dataArray: any;
  type: string;
  handleChange: (event: any) => void;
  valueLengthOne: string;
  valueFormControl: string;
  isPhone: boolean;
  revertLabel: () => void;
  indexToDelete: number;
}

export function RadioChooserEmailPhone({
  field,
  selectedOptions,
  isDisabled,
  valueFormControl,
  toggleDisable,
  index,
  dataArray,
  type,
  valueLengthOne,
  handleChange,
  isPhone,
  revertLabel,
  indexToDelete
}: IChooserEmailPhone): React.ReactElement {
  const text = isPhone ? "Choose Phone Number" : "Choose Email Address";
  const [open, setOpen] = React.useState(false);

  const [selectValue, setSelectValue] = React.useState("");
  const isCheck = selectedOptions[field]?.isCheck;
  React.useEffect(() => {
    if (!isCheck && indexToDelete === index) {
      setSelectValue("");
    }
  }, [indexToDelete, index, isCheck]);

  return (
    <Grid item xs={9}>
      <FormControl fullWidth>
        <RadioGroup name="radio-buttons-group">
          <FormControlLabel
            value={valueFormControl}
            control={
              <Radio
                checked={selectedOptions[field]?.isCheck}
                onChange={() => {
                  if (dataArray.length === 1) {
                    selectedOptions[field].isCheck = true;
                  } else {
                    if (selectedOptions?.[field]?.isCheck && selectValue !== "") {
                      selectedOptions[field].isCheck = true;
                    } else {
                      selectedOptions[field].isCheck = false;
                    }
                  }
                }}
                onClick={() => {
                  setOpen(!open);
                  if (dataArray.length === 1) {
                    revertLabel();
                  }
                  toggleDisable(field, index);
                }}
                disabled={isDisabled}
              />
            }
            sx={{
              marginTop: "25px",
              ".MuiTypography-root": {
                width: "100%"
              },
              ".MuiFormControl-root": {
                width: "100%",
                fieldset: {
                  border: dataArray.length === 1 ? "none" : "1px solid"
                },
                label: {
                  top: dataArray.length === 1 ? "7px" : "0px"
                }
              },
              ".MuiInputLabel-root": {
                display: selectValue === "" && dataArray.length > 1 ? "none" : "block"
              }
            }}
            label={
              <FormGroup>
                {dataArray && dataArray.length > 1 ? (
                  <FormControl>
                    <InputLabel id="demo-multiple-chip-label">{type}</InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          sx={{
                            ".MuiSvgIcon-root": {
                              fontSize: "3rem !important"
                            }
                          }}
                          id="select-multiple-chip"
                          label={type}
                        />
                      }
                      onClick={e => {
                        e.preventDefault();

                        const isDisabled =
                          selectedOptions?.[field] !== undefined &&
                          selectedOptions[field]?.index !== index &&
                          selectedOptions[field]?.isCheck;

                        if (isDisabled) {
                          return;
                        }

                        setOpen(!open);
                        if (selectValue === "") {
                          return;
                        }
                        toggleDisable(field, index);
                      }}
                      disabled={isDisabled}
                      value={selectValue}
                      fullWidth
                      displayEmpty
                      open={open}
                      renderValue={selectValue !== "" ? undefined : () => text}
                      onChange={e => {
                        toggleDisable(field, index);
                        handleChange(e);
                        setSelectValue(e.target.value);
                      }}>
                      {dataArray.map((item: any, index: number) => {
                        return (
                          <MenuItem key={index} value={index + 1}>
                            {isPhone ? item?.phone_number : item?.email}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    InputProps={{
                      readOnly: true
                    }}
                    onClick={() => {
                      toggleDisable(field, index);
                    }}
                    fullWidth
                    disabled={isDisabled}
                    label={type}
                    value={valueLengthOne}
                  />
                )}
              </FormGroup>
            }
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import * as React from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Box, Typography } from "@mui/material";
import type { IAccountModel, IDebtOutBalanceModel, IPaymentPlanOutModel } from "~/gql/types";
import { gql } from "@apollo/client";
import { formatDate, formatTimestamp, isValidDate } from "~/core/utils/formats";
import { DataRow } from "../../widgets/DataRow";
import { Block } from "../blocks/Block";
import { MonetaryValue } from "../widgets/MonetaryValue";
import { isNil } from "lodash";
import { useTheme } from "@mui/material/styles";

interface IPlanDetails {
  account: IAccountModel;
  debtOutBalance: IDebtOutBalanceModel;
  setViewPlan: (value: boolean) => void;
  paymentPlanOut: IPaymentPlanOutModel | undefined | null;
}

export const PaymentPlanOutQuery = gql(`
query paymentPlanOutQuery($planId: ID!) {
  paymentPlanOut: PaymentPlanOut(id: $planId) {
    id
    payment_method_info {
      card_info {
        card_type
        expiration_year
        expiration_month
        last_four_digits
      }
    }
    is_recurring
    next_installment
    installments {
      installment_amount {
        amount
        currency
      }
      due_date
      status
      amount_due {
       amount
       currency
      }
    }
    application {
      discount {
        amount
        currency
      }
      amount_to_pay {
       amount
       currency
      }
    }
  }
}
`);

export function PlanDetails({
  account,
  debtOutBalance,
  setViewPlan,
  paymentPlanOut
}: IPlanDetails): React.ReactElement {
  const theme = useTheme();
  let due_date;
  const next_installment = paymentPlanOut?.next_installment;
  if (!isNil(next_installment)) {
    due_date = paymentPlanOut?.installments?.[next_installment]?.due_date;
  }
  const paymentStatus = !isNil(next_installment) && paymentPlanOut?.installments[next_installment]?.status;

  let isDiscount: boolean;
  if (paymentPlanOut?.application?.discount?.amount) {
    isDiscount = paymentPlanOut?.application?.discount?.amount > 0;
  } else {
    isDiscount = false;
  }
  const debtStateOrderByLastModified = _.orderBy(
    debtOutBalance.debt_states ?? [],
    state => state?.meta?.last_modified
  ).reverse();
  const partPaymentIndex = debtStateOrderByLastModified?.findIndex(
    item => item?.part_payment !== null && item?.part_payment?.is_settlement
  );
  let partPayment;

  if (partPaymentIndex !== -1) {
    partPayment = debtOutBalance.debt_states?.[partPaymentIndex ?? 0]?.part_payment;
  }

  const settlementOffer = account.calculated_payment_offers?.available_settlement_offer;

  const day = partPayment?.is_settlement ? partPayment?.due_date?.day : settlementOffer?.expiration_date?.day;
  const month = partPayment?.is_settlement
    ? partPayment?.due_date?.month
    : settlementOffer?.expiration_date?.month;
  const year = partPayment?.is_settlement
    ? partPayment?.due_date?.year
    : settlementOffer?.expiration_date?.year;

  let dateFormatted = "No entry";
  let date;
  if (day && month && year) {
    date = new Date(`${year}/${month}/${day}`);

    if (isValidDate(date)) {
      dateFormatted = formatDate(date, "MMMM d, yyyy");
    }
  }

  const isOfferExpired = date && date > new Date();

  let title = partPaymentIndex !== -1 ? "One Time Settlement Offer" : "Offer details";

  const debt = account.debt;

  if (debt?.status !== "PendingApproval" && debt?.status !== "New" && paymentPlanOut) {
    title = "Plan Details";
  }
  return (
    <Block
      titleTypography="h2"
      noBottomPadding={true}
      removePaddingTop={true}
      isCard
      paddingTitle={"16px 16px 0px 16px"}
      embedded={paymentPlanOut === null}
      title={paymentPlanOut === null && partPaymentIndex === -1 ? "" : title}>
      {paymentPlanOut !== null && (debt?.status == "PendingApproval" || debt?.status == "New") ? (
        <>
          {settlementOffer && !isOfferExpired && partPaymentIndex === -1 && (
            <>
              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Settlement Offer:"}
                  value={
                    <>
                      {!isOfferExpired ? (
                        <Stack direction={"row"} sx={{ fontSize: theme.typography.body1 }} variant="text">
                          <Stack direction={"row"} color={theme.palette.common.black}>
                            {settlementOffer?.settlement_percent}%<Box paddingX={0.5}>or</Box>
                            <Stack position={"relative"} top={"-1px"}>
                              <MonetaryValue variant="body1" monetaryAmount={settlementOffer?.amount} />
                            </Stack>
                          </Stack>
                        </Stack>
                      ) : (
                        "None"
                      )}
                    </>
                  }
                />
              </Stack>

              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Installment Plan:"}
                  value={
                    <>
                      {account.calculated_payment_offers?.highlighted_plan ? (
                        <Typography>
                          {account.calculated_payment_offers?.highlighted_plan?.application?.payment_amount}{" "}
                          over{" "}
                          {
                            account.calculated_payment_offers?.highlighted_plan?.application
                              ?.number_of_installments
                          }{" "}
                          max of {account.calculated_payment_offers?.highlighted_plan?.application?.frequency}
                        </Typography>
                      ) : (
                        "None"
                      )}
                    </>
                  }
                />
              </Stack>
              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Expiration Date:"}
                  value={<Typography>{!isOfferExpired ? dateFormatted : "N/A"}</Typography>}
                />
              </Stack>
            </>
          )}

          {settlementOffer && isOfferExpired && partPaymentIndex === -1 && (
            <>
              <Stack mb={1.5}>
                <DataRow inline title={"Payment Details:"} value={"** Coming Soon **"} />
              </Stack>
              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Expiration Date:"}
                  value={<Typography>{!isOfferExpired ? dateFormatted : "N/A"}</Typography>}
                />
              </Stack>
            </>
          )}
        </>
      ) : (
        <>
          {partPaymentIndex !== -1 && (
            <>
              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Payment Details:"}
                  value={
                    // <Button variant="text" sx={{ textTransform: "none", fontSize: "1em" }}>
                    //   View Settlement
                    // </Button>
                    "** Coming Soon **"
                  }
                />
              </Stack>
              <Stack mb={1.5}>
                <DataRow inline title={"Due Date:"} value={<Typography>{dateFormatted}</Typography>} />
              </Stack>
              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Amount to Pay:"}
                  value={
                    <MonetaryValue
                      variant={"body1"}
                      monetaryAmount={{
                        amount: account.calculated_payment_offers?.settlement_constraints?.min?.amount?.amount
                      }}
                    />
                  }
                />
              </Stack>
            </>
          )}
          {paymentPlanOut === null && partPaymentIndex === -1 && (
            <Typography textAlign={"center"} position={"relative"} top={"-15px"} variant="h4">
              No Plan Details
            </Typography>
          )}
          {paymentPlanOut && debt?.status !== "PendingApproval" && debt?.status !== "New" && (
            <>
              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Plan Details:"}
                  value={
                    <Button
                      sx={{ textTransform: "none", fontSize: "1em" }}
                      onClick={() => setViewPlan(true)}
                      variant="text">
                      View Plan
                    </Button>
                  }
                />
              </Stack>
              <>
                {paymentStatus === "UNPAID_OVER_DUE" ||
                  (paymentStatus === "UNPAID_LATE" && (
                    <Stack mb={1.5}>
                      <Stack direction={"row"} justifyContent={"flex-end"}>
                        <PriorityHighIcon color={"error"} />
                        <Typography>Missing Payment</Typography>
                      </Stack>
                    </Stack>
                  ))}
              </>
              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Plan Type:"}
                  value={isDiscount ? "Settlement in Full" : "Pay in Full"}
                />
              </Stack>
              <Stack mb={1.5}>
                <DataRow
                  inline
                  title={"Next Schedule Payment:"}
                  value={formatTimestamp(due_date, "MMMM d, yyyy")}
                />
              </Stack>

              <Stack mb={1.5}>
                <DataRow inline title={"Recurring"} value={paymentPlanOut?.is_recurring ? "Yes" : "No"} />
              </Stack>
            </>
          )}
        </>
      )}
    </Block>
  );
}

import { createBrowserHistory } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { withConfig } from "./config/context";
import { enableDatadogMonitoring } from "./core/datadog";
import reportWebVitals from "./core/reportWebVitals";
import { enableSentry } from "./core/sentry";

const history = createBrowserHistory();

// eslint-disable-next-line @typescript-eslint/require-await
void withConfig("/config/env.json", async config => {
  console.debug(`Running in environment ${config.appConfig.appEnv}`);
  if (config.appConfig.appEnv !== "dev") {
    enableSentry(config);
    enableDatadogMonitoring(config);
  }
  ReactDOM.render(<App history={history} config={config} />, document.getElementById("root"));

  if (config.soloConfig.enableWebVitals) {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(console.debug);
  }
});

import { Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import type { ReactNode } from "react";
import * as React from "react";

interface BlockHeadingProps {
  title: string;
  children?: ReactNode;
}

export function BlockHeading({ title, children }: BlockHeadingProps): React.ReactElement {
  const theme = useTheme();
  return (
    <Stack
      spacing={2}
      direction={"row"}
      sx={{
        marginTop: "1em",
        padding: "1ex",
        backgroundColor: theme.palette.grey[400],
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px"
      }}
      alignItems={"center"}>
      <Typography variant="h5">{title}</Typography>
      {children}
    </Stack>
  );
}

import { Grid } from "@mui/material";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import { Alerts } from "~/core/components/widgets/AlertContext";
import { Footer } from "./Footer";
import { TopNavBar } from "./TopNavBar";

interface IProps {
  children?: React.ReactNode;
  widePadding?: boolean;
}

export function WideLayout({ children, widePadding }: IProps): ReactElement {
  const theme = useTheme();
  const isAccountsUrl = window.location.href.includes("accounts");
  return (
    <Grid
      container
      direction={"column"}
      spacing={1}
      minHeight={"100vh"}
      sx={{ backgroundColor: isAccountsUrl ? theme.palette.grey[100] : theme.palette.common.white }}>
      <Grid item>
        <TopNavBar />
      </Grid>
      <Alerts />
      <Grid
        item
        mx={1}
        flex={2}
        sx={{
          paddingTop: isAccountsUrl ? 0 : 2,
          paddingLeft: isAccountsUrl ? 0 : "unset",
          marginRight: isAccountsUrl ? 0 : "unset"
        }}>
        <Stack p={widePadding ? 0 : isAccountsUrl ? 0 : 2}>{children}</Stack>
      </Grid>
      <Grid item>
        <Footer />
      </Grid>
    </Grid>
  );
}

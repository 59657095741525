import { Box } from "@mui/material";
import { Tooltip } from "@mui/material";
import type { ReactChild, ReactElement } from "react";
import * as React from "react";
import { isValidElement } from "react";
import type { TooltipValue } from "./StyledDataGrid";

interface Props {
  title: TooltipValue;
  overflow?: boolean;
  children?: ReactChild | null | undefined;
  value?: string;
  notWhiteSpace?: boolean;
}

export function OverflowTooltip({
  title,
  value,
  children,
  overflow = true,
  notWhiteSpace
}: Props): ReactElement {
  const inner = overflow ? (
    <Box textOverflow="ellipsis" overflow="hidden" whiteSpace={notWhiteSpace ? "unset" : "nowrap"}>
      {children ?? value}
    </Box>
  ) : isValidElement(children) ? (
    children
  ) : (
    <span>{children ?? value}</span>
  );
  return <Tooltip title={title}>{inner}</Tooltip>;
}

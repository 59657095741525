import type { StyledComponent } from "@emotion/styled";
import { Stack } from "@mui/material";
import type { Theme } from "@mui/material";
import { styled } from "@mui/material";
import { Typography } from "@mui/material";
import type { MUIStyledCommonProps } from "@mui/system/createStyled";
import { isNumber, isString } from "lodash";
import type { ReactElement, ReactNode } from "react";
import { CopyText } from "./CopyText";
import type { Variant } from "@mui/material/styles/createTypography";

type DataRowProps = {
  title?: string;
  symbolSafe?: boolean;
  inline?: boolean;
  justify?: string;
  titleTypography?: Variant;
} & (
  | { children: ReactNode; value?: undefined; clipboard?: undefined; truncate?: undefined }
  | { value: ReactNode; children?: undefined; clipboard?: undefined; truncate?: undefined }
  | { value: string; clipboard?: boolean; truncate?: number; children?: undefined }
);

export function DataRow({
  title,
  symbolSafe = false,
  inline = false,
  clipboard,
  truncate,
  value,
  children,
  justify,
  titleTypography
}: DataRowProps): ReactElement {
  let inner: ReactNode;
  if (value) {
    if (isString(value) || isNumber(value)) {
      const displayValue = symbolSafe ? <SafeValue>{value}</SafeValue> : <Typography>{value}</Typography>;
      inner = clipboard ? (
        <CopyText value={value.toString()} displayValue={displayValue} truncate={truncate} />
      ) : (
        displayValue
      );
    } else {
      inner = value;
    }
  } else {
    inner = children;
  }
  justify ??= inline ? "space-between" : undefined;
  return (
    <Stack
      direction={inline ? "row" : "column"}
      spacing={1}
      alignContent={inline ? "baseline" : undefined}
      alignItems={inline ? "baseline" : undefined}
      justifyContent={justify ?? undefined}
      justifyItems={justify ?? undefined}>
      {title && <Typography variant={titleTypography ? titleTypography : "h6"}>{title}</Typography>}
      {inner}
    </Stack>
  );
}
export const SafeValue: StyledComponent<MUIStyledCommonProps<Theme>> = styled(props => <pre {...props} />)`
  margin: 0;
  text-overflow: ellipsis;
  overflow-x: auto;
`;

import { Stack } from "@mui/material";
import { Typography } from "@mui/material";

import type { ReactElement } from "react";
import * as React from "react";
import { ClaimedTasksList } from "~/core/components/domain/tasks/list/ClaimedTasksList";
import { DisputeTasksList } from "~/core/components/domain/tasks/list/DisputeTasksList";
import { WideLayout } from "~/core/components/layouts/WideLayout";

function ListTasksLive(): ReactElement {
  return (
    <WideLayout>
      <Stack px={2} spacing={2}>
        <Typography variant={"h3"} mt={"1em"}>
          Debt Verification Dashboard
        </Typography>
        <ClaimedTasksList />
        <DisputeTasksList />
      </Stack>
    </WideLayout>
  );
}

export const ListTasks = React.memo(ListTasksLive);

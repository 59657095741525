import Box from "@mui/system/Box";
import type { Theme } from "@mui/system/createTheme";
import type { SxProps } from "@mui/system/styleFunctionSx";
import type { ReactElement } from "react";
import * as React from "react";
import ProsperLogoWhite from "~/core/assets/prosper-logo-white.svg";

export interface ILogoProps {
  size?: "giant" | "large" | "medium" | "small";
  sx?: SxProps<Theme>;
  customWidth?: string;
}

const dividerForSize = {
  small: 6,
  medium: 4,
  large: 2,
  giant: 1
};

export function Logo({ sx = {}, size = "medium", customWidth }: ILogoProps = {}): ReactElement {
  const divider = dividerForSize[size];
  const width = 1024 / divider;
  const height = 253 / divider;
  return (
    <Box sx={{ ...sx, width: customWidth ?? width, height }} display={"block"}>
      <img src={ProsperLogoWhite} width={customWidth ?? width} height={height} alt={"TrueAccord"} />
    </Box>
  );
}

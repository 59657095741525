import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import type { AlertType } from "material-ui-dropzone";
import { DropzoneDialog } from "material-ui-dropzone";
import pluralize from "pluralize";
import type { ReactElement } from "react";
import * as React from "react";
import { titleCase } from "~/core/components/utils/content";
import { useAlerts } from "~/core/components/widgets/AlertContext";
import { ConfirmationDialog, useConfirmationDialog } from "~/core/components/widgets/ConfirmationDialog";
import { useCrmApi } from "~/core/contexts/ApiContext";
import { formatBytes } from "~/core/utils/formats";
import type { ICreditorTaskModel, IFileRequestModel } from "~/gql/types";

interface Props {
  title: string;
  creditorTask: ICreditorTaskModel;
  fileRequest: IFileRequestModel;
  onUpload: (files: File[]) => void;
}

export function AddFileAction({ title, creditorTask, fileRequest, onUpload }: Props): ReactElement {
  const alerts = useAlerts();
  const crmApi = useCrmApi();
  const { confirmState, openConfirm, closeConfirm } = useConfirmationDialog();
  const [isFileUploadOpen, setIsFileUploadOpen] = React.useState(false);

  const uploadFiles = async (files: File[]): Promise<void> => {
    const creditorId = creditorTask.header?.creditor_id;
    if (!creditorId) {
      throw "Missing creditor_id in CreditorTask header.";
    }
    try {
      for (const file of files) {
        try {
          await crmApi.remoteFilesControllerUpload({
            files: [file],
            creditorId,
            creditorTaskId: creditorTask.id,
            fileRequestId: fileRequest.id
          });
          alerts.addAlert({
            message: `Successfully uploaded ${file.name} (${formatBytes(file.size)}).`,
            severity: "success"
          });
          onUpload(files);
        } catch (error) {
          console.error(error);
          console.debug({
            file,
            creditorId,
            creditorTask,
            fileRequest
          });
          alerts.addAlert({
            message: `Failed to upload ${file.name} (${formatBytes(file.size)}) due to an internal error.`,
            severity: "error"
          });
        }
      }
    } catch (error) {
      console.error(error);
      const message = `Failed to upload ${pluralize("file", files.length, true)} due to an internal error.`;
      alerts.addAlert({
        message,
        severity: "error"
      });
      throw error;
    }
  };

  return (
    <>
      <DropzoneDialog
        showPreviewsInDropzone={true}
        showFileNames={true}
        filesLimit={3}
        dialogTitle={titleCase(title)}
        cancelButtonText={"Cancel"}
        showAlerts={false}
        onAlert={(message: string, variant: AlertType) => {
          window.alert(`${titleCase(variant)}: ${message}`);
        }}
        submitButtonText={"Attach"}
        getFileLimitExceedMessage={(filesLimit: number): string => {
          return `You can only upload ${pluralize("file", filesLimit, true)} at a time.`;
        }}
        maxFileSize={25 * 1024 * 1024}
        open={isFileUploadOpen}
        onClose={() => setIsFileUploadOpen(false)}
        onSave={files => {
          setIsFileUploadOpen(false);
          openConfirm({
            title: `Are you sure you want to add ${pluralize("file", files.length, true)}?`,
            body: (
              <>
                Do you want to add these files to the {titleCase(fileRequest.title ?? "Unknown File Request")}{" "}
                documents?
              </>
            ),
            confirmLabel: "Yes, Add",
            onConfirm: async () => uploadFiles(files)
          });
        }}
        showPreviews={false}
      />
      <ConfirmationDialog state={confirmState} onClose={closeConfirm} />

      <IconButton
        size={"small"}
        disableFocusRipple
        color={"primary"}
        onClick={() => setIsFileUploadOpen(true)}>
        <AddCircleIcon fontSize={"small"} />
      </IconButton>
    </>
  );
}

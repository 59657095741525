import * as React from "react";
import Paper from "@mui/material/Paper";
import { useUser } from "~/core/contexts/UserContext";
import { Block } from "../blocks/Block";
import { useTheme } from "@mui/material/styles";
import DoubleArrowDown from "../../../../core/assets/chevron-double-down-regular.svg";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DoubleArrow from "../../../../core/assets/double-up-arrow_svg-black.svg";
import _ from "lodash";
import type { Variant } from "@mui/material/styles/createTypography";
import { useCrmApi } from "~/core/contexts/ApiContext";
import type { IAccountModel } from "~/gql/types";
export interface IAccountNotesAddCommentProps {
  closeAccountNotesAddComment: () => void;
  openModalComments: (state: boolean) => void;
  setOpenSnackBar: (state: boolean) => void;
  titleTypography?: Variant;
  width: number;
  account: IAccountModel;
  refetchComments: () => void;
  rightPositionAddNote: string | undefined;
  rightPositionNoteInProgress: string | undefined;
}

export function AccountNotesAddComment({
  closeAccountNotesAddComment,
  openModalComments,
  setOpenSnackBar,
  titleTypography,
  account,
  refetchComments,
  width,
  rightPositionAddNote,
  rightPositionNoteInProgress
}: IAccountNotesAddCommentProps): React.ReactElement {
  const theme = useTheme();
  const [isCollapsedAddComment, setCollapseAddComment] = React.useState(false);
  const [valueTextArea, setValueTextArea] = React.useState("");
  const crmApi = useCrmApi();
  const user = useUser();

  function getButtons(isCollapse?: boolean): React.ReactElement {
    return (
      <Stack direction="row" marginLeft={"auto !important"} alignItems="center" mt={"3px"}>
        <Box
          mr={0.5}
          sx={{ height: isCollapse ? "23px" : "unset", cursor: "pointer" }}
          onClick={() => setCollapseAddComment(!isCollapsedAddComment)}>
          <img
            src={isCollapse ? DoubleArrow : DoubleArrowDown}
            width={isCollapse ? "30px" : "24px"}
            height={isCollapse ? "22px" : "18px"}
            alt="Open Account Notes"
          />
        </Box>

        <Box
          mt={-0.25}
          sx={{ height: isCollapse ? "23px" : "unset", cursor: "pointer" }}
          onClick={e => {
            e.stopPropagation();
            if (valueTextArea.length === 0) {
              closeAccountNotesAddComment();
            } else {
              openModalComments(true);
            }
          }}>
          <CloseIcon />
        </Box>
      </Stack>
    );
  }

  const [isCheckThirdParty, setThirdParty] = React.useState(false);

  function thirdPartyClicked() {
    setThirdParty(!isCheckThirdParty);
  }

  const handleAddCommentSnackBar = async (): Promise<void> => {
    closeAccountNotesAddComment();
    setOpenSnackBar(true);
    await crmApi.commentsControllerAddComment({
      objectId: account?.debt?.id ?? "",
      comment: valueTextArea,
      userName: user.name
    });
    refetchComments();
  };
  const threeColumnLayoutBreakpoint = 1700;
  return (
    <>
      <>
        {isCollapsedAddComment ? (
          <Box
            sx={{
              position: "fixed",
              bottom: width >= threeColumnLayoutBreakpoint ? "80px" : "50px",
              width: width >= threeColumnLayoutBreakpoint ? "25em" : "16.25em",
              height: "0px",
              right: rightPositionNoteInProgress
            }}>
            <Box position="relative" top={width >= threeColumnLayoutBreakpoint ? "33px" : "13px"}>
              <Block
                paddingTitle={width >= threeColumnLayoutBreakpoint ? "6px 11px" : "0px 11px"}
                borderRadius="10px"
                isCard
                noBottomPadding
                embedded
                paddingTopHeader="1px"
                title={"Note in Progress"}
                titleTypography={titleTypography ? titleTypography : "h4"}
                customBackground={theme.palette.warning.dark}
                handleClickHeader={() => setCollapseAddComment(!isCollapsedAddComment)}
                titlePostfix={getButtons(true)}>
                <></>
              </Block>
            </Box>
          </Box>
        ) : (
          <Paper
            sx={{
              width: "27.6875em",
              height: titleTypography ? "20.8125em" : "21.8125em",
              position: "fixed",
              bottom: "30px",
              right: rightPositionAddNote,
              paddingBottom: 1.25
            }}>
            <Block
              paddingTopContent={"0px"}
              noBottomPadding
              titleTypography={titleTypography ? titleTypography : "h4"}
              isCard
              customBackground={theme.palette.grey[500]}
              title={"Add an Account Note"}
              titlePostfix={getButtons()}
              handleClickHeader={() => setCollapseAddComment(!isCollapsedAddComment)}>
              <TextareaAutosize
                autoFocus
                onChange={e => setValueTextArea(e.target.value)}
                value={valueTextArea}
                style={{ width: "26.5em", height: "11.25em", resize: "none" }}
              />
              <Stack direction="row" justifyContent="flex-end">
                <Stack direction="row" alignItems="center" pb={1.25}>
                  {/* Checkbox 3er party disabled until BE is ready */}
                  <Checkbox disabled onChange={thirdPartyClicked} size="medium" />
                  <Typography fontSize={"1em"} color={theme.palette.grey[900]}>
                    3rd Party
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="space-between" pb={1.25}>
                <FormControl component="fieldset">
                  <RadioGroup row name="row-radio-buttons-group">
                    <FormControlLabel
                      value="phoneCall"
                      control={<Radio size="small" disabled />}
                      label={
                        <Typography color={theme.palette.grey[900]} fontSize="0.75em">
                          PhoneCall
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="email"
                      control={<Radio size="small" disabled />}
                      label={
                        <Typography color={theme.palette.grey[900]} fontSize="0.75em">
                          Email
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                  sx={{ fontSize: "0.8em", backgroundColor: theme.palette.common.white, fontWeight: "600" }}
                  color="inherit"
                  onClick={async () => handleAddCommentSnackBar()}
                  disabled={valueTextArea.length <= 0}
                  variant="outlined">
                  Add Note
                </Button>
              </Stack>
            </Block>
          </Paper>
        )}
      </>
    </>
  );
}

import type { ReactElement } from "react";
import * as React from "react";
import { useParams } from "react-router-dom";
import { DisputeTaskViewQuery } from "~/core/components/domain/tasks/DisputeTaskView";
import { useSafeQuery } from "~/core/utils/apollo";
import { ShowTask } from "./ShowTask";

export interface ShowTaskByIdRouteParams {
  taskId: string;
}

export function ShowTaskById(): ReactElement {
  const { taskId } = useParams<ShowTaskByIdRouteParams>();

  const { loading, data } = useSafeQuery(DisputeTaskViewQuery, {
    variables: { taskId },
    pollInterval: 60_000
  });

  return <ShowTask isLoading={loading} data={data} />;
}

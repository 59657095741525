import * as React from "react";
import { useParams } from "react-router-dom";
import { AccountViewQuery } from "~/core/components/domain/accounts/AccountView";
import AccountView from "~/core/components/domain/accounts/AccountView";
import { WideLayout } from "~/core/components/layouts/WideLayout";
import { ProgressIndicator } from "~/core/components/widgets/ProgressIndicator";
import { useSafeQuery } from "~/core/utils/apollo";
export interface IShowAccountParams {
  accountId: string;
}

export default function ShowAccount(): React.ReactElement {
  const { accountId } = useParams<IShowAccountParams>();
  const { data, refetch } = useSafeQuery(AccountViewQuery, {
    variables: { accountId },
    pollInterval: 60_000
  });

  const [isRefetchQueries, setRefetchQueries] = React.useState(false);

  React.useEffect(() => {
    if (isRefetchQueries) {
      refetch().finally(() => setRefetchQueries(false));
    }
  }, [isRefetchQueries]);

  if (data?.account?.debt) {
    return (
      <WideLayout widePadding>
        <AccountView refetchAccountQuery={() => setRefetchQueries(true)} account={data.account} />
      </WideLayout>
    );
  }

  return (
    <WideLayout widePadding>
      <ProgressIndicator message={`Loading account information...`} />
    </WideLayout>
  );
}

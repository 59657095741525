import type { Reference } from "@apollo/client";
import { parseJSON } from "date-fns";
import { chain, isEmpty, isString, uniqBy } from "lodash";
import type {
  ICamunda_KeyValuePair, ICamunda_Task, IDisputeContextHelp, IFileRequestReviewModel
} from "~/gql/types";
import type { StrictTypedTypePolicies } from "../../gql/apollo-helpers";
import { defineReader, missingAsNull } from "../utils/policies";

type DocumentRequirements = Omit<IDisputeContextHelp, "__typename" | "documentExamples">;

export const CAMUNDA_TASK_POLICY: StrictTypedTypePolicies["camunda_Task"] = {
  fields: {
    variables: {
      keyArgs: false,
      merge(existing: ICamunda_KeyValuePair[] = [], incoming: ICamunda_KeyValuePair[]) {
        return uniqBy([...existing, ...incoming], "key");
      }
    },
    creditorTaskId: defineReader(({ readCamundaVar }) => readCamundaVar("creditorTaskId")),
    creditorTask: defineReader<ICamunda_Task, Reference>(({
                                                            readPrimitive,
                                                            readReference,
                                                            readReferencesFrom,
                                                            readPrimitiveFrom
                                                          }) => {
      const creditorTaskId = readPrimitive("creditorTaskId");
      const debtRef = readReference("debt");
      const creditorTasksRefs = readReferencesFrom(debtRef)("creditorTasks");
      return chain(creditorTasksRefs)
        .map(ct => {
          const thisTaskId = readPrimitiveFrom(ct)("id");
          return thisTaskId === creditorTaskId ? ct : undefined;
        })
        .compact()
        .head()
        .value();
    }),
    dueDate: defineReader<ICamunda_Task, Date>(({ readPrimitive }) => {
      const rawDue = readPrimitive("due");
      if (isString(rawDue) && !isEmpty(rawDue)) {
        return new Date(rawDue);
      } else {
        return null;
      }
    }),
    disputeType: defineReader<ICamunda_Task, string>(({ readCamundaVar }) => readCamundaVar("disputeType")),
    disputeContextHelp: defineReader<ICamunda_Task, IDisputeContextHelp>(({ readCamundaVar }) => {
      const rawJson = missingAsNull(() => readCamundaVar("documentRequirements"));
      const docReqs = rawJson ? (JSON.parse(rawJson) as DocumentRequirements) : null;
      return {
        __typename: "DisputeContextHelp",
        goal: null,
        matchRequirements: null,
        required: null, ...docReqs,
        stateRequirements: missingAsNull(() => readCamundaVar("stateRequirements")),
        documentExamples: missingAsNull(() => readCamundaVar("documentExamples"))
      };
    }),
    fileRequestsReviews: defineReader<ICamunda_Task, IFileRequestReviewModel[]>(({ readCamundaVar }) => {
      const rawJson = missingAsNull(() => readCamundaVar("fileRequests"));
      const parsed = JSON.parse(rawJson ?? "null") as RawFileRequestReview[] | null;
      return (parsed ?? []).map(review => ({
        __typename: "FileRequestReview", ...review,
        reviewTimestamp: review.reviewTimestamp ? parseJSON(review.reviewTimestamp) : null
      }));
    }),
    allowsInternationalAgents: defineReader<ICamunda_Task, boolean>(({ readCamundaVar }) => {
      const allowAccessFrom = missingAsNull(() => readCamundaVar("allowAccessFrom"));
      if (!allowAccessFrom) {
        // If allowAccessFrom is null, this is an older task that predates the allowAccessFrom variable.
        // In this case, we assume that the task is old enough that it predates the international agents
        // creditor settings, and so we return false just to be safe.
        return false;
      } else {
        return allowAccessFrom.includes("NEARSHORE") || allowAccessFrom.includes("OFFSHORE")
               || allowAccessFrom.includes("INTERNATIONAL_ENGINEER");
      }
      
    })
  }
};

type RawFileRequestReview =
  Omit<IFileRequestReviewModel, "reviewTimestamp">
  & {
  reviewTimestamp?: string | null;
};

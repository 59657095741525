import type { AlertColor } from "@mui/material";
import { Alert, AlertTitle, Grid, Stack } from "@mui/material";
import { filter, isEmpty, uniqBy, uniqueId } from "lodash";
import type { PropsWithChildren, ReactElement } from "react";
import { Component, createContext, useContext, useEffect } from "react";

interface AlertMessage {
  id: string;
  severity: AlertColor;
  message: string;
  description?: string;
}

const STORAGE_KEY = "ta-crm-alerts";

export const AlertContext = createContext<AlertsContextState>({
  alerts: [],
  addAlert: () => null,
  removeAlerts: () => null,
  removeAlert: () => null
});

type IProps = PropsWithChildren<Record<string, unknown>>;

export interface AlertsContextState {
  alerts: AlertMessage[];
  addAlert: (alert: Omit<AlertMessage, "id">) => void;
  removeAlerts: () => void;
  removeAlert: (alertId: string) => void;
}

function readFromStorage(): AlertsContextState["alerts"] {
  if (typeof localStorage === "undefined") {
    return [];
  }
  const maybeValue = localStorage.getItem(STORAGE_KEY);
  if (maybeValue) {
    return JSON.parse(maybeValue) as AlertsContextState["alerts"];
  } else {
    return [];
  }
}

function writeToStorage(alerts: AlertsContextState["alerts"]): void {
  if (typeof localStorage === "undefined") {
    return;
  }
  localStorage.setItem(STORAGE_KEY, JSON.stringify(alerts));
}

export class AlertsProvider extends Component<IProps, AlertsContextState> {
  addAlert: (alert: Omit<AlertMessage, "id">) => void;
  removeAlerts: () => void;
  removeAlert: (alertId: string) => void;

  constructor(props: IProps) {
    super(props);

    this.addAlert = (alert: Omit<AlertMessage, "id">) => {
      this.setState(state => {
        const alerts = uniqBy(
          [
            ...state.alerts,
            {
              ...alert,
              id: uniqueId("alert-")
            }
          ],
          a => a.message
        );
        const newState = {
          ...state,
          alerts
        };
        writeToStorage(newState.alerts);
        return newState;
      });
    };

    this.removeAlerts = () => {
      this.setState(state => {
        writeToStorage([]);
        return {
          ...state,
          alerts: []
        };
      });
    };

    this.removeAlert = (alertId: string) => {
      this.setState(state => {
        const newState = {
          ...state,
          alerts: filter(state.alerts, alert => alert.id !== alertId)
        };
        writeToStorage(newState.alerts);
        return newState;
      });
    };

    this.state = {
      alerts: readFromStorage(),
      addAlert: this.addAlert,
      removeAlerts: this.removeAlerts,
      removeAlert: this.removeAlert
    };
  }

  override render(): ReactElement {
    return <AlertContext.Provider value={this.state}>{this.props.children}</AlertContext.Provider>;
  }
}

export function Alerts(): ReactElement | null {
  const { alerts, removeAlert } = useContext(AlertContext);
  const inner = uniqBy(alerts, a => a.message).map((alert, idx) => {
    return <RenderAlert alert={alert} removeAlert={removeAlert} key={idx} />;
  });
  if (isEmpty(inner)) {
    return null;
  } else {
    return (
      <Grid item mx={8}>
        <Stack spacing={2}>{inner}</Stack>
      </Grid>
    );
  }
}

function RenderAlert({
  alert,
  removeAlert
}: {
  alert: AlertMessage;
  removeAlert: (alertId: string) => void;
}): ReactElement {
  const onClose = (): void => removeAlert(alert.id);

  useEffect(() => {
    if (alert.severity === "success") {
      console.debug(`Removing alert by timeout: ${JSON.stringify(alert)}`);
      setTimeout(onClose, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert.id]);
  return (
    <>
      <Alert severity={alert.severity} onClose={onClose}>
        {alert.description === undefined ? (
          alert.message
        ) : (
          <>
            <AlertTitle>{alert.message}</AlertTitle>
            {alert.description}
          </>
        )}
      </Alert>
    </>
  );
}

export function useAlerts(): AlertsContextState {
  return useContext(AlertContext);
}

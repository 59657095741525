import { datadogRum } from "@datadog/browser-rum";
import type { IConfig } from "../config/context";

export function enableDatadogMonitoring(config: IConfig): void {
  datadogRum.init({
    applicationId: "0fa67b9c-56d8-420b-9485-884068e3670a",
    clientToken: "pub63c9ce725e5f75c31423ad5c5b34cd3a",
    site: "datadoghq.com",
    service: "prosper",
    env: config.appConfig.appEnv,
    version: process.env.__VERSION__,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input"
  });

  datadogRum.startSessionReplayRecording();
}

import _ from "lodash";
import type { ReactElement } from "react";
import React from "react";

export function renderComponent(Component: React.ComponentType): ReactElement {
  return <Component />;
}

export function useDeepState<S>(initialState: S | (() => S)): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = React.useState<S>(initialState);
  const deepSetState: React.Dispatch<React.SetStateAction<S>> = (action: React.SetStateAction<S>) => {
    if (action instanceof Function) {
      setState((prevState: S) => {
        const newState = action(prevState);
        if (_.isEqual(newState, prevState)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return prevState;
        } else {
          return newState;
        }
      });
    } else {
      setState((prevState: S) => {
        const newState = action;
        if (_.isEqual(newState, prevState)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return prevState;
        } else {
          return newState;
        }
      });
    }
  };
  return [state, deepSetState];
}

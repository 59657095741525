import type {
  ApolloClient,
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult
} from "@apollo/client";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import type { Reference } from "@apollo/client/utilities";
import { getOperationName } from "@apollo/client/utilities";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useErrorHandler } from "react-error-boundary";

export function useSafeQuery<TData, TVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): Omit<QueryResult<TData, TVariables>, "error"> {
  const ret = useQuery(query, options);
  if (ret.error) {
    ret.error.extraInfo = { query, variables: options?.variables, pollInterval: options?.pollInterval };
  }
  useErrorHandler(ret.error);
  delete ret.error;
  return ret;
}

export function useSafeMutation<TData, TVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const client = useApolloClient();
  return useMutation(query, {
    onCompleted: async () => {
      console.log(`Refetching all observable queries on mutation ${getOperationName(query)}`);
      await client.reFetchObservableQueries(true);
    },
    ...options
  });
}

export function deleteFromCache(
  value: Reference | { __typename?: string },
  client: ApolloClient<object>
): void {
  client.cache.evict({ id: client.cache.identify(value) });
}

import type { OktaAuthOptions } from "@okta/okta-auth-js/lib/types";
import { envVarAsUrl } from "~/core/utils/env";
import type { IConfigEnv } from "./context";

export interface IOktaConfig {
  readonly devMode: boolean;
  readonly clientId: string;
  readonly issuer: string;
  readonly baseUrl: string;
  readonly logoutUri: string;
  readonly authorizeUri?: string;
  readonly requireHttps: boolean;
}

export function oktaConfig(env: IConfigEnv): Readonly<IOktaConfig> {
  return {
    devMode: env.get("REACT_APP_OKTA_DEV_MODE").default("false").asBool(),
    clientId: env.get("REACT_APP_OKTA_CLIENT_ID").required().asString(),
    issuer: envVarAsUrl(env.get("REACT_APP_OKTA_ISSUER").required()),
    baseUrl: envVarAsUrl(env.get("REACT_APP_OKTA_BASE_URI").required()),
    logoutUri: envVarAsUrl(env.get("REACT_APP_OKTA_LOGOUT_URI").required()),
    authorizeUri: envVarAsUrl(env.get("REACT_APP_OKTA_AUTHORIZE_URI")),
    requireHttps: env.get("REACT_APP_OKTA_REQUIRE_HTTPS").default("true").asBool()
  };
}

export interface IOktaGenericAuthOptions extends OktaAuthOptions {
  readonly issuer: string;
  readonly clientId: string;
  readonly devMode: boolean;
  disableHttpsCheck: boolean;
}

export function oktaGenericAuthOptions(env: IConfigEnv): Readonly<IOktaGenericAuthOptions> {
  return {
    pkce: true,
    scopes: ["openid", "profile", "email", "groups", "offline_access"],
    issuer: oktaConfig(env).issuer,
    clientId: oktaConfig(env).clientId,
    devMode: oktaConfig(env).devMode,
    authorizeUrl: oktaConfig(env).authorizeUri,
    disableHttpsCheck: !oktaConfig(env).requireHttps
  };
}

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SearchBar from "~/core/components/domain/accounts/SearchBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TablePagination from "@mui/material/TablePagination";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useCrmApi } from "~/core/contexts/ApiContext";
import React from "react";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import { MonetaryValue } from "~/core/components/domain/widgets/MonetaryValue";
import type { RootState } from "~/solo/store";
import { useSelector } from "react-redux";
import { TopNavBar } from "~/core/components/layouts/TopNavBar";
import _ from "lodash";
import { useHistory } from "react-router";

export interface IRows {
  key: number | string;
  item?: ISearchResultItem;
  isCollapse: { isCollapse: boolean; isCollapsePhone: boolean; indexSelected: number };
  id: string;
  name: string;
  email: Array<{ email: string; meta: { isPrimary: boolean } }>;
  phone: Array<{ meta: { isPrimary: boolean }; phoneNumber: string }>;
  addresses: {
    addressItem: Array<{
      city: string;
      countryCode: string;
      state: string;
      streetLine1: string;
      zipcode: string;
    }>;
    isCollapse: boolean;
  };
  status: [string];
  accountNumber: [string];
  transactionId: string;
  meta: {
    id: string;
  };
  creditor: [
    {
      companyName: string;
      originalBrand: {
        name: string;
      };
    }
  ];
  personId: string;
  balance: [
    {
      amount: number;
      currency: string;
    }
  ];
  debtId: [string];
  debtorId: [string];
  originalCreditor: [string];
  originalAccountNumber: [string];
  reference: [{ ref: string | undefined; txn: string | undefined }];
}
export interface ISearchResultItem {
  score: number;
  isCollapse: boolean;
  isCollapsePhone: boolean;
  index: number;
  overflow: string;
  height: string;
  person: {
    meta: {
      id: string;
    };
    name: { firstName: string; lastName: string };
    emails: Array<{ email: string; meta: { isPrimary: boolean } }>;
    phones: Array<{ meta: { isPrimary: boolean }; phoneNumber: string }>;
    addresses: Array<{
      city: string;
      countryCode: string;
      state: string;
      streetLine1: string;
      zipcode: string;
    }>;
    debts: Array<{
      status: string;
      accountNumber: string;
      transactionId: string;
      meta: {
        id: string;
      };
      creditor: { companyName: string; originalBrand: { name: string } };
      personId: string;
      balance: { amount: number; currency: string };
      debtId: [string];
      debtorId: [string];
      originalCreditor: [string];
      originalAccountNumber: [string];
    }>;
    reference: string;
    additionalInformation?: {
      references: [
        {
          creditorId: string;
          reference: string;
        }
      ];
    };
  };
}

export interface ISearchResults {
  results: Array<{
    isCollapse: boolean;
    isCollapsePhone: boolean;
    index: number;
    overflow: string;
    height: string;
    score: number;
    person: IPersonSearch;
  }>;
}

interface IDebt {
  status: string;
  accountNumber: string;
  transactionId: string;
  meta: {
    id: string;
  };
  creditorId?: string;
  creditor: {
    companyName: string;
    originalBrand: {
      name: string;
    };
  };
  personId: string;
  balance: {
    amount: number;
    currency: string;
  };
  originalCreditorAccountNumber?: string;
  debtId: [string];
  debtorId: [string];
  originalCreditor: [string];
  originalAccountNumber: [string];
}

interface IPersonSearch {
  meta: {
    id: string;
  };
  name: {
    firstName: string;
    lastName: string;
  };
  emails: Array<{
    email: string;
    meta: {
      isPrimary: boolean;
    };
  }>;
  phones: Array<{
    meta: {
      isPrimary: boolean;
    };
    phoneNumber: string;
  }>;
  addresses: Array<{
    city: string;
    countryCode: string;
    state: string;
    streetLine1: string;
    zipcode: string;
  }>;
  debts: IDebt[];
  reference: string;
  additionalInformation?: {
    references: [
      {
        creditorId: string;
        reference: string;
      }
    ];
  };
}

export function SearchPage() {
  const crmApi = useCrmApi();
  const theme = useTheme();
  const history = useHistory();
  const creditorIdSelected = useSelector((state: RootState) => state.creditor.value);
  const [shouldMoveResults, setMoveResults] = React.useState(true);
  const [searchTerms, setSearchTerms] = React.useState<string[]>([]);

  const [isSearchTermDeleted, searchTermDeleted] = React.useState(false);
  React.useEffect(() => {
    if (isSearchTermDeleted) {
      const searchResultsResult = searchResults?.results.map(item => {
        item.isCollapse = false;
        item.isCollapsePhone = false;
        return item;
      });
      setSearchResults({ results: searchResultsResult ?? [] });
      searchTermDeleted(false);
    }
  }, [isSearchTermDeleted]);

  const [isLoadingDataTable, setIsLoadingDataTable] = React.useState(false);

  const [searchResults, setSearchResults] = React.useState<ISearchResults | undefined>();

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const PER_PAGE = rowsPerPage;

  const count = Math.ceil(searchResults?.results.length ?? 0 / PER_PAGE);
  const _DATA = usePagination(searchResults, PER_PAGE);

  const handleChange = (e: any, p: number) => {
    setPage(p);
    if (page < p) {
      _DATA.next();
    } else {
      _DATA.prev();
    }
  };

  const [creditorId, setCreditorId] = React.useState<string | undefined>("");

  const columns = [
    {
      checked: true,
      label: "Name",
      isDisabled: true
    },
    {
      checked: true,
      label: "Email",
      isDisabled: true
    },
    {
      checked: true,
      label: "Phone Number",
      isDisabled: true
    },
    {
      checked: true,
      label: "Addresses",
      isDisabled: true
    },
    {
      checked: true,
      label: "Creditor",
      isDisabled: false
    },
    {
      checked: true,
      label: "Balance",
      isDisabled: false
    },
    {
      checked: true,
      label: "Reference",
      isDisabled: false
    },
    {
      checked: true,
      label: "Status"
    },
    {
      checked: true,
      label: "TA Number",
      isDisabled: false
    },
    {
      checked: false,
      label: "Debt ID",
      isDisabled: false
    },
    {
      checked: false,
      label: "Debtor ID",
      isDisabled: false
    },
    {
      checked: false,
      label: "Original Creditor",
      isDisabled: false
    },
    {
      checked: false,
      label: "Original Account Number",
      isDisabled: false
    }
  ];

  const [columnsData, setColumns] = React.useState(columns);

  const [isOpenFilterColumns, setOpenFilterColumns] = React.useState(false);

  function getHighlighted(field: string | undefined) {
    let index: number | undefined = -1;
    let endIndex = -1;
    searchTerms.map(item => {
      if (item.length < 3) return;
      let newItem: string | undefined = item;
      if (item.includes("Email") || item.includes("Reference") || item.includes("Creditor")) {
        newItem = item?.split(":").pop()?.trim();
      }
      if (item.includes("#")) {
        newItem = item.slice(3);
      }
      const lowerCaseItem = newItem?.toLowerCase();
      if (field?.toLowerCase().indexOf(lowerCaseItem ?? "") !== -1) {
        index = field?.toLowerCase().indexOf(lowerCaseItem ?? "");
        if (index !== undefined) endIndex = index + Number(lowerCaseItem?.length) - 1;
      }
      return lowerCaseItem;
    });

    if (index !== -1 && endIndex !== -1) {
      const values = [];
      if (field !== undefined) {
        for (let i = 0; i < field?.length; i++) {
          if (field?.[i] === " ") {
            values.push(<Box display={"inline-block"} ml={"4px"}></Box>);
          }
          if (i >= index && i <= endIndex) {
            values.push(
              <Stack
                display={"inline-block"}
                direction={"row"}
                sx={{ backgroundColor: "#FFEBA080", color: theme.palette.grey[300] }}>
                {field?.[i]}
              </Stack>
            );
          } else {
            values.push(
              <Stack display={"inline-block"} direction={"row"}>
                {field?.[i]}
              </Stack>
            );
          }
        }
      }

      return values;
    } else {
      return field;
    }
  }

  function getHighlightedPhone(field: string | undefined) {
    let formatedPhone: string | undefined;
    formatedPhone = field?.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "");
    if (formatedPhone !== undefined) formatedPhone = "+1" + formatedPhone;

    let endIndex = -1;
    let index: number | undefined = -1;
    searchTerms.map(item => {
      let newItem;
      if (item.includes("Phone:")) {
        newItem = item?.split(":").pop()?.replaceAll(" ", "") ?? "";
      } else {
        let itemFormated = item
          ?.replaceAll("-", "")
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll(" ", "");
        if (itemFormated !== undefined) itemFormated = "+1" + itemFormated;
        if (itemFormated === formatedPhone) {
          newItem = itemFormated;
        }
      }
      if (newItem !== undefined) {
        if (formatedPhone?.indexOf(newItem ?? "") !== -1) {
          index = formatedPhone?.indexOf(newItem ?? "");
          if (index !== undefined) endIndex = index + newItem.length - 1;
        }
        return newItem;
      } else {
        return null;
      }
    });
    if (index !== -1 && index !== undefined && endIndex !== -1) {
      const values = [];
      if (field !== undefined) {
        for (let i = 0; i < field?.length; i++) {
          if (i >= index && i <= endIndex) {
            values.push(
              <Stack
                display={"inline-block"}
                direction={"row"}
                sx={{ backgroundColor: "#FFEBA080", color: theme.palette.grey[300] }}>
                {field?.[i]}
              </Stack>
            );
          } else {
            values.push(
              <Stack display={"inline-block"} direction={"row"} color={"black"}>
                {field?.[i]}
              </Stack>
            );
          }
        }
      }

      return values;
    } else {
      return field;
    }
  }

  function handleChangeCheckBox(value: boolean, indexSelected: number) {
    const result = columnsData.map((item, index) => {
      if (index === indexSelected) {
        item.checked = !value;
        return item;
      } else {
        return item;
      }
    });

    setColumns(result);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function sendCreditorSelected(value: string | undefined): void {
    setCreditorId(value);
  }

  React.useEffect(() => {
    sendCreditorSelected(creditorIdSelected);
  }, [creditorIdSelected]);

  const rows = _DATA.currentData().map((item, index) => {
    const row: IRows = {
      isCollapse: { isCollapse: false, isCollapsePhone: false, indexSelected: 0 },
      id: "0",
      name: "",
      email: [],
      phone: [],
      addresses: {
        addressItem: [{ city: "", zipcode: "", state: "", streetLine1: "", countryCode: "" }],
        isCollapse: false
      },

      status: [""],
      accountNumber: [""],
      transactionId: "",
      meta: {
        id: ""
      },
      creditor: [
        {
          companyName: "",
          originalBrand: {
            name: ""
          }
        }
      ],
      personId: "",
      balance: [
        {
          amount: 0,
          currency: ""
        }
      ],
      debtId: [""],
      debtorId: [""],
      originalAccountNumber: [""],
      originalCreditor: [""],

      reference: [{ ref: "", txn: "" }],
      key: 0
    };

    row.key = index;
    row.item = item;
    row.isCollapse = {
      isCollapse: item.isCollapse,
      isCollapsePhone: item.isCollapsePhone,
      indexSelected: index,
      addressItem: item.person.addresses,
      phones: item.person.phones,
      email: item.person.emails
    };
    row.id = item.person.meta.id + String(index);
    row.name = `${item.person.name.firstName} ${item.person.name.lastName}`;

    item.person.emails.map((itemEmail, indexEmail) => {
      searchTerms.map(itemSearch => {
        const itemSearchClean = itemSearch?.split(":").pop()?.trim();
        if (itemEmail.email === itemSearchClean) {
          if (indexEmail === 0) {
            item.isCollapse = false;
          } else {
            item.isCollapse = true;
          }
        }
      });
    });

    row.email = item.person.emails;

    item.person.phones.map((itemPhoneCurrent, indexPhone) => {
      let formatedPhone: string | undefined;
      if (itemPhoneCurrent.phoneNumber?.includes("-")) {
        formatedPhone = itemPhoneCurrent.phoneNumber?.replaceAll("-", "");
      } else {
        formatedPhone = itemPhoneCurrent.phoneNumber;
      }
      if (formatedPhone !== undefined) formatedPhone = "+1" + formatedPhone;
      searchTerms.map(itemSearch => {
        let itemSearchClean = itemSearch?.split(":").pop()?.trim().replaceAll(" ", "");

        if (!itemSearchClean?.includes("+")) itemSearchClean = "+1" + String(itemSearchClean);

        if (formatedPhone === itemSearchClean) {
          if (indexPhone === 0) {
            item.isCollapsePhone = false;
          } else {
            item.isCollapsePhone = true;
          }
        }
      });
    });

    row.phone = item.person.phones;
    row.addresses = {
      addressItem: item.person.addresses,
      isCollapse: item.isCollapse
    };

    const debt = item.person.debts[0];
    const reference = _.find(item.person.additionalInformation?.references, function (reference) {
      return reference?.creditorId === debt?.creditorId;
    });
    item.person.debts.map(itemDebt => {
      row.creditor.push(itemDebt.creditor);
      row.accountNumber.push(itemDebt.accountNumber);
      row.originalCreditor.push(itemDebt.creditor.originalBrand.name);
      row.status.push(itemDebt.status);
      row.balance.push(itemDebt.balance);
      row.debtId.push(itemDebt.meta.id);
      row.debtorId.push(itemDebt.personId);
      row.originalAccountNumber.push(itemDebt.originalCreditorAccountNumber ?? "");
      row.reference.push({ ref: reference?.reference ?? undefined, txn: itemDebt.transactionId });
    });
    return row;
  });

  const columnsTable = [
    {
      field: "isCollapse",
      headerName: "",
      resizable: false,
      sortable: false,
      hide: !rows.some(item => {
        return item.addresses.addressItem.length > 1 || item.phone.length > 3 || item.email.length > 2;
      }),
      width: 10,
      renderCell: (params: {
        row: any;
        value: {
          isCollapse: boolean;
          isCollapsePhone: boolean;
          indexSelected: number;
          addressItem: [];
          phones: [];
          email: [];
        };
      }) => {
        const conditionToShowExpand =
          params.value.addressItem.length > 1 ||
          params.value.phones.length > 3 ||
          params.value.email.length > 2;

        return (
          <>
            {conditionToShowExpand ? (
              <Box
                onClick={() => {
                  if (params.value.isCollapse) {
                    params.value.isCollapse = false;
                    params.value.isCollapsePhone = false;
                    params.row.item.isCollapse = false;
                  } else {
                    params.value.isCollapse = true;
                    params.value.isCollapsePhone = true;
                  }
                }}>
                {params.value.isCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Box>
            ) : null}
          </>
        );
      }
    },
    { field: "id", hide: true, sortable: false },
    {
      field: "name",
      flex: 0.8,
      minWidth: 125,
      hide: false,
      sortable: false,
      headerName: "Name",
      renderCell: (params: { value: string; row: { item: ISearchResultItem } }) => {
        return (
          <Typography>
            <Link
              style={{ textDecoration: "unset", color: theme.palette.primary.main }}
              to={`/app/accounts/${params.row.item.person.debts[0]?.meta.id}`}>
              {searchTerms.length > 0 ? getHighlighted(params.value) : params.value}
            </Link>
          </Typography>
        );
      }
    },
    {
      field: "email",
      flex: 1.2,
      hide: false,
      minWidth: 160,
      headerName: "Email",
      sortable: false,
      renderCell: (params: {
        row: {
          isCollapse: any;
          row: any;
          item: ISearchResultItem;
        };
        value: [{ meta: { isPrimary: boolean }; email: string | undefined }];
      }) => {
        return (
          <Stack>
            {params.value.map(
              (email: { meta: { isPrimary: boolean }; email: string | undefined }, index: number) => {
                const isSomeCollapse = rows.some(item => {
                  return (
                    item.isCollapse.isCollapse && item.email[index]?.email === params?.value?.[index]?.email
                  );
                });

                if (index <= 2) {
                  return (
                    <Typography
                      key={index}
                      variant={params?.value?.[index]?.meta.isPrimary ? "body2" : "body1"}
                      pb={isSomeCollapse ? 1 : 0}
                      borderBottom={
                        isSomeCollapse && params.value.length > 2 && index !== params.value.length - 1
                          ? `1px solid ${theme.palette.common.black}`
                          : "unset"
                      }>
                      {searchTerms.length > 0
                        ? getHighlighted(params?.value?.[index]?.email)
                        : params?.value?.[index]?.email}
                    </Typography>
                  );
                } else if (isSomeCollapse) {
                  return (
                    <Typography
                      key={index}
                      variant={params?.value?.[index]?.meta.isPrimary ? "body2" : "body1"}>
                      {searchTerms.length > 0
                        ? getHighlighted(params?.value?.[index]?.email)
                        : params?.value?.[index]?.email}
                    </Typography>
                  );
                } else {
                  return null;
                }
              }
            )}
          </Stack>
        );
      }
    },
    {
      field: "phone",
      flex: 0.5,
      hide: false,
      headerName: "Phone #",
      minWidth: 150,
      sortable: false,
      renderCell: (params: {
        row: { item: ISearchResultItem };
        value: [{ meta: { isPrimary: boolean }; phoneNumber: string }];
      }) => {
        return (
          <Stack>
            {params.value.map(
              (phone: { meta: { isPrimary: boolean }; phoneNumber: string }, index: number) => {
                const isSomeCollapse = rows.some(item => {
                  return (
                    item.isCollapse.isCollapsePhone &&
                    item.phone[index]?.phoneNumber === params?.value?.[index]?.phoneNumber
                  );
                });
                if (index <= 2) {
                  return (
                    <Typography
                      key={index}
                      pb={isSomeCollapse ? 1 : 0}
                      borderBottom={
                        isSomeCollapse && params.value.length > 2
                          ? `1px solid ${theme.palette.common.black}`
                          : "unset"
                      }
                      variant={params?.value?.[index]?.meta?.isPrimary ? "body2" : "body1"}>
                      {searchTerms.length > 0
                        ? getHighlightedPhone(params?.value?.[index]?.phoneNumber)
                        : params?.value?.[index]?.phoneNumber}
                    </Typography>
                  );
                } else if (isSomeCollapse) {
                  return (
                    <Typography
                      key={index}
                      variant={params?.value?.[index]?.meta?.isPrimary ? "body2" : "body1"}>
                      {searchTerms.length > 0
                        ? getHighlightedPhone(params?.value?.[index]?.phoneNumber)
                        : params?.value?.[index]?.phoneNumber}
                    </Typography>
                  );
                } else {
                  return null;
                }
              }
            )}
          </Stack>
        );
      }
    },
    {
      field: "addresses",
      flex: 1.2,
      hide: false,
      minWidth: 200,
      sortable: false,
      headerName: "Addresses",
      renderCell: (params: {
        value: {
          isCollapse: boolean;
          addressItem: [
            {
              streetLine1: string;
              state: string;
              city: string;
              zipcode: string;
              meta: { isPrimary: boolean };
            }
          ];
        };
      }) => {
        params.value.addressItem.sort(function (x, y) {
          return x.meta.isPrimary === y.meta.isPrimary ? 0 : x.meta.isPrimary ? -1 : 1;
        });

        return (
          <Stack width={"100%"}>
            {params.value.addressItem.map(
              (
                address: { streetLine1: string; state: string; city: string; zipcode: string },
                index: number
              ) => {
                const street = params?.value?.addressItem?.[index]?.streetLine1.split(",");
                const streetPart1 = street?.[0];
                const isSomeCollapse = rows.some(item => {
                  const streetPart1Item = item.addresses.addressItem[index]?.streetLine1.split(",")[0];
                  return item.isCollapse.isCollapse && streetPart1Item === streetPart1;
                });
                if (index === 0) {
                  return (
                    <Box key={index}>
                      <Typography
                        variant={params.value.addressItem[index].meta.isPrimary ? "body2" : "body1"}
                        sx={{ wordBreak: "normal !important" }}>
                        {streetPart1}
                      </Typography>
                      <Typography
                        sx={{ wordBreak: "normal !important" }}
                        variant={params.value.addressItem[index].meta.isPrimary ? "body2" : "body1"}
                        pb={isSomeCollapse ? 1 : 0}>
                        {`${params.value.addressItem[index].city}, ${params.value.addressItem[index].state} ${params.value.addressItem[index].zipcode}`}
                      </Typography>
                      <Box
                        position={"relative"}
                        borderBottom={
                          params.value.addressItem.length > 1 && isSomeCollapse
                            ? `1px solid ${theme.palette.common.black}`
                            : "unset"
                        }></Box>
                    </Box>
                  );
                } else if (params.value.addressItem.length > 1 && isSomeCollapse) {
                  const street = params?.value?.addressItem?.[index]?.streetLine1.split(",");
                  const streetPart1 = street?.[0];

                  return (
                    <Box key={index}>
                      <Typography sx={{ wordBreak: "normal !important", marginTop: 0.5 }}>
                        {streetPart1}
                      </Typography>
                      <Typography
                        sx={{ wordBreak: "normal !important" }}
                        pb={isSomeCollapse ? 1 : 0}
                        borderBottom={
                          params.value.addressItem.length > 1 &&
                          isSomeCollapse &&
                          index !== params.value.addressItem.length - 1
                            ? `1px solid ${theme.palette.common.black}`
                            : "unset"
                        }>
                        {`${params?.value?.addressItem?.[index]?.city}, ${params?.value?.addressItem?.[index]?.state} ${params?.value?.addressItem?.[index]?.zipcode}`}
                      </Typography>
                    </Box>
                  );
                } else {
                  return null;
                }
              }
            )}
          </Stack>
        );
      }
    },
    {
      field: "creditor",
      flex: 1,
      minWidth: 120,
      hide: !columnsData?.[4]?.checked,
      sortable: false,
      headerName: "Creditor",
      renderCell: (params: { value: [{ companyName: string }] }) => {
        return (
          <>
            {params.value.map((item, index) => {
              return (
                <Typography key={index} pb={index > 0 ? 3 : 0}>
                  {searchTerms.length > 0 ? getHighlighted(item.companyName) : item.companyName}
                </Typography>
              );
            })}
          </>
        );
      }
    },
    {
      field: "balance",
      flex: 0.6,
      minWidth: 110,
      hide: !columnsData?.[5]?.checked,
      sortable: false,
      headerName: "Balance",
      renderCell: (params: { value: [{ amount: number; currency: string }] }) => {
        return (
          <>
            {params.value.slice(1).map((item, index) => {
              return (
                <Stack key={index} pb={index > 0 ? 3 : 0}>
                  <MonetaryValue variant="body1" monetaryAmount={{ amount: item.amount, currency: "USD" }} />
                </Stack>
              );
            })}
          </>
        );
      }
    },
    {
      field: "reference",
      flex: 0.7,
      minWidth: 170,
      hide: !columnsData?.[6]?.checked,
      sortable: false,
      headerName: "Reference",
      renderCell: (params: { value: [{ ref: string; txn: string }] }) => {
        return (
          <Stack>
            <>
              {params.value.slice(1).map((item, index) => {
                const valueRefHighlighted = getHighlighted(item.ref);
                const valueTxnHighlighted = getHighlighted(item.txn);
                return (
                  <>
                    <Box key={index}>
                      <Typography sx={{ wordBreak: "normal !important" }}>
                        Ref: {valueRefHighlighted === undefined ? "N/A" : valueRefHighlighted}
                      </Typography>
                      <Typography pb={2} sx={{ wordBreak: "normal !important" }}>
                        TXN ID: {valueTxnHighlighted === undefined ? "N/A" : valueTxnHighlighted}
                      </Typography>
                    </Box>
                  </>
                );
              })}
            </>
          </Stack>
        );
      }
    },
    {
      field: "status",
      flex: 0.7,
      minWidth: 80,
      sortable: false,
      hide: !columnsData?.[7]?.checked,
      headerName: "Status",
      renderCell: (params: { value: [string] }) => {
        return (
          <>
            {params.value.map((item, index) => {
              return (
                <Typography key={index} pb={index > 0 ? 3 : 0} sx={{ wordBreak: "break-all !important" }}>
                  {item}
                </Typography>
              );
            })}
          </>
        );
      }
    },
    {
      field: "accountNumber",
      flex: 1,
      hide: !columnsData?.[8]?.checked,
      sortable: false,
      headerName: "TA Number",
      minWidth: 125,
      renderCell: (params: { value: [string] }) => {
        return (
          <>
            {params.value.slice(1).map((item, index) => {
              return (
                <Typography key={index} pb={index > 0 ? 3 : 0}>
                  {searchTerms.length > 0 ? getHighlighted(item) : item}
                </Typography>
              );
            })}
          </>
        );
      }
    },
    {
      field: "debtId",
      flex: 1,
      hide: !columnsData?.[9]?.checked,
      sortable: false,
      headerName: "Debt ID",
      renderCell: (params: { value: [string] }) => {
        return (
          <>
            {params.value.map((item, index) => {
              return (
                <Typography key={index} pb={index > 0 ? 3 : 0}>
                  {searchTerms.length ? getHighlighted(item) : item}
                </Typography>
              );
            })}
          </>
        );
      }
    },
    {
      field: "debtorId",
      flex: 1,
      hide: !columnsData?.[10]?.checked,
      sortable: false,
      headerName: "Debtor ID",
      renderCell: (params: { value: [string] }) => {
        return (
          <>
            {params.value.map((item, index) => {
              return (
                <Typography key={index} pb={index > 0 ? 3 : 0}>
                  {searchTerms.length ? getHighlighted(item) : item}
                </Typography>
              );
            })}
          </>
        );
      }
    },
    {
      field: "originalCreditor",
      flex: 1,
      hide: !columnsData?.[11]?.checked,
      sortable: false,
      headerName: "Original Creditor",
      renderCell: (params: { value: [string] }) => {
        return (
          <>
            {params.value.map((item, index) => {
              return (
                <Typography key={index} pb={index > 0 ? 3 : 0}>
                  {searchTerms.length ? getHighlighted(item) : item}
                </Typography>
              );
            })}
          </>
        );
      }
    },
    {
      field: "originalAccountNumber",
      flex: 1,
      hide: !columnsData?.[12]?.checked,
      sortable: false,
      headerName: "Original Account Number",
      renderCell: (params: { value: [string] }) => {
        return (
          <>
            {params.value.map((item, index) => {
              return (
                <Typography key={index} pb={index > 0 ? 3 : 0}>
                  {searchTerms.length ? getHighlighted(item) : item}
                </Typography>
              );
            })}
          </>
        );
      }
    }
  ];

  async function callApi(resultSearchTerms: string | undefined) {
    let creditorIdUrl = "";
    const index = resultSearchTerms?.search("creditorId");

    if (index != -1 && index !== undefined) {
      const indexBeginCreditor = index - 1;
      resultSearchTerms = resultSearchTerms?.slice(0, indexBeginCreditor);
    }

    const url = window.location.href;
    const indexCreditor = url.search("creditorId");
    if (indexCreditor !== -1) {
      const indexBeginCreditor = indexCreditor - 1;
      creditorIdUrl = url.slice(indexBeginCreditor + 12, url.length);
    }

    const result = await crmApi
      .searchControllerSearch({
        searchQuery: resultSearchTerms ?? "",
        creditorId: creditorIdUrl.length > 0 ? creditorIdUrl : undefined
      })
      .then(data => {
        return data.data;
      });

    return result;
  }

  function CustomNoRowsOverlay() {
    return (
      <Stack textAlign="center" py={2}>
        <Typography variant="h4">No Search Results Found.</Typography>
        <Typography variant="body1">Modify search criteria above.</Typography>
      </Stack>
    );
  }

  const creditorName = useSelector((state: RootState) => state.creditor.name);

  function createTermsObject(searchQuery: string) {
    const index = searchQuery.search("/search/advance");
    let arrayTerms = searchQuery.split("+");

    const arrayResult: string[] = [];
    if (index !== -1) {
      arrayTerms.pop();
    }
    arrayTerms = arrayTerms.filter(item => item != "");

    arrayTerms.map(item => {
      item = decodeURI(item);
      if (item.includes(":")) {
        const spaceString = item.split(":");

        item = `${spaceString[0]}: ${spaceString[1]}`;

        let result = `${item.charAt(0).toUpperCase()}${item.slice(1)}`;
        if (item.includes("phone")) {
          result = `${spaceString[0]?.charAt(0).toUpperCase()}${spaceString[0]?.slice(
            1
          )}: +${spaceString[1]?.charAt(0)} ${spaceString[1]?.slice(1)}`;
        }

        arrayResult.push(result);
      } else {
        if (item != "") {
          arrayResult.push(item.trim());
        }
      }
    });
    if (arrayResult.length > 0) {
      setSearchTerms(arrayResult);
    }
  }

  const allCreditors = useSelector((state: RootState) => state.creditor.allCreditors);

  React.useEffect(() => {
    const url = window.location.href;
    const hasCreditorIndex = url.search("creditorId");
    const indexBeginCreditor = hasCreditorIndex - 1;
    const creditorId = url.slice(indexBeginCreditor + 12, url.length);
    const creditorSelected = allCreditors?.find(item => {
      if (item.value === creditorId) {
        return item;
      }
      return;
    });

    let label = "";
    if (creditorSelected?.label !== undefined && hasCreditorIndex !== -1) {
      label = `Creditor: ${creditorSelected?.label}`;
    }
    if (label.length > 0) {
      searchTerms.push(label);
      const newArray = [...searchTerms, label];
      const duplicates = [...new Set(newArray)];
      let count = 0;
      duplicates.map(item => {
        if (item.includes("Creditor")) {
          count = count + 1;
        }
        return;
      });
      if (count == 2) {
        duplicates.pop();
      }
      const stringSearch = duplicates.join("+");
      createTermsObject(stringSearch);
    }
  }, [allCreditors]);

  React.useEffect(() => {
    const url = window.location.href;
    const indexSearchQuery = url.search("searchQuery=");
    const indexToSum = indexSearchQuery;
    const hasCreditorIndex = url.search("creditorId");
    if (indexSearchQuery !== -1 || hasCreditorIndex !== -1) {
      //searchQuery= length
      const beginQuery = 12;
      const indexCreditor = url?.search("creditorId");
      const indexBeginCreditor = indexCreditor - 1;

      let searchQuery = url.slice(
        indexToSum + beginQuery,
        indexCreditor !== -1 ? indexBeginCreditor : url.length
      );

      const hasCreditorIndex = url.search("creditorId");
      //max of creditor id === 32
      const creditorId = url.slice(indexBeginCreditor + 12, url.length);
      const creditorSelected = allCreditors?.find(item => {
        if (item.value === creditorId) {
          return item;
        }
        return;
      });

      let label = "";
      if (creditorSelected?.label !== undefined && hasCreditorIndex !== -1) {
        label = `Creditor: ${creditorSelected?.label}`;
      }
      searchQuery = decodeURI(searchQuery);
      setSearchResults(undefined);
      if (searchQuery.search("/search/advance") !== -1) {
        void callApiAndSort(undefined);
        const searchQueryObject = `${label}`;

        createTermsObject(searchQueryObject);
      } else {
        void callApiAndSort(searchQuery);
        let searchQueryObject;
        if (label !== "") {
          searchQueryObject = `${searchQuery}+${label}`;
        } else {
          searchQueryObject = `${searchQuery}`;
        }

        createTermsObject(searchQueryObject);
      }
    }
  }, [window.location.href]);

  function bodyMultilpleOrSingleDebt(data: Array<{ debts: IDebt; person: IPersonSearch; score: number }>) {
    let item: ISearchResultItem;
    let dataResult: ISearchResultItem[] = [];
    dataResult = data?.map((itemPerson, index) => {
      item = {
        isCollapse: false,
        isCollapsePhone: false,
        height: "80px",
        overflow: "hidden",
        index: index,
        person: {
          addresses:
            !("addresses" in itemPerson.person) || itemPerson.person.addresses?.length === 0
              ? []
              : itemPerson.person.addresses,
          debts: [itemPerson.debts],
          emails:
            !("emails" in itemPerson.person) || itemPerson.person.emails?.length === 0
              ? []
              : itemPerson.person.emails,
          meta: itemPerson.person.meta,
          name: itemPerson.person.name,
          phones:
            !("phones" in itemPerson.person) || itemPerson.person.phones?.length === 0
              ? []
              : itemPerson.person.phones,
          reference: itemPerson.person.reference,
          additionalInformation: itemPerson.person?.additionalInformation
        },
        score: itemPerson.score
      };
      return item;
    });
    return dataResult;
  }

  async function callApiAndSort(resultSearchTerms: string | undefined) {
    setIsLoadingDataTable(true);
    const result = callApi(resultSearchTerms);

    const data = (await result.then(data => data)) as ISearchResults;

    const multipleDebt: Array<{ debts: IDebt; person: IPersonSearch; score: number }> = [];
    const singleDebt: Array<{ debts: IDebt; person: IPersonSearch; score: number }> = [];

    data.results.map(item => {
      if (item.person.debts.length > 1) {
        item.person.debts.map(itemDebt => {
          multipleDebt.push({ debts: itemDebt, person: item.person, score: item.score });
        });
      } else if (item.person.debts.length === 1) {
        item.person.debts.map(itemDebt => {
          singleDebt.push({ debts: itemDebt, person: item.person, score: item.score });
        });
      }
    });
    let dataCustomMultiple: ISearchResultItem[] = [];
    if (multipleDebt.length > 0) {
      dataCustomMultiple = bodyMultilpleOrSingleDebt(multipleDebt);
    }

    const dataCustomSingle = bodyMultilpleOrSingleDebt(singleDebt);
    const allDebts = dataCustomSingle.concat(dataCustomMultiple);

    allDebts?.sort((a, b) => b.score - a.score);
    setSearchResults({ results: allDebts ?? [] });
    setIsLoadingDataTable(false);
  }
  const [isOpenAdvanceSearch, setOpenAdvanceSearch] = React.useState(false);

  const [width, setWidth] = React.useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const firstFixedBreakPoint = 1080;
  const middleRelativeBreakPoint = 1300;
  const secondFixedBreakpoint = 1440;
  const threeColumnLayoutBreakpoint = 1700;

  let inputSearchBarSize;
  let inputsAdvancedSearch;
  if (width <= firstFixedBreakPoint) {
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  } else if (width > firstFixedBreakPoint && width <= middleRelativeBreakPoint) {
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  } else if (width > middleRelativeBreakPoint && width <= secondFixedBreakpoint) {
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  } else if (width > secondFixedBreakpoint && width < threeColumnLayoutBreakpoint) {
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  } else if (width >= threeColumnLayoutBreakpoint) {
    inputSearchBarSize = "874px";
    inputsAdvancedSearch = "400px";
  }

  const [isOnlySearch, setOnlySearch] = React.useState(false);

  function setEmptyResults() {
    setSearchTerms([]);
    setSearchResults(undefined);
  }

  return (
    <>
      <TopNavBar setEmptyResults={setEmptyResults} />
      <Stack direction={"row"} justifyContent={"center"} width={"100%"} alignItems={"center"} mt={5.625}>
        <Box position={"relative"}>
          <SearchBar
            isOpenAdvanceSearch={isOpenAdvanceSearch}
            setOpenAdvanceSearch={(isOpen: boolean) => setOpenAdvanceSearch(isOpen)}
            fullWidth
            isOnlySearch
            variant="standard"
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            inputSearchBarSize={inputSearchBarSize}
            inputsAdvancedSearch={inputsAdvancedSearch}
            sendCreditorSelected={sendCreditorSelected}
            handleSearch={(isClickedSearch: boolean) => setOnlySearch(isClickedSearch)}
            shouldMoveResults={shouldMoveResults}
            setMoveResults={value => setMoveResults(value)}
            executeSearch={async items => {
              const hasCreditor = items?.some(item => item.includes("Creditor:"));
              items = items?.filter(item => !item.includes("Creditor:"));
              const searchQuery = convertQueryToString(items);
              const creditorIdSelectedForUrl = creditorId ?? creditorIdSelected;
              if (!hasCreditor) {
                history.push(`/app/accounts/search/advance?searchQuery=${searchQuery}`);
              } else if (searchQuery != "") {
                history.push(
                  `/app/accounts/search/advance?searchQuery=${searchQuery}&creditorId=${creditorIdSelectedForUrl}`
                );
              } else if (searchQuery === "" && creditorIdSelectedForUrl !== "") {
                history.push(`/app/accounts/search/advance?creditorId=${creditorIdSelectedForUrl}`);
              }
            }}
            searchTermDeleted={(value: boolean) => searchTermDeleted(value)}
          />
          <Box sx={{ position: "absolute", top: "13px", right: "31px", display: "flex" }}>
            <Tooltip
              placement="right"
              arrow
              title={
                <>
                  <Typography variant="body1">Be sure to separate multiple facets</Typography>
                  <Typography variant="body1">with a space</Typography>
                </>
              }>
              <InfoOutlinedIcon sx={{ fontSize: "2rem" }} />
            </Tooltip>
          </Box>
        </Box>
      </Stack>

      <Stack pt={shouldMoveResults ? 2 : 50}>
        {!isLoadingDataTable && _DATA.currentData().length > 0 && (
          <Stack
            direction={"row"}
            height={"47px"}
            justifyContent="space-between"
            alignItems={"center"}
            sx={{ backgroundColor: theme.palette.common.white }}
            mb={0.5}
            mx={1}
            mr={2}>
            <Typography variant="h4" ml={2}>
              Search Results
            </Typography>
            <Box onClick={() => setOpenFilterColumns(true)} mr={2}>
              <VisibilityIcon />
            </Box>
          </Stack>
        )}
        {isOpenFilterColumns && (
          <ClickAwayListener onClickAway={() => setOpenFilterColumns(false)}>
            <Stack
              width="260px"
              height="560px"
              pl={3.375}
              pt={1}
              sx={{
                backgroundColor: theme.palette.grey[600],
                position: "absolute",
                right: "11px",
                zIndex: 1
              }}>
              {columnsData.map((item, index) => {
                return (
                  <Stack key={index} direction={"row"}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e => handleChangeCheckBox(item.checked, index)}
                            checked={item.checked}
                            disabled={item.isDisabled}
                          />
                        }
                        label={item.label}
                      />
                    </FormGroup>
                  </Stack>
                );
              })}
            </Stack>
          </ClickAwayListener>
        )}
        <div
          style={{
            height: rows.length > 0 ? 500 : 150,
            background: theme.palette.common.white,
            margin: "0px 8px",
            marginRight: "16px"
          }}>
          <DataGridPro
            getRowHeight={() => "auto"}
            loading={isLoadingDataTable}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay
            }}
            rows={rows ?? []}
            columns={columnsTable}
            sx={{
              ".MuiDataGrid-cell": {
                alignItems: "flex-start",
                flexDirection: "column",
                ".MuiTypography-root": {
                  whiteSpace: "normal",
                  wordBreak: "break-all"
                }
              },
              ".MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#EAEAEB"
              },
              ".MuiDataGrid-footerContainer": {
                display: "none"
              },
              ".MuiDataGrid-row:hover": {
                boxShadow: "0px 2px 11px 0px rgb(0 0 0 / 50%)"
              },
              ".MuiDataGrid-row:first-of-type": {
                marginTop: "7px"
              }
            }}
          />
        </div>
        {!isLoadingDataTable && _DATA.currentData().length > 0 && (
          <Stack sx={{ backgroundColor: theme.palette.common.white }} mt={0.5} mx={1}>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
}

function usePagination(data: ISearchResults | undefined, itemsPerPage: number) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const maxPage = Math.ceil(data?.results?.length ?? 0 / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + Number(itemsPerPage);
    return data?.results.slice(begin, end).filter(item => item !== undefined) ?? [];
  }

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  return { next, prev, currentData, currentPage, maxPage };
}

export function convertQueryToString(items: string[] | undefined) {
  const isIncluded = items?.some(
    item =>
      item.includes("Email") ||
      item.includes("Creditor") ||
      item.includes("#") ||
      item.includes("Reference") ||
      item.includes("Phone")
  );

  const itemsFormatted = items?.map(item => {
    if (item.includes("Phone")) {
      const itemNoSpaces = item.replaceAll(" ", "").replaceAll("+", "");
      return itemNoSpaces;
    }
    if (item.includes("Email")) {
      const itemNoSpaces = item.replaceAll(" ", "");
      return itemNoSpaces;
    }
    if (item.includes("Creditor")) {
      const itemNoSpaces = item.replaceAll(" ", "");
      return itemNoSpaces;
    }
    if (item.includes("#")) {
      const itemNoSpaces = item.replaceAll(" ", "").replaceAll("TA", "");
      return itemNoSpaces;
    }
    if (item.includes("Reference")) {
      const itemNoSpaces = item.replaceAll(" ", "");
      return itemNoSpaces;
    }
    return item;
  });
  let resultSearchTerms;
  if (isIncluded) {
    resultSearchTerms = itemsFormatted?.join("+").replaceAll(" ", "+").trim().toLowerCase();
  } else {
    resultSearchTerms = items?.join("+");
  }
  return resultSearchTerms;
}

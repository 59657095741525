import { Link } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import { useGenericConfig } from "~/core/config/context";
import { urlToCamundaCockpitTask } from "~/core/utils/routing";

export function CockpitTaskLink(props: { processInstanceId: string }): ReactElement {
  const { camundaConfig } = useGenericConfig();
  const cockpitUrl = urlToCamundaCockpitTask(props.processInstanceId, camundaConfig).toString();
  return (
    <Link href={cockpitUrl} target="_blank">
      Open in Camunda Cockpit
    </Link>
  );
}

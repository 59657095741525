import { Tooltip, Typography } from "@mui/material";
import type { ReactElement, ReactNode } from "react";
import { useTheme } from "@mui/material/styles";

interface Props {
  tooltip: string | null;
  children: ReactNode;
}

export function NavbarText({ tooltip, children }: Props): ReactElement {
  const theme = useTheme();
  return (
    <Tooltip title={tooltip ?? ""}>
      <Typography
        variant="button"
        mx={1}
        style={{
          color: theme.palette.common.white,
          fontWeight: "bold",
          textTransform: "none"
        }}>
        {children}
      </Typography>
    </Tooltip>
  );
}

import { format } from "date-fns";

export function formatDate(date: Date | null | string | undefined, dateFormat?: string): string {
  if (date) {
    return format(new Date(date), dateFormat ? dateFormat : "MM/dd/yyyy");
  } else {
    return "None";
  }
}

export function formatTimestamp(date: number | null | undefined, dateFormat?: string): string {
  if (date) {
    return format(new Date(date), dateFormat ? dateFormat : "MM/dd/yyyy");
  } else {
    return "None";
  }
}

export function renderDate(date: Date | null | undefined): string {
  if (date) {
    return formatDate(date);
  } else {
    return "None";
  }
}

export function formatTime(date: Date): string {
  return format(date, "MM/dd/yyyy, HH:mm OOOO");
}

export function renderTime(date: Date | null | undefined): string {
  if (date) {
    return formatTime(date);
  } else {
    return "None";
  }
}

export function isValidDate(d: Date): boolean {
  return d instanceof Date && !isNaN(Number(d));
}

export function formatNumber(number: number, units?: string, options?: Intl.NumberFormatOptions): string {
  const numberFormatter = new Intl.NumberFormat(undefined, options);
  const formatted = numberFormatter.format(number);
  if (units) {
    return `${formatted} ${units}`;
  } else {
    return formatted;
  }
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const num = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  return `${num} ${sizes[i] ?? ""}`;
}

import * as React from "react";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Box, Stack, styled } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import RestoreIcon from "@mui/icons-material/Restore";
import ForumIcon from "@mui/icons-material/Forum";
import WarningIcon from "@mui/icons-material/Warning";
import PolicyIcon from "@mui/icons-material/Policy";
import GavelIcon from "@mui/icons-material/Gavel";
import SettingsIcon from "@mui/icons-material/Settings";
import { SignedInNavPartial } from "../../../components/layouts/SignedInNavPartial";
import SearchBar from "./SearchBar";
import { useTheme } from "@mui/material/styles";
import { Logo } from "../../layouts/Logo";

interface ISidebar {
  isOpenAdvanceSearch: boolean;
  setOpenAdvanceSearch: (isOpen: boolean) => void;
  handleSearch: (isClickedSearch: boolean) => void;
  searchTerms: string[];
  setSearchTerms: (item: string[]) => void;
  inputSearchBarSize: string | undefined;
  inputsAdvancedSearch: string | undefined;
  executeSearch: (item: string[]) => void;
  sendCreditorSelected: (value: string | undefined) => void;
  searchTermDeleted: (value: boolean) => void;
  shouldMoveResults: boolean;
  setMoveResults: (value: boolean) => void;
}

const Drawer = styled(MuiDrawer)`
  & .MuiDrawer-paper {
    width: 55px;
    overflow: hidden;
    margin-top: 65px;
  }
`;

export const SideBar = ({
  isOpenAdvanceSearch,
  setOpenAdvanceSearch,
  handleSearch,
  searchTerms,
  setSearchTerms,
  inputSearchBarSize,
  inputsAdvancedSearch,
  executeSearch,
  sendCreditorSelected,
  searchTermDeleted,
  shouldMoveResults,
  setMoveResults
}: ISidebar): React.ReactElement => {
  const theme = useTheme();

  return (
    <>
      <MuiAppBar position="fixed">
        <Toolbar sx={{ backgroundColor: theme.palette.primary.dark }}>
          <Stack direction={"row"} justifyContent={"space-between"} width={"100%"} alignItems={"center"}>
            <Box>
              <Logo size="small" customWidth={"60px"} sx={{ position: "relative", left: "-23px" }} />
            </Box>
            <Box>
              <SearchBar
                isOpenAdvanceSearch={isOpenAdvanceSearch}
                setOpenAdvanceSearch={(isOpen: boolean) => setOpenAdvanceSearch(isOpen)}
                fullWidth
                variant="standard"
                handleSearch={handleSearch}
                searchTerms={searchTerms}
                setSearchTerms={setSearchTerms}
                inputSearchBarSize={inputSearchBarSize}
                inputsAdvancedSearch={inputsAdvancedSearch}
                executeSearch={items => executeSearch(items)}
                sendCreditorSelected={sendCreditorSelected}
                searchTermDeleted={(value: boolean) => searchTermDeleted(value)}
                shouldMoveResults={shouldMoveResults}
                setMoveResults={setMoveResults}
              />
            </Box>
            <Box>
              <SignedInNavPartial />
            </Box>
          </Stack>
        </Toolbar>
      </MuiAppBar>
      <Drawer variant="permanent">
        <List>
          {[
            <DashboardIcon />,
            <AttachMoneyIcon />,
            <MailOutlineIcon />,
            <RestoreIcon />,
            <ForumIcon />,
            <WarningIcon />,
            <PolicyIcon />,
            <GavelIcon />,
            <SettingsIcon />
          ].map((icon, index) => (
            <ListItem button key={index}>
              <ListItemIcon sx={{ mb: 1.25 }}>{icon}</ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

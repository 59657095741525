import { useMutation } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { Button, IconButton, Tooltip } from "@mui/material";
import type { GridActionsCellItemProps } from "@mui/x-data-grid";
import _ from "lodash";
import type { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { useAlerts } from "~/core/components/widgets/AlertContext";
import { ConfirmationDialog, useConfirmationDialog } from "~/core/components/widgets/ConfirmationDialog";
import { useUser } from "~/core/contexts/UserContext";
import { useSafeMutation } from "~/core/utils/apollo";
import { humanTaskName } from "~/core/utils/models";
import type { IDisputeTaskModel } from "~/gql/types";
import { ClaimedTasksListQuery } from "../tasks/list/ClaimedTasksList";
import { DisputeTasksListQuery } from "../tasks/list/DisputeTasksList";
import { SetDisputeTaskAssignee } from "../tasks/list/TaskListTable";

export type StartTaskButtonProps = GridActionsCellItemProps & {
  task: IDisputeTaskModel;
};

export function ClaimTaskButton({ task, icon, label, showInMenu }: StartTaskButtonProps): ReactElement {
  const [assignTask] = useSafeMutation(SetDisputeTaskAssignee);
  const history = useHistory();
  const { camundaId } = useUser();
  const alerts = useAlerts();
  const { confirmState, openConfirm, closeConfirm } = useConfirmationDialog();

  const startTask = async (taskId: string): Promise<void> => {
    try {
      if (!task.assigneeId) {
        await assignTask({
          variables: {
            taskId,
            assigneeId: camundaId
          }
        });
        alerts.addAlert({
          severity: "success",
          message: `You've now claimed ${humanTaskName(
            task
          )}, it is assigned to you and other agents can't see it.`
        });
      } else if (task.assigneeId !== camundaId) {
        alerts.addAlert({
          severity: "warning",
          message: `We couldn't claim the ${humanTaskName(
            task
          )} for you, because someone else has it. You can still view it, but you can't make any changes.`
        });
      }
      history.push(`/app/tasks/${taskId}`);
    } catch (error) {
      console.error({ error });
      alerts.addAlert({
        severity: "error",
        message: `We couldn't claim the ${humanTaskName(
          task
        )} for you due to an error. If you think this is a mistake, ask your supervisor for help.`
      });
    }
  };

  const askConfirmation = (): void => {
    openConfirm({
      title: "Are you sure you want to claim this task?",
      body:
        "Claiming a task means you are going to work on it. " +
        "No other agents will be able to see it if it's assigned to you.",
      confirmLabel: "Yes, Claim",
      onConfirm: async () => startTask(task.id)
    });
  };
  return (
    <>
      <ConfirmationDialog state={confirmState} onClose={closeConfirm} />

      <Tooltip title={"Assign this task to yourself to start working on it"}>
        {icon && !showInMenu ? (
          <IconButton aria-label={label} onClick={askConfirmation}>
            {icon}
          </IconButton>
        ) : (
          <Button
            onClick={askConfirmation}
            color={"primary"}
            variant={showInMenu ? "contained" : "outlined"}
            size="small">
            {label}
          </Button>
        )}
      </Tooltip>
    </>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Block } from "../blocks/Block";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

interface ModalAccountNotesProps {
  isOpenModal: boolean;
  setOpenModal: (state: boolean) => void;
  closeAccountNotesAddComment: () => void;
}

export function ModalAccountNotes({
  isOpenModal,
  setOpenModal,
  closeAccountNotesAddComment
}: ModalAccountNotesProps): React.ReactElement {
  const theme = useTheme();
  return (
    <Modal open={isOpenModal} onClose={() => setOpenModal(false)}>
      <Box
        width="546px"
        height="329px"
        sx={{
          backgroundColor: "#FFF",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          position: "absolute",
          borderRadius: "4px"
        }}>
        <Block
          noBottomPadding
          heightCard="329px"
          titlePrefix={
            <WarningIcon sx={{ color: "#FF9F4C", fontSize: "1.1em", marginRight: 1, marginTop: -0.75 }} />
          }
          title="Note Incomplete"
          titleTypography="h2"
          titlePostfix={
            <Stack direction="row" marginLeft={"auto !important"} alignItems="center">
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setOpenModal(false)} />
            </Stack>
          }>
          <Stack px={2}>
            <Typography variant="subtitle2">
              Your account note is not complete. Please review and complete the note for submission.
            </Typography>
            <Typography variant="subtitle2" mt={3} mb={5}>
              Select "Return to complete" to complete the note or select "Proceed without completing" to
              continue without comments.
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between" position="relative" top="10px">
            <Button
              onClick={() => {
                setOpenModal(false);
                closeAccountNotesAddComment?.();
              }}
              variant="outlined">
              PROCEED WITHOUT COMPLETING
            </Button>
            <Button
              sx={{ backgroundColor: theme.palette.primary.main }}
              onClick={() => setOpenModal(false)}
              variant="contained">
              RETURN TO COMPLETE
            </Button>
          </Stack>
        </Block>
      </Box>
    </Modal>
  );
}

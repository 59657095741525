import * as _ from "lodash";
import type { ICamunda_KeyValuePair, Maybe } from "~/gql/types";

export type CamundaVars = ReadonlyArray<Maybe<ICamunda_KeyValuePair>>;

// TODO: verify valueType
export function getOptionalVariable(pairs: CamundaVars, key: string): string | null {
  return _.compact(pairs).find(pair => pair.key === key)?.value ?? null;
}

export function getVariable(pairs: CamundaVars, key: string): string {
  const value = _.compact(pairs).find(pair => pair.key === key)?.value;
  if (!value) {
    throw `Value for key ${key} is empty when it should not be.`;
  }
  return value;
}

import type { IMonetaryAmount } from "~/gql/types";

export function makeMonetaryAmount(amount: number, currency = "USD"): IMonetaryAmount {
  return {
    __typename: "MonetaryAmount",
    amount,
    currency
  };
}
export function catchToNull<R, E extends new (message?: string | undefined) => Error>(
  fn: () => R,
  errorType?: E
): R | null {
  try {
    return fn();
  } catch (e) {
    if (errorType) {
      if (e instanceof errorType) {
        console.debug(`Swallowing error of type ${errorType.name}:`, e);
        return null;
      } else {
        throw e;
      }
    } else {
      console.warn("Swallowing error:", e);
      return null;
    }
  }
}

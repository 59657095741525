import { Button, Checkbox, FormControlLabel, FormGroup, Radio, Stack, TextField } from "@mui/material";
import * as _ from "lodash";
import type { ReactElement, ReactNode } from "react";
import { useState } from "react";
import { useAlerts } from "~/core/components/widgets/AlertContext";
import { ConfirmationDialog, useConfirmationDialog } from "~/core/components/widgets/ConfirmationDialog";
import type { ReviewAction } from "./DisputeActionForm";

interface DisputeActionItemProps {
  title: string;
  value: ReviewAction;
  actionButtonTitle: string;
  isSelected: boolean;
  resetState: () => void;
  successMessage: string;
  failureMessage: string;
  onAction: (commentText: string, shouldPostComment: boolean) => Promise<unknown>;
  confirmationTitle: string;
  confirmationActionTitle: string;
  confirmationCancelTitle?: string;
  children?: ReactNode;
}

type ConfirmationBodyOrTextProps =
  | { confirmationBody: ReactElement; confirmationText?: string }
  | { confirmationBody?: ReactElement; confirmationText: string };

export function DisputeActionItem({
  title,
  value,
  actionButtonTitle,
  isSelected,
  successMessage,
  failureMessage,
  resetState,
  onAction,
  confirmationTitle,
  confirmationBody,
  confirmationText,
  confirmationActionTitle,
  confirmationCancelTitle = "Cancel",
  children
}: ConfirmationBodyOrTextProps & DisputeActionItemProps): ReactElement {
  const [commentText, setCommentText] = useState("");
  const [hasValidationError, setValidationError] = useState(false);
  const [shouldPostComment, setShouldPostComment] = useState(false);
  const alerts = useAlerts();
  const { confirmState, openConfirm, closeConfirm } = useConfirmationDialog({
    onFinish: async () => {
      setCommentText("");
      setValidationError(false);
      resetState();
      alerts.addAlert({
        severity: "success",
        message: successMessage
      });
    },
    onCancel: async () => resetState(),
    onError: async () =>
      alerts.addAlert({
        severity: "error",
        message: failureMessage
      })
  });
  const isCommentValid = (text: string): boolean => !_.isEmpty(_.trim(text));

  const onActionButton = (): void => {
    if (isCommentValid(commentText)) {
      openConfirm({
        title: confirmationTitle,
        body: confirmationBody ?? confirmationText,
        confirmLabel: confirmationActionTitle,
        cancelLabel: confirmationCancelTitle,
        onConfirm: async () => {
          await onAction(commentText, shouldPostComment);
        }
      });
    }
  };
  const onCancelButton = (): void => {
    setCommentText("");
    setValidationError(false);
    resetState();
  };

  return (
    <Stack>
      <FormControlLabel value={value} control={<Radio />} label={title} />
      {isSelected && (
        <Stack spacing={1} pl={2}>
          <TextField
            id="outlined-multiline-static"
            label="Enter your comments"
            value={commentText}
            onChange={event => {
              const newText = event.target.value;
              setCommentText(newText);
              setValidationError(!isCommentValid(newText));
            }}
            margin={"none"}
            variant="standard"
            type="search"
            required={true}
            error={hasValidationError}
          />
          <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
            <FormGroup>
              <FormControlLabel
                sx={{ fontSize: "small" }}
                control={
                  <Checkbox
                    checked={shouldPostComment}
                    onChange={evt => setShouldPostComment(evt.target.checked)}
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                    size={"small"}
                  />
                }
                label="Post to CE"
              />
            </FormGroup>
            <Stack direction={"row"} spacing={1} sx={{ marginLeft: children ? undefined : "auto" }}>
              <Button variant="text" onClick={onCancelButton} size="small">
                Cancel
              </Button>
              <Button variant="contained" onClick={onActionButton} size="small">
                {actionButtonTitle}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}
      <ConfirmationDialog state={confirmState} onClose={closeConfirm} />
    </Stack>
  );
}

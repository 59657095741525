import { Email as EmailIcon } from "@mui/icons-material";
import type { ReactElement } from "react";
import { IconLink } from "./IconLink";

export interface IEmailLinkProps {
  email: string;
}

export function EmailLink({ email }: IEmailLinkProps): ReactElement {
  return <IconLink icon={<EmailIcon fontSize={"small"} />} href={`mailto:${email}`} title={email} />;
}

import { ControlPoint as ControlPointIcon } from "@mui/icons-material";
import { Create as CreateIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { ThreeDotsButton } from "~/core/components/widgets/ThreeDotsButton";

export function ContactBlockButtons(): React.ReactElement {
  return (
    <>
      <Box position={"relative"} top={"3px"}>
        <ControlPointIcon
          color="disabled"
          sx={{
            marginRight: "0.4em",
            marginLeft: "0.4em"
          }}
        />
        <CreateIcon color="disabled" />
      </Box>
      <ThreeDotsButton alignRight />
    </>
  );
}

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React from "react";
import { useGenericConfig } from "~/core/config/context";
import { formatTimestamp } from "~/core/utils/formats";
import { urlToTemplateEmail } from "~/core/utils/routing";
import type { IDebtLogEntryModel } from "~/gql/graphql";
import type {
  ClassifiedDLE,
  EmailToDebtorDLE,
  SmsToDebtorDLE,
  StatusChangeDLE,
  TotalAdjustmentDLE
} from "~/models/models/dle";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";

interface TimelineProps {
  timeline: ClassifiedDLE[];
  setPopOverOpen: (isOpen: boolean, dleId: string | null, anchorEl: HTMLElement | null) => void;
}

export function TimelineHistory({ timeline, setPopOverOpen }: TimelineProps): React.ReactElement | null {
  return (
    <>
      {timeline.map((dle, index) => {
        const isEdge = index + 1 >= timeline.length;
        switch (dle.type) {
          case "email_to_debtor":
            return <EmailToDebtorItem key={dle.id} dle={dle} isEdge={isEdge} />;
          case "sms_to_debtor":
            return <SmsToDebtorItem key={dle.id} dle={dle} isEdge={isEdge} />;
          case "status_change":
            return <StatusChangeItem key={dle.id} dle={dle} isEdge={isEdge} />;
          case "total_to_collect_adjustment":
            return (
              <TotalAdjustmentItem
                key={dle.id}
                dle={dle}
                isEdge={isEdge}
                onClick={anchor => setPopOverOpen(true, dle.id, anchor.current)}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}

interface DleTimelineItemProps {
  dle: IDebtLogEntryModel;
  icon: React.ReactElement;
  content: React.ReactNode;
  isEdge?: boolean;
  noWrap?: boolean;
  onClick?: (ref: React.MutableRefObject<HTMLElement | null>) => void;
}

function DleTimelineItem({
  dle,
  icon,
  content,
  isEdge = false,
  noWrap = true,
  onClick
}: DleTimelineItemProps): React.ReactElement {
  const theme = useTheme();
  const anchorRef = React.useRef<HTMLElement | null>(null);
  return (
    <TimelineItem key={dle.id} sx={{ marginLeft: "3px !important" }}>
      <TimelineSeparator>
        {icon}
        {!isEdge && <TimelineConnector sx={{ marginY: 1.25, backgroundColor: theme.palette.common.black }} />}
      </TimelineSeparator>

      <TimelineContent py={"0px !important"}>
        <Stack
          direction={"row"}
          onClick={() => onClick?.(anchorRef)}
          sx={{ cursor: onClick ? "pointer" : undefined }}>
          <Typography variant={"body1"} mr={1} ref={anchorRef}>
            {formatTimestamp(dle.meta?.time_created)}
          </Typography>
          <Box color={onClick ? theme.palette.action.active : undefined}>{content}</Box>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
}

function EmailToDebtorItem({ dle, isEdge }: { dle: EmailToDebtorDLE; isEdge: boolean }): React.ReactElement {
  const theme = useTheme();
  const { appConfig } = useGenericConfig();
  const emailRequest = dle.send_email_to_debtor_request;
  const emailResponse = emailRequest.response;
  const emailSubject = !_.isNil(emailResponse?.subject) ? emailResponse?.subject : "";
  const truncatedEmailSubject = _.truncate(emailSubject, { length: 85, omission: "…" });
  const content = emailResponse && (
    <OverflowTooltip notWhiteSpace title={emailResponse.subject ?? ""}>
      {emailResponse.rendered_content_s3_path ? (
        <a
          color={theme.palette.primary.dark}
          style={{ textTransform: "none", textDecoration: "none" }}
          href={urlToTemplateEmail(dle.id, appConfig) ?? ""}>
          {truncatedEmailSubject}
        </a>
      ) : (
        <Typography color={theme.palette.primary.dark}>{truncatedEmailSubject}</Typography>
      )}
    </OverflowTooltip>
  );

  return (
    <DleTimelineItem
      dle={dle}
      icon={<MailOutlineIcon fontSize="small" />}
      isEdge={isEdge}
      content={content}
    />
  );
}

function SmsToDebtorItem({ dle, isEdge }: { dle: SmsToDebtorDLE; isEdge: boolean }): React.ReactElement {
  const smsRequest = dle.send_sms_to_debtor_request;
  const smsBody = smsRequest.sms_body ?? "No SMS message.";
  const truncatedSMS = _.truncate(smsBody, { length: 34, omission: "…" });
  const content = <OverflowTooltip title={smsBody}>{truncatedSMS}</OverflowTooltip>;
  return (
    <DleTimelineItem
      dle={dle}
      icon={<SmsOutlinedIcon fontSize="small" />}
      isEdge={isEdge}
      content={content}
    />
  );
}

function StatusChangeItem({ dle, isEdge }: { dle: StatusChangeDLE; isEdge: boolean }): React.ReactElement {
  const changeEntry = dle.status_change_entry;
  const content = `Debt State change. From [${changeEntry.old_status}] to [${changeEntry.new_status}]`;
  return (
    <DleTimelineItem
      dle={dle}
      icon={<FiberManualRecordIcon fontSize="small" color="disabled" />}
      isEdge={isEdge}
      isSpecial={true}
      content={content}
    />
  );
}

function TotalAdjustmentItem({
  dle,
  isEdge,
  onClick
}: {
  dle: TotalAdjustmentDLE;
  isEdge: boolean;
  onClick: (ref: React.RefObject<HTMLElement>) => void;
}): React.ReactElement {
  const theme = useTheme();
  const content = <Typography color={theme.palette.primary.dark}>Balance Adjustment</Typography>;
  return (
    <DleTimelineItem
      dle={dle}
      icon={<PaidOutlinedIcon fontSize="small" />}
      isEdge={isEdge}
      noWrap={false}
      onClick={onClick}
      content={content}
    />
  );
}

import { envVarAsUrl } from "../utils/env";
import type { IConfigEnv } from "./context";

type AppEnv = "dev" | "pcid" | "pcip" | "pciq" | "pcis";

export interface IGenericAppConfig {
  readonly appEnv: AppEnv;
  readonly baseUrl: string;
  readonly sentryDsn: string;
  readonly ceBaseUrl: string;
  readonly apiBaseUrl: string;
  readonly graphqlUrl: string;
}

export const appConfig = (env: IConfigEnv): Readonly<IGenericAppConfig> => ({
  appEnv: envVarAsAppEnv(env.get("REACT_APP_APP_ENV").required().asString()),
  sentryDsn: env.get("REACT_APP_SENTRY_DSN").default("").asString(),
  baseUrl: envVarAsUrl(env.get("REACT_APP_APP_BASE_URI").required()),
  ceBaseUrl: envVarAsUrl(env.get("REACT_APP_CE_BASE_URI").required()),
  apiBaseUrl: envVarAsUrl(env.get("REACT_APP_API_BASE_URI").required()),
  graphqlUrl: envVarAsUrl(env.get("REACT_APP_API_GRAPHQL_URI").required())
});

function isAppEnv(envStr: string): envStr is AppEnv {
  return ["dev", "pcid", "pcip", "pciq", "pcis"].includes(envStr);
}

function envVarAsAppEnv(envVar: string): AppEnv {
  const envStr = envVar.toLowerCase();
  if (!isAppEnv(envStr)) {
    throw new Error(`Invalid app env: ${envVar}`);
  }
  return envStr;
}

import Debug from "debug";
import type { IEnv, IOptionalVariable, LoggerFn } from "env-var";
import { from } from "env-var";
import * as React from "react";
import { useContext } from "react";
import type { IGenericAppConfig } from "./app.config";
import { appConfig } from "./app.config";
import type { ICamundaConfig } from "./camunda.config";
import { camundaConfig } from "./camunda.config";
import type { IOktaConfig, IOktaGenericAuthOptions } from "./okta.config";
import { oktaConfig, oktaGenericAuthOptions } from "./okta.config";

export interface IGenericConfig {
  readonly appConfig: IGenericAppConfig;
  readonly camundaConfig: ICamundaConfig;
  readonly oktaConfig: IOktaConfig;
  readonly oktaGenericAuthOptions: IOktaGenericAuthOptions;
}

export type IConfigEnv = IEnv<IOptionalVariable, Record<string, string>>;

export const ConfigContext = React.createContext<IGenericConfig | undefined>(undefined);

export function useGenericConfig<T extends IGenericConfig>(): T {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(`useGenericConfig must be called within ConfigContext`);
  }
  return context as T;
}

const fetchCache: Record<string, Promise<Record<string, string>>> = {};

export async function withGenericConfig<T extends IGenericConfig, R>(
  path: string,
  extendFn: (env: Readonly<IConfigEnv>, config: Readonly<IGenericConfig>) => T,
  wrappedFn: (config: Readonly<T>) => Promise<R>
): Promise<R> {
  if (fetchCache[path] === undefined) {
    fetchCache[path] = fetch(path, { cache: "no-cache" }).then(
      async resp => (await resp.json()) as Record<string, string>
    );
  }
  const envConfig = await fetchCache[path];
  if (!envConfig) {
    throw `Failed to fetch cached envConfig from ${path}`;
  }
  const debug = Debug("config");
  const loggerFn: LoggerFn = (varName, msg) => {
    debug(`[${varName}] ${msg}`);
  };
  const env = from(envConfig, {}, loggerFn);
  const config: IGenericConfig = {
    appConfig: appConfig(env),
    camundaConfig: camundaConfig(env),
    oktaConfig: oktaConfig(env),
    oktaGenericAuthOptions: oktaGenericAuthOptions(env)
  };

  return wrappedFn(extendFn(env, config));
}

import { gql } from "@apollo/client";
import { Button, Divider, Grid, Stack, useTheme } from "@mui/material";
import * as _ from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useAlerts } from "~/core/components/widgets/AlertContext";
import { ConfirmationDialog, useConfirmationDialog } from "~/core/components/widgets/ConfirmationDialog";
import { ExternalLink } from "~/core/components/widgets/ExternalLink";
import { useGenericConfig } from "~/core/config/context";
import { useUser } from "~/core/contexts/UserContext";
import { useSafeMutation } from "~/core/utils/apollo";
import { humanTaskName } from "~/core/utils/models";
import { urlToCreditorLegacy } from "~/core/utils/routing";
import type { IDisputeTaskModel } from "~/gql/types";
import { IFileReviewStatus } from "~/gql/types";
import { Block } from "../blocks/Block";
import { CustomerInformationBlock } from "../blocks/CustomerInformationBlock";
import { DisputeHelpBlock } from "../blocks/DisputeHelpBlock";
import { ClaimTaskButton } from "../widgets/ClaimTaskButton";
import { CockpitTaskLink } from "../widgets/CockpitTaskLink";
import { DisputeFileRequestsView } from "./DisputeFileRequestsView";

export const DisputeTaskViewQuery = gql(`
query DisputeTaskView($taskId: ID!, $skipFileRequestReviews: Boolean = false) {
  task: camunda_Task(id: $taskId) {
    ...DisputeTaskModel
  }
}
`);

export const DisputeTaskViewByBusinessKeyQuery = gql(`
query DisputeTaskViewByBusinessKey(
  $name: String!
  $businessKey: String!
  $skipFileRequestReviews: Boolean = false
) {
  tasks: camunda_Tasks(name: $name, businessKey: $businessKey, firstResult: 0, maxResults: 1) {
    ...DisputeTaskModel
  }
}
`);

export const CompleteDisputeTask = gql(`
mutation CompleteDisputeTask($taskId: ID!, $variables: [camunda_KeyValuePairInput!]) {
  update_camunda_Task_complete(id: $taskId, variables: $variables) {
    id
  }
}
`);

export const UpdateDisputeTask = gql(`
mutation UpdateDisputeTask(
  $taskId: ID!
  $variables: [camunda_KeyValuePairInput]
  $skipFileRequestReviews: Boolean = false
) {
  update_camunda_Task_update(id: $taskId, variables: $variables) {
    ...DisputeTaskModel
  }
}
`);

interface DisputeTaskViewProps {
  task: IDisputeTaskModel;
  isLoading: boolean;
}

function test(): boolean {
  return false;
}

export function DisputeTaskView({ task, isLoading }: DisputeTaskViewProps): ReactElement | null {
  const { appConfig } = useGenericConfig();
  const { addAlert } = useAlerts();

  const history = useHistory();
  const theme = useTheme();
  const user = useUser();
  const debt = task.debt;
  const creditor = debt?.creditor;
  const creditorTask = _.first(debt?.creditorTasks);
  const areSomeReviewsPending =
    task.fileRequestsReviews?.some(review => !review.status || review.status === IFileReviewStatus.Pending) ??
    false;

  if (!creditorTask || !debt || !creditor || !debt.person) {
    addAlert({
      message: `Couldn't load CreditorTask, Debt, Creditor or Debtor for the task ${task.id}`,
      severity: "error",
      description: `Please contact Prosper technical support. [creditorTask=${
        creditorTask?.id ?? "missing"
      }; debt=${debt?.id ?? "missing"}; creditor=${creditor?.id ?? "missing"}; debtor=${
        debt?.person?.id ?? "missing"
      }]`
    });
    history.push("/app/tasks");
    return null;
  }
  return (
    <Stack>
      <Grid container direction={"row"} spacing={2}>
        <Grid item xs={3}>
          <Stack spacing={2}>
            <CustomerInformationBlock task={task} debt={debt} person={debt.person} />
            {user.isDvSupervisor && (
              <Block title="Admin Tools">
                <Stack spacing={1}>
                  {task.processInstanceId && <CockpitTaskLink processInstanceId={task.processInstanceId} />}
                </Stack>
              </Block>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Block
              title={`Review Debt Verification Documents`}
              subheader={`Creditor: ${creditor.company_name ?? "Unknown Creditor"}`}
              titlePostfix={
                <ExternalLink
                  title={"View creditor in legacy CE"}
                  href={urlToCreditorLegacy(creditor.id, appConfig)}
                />
              }
              isLoading={isLoading}>
              <Stack spacing={2}>
                <DisputeFileRequestsView task={task} creditorTask={creditorTask} />
                <Stack spacing={2} pt={"1.5em"}>
                  <Divider color={theme.palette.grey[700]} variant="fullWidth" />
                  <Stack direction={"row"} textAlign={"right"} justifyContent={"right"} spacing={2}>
                    <Button onClick={() => history.push("/app")} variant="outlined">
                      Return to Dashboard
                    </Button>
                    {!task.assigneeId && <ClaimTaskButton label="Claim" task={task} showInMenu />}
                    {user.ownsTask(task) && !areSomeReviewsPending && <FinishDisputeTask task={task} />}
                  </Stack>
                </Stack>
              </Stack>
            </Block>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={2}>
            {task.disputeContextHelp && (
              <DisputeHelpBlock disputeType={task.disputeType} disputeHelp={task.disputeContextHelp} />
            )}
          </Stack>
        </Grid>
      </Grid>
      {/*<BpmnViewer taskId={task.id} />*/}
    </Stack>
  );
}

function FinishDisputeTask({ task }: { task: IDisputeTaskModel }): ReactElement {
  const [completeDisputeTask] = useSafeMutation(CompleteDisputeTask, {
    ignoreResults: true
  });
  const history = useHistory();
  const { confirmState, openConfirm, closeConfirm } = useConfirmationDialog();
  const alerts = useAlerts();
  const onClick = (): void =>
    openConfirm({
      title: "Are you sure you want to finish with this task?",
      body: (
        <>
          If you are done with the reviews, finishing this task will trigger the necessary steps, like
          notifying creditor and/or consumer.
        </>
      ),
      confirmLabel: "Yes, Finish",
      onConfirm: async () => {
        try {
          await completeDisputeTask({
            variables: { taskId: task.id, variables: [] }
          });
          alerts.addAlert({
            severity: "success",
            message: `You've successfully finished ${humanTaskName(task)}.`
          });
          history.push("/app/tasks");
        } catch (error) {
          console.error({ error });
          alerts.addAlert({
            severity: "error",
            message: `Couldn't finish task ${humanTaskName(task)}.`
          });
        }
      }
    });
  return (
    <>
      <ConfirmationDialog state={confirmState} onClose={closeConfirm} />
      <Button color="primary" variant="contained" onClick={onClick}>
        Finish Task
      </Button>
    </>
  );
}

import { AppBar, Box, Stack, Toolbar } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import { Alerts } from "~/core/components/widgets/AlertContext";
import { Footer } from "./Footer";
import { Logo } from "./Logo";
import { useTheme } from "@mui/material/styles";

export interface ICenteredLayoutProps {
  children?: React.ReactNode;
}

export function CenteredLayout({ children }: ICenteredLayoutProps): ReactElement {
  const theme = useTheme();
  return (
    <Stack spacing={2} minHeight={"100vh"} minWidth={"50em"} alignItems={"center"}>
      <AppBar position="static" sx={{ alignItems: "center", backgroundColor: theme.palette.primary.dark }}>
        <Toolbar>
          <Logo size="small" />
        </Toolbar>
      </AppBar>
      <Alerts />
      <Box
        sx={{
          alignItems: "center",
          alignContent: "center",
          width: "90%"
        }}>
        {children}
      </Box>
      <Box flex={1} />
      <Footer />
    </Stack>
  );
}

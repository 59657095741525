import type { IConfigEnv } from "~/core/config/context";
import { envVarAsUrl } from "~/core/utils/env";

interface SoloConfig {
  baseUri: string;
  enableWebVitals: boolean;
}

export function soloConfig(env: IConfigEnv): SoloConfig {
  return {
    baseUri: envVarAsUrl(env.get("REACT_APP_SOLO_BASE_URI").required()),
    enableWebVitals: env.get("REACT_APP_SOLO_ENABLE_WEB_VITALS").default("true").asBool()
  };
}

import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";

export function ThreeDotsButton({ alignRight }: { alignRight?: boolean }): React.ReactElement {
  const theme = useTheme();
  return (
    <Box
      sx={{
        cursor: "pointer",
        marginLeft: alignRight ? "auto !important" : undefined,
        display: "flex"
      }}
      color={theme.palette.common.black}>
      <MoreVertIcon color="disabled" />
    </Box>
  );
}

import { Refresh as RefreshIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import type { ReactElement } from "react";

interface RefreshButtonProps {
  onClick: () => void;
}

export function RefreshButton({ onClick }: RefreshButtonProps): ReactElement {
  return (
    <Button size={"small"} color={"primary"} startIcon={<RefreshIcon />} onClick={onClick}>
      Refresh
    </Button>
  );
}

import { useEffect, useState } from "react";

export type Interval = number | "hour" | "minute" | "second" | null | undefined;

export function useCurrentDate(interval: Interval = "minute"): Date {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    const bump = (): void => {
      intervalId = setTimeout(() => {
        setDate(new Date());
        bump();
      }, nextCallback(new Date(), interval));
    };

    bump();

    return () => intervalId && clearInterval(intervalId);
  });

  return date;
}

function nextCallback(now: Date, interval: Interval): number {
  if (typeof interval === "number") {
    return interval;
  } else if (interval === "second") {
    return 1000 - now.getMilliseconds();
  } else if (interval === "minute") {
    return 60 * 1000 - now.getMilliseconds() - now.getSeconds() * 1000;
  } else if (interval === "hour") {
    return 60 * 60 * 1000 - now.getMilliseconds() - now.getSeconds() * 1000 - now.getMinutes() * 60 * 1000;
  } else {
    return 1000 - now.getMilliseconds();
  }
}

import { Stack } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import { TimeDisplay } from "~/core/components/widgets/TimeDisplay";
import type { IFileRequestReviewModel } from "~/gql/types";
import { IFileReviewStatus } from "~/gql/types";
import { DataRow } from "../../widgets/DataRow";

interface FileReviewStatusProps {
  fileRequestReview: IFileRequestReviewModel;
}

export function FileReviewStatus({
  fileRequestReview: { comment, status, reviewTimestamp }
}: FileReviewStatusProps): ReactElement | null {
  const inner =
    status === IFileReviewStatus.Approved ? (
      <DataRow inline justify="left" title={"Approved:"}>
        <TimeDisplay date={reviewTimestamp} />
      </DataRow>
    ) : status === IFileReviewStatus.Rejected ? (
      <DataRow inline justify="left" title={"Rejected:"}>
        <TimeDisplay date={reviewTimestamp} />
      </DataRow>
    ) : null;

  return (
    <Stack>
      {inner}
      {comment ? <DataRow inline justify="left" title="Comment:" value={comment} /> : undefined}
    </Stack>
  );
}

import type { IConfigEnv } from "~/core/config/context";
import type { IOktaGenericAuthOptions } from "~/core/config/okta.config";
import { oktaGenericAuthOptions } from "~/core/config/okta.config";
import { envVarAsUrl } from "~/core/utils/env";
import { soloConfig } from "./solo.config";

export const oktaSoloConfig = oktaAuthOptions;

export function oktaAuthOptions(env: IConfigEnv): IOktaGenericAuthOptions {
  const authOpts = oktaGenericAuthOptions(env);
  const soloConf = soloConfig(env);
  return {
    ...authOpts,
    redirectUri: envVarAsUrl(env.get("REACT_APP_SOLO_OKTA_LOGIN_REDIRECT_URI").required()),
    postLogoutRedirectUri: soloConf.baseUri
  };
}

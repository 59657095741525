import * as _ from "lodash";
import { chain } from "lodash";
import type { ICreditorTask } from "~/gql/types";
import type { StrictTypedTypePolicies } from "../../gql/apollo-helpers";
import { defineReader, isTaggedReference } from "../utils/policies";

export const CREDITOR_TASK_POLICY: StrictTypedTypePolicies["CreditorTask"] = {
  keyFields: ["meta", ["id"]],
  fields: {
    id: defineReader<ICreditorTask, string>(({ readPrimitive }) => {
      const thisTaskHeader = readPrimitive("header");
      return thisTaskHeader?.task_id;
    }),
    lastMediaReceivedDate: defineReader<ICreditorTask, Date>(
      ({ readReferenceFrom, readPrimitiveFrom, readPrimitive }) => {
        const dvRequestRef = readPrimitive("debt_verification_request");
        return _.chain(dvRequestRef?.requested_files)
          .compact()
          .flatMap(rf =>
            chain(rf.remote_files)
              .compact()
              .map(remFile => {
                if (isTaggedReference(remFile)) {
                  const remoteFileMeta = readReferenceFrom(remFile)("meta");
                  return readPrimitiveFrom(remoteFileMeta)("time_created");
                } else {
                  return undefined;
                }
              })
              .compact()
              .value()
          )
          .compact()
          .map(epoch => new Date(epoch))
          .sortBy()
          .reverse()
          .head()
          .value();
      }
    )
  }
};

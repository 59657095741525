import { useApolloClient } from "@apollo/client";
import { AdminPanelSettings as AdminPanelSettingsIcon } from "@mui/icons-material";
import { Face as FaceIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import type { ReactElement } from "react";
import * as React from "react";
import { signOutUser, useUser } from "~/core/contexts/UserContext";
import { NavbarText } from "./NavbarText";

export function SignedInNavPartial(): ReactElement {
  const user = useUser();
  const { oktaAuth } = useOktaAuth();
  const apolloClient = useApolloClient();
  return (
    <Stack direction={"row"} alignItems={"center"}>
      {user.isDvSupervisor ? (
        <Tooltip title={"Supervisor"}>
          <AdminPanelSettingsIcon fontSize={"small"} />
        </Tooltip>
      ) : (
        <Tooltip title={"Agent"}>
          <FaceIcon fontSize={"small"} />
        </Tooltip>
      )}
      <NavbarText tooltip={user.email}>{user.name}</NavbarText>
      <Button color="inherit" onClick={async () => signOutUser({ apolloClient, oktaAuth })}>
        Sign Out
      </Button>
    </Stack>
  );
}

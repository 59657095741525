import type { ApolloCache, NormalizedCacheObject } from "@apollo/client";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  setLogVerbosity
} from "@apollo/client";
import type { TypePolicies } from "@apollo/client/cache/inmemory/policies";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import type { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import { compact, isString } from "lodash";
import React from "react";
import { useDeepCompareEffect } from "use-deep-compare";
import { CenteredLayout } from "~/core/components/layouts/CenteredLayout";
import { ProgressIndicator } from "~/core/components/widgets/ProgressIndicator";
import type { IGenericAppConfig } from "~/core/config/app.config";
import { useGenericConfig } from "~/core/config/context";
import { TYPE_POLICIES } from "~/models/client";
import { GRAPHQL_SCHEMA_DIGEST } from "~/models/schemaDigest";
import { signOutUser } from "./UserContext";

const SCHEMA_DIGEST_KEY = "prosperSchemaDigest";

const cache = createMemoryCache();
const localStorageWrapper = new LocalStorageWrapper(window.localStorage);

export function ApolloContext({ children }: { children?: React.ReactNode }): React.ReactElement {
  const { authState, oktaAuth } = useOktaAuth();
  const { appConfig } = useGenericConfig();
  setLogVerbosity("debug");

  const [client, setClient] = React.useState<ApolloClient<NormalizedCacheObject>>();
  const persistor = React.useRef<CachePersistor<NormalizedCacheObject>>();

  useDeepCompareEffect(() => {
    const userId = authState?.idToken?.claims?.sub;
    if (client) {
      console.debug(
        `Initializing Apollo Client: isAuthenticated = ${authState?.isAuthenticated} (${userId})`
      );
    } else {
      console.debug(
        `Initializing Apollo Client: isAuthenticated = ${authState?.isAuthenticated} (${userId})`
      );
    }
    if (userId) {
      void createCachePersistor({ cache, appConfig, userId }).then(newPersistor => {
        const idToken = authState.idToken?.idToken;
        const nonce = authState.idToken?.claims.nonce;
        const link = from(
          compact([
            idToken && nonce ? getAuthenticationLink({ idToken, nonce }) : undefined,
            idToken && client ? getErrorHandlingLink({ apolloClient: client, oktaAuth }) : undefined,
            getHttpLink(appConfig)
          ])
        );
        persistor.current = newPersistor;
        const newClient = createApolloClient({ cache, link, persistor: newPersistor });
        newClient.disableNetworkFetches = false;
        setClient(newClient);
        setTimeout(() => {
          void newClient.reFetchObservableQueries(true);
        }, 1000);
      });
    } else {
      const localOnlyClient = createApolloClient({ cache });
      localOnlyClient.disableNetworkFetches = true;
      setClient(localOnlyClient);
    }
  }, [authState?.idToken, appConfig]);

  if (!client) {
    return (
      <CenteredLayout>
        <ProgressIndicator message="Connecting to subspace communications network..." width="60%" />
      </CenteredLayout>
    );
  }

  try {
    return <ApolloProvider client={client} children={children} />;
  } catch (e) {
    void client.resetStore();
    throw e;
  }
}

interface AuthenticationLinkProps {
  idToken: string;
  nonce: string;
}
function getAuthenticationLink({ idToken, nonce }: AuthenticationLinkProps): ApolloLink {
  // add the authorization to the headers
  return new ApolloLink((operation, forward) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation.setContext((context: Record<string, any>) => {
      return {
        ...context,
        headers: {
          ...(context.headers as Record<string, string>),
          Authorization: `Bearer ${idToken}`,
          "X-Nonce": nonce
        }
      };
    });
    return forward(operation);
  });
}

interface ErrorHandlingLinkOpts {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  oktaAuth: OktaAuth;
}

function getErrorHandlingLink({ apolloClient, oktaAuth }: ErrorHandlingLinkOpts): ApolloLink {
  return onError(({ graphQLErrors, networkError }) => {
    console.group("GraphQL Error");
    try {
      void apolloClient.clearStore();
      if (graphQLErrors) {
        for (const { message, locations, path } of graphQLErrors) {
          console.log({ message, locations, path });
          if (isString(message) && message.includes("authenticate")) {
            console.log(`Authentication issue detected, signing out.`);
            void signOutUser({ apolloClient, oktaAuth });
          }
        }
      }
      if (networkError) {
        console.error(networkError);
      }
    } finally {
      console.groupEnd();
    }
  });
}

function getHttpLink(appConfig: IGenericAppConfig): ApolloLink {
  return getDefaultHttpLink(appConfig);
}

function getDefaultHttpLink(appConfig: IGenericAppConfig): ApolloLink {
  return new HttpLink({ useGETForQueries: true, uri: appConfig.graphqlUrl, credentials: "include" });
}
function getBatchHttpLink(appConfig: IGenericAppConfig): ApolloLink {
  return new BatchHttpLink({
    uri: appConfig.graphqlUrl,
    includeExtensions: true,
    credentials: "include"
  });
}

function createMemoryCache(): ApolloCache<NormalizedCacheObject> {
  console.debug("Initializing Apollo InMemoryCache");
  return new InMemoryCache({
    typePolicies: TYPE_POLICIES as TypePolicies,
    canonizeResults: true,
    addTypename: true
  });
}

async function ensurePersistedCachesIsValid<T>({
  persistor,
  userId
}: {
  persistor: CachePersistor<T>;
  userId: string;
}): Promise<boolean> {
  const expectedDigest = `${GRAPHQL_SCHEMA_DIGEST}-${userId}`;
  const persistedDigest = localStorageWrapper.getItem(SCHEMA_DIGEST_KEY);
  if (expectedDigest !== persistedDigest) {
    console.debug(`Purging cache because schema was updated: ${persistedDigest} -> ${expectedDigest}`);
    await persistor.purge();
    localStorageWrapper.setItem(SCHEMA_DIGEST_KEY, expectedDigest);
    return true;
  } else {
    return false;
  }
}

interface CreateCachePersistorOpts<T> {
  cache: ApolloCache<T>;
  appConfig: IGenericAppConfig;
  userId: string;
}

async function createCachePersistor<T>({
  cache,
  appConfig,
  userId
}: CreateCachePersistorOpts<T>): Promise<CachePersistor<T>> {
  console.debug(`Initializing Apollo CachePersistor for user ${userId}`);
  const newPersistor = new CachePersistor({
    cache,
    storage: localStorageWrapper,
    debug: appConfig.appEnv !== "pcip",
    trigger: "write",
    debounce: 500
  });
  if (await ensurePersistedCachesIsValid({ persistor: newPersistor, userId })) {
    await cache.reset();
  } else {
    await newPersistor.restore();
  }
  return newPersistor;
}

interface CreateApolloClientOpts<T> {
  link?: ApolloLink;
  cache: ApolloCache<T>;
  persistor?: CachePersistor<T>;
}

function createApolloClient<T>({ link, cache, persistor }: CreateApolloClientOpts<T>): ApolloClient<T> {
  const client = new ApolloClient({
    link,
    name: "@trueaccord/prosper-frontend",
    credentials: "include",
    connectToDevTools: true,
    defaultOptions: {
      query: {
        errorPolicy: "none",
        notifyOnNetworkStatusChange: true
      },
      mutate: {
        errorPolicy: "none"
      },
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "none",
        notifyOnNetworkStatusChange: true
      }
    },
    cache
  });
  if (persistor) {
    client.onClearStore(async () => {
      console.debug("ApolloClient.onClearStore triggered persistor purge");
      return persistor.purge();
    });
    client.onResetStore(async () => {
      console.debug("ApolloClient.onResetStore triggered persistor purge");
      return persistor.purge();
    });
  }
  return client;
}

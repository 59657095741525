import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { sum } from "lodash";
import * as React from "react";
import type { AdjustedBalance } from "~/core/components/domain/accounts/helpers";
import type { IDebtOutBalanceModel } from "~/gql/types";
import { DataRow } from "../../widgets/DataRow";
import { Block } from "../blocks/Block";
import { MonetaryValue } from "../widgets/MonetaryValue";

interface IBalanceAdjustmentPopover {
  debtOutBalance: IDebtOutBalanceModel;
  adjustedBalance: AdjustedBalance | undefined;
}

export function BalanceAdjustmentsPopover({
  debtOutBalance,
  adjustedBalance
}: IBalanceAdjustmentPopover): React.ReactElement {
  const balance = debtOutBalance.balance_details_at_placement;
  const sumOfBalances = sum(
    [balance?.principal, balance?.interest, balance?.fees, balance?.costs].flatMap(b => b?.amount)
  );
  let arrowDirection: "up" | "down" | "none";
  if (adjustedBalance && adjustedBalance.total < sumOfBalances) {
    arrowDirection = "down";
  } else if (adjustedBalance && adjustedBalance.total > sumOfBalances) {
    arrowDirection = "up";
  } else {
    arrowDirection = "none";
  }

  return (
    <Block
      title={"Balance Adjustment"}
      noBottomPadding
      titleTypography={"h5"}
      paddingTitle={"16px 16px 0px 16px"}>
      <Stack minWidth={"25em"} maxWidth={"35em"}>
        <Stack>
          <Stack mb={2.5}>
            <DataRow
              titleTypography="body2"
              inline
              title="Reason:"
              value={<Typography variant="body1">{adjustedBalance?.notes}</Typography>}
            />
          </Stack>
          <Stack mb={2.5}>
            <DataRow
              inline
              titleTypography="body2"
              title="Adjusted Balance:"
              value={
                <Stack direction={"row"}>
                  <MonetaryValue variant="body1" monetaryAmount={{ amount: adjustedBalance?.total }} />
                  {arrowDirection === "up" && <ArrowUpwardIcon />}
                  {arrowDirection === "down" && <ArrowDownwardIcon />}
                </Stack>
              }
            />
          </Stack>
          {adjustedBalance && (
            <Stack mb={2.5}>
              <DataRow
                titleTypography="body2"
                inline
                title="Date of Adjustment:"
                value={adjustedBalance.date}
              />
            </Stack>
          )}

          <Stack mb={2.5}>
            <DataRow
              inline
              titleTypography="body2"
              title="New Principal:"
              value={<MonetaryValue variant="body1" value={adjustedBalance?.principal} />}
            />
          </Stack>

          <Stack mb={2.5}>
            <DataRow
              inline
              titleTypography="body2"
              title="New Interest:"
              value={<MonetaryValue variant="body1" value={adjustedBalance?.interest} />}
            />
          </Stack>

          <Stack mb={2.5}>
            <DataRow
              titleTypography="body2"
              inline
              title="New Fees:"
              value={<MonetaryValue variant="body1" value={adjustedBalance?.fees} />}
            />
          </Stack>

          <Stack mb={2.5}>
            <DataRow
              titleTypography="body2"
              inline
              title="New Costs:"
              value={<MonetaryValue variant="body1" value={adjustedBalance?.costs} />}
            />
          </Stack>
        </Stack>
      </Stack>
    </Block>
  );
}

import { useApolloClient } from "@apollo/client";
import { Chip, Stack } from "@mui/material";
import { chain } from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { Suspense, useState } from "react";
import { titleCase } from "~/core/components/utils/content";
import type { ITableColumn } from "~/core/components/widgets/StyledDataGrid";
import { StyledDataGrid } from "~/core/components/widgets/StyledDataGrid";
import { useGenericConfig } from "~/core/config/context";
import { useUser } from "~/core/contexts/UserContext";
import type { MuiColor } from "~/core/styles/types";
import { deleteFromCache } from "~/core/utils/apollo";
import { urlToDownloadRemoteFile } from "~/core/utils/routing";
import type {
  ICreditorTaskModel,
  IDebtTaskModel,
  IFileRequestModel,
  IFileRequestReviewModel,
  IRemoteFileModel,
  Maybe
} from "~/gql/types";
import { IFileReviewStatus } from "~/gql/types";
import { Block } from "../blocks/Block";
import FilePreviewBlock from "../blocks/FilePreviewBlock";
import { AddFileAction } from "./AddFileAction";
import { DisputeActionForm } from "./DisputeActionForm";
import type { DisputeFileRow } from "./DisputeFileRequestsView";
import { DisputeTaskViewQuery } from "./DisputeTaskView";
import { FileReviewStatus } from "./FileReviewStatus";

export interface DisputeFileRequestViewProps {
  order: number;
  columns: ReadonlyArray<ITableColumn<DisputeFileRow>>;
  creditorTask: ICreditorTaskModel;
  task: IDebtTaskModel;
  fileRequest: IFileRequestModel;
  fileRequestReview: IFileRequestReviewModel;
  onReviewComplete: (updatedReview: IFileRequestReviewModel) => Promise<void>;
}

type MaybeUpload = Maybe<{ original_filename?: Maybe<string> }>;

interface UploadedFile {
  creditor_upload?: MaybeUpload;
  debt_verification_upload?: MaybeUpload;
  media_request_upload?: MaybeUpload;
}

export function DisputeFileRequestView({
  order,
  columns,
  task,
  creditorTask,
  fileRequest,
  fileRequestReview,
  onReviewComplete
}: DisputeFileRequestViewProps): ReactElement {
  const user = useUser();
  const { appConfig } = useGenericConfig();
  const client = useApolloClient();
  const [selectedFile, setSelectedFile] = useState<DisputeFileRow | null>(null);

  const canMakeChanges = user.ownsTask(task) && fileRequestReview.status === IFileReviewStatus.Pending;

  const onSelect = (disputeFileRow?: DisputeFileRow): void => {
    if (disputeFileRow?.id === selectedFile?.id) {
      setSelectedFile(null);
    } else {
      setSelectedFile(disputeFileRow ?? null);
    }
  };
  const onFileDelete = (remoteFile: IRemoteFileModel): void => {
    setSelectedFile(null);
    deleteFromCache(remoteFile, client);
  };
  const onFileUpload = async (files: File[]): Promise<void> => {
    setSelectedFile(null);
    await client.refetchQueries({ include: [DisputeTaskViewQuery] });
  };

  const rows = chain(fileRequest.remote_files)
    .compact()
    .map<DisputeFileRow | undefined>(remoteFile => {
      const fileName = getFileName(remoteFile) ?? remoteFile.id;
      const fileRequestId = fileRequest.meta?.id;
      const creditorTaskId = creditorTask.id;
      const creditorId = task.debt?.creditor?.id;
      if (!(creditorId && fileRequestId && creditorTaskId)) {
        return;
      }
      return {
        id: remoteFile.id,
        remoteFile,
        fileRequest,
        fileRequestId,
        creditorTaskId,
        remoteFileId: remoteFile.id,
        creditorId,
        downloadUrl: urlToDownloadRemoteFile(remoteFile.id, appConfig),
        fileName,
        contentType: remoteFile.content_type ?? undefined,
        title: fileRequest.title ?? fileRequest.description ?? "Missing title.",
        description: fileRequest.description ?? "",
        onFileDelete,
        canMakeChanges
      };
    })
    .compact()
    .value();
  const groupName = titleCase(`${fileRequest.title ?? "Unknown Request"} Documents`);

  return (
    <Block
      titlePrefix={<ReviewStatusChip order={order} status={fileRequestReview.status} />}
      title={groupName}
      embedded
      key={fileRequest.id}
      titlePostfix={
        canMakeChanges ? (
          <AddFileAction
            title={`Upload ${groupName}`}
            creditorTask={creditorTask}
            fileRequest={fileRequest}
            onUpload={onFileUpload}
          />
        ) : null
      }>
      <Stack spacing={1}>
        <FileReviewStatus fileRequestReview={fileRequestReview} />
        <StyledDataGrid
          rows={rows}
          columns={columns.map(({ columnDef }) => columnDef)}
          hideFooter={true}
          autoHeight={true}
          showCellRightBorder={false}
          disableColumnSelector={true}
          disableSelectionOnClick={false}
          density={"compact"}
          checkboxSelection={false}
          onRowClick={({ row }) => {
            onSelect(row as DisputeFileRow);
          }}
        />

        <Suspense fallback={<div>Loading...</div>}>
          {selectedFile && <FilePreviewBlock file={selectedFile} />}
        </Suspense>

        {canMakeChanges && task.debt?.person && (
          <DisputeActionForm
            fileRequest={fileRequest}
            fileRequestReview={fileRequestReview}
            taskId={task.id}
            debtId={task.debt.id}
            debtor={task.debt.person}
            onComplete={onReviewComplete}
          />
        )}
      </Stack>
    </Block>
  );
}

interface ReviewStatusChipProps {
  order: number;
  status: Maybe<IFileReviewStatus>;
}

function ReviewStatusChip({ order, status }: ReviewStatusChipProps): ReactElement {
  let chipColor: MuiColor;
  switch (status) {
    case IFileReviewStatus.Approved:
      chipColor = "success";
      break;
    case IFileReviewStatus.Rejected:
      chipColor = "error";
      break;
    default:
      chipColor = "default";
  }

  return <Chip label={order} color={chipColor} sx={{ marginRight: "1em" }} />;
}

function getFileName(file: UploadedFile): string | null {
  return chain([file.creditor_upload, file.debt_verification_upload, file.media_request_upload])
    .compact()
    .flatMap(({ original_filename }) => original_filename)
    .first()
    .value();
}

import Box from "@mui/material/Box";
import * as React from "react";
import { DataRow } from "~/core/components/widgets/DataRow";
import { availablePaymentMethods } from "~/core/utils/models";
import type { IAccountModel, IDebtModel } from "~/gql/types";
import { gql } from "@apollo/client";
import { BlueDot } from "../../widgets/BlueDot";
import { Block } from "../blocks/Block";
import { formatTimestamp } from "~/core/utils/formats";
import { useSafeQuery } from "~/core/utils/apollo";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { styled, useTheme } from "@mui/material/styles";
import { ArrowedPopper } from "../../widgets/ArrowedPopper";
import _ from "lodash";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";
import type { VirtualElement } from "@popperjs/core";
import { CopyText } from "../../widgets/CopyText";

export interface ICreditorBlockProps {
  account: IAccountModel;
  debt: IDebtModel;
  drawerOpen: boolean;
  onCopy: (type: string) => void;
}

const StyledTableCell = styled(TableCell)({
  padding: "8px 16px 8px 0px"
});

const StyledTableRow = styled(TableRow)({
  ":last-child td": {
    border: "none"
  }
});

interface IComment {
  object_id: string | null | undefined;
  inputDate: string | null | undefined;
  inputTime: string | null | undefined;
  inputTimeSort: string | Date;
  comment: string | null | undefined;
  meta: {
    time_created: number | null | undefined;
    id: string | null | undefined;
  };
}

interface CreditorNotesProps {
  comments: IComment[] | undefined;
}

function CreditorNotesTable({ comments }: CreditorNotesProps): React.ReactElement {
  const commentsSorted = _.orderBy(comments, ["inputTimeSort", "comment"], ["desc", "desc", "desc"]);
  return (
    <Stack maxHeight={"22em"}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Input Date</StyledTableCell>
              <StyledTableCell>Input Time</StyledTableCell>
              <StyledTableCell>Details</StyledTableCell>
              <StyledTableCell>TA Import Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commentsSorted?.map(item => {
              if (_.isNil(item)) return null;
              const taImportDate = formatTimestamp(item?.meta?.time_created);

              const comment = getComment(item.comment) ?? "No entry";

              const commentTruncated = _.truncate(comment, {
                length: 30,
                omission: "…"
              });

              return (
                <StyledTableRow key={item.meta.id}>
                  <StyledTableCell>
                    <Typography variant="body1">{getDateFromComment(item.comment) ?? "Unknown"}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">{getTimeFromComment(item.comment) ?? "Unknown"}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <OverflowTooltip title={comment ?? ""}>
                      <Typography variant="body1">{commentTruncated}</Typography>
                    </OverflowTooltip>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">{taImportDate}</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export const CommentsByObjectIds = gql(`
query CommentsByObjectIdsCreditorNotes($ids: [String!]) {
  comments: CommentsByObjectIds(ids: $ids) {
    internal_information{
      aim_info {
        creditor_id
      }
      amex_information {
        flag
      }
    }
    object_id
    comment
    comment_type
    data_source {
      type
    }
    meta {
      time_created
      id
    }
  }
}
`);

const DATE_RE = new RegExp("\\d{2}/\\d{2}/(?:\\d{4}|\\d{2})");
const TIME_RE = new RegExp("\\b([0-1]?[0-9]|2[0-3]):[0-5][0-9](s*[ap]m)?\\b");

export function getDateFromComment(comment: string | undefined | null): string | undefined {
  if (_.isNil(comment)) {
    return undefined;
  }

  const date = comment.match(DATE_RE);
  if (date !== null) {
    return date?.[0];
  } else {
    return undefined;
  }
}

export function getTimeFromComment(comment: string | undefined | null): string | undefined {
  if (_.isNil(comment)) {
    return undefined;
  }

  const time = comment.match(TIME_RE);

  if (time !== null) {
    return time?.[0];
  } else {
    return undefined;
  }
}

export function getComment(comment: string | undefined | null): string | undefined {
  if (_.isNil(comment)) {
    return undefined;
  }

  const time = comment.match(TIME_RE);
  if (time !== null) {
    comment = comment.replace(time[0] ?? "", "");
  }

  const date = comment.match(DATE_RE);
  if (date !== null) {
    comment = comment.replace(date[0] ?? "", "");
  }

  return comment;
}

export function CreditorBlock({
  account,
  debt,
  drawerOpen,
  onCopy
}: ICreditorBlockProps): React.ReactElement {
  const theme = useTheme();

  const { data } = useSafeQuery(CommentsByObjectIds, {
    variables: { ids: account.debt?.person?.id }
  });

  const partitions = _.partition(data?.comments, function (comment) {
    return (
      (typeof comment?.internal_information !== "undefined" &&
        (comment?.internal_information?.aim_info || comment?.internal_information?.amex_information)) ||
      (typeof comment?.data_source !== "undefined" && comment?.data_source?.type === "CREDITOR")
    );
  });

  const commentsFilteredByCreditorType = partitions[0];

  const [anchorEl, setAnchorEl] = React.useState<VirtualElement | null>(null);

  const handleClick = (evt: React.BaseSyntheticEvent) => {
    setAnchorEl(evt.currentTarget);
    setPopOverCreditorNotesOpen(true);
  };

  const areValidationsOK = !_.isNil(commentsFilteredByCreditorType);

  const creditorNotes: IComment[] | undefined = commentsFilteredByCreditorType?.map(item => {
    return {
      object_id: item?.object_id,
      inputDate: item?.comment,
      inputTimeSort:
        getDateFromComment(item?.comment) && getTimeFromComment(item?.comment)
          ? new Date(`${getDateFromComment(item?.comment)} ${getTimeFromComment(item?.comment)}`)
          : new Date(0),
      inputTime: item?.comment,
      comment: item?.comment,
      meta: {
        time_created: item?.meta?.time_created,
        id: item?.meta?.id
      }
    };
  });

  const [isPopOverCreditorNotesOpen, setPopOverCreditorNotesOpen] = React.useState(false);

  const handlerOpenCreditorNotes = (isOpen: boolean, elem: HTMLElement | null) => {
    setAnchorEl(elem);
    setPopOverCreditorNotesOpen(isOpen);
  };

  return (
    <Stack spacing={2}>
      {account.creditor?.original_brand?.name !== account.creditor?.company_name && (
        <DataRow
          titleTypography="body2"
          inline
          title="Original Creditor:"
          value={<Typography variant="body1">{account.creditor?.original_brand?.name}</Typography>}
        />
      )}
      <DataRow
        inline
        titleTypography="body2"
        title="Payments Accepted:"
        value={
          <Stack direction={"row"} spacing={1}>
            {availablePaymentMethods(account).map(method => (
              <Chip key={method} size={"small"} label={method} />
            ))}
          </Stack>
        }
      />
      {areValidationsOK && !_.isNil(creditorNotes) && creditorNotes?.length > 0 && (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"}>
            <Typography variant="body2">Creditor Notes: </Typography>
            <Box>
              <BlueDot keepPosition color={theme.palette.primary.main} />
            </Box>
          </Stack>
          <Stack>
            <Typography
              onClick={evt => handleClick(evt)}
              variant="body1"
              color={theme.palette.primary.main}
              sx={{ cursor: "pointer" }}>
              View
            </Typography>
          </Stack>
        </Stack>
      )}

      <DataRow
        inline
        title="Debt ID:"
        titleTypography="body2"
        value={
          <CopyText
            onCopy={() => onCopy("Debt ID Copied to Clipboard")}
            variant={"body1"}
            value={debt.id}
            truncate={drawerOpen ? 16 : 0}
          />
        }
      />
      {debt.person?.id && (
        <DataRow
          inline
          title="Debtor ID:"
          titleTypography="body2"
          value={
            <CopyText
              variant={"body1"}
              value={debt.person.id}
              truncate={drawerOpen ? 16 : 0}
              onCopy={() => onCopy("Debtor ID Copied to Clipboard")}
            />
          }
        />
      )}
      <ArrowedPopper
        open={isPopOverCreditorNotesOpen}
        onClose={() => handlerOpenCreditorNotes(false, null)}
        anchorEl={anchorEl}
        placement={"bottom"}>
        <Block
          paddingTopContent={"0px"}
          noBottomPadding
          paddingTitle="16px 16px 0px 16px"
          title="Creditor Notes">
          {areValidationsOK && <CreditorNotesTable comments={creditorNotes} />}
        </Block>
      </ArrowedPopper>
    </Stack>
  );
}

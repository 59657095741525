import { useApolloClient } from "@apollo/client";
import { FileDownload as FileDownloadIcon, Preview as PreviewIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import axios from "axios";
import _, { compact, isNumber, map } from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { iconForContentType } from "~/core/components/utils/content";
import type { ITableColumn } from "~/core/components/widgets/StyledDataGrid";
import { GridColumnBuilder } from "~/core/components/widgets/StyledDataGrid";
import { useSafeMutation } from "~/core/utils/apollo";
import { formatBytes } from "~/core/utils/formats";
import type {
  ICreditorTaskModel,
  IDisputeTaskModel,
  IFileRequestModel,
  IFileRequestReviewModel,
  IRemoteFileModel
} from "~/gql/types";
import { ICamunda_ValueTypeEnum } from "~/gql/types";
import { DeleteFileAction } from "./DeleteFileAction";
import { DisputeFileRequestView } from "./DisputeFileRequestView";
import { UpdateDisputeTask } from "./DisputeTaskView";

export interface DisputeFileRow {
  id: string;
  remoteFile: IRemoteFileModel;
  remoteFileId: string;
  fileRequestId: string;
  fileRequest: IFileRequestModel;
  creditorTaskId: string;
  creditorId: string;
  downloadUrl: string;
  fileName: string;
  contentType: string | undefined;
  title: string;
  description: string;
  onFileDelete: (remoteFile: IRemoteFileModel) => void;
  canMakeChanges: boolean;
}

export interface DisputeFilesViewProps {
  creditorTask: ICreditorTaskModel;
  task: IDisputeTaskModel;
}

async function downloadFile(fileName: string, url: string): Promise<void> {
  const response = await axios({
    url,
    method: "GET",
    responseType: "blob" // important
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(new Blob([response.data]));
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

function defineColumns(): ReadonlyArray<ITableColumn<DisputeFileRow>> {
  const builder = new GridColumnBuilder<DisputeFileRow>();

  builder.string("contentType", {
    width: 25,
    getter({ contentType }) {
      return contentType;
    },
    render(contentType) {
      const Icon = iconForContentType(contentType);
      return <Icon fontSize={"small"} />;
    },
    tooltip: true,
    tooltipOverflow: false,
    align: "right"
  });

  builder.string("fileName", {
    title: "File",
    getter({ fileName }) {
      return fileName;
    },
    tooltip: ({ fileName, remoteFile: { size } }) => {
      const fileSize = isNumber(size) ? `(${formatBytes(size)})` : "";
      return `${fileName} ${fileSize}`;
    },
    align: "left",
    customization: {
      cellClassName: "actionMouse"
    }
  });
  builder.actions(
    disputeFileRow => [
      <GridActionsCellItem
        icon={<FileDownloadIcon />}
        onClick={evt => {
          evt.stopPropagation();
          void downloadFile(disputeFileRow.fileName, disputeFileRow.downloadUrl);
        }}
        label="Download"
      />,
      <GridActionsCellItem
        icon={<PreviewIcon />}
        onClick={evt => {
          evt.stopPropagation();
          window.open(disputeFileRow.downloadUrl, "_blank");
        }}
        label="Open in new tab"
      />,
      disputeFileRow.canMakeChanges ? (
        <DeleteFileAction
          key={`${disputeFileRow.id}-delete`}
          disputeFile={disputeFileRow}
          onDelete={disputeFileRow.onFileDelete}
        />
      ) : undefined
    ],
    {
      align: "right",
      minWidth: 130
    }
  );

  return builder.columns;
}

const columns = defineColumns();

export function DisputeFileRequestsView({ task, creditorTask }: DisputeFilesViewProps): ReactElement {
  const fileRequests = compact(creditorTask.debt_verification_request?.requested_files);
  const client = useApolloClient();
  const [updateDisputeTask] = useSafeMutation(UpdateDisputeTask);

  return (
    <Stack spacing={2} minHeight={500}>
      {map(fileRequests, (fileRequest, idx) => {
        const fileRequestsReviews = task.fileRequestsReviews ?? [];
        const fileRequestReview = fileRequestsReviews.find(rev => rev.id === fileRequest.id);
        if (!fileRequestReview) {
          console.error(`Empty file request review for ${fileRequest.id}`, {
            fileRequest,
            task
          });
          return false;
        }
        const onReviewComplete = async (updatedReview: IFileRequestReviewModel): Promise<void> => {
          const updatedReviews = [
            ...fileRequestsReviews.filter(review => review.id !== fileRequestReview.id),
            updatedReview
          ];
          await updateDisputeTask({
            variables: {
              taskId: task.id,
              variables: [
                {
                  key: "fileRequests",
                  value: JSON.stringify(updatedReviews.map(rev => _.omit(rev, "__typename"))),
                  valueType: ICamunda_ValueTypeEnum.Object
                }
              ]
            }
          });
        };
        return (
          <DisputeFileRequestView
            key={fileRequest.id}
            order={idx + 1}
            creditorTask={creditorTask}
            task={task}
            fileRequest={fileRequest}
            fileRequestReview={fileRequestReview}
            columns={columns}
            onReviewComplete={onReviewComplete}
          />
        );
      })}
    </Stack>
  );
}

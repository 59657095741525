import { LinearProgress } from "@mui/material";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import type { ReactElement } from "react";

interface ProgressIndicatorProps {
  message?: string;
  width?: string;
}
export function ProgressIndicator({ message, width = "80%" }: ProgressIndicatorProps): ReactElement {
  return (
    <Stack spacing={2} ml={"auto"} mr={"auto"} width={width} mt={1} mb={1}>
      <LinearProgress />
      {message && <Typography align={"center"}>{message}</Typography>}
    </Stack>
  );
}

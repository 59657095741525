import { Phone as PhoneIcon } from "@mui/icons-material";
import type { ReactElement } from "react";
import { IconLink } from "./IconLink";

export interface IPhoneLinkProps {
  phoneNumber: string;
}

export function PhoneLink({ phoneNumber }: IPhoneLinkProps): ReactElement {
  return (
    <IconLink
      icon={<PhoneIcon fontSize={"small"} />}
      href={`tel:${encodeURI(phoneNumber)}`}
      title={phoneNumber}
    />
  );
}

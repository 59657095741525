import { Button } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";

export function SignedOutNavPartial(): ReactElement {
  const history = useHistory();
  return (
    <Button color="inherit" onClick={() => history.push("/")}>
      Sign In
    </Button>
  );
}

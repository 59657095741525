import { gql } from "@apollo/client";
import { Stack } from "@mui/material";
import * as _ from "lodash";
import { chain, isEmpty, isEqual } from "lodash";
import type { ReactElement } from "react";
import * as React from "react";
import { Address } from "~/core/components/domain/widgets/Address";
import { DataRow, SafeValue } from "~/core/components/widgets/DataRow";
import { EmailLink } from "~/core/components/widgets/EmailLink";
import { ExternalLink } from "~/core/components/widgets/ExternalLink";
import { PhoneLink } from "~/core/components/widgets/PhoneLink";
import { useGenericConfig } from "~/core/config/context";
import { urlToDebtorLegacy } from "~/core/utils/routing";
import type {
  IDebtModel,
  IDebtOutBalanceModel,
  IDisputeTaskModel,
  IPersonModel,
  IPersonOutModel,
  Maybe
} from "~/gql/types";
import { DebtOutBalancesQueryDocument } from "~/gql/types";
import { DaysRemaining } from "../widgets/DaysRemaining";
import { BalanceDetails } from "./BalanceDetails";
import { QueryBlock } from "./QueryBlock";

export interface ICustomerInformationBlockProps {
  person: IPersonModel;
  debt: IDebtModel;
  task: IDisputeTaskModel;
}

export const DebtOutBalancesQuery = gql(`
query DebtOutBalancesQuery($debtId: ID!, $debtorId: ID!) {
  debtOutBalance: DebtOut(id: $debtId) {
    ...DebtOutBalanceModel
  }
  personOut: PersonOut(id: $debtorId) {
    ...PersonOutModel
  }
}
`);

function CustomerInformationBlockLive({ person, debt, task }: ICustomerInformationBlockProps): ReactElement {
  const { appConfig } = useGenericConfig();

  return (
    <QueryBlock
      query={DebtOutBalancesQueryDocument}
      variables={{
        debtId: debt.id,
        debtorId: person.id
      }}
      title={`Customer`}
      subheader={person.fullName}
      titlePostfix={
        <ExternalLink href={urlToDebtorLegacy(person.id, appConfig)} title="View debtor in legacy CE" />
      }>
      {({ debtOutBalance, personOut }) => (
        <CustomerInfoContent
          task={task}
          debtOutBalance={debtOutBalance}
          person={person}
          personOut={personOut}
        />
      )}
    </QueryBlock>
  );
}

export const CustomerInformationBlock = React.memo(CustomerInformationBlockLive, isEqual);

interface CustomerInfoContentProps {
  debtOutBalance: Maybe<IDebtOutBalanceModel>;
  person: IPersonModel;
  personOut: Maybe<IPersonOutModel>;
  task: IDisputeTaskModel;
}

export function CustomerInfoContent({
  debtOutBalance,
  person,
  personOut,
  task
}: CustomerInfoContentProps): ReactElement | null {
  const address = _.first(person.addresses);
  const emails = _.filter(person.emails, e => !e?.is_unsubscribed);
  const phoneNumber = _.first(person.phones)?.phone_number;
  const email = _.first(emails)?.email;
  const transactionId = task.debt?.transaction_id;
  const dueDate = task.processInstance?.dueDate ?? task.dueDate;

  return (
    <Stack spacing={2}>
      {dueDate && <DaysRemaining dueDate={dueDate} addWords={"suffix"} />}
      {debtOutBalance && (
        <DataRow
          title="Balance at Placement:"
          value={<BalanceDetails debtBalances={debtOutBalance} />}
          inline
        />
      )}
      {address && <DataRow title="Address at Placement" value={<Address address={address} />} />}
      {transactionId && (
        <DataRow
          title="Loan # / Transaction ID"
          value={task.debt.transaction_id}
          clipboard
          truncate={16}
          symbolSafe
        />
      )}
      {personOut && task.debt?.creditor?.id && (
        <ReferencesRow creditorId={task.debt.creditor.id} personOut={personOut} />
      )}
      {email && <EmailLink email={email} />}
      {phoneNumber && <PhoneLink phoneNumber={phoneNumber} />}
    </Stack>
  );
}

interface ReferencesRowProps {
  creditorId: string;
  personOut: IPersonOutModel;
}

function ReferencesRow({ creditorId, personOut }: ReferencesRowProps): ReactElement {
  const referenceObj = chain(personOut.additional_information?.references)
    .compact()
    .filter(ref => ref.creditor_id === creditorId)
    .value()[0];
  const customerRef = referenceObj?.reference;
  const alternateRefs = referenceObj?.alternate_references;

  return (
    <>
      {customerRef && (
        <DataRow title="Customer Reference #" value={customerRef} clipboard truncate={16} symbolSafe />
      )}
      {alternateRefs && !isEmpty(alternateRefs) && (
        <DataRow
          title="Alternate References #"
          value={
            <ul style={{ margin: 0 }}>
              {alternateRefs.map(ref => (
                <li key={ref}>
                  <SafeValue>{ref}</SafeValue>
                </li>
              ))}
            </ul>
          }
        />
      )}
    </>
  );
}

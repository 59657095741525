import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { formatDate } from "~/core/utils/formats";
import { useDropzone } from "react-dropzone";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { MonetaryValue } from "../widgets/MonetaryValue";
import { IAccountModel } from "~/gql/types";
import { useCrmApi } from "~/core/contexts/ApiContext";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";
interface IBankruptcy {
  setIsBK: (value: boolean) => void;
  account: IAccountModel;
  setShowBannerSize: (value: boolean) => void;
}

export function Bankruptcy({ setIsBK, account, setShowBannerSize }: IBankruptcy): React.ReactElement {
  const theme = useTheme();

  const [isOpenDatePickerDateFiled, setOpenDatePickerDateFiled] = React.useState(false);
  const [isOpenDatePickerDateDischarged, setOpenDatePickerDateDischarged] = React.useState(false);
  const [isOpenDatePickerDateDismissed, setOpenDatePickerDateDismissed] = React.useState(false);

  const [dateFiledValue, setDateFiledValue] = React.useState<string>();
  const [isValidDateFiled, setIsValidDateFiled] = React.useState(true);

  const [dateDischargedValue, setDateDischargedValue] = React.useState<Date | string>();
  const [isValidDateDischarged, setIsValidDateDischarged] = React.useState(true);

  const [dateDismissedValue, setDateDismissedValue] = React.useState<Date | string>();
  const [isValidDateDismissed, setIsValidDateDismissed] = React.useState(true);

  const [noteValue, setNoteValue] = React.useState("");

  function validateDate(date: string | undefined): boolean {
    const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(20)\d{2}$/;
    return !date ? false : dateRegex.test(date);
  }

  const [backgroundColorDropZone, setBackgroundColorDropZone] = React.useState("transparent");

  function handleDragEnter(): void {
    if (backgroundColorDropZone !== theme.palette.error.light) setBackgroundColorDropZone("#DFE9FB");
  }

  function handleDragLeaveAndDrop(): void {
    setBackgroundColorDropZone("transparent");
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { acceptedFiles, getRootProps, open, isDragReject, fileRejections } = useDropzone({
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeaveAndDrop,
    onDrop: handleDragLeaveAndDrop,
    noClick: true,
    accept: {
      "image/*": [".jpeg", ".png"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"]
    }
  });

  React.useEffect(() => {
    if (isDragReject) {
      setBackgroundColorDropZone(theme.palette.error.light);
    }
  }, [isDragReject]);

  const [filesUpload, setFilesUpload] = React.useState<File[]>([]);

  const [filesIds, setFilesIds] = React.useState<string[]>([]);

  const [copyOfFilesExceeded, setCopyOfFilesExceeded] = React.useState<File[]>([]);

  React.useEffect(() => {
    const files: File[] = [];
    acceptedFiles.map(file => {
      files.push(file);
    });
    // one or more file is less than 48mb
    const isFilesLessThanLimit = acceptedFiles.some(item => fileSize(true, item.size));
    if (!isFilesLessThanLimit && acceptedFiles.length > 0) {
      setShowBannerSize(true);
    }
    async function sendFiles() {
      const result = callApi(files);

      const data: any = await result
        .then(data => {
          return data;
        })
        .catch(() => {
          setFilesIds([]);
          setFilesUpload([]);
        });
      setFilesIds([...filesIds, ...data]);
    }
    if (files.length > 0) {
      void sendFiles();
    }
    const copy = _.cloneDeep(filesUpload);
    setCopyOfFilesExceeded([...copy, ...files]);
    setFilesUpload([...filesUpload, ...files]);
  }, [acceptedFiles]);
  function handleDeleteFile(indexSelected: number): void {
    const filesFiltered = filesUpload.filter((item, index) => index !== indexSelected);
    const filesIdsFiltered = filesIds?.filter((item: string, index: number) => index !== indexSelected);
    setFilesUpload(filesFiltered);
    setFilesIds(filesIdsFiltered);
  }

  const [sourceOptionSelected, setSourceOptionSelected] = React.useState<string | null>("");

  const sourceOptions = [
    { label: "Phone", id: 0 },
    { label: "Email", id: 1 },
    { label: "Snail Mail", id: 2 },
    { label: "Creditor", id: 3 }
  ];

  const [chapterOptionSelected, setChapterOptionSelected] = React.useState<string | null>("");

  const bkChapter = [
    { label: "Chapter 7", id: 0 },
    { label: "Chapter 11", id: 1 },
    { label: "Chapter 13", id: 2 }
  ];

  const [attorneyOptionSelected, setAttorneyOptionSelected] = React.useState<string | null>("");

  const [bkCaseValue, setBkCaseValue] = React.useState("");
  const [valueRadio, setValueRadio] = React.useState("bkDateFiled");
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValueRadio((event.target as HTMLInputElement).value);
    const isResultValid = validateDate(dateFiledValue);
    setIsValidDateFiled(isResultValid);
    if (event.target.value === "dateNotProvided") {
      setDateFiledValue("");
    }
  };

  let numberOfFieldsCompleted = 0;

  if (!_.isNil(sourceOptionSelected) && sourceOptionSelected?.length > 0) {
    numberOfFieldsCompleted += 1;
  }

  if ((dateFiledValue && isValidDateFiled) || valueRadio === "dateNotProvided") {
    numberOfFieldsCompleted += 1;
  }

  if (noteValue.length > 0) {
    numberOfFieldsCompleted += 1;
  }

  const [isOpenModalSubmit, setOpenModalSubmit] = React.useState(false);
  const [isOpenModalLeave, setOpenModalLeave] = React.useState(false);

  const textDateFiledNotProvided = (
    <Stack direction={"row"}>
      <Typography variant="body1">
        <b>As consumer did NOT provide BK file date,</b> a BK pause has applied to following 1 applicable
        debts:{" "}
      </Typography>
    </Stack>
  );

  const crmApi = useCrmApi();
  const [progress, setProgress] = React.useState<number>(0);

  const [isUploading, setIsUploading] = React.useState(false);

  async function callApi(files: File[]) {
    const filesFilteredBySize = files.filter(item => fileSize(true, item.size));
    setIsUploading(true);
    let progressManual = 0;
    const myInterval = setInterval(() => {
      setProgress((progressManual += 1));
    }, 500);

    const result = await crmApi
      .bankruptcyControllerUpload({
        files: filesFilteredBySize
      })
      .then(data => {
        clearInterval(myInterval);
        setProgress(100);
        setTimeout(() => {
          setIsUploading(false);
        }, 1000);
        return data.data;
      });
    return result;
  }

  async function callApiUploadForm() {
    const dateFiled = new Date(dateFiledValue ?? "");
    const dateDischarged = new Date(dateDischargedValue ?? "");
    const dateDismissed = new Date(dateDismissedValue ?? "");
    const result = await crmApi
      .bankruptcyControllerUploadForm({
        body: {
          accountNote: noteValue,
          sourceType: sourceOptionSelected ?? "",
          ...(bkCaseValue.length > 0 && { caseNumber: bkCaseValue }),
          ...(chapterOptionSelected !== null &&
            chapterOptionSelected.length > 0 && { chapter: chapterOptionSelected }),
          debtIds: [account.debt?.id ?? ""],
          personId: account.debt?.person?.id ?? "",
          ...(filesIds.length > 0 && { remoteFileIds: filesIds }),
          ...(!isNaN(dateFiled.getFullYear()) && {
            dateFiled: {
              year: isNaN(dateFiled.getFullYear()) ? undefined : dateFiled.getFullYear(),
              day: isNaN(dateFiled.getDate()) ? undefined : dateFiled.getDate(),
              month: isNaN(dateFiled.getMonth() + 1) ? undefined : dateFiled.getMonth() + 1
            }
          }),
          ...(!isNaN(dateDischarged.getFullYear()) && {
            dateDischarged: {
              year: isNaN(dateDischarged.getFullYear()) ? undefined : dateDischarged.getFullYear(),
              day: isNaN(dateDischarged.getDate()) ? undefined : dateDischarged.getDate(),
              month: isNaN(dateDischarged.getMonth() + 1) ? undefined : dateDischarged.getMonth() + 1
            }
          }),
          ...(!isNaN(dateDismissed.getFullYear()) && {
            dateDismissed: {
              year: isNaN(dateDismissed.getFullYear()) ? undefined : dateDismissed.getFullYear(),
              day: isNaN(dateDismissed.getDate()) ? undefined : dateDismissed.getDate(),
              month: isNaN(dateDismissed.getMonth() + 1) ? undefined : dateDismissed.getMonth() + 1
            }
          })
        }
      })
      .then(data => {
        setIsBK(false);
        return data.data;
      })
      .catch(error => console.log(error));
    return result;
  }

  function fileSize(lessThan: boolean, itemSize: number) {
    const size = 48000000;
    if (lessThan) {
      return itemSize < size;
    } else {
      return itemSize > size;
    }
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack pl={0} pt={2.875} zIndex={0}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            borderBottom={`1px solid ${theme.palette.grey[700]}`}
            pb={1.94}
            pr={3.625}
            mr={2.75}>
            <Stack direction={"row"}>
              <ArrowBackIcon
                sx={{ color: theme.palette.primary.dark, cursor: "pointer" }}
                onClick={() => setOpenModalLeave(true)}
              />
              <Typography ml={2} variant="h3">
                Log a Bankruptcy
              </Typography>
            </Stack>
            <Stack direction={"row"} position="relative" top={"-8px"}>
              <Box
                mr={2}
                mt={0.5}
                sx={{
                  borderRadius: 6,
                  width: "130px",
                  height: "30px",
                  color: theme.palette.common.white,
                  backgroundColor: numberOfFieldsCompleted === 3 ? "#4CAF50" : "#ED6C02"
                }}>
                <Typography pl={2} mt={0.5}>{`${numberOfFieldsCompleted} of 3 Required*`}</Typography>
              </Box>
              <Button
                variant="contained"
                onClick={() => setOpenModalSubmit(true)}
                disabled={numberOfFieldsCompleted !== 3 || (progress !== 100 && filesUpload.length > 0)}>
                SUBMIT
              </Button>
            </Stack>
          </Stack>
          <Stack mb={7.125}>
            <Grid pt={3.55} container spacing={4} pr={2}>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={event => {
                    const value = event.target as HTMLElement;
                    setSourceOptionSelected(value.textContent);
                  }}
                  disablePortal
                  options={sourceOptions}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Select One"
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Source*"
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  onChange={event => {
                    const value = event.target as HTMLElement;
                    setChapterOptionSelected(value.textContent);
                  }}
                  disablePortal
                  options={bkChapter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Select One"
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="BK Chapter"
                      variant="filled"
                      sx={{ width: "93%" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "87%" }}
                  value={bkCaseValue}
                  onChange={e => {
                    const regexOnlyNumbers = /[^A-Za-z0-9]+/;
                    if (!regexOnlyNumbers.test(e.target.value)) {
                      setBkCaseValue(e.target.value);
                    }
                  }}
                  label="BK Case #"
                  variant="filled"
                />
              </Grid>
            </Grid>
            <Grid pt={3.55} container spacing={4} direction={"row"}>
              <Grid item xs={4} direction={"row"}>
                <Stack sx={{ backgroundColor: theme.palette.grey[100] }} p={2}>
                  <FormControl>
                    <RadioGroup name="radio-buttons-group" value={valueRadio} onChange={handleChangeRadio}>
                      <FormControlLabel
                        value="bkDateFiled"
                        control={<Radio />}
                        sx={{
                          ".MuiTypography-root": {
                            width: "100%"
                          },
                          ".MuiFormControl-root": {
                            width: "100%"
                          }
                        }}
                        label={
                          <Stack direction={"row"}>
                            <MobileDatePicker
                              open={isOpenDatePickerDateFiled}
                              onOpen={() => setOpenDatePickerDateFiled(true)}
                              onClose={() => setOpenDatePickerDateFiled(false)}
                              inputFormat="MM/dd/yyyy"
                              value={dateFiledValue}
                              onChange={value => {
                                const dateFormatted = formatDate(value);
                                const isResultValid = validateDate(dateFormatted);
                                setIsValidDateFiled(isResultValid);
                                setDateFiledValue(dateFormatted);
                              }}
                              renderInput={params => (
                                <TextField
                                  width={"160%"}
                                  variant="filled"
                                  value={dateFiledValue}
                                  onChange={event => {
                                    const isResultValid = validateDate(event.target.value);
                                    setIsValidDateFiled(isResultValid);
                                    setDateFiledValue(event.target.value);
                                  }}
                                  label={
                                    dateFiledValue !== undefined &&
                                    dateFiledValue?.length > 0 &&
                                    valueRadio !== "dateNotProvided" &&
                                    !isValidDateFiled
                                      ? "Error"
                                      : "BK Date Filed (MM/DD/YYYY)*"
                                  }
                                  error={
                                    dateFiledValue !== undefined &&
                                    dateFiledValue?.length > 0 &&
                                    valueRadio !== "dateNotProvided" &&
                                    !isValidDateFiled
                                  }
                                  helperText={
                                    dateFiledValue !== undefined &&
                                    dateFiledValue?.length > 0 &&
                                    valueRadio !== "dateNotProvided" &&
                                    !isValidDateFiled
                                      ? "You must enter a valid date"
                                      : ""
                                  }
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  placeholder={"Select a date"}
                                />
                              )}
                            />
                            <CalendarTodayIcon
                              sx={{ position: "relative", top: "12px", right: "-12px" }}
                              color="primary"
                              onClick={e => setOpenDatePickerDateFiled(true)}
                            />
                          </Stack>
                        }
                      />
                      <FormControlLabel
                        value="dateNotProvided"
                        control={<Radio />}
                        label="Date not Provided*"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
                direction={"row"}
                pl={3.5}
                mt={4}
                sx={{
                  ".MuiFormControl-root": {
                    width: "90%"
                  }
                }}>
                <MobileDatePicker
                  open={isOpenDatePickerDateDischarged}
                  onOpen={() => setOpenDatePickerDateDischarged(true)}
                  onClose={() => setOpenDatePickerDateDischarged(false)}
                  inputFormat="MM/dd/yyyy"
                  value={dateDischargedValue}
                  onChange={value => setDateDischargedValue(formatDate(value))}
                  renderInput={params => (
                    <TextField
                      variant="filled"
                      value={dateDischargedValue}
                      onChange={event => {
                        const isResultValid =
                          event.target.value === "" ? true : validateDate(event.target.value);
                        setIsValidDateDischarged(isResultValid);
                        setDateDischargedValue(event.target.value);
                      }}
                      label={!isValidDateDischarged ? "Error" : "BK Date Discharged (MM/DD/YYYY)"}
                      error={!isValidDateDischarged}
                      helperText={!isValidDateDischarged ? "You must enter a valid date" : ""}
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder={"Select a date"}
                    />
                  )}
                />

                <CalendarTodayIcon
                  onClick={e => setOpenDatePickerDateDischarged(true)}
                  sx={{ cursor: "pointer", position: "relative", top: "12px", left: "13px" }}
                  color="primary"
                />
              </Grid>
              <Grid
                item
                xs={4}
                direction={"row"}
                position="relative"
                left="22px"
                pl={"0px !important"}
                mt={4}
                sx={{
                  ".MuiFormControl-root": {
                    width: "78%"
                  }
                }}>
                <MobileDatePicker
                  open={isOpenDatePickerDateDismissed}
                  onOpen={() => setOpenDatePickerDateDismissed(true)}
                  onClose={() => setOpenDatePickerDateDismissed(false)}
                  inputFormat="MM/dd/yyyy"
                  value={dateDismissedValue}
                  onChange={value => {
                    setDateDismissedValue(formatDate(value));
                  }}
                  renderInput={params => (
                    <TextField
                      variant="filled"
                      value={dateDismissedValue}
                      onChange={event => {
                        const isResultValid =
                          event.target.value === "" ? true : validateDate(event.target.value);
                        setIsValidDateDismissed(isResultValid);
                        setDateDismissedValue(event.target.value);
                      }}
                      label={!isValidDateDismissed ? "Error" : "BK Date Dismissed (MM/DD/YYYY)"}
                      error={!isValidDateDismissed}
                      helperText={!isValidDateDismissed ? "You must enter a valid date" : ""}
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder={"Select a date"}
                    />
                  )}
                />

                <CalendarTodayIcon
                  onClick={e => setOpenDatePickerDateDismissed(true)}
                  sx={{ cursor: "pointer", position: "relative", top: "12px", left: "13px" }}
                  color="primary"
                />
              </Grid>
            </Grid>

            <Grid direction={"row"} pt={3.55} container spacing={4}>
              <Grid item xs={7} lg={5}>
                <TextField
                  fullWidth
                  label="Add an Account Note (will apply to all applicable customer accounts)*"
                  variant="filled"
                  value={noteValue}
                  onChange={e => setNoteValue(e.target.value)}
                />
              </Grid>
            </Grid>
          </Stack>

          <Box borderBottom={`1px solid ${theme.palette.grey[800]}`} mr={3} mt={4.25} mb={3.25}>
            <Typography pb={1} variant="h4">
              File(s)
            </Typography>
          </Box>

          <Box
            className="container"
            width="98.5%"
            height={"155px"}
            border={`2px dotted ${theme.palette.grey[700]}`}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ backgroundColor: backgroundColorDropZone }}>
            <Box {...getRootProps({ className: "dropzone" })} width="100%" height={"100%"} textAlign="right">
              {isDragReject ? (
                <p
                  style={{ textAlign: "center", marginBottom: "0px", marginTop: "53px" }}
                  color={theme.palette.grey[700]}>
                  Some file(s) are rejected
                  {fileRejections.map(item => {
                    return <Typography sx={{ color: theme.palette.grey[700] }}>{item.file.name}</Typography>;
                  })}
                </p>
              ) : (
                <>
                  <p
                    style={{ textAlign: "center", marginBottom: "0px", marginTop: "53px" }}
                    color={theme.palette.grey[700]}>
                    Drop bankruptcy file(s) here or{" "}
                    <span style={{ cursor: "pointer", color: theme.palette.primary.main }} onClick={open}>
                      click
                    </span>{" "}
                    to upload.
                  </p>
                  <p style={{ textAlign: "center", marginTop: "0px" }} color={theme.palette.grey[700]}>
                    File size limit is 48MB(per file)
                  </p>
                </>
              )}
            </Box>
          </Box>

          <Box mb={30} mt={1}>
            {isUploading && (
              <Stack>
                <LinearProgress
                  sx={{ width: "250px", marginLeft: "15px", marginRight: "15px", marginTop: "10px" }}
                  variant="determinate"
                  value={progress}
                />
              </Stack>
            )}

            {filesUpload
              .filter(item => fileSize(true, item.size))
              .map((file: File, index: number) => {
                return (
                  <Stack key={index} direction={"row"} mt={2} sx={{ lineHeight: "2" }}>
                    {!isUploading && (
                      <Stack mr={1}>
                        <CheckIcon sx={{ color: theme.palette.success.main }} />
                      </Stack>
                    )}
                    <Stack color={theme.palette.primary.dark}>{file.name}</Stack>
                    {!isUploading && (
                      <Stack direction={"row"}>
                        <OverflowTooltip title={"Remove file"}>
                          <RemoveCircleOutlineIcon
                            color={"error"}
                            sx={{ cursor: "pointer", ml: 1 }}
                            onClick={() => handleDeleteFile(index)}
                          />
                        </OverflowTooltip>
                      </Stack>
                    )}
                  </Stack>
                );
              })}

            {copyOfFilesExceeded
              .filter(item => fileSize(false, item.size))
              .map((file: File, index: number) => {
                return (
                  <Stack key={index} direction={"row"} mt={2} sx={{ lineHeight: "2" }}>
                    <Stack direction={"row"} mr={1}>
                      <WarningIcon sx={{ color: "#DF6B05" }} />
                    </Stack>
                    <Stack color={"#929597"}>{file.name}</Stack>
                    <Typography ml={2.125} sx={{ color: theme.palette.grey[700], lineHeight: "2" }}>
                      Cannot upload due to file size restriction.
                    </Typography>
                  </Stack>
                );
              })}
          </Box>

          <Stack pl={1} direction={"row"} pb={1}>
            <Typography variant="body2">*= Required field</Typography>
          </Stack>
        </Stack>
      </LocalizationProvider>

      <Modal
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={isOpenModalSubmit}
        onClose={() => setOpenModalSubmit(false)}>
        <Stack
          width={"600px"}
          p={2}
          height={dateFiledValue !== undefined && dateFiledValue?.length > 0 ? "280px" : "300px"}
          sx={{ backgroundColor: theme.palette.common.white }}>
          <Stack direction={"row"} justifyContent="space-between">
            <Typography variant="h4" fontWeight={600}>
              Are you sure you want to Submit?
            </Typography>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setOpenModalSubmit(false)} />
          </Stack>
          <Stack pt={2.5}>
            <Typography variant="body1">
              Once the form is submitted, no changes can be made and this action cannot be undone.
            </Typography>
          </Stack>
          <Stack pt={2.5}>
            <Typography variant="body1">
              {dateFiledValue !== undefined && dateFiledValue?.length > 0 && valueRadio !== "dateNotProvided"
                ? "This will apply to the following 1 applicable debts (including this one):"
                : textDateFiledNotProvided}
            </Typography>
          </Stack>
          <Stack pt={1}>
            <ul>
              <li>
                <Stack direction={"row"}>
                  <Typography mr={1}>{account.creditor?.company_name}</Typography>(
                  <MonetaryValue
                    variant="body1"
                    monetaryAmount={{ amount: account.balance?.amount, currency: account.balance?.currency }}
                  />
                  )
                </Stack>
              </li>
            </ul>
          </Stack>
          <Stack pt={5.5} direction={"row"} justifyContent="flex-end">
            <Button onClick={() => setOpenModalSubmit(false)} variant="outlined">
              {" "}
              BACK TO BANKRUPTCY PAGE
            </Button>
            <Button
              onClick={async () => {
                const result = callApiUploadForm();

                const data: any = await result
                  .then(data => {
                    console.log("DATAA", data);
                    return data;
                  })
                  .catch(error => {
                    console.log("error", error);
                  });
                setOpenModalSubmit(false);
                setIsBK(false);
              }}
              sx={{ ml: 2 }}
              variant="contained">
              SUBMIT
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={isOpenModalLeave}
        onClose={() => setOpenModalLeave(false)}>
        <Stack width={"500px"} p={2} height={"230px"} sx={{ backgroundColor: theme.palette.common.white }}>
          <Stack direction={"row"} justifyContent="space-between">
            <Typography variant="h4" fontWeight={600}>
              Leave this Page?
            </Typography>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setOpenModalLeave(false)} />
          </Stack>
          <Stack pt={2.5}>
            <Typography variant="body1">You have unsaved changes.</Typography>
          </Stack>
          <Stack pt={2.5}>
            <Typography variant="body1">Clicking [Stay] will return to the Bankruptcy page.</Typography>
          </Stack>
          <Stack pt={1}>
            <Typography variant="body1">
              Clicking [Leave] will exit the Bankruptcy page with NO changes saved.
            </Typography>
          </Stack>
          <Stack pt={3.5} direction={"row"} justifyContent="flex-end">
            <Button onClick={() => setOpenModalLeave(false)} variant="outlined">
              Stay
            </Button>
            <Button onClick={() => setIsBK(false)} sx={{ ml: 2 }} variant="contained">
              Leave
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}

import * as _ from "lodash";
import type { ICamunda_KeyValuePair, ICamunda_ProcessInstance } from "~/gql/types";
import type { StrictTypedTypePolicies } from "../../gql/apollo-helpers";
import { defineReader } from "../utils/policies";

export const CAMUNDA_PROCESS_INSTANCE_POLICY: StrictTypedTypePolicies["camunda_ProcessInstance"] = {
  fields: {
    variables: {
      keyArgs: false,
      merge(existing: ICamunda_KeyValuePair[] = [], incoming: ICamunda_KeyValuePair[]) {
        return _.uniqBy([...existing, ...incoming], "key");
      }
    },
    dueDate: defineReader<ICamunda_ProcessInstance, Date>(({ readCamundaVar }) => {
      const rawDue = readCamundaVar("dueDate");
      if (_.isString(rawDue) && !_.isEmpty(rawDue)) {
        return new Date(rawDue);
      } else {
        return null;
      }
    }),
    lastUpdateDate: defineReader<ICamunda_ProcessInstance, Date>(({ readPrimitive }) => {
      const lastHistoricActivityAt = readPrimitive("lastHistoricActivityAt");
      if (lastHistoricActivityAt) {
        return new Date(lastHistoricActivityAt);
      } else {
        return null;
      }
    })
  }
};

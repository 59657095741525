import { Avatar } from "@mui/material";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import type { Variant } from "@mui/material/styles/createTypography";
import { differenceInDays, isBefore } from "date-fns";
import pluralize from "pluralize";
import type { ReactElement } from "react";
import { useCurrentDate } from "~/core/components/utils/useCurrentDate";
import { OverflowTooltip } from "~/core/components/widgets/OverflowTooltip";
import { formatDate } from "~/core/utils/formats";

export interface IDaysRemainingProps {
  dueDate: Date;
  addWords?: "embed" | "suffix" | undefined;
  digitsVariant?: Variant;
  wordsVariant?: Variant;
}

export function DaysRemaining({
  dueDate,
  addWords,
  digitsVariant = "h4",
  wordsVariant = "h5"
}: IDaysRemainingProps): ReactElement {
  const theme = useTheme();
  const currentDate = useCurrentDate();
  const daysRemaining = differenceInDays(dueDate, currentDate);
  let bgcolor = "transparent";
  if (daysRemaining < 3) {
    bgcolor = theme.palette.error.dark;
  } else if (daysRemaining < 6) {
    bgcolor = theme.palette.error.main;
  } else if (daysRemaining < 10) {
    bgcolor = theme.palette.error.light;
  }
  const fontColor =
    bgcolor != "transparent" ? theme.palette.getContrastText(bgcolor) : theme.palette.text.primary;

  let words: string | undefined;
  const daysWord = pluralize("Day", Math.abs(daysRemaining));

  switch (addWords) {
    case "embed":
      words = isBefore(dueDate, new Date()) ? `${daysWord} Past` : daysWord;
      break;
    case "suffix":
      words = isBefore(dueDate, new Date()) ? `${daysWord} Past Due` : `${daysWord} Left`;
      break;
    default:
      break;
  }

  const inner = (
    <OverflowTooltip title={formatDate(dueDate)}>
      <Avatar
        sx={{
          color: fontColor,
          bgcolor,
          width: "100%",
          padding: "0 0.5ex 0 0.5ex",
          minWidth: "2rem"
        }}
        variant="rounded">
        <Stack direction={"row"} spacing={1} justifyItems={"center"}>
          <Typography variant={digitsVariant}>
            {addWords ? Math.abs(daysRemaining) : daysRemaining}
          </Typography>
          {addWords === "embed" && <Typography variant={wordsVariant}>{words}</Typography>}
        </Stack>
      </Avatar>
    </OverflowTooltip>
  );

  if (addWords === "suffix") {
    return (
      <Stack
        direction={"row"}
        alignContent={"center"}
        alignItems={"baseline"}
        spacing={1}
        sx={{ verticalAlign: "center" }}>
        {inner}
        <Typography variant={wordsVariant}>{words}</Typography>
      </Stack>
    );
  } else {
    return inner;
  }
}

import { AppBar, Box, Toolbar } from "@mui/material";
import type { ReactElement } from "react";
import { useSession } from "~/core/contexts/UserContext";
import { SignedInNavPartial } from "./SignedInNavPartial";
import { SignedOutNavPartial } from "./SignedOutNavPartial";
import { useTheme } from "@mui/material/styles";
import { Logo } from "./Logo";
import { useHistory } from "react-router";

interface ITopNavBar {
  setEmptyResults?: () => void;
}

export function TopNavBar({ setEmptyResults }: ITopNavBar): ReactElement | null {
  const { isAuthenticated } = useSession();
  const theme = useTheme();
  const history = useHistory();

  const url = window.location.href;
  function goTo() {
    if (url.search("search") !== -1 || url.search("authenticate") !== -1) {
      history.push(`/app/accounts/search/advance`);
      setEmptyResults?.();
    } else {
      history.push(`/`);
    }
  }
  return (
    <AppBar position="static">
      <Toolbar sx={{ backgroundColor: theme.palette.primary.dark }}>
        <Box onClick={() => goTo()}>
          <Logo size="small" sx={{ position: "absolute", left: "-45px", top: "12px", cursor: "pointer" }} />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {isAuthenticated ? <SignedInNavPartial /> : <SignedOutNavPartial />}
      </Toolbar>
    </AppBar>
  );
}

import * as React from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Block } from "../blocks/Block";
import DoubleArrowDown from "../../../../core/assets/chevron-double-down-regular.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { formatTimestamp } from "~/core/utils/formats";
import _ from "lodash";
import { OverflowTooltip } from "../../widgets/OverflowTooltip";
import type { Variant } from "@mui/material/styles/createTypography";
import type { IAccountModel, ICommentsByObjectIds } from "~/gql/types";
import { gql } from "@apollo/client";

export interface IAccountNotesProps {
  closeAccountNotes: () => void;
  openAccountAddComment: (e: React.SyntheticEvent) => void;
  isOpenAddComment: boolean;
  titleTypography?: Variant;
  refetchComments: () => boolean;
  width: number;
  accountNotesWidth: string | undefined;
  data: ICommentsByObjectIds | undefined;
  refetchCommentsQuery: () => void;
}

export const CommentsQuery = gql(`
  query CommentsByObjectIds($ids: [String!]) {
    comments: CommentsByObjectIds(ids: $ids) {
      internal_information{
        aim_info {
          creditor_id
        }
        amex_information {
          flag
        }
      }
      user_name
      comment
      data_source {
        type
      }
      meta {
        time_created
        id
      }
    }
  }
`);

export function AccountNotes({
  closeAccountNotes,
  openAccountAddComment,
  isOpenAddComment,
  titleTypography,
  refetchComments,
  width,
  accountNotesWidth,
  data,
  refetchCommentsQuery
}: IAccountNotesProps): React.ReactElement {
  React.useEffect(() => {
    void refetchCommentsQuery();
  }, [refetchComments]);

  let comments = data?.comments?.slice();

  const partitions = _.partition(comments, function (comment) {
    return (
      (typeof comment?.internal_information !== "undefined" &&
        (comment?.internal_information?.aim_info || comment?.internal_information?.amex_information)) ||
      (typeof comment?.data_source !== "undefined" && comment?.data_source?.type === "CREDITOR")
    );
  });

  comments = partitions[1];

  comments = _.compact(_.orderBy(comments ?? [], entry => entry?.meta?.time_created)).reverse();
  const theme = useTheme();
  const [canExpand, setCanExpand] = React.useState([
    {
      isExpanded: false,
      index: -1
    }
  ]);

  const handleClickClose = (evt: React.BaseSyntheticEvent) => {
    closeAccountNotes();
  };

  function getButtons(): React.ReactElement {
    return (
      <Stack direction="row" marginLeft={"auto !important"} alignItems="center">
        <Stack>
          <ControlPointIcon
            onClick={e => openAccountAddComment(e)}
            sx={{
              marginRight: 1.28,
              marginLeft: 1.28,
              cursor: isOpenAddComment ? "unset" : "pointer",
              color: isOpenAddComment ? theme.palette.grey[800] : theme.palette.primary.dark
            }}
          />
        </Stack>
        <Stack onClick={handleClickClose} sx={{ cursor: "pointer" }}>
          <img src={DoubleArrowDown} width="24px" height="18px" alt="Open Account Notes" />
        </Stack>
      </Stack>
    );
  }
  const threeColumnLayoutBreakpoint = 1700;
  return (
    <>
      <Paper
        sx={{
          width: accountNotesWidth,
          position: "absolute",
          bottom: "-5px",
          right: 0,
          overflowY: "auto",
          minHeight: "195px",
          maxHeight: "calc(100vh - 40px)"
        }}>
        <Block
          title={"Account Notes"}
          handleClickHeader={closeAccountNotes}
          titleTypography={titleTypography ? titleTypography : "h4"}
          paddingTitle={width >= threeColumnLayoutBreakpoint ? "9px 10px" : "10px 16px"}
          paddingTopContent={"0px"}
          customBackground={theme.palette.grey[500]}
          titlePostfix={getButtons()}
          isCard
          customColorDivider={theme.palette.grey[200]}>
          <Stack direction="row" mb={1.375}>
            <Typography fontWeight="600">All ({comments?.length})</Typography>
          </Stack>
          <Stack>
            {comments?.map((item, index) => {
              const truncatedComment = item?.comment?.substring(0, 123);
              const isCommentExpanded = canExpand.find(
                (item: { isExpanded: boolean; index: number }) => item.isExpanded && item.index === index
              );

              const dateFormated = formatTimestamp(item?.meta?.time_created, "MM/dd/yy hh:mm a");

              const date = dateFormated.slice(0, 8);
              const time = dateFormated.slice(8, 17);
              const newDateWithSpaces = date + Array(1).fill("\xa0").join("") + time;
              let truncatedName: string | undefined | null;
              if (typeof item?.user_name === "string") {
                truncatedName = _.truncate(item?.user_name, {
                  length: 15,
                  omission: "…"
                });
              } else {
                truncatedName = item?.user_name;
              }
              return (
                <Stack
                  key={`${index} + ${item?.comment}`}
                  direction="row"
                  width="100%"
                  position={"relative"}
                  borderBottom={`1px solid ${theme.palette.grey[200]}`}
                  pb={
                    comments && index === comments?.length - 1
                      ? width >= threeColumnLayoutBreakpoint
                        ? 5
                        : 2.125
                      : 1
                  }>
                  <Stack direction="column" width={"100%"}>
                    <Stack
                      pt={index > 0 ? 1 : 0}
                      justifyContent={"space-between"}
                      direction={"row"}
                      width={"100%"}>
                      {typeof item?.user_name === "string" ? (
                        <OverflowTooltip title={item?.user_name}>
                          <Typography mr={0.5} mb={1} variant={"body2"}>
                            {truncatedName}
                            {/* For when the BE is ready  {item.isThirdParty && (
                              <Box
                                sx={{
                                  backgroundColor: theme.palette.grey[300],
                                  padding: 0,
                                  color: theme.palette.common.white,
                                  fontSize: "12px",
                                  width: "25px",
                                  height: "17px",
                                  textAlign: "center",
                                  position: "relative",
                                  top: "2px"
                                }}>
                                3rd
                              </Box>
                            )} */}
                          </Typography>
                        </OverflowTooltip>
                      ) : (
                        <Typography mb={1} variant={"body2"}>
                          {truncatedName}
                        </Typography>
                      )}
                      <Typography variant={"body1"}>{newDateWithSpaces}</Typography>
                    </Stack>
                    {!isCommentExpanded && (
                      <Stack direction="row">
                        <Typography sx={{ wordWrap: "break-word" }}>
                          {truncatedComment}
                          {item?.comment && item?.comment?.length > 124 && (
                            <Stack direction={"column"} alignItems="flex-start">
                              <Button
                                onClick={() =>
                                  setCanExpand(oldArray => [...oldArray, { isExpanded: true, index: index }])
                                }
                                variant="text">
                                <Typography variant="body1" color={theme.palette.primary.dark}>
                                  [More]
                                </Typography>
                              </Button>
                            </Stack>
                          )}
                        </Typography>
                      </Stack>
                    )}

                    {isCommentExpanded && (
                      <Stack>
                        <Typography sx={{ wordWrap: "break-word" }}>{item?.comment}</Typography>
                        <Typography>
                          <Button
                            onClick={() => {
                              const arrayCanExpand = [...canExpand];
                              const indexToUpdate = arrayCanExpand.findIndex(
                                item => item.isExpanded && item.index === index
                              );
                              arrayCanExpand[indexToUpdate] = { isExpanded: false, index: index };
                              setCanExpand(arrayCanExpand);
                            }}
                            variant="text">
                            <Typography variant="body1" color={theme.palette.primary.dark}>
                              [Less]
                            </Typography>
                          </Button>
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Stack mt={index > 0 ? 2 : 0} position={"absolute"} right={0} bottom="13px">
                    <Stack sx={{ cursor: "pointer" }}>
                      <CopyToClipboard text={item?.comment ?? ""}>
                        <ContentCopyIcon fontSize={"small"} />
                      </CopyToClipboard>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
            {comments?.length === 0 && <Typography mb={2}>No Account Notes available.</Typography>}
          </Stack>
        </Block>
      </Paper>
    </>
  );
}

import * as React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Autocomplete, Button, Modal, Snackbar, TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { MonetaryValue } from "../widgets/MonetaryValue";
import { ExternalLink } from "../../widgets/ExternalLink";
import type { IAccountModel, IPaymentPlanOutModel } from "~/gql/types";
import Box from "@mui/system/Box";
import { formatTimestamp } from "~/core/utils/formats";
import { useCrmApi } from "~/core/contexts/ApiContext";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";

const optionsCancelPlan = [
  {
    title: "New Plan Requested",
    value: "NEW_PLAN_REQUEST"
  },
  {
    title: "Missed/Failed Payment(s)",
    value: "PLAN_BREAKAGE"
  },
  {
    title: "Customer Requested Cancellation",
    value: "REQUESTED_PLAN_CANCELLATION"
  },
  {
    title: "Other",
    value: "MISC_REMOVAL"
  }
];

interface IViewPlan {
  setViewPlan: (value: boolean) => void;
  paymentPlanOut: IPaymentPlanOutModel | undefined | null;
  refetchDebOutBalanceAndPaymentPlanOut: () => void;
  debtStateId: string | null | undefined;
  account: IAccountModel;
  width: number;
}

export function ViewPlan({
  setViewPlan,
  paymentPlanOut,
  refetchDebOutBalanceAndPaymentPlanOut,
  debtStateId,
  account,
  width
}: IViewPlan): React.ReactElement {
  const theme = useTheme();

  const useStyles = makeStyles({
    button: {
      "&:disabled": {
        color: theme.palette.common.white,
        boxShadow: "0px 1px 0px 0px rgb(0 0 0 / 75%)"
      }
    }
  });
  const classButton = useStyles();
  const [isOpenModalCancelPaymentPlan, setOpenModalCancelPaymentPlan] = React.useState(false);

  const [isOpenModalLeavePage, setOpenModalLeavePage] = React.useState(false);

  const [selectedValueOnCancelOptions, setSelectedValueOnCancelOptions] = React.useState<{
    title: string;
    value: string;
  } | null>({ title: "", value: "" });

  function handleCancelPaymentPlan(): void {
    setOpenModalCancelPaymentPlan(true);
  }

  const remainingBalance = paymentPlanOut?.installments.reduce(
    (prevValue, currentValue) => Number(prevValue) + Number(currentValue?.amount_due?.amount),
    0
  );

  const options = [
    {
      label: "Plan Type",
      value:
        paymentPlanOut?.application?.discount?.amount && paymentPlanOut?.application?.discount?.amount > 0
          ? "Settlement in Full (SIF)"
          : "Pay in Full (PIF)"
    },
    {
      label: "Total Amount to Pay",
      value: paymentPlanOut?.application?.amount_to_pay?.amount
    },
    {
      label: "Amount Paid So Far",
      value:
        paymentPlanOut?.application?.amount_to_pay?.amount &&
        remainingBalance &&
        paymentPlanOut?.application?.amount_to_pay?.amount - remainingBalance
    },
    {
      label: "Remaining Amount to Pay",
      value: remainingBalance
    }
  ];

  let settlementPercent;

  if (paymentPlanOut?.application?.discount?.amount && paymentPlanOut?.application?.discount?.amount > 0) {
    settlementPercent =
      100 -
      100 *
        (Number(paymentPlanOut?.application?.discount?.amount) /
          (Number(paymentPlanOut?.application?.amount_to_pay?.amount) +
            Number(paymentPlanOut?.application?.discount?.amount)));
    settlementPercent = Math.round(settlementPercent);
    options.push({ label: "Settlement %", value: settlementPercent });
  }

  const creditCard = {
    label: paymentPlanOut?.payment_method_info?.card_info?.card_type,
    cardNumber: paymentPlanOut?.payment_method_info?.card_info?.last_four_digits,
    date: `${
      paymentPlanOut?.payment_method_info?.card_info?.expiration_month
    }/${paymentPlanOut?.payment_method_info?.card_info?.expiration_year?.slice(2)}`
  };

  const isNotSelectedValue =
    selectedValueOnCancelOptions?.title === undefined || selectedValueOnCancelOptions?.title === "";

  const [isSnackOpenCancelPaymentPlan, setSnackOpenCancelPaymentPlan] = React.useState(false);
  const crmApi = useCrmApi();

  const handleSubmitCancelPaymentPlan = async (): Promise<void> => {
    setOpenModalCancelPaymentPlan(false);
    setSnackOpenCancelPaymentPlan(true);
    await crmApi.paymentPlansControllerCancelPaymentPlan({
      debtId: account?.debt?.id ?? "",
      debtStateId: debtStateId ?? "",
      reason: selectedValueOnCancelOptions?.value ?? ""
    });
    setViewPlan(false);

    refetchDebOutBalanceAndPaymentPlanOut();
  };

  function getButtonsOfViewPlan(): React.ReactElement {
    return (
      <Stack
        direction={"row"}
        justifyContent="flex-end"
        pb={2}
        borderBottom={width <= 1440 && width >= 1080 ? `1px solid ${theme.palette.grey[700]}` : "unset"}>
        <Stack direction="row" mt={width <= 1440 && width >= 1080 ? 2 : 0}>
          <Button
            size={width <= 1080 ? "medium" : "large"}
            variant="text"
            onClick={handleCancelPaymentPlan}
            sx={{ marginRight: 3.25, marginLeft: width > 1440 ? 3.25 : 0 }}>
            CANCEL PAYMENT PLAN
          </Button>
          <Button size={width <= 1080 ? "medium" : "large"} variant="outlined" disabled>
            EMAIL EXISTING PLAN
          </Button>
          <Button
            size={width <= 1080 ? "medium" : "large"}
            sx={{ margin: "0px 26px" }}
            disabled
            variant="outlined">
            SETUP AUTOMATIC BILLING
            <ExternalLink color={theme.palette.grey[300]} href={""} title="" />
          </Button>
          <Button
            size={width <= 1080 ? "medium" : "large"}
            disabled
            sx={{ backgroundColor: theme.palette.grey[100], padding: "0px 20px", marginRight: 2.375 }}>
            SEND UPDATED PLAN
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Stack direction={"row"} borderBottom={width > 1440 ? `1px solid ${theme.palette.grey[700]}` : "unset"}>
        <Stack direction={"row"} mt={width > 1440 ? 1 : 0}>
          <ArrowBackIcon
            sx={{ color: theme.palette.primary.dark, cursor: "pointer" }}
            onClick={() => setOpenModalLeavePage(true)}
          />
          <Typography ml={2} variant="h3">
            Current Payment Plan
          </Typography>
        </Stack>
        {width > 1440 && getButtonsOfViewPlan()}
      </Stack>
      {width <= 1440 && width >= 1080 && getButtonsOfViewPlan()}
      <Stack direction={"row"}>
        <Stack direction={"column"} pt={4.28}>
          {options.map(item => {
            return (
              <Stack key={item.label} width={"198px"} direction={"column"} mb={3.75}>
                <Stack
                  direction={"column"}
                  textAlign={"center"}
                  justifyContent="space-between"
                  sx={{
                    height: "110px",
                    backgroundColor: theme.palette.grey[500],
                    boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 75%)"
                  }}>
                  <>
                    <Typography pt={1.375} px={1} fontWeight={600} variant="subtitle1">
                      {item.label}
                    </Typography>
                    {item.label !== "Plan Type" && item.label !== "Settlement %" ? (
                      <Box pb={1.375}>
                        <MonetaryValue variant="subtitle1" monetaryAmount={{ amount: Number(item.value) }} />
                      </Box>
                    ) : (
                      <Typography pb={item.label == "Settlement %" ? 3 : 1.375} variant="subtitle1">
                        {item.label == "Settlement %" ? `${item.value}%` : item.value}
                      </Typography>
                    )}
                  </>
                </Stack>
              </Stack>
            );
          })}
          {paymentPlanOut?.is_recurring && (
            <Stack
              sx={{
                height: "110px",
                backgroundColor: theme.palette.grey[500],
                boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 75%)"
              }}>
              <Stack pl={2} pr={2} pt={0}>
                <Stack direction={"column"} alignItems="center">
                  <Typography pt={1.375} fontWeight={600} variant="subtitle1">
                    {creditCard.label}
                  </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack>{creditCard.cardNumber}</Stack>
                  <Stack>{creditCard.date}</Stack>
                </Stack>
                <Stack direction={"row"}>
                  <Button
                    disabled
                    sx={{ color: theme.palette.grey[300], paddingLeft: 0, marginTop: 1 }}
                    variant="text">
                    CHANGE <ExternalLink color={theme.palette.grey[300]} href={""} title="" />
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack pl={3.75} mt={4.28} width={"97%"}>
          <TableContainer sx={{ backgroundColor: theme.palette.grey[500], width: "97%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>
                    <Typography variant="body2">Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Amount Paid</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Status</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentPlanOut?.installments.map((item, index) => {
                  const today = new Date().setHours(0, 0, 0, 0);
                  const dueDate = new Date(item?.due_date ?? "").setHours(0, 0, 0, 0);
                  return (
                    <TableRow key={`${index}-${item?.due_date}`}>
                      <TableCell>
                        <Typography variant="body1" sx={{ color: theme.palette.grey[700] }}>
                          {Number(index) + 1}
                        </Typography>
                      </TableCell>
                      {/* The following code it will be uncommented when the fields become editable */}
                      {/* {today < dueDate ? (
                        <TableCell>
                          <Stack
                            direction={"column"}
                            sx={{
                              backgroundColor: theme.palette.common.white,
                              width: "125px",
                              height: "48px",
                              boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 75%)",
                              textAlign: "center"
                            }}>
                            <Typography
                              sx={{ color: theme.palette.primary.dark, top: "15px", position: "relative" }}
                              fontSize={"0.8rem"}>
                              {formatTimestamp(item?.due_date)}
                            </Typography>
                          </Stack>
                        </TableCell>
                      ) : ( */}
                      <TableCell>
                        <Typography sx={{ color: theme.palette.grey[700] }} variant="body1">
                          {formatTimestamp(item?.due_date)}
                        </Typography>
                      </TableCell>
                      {/* )} */}
                      {item?.amount_due?.amount !== 0 ? (
                        <TableCell>
                          <Stack
                            direction={"column"}
                            sx={{
                              //backgroundColor: theme.palette.common.white,
                              width: "125px",
                              height: "48px"
                              //boxShadow: "0px 2px 4px -2px rgb(0 0 0 / 75%)"
                            }}>
                            <Typography sx={{ color: theme.palette.grey[700] }} fontSize={"0.8rem"}>
                              Amount Due
                            </Typography>
                            <Typography sx={{ color: theme.palette.grey[700] }}>
                              {/* In the future this field can be editable
                               <Input
                                id="standard-adornment-amount"
                                /value={}
                                type="number"
                                 onChange={e => {}}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              /> */}
                              <MonetaryValue
                                isWithWhiteSpace
                                variant="body1"
                                monetaryAmount={item?.installment_amount}
                              />
                            </Typography>
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <Typography sx={{ color: theme.palette.grey[700] }}>
                            <MonetaryValue
                              variant="body1"
                              isWithWhiteSpace
                              monetaryAmount={item?.installment_amount}
                            />
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography sx={{ color: theme.palette.grey[700] }}>
                          <Stack direction={"row"}>
                            {item?.amount_due?.amount !== 0 ? (
                              <>
                                <MonetaryValue
                                  variant="body1"
                                  isWithWhiteSpace
                                  monetaryAmount={item?.amount_due}
                                />
                                <Typography ml={0.6} variant="body1">
                                  Due
                                </Typography>
                              </>
                            ) : (
                              <Typography variant="body1">{item.status?.replaceAll("_", " ")}</Typography>
                            )}
                          </Stack>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Modal
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          open={isOpenModalCancelPaymentPlan}
          onClose={() => setOpenModalCancelPaymentPlan(false)}>
          <Stack width={"600px"} p={2} height={"253px"} sx={{ backgroundColor: theme.palette.common.white }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography variant="h4" fontWeight={600}>
                Canceling this Plan?
              </Typography>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setOpenModalCancelPaymentPlan(false)} />
            </Stack>
            <Stack direction={"row"} spacing={2} mt={2}>
              <Autocomplete
                onChange={(event, value) => setSelectedValueOnCancelOptions(value)}
                id="size-small-standard"
                size="medium"
                fullWidth
                options={optionsCancelPlan}
                getOptionLabel={option => option.title}
                defaultValue={{ title: "Select One", value: "" }}
                renderInput={params => (
                  <TextField
                    {...params}
                    size={"medium"}
                    variant="standard"
                    label="Reasons for Cancelling Plan"
                    placeholder="Select One"
                  />
                )}
              />
            </Stack>
            <Stack direction={"row"} pt={4.875} pb={4.5}>
              <ErrorOutlineIcon sx={{ ml: 1, mr: 1 }} />
              <Typography>
                Be sure to include the reason for cancelling the plan in the Account Notes.
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent="flex-end">
              <Button onClick={() => setOpenModalCancelPaymentPlan(false)} variant="outlined">
                BACK TO ACCOUNT INFO PAGE
              </Button>
              <Button
                sx={{ marginLeft: 3 }}
                className={classButton.button}
                onClick={handleSubmitCancelPaymentPlan}
                disabled={isNotSelectedValue}
                variant="contained">
                SUBMIT
              </Button>
            </Stack>
          </Stack>
        </Modal>

        <Modal
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          open={isOpenModalLeavePage}
          onClose={() => setOpenModalLeavePage(false)}>
          <Stack width={"516px"} p={2} height={"245px"} sx={{ backgroundColor: theme.palette.common.white }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="h4" fontWeight={600}>
                Leave this page?
              </Typography>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => setViewPlan(false)} />
            </Stack>
            <Stack direction={"row"} pt={2}>
              <Typography variant="subtitle2">You have unsaved changes.</Typography>
            </Stack>
            <Stack direction={"row"} pt={2} pb={2}>
              <Typography variant="subtitle2">Clicking [Stay] will return to this Payment Plan.</Typography>
            </Stack>
            <Stack direction={"row"}>
              <Typography variant="subtitle2">
                Clicking [Leave] will exit the payment plan with NO changes saved.
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent="flex-end">
              <Button onClick={() => setOpenModalLeavePage(false)} variant="outlined">
                STAY
              </Button>
              <Button onClick={() => setViewPlan(false)} sx={{ marginLeft: 3 }} variant="contained">
                Leave
              </Button>
            </Stack>
          </Stack>
        </Modal>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          message={"The plan was cancelled successfully"}
          open={isSnackOpenCancelPaymentPlan}
          autoHideDuration={3000}
        />
      </Stack>
    </>
  );
}

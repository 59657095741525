import type { ApolloClient } from "@apollo/client";
import type { OktaAuth, UserClaims } from "@okta/okta-auth-js";
import type { IDToken } from "@okta/okta-auth-js/lib/types/Token";
import { useOktaAuth } from "@okta/okta-react";
import * as Sentry from "@sentry/react";
import { every, isArray, isString } from "lodash";
import type { ReactElement, ReactNode } from "react";
import React, { createContext, useContext, useEffect, useState } from "react";
import type { ICamundaConfig } from "~/core/config/camunda.config";
import { useGenericConfig } from "~/core/config/context";
import type { IThinTask } from "../utils/models";

interface IUserContext {
  user?: OktaUser;
  isAuthenticated: boolean;
}

export const UserContext = createContext<IUserContext>({ isAuthenticated: false });

export function UserContextProvider({ children }: { children: ReactNode }): ReactElement | null {
  const { oktaAuth, authState } = useOktaAuth();
  const { camundaConfig } = useGenericConfig();
  const [userClaims, setUserClaims] = useState<UserClaims | null>(null);

  useEffect(() => {
    let shouldIgnore = false;
    if (shouldIgnore) {
      return;
    }
    if (authState?.idToken?.idToken) {
      const fetchUserClaims = async (): Promise<void> => {
        console.time("Fetching UserClaims");
        const claims = await oktaAuth.getUser();
        setUserClaims(claims);
        console.timeEnd("Fetching UserClaims");
      };
      void fetchUserClaims();
      return () => {
        shouldIgnore = true;
      };
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.idToken?.idToken]);

  let user: OktaUser | undefined;
  if (authState?.idToken && userClaims) {
    user = new OktaUser(authState.idToken, userClaims, camundaConfig);
  }
  const ctx: IUserContext = {
    user,
    isAuthenticated: (user && authState?.isAuthenticated) || false
  } as IUserContext;

  Sentry.setUser({
    id: user?.email,
    name: user?.name,
    email: user?.email
  });
  Sentry.setTags({
    isAuthenticated: ctx.isAuthenticated,
    isDvSupervisor: user?.isDvSupervisor,
    isCamundaAdmin: user?.isCamundaAdmin
  });
  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
}

export function useSession(): IUserContext {
  return useContext(UserContext);
}

export function useUser(): OktaUser {
  const { isAuthenticated, user } = useContext(UserContext);
  if (isAuthenticated && user) {
    return user;
  } else {
    throw "Trying to useUser in non-authenticated session.";
  }
}

function isArrayOf<T>(value: unknown, predicate: (v?: unknown) => v is T): value is T[] {
  return isArray(value) && every(value, predicate);
}

export class OktaUser {
  readonly id: string;
  readonly email: string;
  readonly preferred_username?: string;
  readonly name: string;
  readonly camundaId: string;
  readonly groups: string[] = [];
  readonly isDvSupervisor: boolean;
  readonly isCamundaAdmin: boolean;
  private readonly _idToken: IDToken;
  private readonly _camundaConfig: ICamundaConfig;

  constructor(idToken: IDToken, userClaims: UserClaims, camundaConfig: ICamundaConfig) {
    if (!idToken.claims.email) {
      throw "User without an email.";
    }
    if (!idToken.claims.name) {
      throw "User without a name.";
    }

    this._idToken = idToken;
    this._camundaConfig = camundaConfig;
    if (camundaConfig.useEmailAsId) {
      this.camundaId = idToken.claims.email;
    } else if (isString(idToken.claims.camundaId)) {
      this.camundaId = idToken.claims.camundaId;
    } else {
      throw "User without valid camundaId.";
    }
    if (isArrayOf(userClaims.groups, isString)) {
      this.groups = userClaims.groups;
    }
    this.id = idToken.claims.sub;
    this.email = idToken.claims.email;
    this.name = idToken.claims.name;
    this.preferred_username = idToken.claims.preferred_username;
    this.isCamundaAdmin = this.groups.includes(camundaConfig.groups.camundaAdmin);
    this.isDvSupervisor =
      this.groups.includes(camundaConfig.groups.debtVerificationSupervisors) || this.isCamundaAdmin;
  }

  ownsTask(task: IThinTask): boolean {
    return !!(task.assigneeId && this.camundaId === task.assigneeId);
  }
}

interface SignOutOpts<T> {
  oktaAuth: OktaAuth;
  apolloClient: ApolloClient<T>;
}

export async function signOutUser<T>({ oktaAuth, apolloClient }: SignOutOpts<T>): Promise<void> {
  console.log("Signing out and clearing persisted state.");
  await apolloClient.resetStore();
  await oktaAuth.signOut({ revokeAccessToken: true, revokeRefreshToken: true });
}

import type { GridFilterItem, GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { endOfDay, parse, startOfDay } from "date-fns";
import _ from "lodash";
import type {
  CamundaTaskQuery,
  CamundaTaskQuerySorting,
  VariableQueryParameterDto
} from "~/core/types/camunda/api";
import { CamundaVariableOperator } from "~/core/types/camunda/api";

export function makeCamundaTaskQuery(
  filterModel: GridFilterModel,
  sortModel: GridSortModel
): CamundaTaskQuery {
  const query: CamundaTaskQuery = {
    sorting: makeCamundaSorting(sortModel)
  };
  const processVariables: VariableQueryParameterDto[] = [];

  const filters = _.chain(filterModel.items ?? [])
    .compact()
    .filter(
      ({ columnField, operatorValue, value }) =>
        columnField !== undefined && operatorValue !== undefined && value !== undefined
    )
    .value() as Array<Required<GridFilterItem>>;
  for (const filter of filters) {
    switch (filter.columnField) {
      case "creditorName": {
        processVariables.push(...convertStringVariableFilter(filter));
        break;
      }
      case "daysRemaining": {
        processVariables.push(...convertDateVariableOperator(filter, "dueDate"));
        break;
      }
      case "taskDueDate":
        if (!_.isDate(filter.value)) {
          console.error(`Wrong taskDueDate value: ${JSON.stringify(filter)}`);
          break;
        }
        switch (filter.operatorValue) {
          case "is":
            query.dueDate = filter.value;
            break;
          case "after":
          case "onOrAfter":
            query.dueAfter = filter.value;
            break;
          case "before":
          case "onOrBefore":
            query.dueBefore = filter.value;
            break;
          case "isEmpty":
            query.withoutDueDate = true;
            break;
          case "isNotEmpty":
            query.withoutDueDate = false;
            break;
          default:
            console.error(`Unsupported operator for taskDueDate: ${JSON.stringify(filter)}`);
        }
        break;
      case "taskType": {
        if (!_.isString(filter.value)) {
          console.error(`Wrong taskType filter value: ${JSON.stringify(filter)}`);
          break;
        }
        switch (filter.operatorValue) {
          case "contains":
            query.nameLike = `%${filter.value}%`;
            break;
          case "startsWith":
            query.nameLike = `${filter.value}%`;
            break;
          case "endsWith":
            query.nameLike = `%${filter.value}`;
            break;
          case "equals":
            query.name = filter.value;
            break;
          case "isEmpty":
            query.name = "";
            break;
          case "isNotEmpty":
            query.nameNotEqual = "";
            break;
          default:
            console.error(`Unsupported operator: ${JSON.stringify(filter.operatorValue)}`);
            break;
        }
        break;
      }
      case "disputeType": {
        if (!_.isString(filter.value)) {
          console.error(`Wrong disputeType filter value: ${JSON.stringify(filter)}`);
          break;
        }
        switch (filter.operatorValue) {
          case "is":
            processVariables.push({
              name: "disputeType",
              operator: CamundaVariableOperator.eq,
              value: filter.value
            });
            break;
          case "not":
            processVariables.push({
              name: "disputeType",
              operator: CamundaVariableOperator.neq,
              value: filter.value
            });
            break;
          default:
            console.error(`Unsupported operator: ${JSON.stringify(filter)}`);
        }
        break;
      }
      case "assignee":
        if (!_.isString(filter.value) && filter.value !== undefined) {
          console.error(`Wrong disputeType filter value: ${JSON.stringify(filter)}`);
          break;
        }
        switch (filter.operatorValue) {
          case "is":
            if (filter.value === undefined) {
              query.assigned = false;
            } else {
              query.assigneeIn = [filter.value];
            }
            break;
          case "not":
            if (filter.value === undefined) {
              query.assigned = true;
            } else {
              query.assigneeNotIn = [filter.value];
            }
            break;
          default:
            console.error(`Unsupported operator: ${JSON.stringify(filter)}`);
        }
        break;
      case "debtId":
        processVariables.push(...convertStringVariableFilter(filter));
        break;
      default:
        console.error(`Filter field '${JSON.stringify(filter.columnField)}' is not supported yet.`);
    }
  }
  return {
    ...query,
    processVariables
  };
}

function convertStringVariableFilter(
  { columnField, operatorValue, value }: GridFilterItem,
  name: string = columnField
): VariableQueryParameterDto[] {
  if (!(operatorValue && columnField)) {
    return [];
  }
  switch (operatorValue) {
    case "isEmpty":
      return [{ name, operator: CamundaVariableOperator.eq, value: null }];
    case "isNotEmpty":
      return [{ name, operator: CamundaVariableOperator.neq, value: null }];
  }
  if (!_.isString(value)) {
    console.error(`Wrong string variable filter value: ${JSON.stringify(value)}`);
    return [];
  }
  switch (operatorValue) {
    case "equals":
    case "is":
      return [{ name, operator: CamundaVariableOperator.eq, value }];
    case "not":
      return [{ name, operator: CamundaVariableOperator.neq, value }];
    case "contains":
      return [{ name, operator: CamundaVariableOperator.like, value: `%${value}%` }];
    case "startsWith":
      return [{ name, operator: CamundaVariableOperator.like, value: `${value}%` }];
    case "endsWith":
      return [{ name, operator: CamundaVariableOperator.like, value: `%${value}` }];
    default:
      console.error(`Operator '${operatorValue}' is not supported for value '${JSON.stringify(value)}'`);
      return [];
  }
}

function convertDateVariableOperator(
  { columnField, operatorValue, value }: GridFilterItem,
  name: string = columnField
): VariableQueryParameterDto[] {
  if (!(operatorValue && columnField)) {
    return [];
  }

  switch (operatorValue) {
    case "isEmpty":
      return [{ name, operator: CamundaVariableOperator.eq, value: null }];
    case "isNotEmpty":
      return [{ name, operator: CamundaVariableOperator.neq, value: null }];
  }

  if (!_.isString(value)) {
    console.error(`Wrong date variable filter value: ${JSON.stringify(value)}`);
    return [];
  }

  try {
    const valueDate = parse(value, "yyyy-MM-dd", new Date());
    const valueStartDay = startOfDay(valueDate);
    const valueEndDay = endOfDay(valueDate);
    switch (operatorValue) {
      case "equals":
      case "is":
        return [
          { name, operator: CamundaVariableOperator.gteq, value: valueStartDay },
          { name, operator: CamundaVariableOperator.lteq, value: valueEndDay }
        ];
      case "after":
        return [{ name, operator: CamundaVariableOperator.gteq, value: valueEndDay }];
      case "onOrAfter":
        return [{ name, operator: CamundaVariableOperator.gteq, value: valueStartDay }];
      case "before":
        return [{ name, operator: CamundaVariableOperator.lteq, value: valueStartDay }];
      case "onOrBefore":
        return [{ name, operator: CamundaVariableOperator.lteq, value: valueEndDay }];
      default:
        console.error(`Operator '${operatorValue}' is not supported for value '${JSON.stringify(value)}'`);
        return [];
    }
  } catch (e) {
    console.error(`Wrong date variable filter value: ${JSON.stringify(value)}`);
    return [];
  }
}

function makeCamundaSorting(sortModel: GridSortModel): CamundaTaskQuerySorting[] {
  return _.chain(sortModel)
    .map((sortItem): CamundaTaskQuerySorting | undefined => {
      const sortOrder = sortItem.sort ?? "desc";
      switch (sortItem.field) {
        case "dueDate":
          return {
            sortBy: "processVariable",
            parameters: { variable: "dueDate", type: "Date" },
            sortOrder
          };
        case "taskDueDate":
          return { sortBy: "dueDate", sortOrder };
        case "taskType":
          return { sortBy: "name", sortOrder };
        case "assignee":
          return { sortBy: "assignee", sortOrder };
        case "creditorName":
          return {
            sortBy: "processVariable",
            parameters: { variable: "creditorName", type: "String" },
            sortOrder
          };
        case "daysRemaining":
          return {
            sortBy: "processVariable",
            parameters: { variable: "dueDate", type: "Date" },
            sortOrder
          };
        case "disputeType":
          return {
            sortBy: "processVariable",
            parameters: { variable: "disputeType", type: "String" },
            sortOrder
          };
        case "debtId":
          return {
            sortBy: "processVariable",
            parameters: { variable: "debtId", type: "String" },
            sortOrder
          };
        default:
          console.error(`Sorting by '${sortItem.field}' is not supported yet.`);
          return undefined;
      }
    })
    .compact()
    .value();
}

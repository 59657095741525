import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Accordion } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";
import { lighten } from "@mui/system/colorManipulator";
import type { ReactElement, ReactNode } from "react";

export interface SmallAccordionProps {
  title: string;
  children: ReactNode;
  isExpanded?: boolean;
}

export function SmallAccordion({
  title,
  children,
  isExpanded = false
}: SmallAccordionProps): ReactElement | null {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        borderRight: "none",
        borderLeft: "none",
        borderBottom: "none",
        borderTop: `1px solid ${theme.palette.divider}`
      }}
      elevation={0}
      disableGutters={true}
      defaultExpanded={isExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: lighten(theme.palette.background.default, 0.8)
        }}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 1ex 0 1ex" }}>{children}</AccordionDetails>
    </Accordion>
  );
}

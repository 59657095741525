import { envVarAsUrl } from "~/core/utils/env";
import type { IConfigEnv } from "./context";

export interface ICamundaGroups {
  readonly debtVerificationAgents: string;
  readonly debtVerificationInternationalAgents: string;
  readonly debtVerificationSupervisors: string;
  readonly camundaAdmin: string;
}

export interface ICamundaConfig {
  readonly baseUrl: string;
  readonly useEmailAsId: boolean;
  readonly groups: ICamundaGroups;
}

export const camundaConfig = (env: IConfigEnv): ICamundaConfig => ({
  baseUrl: envVarAsUrl(env.get("REACT_APP_CAMUNDA_BASE_URI").required()),
  useEmailAsId: env.get("REACT_APP_CAMUNDA_USE_EMAIL_AS_ID").default("true").asBool(),
  groups: {
    debtVerificationAgents: getGroupValueFromEnv(env, "debtVerificationAgents", "debtVerificationAgents"),
    debtVerificationInternationalAgents: getGroupValueFromEnv(env, "debtVerificationInternationalAgents", "debtVerificationInternationalAgents"),
    debtVerificationSupervisors: getGroupValueFromEnv(
      env,
      "debtVerificationSupervisors",
      "debtVerificationSupervisors"
    ),
    camundaAdmin: getGroupValueFromEnv(env, "camundaAdmin", "camunda-admin")
  }
});

function getGroupValueFromEnv(env: IConfigEnv, group: keyof ICamundaGroups, defaultValue: string): string {
  return env.get(`REACT_APP_CAMUNDA_GROUP_${group}`).default(defaultValue).asString();
}

import * as _ from "lodash";
import type { IPerson } from "~/gql/types";
import type { StrictTypedTypePolicies } from "../../gql/apollo-helpers";
import { defineReader } from "../utils/policies";

export const PERSON_POLICY: StrictTypedTypePolicies["Person"] = {
  fields: {
    fullName: defineReader<IPerson, string>(({ readPrimitive }) => {
      const name = readPrimitive("name");
      if (!name) {
        return "[Missing PersonName]";
      }
      return _.chain([
        name.first_name,
        name.middle_name ? `${name.middle_name[0] ?? "_"}.` : undefined,
        name.last_name
      ])
        .compact()
        .join(" ")
        .value();
    })
  }
};

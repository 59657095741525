import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { MonetaryValue } from "../widgets/MonetaryValue";
import { isNil } from "lodash";
import { Link } from "react-router-dom";
import type { IAccountModel, IPersonOutModel } from "~/gql/types";
import { disambiguateCompanyNames } from "./helpers";
import _ from "lodash";

interface ModalCreditorsProps {
  personOut: IPersonOutModel;
  account: IAccountModel;
}

const humanStatusMap = [
  {
    status: "IN_PROGRESS",
    label: "In Progress"
  },
  {
    status: "IN_PAYMENT_PLAN",
    label: "In Payment Plan"
  },
  {
    status: "IN_DISPUTE",
    label: "In Dispute"
  },
  {
    status: "OBSOLETE_DISCHARGED",
    label: "Obsolete Discharged"
  },
  {
    status: "PAID_IN_FULL",
    label: "Paid In Full"
  },
  {
    status: "PARTIALLY_PAID",
    label: "Partially Paid"
  },
  {
    status: "CLOSED",
    label: "Closed"
  },
  {
    status: "RECALLED",
    label: "Recalled"
  },
  {
    status: "SETTLED_IN_FULL",
    label: "Settled In Full"
  }
];

export function ModalCreditors({ personOut, account }: ModalCreditorsProps): React.ReactElement {
  const theme = useTheme();

  let companyItems = disambiguateCompanyNames(personOut, account);

  const currentCreditor = companyItems.find(
    item =>
      item.creditor?.company_name === account.creditor?.company_name &&
      item.balance?.amount === account.balance?.amount
  );
  if (currentCreditor !== undefined) {
    companyItems.unshift(currentCreditor);
    companyItems = [...new Set(companyItems)];
  }

  const lengthsCreditor = companyItems?.map(item => item.creditor?.company_name?.length ?? 0);
  const creditorNameWithMoreLength = Math.max(...lengthsCreditor);

  const balanceLength = companyItems?.map(item => String(item?.balance).length ?? 0);
  const balanceWithMoreLength = Math.max(...balanceLength);

  const greyFontColor = theme.palette.grey[900];
  return (
    <Stack direction="column" spacing={2} width="auto" padding="1em">
      {companyItems?.map(({ id, simplified_status, creditor, balance }) => {
        const isActualCreditor =
          creditor?.company_name === account.creditor?.company_name &&
          balance?.amount === account.balance?.amount;
        const actualStatus = humanStatusMap.find(status => status.status === simplified_status);
        return (
          <Stack key={id} direction="row">
            <Stack direction="row" color={isActualCreditor ? greyFontColor : theme.palette.primary.main}>
              {!isActualCreditor ? (
                <Link
                  to={`/app/accounts/${id}`}
                  style={{ textDecoration: "none", color: theme.palette.primary.main, display: "flex" }}
                  target="_blank">
                  <Stack
                    direction={"row"}
                    sx={{
                      gridTemplateColumns: `${creditorNameWithMoreLength * 8 + 16}px ${
                        balanceWithMoreLength * 8 - 20
                      }px`,
                      display: "grid"
                    }}
                    justifyContent="space-between">
                    <Stack>{creditor?.company_name}</Stack>
                    <Stack direction={"row"}>
                      (<MonetaryValue variant="body1" monetaryAmount={balance} />)
                    </Stack>
                  </Stack>
                </Link>
              ) : (
                <Stack
                  direction={"row"}
                  sx={{
                    gridTemplateColumns: `${creditorNameWithMoreLength * 8 + 16}px ${
                      balanceWithMoreLength * 8 - 20
                    }px`,
                    display: "grid"
                  }}
                  justifyContent="space-between"
                  color={greyFontColor}>
                  <Stack>{creditor?.company_name}</Stack>
                  <Stack direction={"row"}>
                    (<MonetaryValue variant="body1" monetaryAmount={balance} />)
                  </Stack>
                </Stack>
              )}
            </Stack>
            {isActualCreditor && <Stack color={greyFontColor}>Current Viewing</Stack>}
            {!isActualCreditor && !isNil(simplified_status) && (
              <Avatar
                sx={{
                  width: "auto",
                  padding: "0 10px",
                  fontSize: "1em",
                  height: "1.875em",
                  backgroundColor: theme.palette.grey[700]
                }}
                color="success"
                variant="rounded">
                {actualStatus?.label}
              </Avatar>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
}

import type { IGenericConfig } from "~/core/config/context";
import { useGenericConfig, withGenericConfig } from "~/core/config/context";
import { oktaAuthOptions, oktaSoloConfig } from "./okta.config";
import { soloConfig } from "./solo.config";

export interface IConfig extends IGenericConfig {
  soloConfig: ReturnType<typeof soloConfig>;
  oktaSoloConfig: ReturnType<typeof oktaSoloConfig>;
  oktaSoloOptions: ReturnType<typeof oktaAuthOptions>;
}

export function useConfig(): IConfig {
  return useGenericConfig<IConfig>();
}

export async function withConfig<R>(path: string, wrappedFn: (config: IConfig) => Promise<R>): Promise<R> {
  return await withGenericConfig<IConfig, R>(
    path,
    (env, config) => {
      return {
        ...config,
        soloConfig: soloConfig(env),
        oktaSoloConfig: oktaSoloConfig(env),
        oktaSoloOptions: oktaAuthOptions(env)
      };
    },
    wrappedFn
  );
}

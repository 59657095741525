import { Chip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import type { ReactElement } from "react";

interface CountBadgeProps {
  isLoading: boolean;
  label: string;
}

export function CountBadge({ label, isLoading = false }: CountBadgeProps): ReactElement {
  if (isLoading) {
    return <CircularProgress size={20} />;
  } else {
    return <Chip sx={{ fontSize: "15px" }} label={label} size={"small"} />;
  }
}

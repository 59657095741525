import { titleCase } from "~/core/components/utils/content";
import type { IAccountModel, Maybe } from "~/gql/types";
import { IDisputeClaim_Reason } from "~/gql/types";

export interface IThinTask {
  id: string;
  name?: Maybe<string>;
  assigneeId?: Maybe<string>;
  debt?: Maybe<{ person?: Maybe<{ fullName: string }> }>;
}

export function humanTaskName(task: IThinTask): string {
  return `${titleCase(task.name ?? "Unknown")} task for ${task.debt?.person?.fullName ?? "Unknown User"}`;
}

export function humanDisputeName(dispute: Maybe<IDisputeClaim_Reason>): string {
  switch (dispute) {
    case IDisputeClaim_Reason.ActiveMilitary:
      return "Active Military";
    case IDisputeClaim_Reason.AlreadyPaid:
      return "Already Paid";
    case IDisputeClaim_Reason.AlreadyPaidInFull:
      return "Already Pain in Full";
    case IDisputeClaim_Reason.AlreadySettledInFull:
      return "Already Settled in Full";
    case IDisputeClaim_Reason.AmountIncorrect:
      return "Amount Incorrect";
    case IDisputeClaim_Reason.Bankruptcy:
      return "Bankruptcy";
    case IDisputeClaim_Reason.BillingInquiry:
      return "Billing Inquiry";
    case IDisputeClaim_Reason.CreditorSpecific:
      return "Creditor Specific";
    case IDisputeClaim_Reason.DebtInvalid:
      return "Debt Invalid";
    case IDisputeClaim_Reason.DelinquencyFees:
      return "Delinquency Fees";
    case IDisputeClaim_Reason.IdentityTheft:
      return "Identity Theft";
    case IDisputeClaim_Reason.MediaRequest:
      return "Media Request";
    case IDisputeClaim_Reason.MembershipFees:
      return "Membership Fees";
    default:
      return "Unknown Dispute";
  }
}

export type PaymentMethod = "ACH" | "Credit" | "Debit";

export function availablePaymentMethods(account: IAccountModel): PaymentMethod[] {
  const methods: PaymentMethod[] = [];
  if (account.available_payment_methods?.check) {
    methods.push("ACH");
  }
  if (account.available_payment_methods?.credit_card) {
    methods.push("Credit");
  }
  if (account.available_payment_methods?.debit_card) {
    methods.push("Debit");
  }
  return methods;
}

import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { useLocation } from "react-router-dom";
import { CenteredLayout } from "~/core/components/layouts/CenteredLayout";
import { ProgressIndicator } from "~/core/components/widgets/ProgressIndicator";

function OidcCompliant(): React.ReactElement {
  const { oktaAuth, authState } = useOktaAuth();

  const login = async (): Promise<void> => oktaAuth.signInWithRedirect();

  const searchParams = new URLSearchParams(useLocation().search);
  const iss = searchParams.get("iss");
  // if app can be configured for multiple issuers use this value
  console.log(`iss: ${iss}`);

  if (!authState || !authState.isAuthenticated) {
    void login();
  }

  return (
    <CenteredLayout>
      <ProgressIndicator message={`Initiating subspace transporter...`} width={"40%"} />
    </CenteredLayout>
  );
}

export default OidcCompliant;

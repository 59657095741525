import { Button } from "@mui/material";
import { Tooltip } from "@mui/material";
import type { ReactElement } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useUser } from "~/core/contexts/UserContext";
import type { IThinTask } from "~/core/utils/models";

export function ContinueTaskButton({ task }: { task: IThinTask }): ReactElement | null {
  const history = useHistory();
  const user = useUser();
  if (!user.ownsTask(task)) {
    return null;
  }

  return (
    <Tooltip title={"Continue working on this task"}>
      <Button onClick={() => history.push(`/app/tasks/${task.id}`)} size="small">
        Continue
      </Button>
    </Tooltip>
  );
}

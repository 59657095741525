import type { SvgIconComponent } from "@mui/icons-material";
import { Description as DescriptionIcon } from "@mui/icons-material";
import { LibraryBooks as LibraryBooksIcon } from "@mui/icons-material";
import { LibraryMusic as LibraryMusicIcon } from "@mui/icons-material";
import { PhotoLibrary as PhotoLibraryIcon } from "@mui/icons-material";
import { PictureAsPdf as PictureAsPdfIcon } from "@mui/icons-material";
import { startCase, toLower } from "lodash";

export function iconForContentType(contentType: string | null | undefined): SvgIconComponent {
  if (contentType === "application/pdf") {
    return PictureAsPdfIcon;
  } else if (contentType?.startsWith("image/")) {
    return PhotoLibraryIcon;
  } else if (contentType?.startsWith("plain/")) {
    return LibraryBooksIcon;
  } else if (contentType?.startsWith("audio/")) {
    return LibraryMusicIcon;
  } else {
    return DescriptionIcon;
  }
}

export function titleCase(value: string): string {
  return startCase(toLower(value));
}

import { Stack } from "@mui/material";
import type { ReactElement } from "react";
import { MarkdownAccordion } from "~/core/components/widgets/MarkdownAccordion";
import { humanDisputeName } from "~/core/utils/models";
import type { IDisputeClaim_Reason, IDisputeContextHelpModel, Maybe } from "~/gql/types";
import { Block } from "./Block";

export interface IDisputeHelpBlockProps {
  disputeHelp: IDisputeContextHelpModel;
  disputeType: Maybe<IDisputeClaim_Reason>;
}

export function DisputeHelpBlock({
  disputeType,
  disputeHelp: { goal, matchRequirements, required, documentExamples, stateRequirements }
}: IDisputeHelpBlockProps): ReactElement {
  return (
    <Block
      title="Agent Help"
      subheader={`Dispute: ${humanDisputeName(disputeType)}`}
      noHorizontalPadding
      noBottomPadding>
      <Stack>
        {goal && <MarkdownAccordion title={`What to Check`} markdown={goal} isExpanded />}
        {stateRequirements && (
          <MarkdownAccordion title="State Specific Requirements" markdown={stateRequirements} isExpanded />
        )}
        {required && <MarkdownAccordion title="Required Documents" markdown={required} isExpanded />}
        {matchRequirements && (
          <MarkdownAccordion title="Match Requirements" markdown={matchRequirements} isExpanded />
        )}
        {documentExamples && <MarkdownAccordion title="Document Examples" markdown={documentExamples} />}
      </Stack>
    </Block>
  );
}

import { useOktaAuth } from "@okta/okta-react";
import type { ReactElement, ReactNode } from "react";
import { createContext, useContext } from "react";
import { Configuration, CrmApi } from "~/api-client";
import { useGenericConfig } from "~/core/config/context";

export const ApiContext = createContext<CrmApi | null>(null);

interface ApiProviderProps {
  children: ReactNode;
}

export function ApiProvider({ children }: ApiProviderProps): ReactElement {
  const { authState } = useOktaAuth();
  const { appConfig } = useGenericConfig();
  const idToken = authState?.idToken?.idToken;
  const nonce = authState?.idToken?.claims.nonce;

  const config = new Configuration({
    apiKey: nonce,
    accessToken: idToken
  });
  const api = new CrmApi(config, appConfig.apiBaseUrl);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export function useCrmApi(): CrmApi {
  const crmApi = useContext(ApiContext);
  if (!crmApi) {
    throw "Trying to useCrmApi outside the ApiContextProvider.";
  }
  return crmApi;
}
